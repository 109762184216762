import React, { createContext, useContext, useEffect, useState } from "react";
import moment from "moment";

import "../../styles/StackPlotEditor.css";

import StackPlot from "./StackPlot";
import { data as dummyData, config as dummyConfig } from "./StackPlotDummy";
import { Button, Input, Label, Spinner } from "reactstrap";
import { RestContext } from "../../App";
import { DATA_URL, STACKPLOT_URL } from "../../serverConfig";
import MultiSelect from "../../components/MultiSelect";
import {
    checkCustomParameter,
    generateMockDataset,
} from "../../util/mockDataUtil";
import { colors } from "../../styles/colors";

const StackPlotEditorContext = createContext(null);

const NUM_DAYS = 3;

const INTERVALS = {
    hourly: 60 * 60,
    "1-min": 60,
    "5-min": 60 * 5,
    "15-min": 60 * 15,
    daily: 60 * 60 * 24,
    cumulative: 60 * 60,
    "24-hr run": 60 * 60,
};

function StackPlotEditor({ selectedSite }) {
    const [configList, setConfigList] = useState([]);
    const [selectedConfig, setSelectedConfig] = useState();
    const [config, setConfig] = useState({});
    const [data, setData] = useState({});
    const [mockDatasets, setMockDatasets] = useState({});
    const [columns, setColumns] = useState([]);
    const [selectedChartIndex, setSelectedChartIndex] = useState(null);
    const [siteParameterList, setSiteParameterList] = useState([]);
    const [precision, setPrecision] = useState();
    const [changed, setChanged] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const { sendGetRequest, sendPostRequest } = useContext(RestContext);

    useEffect(() => {
        setConfig(dummyConfig);
        setColumns(dummyData.columns);
    }, []);

    useEffect(() => {
        if (selectedSite) {
            if (selectedSite === "all") {
                setConfig(dummyConfig);
                setColumns(dummyData.columns);
                setData(explodeData(dummyData.data));
            } else {
                sendGetRequest(
                    STACKPLOT_URL + "/list/" + selectedSite,
                    (response) => {
                        if (response.data && response.data.length) {
                            setConfigList(response.data);
                            setSelectedConfig(response.data[0].stackPlotId);
                        } else {
                            setConfigList([]);
                            setSelectedConfig();
                        }
                    }
                );
            }
        }
    }, [selectedSite]);

    useEffect(() => {
        if (selectedConfig) {
            sendGetRequest(
                STACKPLOT_URL + "/config/" + selectedConfig,
                (response) => {
                    setConfig(response.data);
                }
            );
        }
    }, [selectedConfig]);

    useEffect(() => {
        if (config && selectedSite !== "all") {
            let newMockDatasets = { ...mockDatasets };
            if (config.precision) {
                setPrecision(config.precision);

                if (newMockDatasets.precision !== config.precision) {
                    newMockDatasets = { precision: config.precision };
                }
            }
            if (config.charts) {
                for (let chart of config.charts) {
                    if (chart.parameters) {
                        for (let par of chart.parameters) {
                            if (checkCustomParameter(par.parName)) {
                                if (!(par.parName in newMockDatasets)) {
                                    newMockDatasets[par.parName] =
                                        generateMockDataset(
                                            par.parName,
                                            NUM_DAYS,
                                            newMockDatasets.precision
                                        );
                                }
                            } else if (!("other" in newMockDatasets)) {
                                newMockDatasets["other"] = generateMockDataset(
                                    "other",
                                    NUM_DAYS,
                                    newMockDatasets.precision
                                );
                            }
                        }
                    }
                }
            }

            setMockDatasets(newMockDatasets);
        }
    }, [config, selectedSite]);

    useEffect(() => {
        if (selectedSite && precision) {
            sendGetRequest(
                `${DATA_URL}/parameters/${selectedSite}?interval=${precision}`,
                (response) => {
                    setSiteParameterList(response.data);
                }
            );
        }
    }, [selectedSite, precision]);

    useEffect(() => {
        if (mockDatasets && selectedSite !== "all") {
            setData(explodeMockData());
        }
    }, [mockDatasets]);

    const explodeData = (rawData) => {
        if (!rawData || !columns) return null;
        const explodedData = {};
        for (let i = 0; i < columns.length; i += 2) {
            explodedData[columns[i]] = {
                columnIndex: i,
            };
        }

        const rawTimestamps = Object.keys(rawData).sort();
        let minTimestamp = Number(rawTimestamps[0]);
        let maxTimestamp = Number(rawTimestamps[rawTimestamps.length - 1]);
        let timestamps = [];
        let interval = 60;
        for (let i = 0; i <= (maxTimestamp - minTimestamp) / interval; i++) {
            timestamps.push(minTimestamp + i * interval);
        }

        for (let timestamp of timestamps) {
            let row = rawData[timestamp];
            if (row) {
                for (let parCode in explodedData) {
                    explodedData[parCode][timestamp] = [
                        row[explodedData[parCode].columnIndex],
                        row[explodedData[parCode].columnIndex + 1],
                    ];
                }
            }
        }
        explodedData._timestamps = timestamps;
        return explodedData;
    };

    const explodeMockData = () => {
        if (!config || !config.precision || !config.charts) return null;

        // Generate timestamps
        let timestamp = moment().unix();
        let endTimestamp = moment().add(NUM_DAYS, "days").unix();
        let interval = INTERVALS[config.precision];
        let timestamps = [];
        while (timestamp < endTimestamp) {
            timestamps.push(timestamp);
            timestamp += interval;
        }

        // Prepare parameters to load mock data
        const parMockMappings = {};
        const otherParOffsets = {};
        let otherParCount = 0;
        for (let chart of config.charts) {
            for (let parameter of chart.parameters) {
                if (parameter.parCode in parMockMappings) {
                    continue;
                }
                if (checkCustomParameter(parameter.parName)) {
                    parMockMappings[parameter.parCode] = parameter.parName;
                } else {
                    parMockMappings[parameter.parCode] = "other";
                    otherParOffsets[parameter.parCode] = otherParCount * 5;
                    otherParCount++;
                }
            }
        }

        // Load mock data
        const explodedData = {};
        for (let parameter in parMockMappings) {
            explodedData[parameter] = {};
        }
        if (Object.keys(explodedData).length) {
            for (let parameter in explodedData) {
                const parOffset =
                    parameter in otherParOffsets
                        ? otherParOffsets[parameter]
                        : 0;
                const parDataset = mockDatasets[parMockMappings[parameter]];
                if (parDataset) {
                    for (let i in timestamps) {
                        explodedData[parameter][timestamps[i]] = [
                            mockDatasets[parMockMappings[parameter]][i] +
                                parOffset,
                            null,
                        ];
                    }
                }
            }
        }
        explodedData._timestamps = timestamps;
        return explodedData;
    };

    const getFullTitle = () => {
        return config?.fullTitle || "";
    };

    const getFootnote = () => {
        return config?.footnote || "";
    };

    const getPrecision = () => {
        return config?.precision || "";
    };

    const getChartSpacing = () => {
        return Number(config?.chartSpacing || 0);
    };

    const getDefaultSpan = () => {
        return Number(config?.defaultSpan || 0);
    };

    const getChartConfig = () => {
        return config?.charts?.at(selectedChartIndex) || null;
    };

    const handleConfigSelect = (event) => {
        const value = event.target.value;
        setSelectedConfig(value);
    };

    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setChanged(true);
        setConfig((values) => ({ ...values, [name]: value }));
        setChanged(true);
    };

    const handleNumberInput = (event) => {
        const name = event.target.name;
        const value = Number(event.target.value);
        setConfig((values) => ({ ...values, [name]: value }));
        setChanged(true);
    };

    const updateChart = (index, chartConfig) => {
        const newCharts = config.charts;
        newCharts[index] = chartConfig;
        setConfig((values) => ({ ...values, charts: newCharts }));
        setChanged(true);
    };

    const updateParameter = (newParameter) => {
        if (selectedChartIndex === null || !config?.charts) return;
        const newChart = { ...config.charts[selectedChartIndex] };
        let par;
        for (let i = 0; i < newChart.parameters.length; i++) {
            par = newChart.parameters[i];
            if (par.parCode === newParameter.parCode) {
                newChart.parameters[i] = newParameter;
                updateChart(selectedChartIndex, newChart);
                return;
            }
        }
    };

    const plotEditorPlugin = {
        id: "plotEditorPlugin",
        beforeEvent: (chart, args) => {
            const event = args.event;
            if (event.type === "click") {
                setSelectedChartIndex(chart.options.chartIndex);
            }
        },
    };

    const getChartEditor = () => {
        if (selectedChartIndex === null) return null;
        return (
            <ChartEditor
                chartIndex={selectedChartIndex}
                chartConfig={getChartConfig()}
                updateChart={updateChart}
            />
        );
    };

    const createNewChart = () => {
        if (!config?.charts) return;
        let newCharts = [...config.charts];
        let newChart = {
            leftMin: null,
            leftMax: null,
            rightMax: null,
            rightMin: null,
            chartHeight: 200,
            parameters: [],
        };
        newCharts.push(newChart);
        setConfig((values) => ({ ...values, charts: newCharts }));
    };

    const deleteSelectedChart = () => {
        if (
            !(
                config?.charts ||
                selectedChartIndex === null ||
                config?.charts?.length >= selectedChartIndex
            )
        )
            return;
        let newCharts = config.charts
            .slice(0, selectedChartIndex)
            .concat(
                config.charts.slice(
                    selectedChartIndex + 1
                )
            );
        setConfig((values) => ({ ...values, charts: newCharts }));
        if (newCharts.length === 0) {
            setSelectedChartIndex(null);
        }
        else if (selectedChartIndex >= newCharts.length) {
            setSelectedChartIndex(newCharts.length - 1);
        }
    };

    const mapConfigsToOption = () => {
        if (!configList) return null;
        return configList.map((config, index) => (
            <option
                key={"stackplot-config-list-option-" + index}
                value={config.stackPlotId}
            >
                {config.plotName}
            </option>
        ));
    };

    const handleSubmit = () => {
        setWaiting(true);

        sendPostRequest(
            STACKPLOT_URL + "/config",
            { ...config, idNo: selectedSite },
            (response) => {
                setConfig(response.data);
                setWaiting(false);
                setChanged(false);
            },
            (error) => {
                setWaiting(false);
            }
        );
    };

    return (
        <StackPlotEditorContext.Provider
            value={{ selectedSite, siteParameterList, config, updateParameter }}
        >
            <div id="stackplot-editor">
                <div id="stackplot-editor-header">
                    <div className="stackplot-control-wrapper">
                        <Label>Select Configuration:</Label>
                        <select
                            id="stackplot-config-select"
                            onChange={handleConfigSelect}
                            disabled={!mapConfigsToOption()?.length}
                        >
                            {mapConfigsToOption()}
                        </select>
                    </div>
                </div>
                <div id="stackplot-editor-main">
                    <div id="stackplot-editor-left">
                        <div className="stackplot-editor-input-wrapper">
                            <Label>Full Title</Label>
                            <Input
                                type="text"
                                name="fullTitle"
                                className="stackplot-editor-input"
                                value={getFullTitle()}
                                onChange={handleInput}
                            />
                        </div>
                        <div className="stackplot-editor-input-wrapper">
                            <Label>Default Data Span (days)</Label>
                            <Input
                                type="number"
                                name="defaultSpan"
                                className="stackplot-editor-input"
                                value={getDefaultSpan()}
                                onChange={handleNumberInput}
                            />
                        </div>
                        <div className="stackplot-editor-input-wrapper">
                            <Label>Chart Spacing</Label>
                            <Input
                                type="number"
                                name="chartSpacing"
                                className="stackplot-editor-input"
                                value={getChartSpacing()}
                                onChange={handleNumberInput}
                            />
                        </div>
                        <div className="stackplot-editor-input-wrapper">
                            <Label>Data Precision</Label>
                            <select
                                name="precision"
                                className="stackplot-editor-select"
                                value={getPrecision()}
                                onChange={handleInput}
                            >
                                <option value={"1-min"}>1 Minute</option>
                                <option value={"5-min"}>5 Minute</option>
                                <option value={"15-min"}>15 Minute</option>
                                <option value={"hourly"}>Hourly</option>
                                <option value={"daily"}>Daily</option>
                                <option value={"cumulative"}>Cumulative</option>
                                <option value={"24-hour run"}>
                                    24-Hour Run
                                </option>
                            </select>
                        </div>
                        <div className="stackplot-editor-input-wrapper">
                            <Label>Footnote</Label>
                            <Input
                                type="textarea"
                                name="footnote"
                                className="stackplot-editor-textarea"
                                value={getFootnote()}
                                onChange={handleInput}
                            />
                        </div>
                        <ChartSelect
                            config={config}
                            selectedIndex={selectedChartIndex}
                            setSelectedIndex={setSelectedChartIndex}
                            createNewChart={createNewChart}
                            deleteSelectedChart={deleteSelectedChart}
                        />
                        <Button
                            className="submit-button"
                            disabled={!changed}
                            onClick={handleSubmit}
                        >
                            {waiting ? <Spinner size="sm" /> : "Save Changes"}
                        </Button>
                    </div>
                    <div id="stackplot-editor-center">
                        <StackPlot
                            data={data}
                            config={config}
                            plugins={[plotEditorPlugin]}
                        />
                    </div>
                    <div id="stackplot-editor-right">{getChartEditor()}</div>
                </div>
            </div>
        </StackPlotEditorContext.Provider>
    );
}

function ChartSelect({ config, selectedIndex, setSelectedIndex, createNewChart, deleteSelectedChart }) {
    const mapCharts = () => {
        if (!config?.charts?.length) return null;
        return config.charts.map((chart, index) => (
            <ChartSelectRow
                chart={chart}
                index={index}
                selected={selectedIndex === index}
                setSelectedIndex={setSelectedIndex}
            />
        ));
    };

    return (
        <div id="stackplot-editor-chart-select">
            <div id="stackplot-editor-chart-select-label">
                <Label>Charts</Label>
                <Button
                    className="stackplot-editor-label-button"
                    onClick={createNewChart}
                >
                    <b>+</b>
                </Button>
                <Button
                    className="stackplot-editor-label-button"
                    onClick={deleteSelectedChart}
                    disabled={selectedIndex === null}
                >
                    <b>-</b>
                </Button>
            </div>
            <div id="chart-container-wrapper">
                <div id="chart-container">{mapCharts()}</div>
            </div>
        </div>
    );
}

function ChartSelectRow({ chart, index, selected, setSelectedIndex }) {
    const handleClick = (event) => {
        setSelectedIndex(index);
    };

    const getChartText = () => {
        if (!(chart?.parameters?.length)) return <span style={{color:colors['ars-neutral-600']}}>Empty</span>;
        return chart.parameters.map((parameter) => parameter.parName).join(" ");
    };

    return (
        <div
            className={"chart-select-row" + (selected ? " selected" : "")}
            onClick={handleClick}
        >
            <span>{getChartText()}</span>
            <span className="chart-select-row-index">{`{${index}}`}</span>
        </div>
    );
}

function ChartEditor({ chartIndex, chartConfig, updateChart }) {
    const hasRightSide = () => {
        return chartConfig?.hasRightSide || false;
    };

    const hasCustomLeftScale = () => {
        return !(
            chartConfig?.leftMin === null ||
            chartConfig?.leftMin === undefined ||
            chartConfig?.leftMax === null ||
            chartConfig?.leftMax === undefined
        );
    };

    const hasCustomRightScale = () => {
        return !(
            chartConfig?.rightMin === null ||
            chartConfig?.rightMin === undefined ||
            chartConfig?.rightMax === null ||
            chartConfig?.rightMax === undefined
        );
    };

    const getChartHeight = () => {
        return chartConfig?.chartHeight || 200;
    };

    const getLeftMin = () => {
        return chartConfig?.leftMin || 0;
    };

    const getLeftMax = () => {
        return chartConfig?.leftMax || 100;
    };

    const getRightMin = () => {
        return chartConfig?.rightMin || 0;
    };

    const getRightMax = () => {
        return chartConfig?.rightMax || 100;
    };

    const handleNumberInput = (event) => {
        const name = event.target.name;
        const value = Number(event.target.value);
        const newChartConfig = { ...chartConfig, [name]: value };
        updateChart(chartIndex, newChartConfig);
    };

    const toggleHasRightSide = () => {
        if (!chartConfig) return;
        updateChart(chartIndex, {
            ...chartConfig,
            hasRightSide: chartConfig.hasRightSide ? false : true,
        });
    };

    const toggleCustomLeftScale = () => {
        if (!chartConfig) return;
        if (hasCustomLeftScale()) {
            updateChart(chartIndex, {
                ...chartConfig,
                leftMin: null,
                leftMax: null,
            });
        } else {
            updateChart(chartIndex, {
                ...chartConfig,
                leftMin: 0,
                leftMax: 100,
            });
        }
    };

    const toggleCustomRightScale = () => {
        if (!chartConfig) return;
        if (hasCustomRightScale()) {
            updateChart(chartIndex, {
                ...chartConfig,
                rightMin: null,
                rightMax: null,
            });
        } else {
            updateChart(chartIndex, {
                ...chartConfig,
                rightMin: 0,
                rightMax: 100,
            });
        }
    };

    const updateParameters = (sideParameters, side) => {
        let newParameters =
            chartConfig?.parameters?.filter(
                (parameter) => parameter.side !== side
            ) || [];
        for (let parameter of sideParameters) {
            parameter.color = parameter.color || "000000";
            parameter.side = parameter.side || side;
            parameter.type = parameter.type || "line";
            parameter.unit = parameter.unit || parameter.unitCode;
            parameter.parName = parameter.parName || parameter.parCode;
            parameter.flagName =
                parameter.flagName || parameter.parName + "_FLAG";
        }
        newParameters.push(...sideParameters);
        const newChartConfig = { ...chartConfig, parameters: newParameters };
        updateChart(chartIndex, newChartConfig);
    };

    return (
        <>
            <div className="stackplot-editor-input-wrapper">
                <Label>Chart Height</Label>
                <Input
                    type="number"
                    name="chartHeight"
                    className="stackplot-editor-input"
                    value={getChartHeight()}
                    onChange={handleNumberInput}
                />
            </div>
            <div className="stackplot-editor-section">
                <Label>Left Side</Label>

                <div className="stackplot-editor-input-wrapper inline">
                    <Label>Custom Scale?</Label>
                    <Input
                        type="checkbox"
                        className="stackplot-editor-checkbox"
                        checked={hasCustomLeftScale()}
                        onClick={toggleCustomLeftScale}
                        readOnly
                    />
                </div>

                <div
                    className="stackplot-editor-input-wrapper"
                    hidden={!hasCustomLeftScale()}
                >
                    <div className="stackplot-editor-number-range">
                        <Input
                            type="number"
                            name="leftMin"
                            className="stackplot-editor-input"
                            value={getLeftMin()}
                            onChange={handleNumberInput}
                        />
                        <span>-</span>
                        <Input
                            type="number"
                            name="leftMax"
                            className="stackplot-editor-input"
                            value={getLeftMax()}
                            onChange={handleNumberInput}
                        />
                    </div>
                </div>
                <div className="stackplot-editor-input-wrapper">
                    <ParameterSelect
                        chartConfig={chartConfig}
                        side={"L"}
                        updateParameters={updateParameters}
                    />
                </div>
            </div>
            <div className="stackplot-editor-input-wrapper inline">
                <Label>Has Right Side?</Label>
                <Input
                    type="checkbox"
                    className="stackplot-editor-checkbox"
                    checked={hasRightSide()}
                    onClick={toggleHasRightSide}
                    readOnly
                />
            </div>
            <div className="stackplot-editor-section" hidden={!hasRightSide()}>
                <Label>Right Side</Label>
                <div className="stackplot-editor-input-wrapper inline">
                    <Label>Custom Scale?</Label>
                    <Input
                        type="checkbox"
                        className="stackplot-editor-checkbox"
                        checked={hasCustomRightScale()}
                        onClick={toggleCustomRightScale}
                        readOnly
                    />
                </div>
                <div
                    className="stackplot-editor-input-wrapper"
                    hidden={!hasCustomRightScale()}
                >
                    <div className="stackplot-editor-number-range">
                        <Input
                            type="number"
                            name="rightMin"
                            className="stackplot-editor-input"
                            value={getRightMin()}
                            onChange={handleNumberInput}
                        />
                        <span>-</span>
                        <Input
                            type="number"
                            name="rightMax"
                            className="stackplot-editor-input"
                            value={getRightMax()}
                            onChange={handleNumberInput}
                        />
                    </div>
                </div>
                <div className="stackplot-editor-input-wrapper">
                    <ParameterSelect
                        chartConfig={chartConfig}
                        side={"R"}
                        updateParameters={updateParameters}
                    />
                </div>
            </div>
        </>
    );
}

function ParameterSelect({ chartConfig, side, updateParameters }) {
    const { siteParameterList } = useContext(StackPlotEditorContext);

    const getSelectedParameters = () => {
        if (!chartConfig?.parameters?.length) return [];
        return chartConfig.parameters.filter(
            (parameter) => parameter.side === side
        );
    };

    const setSelected = (sideParameters) => {
        updateParameters(sideParameters, side);
    };

    return (
        <MultiSelect
            className="stackplot-editor-parameter-select"
            data={siteParameterList}
            selected={getSelectedParameters()}
            setSelected={setSelected}
            textField={"parCode"}
            valField={"parCode"}
            hideSearch={true}
            SelectedComponent={SelectedParameter}
        />
    );
}

function SelectedParameter({ item, className, value, ...props }) {
    const { updateParameter } = useContext(StackPlotEditorContext);

    const getText = () => {
        return item?.parCode || "No ParCode";
    };

    const getColor = () => {
        return "#" + (item?.color || "000000");
    };

    const getType = () => {
        return item?.type || null;
    };

    const getSymbol = () => {
        return item?.symbol || null;
    };

    const handleColor = (event) => {
        const value = event.target.value.replace("#", "");
        const newParameter = { ...item, color: value };
        updateParameter(newParameter);
    };

    const handleType = (type, symbol = null) => {
        const newParameter = { ...item, type: type, symbol: symbol };
        updateParameter(newParameter);
    };

    return (
        <div
            className={
                "multiselect-option selected-parameter" +
                (className ? " " + className : "")
            }
            value={value}
            {...props}
        >
            <ParameterTypeToggle
                currentType={getType()}
                currentSymbol={getSymbol()}
                handleType={handleType}
            />
            <div className="selected-parameter-code">{getText()}</div>
            <input
                className="selected-parameter-color"
                type="color"
                value={getColor()}
                onChange={handleColor}
            />
        </div>
    );
}

function ParameterTypeToggle({ currentType, currentSymbol, handleType }) {
    const getCurrentType = () => {
        if (!currentType) return "";
        if (currentType === "point") {
            return currentSymbol;
        } else {
            return "L";
        }
    };

    const toggleType = () => {
        if (!currentType) {
            handleType("line");
        } else if (currentType === "line") {
            handleType("point", "X");
        } else {
            if (currentSymbol === "X") {
                handleType("point", "O");
            } else if (currentSymbol === "O") {
                handleType("point", "T");
            } else {
                handleType("line");
            }
        }
    };

    return (
        <div className="selected-parameter-type" onClick={toggleType}>
            {getCurrentType()}
        </div>
    );
}

export default StackPlotEditor;
