const icons = {
    Ozone: ozoneIcon,
    "Particulate Matter": pmIcon,
    "Particulate Matter (Low Cost Sensor)": pmIcon,
    Temperature: tempIcon,
    "Ambient Temperature": tempIcon,
    "Station Temperature": tempIcon,
    Precipitation: precipitationIcon,
    Wind: peakWindIcon,
    "Peak Wind Speed": peakWindIcon,
    "Relative Humidity": humidityIcon,
    "Solar Radiation": solarIcon,
    Visibility: visibilityIcon,
    "Barometric Pressure": barometerIcon,
    "Carbon Monoxide": carbonIcon,
    "Nitric Oxide": nitricIcon,
    "Nitrogen Dioxide": no2Icon,
    "Oxides of Nitrogen": noxIcon,
    "Nitrogen Dioxide plus Nitric Acid": noyIcon,
    "Sulfur Dioxide": so2Icon,
    dot: dotIcon,
    Compass: compassIcon,
    CompassPointer: compassPointerIcon,
};

function ozoneIcon(color) {
    return (
        <svg
            className="current-data-panel-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
        >
            <title>Ozone</title>
            <path
                fill={color}
                stroke={color}
                strokeMiterlimit={10}
                strokeWidth="0.45px"
                d="M15.09,30.5C7.47,30.5,2,24.82,2,17.79v-.07A12.77,12.77,0,0,1,15.16,4.95c7.62,0,13.09,5.68,13.09,12.7v.07A12.77,12.77,0,0,1,15.09,30.5Zm7.48-12.78A7.51,7.51,0,0,0,15.09,10a7.39,7.39,0,0,0-7.41,7.69v.07a7.51,7.51,0,0,0,7.48,7.76,7.39,7.39,0,0,0,7.41-7.69Z"
            />
            <path
                fill={color}
                d="M29.46,24.82V22.49h8.35v2l-3.12,3C36.38,27.8,38,28.68,38,31s-1.69,4.06-4.45,4.06a6,6,0,0,1-4.82-2.14l1.87-1.78a3.77,3.77,0,0,0,3,1.48,1.55,1.55,0,0,0,1.75-1.52c0-1-.86-1.59-2.42-1.59H31.79l-.42-1.73,2.95-3Z"
            />
            <rect fill="none" width="40" height="40" />
        </svg>
    );
}

function pmIcon(color) {
    return (
        <svg
            className="current-data-panel-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
        >
            <title>Particulate Matter</title>
            <rect fill="none" width="40" height="40" id="svg_3" y="40" x="68" />
            <text
                fill={color}
                stroke="null"
                strokeWidth="0"
                strokeOpacity="null"
                x="2"
                y="28.453125"
                id="svg_4"
                fontSize="23"
                fontFamily="Helvetica, Arial, sans-serif"
                textAnchor="start"
                fontWeight="bold"
            >
                PM
            </text>
        </svg>
    );
}

function tempIcon(color) {
    return (
        <svg
            className="current-data-panel-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
        >
            <title>Temperature</title>
            <rect fill="none" width="40" height="40" />
            <path
                fill={color}
                d="M29,26.34a8.06,8.06,0,0,0-.66-.59V5a5,5,0,0,0-10,0V25.76a8.1,8.1,0,0,0-.65.58,8,8,0,1,0,11.31,0ZM21.18,38a6.39,6.39,0,0,1,0-12,9.64,9.64,0,0,0-1.79,6A9.65,9.65,0,0,0,21.18,38Zm4.21-24h-4V5a2,2,0,1,1,4,0Z"
            />
            <path fill={color} d="M9.61,4a1,1,0,0,0,0,2h6V4Z" />
            <path fill={color} d="M12.61,10a1,1,0,0,0,0,2h3V10Z" />
            <path fill={color} d="M12.61,16a1,1,0,1,0,0,2h3V16Z" />
            <path fill={color} d="M9.61,22a1,1,0,0,0,0,2h6V22Z" />
        </svg>
    );
}

function precipitationIcon(color) {
    return (
        <svg
            className="current-data-panel-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
        >
            <title>Precipitation</title>
            <rect fill="none" width="40" height="40" />
            <path
                fill={color}
                d="M29.35,19.71h0L20,3,10.65,19.71h0a11.52,11.52,0,0,0-1.65,6A11.17,11.17,0,0,0,20,37,11.17,11.17,0,0,0,31,25.67,11.52,11.52,0,0,0,29.35,19.71ZM13.78,32.08A9.27,9.27,0,0,1,12,21.87a13.76,13.76,0,0,0,4.09,7.81c2.44,2.51,5.27,4,7.58,4.22A8.6,8.6,0,0,1,13.78,32.08Z"
            />
        </svg>
    );
}

function humidityIcon(color) {
    return (
        <svg
            className="current-data-panel-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
        >
            <title>Humidity</title>
            <rect fill="none" width="40" height="40" />
            <circle fill={color} cx="30" cy="20" r="3" />
            <circle fill={color} cx="20" cy="20" r="3" />
            <circle fill={color} cx="15" cy="29" r="2" />
            <circle fill={color} cx="25" cy="29" r="2" />
            <circle fill={color} cx="15" cy="10.99" r="2" />
            <circle fill={color} cx="25" cy="10.99" r="2" />
            <circle fill={color} cx="20" cy="3.99" r="1" />
            <circle fill={color} cx="20" cy="36.01" r="1" />
            <circle fill={color} cx="10" cy="20" r="3" />
        </svg>
    );
}

function peakWindIcon(color) {
    return (
        <svg
            className="current-data-panel-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
        >
            <title>Peak Wind Speed</title>
            <rect fill="none" width="40" height="40" />
            <path
                fill={color}
                d="M18.39,23.61H0V26H18.39a3.2,3.2,0,1,1-2.76,4.8H13a5.6,5.6,0,1,0,5.36-7.2Z"
            />
            <path
                fill={color}
                d="M26.4,5.2A5.6,5.6,0,0,0,21,9.2h2.61A3.19,3.19,0,1,1,26.4,14H0v2.4H26.4a5.6,5.6,0,0,0,0-11.2Z"
            />
            <path
                fill={color}
                d="M33.6,18.81H0v2.4H33.62A4,4,0,1,1,29.68,26H27.26a6.4,6.4,0,1,0,6.34-7.2Z"
            />
        </svg>
    );
}

function solarIcon(color) {
    return (
        <svg
            className="current-data-panel-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
        >
            <title>Solar Radiation</title>
            <rect fill="none" width="40" height="40" />
            <rect fill={color} x="19" y="1" width="2" height="38" />
            <rect
                fill={color}
                x="19"
                y="1"
                width="2"
                height="38"
                transform="translate(0 40) rotate(-90)"
            />
            <rect
                fill={color}
                x="19"
                y="1"
                width="2"
                height="38"
                transform="translate(-8.28 20) rotate(-45)"
            />
            <rect
                fill={color}
                x="19"
                y="1"
                width="2"
                height="38"
                transform="translate(20 48.28) rotate(-135)"
            />
            <rect
                fill={color}
                x="19.25"
                y="5"
                width="1.5"
                height="30"
                transform="translate(-6.13 9.18) rotate(-22.5)"
            />
            <rect
                fill={color}
                x="19.25"
                y="5"
                width="1.5"
                height="30"
                transform="translate(9.18 46.13) rotate(-112.5)"
            />
            <rect
                fill={color}
                x="19.25"
                y="5"
                width="1.5"
                height="30"
                transform="translate(-6.13 30.82) rotate(-67.5)"
            />
            <rect
                fill={color}
                x="19.25"
                y="5"
                width="1.5"
                height="30"
                transform="translate(30.82 46.13) rotate(-157.5)"
            />
            <circle fill={color} cx="20" cy="20" r="9" />
        </svg>
    );
}

function visibilityIcon(color) {
    return (
        <svg
            className="current-data-panel-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
        >
            <title>Visibility</title>
            <rect fill="none" width="40" height="40" />
            <path
                fill={color}
                d="M14.42,20A4.29,4.29,0,0,0,23,20H18.73V15.83h0A4.23,4.23,0,0,0,14.42,20Z"
            />
            <path
                fill={color}
                d="M.31,19.11a1.45,1.45,0,0,0,0,1.78A25.85,25.85,0,0,0,19.53,30c12.18-.05,20-8.93,20.1-9a1.44,1.44,0,0,0,0-1.93c-.13-.1-7.92-9-20.1-9A25.87,25.87,0,0,0,.31,19.11Zm9.9,5.74a24.42,24.42,0,0,1-3.07-1.79A24.27,24.27,0,0,1,3.81,20.3l-.3-.3.3-.3a24.44,24.44,0,0,1,6.39-4.55,9.31,9.31,0,0,0,0,9.7ZM26.46,14.1a26.6,26.6,0,0,1,6,2.87,28.59,28.59,0,0,1,3.66,2.79l.27.25-.27.25a28,28,0,0,1-9.64,5.66,9.35,9.35,0,0,0,0-11.81ZM25.53,20a6.84,6.84,0,0,1-13.67,0,6.84,6.84,0,0,1,13.67,0Z"
            />
        </svg>
    );
}

function barometerIcon(color) {
    return (
        <svg
            className="current-data-panel-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
        >
            <title>Barometric Pressure</title>
            <g>
                <circle
                    style={{ fill: "none", stroke: color, strokeWidth: 2 }}
                    id="path1"
                    cx="20"
                    cy="20"
                    r="16.941666"
                />
                <circle
                    style={{ fill: color, fillOpacity: 1, stroke: "none" }}
                    id="path2"
                    cx="20"
                    cy="20"
                    r="2"
                />
                <rect
                    style={{ fill: color, fillOpacity: 1, stroke: "none" }}
                    id="rect3"
                    width="1"
                    height="22"
                    x="27.782349"
                    y="-13.997105"
                    transform="rotate(45)"
                />
                <rect
                    style={{ fill: color, fillOpacity: 1, stroke: "none" }}
                    id="rect3-2"
                    width="1"
                    height="14"
                    x="13.636512"
                    y="10.493436"
                    transform="rotate(-15)"
                />
                <circle
                    style={{ fill: color, fillOpacity: 1, stroke: "none" }}
                    id="path3"
                    cx="28.282349"
                    cy="8.0028954"
                    r="1.5"
                    transform="rotate(45)"
                />
            </g>
        </svg>
    );
}

function carbonIcon(color) {
    return (
        <svg
            className="current-data-panel-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
        >
            <title>Carbon Monoxide</title>
            <g>
                <text
                    style={{
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "bold",
                        fontSize: "26px",
                        fontFamily: "Montserrat",
                        fill: color,
                    }}
                    x="-0.42855927"
                    y="28.893913"
                >
                    <tspan x="-0.42855927" y="28.893913">
                        CO
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

function nitricIcon(color) {
    return (
        <svg
            className="current-data-panel-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
        >
            <title>Nitric Oxide</title>
            <g>
                <text
                    style={{
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "bold",
                        fontSize: "26px",
                        fontFamily: "Montserrat",
                        fill: color,
                    }}
                    x="-2.2235043"
                    y="28"
                >
                    <tspan x="-2.2235043" y="28.893913">
                        NO
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

function no2Icon(color) {
    return (
        <svg
            className="current-data-panel-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
        >
            <title>Nitrogen Dioxide</title>
            <g>
                <text
                    style={{
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "bold",
                        fontSize: "20.6793px",
                        fontFamily: "Montserrat",
                        fill: color,
                    }}
                    x="-1.7163604"
                    y="25.293901"
                    id="text6"
                >
                    <tspan x="-1.7163604" y="25.293901" id="tspan7">
                        NO
                        <tspan
                            style={{
                                fontSize: "13.4415px",
                                baselineShift: "sub",
                            }}
                            id="tspan9"
                        >
                            2
                        </tspan>
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

function noxIcon(color) {
    return (
        <svg
            className="current-data-panel-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
        >
            <title>Oxides of Nitrogen</title>
            <g>
                <text
                    style={{
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "bold",
                        fontSize: "20px",
                        fontFamily: "Montserrat",
                        fill: color,
                    }}
                    x="-1.7"
                    y="24.762852"
                    id="text6"
                >
                    <tspan x="-1.7" y="24.762852" id="tspan7">
                        NO
                        <tspan
                            style={{ fontSize: "13px", baselineShift: "sub" }}
                            id="tspan9"
                        >
                            X
                        </tspan>
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

function noyIcon(color) {
    return (
        <svg
            className="current-data-panel-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
        >
            <title>Nitrogen Dioxide plus Nitric Acid</title>
            <g>
                <text
                    style={{
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "bold",
                        fontSize: "20px",
                        fontFamily: "Montserrat",
                        fill: color,
                    }}
                    x="-1.7"
                    y="24.762852"
                    id="text6"
                >
                    <tspan x="-1.7" y="24.762852" id="tspan7">
                        NO
                        <tspan
                            style={{ fontSize: "13px", baselineShift: "sub" }}
                            id="tspan9"
                        >
                            Y
                        </tspan>
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

function so2Icon(color) {
    return (
        <svg
            className="current-data-panel-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
        >
            <title>Sulfur Dioxide</title>
            <g>
                <text
                    style={{
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "bold",
                        fontSize: "20.6793px",
                        fontFamily: "Montserrat",
                        fill: color,
                    }}
                    x="1"
                    y="25.293901"
                    id="text6"
                >
                    <tspan x="1" y="25.293901" id="tspan7">
                        SO
                        <tspan
                            style={{
                                fontSize: "13.4415px",
                                baselineShift: "sub",
                            }}
                            id="tspan9"
                        >
                            2
                        </tspan>
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

function dotIcon(color, title = "Dot") {
    return (
        <svg
            className="current-data-dot-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
        >
            <title>{title}</title>
            <circle fill={color} cx="20" cy="20" r="10" />
        </svg>
    );
}

function compassIcon(color) {
    return (
        <svg
            className="current-data-panel-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
        >
            <title>Compass</title>
            <g id="layer1">
                <path
                    id="path1"
                    style={{
                        fill: "none",
                        stroke: color,
                    }}
                    d="M 38,20 A 18,18 0 0 1 20,38 18,18 0 0 1 2,20 18,18 0 0 1 20,2 18,18 0 0 1 38,20 Z"
                />
                <path
                    id="rect1"
                    style={{
                        stroke: "none",
                        fill: color,
                    }}
                    d="m 18,0 h 4 v 2 h -4 z"
                />
                <path
                    id="rect1-8"
                    style={{
                        stroke: "none",
                        fill: color,
                    }}
                    d="m 18,38 h 4 v 2 h -4 z"
                />
                <path
                    id="rect1-6"
                    style={{
                        stroke: "none",
                        fill: color,
                    }}
                    d="m 0,18 h 2 v 4 H 0 Z"
                />
                <path
                    id="rect1-6-7"
                    style={{
                        stroke: "none",
                        fill: color,
                    }}
                    d="m 38,18 h 2 v 4 h -2 z"
                />
                <path
                    id="rect1-5"
                    style={{
                        stroke: "none",
                        fill: color,
                    }}
                    transform="rotate(45)"
                    d="m 27.284271,-20 h 2 v 2 h -2 z"
                />
                <path
                    id="rect1-8-6"
                    style={{
                        stroke: "none",
                        fill: color,
                    }}
                    transform="rotate(45)"
                    d="m 27.284271,18 h 2 v 2 h -2 z"
                />
                <path
                    id="rect1-6-3"
                    style={{
                        stroke: "none",
                        fill: color,
                    }}
                    transform="rotate(45)"
                    d="M 8.2842712,-1 H 10.284271 V 1 H 8.2842712 Z"
                />
                <path
                    id="rect1-6-7-7"
                    style={{
                        stroke: "none",
                        fill: color,
                    }}
                    transform="rotate(45)"
                    d="m 46.284271,-1 h 2 v 2 h -2 z"
                />
            </g>
        </svg>
    );
}

function compassPointerIcon(color, rotation) {
    return (
        <svg
            className="current-data-panel-svg"
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                position: "absolute",
                top: "calc(25% - 10px)",
                rotate: rotation + "deg",
            }}
        >
            <title>Compass Pointer</title>
            <g id="layer1">
                <path
                    id="path2"
                    style={{
                        stroke: 1,
                        fill: color,
                    }}
                    d="m 22,20 a 2,2 0 0 1 -2,2 2,2 0 0 1 -2,-2 2,2 0 0 1 2,-2 2,2 0 0 1 2,2 z"
                />
                <path
                    style={{
                        fill: color,
                        stroke: "none",
                    }}
                    d="M 18,20 20,5.1507576 22,20 Z"
                    id="path4"
                />
            </g>
        </svg>
    );
}

export default icons;
