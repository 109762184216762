import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Label } from "reactstrap";

import { default as utilIcons } from '../../resources/utilIcons';

import { RestContext } from "../../App";
import { ADMIN_URL, SITE_URL, corsConfig } from "../../serverConfig";
import TelephoneInput from "../../components/TelephoneInput";
import { colors } from "../../styles/colors";

const opTypes = {
    "Primary Operator": 1,
    "Secondary Operator": 2,
    "Contact": 3
}

function ContactsAdmin({ selectedSite }) {
    const [contacts, setContacts] = useState([]);
    const [toDelete, setToDelete] = useState([]);
    const [changed, setChanged] = useState(new Set());

    const { sendGetRequest, sendPostRequest } = useContext(RestContext);

    useEffect(() => {
        if (selectedSite !== 'all') {
            loadContacts();
        } else {
            setContacts([]);
            setToDelete([]);
            changed.clear();
        }
    }, [selectedSite]);

    const loadContacts = () => {
        sendGetRequest(SITE_URL + '/contacts/' + selectedSite, (response) => {
            setContacts(response.data.sort(sortContacts));
            setToDelete([]);
            changed.clear();
        });
    }

    const sortContacts = (a, b) => {
        if (opTypes[a.opType] < opTypes[b.opType]) return -1;
        else if (opTypes[a.opType] > opTypes[b.opType]) return 1;
        else {
            if (a.admin && !b.admin) return -1;
            else if (b.admin && !a.admin) return 1;
            else {
                return a.name.localeCompare(b.name);
            }
        }
    }

    const handleChange = (index, event) => {
        const name = event.target.name;
        const value = event.target.value;
        const newContacts = [...contacts];
        newContacts[index][name] = value;
        setContacts(newContacts);
        changed.add(index);
    }

    const handleCheckbox = (index, event) => {
        const name = event.target.name;
        const newContacts = [...contacts];
        newContacts[index][name] = !newContacts[index][name];
        setContacts(newContacts);
        changed.add(index);
    }

    const handleDelete = (index) => {
        const contact = contacts[index];
        if (contact.contactId) {
            setToDelete([...toDelete, contact.contactId]);
        } else {
            let newContacts = [...contacts];
            delete newContacts[index];
            setContacts(newContacts);
        }
        changed.add(index);
    }

    const handleRestore = (index) => {
        setToDelete(toDelete.filter(id => id != contacts[index].contactId));
    }

    const mapContacts = () => {
        if (!contacts) return null;
        return contacts.map((contact, index) => {
            if (!contact) return null;
            return (
                <ContactRow
                    key={'contacts-admin-row-' + index}
                    index={index}
                    contact={contact}
                    handleChange={handleChange}
                    handleCheckbox={handleCheckbox}
                    handleDelete={handleDelete}
                    handleRestore={handleRestore}
                    toDelete={toDelete.includes(contact.contactId)}
                />
            )
        })
    }

    const addNewContact = () => {
        const newContact = {
            name: "",
            opType: "Primary Operator",
            admin: false,
            siteId: selectedSite
        };
        setContacts([...contacts, newContact]);
        changed.add(contacts.length - 1);
    }

    const validEmail = (email) => {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
    }

    const validPhone = (phone) => {
        return /^([0-9]+-)?[0-9]{3}-[0-9]{3}-[0-9]{4}(x[0-9]{1,4})?$/.test(phone);
    }

    const canSubmit = () => {
        const allHaveNames = !contacts.some(contact => contact && (!contact.name || contact.name.length === 0));
        const allHaveAtLeastOneContact = !contacts.some(contact => contact && (!contact.email || !validEmail(contact.email))
            && (!contact.cell || !validPhone(contact.cell))
            && (!contact.office || !validPhone(contact.office)));
        const atLeastOneChange = changed.size > 0;
        return allHaveNames && allHaveAtLeastOneContact && atLeastOneChange;
    }

    const handleSubmit = () => {
        if (!canSubmit()) return;

        let requestCount = 2;
        let contactsToSend = [];
        for (let index of changed) {
            if (contacts[index] && !toDelete.includes(contacts[index].contactId)) {
                contactsToSend.push(contacts[index]);
            }
        }
        const deleteFormData = new FormData();
        for (let contactId of toDelete) {
            deleteFormData.append('contactId', contactId);
        }

        if (contactsToSend.length > 0) {
            sendPostRequest(ADMIN_URL + '/contacts', contactsToSend, (response) => {
                requestCount -= 1;
                if (requestCount === 0) {
                    loadContacts();
                }
            }, (error) => {
                requestCount -= 1;
                if (requestCount === 0) {
                    loadContacts();
                }
            });
        } else {
            requestCount -= 1;
            if (requestCount === 0) {
                loadContacts();
            }
        }

        if (toDelete.length > 0) {
            sendPostRequest(ADMIN_URL + '/contacts/delete', deleteFormData, (response) => {
                requestCount -= 1;
                if (requestCount === 0) {
                    loadContacts();
                }
            }, (error) => {
                requestCount -= 1;
                if (requestCount === 0) {
                    loadContacts();
                }
            });
        } else {
            requestCount -= 1;
            if (requestCount === 0) {
                loadContacts();
            }
        }
    }

    return (
        <div id='contacts-admin'>
            <div id='contacts-admin-content'>
                {mapContacts()}
                <NoContacts hidden={contacts.length > 0} selectedSite={selectedSite} />
                <Button id='contacts-admin-new-contact' onClick={addNewContact} hidden={!selectedSite || selectedSite === 'all'}>Add new contact</Button>
            </div>
            <div id='contacts-admin-submit-wrapper' hidden={!selectedSite || selectedSite === 'all'}>
                <Button id='contacts-admin-submit' className="submit-button" onClick={handleSubmit} disabled={!canSubmit()}>Save</Button>
            </div>
        </div>
    )
}

function ContactRow({ contact, index, handleChange, handleCheckbox, handleDelete, handleRestore, toDelete }) {
    const handleInput = (event) => {
        handleChange(index, event);
    }

    const handleCheckboxInput = (event) => {
        handleCheckbox(index, event);
    }

    const getName = () => {
        if (!contact.name) return '';
        return contact.name + (toDelete ? ' (Deleted)' : '');
    }

    const getOpType = () => {
        if (!contact.opType) return '';
        return contact.opType;
    }

    const getAdmin = () => {
        if (contact.admin === undefined) return false;
        return contact.admin;
    }

    const getEmail = () => {
        if (!contact.email) return '';
        return contact.email;
    }

    const getCell = () => {
        if (!contact.cell) return '';
        return contact.cell;
    }

    const getOffice = () => {
        if (!contact.office) return '';
        return contact.office;
    }
    
    const getNotes = () => {
        if (!contact.notes) return '';
        return contact.notes;
    }

    const handleEditButton = (event) => {
        const input = document.getElementById('contacts-admin-row-name-' + index);
        input.focus();
    }

    const deleteButton = () => {
        if (toDelete) {
            return (
                <Button className="contacts-admin-row-header-button" onClick={() => handleRestore(index)}>{utilIcons.backArrow(colors['ars-neutral-500'], { height: '1.5rem', width: '1.5rem', flex: '0 0 auto' })}</Button>
            )
        } else {
            return (
                <Button className="contacts-admin-row-header-button" onClick={() => handleDelete(index)}>{utilIcons.trash(colors['ars-neutral-500'], { height: '1.5rem', width: '1.5rem', flex: '0 0 auto' })}</Button>
            )
        }
    }

    return (
        <div className={"contacts-admin-row" + (toDelete ? " ghost" : '')}>
            <div className="contacts-admin-row-mask" hidden={!toDelete} />
            <div className="contacts-admin-row-header">
                <Input name='name' id={'contacts-admin-row-name-' + index} className="contacts-admin-row-name" placeholder="Contact Name" value={getName()} onChange={handleInput} disabled={toDelete}/>
                <Button className="contacts-admin-row-header-button" onClick={handleEditButton} hidden={toDelete}>{utilIcons.pencil(colors['ars-neutral-500'], { height: '1.5rem', width: '1.5rem', flex: '0 0 auto' })}</Button>
                {deleteButton()}
            </div>

            <div className="contacts-admin-row-section-descriptors">
                <div className="contacts-admin-row-input-wrapper">
                    <Label for='opType' className="contacts-admin-row-contact-label">Contact Type</Label>
                    <select name='opType' className="contacts-admin-row-optype" value={getOpType()} onChange={handleInput} disabled={toDelete}>
                        <option>Primary Operator</option>
                        <option>Secondary Operator</option>
                        <option>Contact</option>
                    </select>
                </div>
                <div className="contacts-admin-row-checkbox-wrapper">
                    <Label for='admin' className="contacts-admin-row-contact-label">Administrative Contact</Label>
                    <Input name='admin' type='checkbox' className="contacts-admin-row-checkbox" checked={getAdmin()} onClick={handleCheckboxInput} disabled={toDelete} readOnly />
                </div>
            </div>
            <div className="contacts-admin-row-section-info">
                <div className="contacts-admin-row-input-wrapper wide">
                    <Label for='email' className="contacts-admin-row-contact-label">Email</Label>
                    <Input name='email' type="email" className="contacts-admin-row-contact-input" placeholder="e.g. example@gmail.com" value={getEmail()} onChange={handleInput} disabled={toDelete} />
                </div>
                <div className="contacts-admin-row-input-wrapper">
                    <Label for='cell' className="contacts-admin-row-contact-label">Cell Phone</Label>
                    <TelephoneInput name='cell' className="contacts-admin-row-contact-input" placeholder="e.g. (000) 123-4567" value={getCell()} onChange={handleInput} disabled={toDelete} />
                </div>
                <div className="contacts-admin-row-input-wrapper">
                    <Label for='office' className="contacts-admin-row-contact-label">Office Phone</Label>
                    <TelephoneInput name='office' className="contacts-admin-row-contact-input" placeholder="e.g. (000) 123-4567" value={getOffice()} onChange={handleInput} disabled={toDelete} />
                </div>
                <div className="contacts-admin-row-input-wrapper wide">
                    <Label for='notes' className="contacts-admin-row-contact-label">Notes</Label>
                    <Input name='notes' type="textarea" maxLength={100} className="contacts-admin-row-contact-input" value={getNotes()} onChange={handleInput} disabled={toDelete} />
                    <div className='contacts-admin-row-textarea-counter'>{getNotes().length + ' / 100'}</div>
                </div>
            </div>
        </div>
    )
}

function NoContacts({ hidden, selectedSite }) {
    return (
        <div id='contacts-admin-no-contacts' hidden={hidden}>{(!selectedSite || selectedSite === 'all') ? 'Select a site to administrate contacts.' : 'No contacts.'}</div>
    )
}

export default ContactsAdmin;