import { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";

import "../../styles/StackPlot.css";

import StackPlot from "./StackPlot";
import { data as dummyData, config as dummyConfig } from "./StackPlotDummy";
import { RestContext } from "../../App";
import { DATA_URL, STACKPLOT_URL } from "../../serverConfig";
import { checkMobile } from "../../util/globals";
import { Button, Label } from "reactstrap";

function StackPlots({ selectedSite }) {
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [config, setConfig] = useState();
    const [stackplotData, setStackplotData] = useState({});
    const [precision, setPrecision] = useState("hourly");
    const [configList, setConfigList] = useState([]);
    const [selectedConfig, setSelectedConfig] = useState();
    const [selectedOptions, setSelectedOptions] = useState({
        selectedSite: selectedSite,
        configId: null,
        precision: "hourly",
        datesToLoad: { minTimestamp: null, maxTimestamp: null },
        sameConfig: false,
    });
    const [loading, setLoading] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(1);
    const [dateRange, setDateRange] = useState({});

    const { sendGetRequest, sendPostRequest } = useContext(RestContext);

    const loadingOptions = useRef(loading);

    useEffect(() => {
        if (config) {
            console.log(config);
            const today = moment().hour(0).minute(0).second(0).millisecond(0);
            setSelectedOptions((values) => ({
                ...values,
                precision: config.precision || "hourly",
                configId: config.stackPlotId,
                datesToLoad: {
                    minTimestamp: moment(today)
                        .subtract(config.defaultSpan || 7, "day")
                        .unix(),
                    maxTimestamp: today.unix(),
                },
                sameConfig: false,
            }));
        }
    }, [config]);

    useEffect(() => {
        if (config && selectedOptions) {
            if (selectedSite === "all") {
                const responseData = dummyData;
                setColumns(responseData.columns);
                setData(responseData.data);
            } else {
                beginLoad();
            }
        }
    }, [selectedOptions]);

    useEffect(() => {
        if (selectedSite !== "all") {
            setSelectedOptions((values) => ({
                ...values,
                selectedSite: selectedSite,
            }));
            sendGetRequest(
                STACKPLOT_URL + "/list/" + selectedSite,
                (response) => {
                    setConfigList(response.data);
                    if (response.data && response.data.length) {
                        setSelectedConfig(response.data[0].stackPlotId);
                    } else {
                        setSelectedConfig();
                    }
                }
            );
        } else {
            setConfigList([]);
            setConfig(dummyConfig);
        }
    }, [selectedSite]);

    useEffect(() => {
        if (selectedConfig) {
            sendGetRequest(
                STACKPLOT_URL + "/config/" + selectedConfig,
                (response) => {
                    setConfig(response.data);
                }
            );
        }
    }, [selectedConfig]);

    useEffect(() => {
        if (loading) {
            loadInfo();
        } else {
            setLoadingProgress(0);
        }
    }, [loading]);

    useEffect(() => {
        setStackplotData(explodeData(data));
    }, [data, selectedOptions]);

    useEffect(() => {
        setDateRange({ min: getMinDate(), max: getMaxDate() });
    }, [stackplotData]);

    const beginLoad = () => {
        if (!selectedOptions || !config || !config.parCodes) return;
        const dataOptions = {
            selectedSite: selectedOptions.selectedSite,
            precision: selectedOptions.precision,
            sameConfig: selectedOptions.sameConfig,
            loadedMin: selectedOptions.sameConfig ? getLoadedMin() : null,
            loadedMax: selectedOptions.sameConfig ? getLoadedMax() : null,
            minTimestamp: selectedOptions.datesToLoad.minTimestamp,
            maxTimestamp: selectedOptions.datesToLoad.maxTimestamp,
            parCodes: config.parCodes,
        };
        if (!loading) {
            setLoading(dataOptions);
        }
    };

    const loadInfo = () => {
        if (!selectedOptions) return;
        loadingOptions.current = loading;
        if (loading) {
            if (
                loading.loadedMin &&
                loading.loadedMin < selectedOptions.datesToLoad.minTimestamp
            ) {
                setLoading(null);
                return;
            }
            const payload = {
                precision: loading.precision,
                minTimestamp: loading.minTimestamp,
                maxTimestamp: loading.maxTimestamp,
                loadedMin: loading.loadedMin,
                loadedMax: loading.loadedMax,
                parCodes: loading.parCodes,
            };
            sendPostRequest(
                `${DATA_URL}/stackplot/info/${loading.selectedSite}`,
                payload,
                (response) => {
                    const numPages = response.data.numPages;
                    const pageSize = response.data.pageSize;
                    setLoadingProgress(0);
                    if (numPages > 0) {
                        setColumns(response.data.columns);
                        if (loading.sameConfig) {
                            loadPage(1, numPages, pageSize, loading, data);
                        } else {
                            loadPage(1, numPages, pageSize, loading);
                        }
                    } else {
                        if (!loading.sameConfig) {
                            setColumns([]);
                            setData({});
                        }
                        setLoading(null);
                    }
                },
                (error) => {
                    setLoading(null);
                }
            );
        }
    };

    const loadPage = (
        pageNumber,
        numPages,
        pageSize,
        dataOptions,
        tempData = {}
    ) => {
        if (
            JSON.stringify(loadingOptions.current) ===
            JSON.stringify(dataOptions)
        ) {
            const payload = {
                precision: dataOptions.precision,
                page: pageNumber,
                pageSize: pageSize,
                numPages: numPages,
                loadedMin: dataOptions.loadedMin,
                loadedMax: dataOptions.loadedMax,
                minTimestamp: dataOptions.minTimestamp,
                maxTimestamp: dataOptions.maxTimestamp,
                parCodes: dataOptions.parCodes,
            };
            sendPostRequest(
                `${DATA_URL}/stackplot/${selectedSite}`,
                payload,
                (response) => {
                    if (
                        JSON.stringify(loadingOptions.current) ===
                        JSON.stringify(dataOptions)
                    ) {
                        const responseData = response.data;

                        tempData = { ...tempData, ...responseData.data };
                        setData(tempData);
                        setLoadingProgress(pageNumber / numPages);
                        if (pageNumber < numPages) {
                            loadPage(
                                pageNumber + 1,
                                numPages,
                                pageSize,
                                dataOptions,
                                tempData
                            );
                        } else {
                            setLoading(null);
                            if (!selectedOptions.sameConfig) {
                                setSelectedOptions((values) => ({
                                    ...values,
                                    sameConfig: true,
                                }));
                            }
                        }
                    }
                },
                (error) => {
                    setLoading(null);
                }
            );
        }
    };

    const getLoadedMin = () => {
        if (!data) return null;
        const timestamps = Object.keys(data);
        if (!timestamps.length) return null;
        return getMin(timestamps);
    };

    const getLoadedMax = () => {
        if (!data) return null;
        const timestamps = Object.keys(data);
        if (!timestamps.length) return null;
        return getMax(timestamps);
    };

    const getMinDate = () => {
        if (!stackplotData || !stackplotData._timestamps) return 0;
        return moment
            .unix(stackplotData._timestamps[0])
            .format("YYYY-MM-DDTHH:mm");
    };

    const getMaxDate = () => {
        if (!stackplotData || !stackplotData._timestamps) return 0;
        return moment
            .unix(
                stackplotData._timestamps[stackplotData._timestamps.length - 1]
            )
            .format("YYYY-MM-DDTHH:mm");
    };

    const getMin = (data) => {
        if (data.length === 0) return -Infinity;
        else if (data.length === 1) return data[0];
        else {
            let min = data[0];
            for (let i = 1; i < data.length; i++) {
                if (data[i] < min) min = data[i];
            }
            return min;
        }
    };

    const getMax = (data) => {
        if (data.length === 0) return Infinity;
        else if (data.length === 1) return data[0];
        else {
            let max = data[0];
            for (let i = 1; i < data.length; i++) {
                if (data[i] > max) max = data[i];
            }
            return max;
        }
    };

    const explodeData = (rawData) => {
        if (!rawData || !columns) return null;
        const explodedData = {};
        for (let i = 0; i < columns.length; i += 2) {
            explodedData[columns[i]] = {
                columnIndex: i,
            };
        }

        let minTimestamp = selectedOptions.datesToLoad.minTimestamp;
        let maxTimestamp = selectedOptions.datesToLoad.maxTimestamp;
        let timestamps = [];
        let interval = precision === "hourly" ? 3600 : 60;
        for (let i = 0; i <= (maxTimestamp - minTimestamp) / interval; i++) {
            timestamps.push(minTimestamp + i * interval);
        }

        for (let timestamp of timestamps) {
            let row = rawData[timestamp];
            if (row) {
                for (let parCode in explodedData) {
                    explodedData[parCode][timestamp] = [
                        row[explodedData[parCode].columnIndex],
                        row[explodedData[parCode].columnIndex + 1],
                    ];
                }
            }
        }
        explodedData._timestamps = timestamps;
        return explodedData;
    };

    const mapConfigsToOption = () => {
        if (!configList) return null;
        return configList.map((config, index) => (
            <option
                key={"stackplot-config-list-option-" + index}
                value={config.stackPlotId}
            >
                {config.plotName}
            </option>
        ));
    };

    const doXScroll = (scrollOptions) => {
        if (
            !dateRange ||
            dateRange.min === undefined ||
            dateRange.max === undefined ||
            !stackplotData ||
            !stackplotData._timestamps ||
            stackplotData._timestamps.length < 2
        )
            return;
        const { direction, quantity, unit } = scrollOptions;
        let offset = 0;
        switch (direction) {
            case "left":
                let dataMin = moment.unix(stackplotData._timestamps[0]);
                let currentMin = moment(dateRange.min, "YYYY-MM-DDTHH:mm");
                let targetMin = moment(currentMin).subtract(quantity, unit);
                if (targetMin.unix() < dataMin.unix()) {
                    offset = currentMin.unix() - dataMin.unix();
                } else {
                    offset = currentMin.unix() - targetMin.unix();
                }
                setDateRange({
                    min: currentMin
                        .subtract(offset, "seconds")
                        .format("YYYY-MM-DDTHH:mm"),
                    max: moment(dateRange.max, "YYYY-MM-DDTHH:mm")
                        .subtract(offset, "seconds")
                        .format("YYYY-MM-DDTHH:mm"),
                });
                return;
            case "right":
                let dataMax = moment.unix(
                    stackplotData._timestamps[
                        stackplotData._timestamps.length - 1
                    ]
                );
                let currentMax = moment(dateRange.max, "YYYY-MM-DDTHH:mm");
                let targetMax = moment(currentMax).add(quantity, unit);
                if (targetMax.unix() > dataMax.unix()) {
                    offset = dataMax.unix() - currentMax.unix();
                } else {
                    offset = targetMax.unix() - currentMax.unix();
                }
                setDateRange({
                    min: moment(dateRange.min, "YYYY-MM-DDTHH:mm")
                        .add(offset, "seconds")
                        .format("YYYY-MM-DDTHH:mm"),
                    max: currentMax
                        .add(offset, "seconds")
                        .format("YYYY-MM-DDTHH:mm"),
                });
                return;
            default:
                return;
        }
    };

    const updateTimeframe = (minTimestamp, maxTimestamp) => {
        setSelectedOptions((values) => ({
            ...values,
            datesToLoad: {
                minTimestamp: minTimestamp,
                maxTimestamp: maxTimestamp,
            },
        }));
    };

    const handleConfigSelect = (event) => {
        const value = event.target.value;
        setSelectedConfig(value);
    };

    const handleMinDate = (event) => {
        const value = event.target.value;
        setDateRange((values) => ({ ...values, min: value }));
    };

    const handleMaxDate = (event) => {
        const value = event.target.value;
        setDateRange((values) => ({ ...values, max: value }));
    };

    return (
        <div id="stackplots">
            <div id="stackplots-controls">
                <div className="stackplot-control-wrapper">
                    <Label>Select Configuration:</Label>
                    <select
                        id="stackplot-config-select"
                        onChange={handleConfigSelect}
                        disabled={!mapConfigsToOption()?.length}
                    >
                        {mapConfigsToOption()}
                    </select>
                </div>

                <div className="stackplot-control-wrapper">
                    <Label>Timeframe:</Label>
                    <StackPlotTimeFrameSelector
                        config={config}
                        updateTimeframe={updateTimeframe}
                    />
                </div>
                <div id="x-zoom-controls" className="stackplot-control-wrapper">
                    <Label>View:</Label>
                    <input
                        type="datetime-local"
                        onChange={handleMinDate}
                        min={getMinDate()}
                        max={dateRange.max || getMaxDate()}
                        step={
                            selectedOptions
                                ? selectedOptions.precision.match(/\d+-min/)
                                    ? 60
                                    : 3600
                                : 1
                        }
                        value={dateRange.min || ""}
                    />
                    <span>-</span>
                    <input
                        type="datetime-local"
                        onChange={handleMaxDate}
                        min={dateRange.min || getMinDate()}
                        max={getMaxDate()}
                        step={
                            selectedOptions
                                ? selectedOptions.precision.match(/\d+-min/)
                                    ? 60
                                    : 3600
                                : 1
                        }
                        value={dateRange.max || ""}
                    />
                    <DateScroller doXScroll={doXScroll} />
                </div>
            </div>
            <div id="stackplot-controls-help">
                <div className="stackplot-controls-help-blurb">
                    <b>Drag:</b> Pan X Axis (all charts)
                </div>
                <div className="stackplot-controls-help-blurb">
                    <b>Ctrl+Scroll:</b> Zoom X Axis (all charts)
                </div>
                <div className="stackplot-controls-help-blurb">
                    <b>Shift+Drag</b> Pan Y Axis (single chart)
                </div>
                <div className="stackplot-controls-help-blurb">
                    <b>Shift+Scroll:</b> Zoom Y Axis (single chart)
                </div>
            </div>
            <StackPlot
                data={stackplotData}
                config={config}
                dateRange={dateRange}
            />
            <LoadingScreen
                hidden={!loading}
                loadingProgress={loadingProgress}
                isMobile={checkMobile()}
            />
        </div>
    );
}

function StackPlotTimeFrameSelector({ config, updateTimeframe }) {
    const [selectedMode, setSelectedMode] = useState("last");
    const [numDays, setNumDays] = useState(7);
    const [dateRange, setDateRange] = useState({ min: null, max: null });

    useEffect(() => {
        init();
    }, [config]);

    useEffect(() => {
        setDateRange({
            min: moment()
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .set("millisecond", 0)
                .subtract(numDays, "day")
                .format("YYYY-MM-DDTHH:mm"),
            max: moment().format("YYYY-MM-DDTHH:mm"),
        });
    }, [numDays]);

    useEffect(() => {
        if (dateRange.min && dateRange.max) {
            updateTimeframe(
                moment(dateRange.min, "YYYY-MM-DDTHH:mm").unix(),
                moment(dateRange.max, "YYYY-MM-DDTHH:mm").unix()
            );
        }
    }, [dateRange]);

    const init = () => {
        setSelectedMode("last");

        setNumDays(config?.defaultSpan || 7);

        setDateRange({
            min: moment()
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .set("millisecond", 0)
                .add(-(config?.defaultSpan || 7), "day")
                .format("YYYY-MM-DDTHH:mm"),
            max: moment().format("YYYY-MM-DDTHH:mm"),
        });
    };

    const getControlsForMode = () => {
        switch (selectedMode) {
            case "last":
                return (
                    <>
                        <input
                            id="stackplot-timeframe-numdays-input"
                            type="number"
                            value={numDays}
                            min={1}
                            step={1}
                            onChange={handleNumDays}
                        />
                        <span>Days</span>
                    </>
                );
            case "from":
                return (
                    <>
                        <input
                            id="stackplot-timeframe-mindate-input"
                            type="datetime-local"
                            value={dateRange.min}
                            onChange={handleMinDate}
                            step={3600}
                        />
                        <span>to</span>
                        <input
                            id="stackplot-timeframe-maxdate-input"
                            type="datetime-local"
                            value={dateRange.max}
                            onChange={handleMaxDate}
                        />
                    </>
                );
            default:
                return null;
        }
    };

    const handleChangeMode = (event) => {
        const value = event.target.value;
        setSelectedMode(value);
        if (value === "last") {
            if (dateRange.min && dateRange.max) {
                const diff = Math.ceil(
                    moment(dateRange.max, "YYYY-MM-DDT-HH:mm").diff(
                        moment(dateRange.min, "YYYY-MM-DDT-HH:mm"),
                        "days"
                    )
                );
                setNumDays(diff);
            }
        } else {
            return;
        }
    };

    const handleNumDays = (event) => {
        setNumDays(Number(event.target.value));
    };

    const handleMinDate = (event) => {
        const value = event.target.value;
        setDateRange((values) => ({ ...values, min: value }));
    };

    const handleMaxDate = (event) => {
        const value = event.target.value;
        setDateRange((values) => ({ ...values, max: value }));
    };

    return (
        <div id="stackplot-timeframe-selector">
            <select value={selectedMode} onChange={handleChangeMode}>
                <option value="last">Last</option>
                <option value="from">From</option>
            </select>
            {getControlsForMode()}
        </div>
    );
}

function DateScroller({ doXScroll }) {
    const [quantity, setQuantity] = useState(1);
    const [unit, setUnit] = useState("days");

    const handleQuantity = (event) => {
        const value = Number(event.target.value);
        setQuantity(value);
    };

    const handleUnit = (event) => {
        const value = event.target.value;
        setUnit(value);
    };

    const handleMinus = () => {
        doXScroll({
            direction: "left",
            quantity,
            unit,
        });
    };

    const handlePlus = () => {
        doXScroll({
            direction: "right",
            quantity,
            unit,
        });
    };

    return (
        <div className="date-scroller">
            <Button onClick={handleMinus}>-</Button>
            <input
                className="time-quantity-input"
                type="number"
                step={1}
                value={quantity}
                onChange={handleQuantity}
            />
            <select
                className="time-unit-select"
                value={unit}
                onChange={handleUnit}
            >
                <option value="hours">Hour(s)</option>
                <option value="days">Day(s)</option>
                <option value="weeks">Week(s)</option>
                <option value="months">Month(s)</option>
            </select>
            <Button onClick={handlePlus}>+</Button>
        </div>
    );
}

function LoadingScreen({ hidden, loadingProgress = 0, isMobile = false }) {
    const getContent = () => {
        if (isMobile) {
            return (
                <div className="loading-wrapper">
                    <progress value={loadingProgress} />
                    <span>{Math.round(loadingProgress * 1000) / 10}%</span>
                </div>
            );
        }
    };

    return (
        <div
            className={"loading" + (isMobile ? " mobile" : "")}
            hidden={hidden}
        >
            {getContent()}
        </div>
    );
}

export default StackPlots;
