const precisions = {
    hourly: 24,
    "1-min": 24 * 60,
};

const parameters = {};

export function generateMockDataset(
    parameter,
    timespan = 3,
    precision = "hourly"
) {
    const dataset = [];
    const n = timespan * precisions[precision];
    const { m, v, s } = getParameterValues(parameter); // Each data point is more likely than not to move towards m by up to v units, s controls how quickly the probability scales with distance from m
    if (n > 0) {
        dataset.push(m);
    }
    for (let i = 1; i < n; i++) {
        let r1 = Math.random(),
            r2 = Math.random(v);
        let prev = dataset[i - 1];
        let deltaS = (prev - m) / s;
        if (r1 < 0.5 + 0.15 * deltaS) {
            r2 = -r2;
        }
        dataset.push(Math.round((prev + r2) * 10) / 10);
    }
    return dataset;
}

export function checkCustomParameter(parameter) {
    return parameter in parameters;
}

function getParameterValues(parameter) {
    if (!(parameter in parameters)) {
        return {
            m: 50, // Middle of dataset
            v: 2, // Maximum change between consecutive data points
            s: 12, // Size of a "standard deviation" (Note: resulting dataset will likely not actually have a standard deviation of this size)
        };
    } else {
        return parameters[parameter];
    }
}
