import React, { useContext, useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";

import { colors } from "../../styles/colors";

import { getUnitString } from "../../util/dashboardUtil";
import { getAQIRating } from "./AQI";
import { checkMobile } from "../../util/globals";
import { DashboardContext } from "./Dashboard";

function DashboardChart({ parameter }) {
    const [options, setOptions] = useState({});
    const [data, setData] = useState({});

    const ref = useRef(null);

    const { aqiColors, getAqiRange } = useContext(DashboardContext);

    useEffect(() => {
        if (parameter !== undefined) {
            generateDataObject(parameter);
            generateOptionsObject(parameter);
        }
    }, [parameter]);

    const generateDataObject = (parameter) => {
        const startDate = new Date(parameter.startDate).getTime();
        let labels;
        if (parameter.fields.length !== 0) {
            labels = parameter.fields[0].data.map((val, index) => {
                const tickDate = new Date(startDate + index * (1000 * 60 * 60));
                return tickDate.toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    month: "numeric",
                    day: "numeric",
                    year: "numeric",
                });
            });
        } else {
            labels = parameter.aqi.data.map((val, index) => {
                const tickDate = new Date(startDate + index * (1000 * 60 * 60));
                return tickDate.toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    month: "numeric",
                    day: "numeric",
                    year: "numeric",
                });
            });
        }

        const dataObj = {
            labels,
            datasets: [],
        };

        const lineColors = [
            colors["ars-neutral-200"],
            colors["ars-blue-400"],
            colors["ars-blue-700"],
            colors["ars-blue-900"],
        ];

        for (let index in parameter.fields) {
            const line = {
                type: "line",
                label: parameter.fields[index].fieldName,
                borderColor: lineColors[index],
                borderWidth: 4,
                backgroundColor: "transparent",
                fill: false,
                data: parameter.fields[index].data.map((val) =>
                    val === -99 || val === -999 ? null : val
                ),
                xAxisId: "x",
                yAxisID: "y",
                tension: 0.4,
            };

            dataObj.datasets.push(line);
        }

        if (parameter.aqi !== null) {
            const bar = {
                type: "bar",
                label: parameter.aqi.fieldName,
                backgroundColor: parameter.aqi.data.map((val) =>
                    val === -99 || val === -999
                        ? "transparent"
                        : "#" +
                          aqiColors[
                              getAQIRating(
                                  val,
                                  getAqiRange(parameter.aqi.aqiParameter)
                              )?.index
                          ]
                ),
                data: parameter.aqi.data.map((val) =>
                    val === -99 || val === -999 ? null : val
                ),
                xAxisId: "x",
                borderColor: "transparent",
                borderWidth: 0,
                yAxisID: "y1",
            };

            dataObj.datasets.push(bar);
        }

        setData(dataObj);
    };

    const generateOptionsObject = (parameter) => {
        const maxY = getMaxYValue(parameter);
        const minY = getMinYValue(parameter);

        const optionsObj = {
            responsive: true,
            interaction: {
                mode: "index",
                intersect: false,
            },
            plugins: {
                legend: {
                    display:
                        parameter.aqi !== null || parameter.fields.length > 1,
                    position: "bottom",
                },
                zoom: {
                    pan: {
                        enabled: true,
                        mode: "xy",
                    },
                    zoom: {
                        wheel: {
                            enabled: true,
                        },
                        pinch: {
                            enabled: true,
                        },
                        mode: "xy",
                    },
                },
            },
            scales: {
                x: {
                    min: 0,
                    max: checkMobile() ? (window.innerWidth - 40) / 32 : 128,
                },
            },
            elements: {
                point: {
                    radius: 0,
                },
            },
            maintainAspectRatio: false,
        };

        const yAxis = {
            title: {
                display: true,
                align: "center",
                text: getUnitString(parameter),
            },
            type: "linear",
            display: true,
            position: "left",
            min: minY,
            max: maxY,
            suggestedMin: 0,
            suggestedMax: 0,
        };

        optionsObj.scales["y"] = yAxis;

        if (parameter.aqi !== null) {
            const y1Axis = {
                title: {
                    display: true,
                    align: "center",
                    text: "NowCast AQI",
                },
                type: "linear",
                display: true,
                position: "right",
                grid: {
                    drawOnChartArea: false,
                },
                min: 0,
                max: getMaxAQIValue(parameter),
            };

            optionsObj.scales["y1"] = y1Axis;
        }

        optionsObj.plugins.zoom.pan.onPanStart = ({ chart, event }) => {
            if (event.srcEvent.shiftKey) {
                chart.options.plugins.zoom.pan.mode = "y";
                event.deltaX = 0;
            } else {
                chart.options.plugins.zoom.pan.mode = "x";
                event.deltaY = 0;
            }
        };

        optionsObj.plugins.zoom.zoom.onZoomStart = ({
            chart,
            event,
            point,
        }) => {
            if (event.shiftKey) {
                event.preventDefault();
                const speed =
                    1 +
                    (event.deltaY >= 0
                        ? -chart.options.plugins.zoom.zoom.wheel.speed
                        : chart.options.plugins.zoom.zoom.wheel.speed);
                chart.zoom({
                    x: 1,
                    y: speed,
                    focalPoint: point,
                });
                return false;
            } else {
                event.preventDefault();
                const speed =
                    1 +
                    (event.deltaY >= 0
                        ? -chart.options.plugins.zoom.zoom.wheel.speed
                        : chart.options.plugins.zoom.zoom.wheel.speed);
                chart.zoom({
                    x: speed,
                    y: 1,
                    focalPoint: point,
                });
                return false;
            }
        };

        setOptions(optionsObj);
    };

    const getMaxYValue = (parameter) => {
        if (parameter.fields.length !== 0) {
            switch (parameter.fields[0].unit) {
                case "%":
                    return 100;
                case "°":
                    return 360;
                default:
                    break;
            }
        } else {
            switch (parameter.aqi.unit) {
                case "%":
                    return 100;
                case "°":
                    return 360;
                default:
                    break;
            }
        }

        const maxY = Math.max(
            Math.max(
                ...parameter.fields.map((field) => Math.max(...field.data))
            ) * 1.2,
            0
        );

        return maxY === 0 && getMinYValue(parameter) === 0 ? 1 : maxY;
    };

    const getMaxAQIValue = (parameter) => {
        return Math.max(...parameter.aqi.data) * 1.2;
    };

    const getMinYValue = (parameter) => {
        const minY = Math.min(
            ...parameter.fields.map((field) =>
                Math.min(
                    ...field.data.map((val) =>
                        val === -99 || val === -999 ? null : val
                    )
                )
            ),
            0
        );
        return minY;
    };

    const getChart = () => {
        if (
            Object.keys(data).length === 0 ||
            Object.keys(options).length === 0
        ) {
            return null;
        } else {
            return <Chart type="bar" data={data} options={options} ref={ref} />;
        }
    };

    return <div className="dashboard-chart"><div className="dashboard-chart-controls-message" hidden={checkMobile()}><span>Shift + Scroll: Zoom Y</span><span>Shift + Drag: Pan Y</span></div>{getChart()}</div>;
}

export default DashboardChart;
