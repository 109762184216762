import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import './styles/ContactsReport.css';
import { default as utilIcons } from './resources/utilIcons';

import { ModalContext, RestContext } from './App';
import { UTIL_URL } from "./serverConfig";
import autoTable from "jspdf-autotable";
import { colors } from "./styles/colors";

const styles = {
    'th': {
        fontWeight: 700,
        border: '1px solid black',
        padding: '2px 4px',
    },
    'td': {
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
        verticalAlign: 'top',
        padding: '2px 4px',
        minWidth: '150px'
    },
    'tdSiteAbbr': {
        verticalAlign: 'top',
        padding: '2px 4px',
        border: '1px solid black',
        minWidth: '100px'
    },
    'tdFirst': {
        borderTop: '1px solid black',
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
        verticalAlign: 'top',
        padding: '2px 4px',
        minWidth: '150px',
    },
    'tdLast': {
        borderBottom: '1px solid black',
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
        verticalAlign: 'top',
        padding: '2px 4px',
        minWidth: '150px'
    },
    'page': {
        marginLeft: '5rem',
        marginRight: '5rem',
        maxWidth: '100%'
    }
}

function ContactsReport() {
    const [contactsData, setContactsData] = useState({});
    const [filteredData, setFilteredData] = useState({});
    const [selectedGroups, setSelectedGroups] = useState({});
    const { openModal, setOpenModal } = useContext(ModalContext);
    const { sendGetRequest } = useContext(RestContext);

    const reportRef = useRef(null);

    useEffect(() => {
        if (openModal === 'contacts-report') {
            sendGetRequest(UTIL_URL + '/contacts', (response) => {
                setContactsData(formatData(response.data));
            });
        }
    }, [openModal]);

    useEffect(() => {
        if (Object.keys(contactsData).length !== 0) {
            let newFilteredData = {};
            for (let agency in selectedGroups) {
                if (selectedGroups[agency]) {
                    newFilteredData[agency] = contactsData[agency];
                }
            }
            setFilteredData(newFilteredData);
        }
    }, [contactsData, selectedGroups]);

    const sortSites = (a, b) => {
        return a.siteAbbr.localeCompare(b.siteAbbr);
    }

    const formatData = (data) => {
        let formattedData = {};
        let newSelectedGroups = {};
        for (let site of data) {
            const agencyName = site.agencyName ? site.agencyName : 'Other';
            if (!(agencyName in formattedData)) {
                formattedData[agencyName] = [site];
                newSelectedGroups[agencyName] = true;
            } else {
                formattedData[agencyName].push(site);
            }
        }
        for (let abbr in formattedData) {
            formattedData[abbr].sort(sortSites);
        }
        setSelectedGroups(newSelectedGroups);
        return formattedData;
    }

    const toggle = () => {
        if (openModal === 'contacts-report') {
            setOpenModal();
        } else {
            setOpenModal('contacts-report');
        }
    }

    const getName = (contact) => {
        if (!contact || !contact.name) return '';
        return `${contact.name}${contact.opType === 'Primary Operator' ? '*' : ''}${contact.admin ? ' (AC)' : ''}`
    }

    const getEmail = (contact) => {
        if (!contact) return '';
        return contact.email ? contact.email : '';
    }

    const buildTable = () => {
        if (!contactsData) return null;
        return (
            <table id='contacts-report-table'>
                <thead>
                    <tr>
                        <th style={styles.th}>Site</th>
                        <th style={styles.th}>Contact Name</th>
                        <th style={styles.th}>Email</th>
                        <th style={styles.th}>Cell</th>
                        <th style={styles.th}>Office</th>
                    </tr>
                </thead>
                <tbody>
                    {mapDataToHtmlRows()}
                </tbody>
            </table>
        )
    }

    const sortAgencies = (a, b) => {
        if (a === 'Other') return 1;
        else if (b === 'Other') return -1;
        else return a.localeCompare(b);
    }

    const mapDataToHtmlRows = () => {
        let rows = [];
        let agencies = Object.keys(filteredData).sort(sortAgencies);
        for (let agency of agencies) {
            let agencyRows = [
                <tr><td className='agency-row' style={styles.tdFirst} colSpan={5}>{agency}</td></tr>
            ];
            const sites = filteredData[agency];
            for (let site of sites) {
                site.contacts.map((contact, index) => {
                    let cells = [];
                    let style = styles.td;
                    if (index === 0 && index === site.contacts.length - 1) {
                        style = {...styles.tdFirst, ...styles.tdLast};
                        cells.push(<td style={styles.tdSiteAbbr} rowSpan={site.contacts.length}>{site.siteAbbr}</td>);
                    } else if (index === 0) {
                        style = styles.tdFirst;
                        cells.push(<td style={styles.tdSiteAbbr} rowSpan={site.contacts.length}>{site.siteAbbr}</td>);
                    } else if (index === site.contacts.length - 1) {
                        style = styles.tdLast;
                    }
                    cells.push(<td style={style}>{getName(contact)}</td>);
                    cells.push(<td style={style}><a href={'mailto:' + getEmail(contact)}>{getEmail(contact)}</a></td>);
                    cells.push(<td style={style}>{contact.cell ? contact.cell : ''}</td>);
                    cells.push(<td style={style}>{contact.office ? contact.office : ''}</td>);
                    agencyRows.push(<tr>{cells}</tr>);
                });
            }
            rows = [...rows, ...agencyRows];
        }

        return rows;
    }

    const mapDataToPdfRows = () => {
        let rows = [];
        let agencies = [];
        for (let agency in filteredData) {
            let agencyRows = [
                [
                    { content: agency, colSpan: 5, styles: { fillColor: [147, 210, 219], textColor: 40, lineWidth: 1, lineColor: 40, fontSize: 10, fontStyle: 'bold' } }
                ]
            ];
            const sites = filteredData[agency];
            for (let site of sites) {
                site.contacts.map((contact, index) => {
                    let style = {};
                    let cells = [];
                    if (index === 0) {
                        cells.push({ content: site.siteAbbr, rowSpan: site.contacts.length, styles: { lineWidth: 1, lineColor: 40 } });
                        style = { lineWidth: { top: 1, right: 1 }, lineColor: 40 };
                    } else {
                        style = { lineWidth: { right: 1 }, lineColor: 40 };
                    }
                    cells.push({ content: getName(contact), styles: style });
                    cells.push({ content: (contact.email ? contact.email : ''), styles: { textColor: [0, 0, 238], ...style } });
                    cells.push({ content: (contact.cell ? contact.cell : ''), styles: style });
                    cells.push({ content: (contact.office ? contact.office : ''), styles: style });
                    agencyRows.push(cells);
                });
            }
            agencies.push(agencyRows);
        }

        agencies.sort((a, b) => sortAgencies(a[0][0].content, b[0][0].content));

        for (let agency of agencies) {
            rows = [...rows, ...agency];
        }

        return rows;
    }

    const getDate = () => {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const date = new Date();

        return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    }

    const generatePdf = () => {
        if (reportRef.current) {
            const doc = new jsPDF({
                format: 'letter',
                unit: 'px'
            });

            doc.setFontSize(14);

            doc.text(['Air Resource Specialists Contact List', getDate()], doc.internal.pageSize.width / 2, 20, {
                align: 'center'
            });

            autoTable(doc, {
                head: [
                    [
                        { content: 'Site', styles: { fillColor: [45, 84, 95], textColor: 255, lineWidth: 1, lineColor: 40 } },
                        { content: 'Contact Name', styles: { fillColor: [45, 84, 95], textColor: 255, lineWidth: 1, lineColor: 40 } },
                        { content: 'Email', styles: { fillColor: [45, 84, 95], textColor: 255, lineWidth: 1, lineColor: 40 } },
                        { content: 'Cell', styles: { fillColor: [45, 84, 95], textColor: 255, lineWidth: 1, lineColor: 40 } },
                        { content: 'Office', styles: { fillColor: [45, 84, 95], textColor: 255, lineWidth: 1, lineColor: 40 } },
                    ]
                ],
                body: mapDataToPdfRows(),
                foot: [
                    [{ content: '', colSpan: 5, styles: { halign: 'center', fillColor: false, textColor: 40, lineWidth: { top: 1 }, lineColor: 40 } }]
                ],
                headStyles: {
                    cellPadding: { vertical: 1, horizontal: 2 }
                },
                bodyStyles: {
                    fillColor: false,
                    textColor: 40,
                    fontSize: 8,
                    cellPadding: { vertical: 1, horizontal: 2 }
                },
                alternateRowStyles: {
                    fillColor: false
                },
                startY: 50
            });

            doc.setFontSize(11);

            for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
                doc.setPage(i);
                doc.text('ARS Phone: 970-484-7941        * Primary Operator       (AC) Administrative Contact',
                    doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10, { align: 'center' }
                );
                doc.text('Page ' + i, doc.internal.pageSize.width - 25, doc.internal.pageSize.height - 10, { align: 'center' });
            }

            const date = new Date();
            doc.save(`ARSContactsList_${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);
        }
    }

    const toggleGroup = (agency) => {
        const value = selectedGroups[agency];
        setSelectedGroups(values => ({...values, [agency]: !value}))
    }

    const mapGroups = () => {
        if (!selectedGroups) return null;
        return Object.keys(selectedGroups).sort(sortAgencies).map((agency, index) => 
            <div className="contacts-report-agency-toggle-wrapper" onClick={() => toggleGroup(agency)}>
                <div>{agency}</div>
                <Input type='checkbox' checked={selectedGroups[agency]} readOnly />
            </div>
        )
    }

    return (
        <Modal id='contacts-report-modal' isOpen={openModal === 'contacts-report'} toggle={toggle}>
            <ModalHeader toggle={toggle}>Contacts Report</ModalHeader>
            <ModalBody>
                <div id='contacts-report-main'>
                    <div id='contacts-report-table-wrapper' ref={reportRef}>
                        {buildTable()}
                    </div>
                    <div id='contacts-report-agency-select' hidden={Object.keys(selectedGroups) < 2}>
                        <div id='contacts-report-agency-header'>Agencies</div>
                        {mapGroups()}
                    </div>
                </div>
                <div id='contacts-report-legend'>
                    <div>ARS Phone: 970-484-7941</div>
                    <div>* Primary Operator</div>
                    <div>(AC) Administrative Contact</div>
                </div>
                <Button id='contacts-report-save' color="primary" onClick={generatePdf}>{'Download '}{utilIcons.download(colors['ars-neutral-900'], {width: '1em', height: '1em'})}</Button>
            </ModalBody>
        </Modal>
    )
}

export default ContactsReport;