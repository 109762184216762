import "../../styles/Data.css";
import StackPlotEditor from "./StackPlotEditor";
import StackPlots from "./StackPlots";

import StripChart from "./StripChart";
import Tables from "./Tables";

function Data({ selectedSite, openFeature }) {
    const renderOpenFeature = () => {
        switch (openFeature) {
            case "stripchart":
                return <StripChart selectedSite={selectedSite} />;
            case "stackplot-editor":
                return <StackPlotEditor selectedSite={selectedSite} />;
            case "stackplot":
                return <StackPlots selectedSite={selectedSite} />;
            case "tables":
                return <Tables />;
            default:
                return null;
        }
    };

    return <div id="data">{renderOpenFeature()}</div>;
}

export default Data;
