import "../../styles/Tables.css";

const TEST_DATA = `30,2/4/2025 23:45,O3,PREC,60.57,60.31,-0.26,-0.43
30,2/4/2025 23:45,O3,SPAN,190.08,189.82,-0.26,-0.14
30,2/4/2025 23:45,O3,ZERO,-0.06,0.31,0.37,
30,2/3/2025 23:45,O3,PREC,60.47,60.63,0.17,0.27
30,2/3/2025 23:45,O3,SPAN,190.03,189.45,-0.58,-0.3
30,2/3/2025 23:45,O3,ZERO,-0.21,0.39,0.6,
30,2/2/2025 23:45,O3,PREC,60.89,60.1,-0.8,-1.31
30,2/2/2025 23:45,O3,SPAN,191.01,190.22,-0.79,-0.41
30,2/2/2025 23:45,O3,ZERO,-0.2,0.16,0.35,
30,2/2/2025 4:46,O3,DIAG-PREC,30.53,30.59,0.06,0.19
30,2/1/2025 23:45,O3,PREC,60.6,60.79,0.19,0.32
30,2/1/2025 23:45,O3,SPAN,190.87,190.09,-0.78,-0.41
30,2/1/2025 23:45,O3,ZERO,-0.32,0.08,0.4,
30,1/31/2025 23:45,O3,PREC,59.95,59.46,-0.49,-0.82
30,1/31/2025 23:45,O3,SPAN,190.38,189.54,-0.84,-0.44
30,1/31/2025 23:45,O3,ZERO,-0.08,0.38,0.47,
30,1/30/2025 23:45,O3,PREC,60.31,59.52,-0.79,-1.32
30,1/30/2025 23:45,O3,SPAN,189.71,189.47,-0.24,-0.13
30,1/30/2025 23:45,O3,ZERO,0.04,-0.13,-0.16,
30,1/29/2025 23:45,O3,PREC,60.35,59.64,-0.71,-1.18`;

const COLUMNS = [
    "Site",
    "Timestamp",
    "Parameter",
    "Event",
    "Expected",
    "Indicated",
    "Diff.",
    "% Diff.",
];

function Tables({}) {
    return (
        <div id="data-tables">
            <CalibrationResultsTable data={formatTestData(TEST_DATA)} />
        </div>
    );
}

function CalibrationResultsTable({ data }) {
    const mapColumns = () => {
        return COLUMNS.map((column) => <th>{column}</th>);
    };

    const mapData = () => {
        if (!data) return null;
        return data.map((row) => {
            return (
                <tr>
                    {COLUMNS.map((column, index) => (
                        <td>{row[index]}</td>
                    ))}
                </tr>
            );
        });
    };

    return (
        <div id="cal-results">
            <h3>Calibration Results</h3>
            <table>
                <thead>
                    <tr>{mapColumns()}</tr>
                </thead>
                <tbody>{mapData()}</tbody>
            </table>
        </div>
    );
}

function formatTestData(data) {
    const rows = data.split("\n");
    for (let i = 0; i < rows.length; i++) {
        rows[i] = rows[i].split(",");
    }

    return rows;
}

export default Tables;
