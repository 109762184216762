import React, { useContext, useEffect, useState, useRef } from "react";
import { Spinner, UncontrolledTooltip } from 'reactstrap';

import { default as utilIcons } from '../../resources/utilIcons';

import { RestContext } from "../../App";
import { SITE_URL } from "../../serverConfig";
import { colors } from "../../styles/colors";

function DashboardContacts({ selectedSite }) {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(false);

    const { sendGetRequest } = useContext(RestContext);

    useEffect(() => {
        if (selectedSite && selectedSite !== 'all') {
            setLoading(true);
            sendGetRequest(SITE_URL + '/contacts/' + selectedSite, (response) => {
                setContacts(response.data.sort(sortContacts).slice(0, 2));
                setLoading(false);
            }, (error) => {
                setLoading(false);
            });
        }
    }, [selectedSite]);

    const sortContacts = (a, b) => {
        const opTypes = {
            "Primary Operator": 1,
            "Secondary Operator": 2,
            "Contact": 3
        }

        if (opTypes[a.opType] < opTypes[b.opType]) return -1;
        else if (opTypes[a.opType] > opTypes[b.opType]) return 1;
        else {
            if (a.admin && !b.admin) return -1;
            else if (b.admin && !a.admin) return 1;
            else {
                return a.name.localeCompare(b.name);
            }
        }
    }

    const getAvailableContactTypes = (contact) => {
        const contactTypes = {
            'Email': formatEmail(contact.email),
            'Office': formatPhone(contact.office),
            'Cell': formatPhone(contact.cell),
            'Notes': contact.notes
        };

        return Object.keys(contactTypes).map((type) => {
            if (!contactTypes[type] || contactTypes[type] === '') return null;
            return (
                <div className="dashboard-contacts-row-contact">
                    <span className="dashboard-contacts-row-contact-type">{type + ':'}</span>
                    <span className="dashboard-contacts-row-contact-value">{contactTypes[type]}</span>
                </div>
            )
        })
    }

    const formatEmail = (email) => {
        if (!email) return null;
        return (
            <EmailContact email={email} />
        )
    }

    const formatPhone = (phone) => {
        if (!phone) return null;
        const phoneString = phone.replace(/[\.]/g, '-');
        const callable = phoneString.replace(' ', '').replace('x', ',');
        return (
            <a href={"tel:" + callable}>{phoneString}</a>
        )
    }

    const mapContacts = () => {
        if (!contacts) return null;
        return contacts.map((contact, index) =>
            <div key={'dashboard-contact-' + index} className="dashboard-contacts-row">
                <div className="dashboard-contacts-row-label">
                    <div className="dashboard-contacts-row-name-wrapper">
                        <div className="dashboard-contacts-row-name">{contact.name + (contact.admin ? '*' : '')}</div>
                        <div className="dashboard-contacts-row-optype">{contact.opType}</div>
                    </div>

                    {utilIcons.user(colors['ars-blue-300'], { height: '2rem', width: '2rem' })}
                </div>
                <div className="dashboard-contacts-row-info">
                    {getAvailableContactTypes(contact)}
                </div>
            </div>
        )
    }

    const getLoading = () => {
        if (loading) {
            return <ContactsLoading />
        }
    }

    return (
        <div id='dashboard-contacts' className="dashboard-widget">
            {getLoading()}
            <div className="dashboard-widget-title">Contacts</div>
            <div id='dashboard-contacts-wrapper'>
                {mapContacts()}
            </div>
            <div id='dashboard-contacts-legend'>
                <div hidden={!contacts.some(contact => contact.admin)}>* Adminstrative Contact</div>
            </div>
            <NoContacts hidden={contacts.length > 0} />
        </div>
    )
}

function EmailContact({ email }) {
    const ref = useRef(null);

    return (
        <div className="dashboard-contacts-email-wrapper">
            <UncontrolledTooltip target={ref} autohide={false}>{email}</UncontrolledTooltip>
            <a href={"mailto:" + email} ref={ref}>{email}</a>
        </div>
    )
}

function NoContacts({ hidden }) {
    return (
        <div id='dashboard-contacts-no-contacts' className="dashboard-no-data" hidden={hidden}>
            No contacts found.
        </div>
    )
}

function ContactsLoading() {
    return (
        <div id='contacts-loading' className="dashboard-widget-loading">
            <Spinner color="light" />
        </div>
    )
}

export default DashboardContacts;