export const config = {
    plotName: "Minute Data", // configName
    fullTitle: "Test Config (Minute)",
    footnote: "For testing purpose only",
    precision: "1-min",
    chartSpacing: 20,
    defaultSpan: 7,
    charts: [
        {
            chartHeight: 200,
            hasRightSide: true,
            leftMin: 0,
            leftMax: 200,
            rightMin: -10,
            rightMax: 210,
            parameters: [
                {
                    parName: "O3-1",
                    parCode: "O3-1",
                    flagName: "O3-1_FLAG",
                    side: "L",
                    color: "0000FF",
                    unit: "ppb",
                    type: "line",
                    parameterOrder: 1,
                },
                {
                    parName: "O3-1CAL",
                    parCode: "O3-1CAL",
                    flagName: "O3-1CAL_FLAG",
                    side: "R",
                    color: "FF0000",
                    unit: "ppb",
                    type: "line",
                    parameterOrder: 2,
                },
            ],
        },
        {
            chartHeight: 200,
            hasRightSide: true,
            parameters: [
                {
                    parName: "NO",
                    parCode: "NO",
                    flagName: "NO_FLAG",
                    side: "L",
                    color: "0000FF",
                    unit: "ppb",
                    type: "line",
                    parameterOrder: 1,
                },
                {
                    parName: "NOY",
                    parCode: "NOY",
                    flagName: "NOY_FLAG",
                    side: "R",
                    color: "FF0000",
                    unit: "ppb",
                    type: "line",
                    parameterOrder: 2,
                },
            ],
        },
        {
            chartHeight: 200,
            parameters: [
                {
                    parName: "CO",
                    parCode: "CO",
                    flagName: "CO_FLAG",
                    side: "L",
                    color: "000000",
                    unit: "ppb",
                    type: "line",
                    parameterOrder: 1,
                },
            ],
        },
        {
            chartHeight: 200,
            parameters: [
                {
                    parName: "SO2",
                    parCode: "SO2",
                    flagName: "SO2_FLAG",
                    side: "L",
                    color: "000000",
                    unit: "ppb",
                    type: "line",
                    parameterOrder: 1,
                },
            ],
        },
        {
            chartHeight: 200,
            parameters: [
                {
                    parName: "VWD",
                    parCode: "VWD",
                    flagName: "VWD_FLAG",
                    side: "L",
                    color: "000000",
                    unit: "deg",
                    type: "line",
                    parameterOrder: 1,
                },
            ],
        },
        {
            chartHeight: 200,
            parameters: [
                {
                    parName: "VWS",
                    parCode: "VWS",
                    flagName: "VWS_FLAG",
                    side: "L",
                    color: "000000",
                    unit: "m/s",
                    type: "line",
                    parameterOrder: 1,
                },
            ],
        },
        {
            chartHeight: 200,
            parameters: [
                {
                    parName: "TMP",
                    parCode: "TMP",
                    flagName: "TMP_FLAG",
                    side: "L",
                    color: "000000",
                    unit: "degc",
                    type: "line",
                    parameterOrder: 1,
                },
            ],
        },
        {
            chartHeight: 200,
            parameters: [
                {
                    parName: "SOL",
                    parCode: "SOL",
                    flagName: "SOL_FLAG",
                    side: "L",
                    color: "000000",
                    unit: "w/m2",
                    type: "line",
                    parameterOrder: 1,
                },
            ],
        },
        {
            chartHeight: 200,
            parameters: [
                {
                    parName: "RNF",
                    parCode: "RNF",
                    flagName: "RNF_FLAG",
                    side: "L",
                    color: "000000",
                    unit: "mm",
                    type: "line",
                    parameterOrder: 1,
                },
            ],
        },
        {
            chartHeight: 200,
            parameters: [
                {
                    parName: "BP",
                    parCode: "BP",
                    flagName: "BP_FLAG",
                    side: "L",
                    color: "000000",
                    unit: "inhg",
                    type: "line",
                    parameterOrder: 1,
                },
            ],
        },
    ],
};

export const data = {
    columns: [
        "O3-1CALRAW",
        "O3-1CALRAW_FLAG",
        "O3-1CAL",
        "O3-1CAL_FLAG",
        "O3-1",
        "O3-1_FLAG",
        "NO",
        "NO_FLAG",
        "NODIFF",
        "NODIFF_FLAG",
        "NOY",
        "NOY_FLAG",
        "CO",
        "CO_FLAG",
        "SO2",
        "SO2_FLAG",
        "O3B-TMP",
        "O3B-TMP_FLAG",
        "VWD",
        "VWD_FLAG",
        "VWS",
        "VWS_FLAG",
        "TMP",
        "TMP_FLAG",
        "SOL",
        "SOL_FLAG",
        "RNF",
        "RNF_FLAG",
        "BP",
        "BP_FLAG",
        "PM25/H",
        "PM25/H_FLAG",
        "VOLTAGE",
        "VOLTAGE_FLAG",
    ],
    units: [
        "ppb",
        0,
        "ppb",
        0,
        "ppb",
        0,
        "ppb",
        0,
        "ppb",
        0,
        "ppb",
        0,
        "ppb",
        0,
        "ppb",
        0,
        "degc",
        0,
        "deg",
        0,
        "m/s",
        0,
        "dgc",
        0,
        "w/m2",
        0,
        "mm",
        0,
        "inhg",
        0,
        "ug/m3",
        0,
        "vac",
        0,
    ],
    data: {
        1727938980: [
            0.1662,
            0,
            0.1662,
            0,
            12.5815,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.99154,
            0,
            122.5855,
            0,
            0.2231,
            0,
            11.43404,
            0,
            -0.23839,
            0,
            0,
            0,
            29.26263,
            0,
            9.9957,
            "<D",
            124.4414,
            0,
        ],
        1727939040: [
            0.26118,
            0,
            0.26118,
            0,
            10.30023,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            106.578,
            0,
            0.21949,
            0,
            11.43827,
            0,
            -0.22845,
            0,
            0,
            0,
            29.26264,
            0,
            9.40822,
            "<D",
            124.2523,
            0,
        ],
        1727939100: [
            0.28977,
            0,
            0.28977,
            0,
            11.36479,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.00487,
            0,
            97.84502,
            0,
            0.19856,
            0,
            11.40867,
            0,
            -0.23839,
            0,
            0,
            0,
            29.26253,
            0,
            9.2105,
            "<D",
            124.2099,
            0,
        ],
        1727939160: [
            0.2557,
            0,
            0.2557,
            0,
            10.9374,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            108.8654,
            0,
            0.21567,
            0,
            11.41949,
            0,
            -0.22183,
            0,
            0,
            0,
            29.26261,
            0,
            5.93323,
            "<D",
            124.3166,
            0,
        ],
        1727939220: [
            0.10431,
            0,
            0.10431,
            0,
            12.85955,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            104.4103,
            0,
            0.19927,
            0,
            11.45133,
            0,
            -0.16555,
            0,
            0,
            0,
            29.26222,
            0,
            13.06041,
            "<D",
            124.3524,
            0,
        ],
        1727939280: [
            0.25609,
            0,
            0.25609,
            0,
            14.12207,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.00042,
            0,
            87.07563,
            0,
            0.21746,
            0,
            11.4205,
            0,
            -0.09602,
            0,
            0,
            0,
            29.26252,
            0,
            10.60868,
            "<D",
            124.3741,
            0,
        ],
        1727939340: [
            0.16,
            0,
            0.16,
            0,
            13.88123,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            77.35255,
            0,
            0.21471,
            0,
            11.37662,
            0,
            -0.10595,
            0,
            0,
            0,
            29.26253,
            0,
            7.5889,
            "<D",
            124.3897,
            0,
        ],
        1727939400: [
            0.21807,
            0,
            0.21807,
            0,
            12.71875,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            26.6182,
            0,
            0.22365,
            0,
            11.36601,
            0,
            -0.14568,
            0,
            0,
            0,
            29.26254,
            0,
            9.97822,
            "<D",
            124.3159,
            0,
        ],
        1727939460: [
            0.13738,
            0,
            0.13738,
            0,
            11.92771,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            29.22278,
            0,
            0.22347,
            0,
            11.1879,
            0,
            -0.08608,
            0,
            0,
            0,
            29.26252,
            0,
            7.93623,
            "<D",
            124.3781,
            0,
        ],
        1727939520: [
            0.09245,
            0,
            0.09245,
            0,
            10.53543,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            36.921,
            0,
            0.22373,
            0,
            11.09247,
            0,
            -0.11588,
            0,
            0,
            0,
            29.26253,
            0,
            9.19385,
            "<D",
            124.3785,
            0,
        ],
        1727939580: [
            0.17896,
            0,
            0.17896,
            0,
            8.19562,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            13.61433,
            0,
            0.21581,
            0,
            11.08278,
            0,
            -0.08277,
            0,
            0,
            0,
            29.26252,
            0,
            6.07678,
            "<D",
            124.1815,
            0,
        ],
        1727939640: [
            0.15063,
            0,
            0.15063,
            0,
            7.91191,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            360,
            0,
            0.22373,
            0,
            11.09413,
            0,
            -0.10926,
            0,
            0,
            0,
            29.26256,
            0,
            12.79282,
            "<D",
            124.3245,
            0,
        ],
        1727939700: [
            0.21538,
            0,
            0.21538,
            0,
            7.9209,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            2.11182,
            0,
            0.22328,
            0,
            11.05141,
            0,
            -0.06622,
            0,
            0,
            0,
            29.26252,
            0,
            10.66949,
            "<D",
            124.2187,
            0,
        ],
        1727939760: [
            0.29243,
            0,
            0.29243,
            0,
            8.1573,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.99598,
            0,
            8.7433,
            0,
            0.22373,
            0,
            11.05165,
            0,
            -0.07284,
            0,
            0,
            0,
            29.26281,
            0,
            8.17376,
            "<D",
            124.354,
            0,
        ],
        1727939820: [
            0.15637,
            0,
            0.15637,
            0,
            8.88942,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9785,
            0,
            8.66556,
            0,
            0.22373,
            0,
            10.93207,
            0,
            -0.04635,
            0,
            0,
            0,
            29.26405,
            0,
            10.39887,
            "<D",
            124.5517,
            0,
        ],
        1727939880: [
            0.26119,
            0,
            0.26119,
            0,
            8.72697,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            1.34596,
            0,
            0.22367,
            0,
            10.85268,
            0,
            -0.17217,
            0,
            0,
            0,
            29.26394,
            0,
            12.00978,
            "<D",
            124.3823,
            0,
        ],
        1727939940: [
            0.30747,
            0,
            0.30747,
            0,
            8.82032,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9398,
            0,
            0.04286,
            0,
            0.22372,
            0,
            10.88745,
            0,
            -0.23176,
            0,
            0,
            0,
            29.26402,
            0,
            8.90588,
            "<D",
            119.1689,
            0,
        ],
        1727940000: [
            0.21947,
            0,
            0.21947,
            0,
            8.75979,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            350.7498,
            0,
            0.22322,
            0,
            10.9499,
            0,
            -0.23508,
            0,
            0,
            0,
            29.26399,
            0,
            11.21519,
            "<D",
            117.1191,
            0,
        ],
        1727940060: [
            0.13583,
            0,
            0.13583,
            0,
            8.8779,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            348.3745,
            0,
            0.22373,
            0,
            10.98738,
            0,
            -0.1821,
            0,
            0,
            0,
            29.26482,
            0,
            9.9758,
            "<D",
            119.3694,
            0,
        ],
        1727940120: [
            0.19462,
            0,
            0.19462,
            0,
            8.58602,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8796,
            0,
            348.0804,
            0,
            0.22373,
            0,
            10.99982,
            0,
            -0.06291,
            0,
            0,
            0,
            29.26389,
            0,
            12.79877,
            "<D",
            117.9977,
            0,
        ],
        1727940180: [
            0.17925,
            0,
            0.17925,
            0,
            9.22282,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            350.3216,
            0,
            0.22362,
            0,
            11.00312,
            0,
            -0.11588,
            0,
            0,
            0,
            29.26497,
            0,
            12.90118,
            "<D",
            117.9508,
            0,
        ],
        1727940240: [
            0.1235,
            0,
            0.1235,
            0,
            9.87878,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            34.27572,
            0,
            0.20766,
            0,
            11.01413,
            0,
            -0.16885,
            0,
            0,
            0,
            29.26377,
            0,
            7.91761,
            "<D",
            118.2059,
            0,
        ],
        1727940300: [
            0.14535,
            0,
            0.14535,
            0,
            10.15811,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            53.16756,
            0,
            0.21736,
            0,
            11.05753,
            0,
            -0.21189,
            0,
            0,
            0,
            29.26332,
            0,
            11.62032,
            "<D",
            117.9557,
            0,
        ],
        1727940360: [
            0.24853,
            0,
            0.24853,
            0,
            10.01969,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            69.24148,
            0,
            0.21072,
            0,
            11.10784,
            0,
            -0.19203,
            0,
            0,
            0,
            29.2634,
            0,
            6.52278,
            "<D",
            117.5786,
            0,
        ],
        1727940420: [
            0.3367,
            0,
            0.3367,
            0,
            8.7194,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            87.20596,
            0,
            0.21698,
            0,
            11.13511,
            0,
            -0.08939,
            0,
            0,
            0,
            29.26286,
            0,
            9.47601,
            "<D",
            116.9278,
            0,
        ],
        1727940480: [
            0.11751,
            0,
            0.11751,
            0,
            6.47529,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            87.67454,
            0,
            0.21668,
            0,
            11.19303,
            0,
            -0.05959,
            0,
            0,
            0,
            29.26256,
            0,
            10.72416,
            "<D",
            115.9217,
            0,
        ],
        1727940540: [
            0.18738,
            0,
            0.18738,
            0,
            6.47888,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            89.92944,
            0,
            0.31434,
            0,
            11.21769,
            0,
            -0.0927,
            0,
            0,
            0,
            29.26241,
            0,
            6.97513,
            "<D",
            114.6251,
            0,
        ],
        1727940600: [
            0.04738,
            0,
            0.04738,
            0,
            7.06327,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            78.00417,
            0,
            0.53753,
            0,
            11.30355,
            0,
            -0.13574,
            0,
            0,
            0,
            29.26274,
            0,
            10.43267,
            "<D",
            114.4226,
            0,
        ],
        1727940660: [
            0.30687,
            0,
            0.30687,
            0,
            7.45189,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            67.6868,
            0,
            0.5478,
            0,
            11.33193,
            0,
            -0.20857,
            0,
            0,
            0,
            29.26254,
            0,
            9.29673,
            "<D",
            114.5137,
            0,
        ],
        1727940720: [
            0.2191,
            0,
            0.2191,
            0,
            6.65439,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            69.27438,
            0,
            0.51477,
            0,
            11.38068,
            0,
            -0.21851,
            0,
            0,
            0,
            29.26257,
            0,
            9.98099,
            "<D",
            114.1322,
            0,
        ],
        1727940780: [
            0.12855,
            0,
            0.12855,
            0,
            6.27007,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            61.11406,
            0,
            0.43746,
            0,
            11.44379,
            0,
            -0.23175,
            0,
            0,
            0,
            29.26262,
            0,
            11.45059,
            "<D",
            114.2215,
            0,
        ],
        1727940840: [
            0.26424,
            0,
            0.26424,
            0,
            6.35362,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            68.51292,
            0,
            0.44257,
            0,
            11.46983,
            0,
            -0.2152,
            0,
            0,
            0,
            29.26271,
            0,
            9.99972,
            "<D",
            114.2756,
            0,
        ],
        1727940900: [
            0.40426,
            0,
            0.40426,
            0,
            6.64756,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            61.44429,
            0,
            0.46126,
            0,
            11.40047,
            0,
            -0.23506,
            0,
            0,
            0,
            29.2626,
            0,
            10.3892,
            "<D",
            114.4041,
            0,
        ],
        1727940960: [
            0.13604,
            0,
            0.13604,
            0,
            6.59859,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            61.1133,
            0,
            0.37545,
            0,
            11.35408,
            0,
            -0.245,
            0,
            0,
            0,
            29.26263,
            0,
            7.91018,
            "<D",
            114.4468,
            0,
        ],
        1727941020: [
            0.36274,
            0,
            0.36274,
            0,
            6.94961,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            55.48594,
            0,
            0.3767,
            0,
            11.36841,
            0,
            -0.22845,
            0,
            0,
            0,
            29.26264,
            0,
            9.4586,
            "<D",
            114.5221,
            0,
        ],
        1727941080: [
            0.34051,
            0,
            0.34051,
            0,
            7.06463,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            52.7723,
            0,
            0.44388,
            0,
            11.38286,
            0,
            -0.23176,
            0,
            0,
            0,
            29.26265,
            0,
            9.54829,
            "<D",
            115.0089,
            0,
        ],
        1727941140: [
            0.23019,
            0,
            0.23019,
            0,
            8.02394,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            58.06867,
            0,
            0.47449,
            0,
            11.34566,
            0,
            -0.23507,
            0,
            0,
            0,
            29.26264,
            0,
            8.99796,
            "<D",
            114.5652,
            0,
        ],
        1727941200: [
            0.19773,
            0,
            0.19773,
            0,
            6.84451,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            63.21268,
            0,
            0.41717,
            0,
            11.32413,
            0,
            -0.2152,
            0,
            0,
            0,
            29.26265,
            0,
            7.04671,
            "<D",
            114.4163,
            0,
        ],
        1727941260: [
            0.22175,
            0,
            0.22175,
            0,
            7.65753,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            64.51502,
            0,
            0.41309,
            0,
            11.25419,
            0,
            -0.20196,
            0,
            0,
            0,
            29.26265,
            0,
            10.02402,
            "<D",
            114.0371,
            0,
        ],
        1727941320: [
            0.11128,
            0,
            0.11128,
            0,
            7.95819,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            57.13251,
            0,
            0.36724,
            0,
            11.22277,
            0,
            -0.19203,
            0,
            0,
            0,
            29.26265,
            0,
            9.85746,
            "<D",
            113.6889,
            0,
        ],
        1727941380: [
            0.12795,
            0,
            0.12795,
            0,
            7.76887,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            50.83276,
            0,
            0.26725,
            0,
            11.25115,
            0,
            -0.21189,
            0,
            0,
            0,
            29.26251,
            0,
            9.50476,
            "<D",
            114.0494,
            0,
        ],
        1727941440: [
            0.15386,
            0,
            0.15386,
            0,
            7.93655,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            42.89705,
            0,
            0.22371,
            0,
            11.25345,
            0,
            -0.19203,
            0,
            0,
            0,
            29.26243,
            0,
            12.13936,
            "<D",
            113.794,
            0,
        ],
        1727941500: [
            0.15315,
            0,
            0.15315,
            0,
            7.43062,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            54.4227,
            0,
            0.22214,
            0,
            11.26276,
            0,
            -0.20527,
            0,
            0,
            0,
            29.2622,
            0,
            8.2575,
            "<D",
            113.7857,
            0,
        ],
        1727941560: [
            0.07927,
            0,
            0.07927,
            0,
            6.67749,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            69.09341,
            0,
            0.22334,
            0,
            11.2739,
            0,
            -0.22513,
            0,
            0,
            0,
            29.26265,
            0,
            9.45611,
            "<D",
            113.9384,
            0,
        ],
        1727941620: [
            0.25671,
            0,
            0.25671,
            0,
            6.58616,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            76.94057,
            0,
            0.2215,
            0,
            11.1636,
            0,
            -0.23838,
            0,
            0,
            0,
            29.26242,
            0,
            10.96863,
            "<D",
            114.214,
            0,
        ],
        1727941680: [
            0.35021,
            0,
            0.35021,
            0,
            7.0821,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            76.8729,
            0,
            0.22273,
            0,
            11.16463,
            0,
            -0.23506,
            0,
            0,
            0,
            29.26263,
            0,
            9.49972,
            "<D",
            114.1488,
            0,
        ],
        1727941740: [
            0.31693,
            0,
            0.31693,
            0,
            6.7258,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            89.61345,
            0,
            0.22322,
            0,
            11.25856,
            0,
            -0.22513,
            0,
            0,
            0,
            29.26261,
            0,
            7.11196,
            "<D",
            114.0369,
            0,
        ],
        1727941800: [
            0.31512,
            0,
            0.31512,
            0,
            6.9,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            95.28671,
            0,
            0.22373,
            0,
            11.28244,
            0,
            -0.22844,
            0,
            0,
            0,
            29.26269,
            0,
            8.184,
            "<D",
            114.2072,
            0,
        ],
        1727941860: [
            0.2362,
            0,
            0.2362,
            0,
            6.82612,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            93.18013,
            0,
            0.22326,
            0,
            11.28798,
            0,
            -0.23175,
            0,
            0,
            0,
            29.26312,
            0,
            9.36402,
            "<D",
            114.1816,
            0,
        ],
        1727941920: [
            0.40074,
            0,
            0.40074,
            0,
            7.11035,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            79.25769,
            0,
            0.22368,
            0,
            11.25642,
            0,
            -0.1854,
            0,
            0,
            0,
            29.26318,
            0,
            11.66286,
            "<D",
            113.9157,
            0,
        ],
        1727941980: [
            0.20786,
            0,
            0.20786,
            0,
            6.98225,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            67.99662,
            0,
            0.22312,
            0,
            11.16554,
            0,
            -0.16222,
            0,
            0,
            0,
            29.26323,
            0,
            9.0116,
            "<D",
            114.1786,
            0,
        ],
        1727942040: [
            0.08537,
            0,
            0.08537,
            0,
            6.84735,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            63.96641,
            0,
            0.22373,
            0,
            10.98549,
            0,
            -0.14236,
            0,
            0,
            0,
            29.26365,
            0,
            7.43734,
            "<D",
            114.6501,
            0,
        ],
        1727942100: [
            0.22146,
            0,
            0.22146,
            0,
            6.31845,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            64.03541,
            0,
            0.22373,
            0,
            10.87851,
            0,
            -0.09601,
            0,
            0,
            0,
            29.26275,
            0,
            9.04151,
            "<D",
            114.462,
            0,
        ],
        1727942160: [
            0.24133,
            0,
            0.24133,
            0,
            6.17696,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            78.07536,
            0,
            0.22195,
            0,
            10.89779,
            0,
            -0.14898,
            0,
            0,
            0,
            29.26287,
            0,
            10.46023,
            "<D",
            114.4155,
            0,
        ],
        1727942220: [
            0.39058,
            0,
            0.39058,
            0,
            6.38277,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            83.46417,
            0,
            0.22373,
            0,
            10.88443,
            0,
            -0.10925,
            0,
            0,
            0,
            29.26463,
            0,
            12.28329,
            "<D",
            114.4177,
            0,
        ],
        1727942280: [
            0.27951,
            0,
            0.27951,
            0,
            6.21082,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            83.92587,
            0,
            0.22373,
            0,
            10.9838,
            0,
            -0.15891,
            0,
            0,
            0,
            29.2634,
            0,
            11.84476,
            "<D",
            114.188,
            0,
        ],
        1727942340: [
            0.27748,
            0,
            0.27748,
            0,
            6.06097,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            84.50681,
            0,
            0.22373,
            0,
            11.05326,
            0,
            -0.13574,
            0,
            0,
            0,
            29.26329,
            0,
            9.71723,
            "<D",
            114.3922,
            0,
        ],
        1727942400: [
            0.3045,
            0,
            0.3045,
            0,
            6.3706,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            84.44241,
            0,
            0.22373,
            0,
            11.07594,
            0,
            -0.09601,
            0,
            0,
            0,
            29.26441,
            0,
            8.90157,
            "<D",
            114.5192,
            0,
        ],
        1727942460: [
            0.27783,
            0,
            0.27783,
            0,
            6.16301,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            83.66733,
            0,
            0.22373,
            0,
            11.08516,
            0,
            -0.05628,
            0,
            0,
            0,
            29.26535,
            0,
            8.89462,
            "<D",
            114.6719,
            0,
        ],
        1727942520: [
            0.25643,
            0,
            0.25643,
            0,
            5.96627,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8624,
            0,
            83.53893,
            0,
            0.22373,
            0,
            11.03234,
            0,
            -0.06952,
            0,
            0,
            0,
            29.26375,
            0,
            10.19037,
            "<D",
            114.5248,
            0,
        ],
        1727942580: [
            0.19939,
            0,
            0.19939,
            0,
            5.93488,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            83.32377,
            0,
            0.22365,
            0,
            10.9529,
            0,
            -0.0629,
            0,
            0,
            0,
            29.26508,
            0,
            9.5873,
            "<D",
            114.3992,
            0,
        ],
        1727942640: [
            0.23847,
            0,
            0.23847,
            0,
            5.74381,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8624,
            0,
            72.10503,
            0,
            0.22351,
            0,
            10.98197,
            0,
            -0.04966,
            0,
            0,
            0,
            29.26476,
            0,
            9.91481,
            "<D",
            114.4205,
            0,
        ],
        1727942700: [
            0.20436,
            0,
            0.20436,
            0,
            5.38153,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            67.34966,
            0,
            0.22369,
            0,
            10.90353,
            0,
            -0.06952,
            0,
            0,
            0,
            29.26369,
            0,
            10.71992,
            "<D",
            114.1849,
            0,
        ],
        1727942760: [
            0.25611,
            0,
            0.25611,
            0,
            5.31291,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            71.00864,
            0,
            0.22282,
            0,
            10.97505,
            0,
            -0.07284,
            0,
            0,
            0,
            29.26545,
            0,
            10.45575,
            "<D",
            113.9635,
            0,
        ],
        1727942820: [
            0.25483,
            0,
            0.25483,
            0,
            5.31941,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            80.04657,
            0,
            0.22373,
            0,
            10.92142,
            0,
            -0.14899,
            0,
            0,
            0,
            29.26571,
            0,
            11.75618,
            "<D",
            114.4273,
            0,
        ],
        1727942880: [
            0.22148,
            0,
            0.22148,
            0,
            5.04631,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8796,
            0,
            70.94504,
            0,
            0.22321,
            0,
            10.83191,
            0,
            -0.13574,
            0,
            0,
            0,
            29.26594,
            0,
            11.67353,
            "<D",
            114.5611,
            0,
        ],
        1727942940: [
            0.13157,
            0,
            0.13157,
            0,
            5.0941,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            56.66553,
            0,
            0.22325,
            0,
            10.7477,
            0,
            -0.13574,
            0,
            0,
            0,
            29.26583,
            0,
            9.85535,
            "<D",
            114.825,
            0,
        ],
        1727943000: [
            0.22558,
            0,
            0.22558,
            0,
            5.12785,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            52.4723,
            0,
            0.2237,
            0,
            10.60062,
            0,
            -0.1523,
            0,
            0,
            0,
            29.26591,
            0,
            7.61139,
            "<D",
            114.8144,
            0,
        ],
        1727943060: [
            0.27841,
            0,
            0.27841,
            0,
            6.12775,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            54.94285,
            0,
            0.22373,
            0,
            10.49607,
            0,
            -0.18541,
            0,
            0,
            0,
            29.26579,
            0,
            8.98211,
            "<D",
            114.8573,
            0,
        ],
        1727943120: [
            0.53058,
            0,
            0.53058,
            0,
            6.4124,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            51.05843,
            0,
            0.22323,
            0,
            10.54082,
            0,
            -0.19865,
            0,
            0,
            0,
            29.26589,
            0,
            9.5894,
            "<D",
            115.1742,
            0,
        ],
        1727943180: [
            0.10835,
            0,
            0.10835,
            0,
            6.30281,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            60.5009,
            0,
            0.22188,
            0,
            10.63619,
            0,
            -0.22514,
            0,
            0,
            0,
            29.26588,
            0,
            9.59294,
            "<D",
            115.1684,
            0,
        ],
        1727943240: [
            0.20164,
            0,
            0.20164,
            0,
            7.04702,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            73.59959,
            0,
            0.22331,
            0,
            10.74484,
            0,
            -0.23838,
            0,
            0,
            0,
            29.26587,
            0,
            9.78115,
            "<D",
            115.029,
            0,
        ],
        1727943300: [
            0.08265,
            0,
            0.08265,
            0,
            7.41157,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            86.51625,
            0,
            0.2236,
            0,
            10.77716,
            0,
            -0.23838,
            0,
            0,
            0,
            29.26586,
            0,
            6.97961,
            "<D",
            115.0409,
            0,
        ],
        1727943360: [
            0.23241,
            0,
            0.23241,
            0,
            7.32,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            92.00315,
            0,
            0.22355,
            0,
            10.78198,
            0,
            -0.23176,
            0,
            0,
            0,
            29.26587,
            0,
            11.68548,
            "<D",
            115.2195,
            0,
        ],
        1727943420: [
            0.11135,
            0,
            0.11135,
            0,
            7.35971,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            95.68521,
            0,
            0.22354,
            0,
            10.76472,
            0,
            -0.22514,
            0,
            0,
            0,
            29.26587,
            0,
            9.96512,
            "<D",
            115.3883,
            0,
        ],
        1727943480: [
            0.18328,
            0,
            0.18328,
            0,
            7.60418,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.914,
            0,
            94.65249,
            0,
            0.22316,
            0,
            10.84401,
            0,
            -0.23176,
            0,
            0,
            0,
            29.26583,
            0,
            8.27999,
            "<D",
            115.8307,
            0,
        ],
        1727943540: [
            0.17932,
            0,
            0.17932,
            0,
            7.07626,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            88.68964,
            0,
            0.22283,
            0,
            11.05567,
            0,
            -0.23838,
            0,
            0,
            0,
            29.26585,
            0,
            11.13694,
            "<D",
            115.5605,
            0,
        ],
        1727943600: [
            0.194,
            0,
            0.194,
            0,
            6.2955,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            91.59866,
            0,
            0.22269,
            0,
            11.00596,
            0,
            -0.23507,
            0,
            0,
            0,
            29.26594,
            0,
            11.04087,
            "<D",
            115.7055,
            0,
        ],
        1727943660: [
            0.14074,
            0,
            0.14074,
            0,
            6.91698,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9312,
            0,
            93.22156,
            0,
            0.2224,
            0,
            10.91176,
            0,
            -0.33439,
            0,
            0,
            0,
            29.26611,
            0,
            10.49499,
            "<D",
            115.7394,
            0,
        ],
        1727943720: [
            0.26152,
            0,
            0.26152,
            0,
            5.63485,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            80.65387,
            0,
            0.22351,
            0,
            10.88495,
            0,
            -0.3046,
            0,
            0,
            0,
            29.26577,
            0,
            8.43399,
            "<D",
            115.8062,
            0,
        ],
        1727943780: [
            0.22119,
            0,
            0.22119,
            0,
            5.5993,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9312,
            0,
            77.18596,
            0,
            0.22331,
            0,
            10.87215,
            0,
            -0.28804,
            0,
            0,
            0,
            29.26612,
            0,
            10.60711,
            "<D",
            115.633,
            0,
        ],
        1727943840: [
            0.15888,
            0,
            0.15888,
            0,
            6.31236,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            66.92079,
            0,
            0.22368,
            0,
            10.90717,
            0,
            -0.24169,
            0,
            0,
            0,
            29.26658,
            0,
            11.79078,
            "<D",
            115.6564,
            0,
        ],
        1727943900: [
            0.26442,
            0,
            0.26442,
            0,
            5.40234,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            76.92183,
            0,
            0.22313,
            0,
            10.83611,
            0,
            -0.30128,
            0,
            0,
            0,
            29.26618,
            0,
            9.14718,
            "<D",
            115.1251,
            0,
        ],
        1727943960: [
            0.33538,
            0,
            0.33538,
            0,
            4.99414,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9527,
            0,
            88.62337,
            0,
            0.22359,
            0,
            10.77755,
            0,
            -0.33108,
            0,
            0,
            0,
            29.26603,
            0,
            7.88735,
            "<D",
            115.3914,
            0,
        ],
        1727944020: [
            0.25345,
            0,
            0.25345,
            0,
            5.12732,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.957,
            0,
            85.60203,
            0,
            0.22364,
            0,
            10.88426,
            0,
            -0.32777,
            0,
            0,
            0,
            29.26598,
            0,
            9.85214,
            "<D",
            115.6607,
            0,
        ],
        1727944080: [
            0.18835,
            0,
            0.18835,
            0,
            5.18619,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9527,
            0,
            83.87689,
            0,
            0.22371,
            0,
            10.90484,
            0,
            -0.28141,
            0,
            0,
            0,
            29.26649,
            0,
            9.36753,
            "<D",
            115.966,
            0,
        ],
        1727944140: [
            0.16149,
            0,
            0.16149,
            0,
            4.87699,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9484,
            0,
            73.83798,
            0,
            0.22272,
            0,
            10.90247,
            0,
            -0.28472,
            0,
            0,
            0,
            29.26633,
            0,
            11.6343,
            "<D",
            116.4338,
            0,
        ],
        1727944200: [
            0.17639,
            0,
            0.17639,
            0,
            4.61594,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9398,
            0,
            63.17465,
            0,
            0.22295,
            0,
            10.92051,
            0,
            -0.06953,
            0,
            0,
            0,
            29.26643,
            0,
            12.31925,
            "<D",
            116.397,
            0,
        ],
        1727944260: [
            0.18797,
            0,
            0.18797,
            0,
            4.77609,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            79.04651,
            0,
            0.22326,
            0,
            10.90979,
            0,
            -0.04966,
            0,
            0,
            0,
            29.26645,
            0,
            9.82001,
            "<D",
            117.0302,
            0,
        ],
        1727944320: [
            0.20588,
            0,
            0.20588,
            0,
            4.69402,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            93.29351,
            0,
            0.22326,
            0,
            10.95193,
            0,
            -0.05628,
            0,
            0,
            0,
            29.26657,
            0,
            8.52821,
            "<D",
            117.222,
            0,
        ],
        1727944380: [
            0.25931,
            0,
            0.25931,
            0,
            4.77738,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            94.18466,
            0,
            0.22373,
            0,
            10.9336,
            0,
            -0.06291,
            0,
            0,
            0,
            29.26655,
            0,
            9.89538,
            "<D",
            116.507,
            0,
        ],
        1727944440: [
            0.28748,
            0,
            0.28748,
            0,
            5.8137,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            95.78903,
            0,
            0.22362,
            0,
            10.85956,
            0,
            -0.07284,
            0,
            0,
            0,
            29.26645,
            0,
            8.82079,
            "<D",
            117.0947,
            0,
        ],
        1727944500: [
            0.30766,
            0,
            0.30766,
            0,
            5.54517,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9527,
            0,
            92.74411,
            0,
            0.22323,
            0,
            10.78434,
            0,
            -0.08939,
            0,
            0,
            0,
            29.26647,
            0,
            7.29272,
            "<D",
            116.8954,
            0,
        ],
        1727944560: [
            0.26255,
            0,
            0.26255,
            0,
            6.65953,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            88.58125,
            0,
            0.22365,
            0,
            10.85517,
            0,
            -0.11257,
            0,
            0,
            0,
            29.26626,
            0,
            9.90785,
            "<D",
            117.2937,
            0,
        ],
        1727944620: [
            0.08745,
            0,
            0.08745,
            0,
            6.54642,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            83.73547,
            0,
            0.22331,
            0,
            10.90282,
            0,
            -0.10926,
            0,
            0,
            0,
            29.26613,
            0,
            10.01651,
            "<D",
            117.4558,
            0,
        ],
        1727944680: [
            0.13072,
            0,
            0.13072,
            0,
            5.81364,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9699,
            0,
            77.57018,
            0,
            0.2237,
            0,
            10.92475,
            0,
            -0.13906,
            0,
            0,
            0,
            29.26601,
            0,
            8.84742,
            "<D",
            117.3068,
            0,
        ],
        1727944740: [
            0.24937,
            0,
            0.24937,
            0,
            5.40978,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9699,
            0,
            74.4809,
            0,
            0.22355,
            0,
            10.90772,
            0,
            -0.19203,
            0,
            0,
            0,
            29.26592,
            0,
            9.72116,
            "<D",
            117.715,
            0,
        ],
        1727944800: [
            0.23067,
            0,
            0.23067,
            0,
            5.59854,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            75.63785,
            0,
            0.22327,
            0,
            10.90305,
            0,
            -0.20197,
            0,
            0,
            0,
            29.26593,
            0,
            8.04106,
            "<D",
            117.5281,
            0,
        ],
        1727944860: [
            0.25265,
            0,
            0.25265,
            0,
            5.83633,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            79.63078,
            0,
            0.22373,
            0,
            10.89319,
            0,
            -0.17217,
            0,
            0,
            0,
            29.26599,
            0,
            9.61281,
            "<D",
            117.4134,
            0,
        ],
        1727944920: [
            0.12664,
            0,
            0.12664,
            0,
            5.97385,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            80.37533,
            0,
            0.22366,
            0,
            10.87947,
            0,
            -0.11257,
            0,
            0,
            0,
            29.26608,
            0,
            9.66232,
            "<D",
            118.0727,
            0,
        ],
        1727944980: [
            -0.01521,
            0,
            -0.01521,
            0,
            6.58346,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9527,
            0,
            76.79824,
            0,
            0.22327,
            0,
            10.86558,
            0,
            -0.15561,
            0,
            0,
            0,
            29.26616,
            0,
            9.32017,
            "<D",
            118.149,
            0,
        ],
        1727945040: [
            0.29373,
            0,
            0.29373,
            0,
            6.9251,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9656,
            0,
            76.54537,
            0,
            0.2235,
            0,
            10.88327,
            0,
            -0.16886,
            0,
            0,
            0,
            29.26604,
            0,
            10.4739,
            "<D",
            118.0971,
            0,
        ],
        1727945100: [
            0.22922,
            0,
            0.22922,
            0,
            7.71851,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            90.9975,
            0,
            0.22328,
            0,
            10.87969,
            0,
            -0.11588,
            0,
            0,
            0,
            29.26625,
            0,
            9.55539,
            "<D",
            118.3725,
            0,
        ],
        1727945160: [
            0.2445,
            0,
            0.2445,
            0,
            8.77637,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9699,
            0,
            108.1474,
            0,
            0.22343,
            0,
            10.88769,
            0,
            -0.22845,
            0,
            0,
            0,
            29.26601,
            0,
            9.62525,
            "<D",
            118.4029,
            0,
        ],
        1727945220: [
            0.10304,
            0,
            0.10304,
            0,
            8.17924,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9656,
            0,
            108.1469,
            0,
            0.22355,
            0,
            10.87599,
            0,
            -0.22514,
            0,
            0,
            0,
            29.26599,
            0,
            12.93826,
            "<D",
            118.2538,
            0,
        ],
        1727945280: [
            0.17123,
            0,
            0.17123,
            0,
            8.16738,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9785,
            0,
            106.7398,
            0,
            0.22365,
            0,
            10.86233,
            0,
            -0.21521,
            0,
            0,
            0,
            29.26591,
            0,
            10.17428,
            "<D",
            118.2671,
            0,
        ],
        1727945340: [
            0.33199,
            0,
            0.33199,
            0,
            7.44584,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            105.4258,
            0,
            0.22341,
            0,
            10.85704,
            0,
            -0.2119,
            0,
            0,
            0,
            29.26593,
            0,
            9.24778,
            "<D",
            117.5294,
            0,
        ],
        1727945400: [
            0.22449,
            0,
            0.22449,
            0,
            7.16901,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            93.30795,
            0,
            0.22528,
            0,
            10.82953,
            0,
            -0.22514,
            0,
            0,
            0,
            29.26592,
            0,
            9.58084,
            "<D",
            117.8861,
            0,
        ],
        1727945460: [
            0.17156,
            0,
            0.17156,
            0,
            9.08278,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9742,
            0,
            72.40278,
            0,
            0.2229,
            0,
            10.80132,
            0,
            -0.23176,
            0,
            0,
            0,
            29.26589,
            0,
            11.20353,
            "<D",
            118.4067,
            0,
        ],
        1727945520: [
            0.17901,
            0,
            0.17901,
            0,
            9.20729,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9785,
            0,
            91.25933,
            0,
            0.2213,
            0,
            10.82678,
            0,
            -0.23176,
            0,
            0,
            0,
            29.2659,
            0,
            7.04727,
            "<D",
            118.2952,
            0,
        ],
        1727945580: [
            0.23403,
            0,
            0.23403,
            0,
            9.12965,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            117.2025,
            0,
            0.48051,
            0,
            10.80936,
            0,
            -0.2119,
            0,
            0,
            0,
            29.26659,
            0,
            7.16332,
            "<D",
            118.5102,
            0,
        ],
        1727945640: [
            0.10274,
            0,
            0.10274,
            0,
            11.24575,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9828,
            0,
            121.9298,
            0,
            0.66502,
            0,
            10.82588,
            0,
            -0.19203,
            0,
            0,
            0,
            29.26687,
            0,
            11.05061,
            "<D",
            118.5224,
            0,
        ],
        1727945700: [
            0.18587,
            0,
            0.18587,
            0,
            11.01565,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            114.288,
            0,
            0.69451,
            0,
            10.86003,
            0,
            -0.22183,
            0,
            0,
            0,
            29.26778,
            0,
            7.78075,
            "<D",
            118.8306,
            0,
        ],
        1727945760: [
            0.32987,
            0,
            0.32987,
            0,
            10.05052,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            102.339,
            0,
            0.68479,
            0,
            10.87061,
            0,
            -0.21521,
            0,
            0,
            0,
            29.26893,
            0,
            10.29956,
            "<D",
            118.8082,
            0,
        ],
        1727945820: [
            0.16382,
            0,
            0.16382,
            0,
            11.74015,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            89.78678,
            0,
            0.55682,
            0,
            10.86481,
            0,
            -0.22845,
            0,
            0,
            0,
            29.26916,
            0,
            9.6374,
            "<D",
            118.4112,
            0,
        ],
        1727945880: [
            0.2053,
            0,
            0.2053,
            0,
            9.99313,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9828,
            0,
            88.41377,
            0,
            0.37612,
            0,
            10.86367,
            0,
            -0.23508,
            0,
            0,
            0,
            29.2695,
            0,
            9.95393,
            "<D",
            118.1232,
            0,
        ],
        1727945940: [
            0.15602,
            0,
            0.15602,
            0,
            9.87083,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            101.2795,
            0,
            0.45398,
            0,
            10.87937,
            0,
            -0.21852,
            0,
            0,
            0,
            29.27123,
            0,
            8.4328,
            "<D",
            118.035,
            0,
        ],
        1727946000: [
            0.30376,
            0,
            0.30376,
            0,
            10.30512,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9828,
            0,
            109.8894,
            0,
            0.49753,
            0,
            10.88192,
            0,
            -0.24501,
            0,
            0,
            0,
            29.27281,
            0,
            8.82848,
            "<D",
            118.5716,
            0,
        ],
        1727946060: [
            0.25469,
            0,
            0.25469,
            0,
            11.97538,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9828,
            0,
            105.0051,
            0,
            0.41593,
            0,
            10.91615,
            0,
            -0.2417,
            0,
            0,
            0,
            29.27292,
            0,
            11.55112,
            "<D",
            118.1901,
            0,
        ],
        1727946120: [
            0.05632,
            0,
            0.05632,
            0,
            11.95217,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.99598,
            0,
            102.5679,
            0,
            0.44887,
            0,
            10.92175,
            0,
            -0.22845,
            0,
            0,
            0,
            29.27194,
            0,
            10.91643,
            "<D",
            118.2015,
            0,
        ],
        1727946180: [
            0.08163,
            0,
            0.08163,
            0,
            11.83333,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9828,
            0,
            97.14365,
            0,
            0.46299,
            0,
            10.91503,
            0,
            -0.23839,
            0,
            0,
            0,
            29.27204,
            0,
            12.65793,
            "<D",
            118.2895,
            0,
        ],
        1727946240: [
            0.05109,
            0,
            0.05109,
            0,
            10.97862,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            97.134,
            0,
            0.46695,
            0,
            10.90598,
            0,
            -0.23508,
            0,
            0,
            0,
            29.27266,
            0,
            9.60942,
            "<D",
            118.2983,
            0,
        ],
        1727946300: [
            0.13044,
            0,
            0.13044,
            0,
            10.74951,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            93.367,
            0,
            0.46084,
            0,
            10.87158,
            0,
            -0.24501,
            0,
            0,
            0,
            29.27316,
            0,
            9.68847,
            "<D",
            117.8546,
            0,
        ],
        1727946360: [
            0.25423,
            0,
            0.25423,
            0,
            10.27989,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            90.68288,
            0,
            0.47539,
            0,
            10.88451,
            0,
            -0.24501,
            0,
            0,
            0,
            29.27317,
            0,
            13.28111,
            "<D",
            117.6164,
            0,
        ],
        1727946420: [
            0.22678,
            0,
            0.22678,
            0,
            9.58426,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            87.69963,
            0,
            0.45956,
            0,
            10.91491,
            0,
            -0.22845,
            0,
            0,
            0,
            29.27317,
            0,
            11.861,
            "<D",
            117.6738,
            0,
        ],
        1727946480: [
            0.21548,
            0,
            0.21548,
            0,
            8.30024,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            86.88525,
            0,
            0.39518,
            0,
            10.90063,
            0,
            -0.23839,
            0,
            0,
            0,
            29.27317,
            0,
            10.61861,
            "<D",
            117.0958,
            0,
        ],
        1727946540: [
            0.30882,
            0,
            0.30882,
            0,
            7.5191,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            84.70689,
            0,
            0.48007,
            0,
            10.89977,
            0,
            -0.23508,
            0,
            0,
            0,
            29.27317,
            0,
            10.29918,
            "<D",
            117.3414,
            0,
        ],
        1727946600: [
            0.24108,
            0,
            0.24108,
            0,
            7.9105,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            86.52022,
            0,
            0.48929,
            0,
            10.90911,
            0,
            -0.22845,
            0,
            0,
            0,
            29.27316,
            0,
            9.48731,
            "<D",
            117.515,
            0,
        ],
        1727946660: [
            0.22328,
            0,
            0.22328,
            0,
            8.31867,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            92.81681,
            0,
            0.48645,
            0,
            10.89515,
            0,
            -0.21852,
            0,
            0,
            0,
            29.27317,
            0,
            11.26453,
            "<D",
            118.2725,
            0,
        ],
        1727946720: [
            0.19352,
            0,
            0.19352,
            0,
            9.33082,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9828,
            0,
            92.58276,
            0,
            0.53479,
            0,
            10.85798,
            0,
            -0.23176,
            0,
            0,
            0,
            29.27317,
            0,
            10.52177,
            "<D",
            117.3217,
            0,
        ],
        1727946780: [
            0.28465,
            0,
            0.28465,
            0,
            10.33695,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            96.41656,
            0,
            0.53499,
            0,
            10.84969,
            0,
            -0.21521,
            0,
            0,
            0,
            29.27387,
            0,
            8.66179,
            "<D",
            117.2922,
            0,
        ],
        1727946840: [
            0.09219,
            0,
            0.09219,
            0,
            10.14058,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            90.80683,
            0,
            0.46353,
            0,
            10.86606,
            0,
            -0.23839,
            0,
            0,
            0,
            29.27491,
            0,
            9.75109,
            "<D",
            117.4968,
            0,
        ],
        1727946900: [
            0.2303,
            0,
            0.2303,
            0,
            10.3121,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            87.55912,
            0,
            0.42443,
            0,
            10.8768,
            0,
            -0.23176,
            0,
            0,
            0,
            29.27631,
            0,
            11.86091,
            "<D",
            117.3977,
            0,
        ],
        1727946960: [
            0.12915,
            0,
            0.12915,
            0,
            10.05194,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9742,
            0,
            59.22812,
            0,
            0.25896,
            0,
            10.87363,
            0,
            -0.22514,
            0,
            0,
            0,
            29.27641,
            0,
            9.84724,
            "<D",
            117.1778,
            0,
        ],
        1727947020: [
            0.26751,
            0,
            0.26751,
            0,
            9.72495,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9828,
            0,
            54.79621,
            0,
            0.22373,
            0,
            10.86476,
            0,
            -0.23508,
            0,
            0,
            0,
            29.2764,
            0,
            8.90688,
            "<D",
            117.5713,
            0,
        ],
        1727947080: [
            0.24213,
            0,
            0.24213,
            0,
            8.61897,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            54.93383,
            0,
            0.22373,
            0,
            10.88787,
            0,
            -0.23176,
            0,
            0,
            0,
            29.27644,
            0,
            10.4039,
            "<D",
            117.5248,
            0,
        ],
        1727947140: [
            0.08303,
            0,
            0.08303,
            0,
            6.44998,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            54.55289,
            0,
            0.22373,
            0,
            10.91072,
            0,
            -0.23176,
            0,
            0,
            0,
            29.27646,
            0,
            9.98863,
            "<D",
            117.6522,
            0,
        ],
        1727947200: [
            0.25882,
            0,
            0.25882,
            0,
            5.16456,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            55.95626,
            0,
            0.22359,
            0,
            10.91736,
            0,
            -0.22514,
            0,
            0,
            0,
            29.27639,
            0,
            8.51798,
            "<D",
            117.5686,
            0,
        ],
        1727947260: [
            0.21846,
            0,
            0.21846,
            0,
            5.13755,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9785,
            0,
            72.60262,
            0,
            0.22814,
            0,
            10.90624,
            0,
            -0.20528,
            0,
            0,
            0,
            29.27644,
            0,
            10.10906,
            "<D",
            117.4218,
            0,
        ],
        1727947320: [
            0.21802,
            0,
            0.21802,
            0,
            5.29676,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.99598,
            0,
            84.91625,
            0,
            0.22321,
            0,
            10.90599,
            0,
            -0.16886,
            0,
            0,
            0,
            29.27671,
            0,
            13.07172,
            "<D",
            117.4761,
            0,
        ],
        1727947380: [
            0.13198,
            0,
            0.13198,
            0,
            5.59599,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9828,
            0,
            91.47966,
            0,
            0.22355,
            0,
            10.90917,
            0,
            -0.1523,
            0,
            0,
            0,
            29.2766,
            0,
            8.07711,
            "<D",
            116.8638,
            0,
        ],
        1727947440: [
            0.14124,
            0,
            0.14124,
            0,
            6.56343,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9699,
            0,
            89.34789,
            0,
            0.22409,
            0,
            10.89419,
            0,
            -0.1225,
            0,
            0,
            0,
            29.27692,
            0,
            8.79289,
            "<D",
            116.3365,
            0,
        ],
        1727947500: [
            0.03276,
            0,
            0.03276,
            0,
            8.84831,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9742,
            0,
            79.52108,
            0,
            0.22279,
            0,
            10.89436,
            0,
            -0.08277,
            0,
            0,
            0,
            29.27698,
            0,
            7.67696,
            "<D",
            116.6862,
            0,
        ],
        1727947560: [
            0.20369,
            0,
            0.20369,
            0,
            8.46462,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9828,
            0,
            70.01101,
            0,
            0.22342,
            0,
            10.90295,
            0,
            -0.06953,
            0,
            0,
            0,
            29.27686,
            0,
            9.92331,
            "<D",
            116.4897,
            0,
        ],
        1727947620: [
            0.32386,
            0,
            0.32386,
            0,
            7.653,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9785,
            0,
            50.04306,
            0,
            0.22363,
            0,
            10.90407,
            0,
            -0.10264,
            0,
            0,
            0,
            29.27692,
            0,
            7.05647,
            "<D",
            116.3907,
            0,
        ],
        1727947680: [
            0.41974,
            0,
            0.41974,
            0,
            6.05217,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            43.38703,
            0,
            0.35716,
            0,
            10.8763,
            0,
            -0.0596,
            0,
            0,
            0,
            29.27703,
            0,
            13.47389,
            "<D",
            116.0108,
            0,
        ],
        1727947740: [
            0.40915,
            0,
            0.40915,
            0,
            5.43837,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            55.83321,
            0,
            0.40814,
            0,
            10.84184,
            0,
            -0.05629,
            0,
            0,
            0,
            29.27707,
            0,
            9.45234,
            "<D",
            115.9637,
            0,
        ],
        1727947800: [
            0.18779,
            0,
            0.18779,
            0,
            4.47225,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            66.51208,
            0,
            0.39642,
            0,
            10.71623,
            0,
            -0.0596,
            0,
            0,
            0,
            29.27707,
            0,
            9.91336,
            "<D",
            115.9696,
            0,
        ],
        1727947860: [
            0.27564,
            0,
            0.27564,
            0,
            4.85292,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9828,
            0,
            89.04395,
            0,
            0.49984,
            0,
            10.72312,
            0,
            -0.04966,
            0,
            0,
            0,
            29.27742,
            0,
            8.68069,
            "<D",
            116.0685,
            0,
        ],
        1727947920: [
            0.26994,
            0,
            0.26994,
            0,
            5.80091,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9742,
            0,
            88.16858,
            0,
            0.51362,
            0,
            10.75,
            0,
            -0.04635,
            0,
            0,
            0,
            29.27884,
            0,
            9.92937,
            "<D",
            115.8383,
            0,
        ],
        1727947980: [
            0.07543,
            0,
            0.07543,
            0,
            5.58208,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9699,
            0,
            80.36924,
            0,
            0.44652,
            0,
            10.80294,
            0,
            -0.05629,
            0,
            0,
            0,
            29.27886,
            0,
            11.32002,
            "<D",
            115.8845,
            0,
        ],
        1727948040: [
            0.07517,
            0,
            0.07517,
            0,
            6.37635,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9785,
            0,
            66.2707,
            0,
            0.44677,
            0,
            10.828,
            0,
            -0.06291,
            0,
            0,
            0,
            29.27991,
            0,
            8.53039,
            "<D",
            116.1639,
            0,
        ],
        1727948100: [
            0.25547,
            0,
            0.25547,
            0,
            5.72861,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9699,
            0,
            48.48158,
            0,
            0.51671,
            0,
            10.78715,
            0,
            -0.05297,
            0,
            0,
            0,
            29.27948,
            0,
            10.32078,
            "<D",
            115.9577,
            0,
        ],
        1727948160: [
            0.17154,
            0,
            0.17154,
            0,
            4.50743,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9785,
            0,
            53.71434,
            0,
            0.36536,
            0,
            10.83864,
            0,
            -0.0596,
            0,
            0,
            0,
            29.27986,
            0,
            7.87778,
            "<D",
            116.3701,
            0,
        ],
        1727948220: [
            0.3811,
            0,
            0.3811,
            0,
            4.19138,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9742,
            0,
            55.539,
            0,
            0.23016,
            0,
            10.84995,
            0,
            -0.0596,
            0,
            0,
            0,
            29.27991,
            0,
            6.81683,
            "<D",
            116.1941,
            0,
        ],
        1727948280: [
            0.37817,
            0,
            0.37817,
            0,
            4.35835,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9785,
            0,
            89.67754,
            0,
            0.40905,
            0,
            10.85857,
            0,
            -0.04635,
            0,
            0,
            0,
            29.28018,
            0,
            8.68457,
            "<D",
            115.9745,
            0,
        ],
        1727948340: [
            0.13077,
            0,
            0.13077,
            0,
            4.64342,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9785,
            0,
            87.53346,
            0,
            0.47019,
            0,
            10.8699,
            0,
            -0.09602,
            0,
            0,
            0,
            29.28028,
            0,
            12.79003,
            "<D",
            116.2163,
            0,
        ],
        1727948400: [
            0.24802,
            0,
            0.24802,
            0,
            5.89193,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            86.97517,
            0,
            0.47175,
            0,
            10.87553,
            0,
            -0.14568,
            0,
            0,
            0,
            29.27796,
            0,
            6.13793,
            "<D",
            115.6742,
            0,
        ],
        1727948460: [
            0.28559,
            0,
            0.28559,
            0,
            6.5933,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9785,
            0,
            87.85175,
            0,
            0.6112,
            0,
            10.87601,
            0,
            -0.24501,
            0,
            0,
            0,
            29.2789,
            0,
            9.848,
            "<D",
            115.8529,
            0,
        ],
        1727948520: [
            0.18111,
            0,
            0.18111,
            0,
            7.23871,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9785,
            0,
            92.08228,
            0,
            0.64212,
            0,
            10.86034,
            0,
            -0.23507,
            0,
            0,
            0,
            29.27953,
            0,
            8.33529,
            "<D",
            116.0102,
            0,
        ],
        1727948580: [
            0.18701,
            0,
            0.18701,
            0,
            7.09443,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9656,
            0,
            94.40358,
            0,
            0.60834,
            0,
            10.79923,
            0,
            -0.245,
            0,
            0,
            0,
            29.27942,
            0,
            8.35062,
            "<D",
            115.4775,
            0,
        ],
        1727948640: [
            0.32353,
            0,
            0.32353,
            0,
            7.07694,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9742,
            0,
            96.3732,
            0,
            0.58731,
            0,
            10.75334,
            0,
            -0.23176,
            0,
            0,
            0,
            29.27925,
            0,
            9.91002,
            "<D",
            114.7895,
            0,
        ],
        1727948700: [
            0.35686,
            0,
            0.35686,
            0,
            6.53683,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9699,
            0,
            95.84658,
            0,
            0.55005,
            0,
            10.70858,
            0,
            -0.245,
            0,
            0,
            0,
            29.27995,
            0,
            11.6642,
            "<D",
            115.2584,
            0,
        ],
        1727948760: [
            0.24628,
            0,
            0.24628,
            0,
            5.6553,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            97.9399,
            0,
            0.39183,
            0,
            10.70763,
            0,
            -0.22845,
            0,
            0,
            0,
            29.28034,
            0,
            9.68479,
            "<D",
            117.5179,
            0,
        ],
        1727948820: [
            0.10218,
            0,
            0.10218,
            0,
            5.77957,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            102.8165,
            0,
            0.30368,
            0,
            10.69262,
            0,
            -0.25163,
            0,
            0,
            0,
            29.28011,
            0,
            7.48705,
            "<D",
            123.824,
            0,
        ],
        1727948880: [
            0.30724,
            0,
            0.30724,
            0,
            5.79322,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9742,
            0,
            106.643,
            0,
            0.24136,
            0,
            10.71741,
            0,
            -0.23838,
            0,
            0,
            0,
            29.28034,
            0,
            11.24999,
            "<D",
            123.5661,
            0,
        ],
        1727948940: [
            0.1183,
            0,
            0.1183,
            0,
            6.32528,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            97.06023,
            0,
            0.22298,
            0,
            10.74077,
            0,
            -0.15892,
            0,
            0,
            0,
            29.28034,
            0,
            10.91385,
            "<D",
            123.6807,
            0,
        ],
        1727949000: [
            0.25173,
            0,
            0.25173,
            0,
            5.27596,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            84.58871,
            0,
            0.22373,
            0,
            10.75766,
            0,
            -0.06622,
            0,
            0,
            0,
            29.28035,
            0,
            8.96624,
            "<D",
            123.7128,
            0,
        ],
        1727949060: [
            0.26108,
            0,
            0.26108,
            0,
            5.00649,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9699,
            0,
            84.51867,
            0,
            0.22373,
            0,
            10.76767,
            0,
            -0.05629,
            0,
            0,
            0,
            29.28034,
            0,
            10.40367,
            "<D",
            123.6878,
            0,
        ],
        1727949120: [
            0.36152,
            0,
            0.36152,
            0,
            5.29879,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9527,
            0,
            84.50613,
            0,
            0.22373,
            0,
            10.76137,
            0,
            -0.05298,
            0,
            0,
            0,
            29.28034,
            0,
            10.57656,
            "<D",
            123.6479,
            0,
        ],
        1727949180: [
            0.06518,
            0,
            0.06518,
            0,
            5.30144,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            84.48183,
            0,
            0.22373,
            0,
            10.75586,
            0,
            -0.05629,
            0,
            0,
            0,
            29.28083,
            0,
            8.66909,
            "<D",
            123.7122,
            0,
        ],
        1727949240: [
            0.13699,
            0,
            0.13699,
            0,
            4.39229,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            84.54082,
            0,
            0.22373,
            0,
            10.7452,
            0,
            -0.04304,
            0,
            0,
            0,
            29.28131,
            0,
            9.35091,
            "<D",
            123.5273,
            0,
        ],
        1727949300: [
            0.3383,
            0,
            0.3383,
            0,
            4.69833,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            84.56831,
            0,
            0.22373,
            0,
            10.72025,
            0,
            -0.04635,
            0,
            0,
            0,
            29.28185,
            0,
            9.0082,
            "<D",
            123.6408,
            0,
        ],
        1727949360: [
            0.39535,
            0,
            0.39535,
            0,
            5.43742,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            68.43247,
            0,
            0.21826,
            0,
            10.65045,
            0,
            -0.03973,
            0,
            0,
            0,
            29.28191,
            0,
            12.94932,
            "<D",
            123.5088,
            0,
        ],
        1727949420: [
            0.20006,
            0,
            0.20006,
            0,
            5.24726,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9527,
            0,
            51.56824,
            0,
            0.22373,
            0,
            10.47227,
            0,
            -0.05629,
            0,
            0,
            0,
            29.28057,
            0,
            10.90497,
            "<D",
            123.4331,
            0,
        ],
        1727949480: [
            0.3509,
            0,
            0.3509,
            0,
            5.33981,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            51.56006,
            0,
            0.22373,
            0,
            10.46914,
            0,
            -0.06953,
            0,
            0,
            0,
            29.28031,
            0,
            9.68506,
            "<D",
            123.618,
            0,
        ],
        1727949540: [
            0.30732,
            0,
            0.30732,
            0,
            4.80634,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.957,
            0,
            51.60485,
            0,
            0.22373,
            0,
            10.55659,
            0,
            -0.06291,
            0,
            0,
            0,
            29.28056,
            0,
            9.96288,
            "<D",
            123.5229,
            0,
        ],
        1727949600: [
            0.24626,
            0,
            0.24626,
            0,
            3.91855,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9484,
            0,
            56.37273,
            0,
            0.22372,
            0,
            10.60119,
            0,
            -0.07284,
            0,
            0,
            0,
            29.28122,
            0,
            10.95111,
            "<D",
            123.4098,
            0,
        ],
        1727949660: [
            0.29253,
            0,
            0.29253,
            0,
            4.20267,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9527,
            0,
            79.72607,
            0,
            0.246,
            0,
            10.64534,
            0,
            -0.07946,
            0,
            0,
            0,
            29.28223,
            0,
            8.39099,
            "<D",
            123.468,
            0,
        ],
        1727949720: [
            0.22593,
            0,
            0.22593,
            0,
            3.5617,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            72.56913,
            0,
            0.22756,
            0,
            10.65061,
            0,
            -0.05629,
            0,
            0,
            0,
            29.28323,
            0,
            11.74928,
            "<D",
            123.4637,
            0,
        ],
        1727949780: [
            0.31326,
            0,
            0.31326,
            0,
            3.62009,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9484,
            0,
            54.89938,
            0,
            0.22373,
            0,
            10.64502,
            0,
            -0.1225,
            0,
            0,
            0,
            29.2836,
            0,
            8.23969,
            "<D",
            123.4743,
            0,
        ],
        1727949840: [
            0.27118,
            0,
            0.27118,
            0,
            3.07574,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9527,
            0,
            57.22715,
            0,
            0.22344,
            0,
            10.64115,
            0,
            -0.14568,
            0,
            0,
            0,
            29.28364,
            0,
            11.59859,
            "<D",
            123.4194,
            0,
        ],
        1727949900: [
            0.08512,
            0,
            0.08512,
            0,
            3.22499,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.957,
            0,
            74.15381,
            0,
            0.22239,
            0,
            10.64589,
            0,
            -0.04966,
            0,
            0,
            0,
            29.2836,
            0,
            8.87594,
            "<D",
            123.1611,
            0,
        ],
        1727949960: [
            0.13603,
            0,
            0.13603,
            0,
            2.99353,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            78.90078,
            0,
            0.22337,
            0,
            10.57452,
            0,
            -0.04304,
            0,
            0,
            0,
            29.2848,
            0,
            11.02601,
            "<D",
            123.1919,
            0,
        ],
        1727950020: [
            0.23475,
            0,
            0.23475,
            0,
            3.14953,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            75.02095,
            0,
            0.22373,
            0,
            10.59363,
            0,
            -0.05297,
            0,
            0,
            0,
            29.28464,
            0,
            9.57313,
            "<D",
            123.3009,
            0,
        ],
        1727950080: [
            0.0294,
            0,
            0.0294,
            0,
            3.6016,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9441,
            0,
            72.77331,
            0,
            0.22365,
            0,
            10.60408,
            0,
            -0.05297,
            0,
            0,
            0,
            29.28715,
            0,
            10.80554,
            "<D",
            123.2344,
            0,
        ],
        1727950140: [
            0.0911,
            0,
            0.0911,
            0,
            3.42635,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9398,
            0,
            68.86069,
            0,
            0.2237,
            0,
            10.57765,
            0,
            -0.04635,
            0,
            0,
            0,
            29.28827,
            0,
            7.84336,
            "<D",
            123.4048,
            0,
        ],
        1727950200: [
            0.33076,
            0,
            0.33076,
            0,
            3.15313,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            83.45432,
            0,
            0.22094,
            0,
            10.61854,
            0,
            -0.04966,
            0,
            0,
            0,
            29.28845,
            0,
            11.09361,
            "<D",
            123.6325,
            0,
        ],
        1727950260: [
            0.26525,
            0,
            0.26525,
            0,
            3.22461,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            90.39394,
            0,
            0.22411,
            0,
            10.6014,
            0,
            -0.05629,
            0,
            0,
            0,
            29.28845,
            0,
            10.28125,
            "<D",
            123.5649,
            0,
        ],
        1727950320: [
            0.18372,
            0,
            0.18372,
            0,
            3.08197,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            85.31981,
            0,
            0.2228,
            0,
            10.63462,
            0,
            -0.04304,
            0,
            0,
            0,
            29.28843,
            0,
            10.71539,
            "<D",
            123.2776,
            0,
        ],
        1727950380: [
            0.18908,
            0,
            0.18908,
            0,
            2.58064,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            65.74121,
            0,
            0.22181,
            0,
            10.65652,
            0,
            -0.04304,
            0,
            0,
            0,
            29.28841,
            0,
            9.13881,
            "<D",
            123.0578,
            0,
        ],
        1727950440: [
            0.3045,
            0,
            0.3045,
            0,
            3.08207,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            60.89666,
            0,
            0.22278,
            0,
            10.61506,
            0,
            -0.08608,
            0,
            0,
            0,
            29.28815,
            0,
            10.31999,
            "<D",
            123.3595,
            0,
        ],
        1727950500: [
            0.14503,
            0,
            0.14503,
            0,
            2.32944,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            74.79974,
            0,
            0.22351,
            0,
            10.68896,
            0,
            -0.15231,
            0,
            0,
            0,
            29.28896,
            0,
            10.09842,
            "<D",
            123.3593,
            0,
        ],
        1727950560: [
            0.2718,
            0,
            0.2718,
            0,
            2.47211,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            77.68587,
            0,
            0.22357,
            0,
            10.72728,
            0,
            -0.13575,
            0,
            0,
            0,
            29.28997,
            0,
            7.07771,
            "<D",
            122.8775,
            0,
        ],
        1727950620: [
            0.27881,
            0,
            0.27881,
            0,
            3.12551,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            71.2989,
            0,
            0.22371,
            0,
            10.73633,
            0,
            -0.06291,
            0,
            0,
            0,
            29.28959,
            0,
            9.73422,
            "<D",
            122.6223,
            0,
        ],
        1727950680: [
            0.18632,
            0,
            0.18632,
            0,
            2.67913,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            72.05199,
            0,
            0.22371,
            0,
            10.71812,
            0,
            -0.06622,
            0,
            0,
            0,
            29.2908,
            0,
            10.61427,
            "<D",
            122.9743,
            0,
        ],
        1727950740: [
            0.2542,
            0,
            0.2542,
            0,
            2.81227,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.914,
            0,
            68.8111,
            0,
            0.2234,
            0,
            10.70645,
            0,
            -0.06622,
            0,
            0,
            0,
            29.29147,
            0,
            12.55818,
            "<D",
            122.8941,
            0,
        ],
        1727950800: [
            0.12892,
            0,
            0.12892,
            0,
            2.84002,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            65.83647,
            0,
            0.22344,
            0,
            10.70168,
            0,
            -0.12582,
            0,
            0,
            0,
            29.29154,
            0,
            8.9812,
            "<D",
            122.867,
            0,
        ],
        1727950860: [
            0.24722,
            0,
            0.24722,
            0,
            2.56456,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            66.63895,
            0,
            0.22366,
            0,
            10.69724,
            0,
            -0.13244,
            0,
            0,
            0,
            29.29154,
            0,
            10.08102,
            "<D",
            122.9327,
            0,
        ],
        1727950920: [
            0.28514,
            0,
            0.28514,
            0,
            2.8458,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            60.12116,
            0,
            0.2229,
            0,
            10.7597,
            0,
            -0.16224,
            0,
            0,
            0,
            29.2913,
            0,
            11.49935,
            "<D",
            123.1577,
            0,
        ],
        1727950980: [
            0.16672,
            0,
            0.16672,
            0,
            2.68437,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            56.47238,
            0,
            0.22354,
            0,
            10.77313,
            0,
            -0.13244,
            0,
            0,
            0,
            29.29135,
            0,
            7.80407,
            "<D",
            123.4822,
            0,
        ],
        1727951040: [
            0.01361,
            0,
            0.01361,
            0,
            2.30541,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            48.8908,
            0,
            0.22319,
            0,
            10.6537,
            0,
            -0.15893,
            0,
            0,
            0,
            29.29117,
            0,
            8.3311,
            "<D",
            123.6479,
            0,
        ],
        1727951100: [
            0.06182,
            0,
            0.06182,
            0,
            2.42774,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            38.87114,
            0,
            0.22248,
            0,
            10.51139,
            0,
            -0.1523,
            0,
            0,
            0,
            29.29192,
            0,
            8.0055,
            "<D",
            123.8517,
            0,
        ],
        1727951160: [
            0.23091,
            0,
            0.23091,
            0,
            2.9761,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            33.57301,
            0,
            0.22277,
            0,
            10.41975,
            0,
            -0.2119,
            0,
            0,
            0,
            29.29118,
            0,
            7.43329,
            "<D",
            123.7899,
            0,
        ],
        1727951220: [
            0.27536,
            0,
            0.27536,
            0,
            3.35403,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            24.13021,
            0,
            0.22322,
            0,
            10.43749,
            0,
            -0.1821,
            0,
            0,
            0,
            29.29128,
            0,
            11.74811,
            "<D",
            123.3635,
            0,
        ],
        1727951280: [
            0.3564,
            0,
            0.3564,
            0,
            5.50686,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            25.83969,
            0,
            0.22373,
            0,
            10.47965,
            0,
            -0.20859,
            0,
            0,
            0,
            29.29137,
            0,
            9.88465,
            "<D",
            123.8209,
            0,
        ],
        1727951340: [
            0.2479,
            0,
            0.2479,
            0,
            3.87089,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            32.71013,
            0,
            0.22312,
            0,
            10.45037,
            0,
            -0.20528,
            0,
            0,
            0,
            29.29191,
            0,
            9.035,
            "<D",
            123.2593,
            0,
        ],
        1727951400: [
            0.41489,
            0,
            0.41489,
            0,
            2.93596,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            45.65162,
            0,
            0.22336,
            0,
            10.42185,
            0,
            -0.17548,
            0,
            0,
            0,
            29.29384,
            0,
            10.42954,
            "<D",
            123.5621,
            0,
        ],
        1727951460: [
            0.14873,
            0,
            0.14873,
            0,
            3.17909,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            48.57111,
            0,
            0.22373,
            0,
            10.38852,
            0,
            -0.21521,
            0,
            0,
            0,
            29.29276,
            0,
            9.76058,
            "<D",
            122.5885,
            0,
        ],
        1727951520: [
            0.21159,
            0,
            0.21159,
            0,
            3.25012,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            48.55077,
            0,
            0.22373,
            0,
            10.29011,
            0,
            -0.22846,
            0,
            0,
            0,
            29.29223,
            0,
            9.17532,
            "<D",
            121.9981,
            0,
        ],
        1727951580: [
            0.19966,
            0,
            0.19966,
            0,
            3.90496,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            48.5543,
            0,
            0.22373,
            0,
            10.3103,
            0,
            -0.19204,
            0,
            0,
            0,
            29.29341,
            0,
            10.03895,
            "<D",
            122.8641,
            0,
        ],
        1727951640: [
            0.2411,
            0,
            0.2411,
            0,
            3.64146,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            48.48494,
            0,
            0.22373,
            0,
            10.40508,
            0,
            -0.18873,
            0,
            0,
            0,
            29.2945,
            0,
            10.10467,
            "<D",
            122.787,
            0,
        ],
        1727951700: [
            0.21746,
            0,
            0.21746,
            0,
            3.76647,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            48.59565,
            0,
            0.22373,
            0,
            10.45005,
            0,
            -0.13244,
            0,
            0,
            0,
            29.29405,
            0,
            9.89782,
            "<D",
            122.8211,
            0,
        ],
        1727951760: [
            0.34518,
            0,
            0.34518,
            0,
            3.99635,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8796,
            0,
            37.43651,
            0,
            0.22243,
            0,
            10.44223,
            0,
            -0.18873,
            0,
            0,
            0,
            29.29472,
            0,
            9.98728,
            "<D",
            122.7717,
            0,
        ],
        1727951820: [
            0.305,
            0,
            0.305,
            0,
            5.83444,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.871,
            0,
            26.20371,
            0,
            0.22369,
            0,
            10.14886,
            0,
            -0.22515,
            0,
            0,
            0,
            29.29501,
            0,
            5.60603,
            "<D",
            122.4662,
            0,
        ],
        1727951880: [
            0.24195,
            0,
            0.24195,
            0,
            7.3544,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            24.43911,
            0,
            0.22373,
            0,
            10.27649,
            0,
            -0.18873,
            0,
            0,
            0,
            29.295,
            0,
            7.6703,
            "<D",
            123.1139,
            0,
        ],
        1727951940: [
            0.18566,
            0,
            0.18566,
            0,
            7.81053,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            20.03966,
            0,
            0.22352,
            0,
            10.31788,
            0,
            -0.23177,
            0,
            0,
            0,
            29.2952,
            0,
            12.65237,
            "<D",
            123.3382,
            0,
        ],
        1727952000: [
            0.46188,
            0,
            0.46188,
            0,
            8.13357,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8796,
            0,
            18.48091,
            0,
            0.22369,
            0,
            10.20693,
            0,
            -0.23177,
            0,
            0,
            0,
            29.29521,
            0,
            9.45769,
            "<D",
            123.2879,
            0,
        ],
        1727952060: [
            0.23014,
            0,
            0.23014,
            0,
            8.59442,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            19.69109,
            0,
            0.22373,
            0,
            10.20949,
            0,
            -0.16555,
            0,
            0,
            0,
            29.29504,
            0,
            7.67222,
            "<D",
            123.2384,
            0,
        ],
        1727952120: [
            0.15181,
            0,
            0.15181,
            0,
            8.25598,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            19.93185,
            0,
            0.22373,
            0,
            10.36564,
            0,
            -0.17879,
            0,
            0,
            0,
            29.29509,
            0,
            9.8903,
            "<D",
            123.1475,
            0,
        ],
        1727952180: [
            0.26081,
            0,
            0.26081,
            0,
            8.14198,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            21.90065,
            0,
            0.22311,
            0,
            10.39483,
            0,
            -0.14568,
            0,
            0,
            0,
            29.29465,
            0,
            9.93498,
            "<D",
            123.2838,
            0,
        ],
        1727952240: [
            0.30879,
            0,
            0.30879,
            0,
            7.95045,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            36.33969,
            0,
            0.22337,
            0,
            10.38437,
            0,
            -0.22515,
            0,
            0,
            0,
            29.29509,
            0,
            12.48766,
            "<D",
            123.3276,
            0,
        ],
        1727952300: [
            0.25372,
            0,
            0.25372,
            0,
            7.75355,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            50.07316,
            0,
            0.22323,
            0,
            10.39182,
            0,
            -0.23839,
            0,
            0,
            0,
            29.2941,
            0,
            9.43539,
            "<D",
            123.3196,
            0,
        ],
        1727952360: [
            0.18837,
            0,
            0.18837,
            0,
            7.91263,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            58.99558,
            0,
            0.22314,
            0,
            10.43993,
            0,
            -0.22846,
            0,
            0,
            0,
            29.29387,
            0,
            8.19197,
            "<D",
            123.2026,
            0,
        ],
        1727952420: [
            0.1932,
            0,
            0.1932,
            0,
            8.13743,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            68.79276,
            0,
            0.2237,
            0,
            10.4913,
            0,
            -0.22184,
            0,
            0,
            0,
            29.29423,
            0,
            10.15907,
            "<D",
            123.4722,
            0,
        ],
        1727952480: [
            0.19213,
            0,
            0.19213,
            0,
            8.25429,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            73.47017,
            0,
            0.223,
            0,
            10.58833,
            0,
            -0.21853,
            0,
            0,
            0,
            29.2947,
            0,
            8.72573,
            "<D",
            123.4599,
            0,
        ],
        1727952540: [
            0.37394,
            0,
            0.37394,
            0,
            7.58889,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            76.31924,
            0,
            0.22358,
            0,
            10.64109,
            0,
            -0.21521,
            0,
            0,
            0,
            29.29497,
            0,
            9.25338,
            "<D",
            123.5568,
            0,
        ],
        1727952600: [
            0.23764,
            0,
            0.23764,
            0,
            6.68337,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            68.56155,
            0,
            0.22328,
            0,
            10.71624,
            0,
            -0.17879,
            0,
            0,
            0,
            29.29508,
            0,
            10.60323,
            "<D",
            123.4576,
            0,
        ],
        1727952660: [
            0.37017,
            0,
            0.37017,
            0,
            6.07298,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            54.38015,
            0,
            0.22191,
            0,
            10.74027,
            0,
            -0.22846,
            0,
            0,
            0,
            29.29519,
            0,
            8.01373,
            "<D",
            123.503,
            0,
        ],
        1727952720: [
            0.24382,
            0,
            0.24382,
            0,
            3.19204,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            52.44434,
            0,
            0.22347,
            0,
            10.72813,
            0,
            -0.19535,
            0,
            0,
            0,
            29.29511,
            0,
            10.44984,
            "<D",
            123.2692,
            0,
        ],
        1727952780: [
            0.27161,
            0,
            0.27161,
            0,
            3.32672,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            54.50354,
            0,
            0.22243,
            0,
            10.71005,
            0,
            -0.19535,
            0,
            0,
            0,
            29.29513,
            0,
            8.18254,
            "<D",
            123.2077,
            0,
        ],
        1727952840: [
            0.38614,
            0,
            0.38614,
            0,
            3.76241,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            60.15081,
            0,
            0.22359,
            0,
            10.64185,
            0,
            -0.23177,
            0,
            0,
            0,
            29.29508,
            0,
            7.37394,
            "<D",
            122.8065,
            0,
        ],
        1727952900: [
            0.0907,
            0,
            0.0907,
            0,
            3.78495,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            65.89661,
            0,
            0.2236,
            0,
            10.56708,
            0,
            -0.2119,
            0,
            0,
            0,
            29.29508,
            0,
            10.85181,
            "<D",
            123.0803,
            0,
        ],
        1727952960: [
            0.19243,
            0,
            0.19243,
            0,
            3.80698,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            89.95685,
            0,
            0.22311,
            0,
            10.49273,
            0,
            -0.22184,
            0,
            0,
            0,
            29.29517,
            0,
            8.83123,
            "<D",
            123.1192,
            0,
        ],
        1727953020: [
            0.16588,
            0,
            0.16588,
            0,
            2.86669,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            110.7369,
            0,
            0.27097,
            0,
            10.51674,
            0,
            -0.21853,
            0,
            0,
            0,
            29.29519,
            0,
            8.80009,
            "<D",
            123.3171,
            0,
        ],
        1727953080: [
            -0.04054,
            0,
            -0.04054,
            0,
            2.96385,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            100.6785,
            0,
            0.4306,
            0,
            10.50872,
            0,
            -0.22846,
            0,
            0,
            0,
            29.29508,
            0,
            9.76713,
            "<D",
            123.2639,
            0,
        ],
        1727953140: [
            0.2566,
            0,
            0.2566,
            0,
            3.50004,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            76.92677,
            0,
            0.4057,
            0,
            10.51087,
            0,
            -0.22846,
            0,
            0,
            0,
            29.29513,
            0,
            9.75563,
            "<D",
            123.1908,
            0,
        ],
        1727953200: [
            0.08248,
            0,
            0.08248,
            0,
            3.61856,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            42.91291,
            0,
            0.38178,
            0,
            10.49442,
            0,
            -0.23839,
            0,
            0,
            0,
            29.29552,
            0,
            8.96012,
            "<D",
            123.1461,
            0,
        ],
        1727953260: [
            0.23453,
            0,
            0.23453,
            0,
            3.12758,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            27.8689,
            0,
            0.46614,
            0,
            10.49453,
            0,
            -0.23177,
            0,
            0,
            0,
            29.29556,
            0,
            10.10032,
            "<D",
            123.1671,
            0,
        ],
        1727953320: [
            0.30863,
            0,
            0.30863,
            0,
            2.45932,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            30.6477,
            0,
            0.51336,
            0,
            10.49264,
            0,
            -0.23508,
            0,
            0,
            0,
            29.29704,
            0,
            9.53954,
            "<D",
            122.8785,
            0,
        ],
        1727953380: [
            0.3398,
            0,
            0.3398,
            0,
            2.20266,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            38.78247,
            0,
            0.42249,
            0,
            10.34571,
            0,
            -0.17879,
            0,
            0,
            0,
            29.29677,
            0,
            9.13527,
            "<D",
            123.0827,
            0,
        ],
        1727953440: [
            0.20597,
            0,
            0.20597,
            0,
            2.33526,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            45.43141,
            0,
            0.29238,
            0,
            10.32942,
            0,
            -0.23839,
            0,
            0,
            0,
            29.29715,
            0,
            9.91294,
            "<D",
            122.7637,
            0,
        ],
        1727953500: [
            0.22515,
            0,
            0.22515,
            0,
            2.09734,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            47.33709,
            0,
            0.26853,
            0,
            10.44629,
            0,
            -0.23839,
            0,
            0,
            0,
            29.29841,
            0,
            9.27959,
            "<D",
            122.806,
            0,
        ],
        1727953560: [
            0.21349,
            0,
            0.21349,
            0,
            2.01933,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            47.5937,
            0,
            0.36134,
            0,
            10.45932,
            0,
            -0.23177,
            0,
            0,
            0,
            29.29845,
            0,
            9.09138,
            "<D",
            122.6102,
            0,
        ],
        1727953620: [
            0.29511,
            0,
            0.29511,
            0,
            2.04368,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            42.69805,
            0,
            0.42414,
            0,
            10.44725,
            0,
            -0.2119,
            0,
            0,
            0,
            29.29868,
            0,
            9.1696,
            "<D",
            122.5734,
            0,
        ],
        1727953680: [
            0.20259,
            0,
            0.20259,
            0,
            2.29178,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            41.62576,
            0,
            0.37649,
            0,
            10.44966,
            0,
            -0.16886,
            0,
            0,
            0,
            29.29893,
            0,
            8.75572,
            "<D",
            122.8436,
            0,
        ],
        1727953740: [
            0.0606,
            0,
            0.0606,
            0,
            2.23653,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            39.35118,
            0,
            0.34027,
            0,
            10.46508,
            0,
            -0.19204,
            0,
            0,
            0,
            29.29886,
            0,
            9.52715,
            "<D",
            123.2086,
            0,
        ],
        1727953800: [
            0.21345,
            0,
            0.21345,
            0,
            2.76272,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            49.60373,
            0,
            0.33682,
            0,
            10.48941,
            0,
            -0.20197,
            0,
            0,
            0,
            29.29864,
            0,
            11.16778,
            "<D",
            122.9283,
            0,
        ],
        1727953860: [
            0.28543,
            0,
            0.28543,
            0,
            2.60991,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            56.36111,
            0,
            0.22886,
            0,
            10.42777,
            0,
            -0.20528,
            0,
            0,
            0,
            29.29854,
            0,
            7.4966,
            "<D",
            123.2364,
            0,
        ],
        1727953920: [
            0.39175,
            0,
            0.39175,
            0,
            3.08673,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            74.13721,
            0,
            0.22491,
            0,
            10.44485,
            0,
            -0.1821,
            0,
            0,
            0,
            29.29935,
            0,
            10.4473,
            "<D",
            123.2615,
            0,
        ],
        1727953980: [
            0.0266,
            0,
            0.0266,
            0,
            3.42493,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            56.17962,
            0,
            0.31631,
            0,
            10.48944,
            0,
            -0.17879,
            0,
            0,
            0,
            29.29924,
            0,
            10.7344,
            "<D",
            123.2712,
            0,
        ],
        1727954040: [
            0.18716,
            0,
            0.18716,
            0,
            3.0074,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            34.65592,
            0,
            0.42938,
            0,
            10.54501,
            0,
            -0.22515,
            0,
            0,
            0,
            29.30075,
            0,
            8.24612,
            "<D",
            123.3555,
            0,
        ],
        1727954100: [
            0.24828,
            0,
            0.24828,
            0,
            2.07628,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            26.73921,
            0,
            0.34661,
            0,
            10.56881,
            0,
            -0.14899,
            0,
            0,
            0,
            29.3017,
            0,
            10.27177,
            "<D",
            123.3281,
            0,
        ],
        1727954160: [
            0.23758,
            0,
            0.23758,
            0,
            2.1311,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            1.62445,
            0,
            0.33768,
            0,
            10.44941,
            0,
            -0.13906,
            0,
            0,
            0,
            29.30219,
            0,
            12.60223,
            "<D",
            123.2256,
            0,
        ],
        1727954220: [
            0.0239,
            0,
            0.0239,
            0,
            2.39926,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            348.3135,
            0,
            0.35925,
            0,
            10.33977,
            0,
            -0.10264,
            0,
            0,
            0,
            29.30206,
            0,
            11.88046,
            "<D",
            123.3132,
            0,
        ],
        1727954280: [
            0.18848,
            0,
            0.18848,
            0,
            3.4596,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            354.9146,
            0,
            0.24376,
            0,
            10.2214,
            0,
            -0.00662,
            0,
            0,
            0,
            29.30222,
            0,
            6.42351,
            "<D",
            123.1909,
            0,
        ],
        1727954340: [
            0.07759,
            0,
            0.07759,
            0,
            3.19483,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            356.8443,
            0,
            0.22373,
            0,
            10.07925,
            0,
            0.02649,
            0,
            0,
            0,
            29.30219,
            0,
            12.53126,
            "<D",
            123.2281,
            0,
        ],
        1727954400: [
            0.18769,
            0,
            0.18769,
            0,
            2.66285,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            356.8411,
            0,
            0.22373,
            0,
            10.08519,
            0,
            0.11257,
            0,
            0,
            0,
            29.30219,
            0,
            7.1848,
            "<D",
            123.3805,
            0,
        ],
        1727954460: [
            0.36779,
            0,
            0.36779,
            0,
            2.71234,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            12.04137,
            0,
            0.22229,
            0,
            10.21495,
            0,
            0.12581,
            0,
            0,
            0,
            29.30213,
            0,
            10.15173,
            "<D",
            123.3163,
            0,
        ],
        1727954520: [
            0.21556,
            0,
            0.21556,
            0,
            2.40913,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            15.75715,
            0,
            0.22373,
            0,
            10.29503,
            0,
            0.15893,
            0,
            0,
            0,
            29.30198,
            0,
            9.60276,
            "<D",
            123.1543,
            0,
        ],
        1727954580: [
            0.2494,
            0,
            0.2494,
            0,
            2.32205,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            32.352,
            0,
            0.21902,
            0,
            10.35593,
            0,
            0.26488,
            0,
            0,
            0,
            29.30312,
            0,
            12.5089,
            "<D",
            123.4256,
            0,
        ],
        1727954640: [
            0.17376,
            0,
            0.17376,
            0,
            2.21515,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            57.07885,
            0,
            0.22608,
            0,
            10.39739,
            0,
            0.27813,
            0,
            0,
            0,
            29.30303,
            0,
            9.91419,
            "<D",
            123.9414,
            0,
        ],
        1727954700: [
            0.14965,
            0,
            0.14965,
            0,
            3.07915,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            73.33994,
            0,
            0.31354,
            0,
            10.4248,
            0,
            0.4172,
            0,
            0,
            0,
            29.3035,
            0,
            9.30417,
            "<D",
            124.115,
            0,
        ],
        1727954760: [
            0.27176,
            0,
            0.27176,
            0,
            3.53998,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            71.41282,
            0,
            0.33856,
            0,
            10.47674,
            0,
            0.49004,
            0,
            0,
            0,
            29.3041,
            0,
            9.46763,
            "<D",
            123.9203,
            0,
        ],
        1727954820: [
            0.15721,
            0,
            0.15721,
            0,
            3.72721,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            56.48139,
            0,
            0.37913,
            0,
            10.51059,
            0,
            0.67878,
            0,
            0,
            0,
            29.30413,
            0,
            7.44037,
            "<D",
            124.0095,
            0,
        ],
        1727954880: [
            0.27869,
            0,
            0.27869,
            0,
            3.66169,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            54.73848,
            0,
            0.42948,
            0,
            10.59046,
            0,
            0.77812,
            0,
            0,
            0,
            29.30467,
            0,
            6.72491,
            "<D",
            124.0129,
            0,
        ],
        1727954940: [
            0.13217,
            0,
            0.13217,
            0,
            3.51436,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            55.81367,
            0,
            0.39344,
            0,
            10.68148,
            0,
            0.94038,
            0,
            0,
            0,
            29.30524,
            0,
            9.06472,
            "<D",
            124.1327,
            0,
        ],
        1727955000: [
            0.11328,
            0,
            0.11328,
            0,
            3.56267,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            69.00395,
            0,
            0.422,
            0,
            10.71879,
            0,
            1.08942,
            0,
            0,
            0,
            29.3049,
            0,
            9.2369,
            "<D",
            124.1351,
            0,
        ],
        1727955060: [
            0.16341,
            0,
            0.16341,
            0,
            3.77994,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            67.27513,
            0,
            0.42218,
            0,
            10.71652,
            0,
            1.27485,
            0,
            0,
            0,
            29.30526,
            0,
            6.96525,
            "<D",
            124.1433,
            0,
        ],
        1727955120: [
            0.29867,
            0,
            0.29867,
            0,
            3.11315,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            63.53221,
            0,
            0.27495,
            0,
            10.65589,
            0,
            1.48679,
            0,
            0,
            0,
            29.30551,
            0,
            12.08454,
            "<D",
            124.2734,
            0,
        ],
        1727955180: [
            0.16802,
            0,
            0.16802,
            0,
            3.9831,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            77.56686,
            0,
            0.22351,
            0,
            10.62564,
            0,
            1.66897,
            0,
            0,
            0,
            29.30495,
            0,
            9.32195,
            "<D",
            124.4134,
            0,
        ],
        1727955240: [
            0.12157,
            0,
            0.12157,
            0,
            3.97366,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            83.21529,
            0,
            0.22363,
            0,
            10.58318,
            0,
            1.92397,
            0,
            0,
            0,
            29.30493,
            0,
            11.982,
            "<D",
            124.4116,
            0,
        ],
        1727955300: [
            0.33477,
            0,
            0.33477,
            0,
            3.69777,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            75.45062,
            0,
            0.22307,
            0,
            10.54816,
            0,
            2.18566,
            0,
            0,
            0,
            29.30521,
            0,
            8.39954,
            "<D",
            124.2655,
            0,
        ],
        1727955360: [
            0.00059,
            0,
            0.00059,
            0,
            3.90582,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            68.99445,
            0,
            0.22363,
            0,
            10.5257,
            0,
            2.46062,
            0,
            0,
            0,
            29.30555,
            0,
            10.1542,
            "<D",
            124.616,
            0,
        ],
        1727955420: [
            0.18234,
            0,
            0.18234,
            0,
            4.25989,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            70.57307,
            0,
            0.22367,
            0,
            10.50438,
            0,
            2.7422,
            0,
            0,
            0,
            29.30583,
            0,
            8.80728,
            "<D",
            124.5256,
            0,
        ],
        1727955480: [
            0.05759,
            0,
            0.05759,
            0,
            4.53503,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8796,
            0,
            84.49895,
            0,
            0.22263,
            0,
            10.47547,
            0,
            2.98407,
            0,
            0,
            0,
            29.30524,
            0,
            10.7806,
            "<D",
            124.4669,
            0,
        ],
        1727955540: [
            0.20434,
            0,
            0.20434,
            0,
            4.49903,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.871,
            0,
            89.14651,
            0,
            0.22322,
            0,
            10.43932,
            0,
            3.33193,
            0,
            0,
            0,
            29.30568,
            0,
            10.94965,
            "<D",
            124.4393,
            0,
        ],
        1727955600: [
            0.30757,
            0,
            0.30757,
            0,
            4.75189,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            88.21754,
            0,
            0.22366,
            0,
            10.39637,
            0,
            3.65991,
            0,
            0,
            0,
            29.30571,
            0,
            8.57362,
            "<D",
            124.5425,
            0,
        ],
        1727955660: [
            0.21679,
            0,
            0.21679,
            0,
            4.55429,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            96.65594,
            0,
            0.47275,
            0,
            10.36816,
            0,
            4.02439,
            0,
            0,
            0,
            29.30555,
            0,
            11.07005,
            "<D",
            124.3535,
            0,
        ],
        1727955720: [
            0.25109,
            0,
            0.25109,
            0,
            4.06156,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.871,
            0,
            100.6874,
            0,
            0.59828,
            0,
            10.3089,
            0,
            4.35908,
            0,
            0,
            0,
            29.30565,
            0,
            8.8498,
            "<D",
            124.3754,
            0,
        ],
        1727955780: [
            0.1361,
            0,
            0.1361,
            0,
            3.74677,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            108.6871,
            0,
            0.50752,
            0,
            10.30024,
            0,
            4.76671,
            0,
            0,
            0,
            29.30571,
            0,
            6.03961,
            "<D",
            124.1409,
            0,
        ],
        1727955840: [
            0.15407,
            0,
            0.15407,
            0,
            3.49315,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            102.5141,
            0,
            0.46796,
            0,
            10.31531,
            0,
            5.24387,
            0,
            0,
            0,
            29.30585,
            0,
            9.9002,
            "<D",
            124.4646,
            0,
        ],
        1727955900: [
            0.25813,
            0,
            0.25813,
            0,
            3.68163,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            74.93925,
            0,
            0.43376,
            0,
            10.31306,
            0,
            5.67155,
            0,
            0,
            0,
            29.30588,
            0,
            10.43792,
            "<D",
            124.4185,
            0,
        ],
        1727955960: [
            0.24641,
            0,
            0.24641,
            0,
            2.81314,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            53.47974,
            0,
            0.55588,
            0,
            10.28898,
            0,
            6.16217,
            0,
            0,
            0,
            29.3056,
            0,
            9.39787,
            "<D",
            124.4296,
            0,
        ],
        1727956020: [
            0.20944,
            0,
            0.20944,
            0,
            2.03478,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            49.07973,
            0,
            0.68194,
            0,
            10.30125,
            0,
            6.65937,
            0,
            0,
            0,
            29.30549,
            0,
            11.21576,
            "<D",
            124.4885,
            0,
        ],
        1727956080: [
            0.31077,
            0,
            0.31077,
            0,
            1.39148,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            58.4781,
            0,
            0.60102,
            0,
            10.20446,
            0,
            7.09034,
            0,
            0,
            0,
            29.30583,
            0,
            9.35086,
            "<D",
            124.5339,
            0,
        ],
        1727956140: [
            0.10974,
            0,
            0.10974,
            0,
            1.28707,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            72.98322,
            0,
            0.59561,
            0,
            10.15763,
            0,
            7.46524,
            0,
            0,
            0,
            29.30613,
            0,
            6.8518,
            "<D",
            124.6622,
            0,
        ],
        1727956200: [
            0.27591,
            0,
            0.27591,
            0,
            1.47574,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            82.37209,
            0,
            0.58598,
            0,
            10.22104,
            0,
            7.9823,
            0,
            0,
            0,
            29.30582,
            0,
            8.83652,
            "<D",
            124.6016,
            0,
        ],
        1727956260: [
            0.26076,
            0,
            0.26076,
            0,
            1.46462,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            89.07875,
            0,
            0.59752,
            0,
            10.31923,
            0,
            8.4499,
            0,
            0,
            0,
            29.30557,
            0,
            9.8586,
            "<D",
            124.276,
            0,
        ],
        1727956320: [
            0.38506,
            0,
            0.38506,
            0,
            1.48694,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            91.04305,
            0,
            0.54796,
            0,
            10.26939,
            0,
            8.91762,
            0,
            0,
            0,
            29.30555,
            0,
            9.92656,
            "<D",
            124.5595,
            0,
        ],
        1727956380: [
            0.37622,
            0,
            0.37622,
            0,
            1.99051,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            85.65871,
            0,
            0.52631,
            0,
            10.18127,
            0,
            9.36872,
            0,
            0,
            0,
            29.30549,
            0,
            8.94119,
            "<D",
            124.6789,
            0,
        ],
        1727956440: [
            0.31072,
            0,
            0.31072,
            0,
            1.71546,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            70.80799,
            0,
            0.46028,
            0,
            10.15529,
            0,
            9.83963,
            0,
            0,
            0,
            29.30591,
            0,
            8.70106,
            "<D",
            124.6413,
            0,
        ],
        1727956500: [
            0.34647,
            0,
            0.34647,
            0,
            1.75218,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            58.22658,
            0,
            0.41284,
            0,
            10.13562,
            0,
            10.24766,
            0,
            0,
            0,
            29.30605,
            0,
            10.86984,
            "<D",
            124.7012,
            0,
        ],
        1727956560: [
            0.2271,
            0,
            0.2271,
            0,
            1.42761,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            54.87622,
            0,
            0.26284,
            0,
            10.07972,
            0,
            10.63935,
            0,
            0,
            0,
            29.30595,
            0,
            10.23266,
            "<D",
            124.6721,
            0,
        ],
        1727956620: [
            0.26853,
            0,
            0.26853,
            0,
            1.08527,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            56.25497,
            0,
            0.22373,
            0,
            10.06897,
            0,
            11.09047,
            0,
            0,
            0,
            29.30593,
            0,
            7.2976,
            "<D",
            124.5593,
            0,
        ],
        1727956680: [
            0.22516,
            0,
            0.22516,
            0,
            1.11709,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            56.24326,
            0,
            0.2253,
            0,
            10.04424,
            0,
            11.43584,
            0,
            0,
            0,
            29.30576,
            0,
            9.56606,
            "<D",
            124.8208,
            0,
        ],
        1727956740: [
            0.26775,
            0,
            0.26775,
            0,
            1.05428,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            56.9957,
            0,
            0.22371,
            0,
            10.10955,
            0,
            11.91671,
            0,
            0,
            0,
            29.30567,
            0,
            12.16648,
            "<D",
            124.598,
            0,
        ],
        1727956800: [
            0.08737,
            0,
            0.08737,
            0,
            1.01896,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            58.16349,
            0,
            0.22373,
            0,
            10.14271,
            0,
            12.33816,
            0,
            0,
            0,
            29.30605,
            0,
            9.63149,
            "<D",
            124.6302,
            0,
        ],
        1727956860: [
            0.27294,
            0,
            0.27294,
            0,
            1.87526,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            58.10791,
            0,
            0.2253,
            0,
            10.13806,
            0,
            12.75971,
            0,
            0,
            0,
            29.30748,
            0,
            9.84993,
            "<D",
            124.7245,
            0,
        ],
        1727956920: [
            0.22642,
            0,
            0.22642,
            0,
            3.1622,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            57.15535,
            0,
            0.22437,
            0,
            10.14877,
            0,
            13.20112,
            0,
            0,
            0,
            29.3076,
            0,
            9.54787,
            "<D",
            124.7753,
            0,
        ],
        1727956980: [
            0.11014,
            0,
            0.11014,
            0,
            3.7689,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            52.84446,
            0,
            0.22373,
            0,
            10.20084,
            0,
            13.616,
            0,
            0,
            0,
            29.30771,
            0,
            5.85474,
            "<D",
            124.8846,
            0,
        ],
        1727957040: [
            0.13839,
            0,
            0.13839,
            0,
            4.08578,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9312,
            0,
            53.01658,
            0,
            0.22373,
            0,
            10.21043,
            0,
            13.988,
            0,
            0,
            0,
            29.30779,
            0,
            10.81144,
            "<D",
            124.7703,
            0,
        ],
        1727957100: [
            0.18956,
            0,
            0.18956,
            0,
            3.95963,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            53.19028,
            0,
            0.22373,
            0,
            10.16147,
            0,
            14.3565,
            0,
            0,
            0,
            29.30833,
            0,
            9.97811,
            "<D",
            124.7361,
            0,
        ],
        1727957160: [
            0.2651,
            0,
            0.2651,
            0,
            2.56591,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            53.29791,
            0,
            0.22373,
            0,
            10.09304,
            0,
            14.74516,
            0,
            0,
            0,
            29.30825,
            0,
            10.70332,
            "<D",
            124.6275,
            0,
        ],
        1727957220: [
            0.24313,
            0,
            0.24313,
            0,
            2.00115,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9441,
            0,
            57.2498,
            0,
            0.22207,
            0,
            10.21167,
            0,
            15.14332,
            0,
            0,
            0,
            29.30719,
            0,
            9.70176,
            "<D",
            124.549,
            0,
        ],
        1727957280: [
            0.12243,
            0,
            0.12243,
            0,
            2.35171,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            135.6426,
            0,
            0.21952,
            0,
            10.23087,
            0,
            15.48884,
            0,
            0,
            0,
            29.30767,
            0,
            9.57989,
            "<D",
            124.7018,
            0,
        ],
        1727957340: [
            0.21797,
            0,
            0.21797,
            0,
            3.23982,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9398,
            0,
            128.1191,
            0,
            0.28878,
            0,
            10.29078,
            0,
            15.87736,
            0,
            0,
            0,
            29.30806,
            0,
            10.28899,
            "<D",
            122.1415,
            0,
        ],
        1727957400: [
            0.20953,
            0,
            0.20953,
            0,
            2.50414,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9441,
            0,
            105.5762,
            0,
            0.34943,
            0,
            10.34443,
            0,
            16.28908,
            0,
            0,
            0,
            29.30832,
            0,
            9.03946,
            "<D",
            116.2249,
            0,
        ],
        1727957460: [
            0.25874,
            0,
            0.25874,
            0,
            1.79029,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            76.15269,
            0,
            0.53869,
            0,
            10.41043,
            0,
            16.68777,
            0,
            0,
            0,
            29.30827,
            0,
            9.7577,
            "<D",
            109.67,
            0,
        ],
        1727957520: [
            0.2558,
            0,
            0.2558,
            0,
            1.68963,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9398,
            0,
            65.52976,
            0,
            0.57885,
            0,
            10.35553,
            0,
            17.07961,
            0,
            0,
            0,
            29.30851,
            0,
            8.87998,
            "<D",
            105.7069,
            0,
        ],
        1727957580: [
            0.20697,
            0,
            0.20697,
            0,
            1.52998,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.957,
            0,
            72.96019,
            0,
            0.49323,
            0,
            10.31471,
            0,
            17.48484,
            0,
            0,
            0,
            29.30869,
            0,
            10.40013,
            "<D",
            103.6099,
            0,
        ],
        1727957640: [
            0.09152,
            0,
            0.09152,
            0,
            1.21203,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            75.66604,
            0,
            0.48449,
            0,
            10.31726,
            0,
            17.79413,
            0,
            0,
            0,
            29.30744,
            0,
            9.38984,
            "<D",
            103.5877,
            0,
        ],
        1727957700: [
            -0.10519,
            0,
            -0.10519,
            0,
            1.09979,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9441,
            0,
            75.39097,
            0,
            0.49716,
            0,
            10.36734,
            0,
            18.18285,
            0,
            0,
            0,
            29.30925,
            0,
            9.45549,
            "<D",
            103.2257,
            0,
        ],
        1727957760: [
            0.20363,
            0,
            0.20363,
            0,
            1.2715,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9484,
            0,
            74.67371,
            0,
            0.49075,
            0,
            10.30246,
            0,
            18.5746,
            0,
            0,
            0,
            29.30914,
            0,
            7.9991,
            "<D",
            103.1891,
            0,
        ],
        1727957820: [
            0.20122,
            0,
            0.20122,
            0,
            1.20001,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            72.66938,
            0,
            0.42876,
            0,
            10.24209,
            0,
            18.95351,
            0,
            0,
            0,
            29.3095,
            0,
            10.02449,
            "<D",
            102.9761,
            0,
        ],
        1727957880: [
            0.17168,
            0,
            0.17168,
            0,
            1.43183,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9656,
            0,
            70.95219,
            0,
            0.24632,
            0,
            10.23263,
            0,
            19.31242,
            0,
            0,
            0,
            29.30951,
            0,
            9.08337,
            "<D",
            102.972,
            0,
        ],
        1727957940: [
            0.35519,
            0,
            0.35519,
            0,
            1.17656,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9785,
            0,
            81.44589,
            0,
            0.22373,
            0,
            10.22587,
            0,
            19.7013,
            0,
            0,
            0,
            29.30951,
            0,
            11.15574,
            "<D",
            102.3787,
            0,
        ],
        1727958000: [
            0.41015,
            0,
            0.41015,
            0,
            1.09352,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            87.80509,
            0,
            0.22355,
            0,
            10.22902,
            0,
            20.01712,
            0,
            0,
            0,
            29.30951,
            0,
            12.06987,
            "<D",
            102.4565,
            0,
        ],
        1727958060: [
            0.31646,
            0,
            0.31646,
            0,
            1.18041,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9656,
            0,
            88.93187,
            0,
            0.22341,
            0,
            10.29776,
            0,
            20.28688,
            0,
            0,
            0,
            29.30946,
            0,
            8.9756,
            "<D",
            103.0749,
            0,
        ],
        1727958120: [
            0.24984,
            0,
            0.24984,
            0,
            1.31121,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9656,
            0,
            86.42347,
            0,
            0.51506,
            0,
            10.33273,
            0,
            20.65907,
            0,
            0,
            0,
            29.30935,
            0,
            11.49054,
            "<D",
            103.4466,
            0,
        ],
        1727958180: [
            0.27663,
            0,
            0.27663,
            0,
            1.50211,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9742,
            0,
            78.68826,
            0,
            0.56051,
            0,
            10.41491,
            0,
            20.93521,
            0,
            0,
            0,
            29.30949,
            0,
            9.12227,
            "<D",
            103.049,
            0,
        ],
        1727958240: [
            0.21647,
            0,
            0.21647,
            0,
            2.02916,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9656,
            0,
            73.93048,
            0,
            0.50362,
            0,
            10.47476,
            0,
            21.23495,
            0,
            0,
            0,
            29.30909,
            0,
            9.60639,
            "<D",
            102.4788,
            0,
        ],
        1727958300: [
            0.36977,
            0,
            0.36977,
            0,
            1.97696,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9828,
            0,
            75.68054,
            0,
            0.54475,
            0,
            10.50045,
            0,
            21.60024,
            0,
            0,
            0,
            29.30935,
            0,
            8.68635,
            "<D",
            102.262,
            0,
        ],
        1727958360: [
            0.19877,
            0,
            0.19877,
            0,
            2.04008,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9699,
            0,
            80.58137,
            0,
            0.51097,
            0,
            10.5259,
            0,
            21.95878,
            0,
            0,
            0,
            29.30945,
            0,
            8.51832,
            "<D",
            102.6156,
            0,
        ],
        1727958420: [
            0.31943,
            0,
            0.31943,
            0,
            1.7408,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            94.92667,
            0,
            0.58268,
            0,
            10.53582,
            0,
            22.30133,
            0,
            0,
            0,
            29.3095,
            0,
            8.74258,
            "<D",
            102.7834,
            0,
        ],
        1727958480: [
            0.22989,
            0,
            0.22989,
            0,
            2.18165,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            113.159,
            0,
            0.60522,
            0,
            10.53661,
            0,
            22.59114,
            0,
            0,
            0,
            29.30918,
            0,
            11.41941,
            "<D",
            102.4916,
            0,
        ],
        1727958540: [
            0.21989,
            0,
            0.21989,
            0,
            1.77545,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9785,
            0,
            116.7202,
            0,
            0.59408,
            0,
            10.56365,
            0,
            22.92344,
            0,
            0,
            0,
            29.30942,
            0,
            7.70412,
            "<D",
            102.4982,
            0,
        ],
        1727958600: [
            0.09962,
            0,
            0.09962,
            0,
            2.06893,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            107.8864,
            0,
            0.45807,
            0,
            10.55686,
            0,
            23.26597,
            0,
            0,
            0,
            29.30949,
            0,
            9.76536,
            "<D",
            101.8976,
            0,
        ],
        1727958660: [
            0.55508,
            0,
            0.55508,
            0,
            1.85529,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9828,
            0,
            75.41572,
            0,
            0.32512,
            0,
            10.55263,
            0,
            23.56558,
            0,
            0,
            0,
            29.30977,
            0,
            9.22923,
            "<D",
            101.7946,
            0,
        ],
        1727958720: [
            0.14667,
            0,
            0.14667,
            0,
            1.77764,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            53.45615,
            0,
            0.22365,
            0,
            10.51934,
            0,
            23.92124,
            0,
            0,
            0,
            29.30984,
            0,
            7.50964,
            "<D",
            102.0405,
            0,
        ],
        1727958780: [
            0.11212,
            0,
            0.11212,
            0,
            1.34795,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            51.72589,
            0,
            0.3872,
            0,
            10.532,
            0,
            24.22079,
            0,
            0,
            0,
            29.31013,
            0,
            9.94477,
            "<D",
            101.6643,
            0,
        ],
        1727958840: [
            0.40371,
            0,
            0.40371,
            0,
            0.97754,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            61.56425,
            0,
            0.44129,
            0,
            10.54235,
            0,
            24.53656,
            0,
            0,
            0,
            29.31083,
            0,
            9.3511,
            "<D",
            101.2487,
            0,
        ],
        1727958900: [
            0.2948,
            0,
            0.2948,
            0,
            0.9006,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            71.01752,
            0,
            0.51472,
            0,
            10.49462,
            0,
            25.22569,
            0,
            0,
            0,
            29.30997,
            0,
            9.2571,
            "<D",
            101.3589,
            0,
        ],
        1727958960: [
            0.16383,
            0,
            0.16383,
            0,
            1.02451,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            77.72665,
            0,
            0.52885,
            0,
            10.496,
            0,
            26.8729,
            0,
            0,
            0,
            29.31153,
            0,
            8.78841,
            "<D",
            101.6162,
            0,
        ],
        1727959020: [
            0.02147,
            0,
            0.02147,
            0,
            0.8928,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9785,
            0,
            75.62878,
            0,
            0.48187,
            0,
            10.50899,
            0,
            27.40538,
            0,
            0,
            0,
            29.3126,
            0,
            10.2827,
            "<D",
            101.253,
            0,
        ],
        1727959080: [
            0.13973,
            0,
            0.13973,
            0,
            0.83427,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            75.79522,
            0,
            0.43815,
            0,
            10.4938,
            0,
            27.30594,
            0,
            0,
            0,
            29.31247,
            0,
            8.40809,
            "<D",
            101.9708,
            0,
        ],
        1727959140: [
            0.18764,
            0,
            0.18764,
            0,
            0.86817,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            78.10262,
            0,
            0.494,
            0,
            10.55561,
            0,
            30.27213,
            0,
            0,
            0,
            29.31277,
            0,
            9.93078,
            "<D",
            101.9655,
            0,
        ],
        1727959200: [
            0.46663,
            0,
            0.46663,
            0,
            0.96044,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            78.98477,
            0,
            0.47653,
            0,
            10.62657,
            0,
            34.74023,
            0,
            0,
            0,
            29.31277,
            0,
            8.75325,
            "<D",
            101.0171,
            0,
        ],
        1727959260: [
            0.28711,
            0,
            0.28711,
            0,
            1.04482,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            83.70416,
            0,
            0.50372,
            0,
            10.68694,
            0,
            36.99381,
            0,
            0,
            0,
            29.31278,
            0,
            9.0187,
            "<D",
            100.7656,
            0,
        ],
        1727959320: [
            0.31988,
            0,
            0.31988,
            0,
            1.05714,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            80.49464,
            0,
            0.49686,
            0,
            10.74451,
            0,
            33.35718,
            0,
            0,
            0,
            29.31276,
            0,
            7.78457,
            "<D",
            100.9474,
            0,
        ],
        1727959380: [
            0.25206,
            0,
            0.25206,
            0,
            1.4586,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.00042,
            0,
            72.46528,
            0,
            0.4567,
            0,
            10.80008,
            0,
            33.82,
            0,
            0,
            0,
            29.31274,
            0,
            10.83363,
            "<D",
            101.2616,
            0,
        ],
        1727959440: [
            0.33275,
            0,
            0.33275,
            0,
            0.87704,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            70.15117,
            0,
            0.43005,
            0,
            10.81557,
            0,
            33.1671,
            0,
            0,
            0,
            29.31277,
            0,
            8.89815,
            "<D",
            102.1982,
            0,
        ],
        1727959500: [
            0.17367,
            0,
            0.17367,
            0,
            1.05207,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            64.68568,
            0,
            0.40908,
            0,
            10.83692,
            0,
            28.87019,
            0,
            0,
            0,
            29.31277,
            0,
            6.95801,
            "<D",
            102.6487,
            0,
        ],
        1727959560: [
            0.17334,
            0,
            0.17334,
            0,
            1.39328,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.99598,
            0,
            56.05057,
            0,
            0.24666,
            0,
            10.89936,
            0,
            32.00759,
            0,
            0,
            0,
            29.31276,
            0,
            8.83354,
            "<D",
            102.4654,
            0,
        ],
        1727959620: [
            0.2357,
            0,
            0.2357,
            0,
            1.57718,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.00042,
            0,
            43.69316,
            0,
            0.22283,
            0,
            10.9686,
            0,
            35.39709,
            0,
            0,
            0,
            29.31273,
            0,
            8.80622,
            "<D",
            102.1819,
            0,
        ],
        1727959680: [
            0.19143,
            0,
            0.19143,
            0,
            1.69195,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.00042,
            0,
            48.18626,
            0,
            0.23111,
            0,
            11.0503,
            0,
            33.21108,
            0,
            0,
            0,
            29.3126,
            0,
            9.11095,
            "<D",
            100.7492,
            0,
        ],
        1727959740: [
            0.20382,
            0,
            0.20382,
            0,
            2.36853,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.00042,
            0,
            49.63475,
            0,
            0.22333,
            0,
            11.09859,
            0,
            34.94699,
            0,
            0,
            0,
            29.31278,
            0,
            8.392,
            "<D",
            101.3102,
            0,
        ],
        1727959800: [
            0.11047,
            0,
            0.11047,
            0,
            2.45485,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.00042,
            0,
            62.91031,
            0,
            0.22342,
            0,
            11.15198,
            0,
            51.33966,
            0,
            0,
            0,
            29.31277,
            0,
            10.31809,
            "<D",
            101.6595,
            0,
        ],
        1727959860: [
            0.21953,
            0,
            0.21953,
            0,
            2.31173,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.00487,
            0,
            75.30893,
            0,
            0.21627,
            0,
            11.21884,
            0,
            66.29221,
            0,
            0,
            0,
            29.31277,
            0,
            9.20478,
            "<D",
            101.9955,
            0,
        ],
        1727959920: [
            0.33037,
            0,
            0.33037,
            0,
            2.71466,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.00042,
            0,
            73.03036,
            0,
            0.38472,
            0,
            11.26695,
            0,
            53.62671,
            0,
            0,
            0,
            29.31274,
            0,
            9.98337,
            "<D",
            101.5491,
            0,
        ],
        1727959980: [
            0.30638,
            0,
            0.30638,
            0,
            2.85027,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            69.90063,
            0,
            0.47232,
            0,
            11.31157,
            0,
            38.21082,
            0,
            0,
            0,
            29.31309,
            0,
            7.52518,
            "<D",
            101.6178,
            0,
        ],
        1727960040: [
            -0.01334,
            0,
            -0.01334,
            0,
            3.22384,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            64.20354,
            0,
            0.52473,
            0,
            11.3816,
            0,
            31.88211,
            0,
            0,
            0,
            29.31374,
            0,
            7.60729,
            "<D",
            101.3943,
            0,
        ],
        1727960100: [
            0.23034,
            0,
            0.23034,
            0,
            3.42002,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            64.19038,
            0,
            0.51871,
            0,
            11.45141,
            0,
            31.53824,
            0,
            0,
            0,
            29.31316,
            0,
            12.54166,
            "<D",
            101.2016,
            0,
        ],
        1727960160: [
            0.12864,
            0,
            0.12864,
            0,
            4.09746,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            79.92278,
            0,
            0.62921,
            0,
            11.5425,
            0,
            31.38525,
            0,
            0,
            0,
            29.31515,
            0,
            8.29665,
            "<D",
            101.0878,
            0,
        ],
        1727960220: [
            0.27585,
            0,
            0.27585,
            0,
            3.6337,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            83.93055,
            0,
            0.49361,
            0,
            11.53459,
            0,
            33.39053,
            0,
            0,
            0,
            29.31423,
            0,
            8.59516,
            "<D",
            102.7094,
            0,
        ],
        1727960280: [
            0.1745,
            0,
            0.1745,
            0,
            4.15586,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            78.82278,
            0,
            0.50903,
            0,
            11.58752,
            0,
            33.82433,
            0,
            0,
            0,
            29.31575,
            0,
            9.79611,
            "<D",
            106.0452,
            0,
        ],
        1727960340: [
            0.18526,
            0,
            0.18526,
            0,
            4.56824,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01834,
            0,
            51.78104,
            0,
            0.45066,
            0,
            11.63273,
            0,
            33.38731,
            0,
            0,
            0,
            29.31525,
            0,
            10.1594,
            "<D",
            106.2245,
            0,
        ],
        1727960400: [
            0.31712,
            0,
            0.31712,
            0,
            4.49095,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            38.44644,
            0,
            0.59491,
            0,
            11.66459,
            0,
            36.46173,
            0,
            0,
            0,
            29.31696,
            0,
            9.93559,
            "<D",
            101.2771,
            0,
        ],
        1727960460: [
            0.1768,
            0,
            0.1768,
            0,
            5.15087,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.02292,
            0,
            24.69002,
            0,
            0.587,
            0,
            11.75528,
            0,
            36.55886,
            0,
            0,
            0,
            29.31685,
            0,
            11.2428,
            "<D",
            97.80822,
            0,
        ],
        1727960520: [
            0.39102,
            0,
            0.39102,
            0,
            4.66667,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            33.40687,
            0,
            0.52076,
            0,
            11.82037,
            0,
            36.96182,
            0,
            0,
            0,
            29.31753,
            0,
            9.63903,
            "<D",
            97.3702,
            0,
        ],
        1727960580: [
            0.12802,
            0,
            0.12802,
            0,
            4.86825,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            48.62726,
            0,
            0.42357,
            0,
            11.85775,
            0,
            41.46296,
            0,
            0,
            0,
            29.31707,
            0,
            9.70577,
            "<D",
            95.82677,
            0,
        ],
        1727960640: [
            0.19388,
            0,
            0.19388,
            0,
            5.50754,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.03209,
            0,
            61.74434,
            0,
            0.46515,
            0,
            11.88472,
            0,
            42.97583,
            0,
            0,
            0,
            29.31725,
            0,
            12.15488,
            "<D",
            97.0215,
            0,
        ],
        1727960700: [
            0.35269,
            0,
            0.35269,
            0,
            5.25185,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01834,
            0,
            57.54716,
            0,
            0.49668,
            0,
            11.90561,
            0,
            44.45884,
            0,
            0,
            0,
            29.31734,
            0,
            9.54478,
            "<D",
            98.78297,
            0,
        ],
        1727960760: [
            0.28941,
            0,
            0.28941,
            0,
            5.07322,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.0275,
            0,
            57.93877,
            0,
            0.52673,
            0,
            11.95697,
            0,
            43.75749,
            0,
            0,
            0,
            29.31739,
            0,
            9.69948,
            "<D",
            98.42673,
            0,
        ],
        1727960820: [
            0.44575,
            0,
            0.44575,
            0,
            5.08775,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            56.86136,
            0,
            0.4608,
            0,
            12.04309,
            0,
            47.20702,
            0,
            0,
            0,
            29.31734,
            0,
            12.75225,
            "<D",
            97.25563,
            0,
        ],
        1727960880: [
            0.14205,
            0,
            0.14205,
            0,
            4.87669,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.03667,
            0,
            49.17904,
            0,
            0.40588,
            0,
            12.11571,
            0,
            100.4909,
            0,
            0,
            0,
            29.31738,
            0,
            10.48892,
            "<D",
            98.05769,
            0,
        ],
        1727960940: [
            0.11366,
            0,
            0.11366,
            0,
            4.4065,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01834,
            0,
            57.38017,
            0,
            0.39926,
            0,
            12.16944,
            0,
            207.8282,
            0,
            0,
            0,
            29.31732,
            0,
            11.75711,
            "<D",
            98.47539,
            0,
        ],
        1727961000: [
            0.42463,
            0,
            0.42463,
            0,
            4.86285,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.03209,
            0,
            53.18733,
            0,
            0.43641,
            0,
            12.19613,
            0,
            257.8735,
            0,
            0,
            0,
            29.3175,
            0,
            10.60555,
            "<D",
            98.54131,
            0,
        ],
        1727961060: [
            0.17886,
            0,
            0.17886,
            0,
            4.68411,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            70.48494,
            0,
            0.47936,
            0,
            12.24996,
            0,
            261.7656,
            0,
            0,
            0,
            29.3176,
            0,
            11.99937,
            "<D",
            98.15331,
            0,
        ],
        1727961120: [
            0.18055,
            0,
            0.18055,
            0,
            4.48538,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            75.19859,
            0,
            0.49201,
            0,
            12.30172,
            0,
            264.6138,
            0,
            0,
            0,
            29.3176,
            0,
            9.67004,
            "<D",
            98.13468,
            0,
        ],
        1727961180: [
            0.32525,
            0,
            0.32525,
            0,
            4.89278,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            94.66315,
            0,
            0.35386,
            0,
            12.33572,
            0,
            267.6507,
            0,
            0,
            0,
            29.31782,
            0,
            11.75341,
            "<D",
            98.35197,
            0,
        ],
        1727961240: [
            0.18535,
            0,
            0.18535,
            0,
            5.98515,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            82.29012,
            0,
            0.28081,
            0,
            12.31559,
            0,
            270.577,
            0,
            0,
            0,
            29.31766,
            0,
            10.73839,
            "<D",
            98.75223,
            0,
        ],
        1727961300: [
            0.34176,
            0,
            0.34176,
            0,
            6.02733,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            111.7756,
            0,
            0.27699,
            0,
            12.3555,
            0,
            273.4096,
            0,
            0,
            0,
            29.31767,
            0,
            10.17359,
            "<D",
            98.35165,
            0,
        ],
        1727961360: [
            0.18115,
            0,
            0.18115,
            0,
            5.73256,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.05042,
            0,
            143.9567,
            0,
            0.20077,
            0,
            12.43352,
            0,
            276.2982,
            0,
            0,
            0,
            29.31769,
            0,
            13.36775,
            "<D",
            99.04607,
            0,
        ],
        1727961420: [
            0.19889,
            0,
            0.19889,
            0,
            5.79446,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04584,
            0,
            145.0012,
            0,
            0.2933,
            0,
            12.49873,
            0,
            279.3828,
            0,
            0,
            0,
            29.31754,
            0,
            10.63415,
            "<D",
            99.18429,
            0,
        ],
        1727961480: [
            0.12969,
            0,
            0.12969,
            0,
            6.73153,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04584,
            0,
            132.7529,
            0,
            0.33405,
            0,
            12.46634,
            0,
            282.4419,
            0,
            0,
            0,
            29.31861,
            0,
            12.06886,
            "<D",
            100.6382,
            0,
        ],
        1727961540: [
            0.03529,
            0,
            0.03529,
            0,
            6.44376,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04584,
            0,
            135.3931,
            0,
            0.56816,
            0,
            12.55406,
            0,
            285.3662,
            0,
            0,
            0,
            29.31836,
            0,
            7.98087,
            "<D",
            100.5652,
            0,
        ],
        1727961600: [
            0.04737,
            0,
            0.04737,
            0,
            6.11494,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.05042,
            0,
            102.7766,
            0,
            0.36962,
            0,
            12.68307,
            0,
            288.2069,
            0,
            0,
            0,
            29.31838,
            0,
            11.81106,
            "<D",
            98.9903,
            0,
        ],
        1727961660: [
            0.16849,
            0,
            0.16849,
            0,
            6.96824,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.06417,
            0,
            109.1556,
            0,
            0.63794,
            0,
            12.81818,
            0,
            291.2643,
            0,
            0,
            0,
            29.31853,
            0,
            10.70558,
            "<D",
            99.58583,
            0,
        ],
        1727961720: [
            0.25081,
            0,
            0.25081,
            0,
            8.55917,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.06876,
            0,
            108.8357,
            0,
            0.60225,
            0,
            12.9,
            0,
            294.0739,
            0,
            0,
            0,
            29.32051,
            0,
            11.5108,
            "<D",
            100.2519,
            0,
        ],
        1727961780: [
            0.2456,
            0,
            0.2456,
            0,
            6.54431,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.05042,
            0,
            141.8421,
            0,
            0.53352,
            0,
            12.88734,
            0,
            297.0356,
            0,
            0,
            0,
            29.32016,
            0,
            14.55924,
            "<D",
            100.2614,
            0,
        ],
        1727961840: [
            0.28128,
            0,
            0.28128,
            0,
            8.36949,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.06417,
            0,
            129.6766,
            0,
            0.59689,
            0,
            12.9141,
            0,
            299.8654,
            0,
            0,
            0,
            29.31985,
            0,
            13.50937,
            "<D",
            100.051,
            0,
        ],
        1727961900: [
            0.21641,
            0,
            0.21641,
            0,
            9.83469,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.05959,
            0,
            160.4992,
            0,
            0.50612,
            0,
            12.92866,
            0,
            302.9057,
            0,
            0,
            0,
            29.31989,
            0,
            12.98195,
            "<D",
            100.7108,
            0,
        ],
        1727961960: [
            0.1794,
            0,
            0.1794,
            0,
            9.52569,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.06417,
            0,
            161.3788,
            0,
            0.64523,
            0,
            13.04624,
            0,
            305.6708,
            0,
            0,
            0,
            29.32091,
            0,
            11.21006,
            "<D",
            100.7406,
            0,
        ],
        1727962020: [
            0.31646,
            0,
            0.31646,
            0,
            10.84818,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.06876,
            0,
            166.501,
            0,
            0.58354,
            0,
            13.1403,
            0,
            308.3327,
            0,
            0,
            0,
            29.3208,
            0,
            10.03941,
            "<D",
            100.8025,
            0,
        ],
        1727962080: [
            0.21749,
            0,
            0.21749,
            0,
            7.98048,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.06876,
            0,
            143.9328,
            0,
            0.52172,
            0,
            13.40682,
            0,
            311.3705,
            0,
            0,
            0,
            29.32092,
            0,
            12.68225,
            "<D",
            101.6647,
            0,
        ],
        1727962140: [
            0.1752,
            0,
            0.1752,
            0,
            9.85284,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.05959,
            0,
            157.5176,
            0,
            0.2265,
            0,
            13.47499,
            0,
            314.4463,
            0,
            0,
            0,
            29.32064,
            0,
            12.03065,
            "<D",
            100.1746,
            0,
        ],
        1727962200: [
            0.29804,
            0,
            0.29804,
            0,
            11.4715,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.03667,
            0,
            187.9063,
            0,
            0.35189,
            0,
            13.4423,
            0,
            317.7191,
            0,
            0,
            0,
            29.3208,
            0,
            12.82837,
            "<D",
            96.1348,
            0,
        ],
        1727962260: [
            0.26272,
            0,
            0.26272,
            0,
            6.1865,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            206.4798,
            0,
            0.25569,
            0,
            13.34422,
            0,
            320.6794,
            0,
            0,
            0,
            29.32096,
            0,
            11.38078,
            "<D",
            103.2564,
            0,
        ],
        1727962320: [
            0.1208,
            0,
            0.1208,
            0,
            8.32986,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.99598,
            0,
            224.2755,
            0,
            0.09621,
            0,
            13.34979,
            0,
            323.7336,
            0,
            0,
            0,
            29.32104,
            0,
            12.47177,
            "<D",
            102.3593,
            0,
        ],
        1727962380: [
            0.22922,
            0,
            0.22922,
            0,
            9.22255,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9742,
            0,
            200.5249,
            0,
            0.22184,
            0,
            13.49196,
            0,
            326.5713,
            0,
            0,
            0,
            29.32116,
            0,
            11.94944,
            "<D",
            96.40511,
            0,
        ],
        1727962440: [
            0.26929,
            0,
            0.26929,
            0,
            7.98426,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            197.0294,
            0,
            0.22208,
            0,
            13.70489,
            0,
            329.4368,
            0,
            0,
            0,
            29.32129,
            0,
            13.05124,
            "<D",
            106.0399,
            0,
        ],
        1727962500: [
            0.14768,
            0,
            0.14768,
            0,
            8.69011,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            161.9291,
            0,
            0.17128,
            0,
            13.80243,
            0,
            332.5065,
            0,
            0,
            0,
            29.32131,
            0,
            14.75156,
            "<D",
            116.7074,
            0,
        ],
        1727962560: [
            -0.02312,
            0,
            -0.02312,
            0,
            11.93735,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            177.9063,
            0,
            0.17343,
            0,
            14.0019,
            0,
            335.4967,
            0,
            0,
            0,
            29.32077,
            0,
            13.23141,
            "<D",
            115.7918,
            0,
        ],
        1727962620: [
            0.23338,
            0,
            0.23338,
            0,
            11.59405,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.914,
            0,
            162.2525,
            0,
            0.102,
            0,
            14.13859,
            0,
            338.6951,
            0,
            0,
            0,
            29.32047,
            0,
            14.81107,
            "<D",
            104.8465,
            0,
        ],
        1727962680: [
            0.2299,
            0,
            0.2299,
            0,
            12.86187,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            182.3858,
            0,
            0.12269,
            0,
            14.11186,
            0,
            341.8845,
            0,
            0,
            0,
            29.32092,
            0,
            13.96494,
            "<D",
            106.9902,
            0,
        ],
        1727962740: [
            0.26946,
            0,
            0.26946,
            0,
            11.0036,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            295.887,
            0,
            0.22036,
            0,
            14.39485,
            0,
            344.6374,
            0,
            0,
            0,
            29.321,
            0,
            17.07363,
            "<D",
            112.6348,
            0,
        ],
        1727962800: [
            0.22357,
            0,
            0.22357,
            0,
            12.34082,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            311.4317,
            0,
            0.21743,
            0,
            14.45302,
            0,
            347.7158,
            0,
            0,
            0,
            29.321,
            0,
            14.41917,
            "<D",
            111.2314,
            0,
        ],
        1727962860: [
            0.27884,
            0,
            0.27884,
            0,
            11.00239,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            296.569,
            0,
            0.22165,
            0,
            14.50446,
            0,
            350.5129,
            0,
            0,
            0,
            29.3209,
            0,
            15.91869,
            "<D",
            114.7422,
            0,
        ],
        1727962920: [
            0.28751,
            0,
            0.28751,
            0,
            15.64855,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            338.8952,
            0,
            0.21993,
            0,
            14.7763,
            0,
            353.5367,
            0,
            0,
            0,
            29.32031,
            0,
            12.73096,
            "<D",
            122.8561,
            0,
        ],
        1727962980: [
            0.22427,
            0,
            0.22427,
            0,
            13.84691,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            303.1687,
            0,
            0.22039,
            0,
            14.78424,
            0,
            356.7028,
            0,
            0,
            0,
            29.32011,
            0,
            16.15,
            "<D",
            110.0274,
            0,
        ],
        1727963040: [
            0.26053,
            0,
            0.26053,
            0,
            16.00717,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            295.4919,
            0,
            0.22317,
            0,
            14.95121,
            0,
            359.9373,
            0,
            0,
            0,
            29.31978,
            0,
            16.4132,
            "<D",
            104.9488,
            0,
        ],
        1727963100: [
            0.27298,
            0,
            0.27298,
            0,
            15.9851,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            304.9466,
            0,
            0.2268,
            0,
            14.94802,
            0,
            363.0913,
            0,
            0,
            0,
            29.32108,
            0,
            17.33727,
            "<D",
            102.2935,
            0,
        ],
        1727963160: [
            0.21701,
            0,
            0.21701,
            0,
            15.0836,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8796,
            0,
            307.8363,
            0,
            0.22423,
            0,
            14.99271,
            0,
            366.2156,
            0,
            0,
            0,
            29.32059,
            0,
            18.35287,
            "<D",
            105.4336,
            0,
        ],
        1727963220: [
            0.20976,
            0,
            0.20976,
            0,
            13.90153,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            303.1775,
            0,
            0.23695,
            0,
            15.35064,
            0,
            369.1917,
            0,
            0,
            0,
            29.32141,
            0,
            15.02157,
            "<D",
            105.8887,
            0,
        ],
        1727963280: [
            0.18498,
            0,
            0.18498,
            0,
            11.30503,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            304.8534,
            0,
            0.41595,
            0,
            15.31082,
            0,
            371.8308,
            0,
            0,
            0,
            29.32123,
            0,
            18.28784,
            "<D",
            104.385,
            0,
        ],
        1727963340: [
            0.16804,
            0,
            0.16804,
            0,
            12.71139,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            286.0011,
            0,
            0.26567,
            0,
            15.53598,
            0,
            375.0335,
            0,
            0,
            0,
            29.32107,
            0,
            18.95698,
            "<D",
            102.1564,
            0,
        ],
        1727963400: [
            -0.0087,
            0,
            -0.0087,
            0,
            11.84291,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            286.5207,
            0,
            0.22298,
            0,
            15.56789,
            0,
            378.0079,
            0,
            0,
            0,
            29.32146,
            0,
            15.24818,
            "<D",
            103.6698,
            0,
        ],
        1727963460: [
            0.1869,
            0,
            0.1869,
            0,
            11.64654,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            275.1342,
            0,
            0.22583,
            0,
            15.61428,
            0,
            380.8886,
            0,
            0,
            0,
            29.32121,
            0,
            15.921,
            "<D",
            103.4707,
            0,
        ],
        1727963520: [
            0.23129,
            0,
            0.23129,
            0,
            12.29839,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            351.2267,
            0,
            0.15474,
            0,
            15.67392,
            0,
            383.7003,
            0,
            0,
            0,
            29.32131,
            0,
            20.53613,
            "<D",
            103.6276,
            0,
        ],
        1727963580: [
            0.2284,
            0,
            0.2284,
            0,
            12.24594,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            248.7366,
            0,
            0.13453,
            0,
            15.80433,
            0,
            386.3776,
            0,
            0,
            0,
            29.32099,
            0,
            18.97886,
            "<D",
            105.1489,
            0,
        ],
        1727963640: [
            0.19553,
            0,
            0.19553,
            0,
            12.4312,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            212.6677,
            0,
            0.54424,
            0,
            15.98918,
            0,
            388.9557,
            0,
            0,
            0,
            29.32097,
            0,
            21.76775,
            "<D",
            105.0472,
            0,
        ],
        1727963700: [
            0.27737,
            0,
            0.27737,
            0,
            12.73965,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            185.839,
            0,
            0.53135,
            0,
            16.31186,
            0,
            391.8008,
            0,
            0,
            0,
            29.32231,
            0,
            19.41543,
            "<D",
            103.2535,
            0,
        ],
        1727963760: [
            0.21983,
            0,
            0.21983,
            0,
            13.87538,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8882,
            0,
            198.4865,
            0,
            0.65965,
            0,
            16.36945,
            0,
            395.0912,
            0,
            0,
            0,
            29.32306,
            0,
            19.41201,
            "<D",
            108.3598,
            0,
        ],
        1727963820: [
            0.18325,
            0,
            0.18325,
            0,
            13.84643,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8624,
            0,
            183.4605,
            0,
            0.80742,
            0,
            16.30289,
            0,
            398.2182,
            0,
            0,
            0,
            29.32377,
            0,
            18.89714,
            "<D",
            109.3917,
            0,
        ],
        1727963880: [
            0.22668,
            0,
            0.22668,
            0,
            14.27549,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            172.9183,
            0,
            0.90566,
            0,
            16.34724,
            0,
            401.0575,
            0,
            0,
            0,
            29.32388,
            0,
            20.23285,
            "<D",
            108.3685,
            0,
        ],
        1727963940: [
            0.19128,
            0,
            0.19128,
            0,
            14.86431,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            158.2577,
            0,
            0.88534,
            0,
            16.78364,
            0,
            403.9122,
            0,
            0,
            0,
            29.32434,
            0,
            18.06191,
            "<D",
            107.785,
            0,
        ],
        1727964000: [
            0.0012,
            0,
            0.0012,
            0,
            15.13981,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            165.007,
            0,
            0.67247,
            0,
            17.04169,
            0,
            406.9708,
            0,
            0,
            0,
            29.32403,
            0,
            17.59393,
            "<D",
            106.4795,
            0,
        ],
        1727964060: [
            0.25198,
            0,
            0.25198,
            0,
            15.96465,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            178.8562,
            0,
            0.26752,
            0,
            17.37164,
            0,
            409.8902,
            0,
            0,
            0,
            29.32452,
            0,
            18.82929,
            "<D",
            103.943,
            0,
        ],
        1727964120: [
            0.18689,
            0,
            0.18689,
            0,
            14.82371,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            179.7386,
            0,
            0.35921,
            0,
            16.976,
            0,
            412.9558,
            0,
            0,
            0,
            29.32483,
            0,
            18.84185,
            "<D",
            100.8489,
            0,
        ],
        1727964180: [
            0.35654,
            0,
            0.35654,
            0,
            14.4649,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            198.4003,
            0,
            0.53398,
            0,
            16.87396,
            0,
            415.5298,
            0,
            0,
            0,
            29.32447,
            0,
            23.61339,
            "<D",
            106.3543,
            0,
        ],
        1727964240: [
            0.23211,
            0,
            0.23211,
            0,
            13.95065,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            233.5255,
            0,
            0.65539,
            0,
            17.07302,
            0,
            418.5078,
            0,
            0,
            0,
            29.32411,
            0,
            18.43911,
            "<D",
            106.7415,
            0,
        ],
        1727964300: [
            0.36311,
            0,
            0.36311,
            0,
            13.60906,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            247.3459,
            0,
            0.62578,
            0,
            17.15261,
            0,
            421.2256,
            0,
            0,
            0,
            29.32405,
            0,
            20.58316,
            "<D",
            106.8264,
            0,
        ],
        1727964360: [
            0.22422,
            0,
            0.22422,
            0,
            10.8416,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            151.5463,
            0,
            0.53399,
            0,
            17.24623,
            0,
            424.1585,
            0,
            0,
            0,
            29.32425,
            0,
            20.60642,
            "<D",
            105.9814,
            0,
        ],
        1727964420: [
            0.12059,
            0,
            0.12059,
            0,
            14.49346,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            137.993,
            0,
            0.45524,
            0,
            17.31854,
            0,
            427.1576,
            0,
            0,
            0,
            29.32425,
            0,
            18.83888,
            "<D",
            109.3497,
            0,
        ],
        1727964480: [
            0.24768,
            0,
            0.24768,
            0,
            16.70988,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            131.5763,
            0,
            0.23281,
            0,
            17.46167,
            0,
            429.8981,
            0,
            0,
            0,
            29.32424,
            0,
            21.31673,
            "<D",
            108.0745,
            0,
        ],
        1727964540: [
            0.1835,
            0,
            0.1835,
            0,
            15.53337,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            143.5812,
            0,
            0.48833,
            0,
            17.48782,
            0,
            432.5867,
            0,
            0,
            0,
            29.32423,
            0,
            20.11972,
            "<D",
            109.223,
            0,
        ],
        1727964600: [
            0.17987,
            0,
            0.17987,
            0,
            15.62649,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            127.7137,
            0,
            0.40045,
            0,
            17.85547,
            0,
            435.1328,
            0,
            0,
            0,
            29.32449,
            0,
            17.0197,
            "<D",
            112.9697,
            0,
        ],
        1727964660: [
            0.14723,
            0,
            0.14723,
            0,
            16.906,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            157.8887,
            0,
            0.6841,
            0,
            17.99898,
            0,
            437.978,
            0,
            0,
            0,
            29.32467,
            0,
            17.15741,
            "<D",
            112.5223,
            0,
        ],
        1727964720: [
            0.02837,
            0,
            0.02837,
            0,
            17.0882,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            119.414,
            0,
            0.55759,
            0,
            17.92591,
            0,
            440.9266,
            0,
            0,
            0,
            29.32479,
            0,
            18.78743,
            "<D",
            112.3015,
            0,
        ],
        1727964780: [
            0.07639,
            0,
            0.07639,
            0,
            15.10878,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            207.3911,
            0,
            0.46147,
            0,
            17.94808,
            0,
            443.9408,
            0,
            0,
            0,
            29.32537,
            0,
            22.18075,
            "<D",
            112.7399,
            0,
        ],
        1727964840: [
            0.23168,
            0,
            0.23168,
            0,
            17.02991,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            131.9233,
            0,
            0.13773,
            0,
            18.07721,
            0,
            446.7141,
            0,
            0,
            0,
            29.32493,
            0,
            18.76547,
            "<D",
            112.7317,
            0,
        ],
        1727964900: [
            0.2916,
            0,
            0.2916,
            0,
            16.49206,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            186.0032,
            0,
            0.76503,
            0,
            18.08745,
            0,
            449.7739,
            0,
            0,
            0,
            29.3249,
            0,
            19.79671,
            "<D",
            112.6037,
            0,
        ],
        1727964960: [
            0.2061,
            0,
            0.2061,
            0,
            16.5286,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            181.735,
            0,
            0.4091,
            0,
            18.40689,
            0,
            452.5068,
            0,
            0,
            0,
            29.32485,
            0,
            18.85167,
            "<D",
            112.6516,
            0,
        ],
        1727965020: [
            0.27486,
            0,
            0.27486,
            0,
            17.16096,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            153.3476,
            0,
            0.26018,
            0,
            18.44874,
            0,
            454.9849,
            0,
            0,
            0,
            29.32485,
            0,
            19.69806,
            "<D",
            112.6985,
            0,
        ],
        1727965080: [
            0.24446,
            0,
            0.24446,
            0,
            17.4347,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            133.7181,
            0,
            0.40447,
            0,
            18.186,
            0,
            457.7343,
            0,
            0,
            0,
            29.32469,
            0,
            20.5672,
            "<D",
            110.7489,
            0,
        ],
        1727965140: [
            0.11429,
            0,
            0.11429,
            0,
            16.86231,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            131.5675,
            0,
            0.28558,
            0,
            18.31498,
            0,
            460.9272,
            0,
            0,
            0,
            29.32616,
            0,
            19.79916,
            "<D",
            110.215,
            0,
        ],
        1727965200: [
            0.22397,
            0,
            0.22397,
            0,
            17.63741,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            110.6191,
            0,
            0.4624,
            0,
            18.51782,
            0,
            464.311,
            0,
            0,
            0,
            29.32562,
            0,
            20.85263,
            "<D",
            109.3397,
            0,
        ],
        1727965260: [
            0.31348,
            0,
            0.31348,
            0,
            17.62963,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            170.9205,
            0,
            0.39018,
            0,
            18.38681,
            0,
            467.0663,
            0,
            0,
            0,
            29.32754,
            0,
            19.07149,
            "<D",
            108.6105,
            0,
        ],
        1727965320: [
            0.21498,
            0,
            0.21498,
            0,
            18.04361,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            48.00187,
            0,
            0.20871,
            0,
            18.42163,
            0,
            470.0396,
            0,
            0,
            0,
            29.32668,
            0,
            16.56495,
            "<D",
            108.4088,
            0,
        ],
        1727965380: [
            0.28048,
            0,
            0.28048,
            0,
            17.86909,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            132.005,
            0,
            0.23871,
            0,
            18.61436,
            0,
            472.6486,
            0,
            0,
            0,
            29.32604,
            0,
            17.67527,
            "<D",
            108.0143,
            0,
        ],
        1727965440: [
            0.12568,
            0,
            0.12568,
            0,
            18.34579,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            189.8764,
            0,
            0.26612,
            0,
            18.71111,
            0,
            475.066,
            0,
            0,
            0,
            29.32629,
            0,
            19.14467,
            "<D",
            107.3221,
            0,
        ],
        1727965500: [
            0.17159,
            0,
            0.17159,
            0,
            18.66518,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            177.4149,
            0,
            0.33998,
            0,
            19.00961,
            0,
            478.6338,
            0,
            0,
            0,
            29.32543,
            0,
            20.06127,
            "<D",
            107.2726,
            0,
        ],
        1727965560: [
            0.32861,
            0,
            0.32861,
            0,
            19.4141,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            188.7197,
            0,
            0.63109,
            0,
            18.85926,
            0,
            481.8388,
            0,
            0,
            0,
            29.32494,
            0,
            18.72624,
            "<D",
            106.4253,
            0,
        ],
        1727965620: [
            0.25691,
            0,
            0.25691,
            0,
            19.22686,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            202.9349,
            0,
            0.50698,
            0,
            19.09909,
            0,
            484.8231,
            0,
            0,
            0,
            29.32459,
            0,
            16.23015,
            "<D",
            107.6131,
            0,
        ],
        1727965680: [
            0.25384,
            0,
            0.25384,
            0,
            20.78502,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            185.4985,
            0,
            0.76192,
            0,
            19.26558,
            0,
            487.5823,
            0,
            0,
            0,
            29.32477,
            0,
            17.63588,
            "<D",
            106.7996,
            0,
        ],
        1727965740: [
            0.25292,
            0,
            0.25292,
            0,
            20.97439,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            196.118,
            0,
            0.82658,
            0,
            19.56426,
            0,
            490.1257,
            0,
            0,
            0,
            29.32454,
            0,
            20.54948,
            "<D",
            110.7504,
            0,
        ],
        1727965800: [
            0.15956,
            0,
            0.15956,
            0,
            21.79341,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            220.1198,
            0,
            0.99954,
            0,
            19.62997,
            0,
            492.8946,
            0,
            0,
            0,
            29.32492,
            0,
            19.45296,
            "<D",
            108.7761,
            0,
        ],
        1727965860: [
            0.12561,
            0,
            0.12561,
            0,
            23.15138,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            214.8521,
            0,
            0.80423,
            0,
            19.34234,
            0,
            496.2155,
            0,
            0,
            0,
            29.32504,
            0,
            19.76969,
            "<D",
            109.8331,
            0,
        ],
        1727965920: [
            0.23673,
            0,
            0.23673,
            0,
            22.32848,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            206.5283,
            0,
            0.40505,
            0,
            19.27569,
            0,
            499.3169,
            0,
            0,
            0,
            29.32496,
            0,
            19.60719,
            "<D",
            108.8935,
            0,
        ],
        1727965980: [
            0.19997,
            0,
            0.19997,
            0,
            22.30359,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            189.6729,
            0,
            1.07821,
            0,
            19.11281,
            0,
            501.9741,
            0,
            0,
            0,
            29.32491,
            0,
            18.57029,
            "<D",
            108.4216,
            0,
        ],
        1727966040: [
            0.17128,
            0,
            0.17128,
            0,
            22.28806,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            201.9655,
            0,
            1.12348,
            0,
            19.30412,
            0,
            504.8033,
            0,
            0,
            0,
            29.32475,
            0,
            21.15081,
            "<D",
            109.5658,
            0,
        ],
        1727966100: [
            0.28309,
            0,
            0.28309,
            0,
            22.87275,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            205.847,
            0,
            0.78572,
            0,
            19.35662,
            0,
            507.4405,
            0,
            0,
            0,
            29.32453,
            0,
            21.05643,
            "<D",
            110.8308,
            0,
        ],
        1727966160: [
            0.21746,
            0,
            0.21746,
            0,
            22.82015,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            263.2478,
            0,
            0.23084,
            0,
            19.14696,
            0,
            510.1104,
            0,
            0,
            0,
            29.32432,
            0,
            19.3321,
            "<D",
            111.3528,
            0,
        ],
        1727966220: [
            0.18709,
            0,
            0.18709,
            0,
            22.32654,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            208.4812,
            0,
            0.37855,
            0,
            19.04525,
            0,
            513.152,
            0,
            0,
            0,
            29.32443,
            0,
            21.96502,
            "<D",
            110.002,
            0,
        ],
        1727966280: [
            0.17684,
            0,
            0.17684,
            0,
            22.29613,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            204.1792,
            0,
            0.16185,
            0,
            18.99199,
            0,
            516.0327,
            0,
            0,
            0,
            29.32469,
            0,
            20.84533,
            "<D",
            108.5184,
            0,
        ],
        1727966340: [
            0.07436,
            0,
            0.07436,
            0,
            22.10568,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            197.0291,
            0,
            0.64026,
            0,
            19.39207,
            0,
            518.9702,
            0,
            0,
            0,
            29.32461,
            0,
            15.26508,
            "<D",
            109.8269,
            0,
        ],
        1727966400: [
            -0.04711,
            0,
            -0.04711,
            0,
            22.66831,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            203.7015,
            0,
            0.73721,
            0,
            19.77217,
            0,
            521.8185,
            0,
            0,
            0,
            29.32496,
            0,
            20.26119,
            "<D",
            108.3068,
            0,
        ],
        1727966460: [
            0.21466,
            0,
            0.21466,
            0,
            22.27244,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            207.7085,
            0,
            0.75989,
            0,
            19.76848,
            0,
            524.4822,
            0,
            0,
            0,
            29.32452,
            0,
            18.4883,
            "<D",
            107.6546,
            0,
        ],
        1727966520: [
            0.16307,
            0,
            0.16307,
            0,
            24.37725,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            182.496,
            0,
            0.60681,
            0,
            19.97845,
            0,
            527.1194,
            0,
            0,
            0,
            29.32443,
            0,
            18.9447,
            "<D",
            107.6679,
            0,
        ],
        1727966580: [
            0.20118,
            0,
            0.20118,
            0,
            24.07718,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            183.4249,
            0,
            0.74712,
            0,
            20.25587,
            0,
            529.4033,
            0,
            0,
            0,
            29.32465,
            0,
            18.86264,
            "<D",
            106.4656,
            0,
        ],
        1727966640: [
            0.13258,
            0,
            0.13258,
            0,
            23.14439,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            176.4836,
            0,
            1.00507,
            0,
            20.09749,
            0,
            530.6386,
            0,
            0,
            0,
            29.3247,
            0,
            20.67306,
            "<D",
            105.2245,
            0,
        ],
        1727966700: [
            0.26004,
            0,
            0.26004,
            0,
            23.37118,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            156.55,
            0,
            0.8729,
            0,
            19.9988,
            0,
            530.9052,
            0,
            0,
            0,
            29.32492,
            0,
            17.80575,
            "<D",
            104.9525,
            0,
        ],
        1727966760: [
            0.34311,
            0,
            0.34311,
            0,
            23.17501,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            182.8307,
            0,
            0.94026,
            0,
            19.81802,
            0,
            533.322,
            0,
            0,
            0,
            29.32496,
            0,
            22.79766,
            "<D",
            104.4517,
            0,
        ],
        1727966820: [
            0.45766,
            0,
            0.45766,
            0,
            23.82325,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            117.151,
            0,
            0.75662,
            0,
            19.95684,
            0,
            536.0303,
            0,
            0,
            0,
            29.32478,
            0,
            18.97712,
            "<D",
            104.2097,
            0,
        ],
        1727966880: [
            0.10075,
            0,
            0.10075,
            0,
            23.41193,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            124.0103,
            0,
            0.57124,
            0,
            20.15639,
            0,
            538.6397,
            0,
            0,
            0,
            29.32466,
            0,
            17.93443,
            "<D",
            105.7226,
            0,
        ],
        1727966940: [
            0.16951,
            0,
            0.16951,
            0,
            21.76708,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            108.096,
            0,
            0.52584,
            0,
            19.91272,
            0,
            541.5075,
            0,
            0,
            0,
            29.32448,
            0,
            20.07789,
            "<D",
            105.9287,
            0,
        ],
        1727967000: [
            0.27994,
            0,
            0.27994,
            0,
            22.17829,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            62.37933,
            0,
            0.29333,
            0,
            19.99117,
            0,
            543.9189,
            0,
            0,
            0,
            29.32358,
            0,
            18.72032,
            "<D",
            105.4729,
            0,
        ],
        1727967060: [
            0.23632,
            0,
            0.23632,
            0,
            23.62018,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            99.6534,
            0,
            0.30433,
            0,
            19.93553,
            0,
            546.1444,
            0,
            0,
            0,
            29.32262,
            0,
            18.69144,
            "<D",
            105.5223,
            0,
        ],
        1727967120: [
            0.19765,
            0,
            0.19765,
            0,
            23.81591,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            140.5299,
            0,
            0.35982,
            0,
            19.91165,
            0,
            548.5884,
            0,
            0,
            0,
            29.32342,
            0,
            18.3761,
            "<D",
            108.184,
            0,
        ],
        1727967180: [
            0.26396,
            0,
            0.26396,
            0,
            22.61621,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            148.2449,
            0,
            0.59374,
            0,
            20.15093,
            0,
            550.8778,
            0,
            0,
            0,
            29.32309,
            0,
            19.19156,
            "<D",
            109.1367,
            0,
        ],
        1727967240: [
            0.16646,
            0,
            0.16646,
            0,
            21.7139,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            176.1296,
            0,
            0.40909,
            0,
            20.42777,
            0,
            553.2393,
            0,
            0,
            0,
            29.32303,
            0,
            18.37388,
            "<D",
            108.5549,
            0,
        ],
        1727967300: [
            0.1559,
            0,
            0.1559,
            0,
            22.37599,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            246.0605,
            0,
            0.6447,
            0,
            20.1465,
            0,
            555.5012,
            0,
            0,
            0,
            29.32114,
            0,
            20.69665,
            "<D",
            107.736,
            0,
        ],
        1727967360: [
            0.43758,
            0,
            0.43758,
            0,
            23.22723,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            354.7608,
            0,
            0.28545,
            0,
            20.04404,
            0,
            557.6382,
            0,
            0,
            0,
            29.32137,
            0,
            19.02594,
            "<D",
            106.2232,
            0,
        ],
        1727967420: [
            0.28096,
            0,
            0.28096,
            0,
            23.16264,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            328.5168,
            0,
            0.30501,
            0,
            20.36142,
            0,
            559.7112,
            0,
            0,
            0,
            29.32136,
            0,
            19.05819,
            "<D",
            104.8809,
            0,
        ],
        1727967480: [
            0.13261,
            0,
            0.13261,
            0,
            22.30883,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            217.4097,
            0,
            0.16675,
            0,
            20.64981,
            0,
            562.1263,
            0,
            0,
            0,
            29.32142,
            0,
            19.86851,
            "<D",
            105.3315,
            0,
        ],
        1727967540: [
            0.22981,
            0,
            0.22981,
            0,
            20.7476,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            86.20025,
            0,
            0.53781,
            0,
            20.55461,
            0,
            564.2954,
            0,
            0,
            0,
            29.32135,
            0,
            21.6254,
            "<D",
            104.9785,
            0,
        ],
        1727967600: [
            0.29244,
            0,
            0.29244,
            0,
            21.39084,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            114.0633,
            0,
            1.05647,
            0,
            20.30182,
            0,
            566.3121,
            0,
            0,
            0,
            29.32153,
            0,
            17.79397,
            "<D",
            104.6864,
            0,
        ],
        1727967660: [
            0.27843,
            0,
            0.27843,
            0,
            21.49438,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            151.2928,
            0,
            0.26671,
            0,
            20.34035,
            0,
            568.6728,
            0,
            0,
            0,
            29.32142,
            0,
            19.10585,
            "<D",
            104.9735,
            0,
        ],
        1727967720: [
            0.17604,
            0,
            0.17604,
            0,
            21.26009,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            246.0442,
            0,
            0.51692,
            0,
            20.28662,
            0,
            571.3107,
            0,
            0,
            0,
            29.3214,
            0,
            22.4062,
            "<D",
            105.4551,
            0,
        ],
        1727967780: [
            0.07703,
            0,
            0.07703,
            0,
            24.02454,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            312.8352,
            0,
            0.12925,
            0,
            20.49256,
            0,
            573.8665,
            0,
            0,
            0,
            29.32133,
            0,
            19.24191,
            "<D",
            104.7757,
            0,
        ],
        1727967840: [
            0.16721,
            0,
            0.16721,
            0,
            22.97582,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            279.3571,
            0,
            0.54302,
            0,
            20.84085,
            0,
            576.1378,
            0,
            0,
            0,
            29.32149,
            0,
            17.9549,
            "<D",
            105.1472,
            0,
        ],
        1727967900: [
            0.16774,
            0,
            0.16774,
            0,
            23.24605,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            359.9494,
            0,
            0.38847,
            0,
            20.83651,
            0,
            578.6207,
            0,
            0,
            0,
            29.3215,
            0,
            15.42203,
            "<D",
            104.1638,
            0,
        ],
        1727967960: [
            0.12085,
            0,
            0.12085,
            0,
            23.36348,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            55.51027,
            0,
            0.86847,
            0,
            20.73183,
            0,
            580.8478,
            0,
            0,
            0,
            29.32135,
            0,
            20.87167,
            "<D",
            108.1866,
            0,
        ],
        1727968020: [
            0.22161,
            0,
            0.22161,
            0,
            22.74781,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            93.30783,
            0,
            0.9255,
            0,
            20.87091,
            0,
            583.032,
            0,
            0,
            0,
            29.32103,
            0,
            18.49708,
            "<D",
            107.6237,
            0,
        ],
        1727968080: [
            0.26469,
            0,
            0.26469,
            0,
            23.75151,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            34.33752,
            0,
            0.53877,
            0,
            21.16422,
            0,
            585.2911,
            0,
            0,
            0,
            29.321,
            0,
            17.53349,
            "<D",
            108.4646,
            0,
        ],
        1727968140: [
            0.29986,
            0,
            0.29986,
            0,
            23.3614,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            61.98831,
            0,
            0.25556,
            0,
            21.02624,
            0,
            588.0763,
            0,
            0,
            0,
            29.32097,
            0,
            20.95368,
            "<D",
            109.2872,
            0,
        ],
        1727968200: [
            0.19871,
            0,
            0.19871,
            0,
            23.87499,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            194.9513,
            0,
            0.61103,
            0,
            21.0092,
            0,
            590.6174,
            0,
            0,
            0,
            29.32099,
            0,
            17.17629,
            "<D",
            110.1984,
            0,
        ],
        1727968260: [
            0.11096,
            0,
            0.11096,
            0,
            23.80468,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            172.9007,
            0,
            0.63717,
            0,
            21.12076,
            0,
            592.9889,
            0,
            0,
            0,
            29.32109,
            0,
            20.8903,
            "<D",
            108.7166,
            0,
        ],
        1727968320: [
            0.29168,
            0,
            0.29168,
            0,
            24.62879,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            164.7215,
            0,
            0.92352,
            0,
            21.19775,
            0,
            595.4083,
            0,
            0,
            0,
            29.32118,
            0,
            18.7786,
            "<D",
            108.4087,
            0,
        ],
        1727968380: [
            0.16899,
            0,
            0.16899,
            0,
            24.09064,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            137.8694,
            0,
            0.61009,
            0,
            21.37945,
            0,
            597.835,
            0,
            0,
            0,
            29.32127,
            0,
            20.67956,
            "<D",
            108.4356,
            0,
        ],
        1727968440: [
            0.21075,
            0,
            0.21075,
            0,
            22.59664,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            136.6709,
            0,
            0.75493,
            0,
            21.4841,
            0,
            599.724,
            0,
            0,
            0,
            29.32169,
            0,
            20.11244,
            "<D",
            108.8273,
            0,
        ],
        1727968500: [
            0.13756,
            0,
            0.13756,
            0,
            22.26456,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            73.721,
            0,
            1.19185,
            0,
            21.73209,
            0,
            602.3612,
            0,
            0,
            0,
            29.32297,
            0,
            17.42926,
            "<D",
            108.008,
            0,
        ],
        1727968560: [
            0.34947,
            0,
            0.34947,
            0,
            22.83901,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            117.9647,
            0,
            0.63024,
            0,
            21.48587,
            0,
            604.6293,
            0,
            0,
            0,
            29.32464,
            0,
            20.13841,
            "<D",
            108.651,
            0,
        ],
        1727968620: [
            0.23726,
            0,
            0.23726,
            0,
            23.88595,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            160.7534,
            0,
            0.78849,
            0,
            21.26321,
            0,
            607.2331,
            0,
            0,
            0,
            29.32353,
            0,
            21.10869,
            "<D",
            108.7742,
            0,
        ],
        1727968680: [
            0.26477,
            0,
            0.26477,
            0,
            27.03488,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            56.45658,
            0,
            0.37758,
            0,
            21.29712,
            0,
            609.0294,
            0,
            0,
            0,
            29.32388,
            0,
            18.80179,
            "<D",
            108.297,
            0,
        ],
        1727968740: [
            0.23407,
            0,
            0.23407,
            0,
            27.95635,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            154.3598,
            0,
            0.34207,
            0,
            21.30539,
            0,
            611.3966,
            0,
            0,
            0,
            29.32337,
            0,
            17.93767,
            "<D",
            109.4005,
            0,
        ],
        1727968800: [
            0.32195,
            0,
            0.32195,
            0,
            27.076,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            171.8452,
            0,
            0.71941,
            0,
            21.53434,
            0,
            613.1215,
            0,
            0,
            0,
            29.3229,
            0,
            17.70152,
            "<D",
            110.744,
            0,
        ],
        1727968860: [
            0.21986,
            0,
            0.21986,
            0,
            27.55742,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            210.0859,
            0,
            1.00204,
            0,
            21.71752,
            0,
            615.0622,
            0,
            0,
            0,
            29.32286,
            0,
            20.49104,
            "<D",
            111.5232,
            0,
        ],
        1727968920: [
            0.23339,
            0,
            0.23339,
            0,
            30.67727,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            224.1842,
            0,
            1.11863,
            0,
            21.53433,
            0,
            617.6287,
            0,
            0,
            0,
            29.32182,
            0,
            19.4136,
            "<D",
            109.5607,
            0,
        ],
        1727968980: [
            0.2697,
            0,
            0.2697,
            0,
            30.89963,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            242.0803,
            0,
            0.56363,
            0,
            21.41926,
            0,
            619.5396,
            0,
            0,
            0,
            29.32295,
            0,
            20.16565,
            "<D",
            109.6669,
            0,
        ],
        1727969040: [
            0.27745,
            0,
            0.27745,
            0,
            29.80651,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            172.5985,
            0,
            0.87436,
            0,
            21.72115,
            0,
            621.0684,
            0,
            0,
            0,
            29.32387,
            0,
            18.33568,
            "<D",
            111.6308,
            0,
        ],
        1727969100: [
            0.03667,
            0,
            0.03667,
            0,
            28.91858,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            182.1653,
            0,
            1.10141,
            0,
            21.81692,
            0,
            601.4829,
            0,
            0,
            0,
            29.32452,
            0,
            16.5601,
            "<D",
            111.7684,
            0,
        ],
        1727969160: [
            0.23651,
            0,
            0.23651,
            0,
            30.83632,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            205.7785,
            0,
            1.4835,
            0,
            21.81431,
            0,
            545.8196,
            0,
            0,
            0,
            29.32371,
            0,
            21.07775,
            "<D",
            110.9817,
            0,
        ],
        1727969220: [
            0.18201,
            0,
            0.18201,
            0,
            32.0576,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            168.2652,
            0,
            1.3115,
            0,
            21.63374,
            0,
            576.6537,
            0,
            0,
            0,
            29.32461,
            0,
            16.49627,
            "<D",
            110.2891,
            0,
        ],
        1727969280: [
            0.19872,
            0,
            0.19872,
            0,
            29.13338,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            150.6542,
            0,
            1.41775,
            0,
            21.54198,
            0,
            628.2943,
            0,
            0,
            0,
            29.32414,
            0,
            17.38867,
            "<D",
            109.4008,
            0,
        ],
        1727969340: [
            0.27918,
            0,
            0.27918,
            0,
            28.4848,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            137.8959,
            0,
            1.2397,
            0,
            21.35135,
            0,
            629.9558,
            0,
            0,
            0,
            29.32414,
            0,
            17.80997,
            "<D",
            108.784,
            0,
        ],
        1727969400: [
            0.24475,
            0,
            0.24475,
            0,
            29.29676,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            126.7955,
            0,
            1.27223,
            0,
            21.22864,
            0,
            632.5999,
            0,
            0,
            0,
            29.32379,
            0,
            18.87326,
            "<D",
            108.2187,
            0,
        ],
        1727969460: [
            0.2151,
            0,
            0.2151,
            0,
            30.94453,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            163.1795,
            0,
            0.47961,
            0,
            21.20773,
            0,
            634.2107,
            0,
            0,
            0,
            29.32316,
            0,
            20.31183,
            "<D",
            108.0689,
            0,
        ],
        1727969520: [
            0.15213,
            0,
            0.15213,
            0,
            30.61085,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            190.4735,
            0,
            0.78906,
            0,
            21.33741,
            0,
            635.4763,
            0,
            0,
            0,
            29.32336,
            0,
            17.90318,
            "<D",
            108.9808,
            0,
        ],
        1727969580: [
            0.39303,
            0,
            0.39303,
            0,
            30.43263,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            275.7993,
            0,
            0.0311,
            0,
            21.45812,
            0,
            637.2802,
            0,
            0,
            0,
            29.32271,
            0,
            18.89076,
            "<D",
            109.016,
            0,
        ],
        1727969640: [
            0.26015,
            0,
            0.26015,
            0,
            30.16817,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            341.8113,
            0,
            0.53225,
            0,
            21.43012,
            0,
            639.3729,
            0,
            0,
            0,
            29.32198,
            0,
            17.20427,
            "<D",
            109.4358,
            0,
        ],
        1727969700: [
            0.15773,
            0,
            0.15773,
            0,
            30.43084,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            334.84,
            0,
            1.04614,
            0,
            21.55822,
            0,
            641.3957,
            0,
            0,
            0,
            29.32141,
            0,
            21.025,
            "<D",
            109.9059,
            0,
        ],
        1727969760: [
            0.27027,
            0,
            0.27027,
            0,
            30.5414,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            315.9191,
            0,
            1.05858,
            0,
            21.90194,
            0,
            643.3907,
            0,
            0,
            0,
            29.32183,
            0,
            16.62647,
            "<D",
            112.5191,
            0,
        ],
        1727969820: [
            0.23291,
            0,
            0.23291,
            0,
            30.8259,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            345.2578,
            0,
            0.87373,
            0,
            21.65594,
            0,
            645.1748,
            0,
            0,
            0,
            29.32117,
            0,
            17.96593,
            "<D",
            110.5533,
            0,
        ],
        1727969880: [
            0.1714,
            0,
            0.1714,
            0,
            30.98423,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            352.535,
            0,
            1.09056,
            0,
            21.79458,
            0,
            646.6264,
            0,
            0,
            0,
            29.32217,
            0,
            16.16665,
            "<D",
            110.6668,
            0,
        ],
        1727969940: [
            0.31269,
            0,
            0.31269,
            0,
            30.04347,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            12.00145,
            0,
            0.41554,
            0,
            22.30837,
            0,
            648.3373,
            0,
            0,
            0,
            29.32354,
            0,
            17.21901,
            "<D",
            111.1115,
            0,
        ],
        1727970000: [
            0.24244,
            0,
            0.24244,
            0,
            29.56542,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            170.599,
            0,
            0.91641,
            0,
            22.8105,
            0,
            649.9326,
            0,
            0,
            0,
            29.32203,
            0,
            18.11832,
            "<D",
            112.0927,
            0,
        ],
        1727970060: [
            0.25,
            0,
            0.25,
            0,
            29.81609,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            106.1155,
            0,
            1.22671,
            0,
            22.58249,
            0,
            652.386,
            0,
            0,
            0,
            29.32332,
            0,
            18.01637,
            "<D",
            112.0597,
            0,
        ],
        1727970120: [
            0.21321,
            0,
            0.21321,
            0,
            30.8921,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            148.5176,
            0,
            0.7476,
            0,
            22.10661,
            0,
            654.288,
            0,
            0,
            0,
            29.32235,
            0,
            14.98027,
            "<D",
            111.73,
            0,
        ],
        1727970180: [
            0.07728,
            0,
            0.07728,
            0,
            30.37079,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            86.50065,
            0,
            0.63928,
            0,
            22.03892,
            0,
            656.3962,
            0,
            0,
            0,
            29.32316,
            0,
            17.42304,
            "<D",
            111.834,
            0,
        ],
        1727970240: [
            0.3868,
            0,
            0.3868,
            0,
            31.96705,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            76.31385,
            0,
            0.32773,
            0,
            21.80284,
            0,
            657.5463,
            0,
            0,
            0,
            29.32364,
            0,
            19.01491,
            "<D",
            110.6733,
            0,
        ],
        1727970300: [
            0.13554,
            0,
            0.13554,
            0,
            32.60041,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            323.8831,
            0,
            0.24344,
            0,
            21.91878,
            0,
            659.6044,
            0,
            0,
            0,
            29.32463,
            0,
            15.28607,
            "<D",
            111.2322,
            0,
        ],
        1727970360: [
            0.14757,
            0,
            0.14757,
            0,
            33.1174,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            300.2579,
            0,
            0.63391,
            0,
            21.97815,
            0,
            660.8928,
            0,
            0,
            0,
            29.32472,
            0,
            19.19457,
            "<D",
            110.3122,
            0,
        ],
        1727970420: [
            0.14588,
            0,
            0.14588,
            0,
            32.82355,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            326.948,
            0,
            0.79261,
            0,
            22.01071,
            0,
            662.2527,
            0,
            0,
            0,
            29.32455,
            0,
            18.70313,
            "<D",
            110.201,
            0,
        ],
        1727970480: [
            0.2584,
            0,
            0.2584,
            0,
            32.37137,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            336.8425,
            0,
            0.48578,
            0,
            22.21673,
            0,
            664.1857,
            0,
            0,
            0,
            29.32458,
            0,
            19.19567,
            "<D",
            109.6905,
            0,
        ],
        1727970540: [
            0.19284,
            0,
            0.19284,
            0,
            32.41208,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            289.6529,
            0,
            0.31521,
            0,
            22.56032,
            0,
            667.5049,
            0,
            0,
            0,
            29.32474,
            0,
            16.23948,
            "<D",
            110.7399,
            0,
        ],
        1727970600: [
            0.26139,
            0,
            0.26139,
            0,
            32.5417,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            313.8873,
            0,
            0.78589,
            0,
            22.69797,
            0,
            669.1523,
            0,
            0,
            0,
            29.32531,
            0,
            16.26275,
            "<D",
            112.0664,
            0,
        ],
        1727970660: [
            0.38175,
            0,
            0.38175,
            0,
            32.82597,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            289.3128,
            0,
            0.57503,
            0,
            22.43409,
            0,
            671.1318,
            0,
            0,
            0,
            29.32537,
            0,
            19.38081,
            "<D",
            112.466,
            0,
        ],
        1727970720: [
            0.11648,
            0,
            0.11648,
            0,
            33.54491,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            264.7473,
            0,
            0.67387,
            0,
            22.41404,
            0,
            672.0571,
            0,
            0,
            0,
            29.32507,
            0,
            15.63737,
            "<D",
            111.7287,
            0,
        ],
        1727970780: [
            0.19959,
            0,
            0.19959,
            0,
            34.00077,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            284.2195,
            0,
            0.32419,
            0,
            22.88464,
            0,
            672.8387,
            0,
            0,
            0,
            29.32496,
            0,
            19.57677,
            "<D",
            112.3429,
            0,
        ],
        1727970840: [
            0.25855,
            0,
            0.25855,
            0,
            33.47881,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            320.9198,
            0,
            0.95461,
            0,
            22.99212,
            0,
            674.8115,
            0,
            0,
            0,
            29.32467,
            0,
            19.34793,
            "<D",
            114.1208,
            0,
        ],
        1727970900: [
            0.22101,
            0,
            0.22101,
            0,
            34.14722,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            335.7314,
            0,
            1.15624,
            0,
            22.59483,
            0,
            676.528,
            0,
            0,
            0,
            29.3246,
            0,
            15.63566,
            "<D",
            112.2089,
            0,
        ],
        1727970960: [
            0.30959,
            0,
            0.30959,
            0,
            37.06813,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            16.81923,
            0,
            0.74914,
            0,
            22.79037,
            0,
            677.4789,
            0,
            0,
            0,
            29.32461,
            0,
            13.89492,
            "<D",
            112.4717,
            0,
        ],
        1727971020: [
            0.18372,
            0,
            0.18372,
            0,
            36.97074,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            173.8338,
            0,
            1.07141,
            0,
            23.10954,
            0,
            678.5281,
            0,
            0,
            0,
            29.32492,
            0,
            18.31235,
            "<D",
            111.9151,
            0,
        ],
        1727971080: [
            0.3067,
            0,
            0.3067,
            0,
            36.31794,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            44.70002,
            0,
            1.19472,
            0,
            22.83211,
            0,
            680.9162,
            0,
            0,
            0,
            29.32473,
            0,
            15.00731,
            "<D",
            112.0171,
            0,
        ],
        1727971140: [
            0.1453,
            0,
            0.1453,
            0,
            37.61003,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            62.30439,
            0,
            1.46404,
            0,
            22.55158,
            0,
            681.1585,
            0,
            0,
            0,
            29.32461,
            0,
            20.1397,
            "<D",
            111.9646,
            0,
        ],
        1727971200: [
            0.22972,
            0,
            0.22972,
            0,
            38.00223,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            91.42611,
            0,
            0.7227,
            0,
            22.9067,
            0,
            682.8723,
            0,
            0,
            0,
            29.32471,
            0,
            14.8491,
            "<D",
            112.0082,
            0,
        ],
        1727971260: [
            0.22942,
            0,
            0.22942,
            0,
            38.79754,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            309.2708,
            0,
            0.41806,
            0,
            22.92629,
            0,
            683.8469,
            0,
            0,
            0,
            29.32477,
            0,
            17.88717,
            "<D",
            112.9161,
            0,
        ],
        1727971320: [
            0.11204,
            0,
            0.11204,
            0,
            38.41503,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            350.188,
            0,
            0.5608,
            0,
            22.73147,
            0,
            685.9875,
            0,
            0,
            0,
            29.32452,
            0,
            14.97926,
            "<D",
            112.0854,
            0,
        ],
        1727971380: [
            0.17336,
            0,
            0.17336,
            0,
            38.56549,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            334.3263,
            0,
            0.47239,
            0,
            22.88959,
            0,
            686.2729,
            0,
            0,
            0,
            29.32487,
            0,
            16.81052,
            "<D",
            112.6429,
            0,
        ],
        1727971440: [
            0.17306,
            0,
            0.17306,
            0,
            38.82006,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            106.467,
            0,
            0.23857,
            0,
            22.92782,
            0,
            688.3828,
            0,
            0,
            0,
            29.32443,
            0,
            17.00052,
            "<D",
            110.6843,
            0,
        ],
        1727971500: [
            0.11673,
            0,
            0.11673,
            0,
            38.13081,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            182.9816,
            0,
            0.7024,
            0,
            22.95782,
            0,
            689.0238,
            0,
            0,
            0,
            29.32445,
            0,
            17.22871,
            "<D",
            109.61,
            0,
        ],
        1727971560: [
            0.11143,
            0,
            0.11143,
            0,
            38.44612,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            172.1628,
            0,
            1.913,
            0,
            23.16582,
            0,
            689.9634,
            0,
            0,
            0,
            29.32275,
            0,
            14.78344,
            "<D",
            109.6647,
            0,
        ],
        1727971620: [
            0.12604,
            0,
            0.12604,
            0,
            39.57393,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            184.0339,
            0,
            1.57061,
            0,
            22.86407,
            0,
            691.4234,
            0,
            0,
            0,
            29.32149,
            0,
            14.76719,
            "<D",
            112.2264,
            0,
        ],
        1727971680: [
            0.08746,
            0,
            0.08746,
            0,
            40.714,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            167.7014,
            0,
            2.2282,
            0,
            22.86478,
            0,
            692.3576,
            0,
            0,
            0,
            29.32177,
            0,
            18.08637,
            "<D",
            112.3323,
            0,
        ],
        1727971740: [
            0.10087,
            0,
            0.10087,
            0,
            40.49234,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            152.2254,
            0,
            1.96466,
            0,
            23.04063,
            0,
            693.3154,
            0,
            0,
            0,
            29.32151,
            0,
            18.40947,
            "<D",
            112.7298,
            0,
        ],
        1727971800: [
            0.06487,
            0,
            0.06487,
            0,
            39.3417,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            115.085,
            0,
            0.8349,
            0,
            23.18559,
            0,
            697.7224,
            0,
            0,
            0,
            29.32146,
            0,
            14.53013,
            "<D",
            111.6491,
            0,
        ],
        1727971860: [
            0.08057,
            0,
            0.08057,
            0,
            39.82562,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            153.0833,
            0,
            1.02492,
            0,
            23.13553,
            0,
            700.3144,
            0,
            0,
            0,
            29.32104,
            0,
            15.4585,
            "<D",
            110.7646,
            0,
        ],
        1727971920: [
            0.20882,
            0,
            0.20882,
            0,
            40.34431,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            142.1477,
            0,
            1.29742,
            0,
            23.10245,
            0,
            699.2534,
            0,
            0,
            0,
            29.32099,
            0,
            18.92395,
            "<D",
            111.2313,
            0,
        ],
        1727971980: [
            0.33732,
            0,
            0.33732,
            0,
            39.1598,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            135.4409,
            0,
            0.95613,
            0,
            23.0465,
            0,
            702.2744,
            0,
            0,
            0,
            29.32096,
            0,
            15.20758,
            "<D",
            110.5329,
            0,
        ],
        1727972040: [
            0.37351,
            0,
            0.37351,
            0,
            40.32524,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            196.0362,
            0,
            0.57508,
            0,
            22.94353,
            0,
            703.7784,
            0,
            0,
            0,
            29.32097,
            0,
            14.85172,
            "<D",
            110.2167,
            0,
        ],
        1727972100: [
            0.1482,
            0,
            0.1482,
            0,
            41.33669,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            127.3243,
            0,
            0.8001,
            0,
            23.02722,
            0,
            705.7998,
            0,
            0,
            0,
            29.32095,
            0,
            14.77972,
            "<D",
            111.3464,
            0,
        ],
        1727972160: [
            0.35104,
            0,
            0.35104,
            0,
            41.31305,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            189.9205,
            0,
            0.79086,
            0,
            22.997,
            0,
            707.6413,
            0,
            0,
            0,
            29.32095,
            0,
            15.97474,
            "<D",
            110.7855,
            0,
        ],
        1727972220: [
            0.31214,
            0,
            0.31214,
            0,
            40.80236,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            174.2918,
            0,
            0.70358,
            0,
            23.46695,
            0,
            707.5103,
            0,
            0,
            0,
            29.32094,
            0,
            14.11987,
            "<D",
            111.0339,
            0,
        ],
        1727972280: [
            0.33148,
            0,
            0.33148,
            0,
            41.22776,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            185.0718,
            0,
            0.15365,
            0,
            23.5579,
            0,
            709.2,
            0,
            0,
            0,
            29.32096,
            0,
            15.71912,
            "<D",
            109.2451,
            0,
        ],
        1727972340: [
            0.23778,
            0,
            0.23778,
            0,
            40.71505,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            156.6552,
            0,
            0.39193,
            0,
            23.57105,
            0,
            710.394,
            0,
            0,
            0,
            29.32092,
            0,
            15.96912,
            "<D",
            107.9005,
            0,
        ],
        1727972400: [
            0.28464,
            0,
            0.28464,
            0,
            41.03561,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            118.3551,
            0,
            1.14532,
            0,
            23.4459,
            0,
            712.2697,
            0,
            0,
            0,
            29.32093,
            0,
            18.90025,
            "<D",
            108.1397,
            0,
        ],
        1727972460: [
            0.45968,
            0,
            0.45968,
            0,
            40.67233,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            141.4836,
            0,
            1.43214,
            0,
            22.92804,
            0,
            712.8157,
            0,
            0,
            0,
            29.32134,
            0,
            14.24198,
            "<D",
            109.3062,
            0,
        ],
        1727972520: [
            0.29304,
            0,
            0.29304,
            0,
            42.36024,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            177.3522,
            0,
            0.28373,
            0,
            22.93983,
            0,
            712.3513,
            0,
            0,
            0,
            29.32163,
            0,
            19.06619,
            "<D",
            110.2315,
            0,
        ],
        1727972580: [
            0.17124,
            0,
            0.17124,
            0,
            42.32538,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            102.8929,
            0,
            0.87312,
            0,
            23.203,
            0,
            712.3853,
            0,
            0,
            0,
            29.32138,
            0,
            17.85384,
            "<D",
            110.2064,
            0,
        ],
        1727972640: [
            0.15479,
            0,
            0.15479,
            0,
            41.4708,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            115.2155,
            0,
            1.62993,
            0,
            23.26903,
            0,
            714.5234,
            0,
            0,
            0,
            29.32157,
            0,
            14.54266,
            "<D",
            109.636,
            0,
        ],
        1727972700: [
            0.27047,
            0,
            0.27047,
            0,
            41.79315,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            99.1427,
            0,
            0.92932,
            0,
            23.53111,
            0,
            715.9968,
            0,
            0,
            0,
            29.32107,
            0,
            13.89185,
            "<D",
            110.6615,
            0,
        ],
        1727972760: [
            0.123,
            0,
            0.123,
            0,
            41.05916,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            102.2351,
            0,
            1.18737,
            0,
            23.53779,
            0,
            717.7877,
            0,
            0,
            0,
            29.321,
            0,
            13.02786,
            "<D",
            110.2291,
            0,
        ],
        1727972820: [
            0.29056,
            0,
            0.29056,
            0,
            41.01258,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            106.9795,
            0,
            1.49316,
            0,
            23.35312,
            0,
            720.4462,
            0,
            0,
            0,
            29.32094,
            0,
            16.98825,
            "<D",
            108.5068,
            0,
        ],
        1727972880: [
            0.29404,
            0,
            0.29404,
            0,
            42.02858,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            100.5561,
            0,
            0.95294,
            0,
            23.43457,
            0,
            721.0923,
            0,
            0,
            0,
            29.32094,
            0,
            17.98188,
            "<D",
            108.9811,
            0,
        ],
        1727972940: [
            0.3035,
            0,
            0.3035,
            0,
            42.31856,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            104.5563,
            0,
            0.65517,
            0,
            23.59732,
            0,
            721.2713,
            0,
            0,
            0,
            29.32093,
            0,
            15.49331,
            "<D",
            109.8853,
            0,
        ],
        1727973000: [
            0.15028,
            0,
            0.15028,
            0,
            40.93382,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            142.9514,
            0,
            1.80409,
            0,
            23.90489,
            0,
            722.1896,
            0,
            0,
            0,
            29.32006,
            0,
            13.8017,
            "<D",
            110.4,
            0,
        ],
        1727973060: [
            0.13596,
            0,
            0.13596,
            0,
            41.13026,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            113.5335,
            0,
            1.57298,
            0,
            23.69417,
            0,
            723.1096,
            0,
            0,
            0,
            29.32134,
            0,
            19.0749,
            "<D",
            109.326,
            0,
        ],
        1727973120: [
            0.09999,
            0,
            0.09999,
            0,
            42.37172,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            108.8343,
            0,
            1.00424,
            0,
            23.3387,
            0,
            724.1077,
            0,
            0,
            0,
            29.32067,
            0,
            14.9647,
            "<D",
            108.5777,
            0,
        ],
        1727973180: [
            0.28173,
            0,
            0.28173,
            0,
            42.25289,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            107.3145,
            0,
            1.04816,
            0,
            23.524,
            0,
            725.4389,
            0,
            0,
            0,
            29.32101,
            0,
            12.15646,
            "<D",
            110.0422,
            0,
        ],
        1727973240: [
            0.34794,
            0,
            0.34794,
            0,
            41.6365,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            80.63934,
            0,
            1.62136,
            0,
            23.41022,
            0,
            725.8561,
            0,
            0,
            0,
            29.31879,
            0,
            15.94171,
            "<D",
            110.8979,
            0,
        ],
        1727973300: [
            0.12359,
            0,
            0.12359,
            0,
            41.19252,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            48.59335,
            0,
            1.57194,
            0,
            23.36891,
            0,
            726.3076,
            0,
            0,
            0,
            29.31809,
            0,
            18.21025,
            "<D",
            110.2134,
            0,
        ],
        1727973360: [
            0.13493,
            0,
            0.13493,
            0,
            41.26019,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            46.22613,
            0,
            1.56507,
            0,
            23.34825,
            0,
            726.6276,
            0,
            0,
            0,
            29.31788,
            0,
            18.26284,
            "<D",
            109.7266,
            0,
        ],
        1727973420: [
            0.06486,
            0,
            0.06486,
            0,
            40.55868,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            22.50706,
            0,
            1.8246,
            0,
            23.45418,
            0,
            728.5939,
            0,
            0,
            0,
            29.31779,
            0,
            16.03029,
            "<D",
            109.225,
            0,
        ],
        1727973480: [
            0.08433,
            0,
            0.08433,
            0,
            42.78303,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            345.7496,
            0,
            2.05772,
            0,
            22.94088,
            0,
            729.425,
            0,
            0,
            0,
            29.31761,
            0,
            15.63175,
            "<D",
            109.9208,
            0,
        ],
        1727973540: [
            0.10292,
            0,
            0.10292,
            0,
            43.25453,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            356.0132,
            0,
            1.62206,
            0,
            22.71834,
            0,
            728.9536,
            0,
            0,
            0,
            29.3177,
            0,
            16.04822,
            "<D",
            109.3151,
            0,
        ],
        1727973600: [
            0.04882,
            0,
            0.04882,
            0,
            43.30177,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            351.1133,
            0,
            1.26539,
            0,
            22.63123,
            0,
            730.4965,
            0,
            0,
            0,
            29.31764,
            0,
            18.55395,
            "<D",
            110.0171,
            0,
        ],
        1727973660: [
            0.22389,
            0,
            0.22389,
            0,
            43.09266,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            338.0469,
            0,
            1.1707,
            0,
            23.03097,
            0,
            731.5523,
            0,
            0,
            0,
            29.31761,
            0,
            14.01373,
            "<D",
            109.4124,
            0,
        ],
        1727973720: [
            0.1831,
            0,
            0.1831,
            0,
            42.47671,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            255.5829,
            0,
            0.64124,
            0,
            23.43582,
            0,
            733.0788,
            0,
            0,
            0,
            29.31775,
            0,
            18.13991,
            "<D",
            109.2482,
            0,
        ],
        1727973780: [
            0.25832,
            0,
            0.25832,
            0,
            41.49937,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            213.0864,
            0,
            1.11323,
            0,
            23.37806,
            0,
            734.9586,
            0,
            0,
            0,
            29.31763,
            0,
            15.20221,
            "<D",
            108.791,
            0,
        ],
        1727973840: [
            0.22452,
            0,
            0.22452,
            0,
            42.58566,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            171.2977,
            0,
            1.61515,
            0,
            23.42975,
            0,
            737.4583,
            0,
            0,
            0,
            29.31764,
            0,
            16.46117,
            "<D",
            108.5082,
            0,
        ],
        1727973900: [
            0.13716,
            0,
            0.13716,
            0,
            42.42656,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            211.201,
            0,
            1.30647,
            0,
            23.73574,
            0,
            738.6216,
            0,
            0,
            0,
            29.31752,
            0,
            16.13572,
            "<D",
            107.7137,
            0,
        ],
        1727973960: [
            0.32087,
            0,
            0.32087,
            0,
            41.87616,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            217.0643,
            0,
            1.03848,
            0,
            23.80058,
            0,
            737.87,
            0,
            0,
            0,
            29.31758,
            0,
            15.94352,
            "<D",
            109.406,
            0,
        ],
        1727974020: [
            0.25152,
            0,
            0.25152,
            0,
            42.5082,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            203.6358,
            0,
            1.48981,
            0,
            24.05109,
            0,
            739.1923,
            0,
            0,
            0,
            29.31756,
            0,
            15.26216,
            "<D",
            110.727,
            0,
        ],
        1727974080: [
            0.13912,
            0,
            0.13912,
            0,
            42.48884,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            193.2797,
            0,
            1.55803,
            0,
            23.86637,
            0,
            739.691,
            0,
            0,
            0,
            29.31775,
            0,
            17.93337,
            "<D",
            111.3507,
            0,
        ],
        1727974140: [
            0.31339,
            0,
            0.31339,
            0,
            43.03015,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            210.3101,
            0,
            2.12895,
            0,
            23.77027,
            0,
            740.9845,
            0,
            0,
            0,
            29.31774,
            0,
            14.58866,
            "<D",
            110.913,
            0,
        ],
        1727974200: [
            0.13455,
            0,
            0.13455,
            0,
            43.03843,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            183.7065,
            0,
            1.8914,
            0,
            23.68241,
            0,
            741.5048,
            0,
            0,
            0,
            29.31665,
            0,
            12.66638,
            "<D",
            110.5184,
            0,
        ],
        1727974260: [
            0.19385,
            0,
            0.19385,
            0,
            43.42025,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            206.4321,
            0,
            1.73226,
            0,
            23.60326,
            0,
            740.2219,
            0,
            0,
            0,
            29.3161,
            0,
            16.51453,
            "<D",
            110.7672,
            0,
        ],
        1727974320: [
            0.14985,
            0,
            0.14985,
            0,
            43.62923,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            190.377,
            0,
            2.80143,
            0,
            23.79412,
            0,
            741.5988,
            0,
            0,
            0,
            29.31346,
            0,
            15.2424,
            "<D",
            111.2806,
            0,
        ],
        1727974380: [
            0.21729,
            0,
            0.21729,
            0,
            43.9231,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            206.1507,
            0,
            2.11944,
            0,
            23.87859,
            0,
            741.7919,
            0,
            0,
            0,
            29.31445,
            0,
            16.45291,
            "<D",
            110.6185,
            0,
        ],
        1727974440: [
            0.21551,
            0,
            0.21551,
            0,
            43.98425,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            194.5441,
            0,
            2.26512,
            0,
            23.81317,
            0,
            741.7567,
            0,
            0,
            0,
            29.31438,
            0,
            17.46379,
            "<D",
            110.9997,
            0,
        ],
        1727974500: [
            0.16275,
            0,
            0.16275,
            0,
            43.34769,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            177.5487,
            0,
            2.20381,
            0,
            23.98405,
            0,
            741.3752,
            0,
            0,
            0,
            29.31438,
            0,
            13.70398,
            "<D",
            110.4014,
            0,
        ],
        1727974560: [
            0.31519,
            0,
            0.31519,
            0,
            43.01087,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            186.9338,
            0,
            1.61332,
            0,
            23.99962,
            0,
            743.1401,
            0,
            0,
            0,
            29.31432,
            0,
            15.48135,
            "<D",
            108.5517,
            0,
        ],
        1727974620: [
            0.34146,
            0,
            0.34146,
            0,
            43.64423,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            188.8318,
            0,
            1.53337,
            0,
            23.97393,
            0,
            744.12,
            0,
            0,
            0,
            29.31536,
            0,
            14.61782,
            "<D",
            108.3295,
            0,
        ],
        1727974680: [
            0.01986,
            0,
            0.01986,
            0,
            43.38014,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            222.2328,
            0,
            1.40011,
            0,
            24.04481,
            0,
            744.8312,
            0,
            0,
            0,
            29.31421,
            0,
            15.66403,
            "<D",
            108.3483,
            0,
        ],
        1727974740: [
            0.05391,
            0,
            0.05391,
            0,
            42.29853,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            202.6697,
            0,
            1.7694,
            0,
            24.33559,
            0,
            745.4446,
            0,
            0,
            0,
            29.31501,
            0,
            17.76184,
            "<D",
            109.4129,
            0,
        ],
        1727974800: [
            0.15005,
            0,
            0.15005,
            0,
            41.89092,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            203.8781,
            0,
            1.88638,
            0,
            24.06521,
            0,
            744.7634,
            0,
            0,
            0,
            29.31408,
            0,
            14.17545,
            "<D",
            111.7723,
            0,
        ],
        1727974860: [
            0.26221,
            0,
            0.26221,
            0,
            42.52234,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            193.9263,
            0,
            1.90812,
            0,
            23.96864,
            0,
            743.469,
            0,
            0,
            0,
            29.31399,
            0,
            16.95579,
            "<D",
            111.8046,
            0,
        ],
        1727974920: [
            0.23746,
            0,
            0.23746,
            0,
            42.5157,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            179.7856,
            0,
            1.94031,
            0,
            23.82855,
            0,
            742.592,
            0,
            0,
            0,
            29.31473,
            0,
            12.77726,
            "<D",
            112.5872,
            0,
        ],
        1727974980: [
            0.22895,
            0,
            0.22895,
            0,
            43.21658,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            204.5065,
            0,
            1.13717,
            0,
            23.5322,
            0,
            742.5813,
            0,
            0,
            0,
            29.31376,
            0,
            14.75553,
            "<D",
            110.0447,
            0,
        ],
        1727975040: [
            0.35883,
            0,
            0.35883,
            0,
            43.46795,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            201.0427,
            0,
            1.35069,
            0,
            23.79288,
            0,
            742.0573,
            0,
            0,
            0,
            29.31273,
            0,
            15.51107,
            "<D",
            111.0434,
            0,
        ],
        1727975100: [
            0.34364,
            0,
            0.34364,
            0,
            42.62413,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            211.6328,
            0,
            1.22799,
            0,
            24.02459,
            0,
            744.8133,
            0,
            0,
            0,
            29.31271,
            0,
            13.39648,
            "<D",
            110.8548,
            0,
        ],
        1727975160: [
            0.22933,
            0,
            0.22933,
            0,
            41.97536,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            213.5638,
            0,
            1.60711,
            0,
            24.20573,
            0,
            744.3069,
            0,
            0,
            0,
            29.31277,
            0,
            13.11254,
            "<D",
            109.4308,
            0,
        ],
        1727975220: [
            0.20946,
            0,
            0.20946,
            0,
            42.59787,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            212.2786,
            0,
            1.83256,
            0,
            23.84241,
            0,
            746.1365,
            0,
            0,
            0,
            29.31229,
            0,
            15.23111,
            "<D",
            109.7908,
            0,
        ],
        1727975280: [
            0.12131,
            0,
            0.12131,
            0,
            43.57886,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            208.8821,
            0,
            1.3987,
            0,
            23.84826,
            0,
            745.951,
            0,
            0,
            0,
            29.31219,
            0,
            12.4066,
            "<D",
            107.6543,
            0,
        ],
        1727975340: [
            0.17678,
            0,
            0.17678,
            0,
            43.43349,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            188.3996,
            0,
            1.43057,
            0,
            23.80765,
            0,
            747.6706,
            0,
            0,
            0,
            29.30966,
            0,
            14.66317,
            "<D",
            107.2809,
            0,
        ],
        1727975400: [
            0.22379,
            0,
            0.22379,
            0,
            43.27435,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            126.5801,
            0,
            1.64349,
            0,
            24.18834,
            0,
            747.6077,
            0,
            0,
            0,
            29.31069,
            0,
            14.87282,
            "<D",
            107.7166,
            0,
        ],
        1727975460: [
            0.27241,
            0,
            0.27241,
            0,
            42.71669,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            140.1177,
            0,
            1.24747,
            0,
            24.16913,
            0,
            746.9096,
            0,
            0,
            0,
            29.31061,
            0,
            16.36307,
            "<D",
            107.7388,
            0,
        ],
        1727975520: [
            0.13629,
            0,
            0.13629,
            0,
            43.06459,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            47.63236,
            0,
            0.51329,
            0,
            24.19812,
            0,
            746.6129,
            0,
            0,
            0,
            29.30959,
            0,
            17.33041,
            "<D",
            111.1593,
            0,
        ],
        1727975580: [
            0.16713,
            0,
            0.16713,
            0,
            43.53234,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            36.43458,
            0,
            2.30808,
            0,
            24.11757,
            0,
            745.7204,
            0,
            0,
            0,
            29.30953,
            0,
            13.29164,
            "<D",
            110.7044,
            0,
        ],
        1727975640: [
            0.28198,
            0,
            0.28198,
            0,
            43.36463,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            8.65326,
            0,
            0.8793,
            0,
            24.11535,
            0,
            747.2781,
            0,
            0,
            0,
            29.30954,
            0,
            12.63322,
            "<D",
            111.314,
            0,
        ],
        1727975700: [
            0.15162,
            0,
            0.15162,
            0,
            42.61039,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            66.95646,
            0,
            1.07866,
            0,
            24.6209,
            0,
            745.8505,
            0,
            0,
            0,
            29.30954,
            0,
            16.27929,
            "<D",
            111.3556,
            0,
        ],
        1727975760: [
            0.3245,
            0,
            0.3245,
            0,
            41.59428,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            55.438,
            0,
            1.09579,
            0,
            24.61513,
            0,
            744.9708,
            0,
            0,
            0,
            29.30954,
            0,
            15.91039,
            "<D",
            110.0249,
            0,
        ],
        1727975820: [
            0.10965,
            0,
            0.10965,
            0,
            42.51434,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            87.17902,
            0,
            1.15993,
            0,
            24.40932,
            0,
            745.894,
            0,
            0,
            0,
            29.30954,
            0,
            13.43658,
            "<D",
            111.4583,
            0,
        ],
        1727975880: [
            0.20326,
            0,
            0.20326,
            0,
            42.17347,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            121.4987,
            0,
            1.08678,
            0,
            24.18117,
            0,
            746.342,
            0,
            0,
            0,
            29.30952,
            0,
            14.29331,
            "<D",
            110.689,
            0,
        ],
        1727975940: [
            0.17816,
            0,
            0.17816,
            0,
            42.59021,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            60.07968,
            0,
            0.70062,
            0,
            23.88078,
            0,
            746.6696,
            0,
            0,
            0,
            29.30954,
            0,
            15.11605,
            "<D",
            108.9943,
            0,
        ],
        1727976000: [
            0.14083,
            0,
            0.14083,
            0,
            42.25719,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            24.35201,
            0,
            1.19053,
            0,
            24.02023,
            0,
            747.3116,
            0,
            0,
            0,
            29.30938,
            0,
            14.54164,
            "<D",
            109.1215,
            0,
        ],
        1727976060: [
            0.15522,
            0,
            0.15522,
            0,
            41.69862,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            149.3377,
            0,
            1.54912,
            0,
            24.30837,
            0,
            748.8827,
            0,
            0,
            0,
            29.30954,
            0,
            14.30089,
            "<D",
            107.6683,
            0,
        ],
        1727976120: [
            0.26651,
            0,
            0.26651,
            0,
            41.00465,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            163.155,
            0,
            0.96373,
            0,
            24.61743,
            0,
            748.4734,
            0,
            0,
            0,
            29.30954,
            0,
            13.21319,
            "<D",
            107.3187,
            0,
        ],
        1727976180: [
            0.31935,
            0,
            0.31935,
            0,
            40.78601,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8624,
            0,
            191.3206,
            0,
            2.00941,
            0,
            24.89269,
            0,
            748.7168,
            0,
            0,
            0,
            29.30954,
            0,
            17.30896,
            "<D",
            106.4235,
            0,
        ],
        1727976240: [
            0.18252,
            0,
            0.18252,
            0,
            41.85603,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            203.0054,
            0,
            1.73563,
            0,
            24.5634,
            0,
            747.2245,
            0,
            0,
            0,
            29.30954,
            0,
            15.63733,
            "<D",
            107.5194,
            0,
        ],
        1727976300: [
            0.17528,
            0,
            0.17528,
            0,
            43.23375,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            186.0917,
            0,
            1.46342,
            0,
            24.72933,
            0,
            746.9998,
            0,
            0,
            0,
            29.30951,
            0,
            14.01204,
            "<D",
            113.3916,
            0,
        ],
        1727976360: [
            0.33122,
            0,
            0.33122,
            0,
            43.16822,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            184.8854,
            0,
            1.6814,
            0,
            24.65246,
            0,
            747.9816,
            0,
            0,
            0,
            29.30929,
            0,
            13.66196,
            "<D",
            110.197,
            0,
        ],
        1727976420: [
            0.21227,
            0,
            0.21227,
            0,
            43.97488,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            175.2505,
            0,
            1.8163,
            0,
            24.43975,
            0,
            747.4479,
            0,
            0,
            0,
            29.30914,
            0,
            17.17803,
            "<D",
            110.1913,
            0,
        ],
        1727976480: [
            0.18901,
            0,
            0.18901,
            0,
            43.63523,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            165.7525,
            0,
            1.45755,
            0,
            24.19922,
            0,
            747.7682,
            0,
            0,
            0,
            29.30942,
            0,
            14.51217,
            "<D",
            110.1588,
            0,
        ],
        1727976540: [
            0.18812,
            0,
            0.18812,
            0,
            43.24349,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            118.7422,
            0,
            0.29903,
            0,
            24.06239,
            0,
            748.5499,
            0,
            0,
            0,
            29.3091,
            0,
            14.69238,
            "<D",
            111.5983,
            0,
        ],
        1727976600: [
            0.24127,
            0,
            0.24127,
            0,
            43.16216,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            174.1969,
            0,
            1.33584,
            0,
            24.2326,
            0,
            748.8203,
            0,
            0,
            0,
            29.3084,
            0,
            13.35521,
            "<D",
            110.2299,
            0,
        ],
        1727976660: [
            0.24426,
            0,
            0.24426,
            0,
            42.76405,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            201.0501,
            0,
            1.05246,
            0,
            24.52999,
            0,
            748.9604,
            0,
            0,
            0,
            29.30832,
            0,
            13.60229,
            "<D",
            108.4932,
            0,
        ],
        1727976720: [
            0.24361,
            0,
            0.24361,
            0,
            42.93223,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            299.3524,
            0,
            0.44542,
            0,
            24.45287,
            0,
            748.1472,
            0,
            0,
            0,
            29.30692,
            0,
            12.24273,
            "<D",
            108.2832,
            0,
        ],
        1727976780: [
            0.14911,
            0,
            0.14911,
            0,
            43.38167,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            242.2184,
            0,
            0.67739,
            0,
            24.53386,
            0,
            748.9003,
            0,
            0,
            0,
            29.30615,
            0,
            14.35437,
            "<D",
            107.6102,
            0,
        ],
        1727976840: [
            0.20536,
            0,
            0.20536,
            0,
            42.89911,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            203.7733,
            0,
            1.41919,
            0,
            24.88454,
            0,
            748.9809,
            0,
            0,
            0,
            29.30678,
            0,
            13.49127,
            "<D",
            106.6676,
            0,
        ],
        1727976900: [
            0.31402,
            0,
            0.31402,
            0,
            42.77945,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            184.5862,
            0,
            1.65373,
            0,
            24.93012,
            0,
            749.038,
            0,
            0,
            0,
            29.30627,
            0,
            15.144,
            "<D",
            106.072,
            0,
        ],
        1727976960: [
            0.29859,
            0,
            0.29859,
            0,
            43.1701,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            154.4206,
            0,
            1.93045,
            0,
            24.78425,
            0,
            748.3992,
            0,
            0,
            0,
            29.30683,
            0,
            15.73,
            "<D",
            107.0055,
            0,
        ],
        1727977020: [
            0.21595,
            0,
            0.21595,
            0,
            43.34787,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            110.7384,
            0,
            0.65157,
            0,
            25.0655,
            0,
            748.6597,
            0,
            0,
            0,
            29.30549,
            0,
            15.43666,
            "<D",
            111.0744,
            0,
        ],
        1727977080: [
            0.27488,
            0,
            0.27488,
            0,
            42.64854,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            170.4858,
            0,
            0.7465,
            0,
            25.22614,
            0,
            748.6918,
            0,
            0,
            0,
            29.3055,
            0,
            17.02125,
            "<D",
            110.6244,
            0,
        ],
        1727977140: [
            0.19045,
            0,
            0.19045,
            0,
            42.66058,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            115.3647,
            0,
            1.55944,
            0,
            25.42766,
            0,
            749.3485,
            0,
            0,
            0,
            29.30527,
            0,
            14.85166,
            "<D",
            113.772,
            0,
        ],
        1727977200: [
            0.14185,
            0,
            0.14185,
            0,
            42.9129,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            110.3831,
            0,
            1.37943,
            0,
            25.53912,
            0,
            750.5693,
            0,
            0,
            0,
            29.30465,
            0,
            13.19762,
            "<D",
            111.672,
            0,
        ],
        1727977260: [
            0.07107,
            0,
            0.07107,
            0,
            44.65266,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8624,
            0,
            157.9087,
            0,
            1.32655,
            0,
            25.49436,
            0,
            750.8942,
            0,
            0,
            0,
            29.30398,
            0,
            16.08289,
            "<D",
            110.0885,
            0,
        ],
        1727977320: [
            0.17077,
            0,
            0.17077,
            0,
            45.32561,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            140.6693,
            0,
            1.25307,
            0,
            25.39674,
            0,
            750.6479,
            0,
            0,
            0,
            29.30279,
            0,
            11.19107,
            "<D",
            111.0029,
            0,
        ],
        1727977380: [
            0.16422,
            0,
            0.16422,
            0,
            45.34211,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            147.8726,
            0,
            1.27749,
            0,
            25.40454,
            0,
            749.22,
            0,
            0,
            0,
            29.30229,
            0,
            13.30181,
            "<D",
            109.1542,
            0,
        ],
        1727977440: [
            0.26224,
            0,
            0.26224,
            0,
            46.08889,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.871,
            0,
            154.4475,
            0,
            2.13093,
            0,
            25.17508,
            0,
            748.5134,
            0,
            0,
            0,
            29.30231,
            0,
            14.34002,
            "<D",
            110.2242,
            0,
        ],
        1727977500: [
            0.32883,
            0,
            0.32883,
            0,
            46.85442,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            131.2697,
            0,
            2.14285,
            0,
            24.7307,
            0,
            746.9969,
            0,
            0,
            0,
            29.30251,
            0,
            16.47359,
            "<D",
            108.8135,
            0,
        ],
        1727977560: [
            0.15604,
            0,
            0.15604,
            0,
            47.23317,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            122.7848,
            0,
            1.94613,
            0,
            24.6433,
            0,
            746.2071,
            0,
            0,
            0,
            29.30226,
            0,
            13.55575,
            "<D",
            107.7136,
            0,
        ],
        1727977620: [
            0.16016,
            0,
            0.16016,
            0,
            46.79273,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            160.6034,
            0,
            1.40271,
            0,
            24.97168,
            0,
            746.0159,
            0,
            0,
            0,
            29.30234,
            0,
            12.22541,
            "<D",
            107.9715,
            0,
        ],
        1727977680: [
            0.2548,
            0,
            0.2548,
            0,
            45.53299,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            206.2504,
            0,
            1.30536,
            0,
            25.34483,
            0,
            746.1514,
            0,
            0,
            0,
            29.30239,
            0,
            14.48798,
            "<D",
            107.9864,
            0,
        ],
        1727977740: [
            0.28943,
            0,
            0.28943,
            0,
            45.04035,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            204.8398,
            0,
            1.11077,
            0,
            25.62056,
            0,
            745.7127,
            0,
            0,
            0,
            29.30345,
            0,
            14.95984,
            "<D",
            108.6487,
            0,
        ],
        1727977800: [
            0.23268,
            0,
            0.23268,
            0,
            45.06718,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.914,
            0,
            187.5131,
            0,
            0.97465,
            0,
            25.39159,
            0,
            745.5878,
            0,
            0,
            0,
            29.30224,
            0,
            13.0714,
            "<D",
            111.8518,
            0,
        ],
        1727977860: [
            0.26606,
            0,
            0.26606,
            0,
            44.89294,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            176.1021,
            0,
            1.34644,
            0,
            25.40008,
            0,
            745.8125,
            0,
            0,
            0,
            29.30224,
            0,
            14.14955,
            "<D",
            111.266,
            0,
        ],
        1727977920: [
            0.20227,
            0,
            0.20227,
            0,
            44.40505,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            180.5197,
            0,
            0.80724,
            0,
            25.29301,
            0,
            745.5699,
            0,
            0,
            0,
            29.30224,
            0,
            17.23134,
            "<D",
            112.0586,
            0,
        ],
        1727977980: [
            0.30181,
            0,
            0.30181,
            0,
            44.93692,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            198.1618,
            0,
            1.18517,
            0,
            25.63049,
            0,
            746.5545,
            0,
            0,
            0,
            29.30088,
            0,
            14.63444,
            "<D",
            109.2589,
            0,
        ],
        1727978040: [
            0.27696,
            0,
            0.27696,
            0,
            44.92783,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8796,
            0,
            218.639,
            0,
            0.85885,
            0,
            25.0401,
            0,
            747.4357,
            0,
            0,
            0,
            29.2997,
            0,
            17.37265,
            "<D",
            111.2367,
            0,
        ],
        1727978100: [
            0.22006,
            0,
            0.22006,
            0,
            45.54864,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            47.85841,
            0,
            0.26004,
            0,
            24.93133,
            0,
            748.6497,
            0,
            0,
            0,
            29.2991,
            0,
            16.05838,
            "<D",
            112.053,
            0,
        ],
        1727978160: [
            0.37019,
            0,
            0.37019,
            0,
            45.3061,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8882,
            0,
            168.1234,
            0,
            0.9182,
            0,
            24.91076,
            0,
            749.7447,
            0,
            0,
            0,
            29.29828,
            0,
            15.01965,
            "<D",
            109.3179,
            0,
        ],
        1727978220: [
            0.04673,
            0,
            0.04673,
            0,
            45.06311,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            218.5807,
            0,
            1.30718,
            0,
            25.19765,
            0,
            751.2977,
            0,
            0,
            0,
            29.29846,
            0,
            13.69595,
            "<D",
            108.6391,
            0,
        ],
        1727978280: [
            0.17393,
            0,
            0.17393,
            0,
            44.8908,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            146.0485,
            0,
            0.58503,
            0,
            25.48653,
            0,
            753.6561,
            0,
            0,
            0,
            29.29825,
            0,
            15.88898,
            "<D",
            107.5361,
            0,
        ],
        1727978340: [
            0.37533,
            0,
            0.37533,
            0,
            45.4355,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            149.8224,
            0,
            0.9351,
            0,
            25.15632,
            0,
            754.6761,
            0,
            0,
            0,
            29.29825,
            0,
            14.18726,
            "<D",
            107.2574,
            0,
        ],
        1727978400: [
            0.26743,
            0,
            0.26743,
            0,
            45.44371,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            111.7007,
            0,
            1.35509,
            0,
            25.07191,
            0,
            709.864,
            0,
            0,
            0,
            29.29826,
            0,
            15.10835,
            "<D",
            107.6989,
            0,
        ],
        1727978460: [
            0.24949,
            0,
            0.24949,
            0,
            45.55592,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            125.4485,
            0,
            1.11721,
            0,
            24.89091,
            0,
            652.441,
            0,
            0,
            0,
            29.29833,
            0,
            15.53139,
            "<D",
            108.4619,
            0,
        ],
        1727978520: [
            0.10371,
            0,
            0.10371,
            0,
            45.26016,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.914,
            0,
            180.6225,
            0,
            1.35058,
            0,
            25.05002,
            0,
            611.6384,
            0,
            0,
            0,
            29.29867,
            0,
            13.9342,
            "<D",
            112.0068,
            0,
        ],
        1727978580: [
            0.12441,
            0,
            0.12441,
            0,
            45.09393,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            160.2422,
            0,
            0.81851,
            0,
            25.44798,
            0,
            682.6973,
            0,
            0,
            0,
            29.29827,
            0,
            13.57111,
            "<D",
            110.6556,
            0,
        ],
        1727978640: [
            0.21179,
            0,
            0.21179,
            0,
            45.17063,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            192.2917,
            0,
            1.07425,
            0,
            25.7847,
            0,
            703.206,
            0,
            0,
            0,
            29.29772,
            0,
            13.76437,
            "<D",
            111.153,
            0,
        ],
        1727978700: [
            0.23208,
            0,
            0.23208,
            0,
            46.01348,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            223.9544,
            0,
            1.28559,
            0,
            25.57365,
            0,
            715.8352,
            0,
            0,
            0,
            29.29803,
            0,
            13.60288,
            "<D",
            109.9935,
            0,
        ],
        1727978760: [
            0.01369,
            0,
            0.01369,
            0,
            47.40212,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            218.0155,
            0,
            1.52506,
            0,
            25.81045,
            0,
            725.6926,
            0,
            0,
            0,
            29.29715,
            0,
            15.3606,
            "<D",
            110.7248,
            0,
        ],
        1727978820: [
            0.22134,
            0,
            0.22134,
            0,
            47.2781,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            200.96,
            0,
            1.65617,
            0,
            25.99292,
            0,
            730.0277,
            0,
            0,
            0,
            29.29814,
            0,
            15.34764,
            "<D",
            111.2465,
            0,
        ],
        1727978880: [
            0.27525,
            0,
            0.27525,
            0,
            47.15108,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            193.8968,
            0,
            1.8428,
            0,
            25.44152,
            0,
            732.2686,
            0,
            0,
            0,
            29.2976,
            0,
            14.2805,
            "<D",
            110.9169,
            0,
        ],
        1727978940: [
            0.44091,
            0,
            0.44091,
            0,
            47.71958,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            197.8412,
            0,
            1.2553,
            0,
            25.14155,
            0,
            732.3221,
            0,
            0,
            0,
            29.29808,
            0,
            13.2362,
            "<D",
            108.6978,
            0,
        ],
        1727979000: [
            0.27887,
            0,
            0.27887,
            0,
            47.84837,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            199.5583,
            0,
            0.88845,
            0,
            25.1875,
            0,
            723.0865,
            0,
            0,
            0,
            29.29726,
            0,
            14.26686,
            "<D",
            108.3244,
            0,
        ],
        1727979060: [
            0.20658,
            0,
            0.20658,
            0,
            48.28614,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            244.0822,
            0,
            1.49986,
            0,
            25.56834,
            0,
            744.5066,
            0,
            0,
            0,
            29.29576,
            0,
            15.58321,
            "<D",
            107.3083,
            0,
        ],
        1727979120: [
            0.23317,
            0,
            0.23317,
            0,
            47.16241,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            285.9443,
            0,
            0.75913,
            0,
            25.1364,
            0,
            759.956,
            0,
            0,
            0,
            29.29568,
            0,
            11.26222,
            "<D",
            108.1955,
            0,
        ],
        1727979180: [
            0.16778,
            0,
            0.16778,
            0,
            48.17537,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            291.8564,
            0,
            1.60107,
            0,
            25.09617,
            0,
            765.1613,
            0,
            0,
            0,
            29.29494,
            0,
            13.42493,
            "<D",
            110.108,
            0,
        ],
        1727979240: [
            0.15874,
            0,
            0.15874,
            0,
            48.17483,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            248.8946,
            0,
            1.32696,
            0,
            25.08523,
            0,
            760.5954,
            0,
            0,
            0,
            29.29549,
            0,
            15.95607,
            "<D",
            113.605,
            0,
        ],
        1727979300: [
            0.18961,
            0,
            0.18961,
            0,
            48.48541,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            217.1707,
            0,
            1.32268,
            0,
            25.07048,
            0,
            762.1673,
            0,
            0,
            0,
            29.29504,
            0,
            13.12038,
            "<D",
            111.045,
            0,
        ],
        1727979360: [
            0.17323,
            0,
            0.17323,
            0,
            48.29314,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            147.3146,
            0,
            1.13849,
            0,
            25.31171,
            0,
            762.0314,
            0,
            0,
            0,
            29.29494,
            0,
            13.84049,
            "<D",
            111.567,
            0,
        ],
        1727979420: [
            0.19658,
            0,
            0.19658,
            0,
            48.26054,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            194.0371,
            0,
            1.02308,
            0,
            25.39954,
            0,
            753.5039,
            0,
            0,
            0,
            29.29488,
            0,
            14.46083,
            "<D",
            109.7288,
            0,
        ],
        1727979480: [
            0.2101,
            0,
            0.2101,
            0,
            47.7969,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8882,
            0,
            263.4623,
            0,
            1.81057,
            0,
            25.57111,
            0,
            754.606,
            0,
            0,
            0,
            29.29494,
            0,
            16.10814,
            "<D",
            111.2461,
            0,
        ],
        1727979540: [
            0.07352,
            0,
            0.07352,
            0,
            47.19043,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            261.2169,
            0,
            1.45448,
            0,
            25.32248,
            0,
            729.0166,
            0,
            0,
            0,
            29.2944,
            0,
            14.82378,
            "<D",
            111.8363,
            0,
        ],
        1727979600: [
            0.18484,
            0,
            0.18484,
            0,
            48.38791,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            279.1539,
            0,
            0.83361,
            0,
            25.02382,
            0,
            595.9639,
            0,
            0,
            0,
            29.29353,
            0,
            15.02964,
            "<D",
            112.3174,
            0,
        ],
        1727979660: [
            0.21747,
            0,
            0.21747,
            0,
            48.06575,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            226.9679,
            0,
            0.55051,
            0,
            25.17189,
            0,
            564.6941,
            0,
            0,
            0,
            29.2944,
            0,
            15.33004,
            "<D",
            108.416,
            0,
        ],
        1727979720: [
            0.16126,
            0,
            0.16126,
            0,
            47.48741,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            193.5421,
            0,
            1.47508,
            0,
            25.28519,
            0,
            572.7796,
            0,
            0,
            0,
            29.29467,
            0,
            14.4936,
            "<D",
            107.8148,
            0,
        ],
        1727979780: [
            0.28194,
            0,
            0.28194,
            0,
            47.57867,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            189.3647,
            0,
            0.85994,
            0,
            25.14517,
            0,
            559.3145,
            0,
            0,
            0,
            29.29412,
            0,
            13.22521,
            "<D",
            108.4741,
            0,
        ],
        1727979840: [
            0.21979,
            0,
            0.21979,
            0,
            47.47275,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9398,
            0,
            180.568,
            0,
            0.75909,
            0,
            25.18642,
            0,
            595.5115,
            0,
            0,
            0,
            29.29467,
            0,
            14.87515,
            "<D",
            108.3847,
            0,
        ],
        1727979900: [
            0.113,
            0,
            0.113,
            0,
            47.05986,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.957,
            0,
            212.0086,
            0,
            1.1933,
            0,
            25.18392,
            0,
            657.5353,
            0,
            0,
            0,
            29.29276,
            0,
            16.40288,
            "<D",
            111.7329,
            0,
        ],
        1727979960: [
            0.14301,
            0,
            0.14301,
            0,
            46.25209,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9441,
            0,
            161.7124,
            0,
            1.42708,
            0,
            25.72623,
            0,
            731.6556,
            0,
            0,
            0,
            29.29337,
            0,
            12.9923,
            "<D",
            110.7582,
            0,
        ],
        1727980020: [
            0.29677,
            0,
            0.29677,
            0,
            44.94677,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9312,
            0,
            153.594,
            0,
            2.08783,
            0,
            25.33445,
            0,
            760.4297,
            0,
            0,
            0,
            29.2918,
            0,
            15.48565,
            "<D",
            111.4502,
            0,
        ],
        1727980080: [
            0.29004,
            0,
            0.29004,
            0,
            46.85004,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            172.4572,
            0,
            1.80913,
            0,
            25.3268,
            0,
            761.4663,
            0,
            0,
            0,
            29.29169,
            0,
            17.2563,
            "<D",
            112.7891,
            0,
        ],
        1727980140: [
            0.25844,
            0,
            0.25844,
            0,
            45.95628,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            104.3501,
            0,
            1.1758,
            0,
            25.53741,
            0,
            761.6772,
            0,
            0,
            0,
            29.29169,
            0,
            10.29921,
            "<D",
            111.5311,
            0,
        ],
        1727980200: [
            0.39636,
            0,
            0.39636,
            0,
            46.67411,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            249.1416,
            0,
            0.11435,
            0,
            25.63323,
            0,
            750.0089,
            0,
            0,
            0,
            29.29114,
            0,
            15.21389,
            "<D",
            112.2627,
            0,
        ],
        1727980260: [
            0.19392,
            0,
            0.19392,
            0,
            47.66676,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            192.103,
            0,
            0.79397,
            0,
            25.68015,
            0,
            727.2072,
            0,
            0,
            0,
            29.28963,
            0,
            13.84725,
            "<D",
            112.661,
            0,
        ],
        1727980320: [
            0.2807,
            0,
            0.2807,
            0,
            48.56121,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            227.1043,
            0,
            1.42605,
            0,
            25.63954,
            0,
            705.5027,
            0,
            0,
            0,
            29.28975,
            0,
            14.2704,
            "<D",
            110.3323,
            0,
        ],
        1727980380: [
            0.13461,
            0,
            0.13461,
            0,
            49.88162,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            254.821,
            0,
            1.39517,
            0,
            25.51637,
            0,
            661.3477,
            0,
            0,
            0,
            29.28867,
            0,
            15.2663,
            "<D",
            108.9644,
            0,
        ],
        1727980440: [
            0.16876,
            0,
            0.16876,
            0,
            50.33422,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            249.2702,
            0,
            0.84637,
            0,
            25.44011,
            0,
            672.1406,
            0,
            0,
            0,
            29.28856,
            0,
            14.53844,
            "<D",
            108.1748,
            0,
        ],
        1727980500: [
            0.12905,
            0,
            0.12905,
            0,
            51.69228,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9398,
            0,
            242.6962,
            0,
            1.1277,
            0,
            25.40881,
            0,
            639.2911,
            0,
            0,
            0,
            29.28845,
            0,
            13.56432,
            "<D",
            108.0297,
            0,
        ],
        1727980560: [
            0.45702,
            0,
            0.45702,
            0,
            52.24094,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9527,
            0,
            211.0538,
            0,
            1.08696,
            0,
            25.26189,
            0,
            675.2075,
            0,
            0,
            0,
            29.28799,
            0,
            13.23912,
            "<D",
            108.8134,
            0,
        ],
        1727980620: [
            0.36586,
            0,
            0.36586,
            0,
            52.31286,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9527,
            0,
            195.1996,
            0,
            1.22113,
            0,
            25.25871,
            0,
            749.0594,
            0,
            0,
            0,
            29.28785,
            0,
            11.22547,
            "<D",
            113.7387,
            0,
        ],
        1727980680: [
            0.41408,
            0,
            0.41408,
            0,
            52.18285,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9484,
            0,
            208.7834,
            0,
            1.22346,
            0,
            25.39269,
            0,
            769.4174,
            0,
            0,
            0,
            29.28664,
            0,
            15.76236,
            "<D",
            110.3549,
            0,
        ],
        1727980740: [
            0.19742,
            0,
            0.19742,
            0,
            52.23738,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            233.8548,
            0,
            1.2798,
            0,
            25.64199,
            0,
            697.5764,
            0,
            0,
            0,
            29.2873,
            0,
            10.93621,
            "<D",
            111.3276,
            0,
        ],
        1727980800: [
            0.22841,
            0,
            0.22841,
            0,
            51.99691,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            233.868,
            0,
            0.84319,
            0,
            25.95511,
            0,
            597.9922,
            0,
            0,
            0,
            29.28637,
            0,
            13.87225,
            "<D",
            110.5544,
            0,
        ],
        1727980860: [
            0.19528,
            0,
            0.19528,
            0,
            51.41845,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            243.1077,
            0,
            1.49125,
            0,
            26.06231,
            0,
            695.0504,
            0,
            0,
            0,
            29.28805,
            0,
            16.97162,
            "<D",
            109.2859,
            0,
        ],
        1727980920: [
            0.24527,
            0,
            0.24527,
            0,
            52.48208,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            223.8745,
            0,
            1.0238,
            0,
            26.17839,
            0,
            672.3707,
            0,
            0,
            0,
            29.28833,
            0,
            16.0454,
            "<D",
            113.3213,
            0,
        ],
        1727980980: [
            0.45242,
            0,
            0.45242,
            0,
            50.91526,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            249.5991,
            0,
            0.86603,
            0,
            26.68085,
            0,
            484.4506,
            0,
            0,
            0,
            29.28845,
            0,
            15.04178,
            "<D",
            111.0028,
            0,
        ],
        1727981040: [
            0.29532,
            0,
            0.29532,
            0,
            51.14952,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9312,
            0,
            211.4203,
            0,
            0.85968,
            0,
            26.69173,
            0,
            299.0368,
            0,
            0,
            0,
            29.28876,
            0,
            13.28839,
            "<D",
            109.9288,
            0,
        ],
        1727981100: [
            0.26381,
            0,
            0.26381,
            0,
            51.48329,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            244.4044,
            0,
            0.62599,
            0,
            26.38989,
            0,
            478.9767,
            0,
            0,
            0,
            29.28844,
            0,
            14.22663,
            "<D",
            107.9688,
            0,
        ],
        1727981160: [
            0.28032,
            0,
            0.28032,
            0,
            50.19657,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9527,
            0,
            231.2809,
            0,
            0.87398,
            0,
            26.50343,
            0,
            512.9327,
            0,
            0,
            0,
            29.28853,
            0,
            11.87334,
            "<D",
            107.4855,
            0,
        ],
        1727981220: [
            0.22826,
            0,
            0.22826,
            0,
            50.94553,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9527,
            0,
            264.2323,
            0,
            0.71362,
            0,
            26.20197,
            0,
            593.8502,
            0,
            0,
            0,
            29.28842,
            0,
            15.62313,
            "<D",
            108.0771,
            0,
        ],
        1727981280: [
            0.16919,
            0,
            0.16919,
            0,
            52.34636,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            281.486,
            0,
            0.64328,
            0,
            25.82953,
            0,
            596.2966,
            0,
            0,
            0,
            29.28797,
            0,
            13.36978,
            "<D",
            111.4211,
            0,
        ],
        1727981340: [
            0.17051,
            0,
            0.17051,
            0,
            52.77572,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.957,
            0,
            246.6912,
            0,
            0.83223,
            0,
            25.64704,
            0,
            656.5451,
            0,
            0,
            0,
            29.28774,
            0,
            13.69854,
            "<D",
            109.9081,
            0,
        ],
        1727981400: [
            0.11256,
            0,
            0.11256,
            0,
            53.48238,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            215.431,
            0,
            0.69392,
            0,
            25.51942,
            0,
            730.1223,
            0,
            0,
            0,
            29.28783,
            0,
            11.00051,
            "<D",
            110.159,
            0,
        ],
        1727981460: [
            0.12207,
            0,
            0.12207,
            0,
            52.91114,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            228.3105,
            0,
            1.10936,
            0,
            25.91539,
            0,
            700.8903,
            0,
            0,
            0,
            29.28793,
            0,
            14.21389,
            "<D",
            110.3356,
            0,
        ],
        1727981520: [
            0.20862,
            0,
            0.20862,
            0,
            51.80885,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            232.8746,
            0,
            1.20541,
            0,
            25.90657,
            0,
            692.8548,
            0,
            0,
            0,
            29.28804,
            0,
            12.19575,
            "<D",
            110.4913,
            0,
        ],
        1727981580: [
            0.06219,
            0,
            0.06219,
            0,
            52.53049,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            206.5265,
            0,
            1.24009,
            0,
            25.54374,
            0,
            732.9605,
            0,
            0,
            0,
            29.2883,
            0,
            16.81418,
            "<D",
            113.0176,
            0,
        ],
        1727981640: [
            0.40351,
            0,
            0.40351,
            0,
            53.13247,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            228.9498,
            0,
            1.17374,
            0,
            25.54063,
            0,
            719.6919,
            0,
            0,
            0,
            29.28781,
            0,
            14.66044,
            "<D",
            112.6313,
            0,
        ],
        1727981700: [
            0.23677,
            0,
            0.23677,
            0,
            53.44931,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            254.5424,
            0,
            1.27419,
            0,
            25.60365,
            0,
            654.1852,
            0,
            0,
            0,
            29.28785,
            0,
            11.61636,
            "<D",
            110.2127,
            0,
        ],
        1727981760: [
            0.09767,
            0,
            0.09767,
            0,
            53.63882,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            257.9413,
            0,
            0.7746,
            0,
            25.64232,
            0,
            655.3817,
            0,
            0,
            0,
            29.28638,
            0,
            13.41914,
            "<D",
            108.807,
            0,
        ],
        1727981820: [
            0.05904,
            0,
            0.05904,
            0,
            53.84835,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            251.4046,
            0,
            0.54657,
            0,
            25.70229,
            0,
            610.9283,
            0,
            0,
            0,
            29.2864,
            0,
            17.29586,
            "<D",
            107.9224,
            0,
        ],
        1727981880: [
            0.25746,
            0,
            0.25746,
            0,
            53.94523,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            217.4091,
            0,
            0.41952,
            0,
            25.81884,
            0,
            616.4825,
            0,
            0,
            0,
            29.28493,
            0,
            12.41685,
            "<D",
            108.3769,
            0,
        ],
        1727981940: [
            0.10416,
            0,
            0.10416,
            0,
            53.62392,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9699,
            0,
            176.1621,
            0,
            0.82102,
            0,
            26.22012,
            0,
            513.0971,
            0,
            0,
            0,
            29.28522,
            0,
            14.69542,
            "<D",
            110.2727,
            0,
        ],
        1727982000: [
            0.03453,
            0,
            0.03453,
            0,
            52.9563,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            197.425,
            0,
            1.33078,
            0,
            26.00799,
            0,
            516.3314,
            0,
            0,
            0,
            29.28382,
            0,
            12.44279,
            "<D",
            112.1772,
            0,
        ],
        1727982060: [
            0.38413,
            0,
            0.38413,
            0,
            52.45407,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9441,
            0,
            200.0197,
            0,
            1.40806,
            0,
            25.74515,
            0,
            531.2721,
            0,
            0,
            0,
            29.28419,
            0,
            15.4857,
            "<D",
            110.4158,
            0,
        ],
        1727982120: [
            0.21987,
            0,
            0.21987,
            0,
            52.7753,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            223.1756,
            0,
            1.34465,
            0,
            25.59765,
            0,
            545.47,
            0,
            0,
            0,
            29.28361,
            0,
            16.83776,
            "<D",
            110.8091,
            0,
        ],
        1727982180: [
            0.15918,
            0,
            0.15918,
            0,
            52.65416,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            197.8374,
            0,
            1.03035,
            0,
            25.77105,
            0,
            568.8998,
            0,
            0,
            0,
            29.28374,
            0,
            15.3835,
            "<D",
            110.6408,
            0,
        ],
        1727982240: [
            0.1067,
            0,
            0.1067,
            0,
            52.22553,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            178.0543,
            0,
            0.89651,
            0,
            25.87848,
            0,
            591.9691,
            0,
            0,
            0,
            29.28387,
            0,
            14.72313,
            "<D",
            111.7908,
            0,
        ],
        1727982300: [
            0.43978,
            0,
            0.43978,
            0,
            52.42691,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            202.0634,
            0,
            0.92031,
            0,
            25.57491,
            0,
            626.8189,
            0,
            0,
            0,
            29.28405,
            0,
            16.04557,
            "<D",
            112.638,
            0,
        ],
        1727982360: [
            0.09534,
            0,
            0.09534,
            0,
            53.15247,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            210.931,
            0,
            1.96351,
            0,
            25.70702,
            0,
            695.6014,
            0,
            0,
            0,
            29.28422,
            0,
            13.76728,
            "<D",
            111.4372,
            0,
        ],
        1727982420: [
            0.10197,
            0,
            0.10197,
            0,
            53.39277,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            203.0797,
            0,
            1.36795,
            0,
            25.77136,
            0,
            577.9641,
            0,
            0,
            0,
            29.28426,
            0,
            13.78574,
            "<D",
            111.1742,
            0,
        ],
        1727982480: [
            0.40611,
            0,
            0.40611,
            0,
            55.29765,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            213.0989,
            0,
            1.13398,
            0,
            25.73683,
            0,
            686.926,
            0,
            0,
            0,
            29.28411,
            0,
            15.12974,
            "<D",
            109.5721,
            0,
        ],
        1727982540: [
            0.19801,
            0,
            0.19801,
            0,
            54.8479,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            210.0264,
            0,
            1.07306,
            0,
            26.13418,
            0,
            735.4039,
            0,
            0,
            0,
            29.28395,
            0,
            13.32171,
            "<D",
            108.5951,
            0,
        ],
        1727982600: [
            0.15929,
            0,
            0.15929,
            0,
            54.03833,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            203.9573,
            0,
            1.2038,
            0,
            26.57901,
            0,
            730.8894,
            0,
            0,
            0,
            29.28369,
            0,
            12.03702,
            "<D",
            109.5449,
            0,
        ],
        1727982660: [
            0.32525,
            0,
            0.32525,
            0,
            53.26908,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9441,
            0,
            220.8994,
            0,
            1.14455,
            0,
            26.83617,
            0,
            726.6777,
            0,
            0,
            0,
            29.28359,
            0,
            14.5049,
            "<D",
            113.7372,
            0,
        ],
        1727982720: [
            0.12073,
            0,
            0.12073,
            0,
            53.76151,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            201.4618,
            0,
            0.96755,
            0,
            27.15485,
            0,
            724.0488,
            0,
            0,
            0,
            29.28358,
            0,
            16.56645,
            "<D",
            111.6786,
            0,
        ],
        1727982780: [
            0.44533,
            0,
            0.44533,
            0,
            53.95017,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            236.0068,
            0,
            1.141,
            0,
            27.33983,
            0,
            720.2833,
            0,
            0,
            0,
            29.28353,
            0,
            10.88175,
            "<D",
            112.0442,
            0,
        ],
        1727982840: [
            0.1598,
            0,
            0.1598,
            0,
            54.45123,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            230.9431,
            0,
            1.24646,
            0,
            26.92419,
            0,
            709.9826,
            0,
            0,
            0,
            29.28292,
            0,
            13.76525,
            "<D",
            111.5355,
            0,
        ],
        1727982900: [
            0.19466,
            0,
            0.19466,
            0,
            55.76438,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            258.0453,
            0,
            0.62949,
            0,
            26.68836,
            0,
            693.8371,
            0,
            0,
            0,
            29.2839,
            0,
            14.20359,
            "<D",
            111.8458,
            0,
        ],
        1727982960: [
            0.19931,
            0,
            0.19931,
            0,
            55.51459,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            204.2559,
            0,
            1.53537,
            0,
            26.67059,
            0,
            689.6971,
            0,
            0,
            0,
            29.28325,
            0,
            14.37716,
            "<D",
            113.0984,
            0,
        ],
        1727983020: [
            0.3389,
            0,
            0.3389,
            0,
            54.58993,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            204.7953,
            0,
            2.00292,
            0,
            26.76887,
            0,
            689.4735,
            0,
            0,
            0,
            29.28366,
            0,
            11.09643,
            "<D",
            112.0595,
            0,
        ],
        1727983080: [
            0.22939,
            0,
            0.22939,
            0,
            55.4088,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            213.0433,
            0,
            1.23759,
            0,
            26.55325,
            0,
            696.3504,
            0,
            0,
            0,
            29.28392,
            0,
            11.98056,
            "<D",
            111.1222,
            0,
        ],
        1727983140: [
            0.25142,
            0,
            0.25142,
            0,
            55.33793,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            205.8826,
            0,
            1.45036,
            0,
            26.56108,
            0,
            710.007,
            0,
            0,
            0,
            29.2841,
            0,
            14.36036,
            "<D",
            108.6521,
            0,
        ],
        1727983200: [
            0.17637,
            0,
            0.17637,
            0,
            54.34504,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9312,
            0,
            203.4303,
            0,
            1.42925,
            0,
            26.73848,
            0,
            713.2155,
            0,
            0,
            0,
            29.28346,
            0,
            10.33316,
            "<D",
            108.2056,
            0,
        ],
        1727983260: [
            0.14945,
            0,
            0.14945,
            0,
            54.1447,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            234.608,
            0,
            1.12664,
            0,
            26.78476,
            0,
            712.851,
            0,
            0,
            0,
            29.28327,
            0,
            12.45415,
            "<D",
            109.7666,
            0,
        ],
        1727983320: [
            0.24192,
            0,
            0.24192,
            0,
            54.37289,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            212.4406,
            0,
            1.31004,
            0,
            26.97851,
            0,
            713.0134,
            0,
            0,
            0,
            29.28278,
            0,
            13.79163,
            "<D",
            112.3449,
            0,
        ],
        1727983380: [
            0.19338,
            0,
            0.19338,
            0,
            54.78053,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            209.2842,
            0,
            1.25366,
            0,
            26.61058,
            0,
            713.6375,
            0,
            0,
            0,
            29.28159,
            0,
            12.43057,
            "<D",
            110.609,
            0,
        ],
        1727983440: [
            -0.00497,
            0,
            -0.00497,
            0,
            54.79731,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            211.5475,
            0,
            1.53564,
            0,
            27.04083,
            0,
            713.7098,
            0,
            0,
            0,
            29.28272,
            0,
            12.38898,
            "<D",
            110.6345,
            0,
        ],
        1727983500: [
            0.16717,
            0,
            0.16717,
            0,
            54.76459,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            201.625,
            0,
            1.70735,
            0,
            27.15112,
            0,
            705.4911,
            0,
            0,
            0,
            29.28202,
            0,
            13.21542,
            "<D",
            110.4608,
            0,
        ],
        1727983560: [
            0.24241,
            0,
            0.24241,
            0,
            55.67616,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            195.4664,
            0,
            1.16992,
            0,
            26.7635,
            0,
            665.1191,
            0,
            0,
            0,
            29.28363,
            0,
            16.2065,
            "<D",
            111.2097,
            0,
        ],
        1727983620: [
            0.14973,
            0,
            0.14973,
            0,
            55.05188,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            216.3612,
            0,
            1.06763,
            0,
            27.10221,
            0,
            630.6733,
            0,
            0,
            0,
            29.28361,
            0,
            14.60949,
            "<D",
            112.0514,
            0,
        ],
        1727983680: [
            0.27327,
            0,
            0.27327,
            0,
            54.33132,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            233.1569,
            0,
            1.32071,
            0,
            27.09523,
            0,
            525.3009,
            0,
            0,
            0,
            29.28317,
            0,
            13.21023,
            "<D",
            110.0819,
            0,
        ],
        1727983740: [
            0.10523,
            0,
            0.10523,
            0,
            53.51264,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            233.0553,
            0,
            1.24402,
            0,
            26.87037,
            0,
            541.4579,
            0,
            0,
            0,
            29.2836,
            0,
            11.66575,
            "<D",
            110.6019,
            0,
        ],
        1727983800: [
            0.03461,
            0,
            0.03461,
            0,
            53.09526,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            241.3149,
            0,
            1.66412,
            0,
            26.84971,
            0,
            475.6098,
            0,
            0,
            0,
            29.28348,
            0,
            16.11082,
            "<D",
            108.5963,
            0,
        ],
        1727983860: [
            0.27113,
            0,
            0.27113,
            0,
            53.84946,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            253.1442,
            0,
            0.63014,
            0,
            26.79603,
            0,
            547.4055,
            0,
            0,
            0,
            29.28358,
            0,
            12.9833,
            "<D",
            109.1733,
            0,
        ],
        1727983920: [
            0.19994,
            0,
            0.19994,
            0,
            53.60397,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            235.3705,
            0,
            0.72407,
            0,
            27.00899,
            0,
            678.1296,
            0,
            0,
            0,
            29.28358,
            0,
            14.41703,
            "<D",
            111.5716,
            0,
        ],
        1727983980: [
            0.18168,
            0,
            0.18168,
            0,
            54.60864,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8882,
            0,
            267.3795,
            0,
            1.62608,
            0,
            26.77598,
            0,
            701.9297,
            0,
            0,
            0,
            29.28348,
            0,
            14.43579,
            "<D",
            109.9337,
            0,
        ],
        1727984040: [
            0.27841,
            0,
            0.27841,
            0,
            55.77,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            268.9118,
            0,
            0.79357,
            0,
            26.40165,
            0,
            703.2092,
            0,
            0,
            0,
            29.28288,
            0,
            13.44865,
            "<D",
            111.409,
            0,
        ],
        1727984100: [
            0.29518,
            0,
            0.29518,
            0,
            56.06375,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            283.2248,
            0,
            0.64139,
            0,
            26.9407,
            0,
            678.1652,
            0,
            0,
            0,
            29.28267,
            0,
            15.74234,
            "<D",
            110.965,
            0,
        ],
        1727984160: [
            0.27564,
            0,
            0.27564,
            0,
            55.11227,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.871,
            0,
            243.7326,
            0,
            1.30423,
            0,
            26.85334,
            0,
            669.7346,
            0,
            0,
            0,
            29.28277,
            0,
            13.87566,
            "<D",
            111.0799,
            0,
        ],
        1727984220: [
            0.25639,
            0,
            0.25639,
            0,
            55.2132,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            234.6665,
            0,
            1.91099,
            0,
            26.6848,
            0,
            687.0419,
            0,
            0,
            0,
            29.28259,
            0,
            11.57121,
            "<D",
            113.5207,
            0,
        ],
        1727984280: [
            0.17947,
            0,
            0.17947,
            0,
            56.13562,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            272.296,
            0,
            0.59547,
            0,
            26.40558,
            0,
            684.2125,
            0,
            0,
            0,
            29.28164,
            0,
            14.94589,
            "<D",
            112.4227,
            0,
        ],
        1727984340: [
            0.15926,
            0,
            0.15926,
            0,
            55.78286,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            282.981,
            0,
            1.49743,
            0,
            26.34414,
            0,
            666.8569,
            0,
            0,
            0,
            29.28045,
            0,
            14.29722,
            "<D",
            112.0798,
            0,
        ],
        1727984400: [
            0.32066,
            0,
            0.32066,
            0,
            54.81561,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            279.2349,
            0,
            1.43468,
            0,
            26.62601,
            0,
            685.3784,
            0,
            0,
            0,
            29.28115,
            0,
            10.78742,
            "<D",
            110.0957,
            0,
        ],
        1727984460: [
            0.15619,
            0,
            0.15619,
            0,
            54.48006,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            292.0602,
            0,
            2.11108,
            0,
            26.37602,
            0,
            645.9996,
            0,
            0,
            0,
            29.28072,
            0,
            15.37821,
            "<D",
            110.5568,
            0,
        ],
        1727984520: [
            0.14897,
            0,
            0.14897,
            0,
            54.37247,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            301.3333,
            0,
            1.63628,
            0,
            26.115,
            0,
            664.8477,
            0,
            0,
            0,
            29.2804,
            0,
            13.0977,
            "<D",
            113.4097,
            0,
        ],
        1727984580: [
            0.25292,
            0,
            0.25292,
            0,
            54.56671,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            243.7484,
            0,
            0.94042,
            0,
            26.1743,
            0,
            671.6185,
            0,
            0,
            0,
            29.28061,
            0,
            10.54544,
            "<D",
            111.7222,
            0,
        ],
        1727984640: [
            0.14649,
            0,
            0.14649,
            0,
            54.20747,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            194.7702,
            0,
            1.62694,
            0,
            26.47054,
            0,
            668.388,
            0,
            0,
            0,
            29.28034,
            0,
            14.1745,
            "<D",
            112.4364,
            0,
        ],
        1727984700: [
            0.17633,
            0,
            0.17633,
            0,
            53.57377,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            210.9225,
            0,
            1.64282,
            0,
            26.43921,
            0,
            663.0328,
            0,
            0,
            0,
            29.28034,
            0,
            11.69337,
            "<D",
            112.5257,
            0,
        ],
        1727984760: [
            0.24124,
            0,
            0.24124,
            0,
            54.34501,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            198.7559,
            0,
            1.6404,
            0,
            26.38577,
            0,
            657.621,
            0,
            0,
            0,
            29.28034,
            0,
            14.59737,
            "<D",
            111.8596,
            0,
        ],
        1727984820: [
            0.26772,
            0,
            0.26772,
            0,
            53.36989,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            221.5879,
            0,
            1.20403,
            0,
            26.66689,
            0,
            652.3753,
            0,
            0,
            0,
            29.28034,
            0,
            10.56196,
            "<D",
            111.4484,
            0,
        ],
        1727984880: [
            0.11735,
            0,
            0.11735,
            0,
            52.91619,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            207.0087,
            0,
            1.57824,
            0,
            26.45323,
            0,
            647.1831,
            0,
            0,
            0,
            29.28021,
            0,
            12.14923,
            "<D",
            113.018,
            0,
        ],
        1727984940: [
            0.30187,
            0,
            0.30187,
            0,
            53.40667,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            242.209,
            0,
            1.49572,
            0,
            26.11046,
            0,
            641.4631,
            0,
            0,
            0,
            29.27821,
            0,
            13.6127,
            "<D",
            111.6539,
            0,
        ],
        1727985000: [
            0.26374,
            0,
            0.26374,
            0,
            53.45797,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            210.0378,
            0,
            1.23603,
            0,
            26.10085,
            0,
            636.2103,
            0,
            0,
            0,
            29.2783,
            0,
            16.4972,
            "<D",
            111.6333,
            0,
        ],
        1727985060: [
            0.21032,
            0,
            0.21032,
            0,
            53.24135,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            258.026,
            0,
            1.35656,
            0,
            26.6817,
            0,
            630.7308,
            0,
            0,
            0,
            29.27747,
            0,
            14.69559,
            "<D",
            110.2582,
            0,
        ],
        1727985120: [
            0.1559,
            0,
            0.1559,
            0,
            52.10987,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            266.4398,
            0,
            1.51111,
            0,
            26.65987,
            0,
            625.4517,
            0,
            0,
            0,
            29.27751,
            0,
            12.50639,
            "<D",
            112.1979,
            0,
        ],
        1727985180: [
            0.21737,
            0,
            0.21737,
            0,
            52.48727,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            249.343,
            0,
            0.68581,
            0,
            26.94331,
            0,
            620.1871,
            0,
            0,
            0,
            29.27715,
            0,
            15.08984,
            "<D",
            110.7564,
            0,
        ],
        1727985240: [
            0.313,
            0,
            0.313,
            0,
            51.09672,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.871,
            0,
            268.6844,
            0,
            1.92981,
            0,
            26.86248,
            0,
            615.9069,
            0,
            0,
            0,
            29.2771,
            0,
            13.2835,
            "<D",
            110.2117,
            0,
        ],
        1727985300: [
            0.16017,
            0,
            0.16017,
            0,
            51.63875,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            259.0133,
            0,
            1.12779,
            0,
            26.47989,
            0,
            611.5003,
            0,
            0,
            0,
            29.27707,
            0,
            13.74845,
            "<D",
            110.2304,
            0,
        ],
        1727985360: [
            0.09406,
            0,
            0.09406,
            0,
            51.94783,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            276.442,
            0,
            0.73633,
            0,
            26.17629,
            0,
            607.3284,
            0,
            0,
            0,
            29.27706,
            0,
            14.13062,
            "<D",
            111.7784,
            0,
        ],
        1727985420: [
            0.23038,
            0,
            0.23038,
            0,
            51.50039,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            228.8816,
            0,
            0.9478,
            0,
            26.2926,
            0,
            602.2549,
            0,
            0,
            0,
            29.27679,
            0,
            13.44161,
            "<D",
            111.1881,
            0,
        ],
        1727985480: [
            0.16149,
            0,
            0.16149,
            0,
            50.75467,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            301.6256,
            0,
            1.109,
            0,
            26.59322,
            0,
            598.7297,
            0,
            0,
            0,
            29.27662,
            0,
            15.06805,
            "<D",
            111.5834,
            0,
        ],
        1727985540: [
            0.15079,
            0,
            0.15079,
            0,
            51.23004,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            210.2966,
            0,
            1.06181,
            0,
            26.40854,
            0,
            575.5194,
            0,
            0,
            0,
            29.2766,
            0,
            14.03842,
            "<D",
            111.237,
            0,
        ],
        1727985600: [
            0.22142,
            0,
            0.22142,
            0,
            51.45195,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            247.5433,
            0,
            1.04845,
            0,
            26.68618,
            0,
            542.7659,
            0,
            0,
            0,
            29.27666,
            0,
            15.37587,
            "<D",
            109.7686,
            0,
        ],
        1727985660: [
            0.04883,
            0,
            0.04883,
            0,
            51.17373,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            279.9667,
            0,
            1.06924,
            0,
            26.64157,
            0,
            566.1531,
            0,
            0,
            0,
            29.27698,
            0,
            12.79202,
            "<D",
            110.0831,
            0,
        ],
        1727985720: [
            0.2693,
            0,
            0.2693,
            0,
            51.0981,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8796,
            0,
            274.989,
            0,
            1.42219,
            0,
            26.60488,
            0,
            570.2255,
            0,
            0,
            0,
            29.27705,
            0,
            14.83707,
            "<D",
            110.6956,
            0,
        ],
        1727985780: [
            0.21421,
            0,
            0.21421,
            0,
            50.56725,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            192.1226,
            0,
            1.01337,
            0,
            26.75099,
            0,
            571.6428,
            0,
            0,
            0,
            29.2771,
            0,
            11.76151,
            "<D",
            110.8222,
            0,
        ],
        1727985840: [
            0.29088,
            0,
            0.29088,
            0,
            50.2984,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            171.8587,
            0,
            1.40407,
            0,
            26.8958,
            0,
            486.01,
            0,
            0,
            0,
            29.2771,
            0,
            12.24094,
            "<D",
            109.7804,
            0,
        ],
        1727985900: [
            0.2324,
            0,
            0.2324,
            0,
            51.2819,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            213.9993,
            0,
            1.64486,
            0,
            26.42064,
            0,
            548.1992,
            0,
            0,
            0,
            29.27709,
            0,
            10.47731,
            "<D",
            111.5376,
            0,
        ],
        1727985960: [
            0.12057,
            0,
            0.12057,
            0,
            52.25682,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            223.9619,
            0,
            1.11116,
            0,
            26.39769,
            0,
            565.3889,
            0,
            0,
            0,
            29.27709,
            0,
            14.4632,
            "<D",
            111.8118,
            0,
        ],
        1727986020: [
            0.31397,
            0,
            0.31397,
            0,
            51.25922,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            217.2655,
            0,
            1.29927,
            0,
            26.72855,
            0,
            568.0432,
            0,
            0,
            0,
            29.27703,
            0,
            15.10298,
            "<D",
            111.2354,
            0,
        ],
        1727986080: [
            0.13976,
            0,
            0.13976,
            0,
            50.5734,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            248.8157,
            0,
            2.00109,
            0,
            26.89913,
            0,
            561.7569,
            0,
            0,
            0,
            29.27656,
            0,
            12.96014,
            "<D",
            112.8348,
            0,
        ],
        1727986140: [
            0.21583,
            0,
            0.21583,
            0,
            50.48373,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            220.4757,
            0,
            1.67383,
            0,
            26.81307,
            0,
            555.5215,
            0,
            0,
            0,
            29.27647,
            0,
            14.30299,
            "<D",
            112.248,
            0,
        ],
        1727986200: [
            0.28383,
            0,
            0.28383,
            0,
            50.78801,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            227.189,
            0,
            1.75645,
            0,
            27.05769,
            0,
            541.1488,
            0,
            0,
            0,
            29.27672,
            0,
            15.62643,
            "<D",
            110.9123,
            0,
        ],
        1727986260: [
            0.31944,
            0,
            0.31944,
            0,
            49.90311,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            221.0089,
            0,
            1.04605,
            0,
            26.88153,
            0,
            553.9246,
            0,
            0,
            0,
            29.27679,
            0,
            12.09942,
            "<D",
            110.0866,
            0,
        ],
        1727986320: [
            0.29591,
            0,
            0.29591,
            0,
            50.20842,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            221.1453,
            0,
            1.41211,
            0,
            26.7609,
            0,
            549.8947,
            0,
            0,
            0,
            29.27703,
            0,
            15.1177,
            "<D",
            111.8672,
            0,
        ],
        1727986380: [
            0.10079,
            0,
            0.10079,
            0,
            50.59737,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            223.9173,
            0,
            2.44995,
            0,
            26.72855,
            0,
            540.6412,
            0,
            0,
            0,
            29.27718,
            0,
            9.6944,
            "<D",
            113.1408,
            0,
        ],
        1727986440: [
            0.07629,
            0,
            0.07629,
            0,
            51.50672,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            195.0848,
            0,
            1.80965,
            0,
            26.52282,
            0,
            543.909,
            0,
            0,
            0,
            29.27701,
            0,
            13.08566,
            "<D",
            110.9976,
            0,
        ],
        1727986500: [
            0.23397,
            0,
            0.23397,
            0,
            51.24428,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            188.828,
            0,
            1.85542,
            0,
            26.57829,
            0,
            541.5691,
            0,
            0,
            0,
            29.27709,
            0,
            12.38646,
            "<D",
            110.5922,
            0,
        ],
        1727986560: [
            0.08935,
            0,
            0.08935,
            0,
            50.17329,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            213.1649,
            0,
            1.66915,
            0,
            26.72669,
            0,
            529.0884,
            0,
            0,
            0,
            29.27706,
            0,
            14.96271,
            "<D",
            110.0511,
            0,
        ],
        1727986620: [
            0.23503,
            0,
            0.23503,
            0,
            49.98543,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            220.2299,
            0,
            1.4125,
            0,
            26.56912,
            0,
            525.3915,
            0,
            0,
            0,
            29.2771,
            0,
            13.06295,
            "<D",
            108.8032,
            0,
        ],
        1727986680: [
            0.12909,
            0,
            0.12909,
            0,
            50.18428,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            208.9278,
            0,
            1.22958,
            0,
            26.51615,
            0,
            492.5178,
            0,
            0,
            0,
            29.27641,
            0,
            12.33664,
            "<D",
            109.5398,
            0,
        ],
        1727986740: [
            0.17359,
            0,
            0.17359,
            0,
            49.5492,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            224.2003,
            0,
            1.31997,
            0,
            26.46097,
            0,
            329.4213,
            0,
            0,
            0,
            29.2764,
            0,
            12.45179,
            "<D",
            109.4408,
            0,
        ],
        1727986800: [
            0.24973,
            0,
            0.24973,
            0,
            49.01308,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            232.5774,
            0,
            1.54097,
            0,
            26.49064,
            0,
            396.7567,
            0,
            0,
            0,
            29.27658,
            0,
            14.29607,
            "<D",
            110.5881,
            0,
        ],
        1727986860: [
            0.17472,
            0,
            0.17472,
            0,
            49.17805,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            234.8991,
            0,
            1.1621,
            0,
            26.37593,
            0,
            424.4663,
            0,
            0,
            0,
            29.27646,
            0,
            16.36545,
            "<D",
            109.1285,
            0,
        ],
        1727986920: [
            0.1667,
            0,
            0.1667,
            0,
            48.5125,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            203.3923,
            0,
            1.55479,
            0,
            26.36406,
            0,
            418.3678,
            0,
            0,
            0,
            29.27635,
            0,
            15.98328,
            "<D",
            110.3635,
            0,
        ],
        1727986980: [
            0.29409,
            0,
            0.29409,
            0,
            48.63451,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            197.006,
            0,
            1.75037,
            0,
            26.34563,
            0,
            428.6923,
            0,
            0,
            0,
            29.27479,
            0,
            12.38972,
            "<D",
            110.8423,
            0,
        ],
        1727987040: [
            0.22862,
            0,
            0.22862,
            0,
            49.60614,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            223.4029,
            0,
            1.24351,
            0,
            26.26725,
            0,
            482.3152,
            0,
            0,
            0,
            29.27512,
            0,
            15.10156,
            "<D",
            111.8657,
            0,
        ],
        1727987100: [
            0.32933,
            0,
            0.32933,
            0,
            48.90977,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            221.2119,
            0,
            1.36991,
            0,
            26.42615,
            0,
            422.9961,
            0,
            0,
            0,
            29.27579,
            0,
            13.53963,
            "<D",
            109.8608,
            0,
        ],
        1727987160: [
            0.05553,
            0,
            0.05553,
            0,
            48.56624,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            202.3756,
            0,
            1.23987,
            0,
            26.71348,
            0,
            521.7191,
            0,
            0,
            0,
            29.27452,
            0,
            13.19881,
            "<D",
            110.1664,
            0,
        ],
        1727987220: [
            0.10132,
            0,
            0.10132,
            0,
            48.2929,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            196.9271,
            0,
            1.2928,
            0,
            26.64348,
            0,
            519.4121,
            0,
            0,
            0,
            29.27518,
            0,
            12.07696,
            "<D",
            110.6055,
            0,
        ],
        1727987280: [
            0.18231,
            0,
            0.18231,
            0,
            48.30571,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            208.1273,
            0,
            0.88232,
            0,
            26.62987,
            0,
            519.9777,
            0,
            0,
            0,
            29.27543,
            0,
            12.558,
            "<D",
            110.6437,
            0,
        ],
        1727987340: [
            0.13425,
            0,
            0.13425,
            0,
            47.52826,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            183.4168,
            0,
            1.22754,
            0,
            26.66976,
            0,
            523.7344,
            0,
            0,
            0,
            29.27533,
            0,
            13.12035,
            "<D",
            111.0834,
            0,
        ],
        1727987400: [
            0.24205,
            0,
            0.24205,
            0,
            46.95884,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            229.3263,
            0,
            0.89389,
            0,
            27.01052,
            0,
            435.2699,
            0,
            0,
            0,
            29.27576,
            0,
            12.31366,
            "<D",
            111.5024,
            0,
        ],
        1727987460: [
            0.31556,
            0,
            0.31556,
            0,
            47.52184,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            216.4536,
            0,
            0.95614,
            0,
            26.83397,
            0,
            481.507,
            0,
            0,
            0,
            29.27507,
            0,
            13.20653,
            "<D",
            109.9981,
            0,
        ],
        1727987520: [
            0.05221,
            0,
            0.05221,
            0,
            48.23387,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            209.3007,
            0,
            1.34296,
            0,
            26.38041,
            0,
            485.1874,
            0,
            0,
            0,
            29.27516,
            0,
            13.12826,
            "<D",
            109.9997,
            0,
        ],
        1727987580: [
            0.11808,
            0,
            0.11808,
            0,
            48.83654,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            212.4469,
            0,
            1.44878,
            0,
            26.46507,
            0,
            513.0739,
            0,
            0,
            0,
            29.27306,
            0,
            13.24017,
            "<D",
            109.1401,
            0,
        ],
        1727987640: [
            0.1432,
            0,
            0.1432,
            0,
            48.37232,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            191.666,
            0,
            1.97432,
            0,
            26.39774,
            0,
            509.1004,
            0,
            0,
            0,
            29.27378,
            0,
            11.21551,
            "<D",
            111.9288,
            0,
        ],
        1727987700: [
            0.1855,
            0,
            0.1855,
            0,
            48.47565,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            196.3362,
            0,
            1.74023,
            0,
            26.33472,
            0,
            505.1996,
            0,
            0,
            0,
            29.27363,
            0,
            10.42888,
            "<D",
            109.4868,
            0,
        ],
        1727987760: [
            0.19887,
            0,
            0.19887,
            0,
            48.87664,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            179.9834,
            0,
            1.46363,
            0,
            26.49207,
            0,
            502.4844,
            0,
            0,
            0,
            29.27305,
            0,
            13.63216,
            "<D",
            108.4517,
            0,
        ],
        1727987820: [
            0.10981,
            0,
            0.10981,
            0,
            48.14981,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            191.9968,
            0,
            1.27955,
            0,
            26.59896,
            0,
            489.8488,
            0,
            0,
            0,
            29.27332,
            0,
            9.75367,
            "<D",
            108.9175,
            0,
        ],
        1727987880: [
            0.06264,
            0,
            0.06264,
            0,
            47.72995,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            213.0897,
            0,
            1.61716,
            0,
            26.40353,
            0,
            493.0253,
            0,
            0,
            0,
            29.27435,
            0,
            10.17865,
            "<D",
            109.6864,
            0,
        ],
        1727987940: [
            0.16274,
            0,
            0.16274,
            0,
            48.95201,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            235.6229,
            0,
            1.4232,
            0,
            26.70908,
            0,
            475.3671,
            0,
            0,
            0,
            29.27352,
            0,
            13.66471,
            "<D",
            109.5411,
            0,
        ],
        1727988000: [
            0.19658,
            0,
            0.19658,
            0,
            48.8937,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            235.5759,
            0,
            1.42048,
            0,
            26.55215,
            0,
            477.3833,
            0,
            0,
            0,
            29.27378,
            0,
            14.88905,
            "<D",
            110.7128,
            0,
        ],
        1727988060: [
            0.25541,
            0,
            0.25541,
            0,
            49.66006,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            218.121,
            0,
            1.18514,
            0,
            26.54155,
            0,
            412.6063,
            0,
            0,
            0,
            29.27346,
            0,
            13.46051,
            "<D",
            110.9452,
            0,
        ],
        1727988120: [
            0.2067,
            0,
            0.2067,
            0,
            48.84438,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            232.1507,
            0,
            0.95449,
            0,
            26.52875,
            0,
            463.6798,
            0,
            0,
            0,
            29.2742,
            0,
            12.26281,
            "<D",
            110.2301,
            0,
        ],
        1727988180: [
            0.29683,
            0,
            0.29683,
            0,
            48.63677,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            194.4428,
            0,
            1.16805,
            0,
            26.38251,
            0,
            477.7626,
            0,
            0,
            0,
            29.27338,
            0,
            13.44834,
            "<D",
            110.8641,
            0,
        ],
        1727988240: [
            0.18141,
            0,
            0.18141,
            0,
            48.45652,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            212.0856,
            0,
            1.58251,
            0,
            26.39987,
            0,
            479.7282,
            0,
            0,
            0,
            29.27345,
            0,
            10.7295,
            "<D",
            110.1702,
            0,
        ],
        1727988300: [
            0.2,
            0,
            0.2,
            0,
            49.11461,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            200.0574,
            0,
            1.75821,
            0,
            26.57562,
            0,
            476.4467,
            0,
            0,
            0,
            29.27315,
            0,
            15.06791,
            "<D",
            112.5985,
            0,
        ],
        1727988360: [
            0.21319,
            0,
            0.21319,
            0,
            48.85618,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            223.8524,
            0,
            1.53676,
            0,
            26.55594,
            0,
            472.4387,
            0,
            0,
            0,
            29.27369,
            0,
            13.62667,
            "<D",
            110.9448,
            0,
        ],
        1727988420: [
            0.16357,
            0,
            0.16357,
            0,
            48.61642,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            205.4354,
            0,
            1.1091,
            0,
            26.76845,
            0,
            468.8567,
            0,
            0,
            0,
            29.27305,
            0,
            13.38123,
            "<D",
            110.1222,
            0,
        ],
        1727988480: [
            0.11318,
            0,
            0.11318,
            0,
            48.08117,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            203.7779,
            0,
            1.35974,
            0,
            26.73838,
            0,
            466.0797,
            0,
            0,
            0,
            29.27427,
            0,
            12.13966,
            "<D",
            110.2153,
            0,
        ],
        1727988540: [
            0.22358,
            0,
            0.22358,
            0,
            47.7666,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            231.2551,
            0,
            1.71625,
            0,
            26.59622,
            0,
            461.9709,
            0,
            0,
            0,
            29.27533,
            0,
            13.60226,
            "<D",
            110.9942,
            0,
        ],
        1727988600: [
            0.25431,
            0,
            0.25431,
            0,
            48.39802,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            236.4816,
            0,
            1.38321,
            0,
            26.60456,
            0,
            442.3089,
            0,
            0,
            0,
            29.27491,
            0,
            13.58341,
            "<D",
            111.5527,
            0,
        ],
        1727988660: [
            0.23333,
            0,
            0.23333,
            0,
            49.09592,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            216.7277,
            0,
            1.14515,
            0,
            26.67066,
            0,
            448.3463,
            0,
            0,
            0,
            29.27599,
            0,
            11.22897,
            "<D",
            112.0159,
            0,
        ],
        1727988720: [
            0.10804,
            0,
            0.10804,
            0,
            49.26619,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            216.8832,
            0,
            1.02422,
            0,
            26.79261,
            0,
            434.677,
            0,
            0,
            0,
            29.27679,
            0,
            11.11664,
            "<D",
            112.4547,
            0,
        ],
        1727988780: [
            0.0964,
            0,
            0.0964,
            0,
            48.86018,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            234.2953,
            0,
            1.49933,
            0,
            26.67287,
            0,
            398.289,
            0,
            0,
            0,
            29.27659,
            0,
            12.95202,
            "<D",
            112.2056,
            0,
        ],
        1727988840: [
            0.20919,
            0,
            0.20919,
            0,
            49.29613,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            216.3187,
            0,
            1.40305,
            0,
            26.43118,
            0,
            435.4386,
            0,
            0,
            0,
            29.27654,
            0,
            13.99653,
            "<D",
            110.2887,
            0,
        ],
        1727988900: [
            0.22301,
            0,
            0.22301,
            0,
            48.93395,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            226.7187,
            0,
            1.41583,
            0,
            26.59611,
            0,
            441.4242,
            0,
            0,
            0,
            29.27646,
            0,
            12.83222,
            "<D",
            110.5174,
            0,
        ],
        1727988960: [
            0.26088,
            0,
            0.26088,
            0,
            48.63942,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            231.2628,
            0,
            1.74668,
            0,
            26.56122,
            0,
            434.712,
            0,
            0,
            0,
            29.27648,
            0,
            14.71066,
            "<D",
            113.1799,
            0,
        ],
        1727989020: [
            0.11666,
            0,
            0.11666,
            0,
            49.13648,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            231.8142,
            0,
            1.1828,
            0,
            26.58459,
            0,
            346.5605,
            0,
            0,
            0,
            29.27639,
            0,
            12.63844,
            "<D",
            111.0115,
            0,
        ],
        1727989080: [
            0.33279,
            0,
            0.33279,
            0,
            48.88475,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            246.9529,
            0,
            1.33258,
            0,
            26.41058,
            0,
            271.2191,
            0,
            0,
            0,
            29.27636,
            0,
            14.53021,
            "<D",
            111.1181,
            0,
        ],
        1727989140: [
            0.33098,
            0,
            0.33098,
            0,
            48.74907,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            253.015,
            0,
            0.92618,
            0,
            26.10751,
            0,
            235.3211,
            0,
            0,
            0,
            29.27645,
            0,
            13.28078,
            "<D",
            110.7593,
            0,
        ],
        1727989200: [
            0.22679,
            0,
            0.22679,
            0,
            47.94047,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            244.641,
            0,
            0.78386,
            0,
            26.30935,
            0,
            394.1471,
            0,
            0,
            0,
            29.27648,
            0,
            13.63313,
            "<D",
            110.4559,
            0,
        ],
        1727989260: [
            0.21129,
            0,
            0.21129,
            0,
            47.91345,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            187.6031,
            0,
            0.99118,
            0,
            26.25685,
            0,
            393.8097,
            0,
            0,
            0,
            29.27629,
            0,
            14.14366,
            "<D",
            110.828,
            0,
        ],
        1727989320: [
            0.18618,
            0,
            0.18618,
            0,
            48.87845,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            201.1547,
            0,
            1.30213,
            0,
            26.1262,
            0,
            386.9338,
            0,
            0,
            0,
            29.27433,
            0,
            11.68246,
            "<D",
            110.9455,
            0,
        ],
        1727989380: [
            0.24567,
            0,
            0.24567,
            0,
            48.98035,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            207.1021,
            0,
            1.29116,
            0,
            25.95649,
            0,
            421.7506,
            0,
            0,
            0,
            29.27391,
            0,
            13.36248,
            "<D",
            110.5242,
            0,
        ],
        1727989440: [
            0.20659,
            0,
            0.20659,
            0,
            48.95443,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            189.6376,
            0,
            1.3205,
            0,
            26.30397,
            0,
            418.9375,
            0,
            0,
            0,
            29.27391,
            0,
            14.62518,
            "<D",
            111.2452,
            0,
        ],
        1727989500: [
            0.11823,
            0,
            0.11823,
            0,
            48.64208,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            183.3139,
            0,
            2.17356,
            0,
            26.26838,
            0,
            417.6553,
            0,
            0,
            0,
            29.27366,
            0,
            13.82117,
            "<D",
            110.5524,
            0,
        ],
        1727989560: [
            0.23594,
            0,
            0.23594,
            0,
            48.25958,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            210.5616,
            0,
            1.82064,
            0,
            26.35573,
            0,
            416.0853,
            0,
            0,
            0,
            29.27375,
            0,
            12.9005,
            "<D",
            109.8785,
            0,
        ],
        1727989620: [
            0.13521,
            0,
            0.13521,
            0,
            49.59596,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            225.8808,
            0,
            1.33023,
            0,
            26.20614,
            0,
            414.6086,
            0,
            0,
            0,
            29.27325,
            0,
            13.54749,
            "<D",
            112.5019,
            0,
        ],
        1727989680: [
            0.10808,
            0,
            0.10808,
            0,
            49.71782,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            219.4104,
            0,
            0.8598,
            0,
            26.36044,
            0,
            412.5804,
            0,
            0,
            0,
            29.27309,
            0,
            14.29728,
            "<D",
            109.8798,
            0,
        ],
        1727989740: [
            0.14138,
            0,
            0.14138,
            0,
            49.21786,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            192.7323,
            0,
            1.00899,
            0,
            26.48527,
            0,
            396.7328,
            0,
            0,
            0,
            29.27308,
            0,
            13.02877,
            "<D",
            110.1201,
            0,
        ],
        1727989800: [
            0.22496,
            0,
            0.22496,
            0,
            48.93546,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            198.957,
            0,
            1.18798,
            0,
            26.38405,
            0,
            402.0698,
            0,
            0,
            0,
            29.27322,
            0,
            13.32962,
            "<D",
            111.1478,
            0,
        ],
        1727989860: [
            0.25834,
            0,
            0.25834,
            0,
            49.1689,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            212.1919,
            0,
            1.36136,
            0,
            26.21818,
            0,
            364.3177,
            0,
            0,
            0,
            29.27128,
            0,
            13.72525,
            "<D",
            111.1569,
            0,
        ],
        1727989920: [
            0.24957,
            0,
            0.24957,
            0,
            48.42493,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            223.6575,
            0,
            1.32268,
            0,
            26.21054,
            0,
            352.5747,
            0,
            0,
            0,
            29.27335,
            0,
            13.4969,
            "<D",
            110.235,
            0,
        ],
        1727989980: [
            0.13778,
            0,
            0.13778,
            0,
            49.07076,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            214.7767,
            0,
            0.91971,
            0,
            26.30638,
            0,
            366.119,
            0,
            0,
            0,
            29.27331,
            0,
            12.74136,
            "<D",
            110.2714,
            0,
        ],
        1727990040: [
            0.12659,
            0,
            0.12659,
            0,
            49.16173,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            200.0693,
            0,
            1.4433,
            0,
            26.29784,
            0,
            348.2535,
            0,
            0,
            0,
            29.27381,
            0,
            14.15571,
            "<D",
            110.4701,
            0,
        ],
        1727990100: [
            0.48447,
            0,
            0.48447,
            0,
            48.75889,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            224.5957,
            0,
            1.23016,
            0,
            26.151,
            0,
            353.664,
            0,
            0,
            0,
            29.27367,
            0,
            13.52494,
            "<D",
            109.7104,
            0,
        ],
        1727990160: [
            0.2378,
            0,
            0.2378,
            0,
            49.24646,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            246.803,
            0,
            1.53239,
            0,
            26.05105,
            0,
            349.9319,
            0,
            0,
            0,
            29.2737,
            0,
            13.57918,
            "<D",
            108.4662,
            0,
        ],
        1727990220: [
            0.29834,
            0,
            0.29834,
            0,
            49.3064,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            215.9043,
            0,
            1.07903,
            0,
            26.18283,
            0,
            380.5872,
            0,
            0,
            0,
            29.27378,
            0,
            11.05318,
            "<D",
            109.108,
            0,
        ],
        1727990280: [
            0.22225,
            0,
            0.22225,
            0,
            49.22392,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            229.8141,
            0,
            1.43078,
            0,
            26.2375,
            0,
            372.682,
            0,
            0,
            0,
            29.2737,
            0,
            13.84025,
            "<D",
            111.5702,
            0,
        ],
        1727990340: [
            0.19232,
            0,
            0.19232,
            0,
            49.03239,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            245.6456,
            0,
            1.27835,
            0,
            26.24564,
            0,
            308.174,
            0,
            0,
            0,
            29.27382,
            0,
            13.25524,
            "<D",
            109.478,
            0,
        ],
        1727990400: [
            0.28328,
            0,
            0.28328,
            0,
            48.21096,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            217.4115,
            0,
            1.19341,
            0,
            26.21679,
            0,
            338.1029,
            0,
            0,
            0,
            29.27378,
            0,
            13.47467,
            "<D",
            109.7817,
            0,
        ],
        1727990460: [
            0.27112,
            0,
            0.27112,
            0,
            47.90406,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            212.8067,
            0,
            1.35751,
            0,
            26.1118,
            0,
            258.4884,
            0,
            0,
            0,
            29.27379,
            0,
            13.38461,
            "<D",
            109.7818,
            0,
        ],
        1727990520: [
            0.16923,
            0,
            0.16923,
            0,
            47.82461,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            216.6259,
            0,
            1.8343,
            0,
            25.88972,
            0,
            159.903,
            0,
            0,
            0,
            29.27371,
            0,
            13.71633,
            "<D",
            109.6234,
            0,
        ],
        1727990580: [
            0.34865,
            0,
            0.34865,
            0,
            48.40882,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            235.3772,
            0,
            1.09796,
            0,
            25.89511,
            0,
            202.8517,
            0,
            0,
            0,
            29.27377,
            0,
            12.87131,
            "<D",
            109.7275,
            0,
        ],
        1727990640: [
            0.13946,
            0,
            0.13946,
            0,
            48.9085,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            253.0614,
            0,
            1.02627,
            0,
            25.91259,
            0,
            255.5913,
            0,
            0,
            0,
            29.2732,
            0,
            13.0356,
            "<D",
            109.1751,
            0,
        ],
        1727990700: [
            0.21264,
            0,
            0.21264,
            0,
            49.5949,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            242.6253,
            0,
            1.2075,
            0,
            25.96253,
            0,
            300.0572,
            0,
            0,
            0,
            29.27328,
            0,
            11.70585,
            "<D",
            109.2257,
            0,
        ],
        1727990760: [
            0.24702,
            0,
            0.24702,
            0,
            49.3885,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            227.1369,
            0,
            0.91008,
            0,
            26.09635,
            0,
            308.5124,
            0,
            0,
            0,
            29.27317,
            0,
            13.31244,
            "<D",
            109.7956,
            0,
        ],
        1727990820: [
            0.5104,
            0,
            0.5104,
            0,
            48.86613,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            242.5796,
            0,
            0.76943,
            0,
            25.8984,
            0,
            327.9515,
            0,
            0,
            0,
            29.27329,
            0,
            14.27165,
            "<D",
            108.7834,
            0,
        ],
        1727990880: [
            0.20826,
            0,
            0.20826,
            0,
            48.10804,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            230.794,
            0,
            1.04323,
            0,
            25.89635,
            0,
            317.7733,
            0,
            0,
            0,
            29.27425,
            0,
            11.09879,
            "<D",
            109.4661,
            0,
        ],
        1727990940: [
            0.16392,
            0,
            0.16392,
            0,
            47.95256,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            208.0444,
            0,
            0.94085,
            0,
            26.0756,
            0,
            324.5914,
            0,
            0,
            0,
            29.27508,
            0,
            9.83533,
            "<D",
            112.5601,
            0,
        ],
        1727991000: [
            0.40605,
            0,
            0.40605,
            0,
            48.12798,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            240.0453,
            0,
            0.6114,
            0,
            26.46341,
            0,
            319.3132,
            0,
            0,
            0,
            29.27464,
            0,
            10.07642,
            "<D",
            110.4172,
            0,
        ],
        1727991060: [
            0.40621,
            0,
            0.40621,
            0,
            47.41375,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            223.8672,
            0,
            0.6165,
            0,
            26.28791,
            0,
            318.5507,
            0,
            0,
            0,
            29.2744,
            0,
            13.77277,
            "<D",
            109.536,
            0,
        ],
        1727991120: [
            0.34143,
            0,
            0.34143,
            0,
            47.44239,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            268.9783,
            0,
            0.8875,
            0,
            26.36904,
            0,
            291.1912,
            0,
            0,
            0,
            29.27506,
            0,
            13.67902,
            "<D",
            110.0323,
            0,
        ],
        1727991180: [
            0.19531,
            0,
            0.19531,
            0,
            47.93742,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            227.5157,
            0,
            0.9957,
            0,
            26.55861,
            0,
            263.0812,
            0,
            0,
            0,
            29.27586,
            0,
            10.05175,
            "<D",
            110.3645,
            0,
        ],
        1727991240: [
            0.14264,
            0,
            0.14264,
            0,
            47.97491,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            223.0986,
            0,
            1.39798,
            0,
            26.39625,
            0,
            296.2341,
            0,
            0,
            0,
            29.27506,
            0,
            16.96543,
            "<D",
            110.3199,
            0,
        ],
        1727991300: [
            0.14768,
            0,
            0.14768,
            0,
            48.88665,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            213.0996,
            0,
            1.2593,
            0,
            25.97001,
            0,
            320.4446,
            0,
            0,
            0,
            29.27349,
            0,
            13.54072,
            "<D",
            110.0152,
            0,
        ],
        1727991360: [
            0.4135,
            0,
            0.4135,
            0,
            50.06739,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            231.4,
            0,
            1.64803,
            0,
            26.06915,
            0,
            326.5428,
            0,
            0,
            0,
            29.27339,
            0,
            12.28937,
            "<D",
            110.1916,
            0,
        ],
        1727991420: [
            0.31989,
            0,
            0.31989,
            0,
            50.21531,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            214.03,
            0,
            1.34509,
            0,
            26.15327,
            0,
            328.3943,
            0,
            0,
            0,
            29.27459,
            0,
            11.81784,
            "<D",
            109.9217,
            0,
        ],
        1727991480: [
            0.19944,
            0,
            0.19944,
            0,
            49.52477,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            236.1704,
            0,
            0.5757,
            0,
            26.29717,
            0,
            290.6583,
            0,
            0,
            0,
            29.27402,
            0,
            15.06041,
            "<D",
            108.7707,
            0,
        ],
        1727991540: [
            0.23823,
            0,
            0.23823,
            0,
            48.96516,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            206.1087,
            0,
            1.04051,
            0,
            26.19249,
            0,
            302.6335,
            0,
            0,
            0,
            29.2746,
            0,
            13.05524,
            "<D",
            109.5779,
            0,
        ],
        1727991600: [
            0.2664,
            0,
            0.2664,
            0,
            48.85003,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            217.5558,
            0,
            1.37535,
            0,
            25.8814,
            0,
            287.7402,
            0,
            0,
            0,
            29.27481,
            0,
            15.10054,
            "<D",
            112.1137,
            0,
        ],
        1727991660: [
            0.30995,
            0,
            0.30995,
            0,
            49.66652,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            208.2463,
            0,
            1.18239,
            0,
            26.00225,
            0,
            282.2872,
            0,
            0,
            0,
            29.27445,
            0,
            13.14697,
            "<D",
            109.8013,
            0,
        ],
        1727991720: [
            0.35814,
            0,
            0.35814,
            0,
            49.65638,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            215.0341,
            0,
            0.88171,
            0,
            26.07981,
            0,
            305.1492,
            0,
            0,
            0,
            29.27375,
            0,
            11.41182,
            "<D",
            109.0544,
            0,
        ],
        1727991780: [
            0.24965,
            0,
            0.24965,
            0,
            49.13891,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            203.3753,
            0,
            1.74942,
            0,
            26.00833,
            0,
            304.5254,
            0,
            0,
            0,
            29.27364,
            0,
            13.58195,
            "<D",
            108.8094,
            0,
        ],
        1727991840: [
            0.21701,
            0,
            0.21701,
            0,
            49.73272,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            205.1454,
            0,
            0.98802,
            0,
            26.00057,
            0,
            304.429,
            0,
            0,
            0,
            29.2751,
            0,
            15.47512,
            "<D",
            108.8686,
            0,
        ],
        1727991900: [
            0.14348,
            0,
            0.14348,
            0,
            49.71775,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            217.6824,
            0,
            0.83481,
            0,
            25.83916,
            0,
            299.7372,
            0,
            0,
            0,
            29.27534,
            0,
            14.01059,
            "<D",
            108.9845,
            0,
        ],
        1727991960: [
            0.16823,
            0,
            0.16823,
            0,
            49.63728,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            240.7993,
            0,
            1.21681,
            0,
            25.87191,
            0,
            295.2413,
            0,
            0,
            0,
            29.27648,
            0,
            15.36419,
            "<D",
            109.3193,
            0,
        ],
        1727992020: [
            0.00795,
            0,
            0.00795,
            0,
            49.11107,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            222.4942,
            0,
            0.90066,
            0,
            25.91794,
            0,
            291.3818,
            0,
            0,
            0,
            29.27637,
            0,
            12.17631,
            "<D",
            109.2494,
            0,
        ],
        1727992080: [
            0.04414,
            0,
            0.04414,
            0,
            48.48625,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            232.464,
            0,
            1.28047,
            0,
            25.9267,
            0,
            286.8592,
            0,
            0,
            0,
            29.27638,
            0,
            12.52015,
            "<D",
            108.9825,
            0,
        ],
        1727992140: [
            0.38896,
            0,
            0.38896,
            0,
            48.88931,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            209.8365,
            0,
            1.34553,
            0,
            26.07573,
            0,
            282.0164,
            0,
            0,
            0,
            29.27661,
            0,
            16.71695,
            "<D",
            108.8074,
            0,
        ],
        1727992200: [
            0.29186,
            0,
            0.29186,
            0,
            48.23371,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            229.5251,
            0,
            1.53084,
            0,
            25.8922,
            0,
            278.2143,
            0,
            0,
            0,
            29.27641,
            0,
            13.6581,
            "<D",
            109.0982,
            0,
        ],
        1727992260: [
            0.25448,
            0,
            0.25448,
            0,
            48.6194,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            217.4533,
            0,
            1.51063,
            0,
            25.95599,
            0,
            273.6522,
            0,
            0,
            0,
            29.27693,
            0,
            16.35708,
            "<D",
            109.2013,
            0,
        ],
        1727992320: [
            0.27967,
            0,
            0.27967,
            0,
            49.54877,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            187.1342,
            0,
            1.26284,
            0,
            25.98886,
            0,
            269.6108,
            0,
            0,
            0,
            29.27745,
            0,
            12.58446,
            "<D",
            112.866,
            0,
        ],
        1727992380: [
            0.20849,
            0,
            0.20849,
            0,
            49.35017,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            213.4091,
            0,
            1.54322,
            0,
            25.82579,
            0,
            264.4885,
            0,
            0,
            0,
            29.27704,
            0,
            15.69116,
            "<D",
            108.1738,
            0,
        ],
        1727992440: [
            0.34084,
            0,
            0.34084,
            0,
            49.51126,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            222.1282,
            0,
            1.28163,
            0,
            25.77224,
            0,
            259.1161,
            0,
            0,
            0,
            29.27774,
            0,
            15.83856,
            "<D",
            108.347,
            0,
        ],
        1727992500: [
            0.20433,
            0,
            0.20433,
            0,
            48.79615,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            215.5983,
            0,
            1.34797,
            0,
            25.71696,
            0,
            243.5375,
            0,
            0,
            0,
            29.27822,
            0,
            16.10596,
            "<D",
            108.181,
            0,
        ],
        1727992560: [
            0.25927,
            0,
            0.25927,
            0,
            48.58079,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            209.0663,
            0,
            0.91873,
            0,
            25.5985,
            0,
            247.0982,
            0,
            0,
            0,
            29.27888,
            0,
            14.26131,
            "<D",
            109.1595,
            0,
        ],
        1727992620: [
            0.19734,
            0,
            0.19734,
            0,
            48.51545,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            219.7062,
            0,
            0.95935,
            0,
            25.54247,
            0,
            239.2698,
            0,
            0,
            0,
            29.27931,
            0,
            14.12315,
            "<D",
            109.3551,
            0,
        ],
        1727992680: [
            0.29162,
            0,
            0.29162,
            0,
            49.34224,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            213.1148,
            0,
            1.06331,
            0,
            25.5897,
            0,
            237.1002,
            0,
            0,
            0,
            29.27962,
            0,
            13.46335,
            "<D",
            108.6065,
            0,
        ],
        1727992740: [
            0.13186,
            0,
            0.13186,
            0,
            48.70588,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            221.3474,
            0,
            0.90805,
            0,
            25.56909,
            0,
            228.2246,
            0,
            0,
            0,
            29.27912,
            0,
            13.93995,
            "<D",
            108.6779,
            0,
        ],
        1727992800: [
            0.15268,
            0,
            0.15268,
            0,
            48.86626,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            215.8391,
            0,
            1.13799,
            0,
            25.57446,
            0,
            224.9639,
            0,
            0,
            0,
            29.28029,
            0,
            13.33524,
            "<D",
            109.0274,
            0,
        ],
        1727992860: [
            0.06818,
            0,
            0.06818,
            0,
            48.87859,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            213.5504,
            0,
            1.09877,
            0,
            25.68445,
            0,
            229.0644,
            0,
            0,
            0,
            29.28034,
            0,
            13.73486,
            "<D",
            107.4703,
            0,
        ],
        1727992920: [
            0.03475,
            0,
            0.03475,
            0,
            49.72185,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            180.0934,
            0,
            1.24057,
            0,
            25.70229,
            0,
            224.9363,
            0,
            0,
            0,
            29.28034,
            0,
            13.40498,
            "<D",
            107.2932,
            0,
        ],
        1727992980: [
            0.17149,
            0,
            0.17149,
            0,
            48.49188,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            190.5387,
            0,
            1.23692,
            0,
            25.56179,
            0,
            222.6228,
            0,
            0,
            0,
            29.28034,
            0,
            15.07063,
            "<D",
            110.4877,
            0,
        ],
        1727993040: [
            0.22655,
            0,
            0.22655,
            0,
            48.70114,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            201.4868,
            0,
            1.49467,
            0,
            25.43415,
            0,
            217.9408,
            0,
            0,
            0,
            29.28034,
            0,
            14.97635,
            "<D",
            110.6665,
            0,
        ],
        1727993100: [
            0.07186,
            0,
            0.07186,
            0,
            47.79665,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            211.5642,
            0,
            1.23829,
            0,
            25.40861,
            0,
            224.9395,
            0,
            0,
            0,
            29.28029,
            0,
            13.95349,
            "<D",
            108.4594,
            0,
        ],
        1727993160: [
            0.15371,
            0,
            0.15371,
            0,
            48.36077,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            209.1149,
            0,
            0.95935,
            0,
            25.35597,
            0,
            201.0495,
            0,
            0,
            0,
            29.2803,
            0,
            15.95083,
            "<D",
            108.9842,
            0,
        ],
        1727993220: [
            0.24419,
            0,
            0.24419,
            0,
            48.82951,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            210.2259,
            0,
            0.75811,
            0,
            25.25604,
            0,
            156.321,
            0,
            0,
            0,
            29.28018,
            0,
            12.62359,
            "<D",
            110.4956,
            0,
        ],
        1727993280: [
            0.10261,
            0,
            0.10261,
            0,
            48.65052,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            197.5741,
            0,
            1.29857,
            0,
            25.28561,
            0,
            121.6397,
            0,
            0,
            0,
            29.28007,
            0,
            14.97644,
            "<D",
            110.39,
            0,
        ],
        1727993340: [
            0.30598,
            0,
            0.30598,
            0,
            48.15954,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            180.9385,
            0,
            1.25727,
            0,
            25.26159,
            0,
            102.5139,
            0,
            0,
            0,
            29.28014,
            0,
            12.91584,
            "<D",
            109.2381,
            0,
        ],
        1727993400: [
            0.28789,
            0,
            0.28789,
            0,
            48.60622,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            181.0727,
            0,
            1.27782,
            0,
            25.32357,
            0,
            82.7757,
            0,
            0,
            0,
            29.28015,
            0,
            13.57369,
            "<D",
            109.2453,
            0,
        ],
        1727993460: [
            0.29532,
            0,
            0.29532,
            0,
            48.5652,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            190.0383,
            0,
            1.18854,
            0,
            25.28325,
            0,
            77.27659,
            0,
            0,
            0,
            29.28003,
            0,
            11.60526,
            "<D",
            110.5118,
            0,
        ],
        1727993520: [
            0.42102,
            0,
            0.42102,
            0,
            48.85352,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            192.2788,
            0,
            1.37866,
            0,
            25.17874,
            0,
            72.84194,
            0,
            0,
            0,
            29.28004,
            0,
            15.42083,
            "<D",
            110.3047,
            0,
        ],
        1727993580: [
            0.38755,
            0,
            0.38755,
            0,
            49.21363,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            187.6023,
            0,
            0.98463,
            0,
            25.27851,
            0,
            71.74393,
            0,
            0,
            0,
            29.2799,
            0,
            13.12198,
            "<D",
            110.1342,
            0,
        ],
        1727993640: [
            0.24036,
            0,
            0.24036,
            0,
            48.77425,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            191.6364,
            0,
            1.24571,
            0,
            25.29335,
            0,
            76.5486,
            0,
            0,
            0,
            29.28045,
            0,
            14.34652,
            "<D",
            112.701,
            0,
        ],
        1727993700: [
            0.24681,
            0,
            0.24681,
            0,
            48.04833,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            215.896,
            0,
            1.08291,
            0,
            25.2686,
            0,
            77.33986,
            0,
            0,
            0,
            29.28033,
            0,
            14.99853,
            "<D",
            110.0666,
            0,
        ],
        1727993760: [
            0.20628,
            0,
            0.20628,
            0,
            48.9427,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            196.9253,
            0,
            1.16117,
            0,
            25.2746,
            0,
            77.09972,
            0,
            0,
            0,
            29.28022,
            0,
            10.32066,
            "<D",
            110.2912,
            0,
        ],
        1727993820: [
            0.14851,
            0,
            0.14851,
            0,
            48.64096,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            186.1492,
            0,
            1.13292,
            0,
            25.16746,
            0,
            83.28635,
            0,
            0,
            0,
            29.28003,
            0,
            15.8144,
            "<D",
            111.0912,
            0,
        ],
        1727993880: [
            0.18565,
            0,
            0.18565,
            0,
            48.40332,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            191.0092,
            0,
            1.30139,
            0,
            24.98136,
            0,
            72.86092,
            0,
            0,
            0,
            29.28001,
            0,
            12.13838,
            "<D",
            112.052,
            0,
        ],
        1727993940: [
            0.1529,
            0,
            0.1529,
            0,
            47.98772,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            183.6937,
            0,
            0.9944,
            0,
            24.92884,
            0,
            63.715,
            0,
            0,
            0,
            29.28032,
            0,
            15.39312,
            "<D",
            110.0129,
            0,
        ],
        1727994000: [
            0.50709,
            0,
            0.50709,
            0,
            48.38401,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            203.6419,
            0,
            1.04765,
            0,
            24.92915,
            0,
            59.5551,
            0,
            0,
            0,
            29.28041,
            0,
            13.30698,
            "<D",
            108.78,
            0,
        ],
        1727994060: [
            0.0624,
            0,
            0.0624,
            0,
            48.29433,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            194.5704,
            0,
            1.14668,
            0,
            25.07758,
            0,
            57.43477,
            0,
            0,
            0,
            29.27999,
            0,
            14.71386,
            "<D",
            109.7448,
            0,
        ],
        1727994120: [
            0.18086,
            0,
            0.18086,
            0,
            47.73569,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            197.1543,
            0,
            0.86608,
            0,
            25.18589,
            0,
            57.15504,
            0,
            0,
            0,
            29.28085,
            0,
            13.43951,
            "<D",
            109.9093,
            0,
        ],
        1727994180: [
            0.11507,
            0,
            0.11507,
            0,
            48.12011,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            193.8076,
            0,
            1.10722,
            0,
            25.04347,
            0,
            59.32537,
            0,
            0,
            0,
            29.28056,
            0,
            14.42289,
            "<D",
            110.3861,
            0,
        ],
        1727994240: [
            0.16853,
            0,
            0.16853,
            0,
            48.52841,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            192.8529,
            0,
            0.93873,
            0,
            25.20853,
            0,
            60.89354,
            0,
            0,
            0,
            29.28288,
            0,
            14.22941,
            "<D",
            110.358,
            0,
        ],
        1727994300: [
            0.26163,
            0,
            0.26163,
            0,
            46.90883,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            180.5416,
            0,
            0.96408,
            0,
            24.97577,
            0,
            59.35562,
            0,
            0,
            0,
            29.28182,
            0,
            16.34191,
            "<D",
            110.4367,
            0,
        ],
        1727994360: [
            0.07033,
            0,
            0.07033,
            0,
            46.98314,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            194.109,
            0,
            0.93572,
            0,
            24.83755,
            0,
            61.15033,
            0,
            0,
            0,
            29.2816,
            0,
            11.25759,
            "<D",
            110.4732,
            0,
        ],
        1727994420: [
            0.1341,
            0,
            0.1341,
            0,
            46.5901,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            161.6501,
            0,
            0.91154,
            0,
            24.74114,
            0,
            61.13041,
            0,
            0,
            0,
            29.28296,
            0,
            13.07252,
            "<D",
            111.2612,
            0,
        ],
        1727994480: [
            0.07662,
            0,
            0.07662,
            0,
            45.8429,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            177.0774,
            0,
            0.67756,
            0,
            24.7183,
            0,
            51.93708,
            0,
            0,
            0,
            29.28202,
            0,
            12.11092,
            "<D",
            111.2258,
            0,
        ],
        1727994540: [
            0.25495,
            0,
            0.25495,
            0,
            45.36981,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            175.2128,
            0,
            0.44727,
            0,
            24.67449,
            0,
            49.43084,
            0,
            0,
            0,
            29.28374,
            0,
            10.61017,
            "<D",
            111.5777,
            0,
        ],
        1727994600: [
            0.19674,
            0,
            0.19674,
            0,
            45.40025,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            186.5514,
            0,
            0.57803,
            0,
            24.56127,
            0,
            48.6201,
            0,
            0,
            0,
            29.28414,
            0,
            15.66887,
            "<D",
            110.4496,
            0,
        ],
        1727994660: [
            0.30143,
            0,
            0.30143,
            0,
            44.22164,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            210.9798,
            0,
            0.61232,
            0,
            24.65149,
            0,
            48.00837,
            0,
            0,
            0,
            29.28377,
            0,
            12.77576,
            "<D",
            110.2984,
            0,
        ],
        1727994720: [
            0.1364,
            0,
            0.1364,
            0,
            44.81634,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            170.3679,
            0,
            0.53422,
            0,
            24.67303,
            0,
            47.59356,
            0,
            0,
            0,
            29.28364,
            0,
            14.53236,
            "<D",
            110.5464,
            0,
        ],
        1727994780: [
            0.02195,
            0,
            0.02195,
            0,
            44.73995,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            161.2626,
            0,
            0.46945,
            0,
            24.58591,
            0,
            47.35524,
            0,
            0,
            0,
            29.2837,
            0,
            14.24839,
            "<D",
            109.6123,
            0,
        ],
        1727994840: [
            0.10376,
            0,
            0.10376,
            0,
            44.35148,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            158.0938,
            0,
            0.55285,
            0,
            24.42095,
            0,
            46.78463,
            0,
            0,
            0,
            29.28365,
            0,
            16.87344,
            "<D",
            108.1063,
            0,
        ],
        1727994900: [
            0.4026,
            0,
            0.4026,
            0,
            44.62729,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            186.0579,
            0,
            0.43687,
            0,
            24.45885,
            0,
            46.7172,
            0,
            0,
            0,
            29.28377,
            0,
            13.57976,
            "<D",
            108.448,
            0,
        ],
        1727994960: [
            0.14264,
            0,
            0.14264,
            0,
            45.95195,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            153.1698,
            0,
            0.72359,
            0,
            24.47168,
            0,
            46.32006,
            0,
            0,
            0,
            29.28382,
            0,
            15.55012,
            "<D",
            111.1457,
            0,
        ],
        1727995020: [
            0.19599,
            0,
            0.19599,
            0,
            45.8306,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            182.4596,
            0,
            0.40642,
            0,
            24.59259,
            0,
            47.67117,
            0,
            0,
            0,
            29.28371,
            0,
            12.46343,
            "<D",
            109.9356,
            0,
        ],
        1727995080: [
            0.15535,
            0,
            0.15535,
            0,
            46.10195,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            151.4019,
            0,
            0.50998,
            0,
            24.33107,
            0,
            47.55605,
            0,
            0,
            0,
            29.28364,
            0,
            14.41099,
            "<D",
            110.1427,
            0,
        ],
        1727995140: [
            0.39894,
            0,
            0.39894,
            0,
            45.65154,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            173.4977,
            0,
            0.4494,
            0,
            24.15987,
            0,
            50.80118,
            0,
            0,
            0,
            29.28365,
            0,
            13.28726,
            "<D",
            110.4019,
            0,
        ],
        1727995200: [
            0.12254,
            0,
            0.12254,
            0,
            45.55498,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            144.2304,
            0,
            0.48494,
            0,
            24.35775,
            0,
            59.26939,
            0,
            0,
            0,
            29.28361,
            0,
            13.024,
            "<D",
            110.8821,
            0,
        ],
        1727995260: [
            0.09057,
            0,
            0.09057,
            0,
            46.19352,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            144.794,
            0,
            0.42541,
            0,
            24.44427,
            0,
            53.13128,
            0,
            0,
            0,
            29.28352,
            0,
            12.9379,
            "<D",
            111.3952,
            0,
        ],
        1727995320: [
            0.07451,
            0,
            0.07451,
            0,
            46.06831,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            179.8424,
            0,
            0.49622,
            0,
            24.37123,
            0,
            44.71038,
            0,
            0,
            0,
            29.28359,
            0,
            16.54279,
            "<D",
            109.2278,
            0,
        ],
        1727995380: [
            0.15172,
            0,
            0.15172,
            0,
            46.29919,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            163.7386,
            0,
            0.42416,
            0,
            24.39209,
            0,
            43.75843,
            0,
            0,
            0,
            29.28359,
            0,
            15.198,
            "<D",
            109.1372,
            0,
        ],
        1727995440: [
            -0.0016,
            0,
            -0.0016,
            0,
            45.45739,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            145.2088,
            0,
            0.39657,
            0,
            24.50575,
            0,
            43.44452,
            0,
            0,
            0,
            29.28359,
            0,
            14.64341,
            "<D",
            108.0494,
            0,
        ],
        1727995500: [
            0.1767,
            0,
            0.1767,
            0,
            45.64213,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            138.2961,
            0,
            0.39084,
            0,
            24.49761,
            0,
            43.36771,
            0,
            0,
            0,
            29.28359,
            0,
            13.95241,
            "<D",
            109.9073,
            0,
        ],
        1727995560: [
            0.13212,
            0,
            0.13212,
            0,
            46.98341,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            147.7777,
            0,
            0.52135,
            0,
            24.31863,
            0,
            43.07385,
            0,
            0,
            0,
            29.28359,
            0,
            13.4422,
            "<D",
            109.7057,
            0,
        ],
        1727995620: [
            0.24842,
            0,
            0.24842,
            0,
            46.60045,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            125.0286,
            0,
            0.46503,
            0,
            24.31528,
            0,
            42.65981,
            0,
            0,
            0,
            29.28358,
            0,
            13.12059,
            "<D",
            111.762,
            0,
        ],
        1727995680: [
            0.29302,
            0,
            0.29302,
            0,
            45.75792,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            135.5881,
            0,
            0.52943,
            0,
            24.3451,
            0,
            42.12895,
            0,
            0,
            0,
            29.28359,
            0,
            14.17389,
            "<D",
            113.6527,
            0,
        ],
        1727995740: [
            0.23222,
            0,
            0.23222,
            0,
            46.35814,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            143.6974,
            0,
            0.57705,
            0,
            24.2051,
            0,
            41.74503,
            0,
            0,
            0,
            29.2836,
            0,
            14.97929,
            "<D",
            114.9219,
            0,
        ],
        1727995800: [
            0.39098,
            0,
            0.39098,
            0,
            45.99461,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            146.1791,
            0,
            0.71954,
            0,
            24.00079,
            0,
            42.29924,
            0,
            0,
            0,
            29.28362,
            0,
            14.41241,
            "<D",
            113.6157,
            0,
        ],
        1727995860: [
            0.25722,
            0,
            0.25722,
            0,
            46.93359,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            147.2455,
            0,
            0.78936,
            0,
            23.86571,
            0,
            43.06717,
            0,
            0,
            0,
            29.28364,
            0,
            10.00376,
            "<D",
            113.5147,
            0,
        ],
        1727995920: [
            0.23906,
            0,
            0.23906,
            0,
            46.84777,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            148.9737,
            0,
            0.85208,
            0,
            23.87913,
            0,
            41.48456,
            0,
            0,
            0,
            29.28369,
            0,
            15.08157,
            "<D",
            113.9207,
            0,
        ],
        1727995980: [
            0.30157,
            0,
            0.30157,
            0,
            47.05576,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            116.8493,
            0,
            0.55778,
            0,
            23.84519,
            0,
            41.51134,
            0,
            0,
            0,
            29.28374,
            0,
            13.64738,
            "<D",
            113.0889,
            0,
        ],
        1727996040: [
            0.24293,
            0,
            0.24293,
            0,
            46.06643,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            127.682,
            0,
            0.62418,
            0,
            23.65106,
            0,
            44.13917,
            0,
            0,
            0,
            29.28379,
            0,
            12.21456,
            "<D",
            114.4868,
            0,
        ],
        1727996100: [
            0.2762,
            0,
            0.2762,
            0,
            45.3708,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            139.6478,
            0,
            0.73017,
            0,
            23.52524,
            0,
            49.60878,
            0,
            0,
            0,
            29.28369,
            0,
            16.73348,
            "<D",
            113.7856,
            0,
        ],
        1727996160: [
            0.32857,
            0,
            0.32857,
            0,
            45.87671,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            131.3564,
            0,
            0.68245,
            0,
            23.55746,
            0,
            53.99535,
            0,
            0,
            0,
            29.28357,
            0,
            13.12226,
            "<D",
            113.7398,
            0,
        ],
        1727996220: [
            0.14599,
            0,
            0.14599,
            0,
            46.122,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            125.046,
            0,
            0.59844,
            0,
            23.53177,
            0,
            55.16586,
            0,
            0,
            0,
            29.2837,
            0,
            13.53984,
            "<D",
            113.1425,
            0,
        ],
        1727996280: [
            0.2989,
            0,
            0.2989,
            0,
            45.09748,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            133.0861,
            0,
            0.74687,
            0,
            23.50492,
            0,
            54.4343,
            0,
            0,
            0,
            29.28369,
            0,
            13.65463,
            "<D",
            113.1591,
            0,
        ],
        1727996340: [
            0.26527,
            0,
            0.26527,
            0,
            45.79105,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            129.0988,
            0,
            0.66855,
            0,
            23.49663,
            0,
            51.69134,
            0,
            0,
            0,
            29.2837,
            0,
            12.65325,
            "<D",
            114.0014,
            0,
        ],
        1727996400: [
            0.21054,
            0,
            0.21054,
            0,
            45.75523,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            107.5504,
            0,
            0.46123,
            0,
            23.51244,
            0,
            49.00959,
            0,
            0,
            0,
            29.28372,
            0,
            14.30825,
            "<D",
            114.4156,
            0,
        ],
        1727996460: [
            0.06193,
            0,
            0.06193,
            0,
            45.17608,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            118.4889,
            0,
            0.53152,
            0,
            23.5687,
            0,
            46.63942,
            0,
            0,
            0,
            29.28375,
            0,
            12.45812,
            "<D",
            114.3993,
            0,
        ],
        1727996520: [
            -0.02416,
            0,
            -0.02416,
            0,
            44.59796,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            112.9614,
            0,
            0.7285,
            0,
            23.57962,
            0,
            44.0872,
            0,
            0,
            0,
            29.28397,
            0,
            12.97692,
            "<D",
            114.7953,
            0,
        ],
        1727996580: [
            0.19668,
            0,
            0.19668,
            0,
            43.03593,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            92.42174,
            0,
            0.56839,
            0,
            23.54197,
            0,
            41.52989,
            0,
            0,
            0,
            29.28363,
            0,
            8.82865,
            "<D",
            114.411,
            0,
        ],
        1727996640: [
            0.17355,
            0,
            0.17355,
            0,
            42.45542,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            103.3686,
            0,
            0.59326,
            0,
            23.49393,
            0,
            39.16618,
            0,
            0,
            0,
            29.2837,
            0,
            12.23542,
            "<D",
            113.2722,
            0,
        ],
        1727996700: [
            0.18638,
            0,
            0.18638,
            0,
            42.03456,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            98.11302,
            0,
            0.65157,
            0,
            23.4391,
            0,
            36.66452,
            0,
            0,
            0,
            29.28401,
            0,
            13.1397,
            "<D",
            113.1302,
            0,
        ],
        1727996760: [
            0.1262,
            0,
            0.1262,
            0,
            42.37727,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            95.67665,
            0,
            0.5361,
            0,
            23.49455,
            0,
            32.00149,
            0,
            0,
            0,
            29.2839,
            0,
            12.24864,
            "<D",
            113.7198,
            0,
        ],
        1727996820: [
            0.25805,
            0,
            0.25805,
            0,
            41.86426,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            105.7011,
            0,
            0.58561,
            0,
            23.36483,
            0,
            26.24059,
            0,
            0,
            0,
            29.28369,
            0,
            11.30112,
            "<D",
            113.3726,
            0,
        ],
        1727996880: [
            0.15734,
            0,
            0.15734,
            0,
            41.85901,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            107.3762,
            0,
            0.76205,
            0,
            23.31881,
            0,
            23.53531,
            0,
            0,
            0,
            29.28404,
            0,
            14.58808,
            "<D",
            114.2051,
            0,
        ],
        1727996940: [
            0.23955,
            0,
            0.23955,
            0,
            43.2466,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            107.9197,
            0,
            0.68825,
            0,
            23.28081,
            0,
            23.22642,
            0,
            0,
            0,
            29.28406,
            0,
            10.50327,
            "<D",
            113.6933,
            0,
        ],
        1727997000: [
            0.23015,
            0,
            0.23015,
            0,
            43.23581,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            115.6825,
            0,
            0.67401,
            0,
            23.17849,
            0,
            22.17913,
            0,
            0,
            0,
            29.28406,
            0,
            12.91207,
            "<D",
            114.7599,
            0,
        ],
        1727997060: [
            0.31491,
            0,
            0.31491,
            0,
            42.31732,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            120.2553,
            0,
            0.62918,
            0,
            23.07661,
            0,
            19.42608,
            0,
            0,
            0,
            29.28395,
            0,
            13.27203,
            "<D",
            114.7082,
            0,
        ],
        1727997120: [
            0.22879,
            0,
            0.22879,
            0,
            41.40696,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            129.1006,
            0,
            0.61356,
            0,
            22.99958,
            0,
            16.47556,
            0,
            0,
            0,
            29.28369,
            0,
            11.18249,
            "<D",
            114.089,
            0,
        ],
        1727997180: [
            -0.04011,
            0,
            -0.04011,
            0,
            40.54986,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            136.9202,
            0,
            0.70071,
            0,
            22.944,
            0,
            14.34686,
            0,
            0,
            0,
            29.28373,
            0,
            11.13388,
            "<D",
            115.0104,
            0,
        ],
        1727997240: [
            0.1797,
            0,
            0.1797,
            0,
            40.00994,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            135.3005,
            0,
            0.74874,
            0,
            22.85167,
            0,
            12.46137,
            0,
            0,
            0,
            29.28364,
            0,
            8.32008,
            "<D",
            115.109,
            0,
        ],
        1727997300: [
            0.31611,
            0,
            0.31611,
            0,
            40.0518,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            133.0849,
            0,
            0.79626,
            0,
            22.66359,
            0,
            10.95819,
            0,
            0,
            0,
            29.28494,
            0,
            13.64398,
            "<D",
            114.6234,
            0,
        ],
        1727997360: [
            0.29562,
            0,
            0.29562,
            0,
            39.5788,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            131.5313,
            0,
            0.9612,
            0,
            22.53981,
            0,
            10.01259,
            0,
            0,
            0,
            29.28537,
            0,
            10.09786,
            "<D",
            113.9883,
            0,
        ],
        1727997420: [
            0.21784,
            0,
            0.21784,
            0,
            38.84853,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            130.3832,
            0,
            0.89465,
            0,
            22.50272,
            0,
            9.21952,
            0,
            0,
            0,
            29.28464,
            0,
            10.03117,
            "<D",
            113.4858,
            0,
        ],
        1727997480: [
            0.29296,
            0,
            0.29296,
            0,
            38.53811,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            129.281,
            0,
            1.00076,
            0,
            22.46049,
            0,
            8.41365,
            0,
            0,
            0,
            29.28691,
            0,
            8.47137,
            "<D",
            113.7227,
            0,
        ],
        1727997540: [
            0.09755,
            0,
            0.09755,
            0,
            39.27075,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            131.2105,
            0,
            1.01606,
            0,
            22.44834,
            0,
            7.6774,
            0,
            0,
            0,
            29.28706,
            0,
            12.15454,
            "<D",
            113.405,
            0,
        ],
        1727997600: [
            0.19927,
            0,
            0.19927,
            0,
            39.09174,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            131.7325,
            0,
            1.02595,
            0,
            22.44242,
            0,
            7.01085,
            0,
            0,
            0,
            29.28845,
            0,
            11.82266,
            "<D",
            113.0162,
            0,
        ],
        1727997660: [
            0.02867,
            0,
            0.02867,
            0,
            38.89771,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            132.1643,
            0,
            1.00752,
            0,
            22.38394,
            0,
            6.41077,
            0,
            0,
            0,
            29.28845,
            0,
            11.27069,
            "<D",
            114.807,
            0,
        ],
        1727997720: [
            0.10423,
            0,
            0.10423,
            0,
            38.48558,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            127.947,
            0,
            0.94866,
            0,
            22.25377,
            0,
            5.90018,
            0,
            0,
            0,
            29.28845,
            0,
            9.02719,
            "<D",
            115.6354,
            0,
        ],
        1727997780: [
            0.27773,
            0,
            0.27773,
            0,
            38.06223,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            130.9043,
            0,
            0.89656,
            0,
            22.1323,
            0,
            5.43296,
            0,
            0,
            0,
            29.28843,
            0,
            13.34815,
            "<D",
            115.6885,
            0,
        ],
        1727997840: [
            0.22447,
            0,
            0.22447,
            0,
            38.47827,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            138.0689,
            0,
            0.96062,
            0,
            22.0798,
            0,
            5.04856,
            0,
            0,
            0,
            29.28826,
            0,
            11.34771,
            "<D",
            115.6407,
            0,
        ],
        1727997900: [
            0.21695,
            0,
            0.21695,
            0,
            38.96067,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            127.2912,
            0,
            1.19068,
            0,
            22.09093,
            0,
            4.7039,
            0,
            0,
            0,
            29.28804,
            0,
            11.10198,
            "<D",
            115.1156,
            0,
        ],
        1727997960: [
            0.07536,
            0,
            0.07536,
            0,
            39.92714,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            131.5938,
            0,
            1.04341,
            0,
            22.04123,
            0,
            4.37581,
            0,
            0,
            0,
            29.28791,
            0,
            11.54034,
            "<D",
            114.5918,
            0,
        ],
        1727998020: [
            0.34688,
            0,
            0.34688,
            0,
            40.91566,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            141.3605,
            0,
            0.91452,
            0,
            21.98015,
            0,
            4.05765,
            0,
            0,
            0,
            29.28778,
            0,
            11.36083,
            "<D",
            114.6114,
            0,
        ],
        1727998080: [
            0.37752,
            0,
            0.37752,
            0,
            40.45933,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            134.6652,
            0,
            0.77418,
            0,
            21.96954,
            0,
            3.74952,
            0,
            0,
            0,
            29.28775,
            0,
            8.54816,
            "<D",
            114.4036,
            0,
        ],
        1727998140: [
            0.2814,
            0,
            0.2814,
            0,
            39.87236,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            131.1705,
            0,
            0.79317,
            0,
            21.94957,
            0,
            3.43141,
            0,
            0,
            0,
            29.28781,
            0,
            12.70943,
            "<D",
            114.9986,
            0,
        ],
        1727998200: [
            0.27058,
            0,
            0.27058,
            0,
            39.0138,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            122.7812,
            0,
            0.82536,
            0,
            21.91608,
            0,
            3.10006,
            0,
            0,
            0,
            29.28786,
            0,
            10.97118,
            "<D",
            115.2929,
            0,
        ],
        1727998260: [
            0.167,
            0,
            0.167,
            0,
            38.869,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            118.752,
            0,
            0.86426,
            0,
            21.80902,
            0,
            2.82839,
            0,
            0,
            0,
            29.2878,
            0,
            14.33345,
            "<D",
            115.678,
            0,
        ],
        1727998320: [
            0.17142,
            0,
            0.17142,
            0,
            38.41393,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            116.4991,
            0,
            0.82506,
            0,
            21.83857,
            0,
            2.51366,
            0,
            0,
            0,
            29.28781,
            0,
            10.74084,
            "<D",
            115.8575,
            0,
        ],
        1727998380: [
            0.20451,
            0,
            0.20451,
            0,
            38.33244,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            114.3631,
            0,
            0.6606,
            0,
            21.82197,
            0,
            2.10953,
            0,
            0,
            0,
            29.28788,
            0,
            12.80475,
            "<D",
            115.9276,
            0,
        ],
        1727998440: [
            0.12754,
            0,
            0.12754,
            0,
            38.01099,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            111.5283,
            0,
            0.69831,
            0,
            21.71345,
            0,
            1.61604,
            0,
            0,
            0,
            29.28782,
            0,
            11.4762,
            "<D",
            116.6312,
            0,
        ],
        1727998500: [
            0.37111,
            0,
            0.37111,
            0,
            37.79475,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            126.5922,
            0,
            0.79368,
            0,
            21.63947,
            0,
            1.13581,
            0,
            0,
            0,
            29.28786,
            0,
            14.64445,
            "<D",
            116.9042,
            0,
        ],
        1727998560: [
            0.24689,
            0,
            0.24689,
            0,
            37.66764,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            113.5068,
            0,
            0.79486,
            0,
            21.55218,
            0,
            0.79802,
            0,
            0,
            0,
            29.28789,
            0,
            11.06032,
            "<D",
            116.1007,
            0,
        ],
        1727998620: [
            0.14093,
            0,
            0.14093,
            0,
            37.83454,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            117.1227,
            0,
            0.69093,
            0,
            21.53819,
            0,
            0.70527,
            0,
            0,
            0,
            29.28885,
            0,
            9.7877,
            "<D",
            115.9771,
            0,
        ],
        1727998680: [
            0.328,
            0,
            0.328,
            0,
            38.5293,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            117.9022,
            0,
            0.79336,
            0,
            21.49891,
            0,
            0.5993,
            0,
            0,
            0,
            29.28853,
            0,
            14.78131,
            "<D",
            115.6449,
            0,
        ],
        1727998740: [
            0.19633,
            0,
            0.19633,
            0,
            39.87529,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            114.6042,
            0,
            0.83657,
            0,
            21.40545,
            0,
            0.52976,
            0,
            0,
            0,
            29.28836,
            0,
            14.32554,
            "<D",
            115.3903,
            0,
        ],
        1727998800: [
            0.11941,
            0,
            0.11941,
            0,
            40.70853,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            104.4574,
            0,
            0.70094,
            0,
            21.38035,
            0,
            0.36752,
            0,
            0,
            0,
            29.28856,
            0,
            10.28747,
            "<D",
            114.8556,
            0,
        ],
        1727998860: [
            0.11971,
            0,
            0.11971,
            0,
            41.31334,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            99.99207,
            0,
            0.56894,
            0,
            21.43235,
            0,
            0.32448,
            0,
            0,
            0,
            29.29017,
            0,
            14.38277,
            "<D",
            115.9896,
            0,
        ],
        1727998920: [
            0.20529,
            0,
            0.20529,
            0,
            40.88166,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            98.32738,
            0,
            0.51718,
            0,
            21.41364,
            0,
            0.21852,
            0,
            0,
            0,
            29.29018,
            0,
            9.3417,
            "<D",
            115.7776,
            0,
        ],
        1727998980: [
            0.22244,
            0,
            0.22244,
            0,
            40.81278,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            77.20619,
            0,
            0.44026,
            0,
            21.37105,
            0,
            0.1523,
            0,
            0,
            0,
            29.29049,
            0,
            12.42792,
            "<D",
            114.9248,
            0,
        ],
        1727999040: [
            0.11029,
            0,
            0.11029,
            0,
            41.73962,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            71.16035,
            0,
            0.50788,
            0,
            21.43888,
            0,
            0.15561,
            0,
            0,
            0,
            29.2912,
            0,
            11.9046,
            "<D",
            114.6614,
            0,
        ],
        1727999100: [
            0.34229,
            0,
            0.34229,
            0,
            41.76674,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            58.31089,
            0,
            0.57037,
            0,
            21.23114,
            0,
            0.09271,
            0,
            0,
            0,
            29.29169,
            0,
            11.99604,
            "<D",
            113.9423,
            0,
        ],
        1727999160: [
            0.3382,
            0,
            0.3382,
            0,
            40.44851,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            45.76421,
            0,
            0.58965,
            0,
            20.52839,
            0,
            -0.02649,
            0,
            0,
            0,
            29.29169,
            0,
            8.5878,
            "<D",
            114.717,
            0,
        ],
        1727999220: [
            0.14423,
            0,
            0.14423,
            0,
            38.34619,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            39.63789,
            0,
            0.60847,
            0,
            19.98637,
            0,
            -0.03973,
            0,
            0,
            0,
            29.29169,
            0,
            11.96697,
            "<D",
            115.147,
            0,
        ],
        1727999280: [
            0.0944,
            0,
            0.0944,
            0,
            37.18401,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            6.55057,
            0,
            0.72111,
            0,
            19.92045,
            0,
            -0.03642,
            0,
            0,
            0,
            29.29171,
            0,
            12.79297,
            "<D",
            115.3816,
            0,
        ],
        1727999340: [
            0.13533,
            0,
            0.13533,
            0,
            37.01641,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            1.30272,
            0,
            1.05589,
            0,
            20.00177,
            0,
            -0.04304,
            0,
            0,
            0,
            29.29172,
            0,
            11.28041,
            "<D",
            115.2643,
            0,
        ],
        1727999400: [
            0.13972,
            0,
            0.13972,
            0,
            37.27765,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            6.85537,
            0,
            1.19391,
            0,
            19.86802,
            0,
            -0.10595,
            0,
            0,
            0,
            29.29177,
            0,
            12.39458,
            "<D",
            114.9028,
            0,
        ],
        1727999460: [
            0.02318,
            0,
            0.02318,
            0,
            38.32028,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            27.70839,
            0,
            1.1389,
            0,
            19.79774,
            0,
            -0.19534,
            0,
            0,
            0,
            29.29166,
            0,
            11.66873,
            "<D",
            114.276,
            0,
        ],
        1727999520: [
            0.05955,
            0,
            0.05955,
            0,
            37.8223,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            35.76218,
            0,
            0.96877,
            0,
            19.82496,
            0,
            -0.16555,
            0,
            0,
            0,
            29.29178,
            0,
            8.7071,
            "<D",
            114.6833,
            0,
        ],
        1727999580: [
            0.19612,
            0,
            0.19612,
            0,
            37.52378,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            34.89179,
            0,
            1.12944,
            0,
            19.79273,
            0,
            -0.19203,
            0,
            0,
            0,
            29.29175,
            0,
            9.73378,
            "<D",
            114.7704,
            0,
        ],
        1727999640: [
            0.1244,
            0,
            0.1244,
            0,
            37.42397,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            22.76027,
            0,
            1.09663,
            0,
            19.79551,
            0,
            -0.16886,
            0,
            0,
            0,
            29.29272,
            0,
            11.60088,
            "<D",
            114.0133,
            0,
        ],
        1727999700: [
            0.20608,
            0,
            0.20608,
            0,
            36.61536,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            35.02693,
            0,
            0.80528,
            0,
            19.97872,
            0,
            -0.18872,
            0,
            0,
            0,
            29.29274,
            0,
            11.30843,
            "<D",
            113.8557,
            0,
        ],
        1727999760: [
            0.03686,
            0,
            0.03686,
            0,
            36.24277,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            34.83027,
            0,
            0.69802,
            0,
            20.21324,
            0,
            -0.13244,
            0,
            0,
            0,
            29.29287,
            0,
            10.95922,
            "<D",
            115.7087,
            0,
        ],
        1727999820: [
            0.36498,
            0,
            0.36498,
            0,
            35.45489,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            35.14998,
            0,
            0.71771,
            0,
            19.88652,
            0,
            -0.09602,
            0,
            0,
            0,
            29.29322,
            0,
            9.71794,
            "<D",
            116.1069,
            0,
        ],
        1727999880: [
            0.14599,
            0,
            0.14599,
            0,
            35.11262,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            35.42357,
            0,
            0.70802,
            0,
            19.90935,
            0,
            -0.06622,
            0,
            0,
            0,
            29.29385,
            0,
            13.23816,
            "<D",
            116.0815,
            0,
        ],
        1727999940: [
            0.25709,
            0,
            0.25709,
            0,
            33.97577,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            30.35338,
            0,
            0.67598,
            0,
            19.96014,
            0,
            -0.15892,
            0,
            0,
            0,
            29.29319,
            0,
            9.84134,
            "<D",
            113.7857,
            0,
        ],
        1728000000: [
            0.27759,
            0,
            0.27759,
            0,
            32.85005,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            26.51476,
            0,
            0.6851,
            0,
            19.83583,
            0,
            -0.23508,
            0,
            0,
            0,
            29.2931,
            0,
            12.08221,
            "<D",
            112.5333,
            0,
        ],
        1728000060: [
            0.22361,
            0,
            0.22361,
            0,
            32.81406,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            35.81746,
            0,
            0.68387,
            0,
            19.82663,
            0,
            -0.2119,
            0,
            0,
            0,
            29.29315,
            0,
            12.08046,
            "<D",
            113.019,
            0,
        ],
        1728000120: [
            0.33417,
            0,
            0.33417,
            0,
            31.80663,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            32.99747,
            0,
            0.94703,
            0,
            19.80592,
            0,
            -0.0927,
            0,
            0,
            0,
            29.29451,
            0,
            10.43588,
            "<D",
            112.4842,
            0,
        ],
        1728000180: [
            0.25205,
            0,
            0.25205,
            0,
            30.96113,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            39.99361,
            0,
            0.94722,
            0,
            19.87443,
            0,
            -0.04966,
            0,
            0,
            0,
            29.29444,
            0,
            11.37855,
            "<D",
            112.8462,
            0,
        ],
        1728000240: [
            0.16349,
            0,
            0.16349,
            0,
            31.20748,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            40.29567,
            0,
            1.07887,
            0,
            19.96662,
            0,
            -0.04966,
            0,
            0,
            0,
            29.29446,
            0,
            10.69462,
            "<D",
            111.4438,
            0,
        ],
        1728000300: [
            0.27281,
            0,
            0.27281,
            0,
            31.50799,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            36.75907,
            0,
            1.3151,
            0,
            20.05139,
            0,
            -0.07284,
            0,
            0,
            0,
            29.29466,
            0,
            10.07231,
            "<D",
            111.4676,
            0,
        ],
        1728000360: [
            0.15355,
            0,
            0.15355,
            0,
            31.54194,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            41.31947,
            0,
            1.17406,
            0,
            20.12821,
            0,
            -0.14567,
            0,
            0,
            0,
            29.29458,
            0,
            11.06643,
            "<D",
            111.3005,
            0,
        ],
        1728000420: [
            0.01557,
            0,
            0.01557,
            0,
            31.60957,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            41.84868,
            0,
            1.31552,
            0,
            20.01227,
            0,
            -0.15892,
            0,
            0,
            0,
            29.29261,
            0,
            9.88808,
            "<D",
            110.9854,
            0,
        ],
        1728000480: [
            0.08269,
            0,
            0.08269,
            0,
            32.0685,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            46.81895,
            0,
            1.15137,
            0,
            20.20476,
            0,
            -0.20196,
            0,
            0,
            0,
            29.29419,
            0,
            10.62477,
            "<D",
            110.054,
            0,
        ],
        1728000540: [
            0.19871,
            0,
            0.19871,
            0,
            32.75088,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            52.27177,
            0,
            1.18036,
            0,
            20.22629,
            0,
            -0.12912,
            0,
            0,
            0,
            29.29473,
            0,
            13.86774,
            "<D",
            110.4391,
            0,
        ],
        1728000600: [
            0.20178,
            0,
            0.20178,
            0,
            32.95769,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            36.10973,
            0,
            1.16841,
            0,
            20.28122,
            0,
            -0.06291,
            0,
            0,
            0,
            29.29402,
            0,
            12.84597,
            "<D",
            107.5024,
            0,
        ],
        1728000660: [
            0.39027,
            0,
            0.39027,
            0,
            33.14399,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            53.40352,
            0,
            1.01686,
            0,
            20.37196,
            0,
            -0.06952,
            0,
            0,
            0,
            29.29396,
            0,
            11.67374,
            "<D",
            109.2003,
            0,
        ],
        1728000720: [
            0.18655,
            0,
            0.18655,
            0,
            32.19989,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            49.88339,
            0,
            1.07142,
            0,
            20.43762,
            0,
            -0.10926,
            0,
            0,
            0,
            29.29443,
            0,
            10.65163,
            "<D",
            110.9545,
            0,
        ],
        1728000780: [
            0.27418,
            0,
            0.27418,
            0,
            32.9307,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            52.54232,
            0,
            0.94755,
            0,
            20.37163,
            0,
            -0.09932,
            0,
            0,
            0,
            29.29248,
            0,
            10.61392,
            "<D",
            111.6057,
            0,
        ],
        1728000840: [
            0.24238,
            0,
            0.24238,
            0,
            32.27542,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            39.40047,
            0,
            0.98154,
            0,
            20.32766,
            0,
            -0.13905,
            0,
            0,
            0,
            29.29338,
            0,
            11.81504,
            "<D",
            111.4067,
            0,
        ],
        1728000900: [
            0.25325,
            0,
            0.25325,
            0,
            33.1619,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            50.06266,
            0,
            0.68309,
            0,
            20.2588,
            0,
            -0.1854,
            0,
            0,
            0,
            29.29244,
            0,
            12.85337,
            "<D",
            111.6943,
            0,
        ],
        1728000960: [
            0.25992,
            0,
            0.25992,
            0,
            30.88932,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            43.17489,
            0,
            0.68578,
            0,
            20.21664,
            0,
            -0.22183,
            0,
            0,
            0,
            29.29399,
            0,
            11.02009,
            "<D",
            111.5116,
            0,
        ],
        1728001020: [
            0.25863,
            0,
            0.25863,
            0,
            31.3796,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            45.66372,
            0,
            1.02593,
            0,
            20.26274,
            0,
            -0.23507,
            0,
            0,
            0,
            29.29406,
            0,
            10.88193,
            "<D",
            111.2991,
            0,
        ],
        1728001080: [
            0.13515,
            0,
            0.13515,
            0,
            33.01171,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            46.39775,
            0,
            0.64491,
            0,
            20.21818,
            0,
            -0.23507,
            0,
            0,
            0,
            29.29478,
            0,
            11.68286,
            "<D",
            111.9105,
            0,
        ],
        1728001140: [
            0.22709,
            0,
            0.22709,
            0,
            32.93846,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            54.79918,
            0,
            0.87043,
            0,
            20.20083,
            0,
            -0.23176,
            0,
            0,
            0,
            29.2938,
            0,
            11.7838,
            "<D",
            113.1987,
            0,
        ],
        1728001200: [
            0.19566,
            0,
            0.19566,
            0,
            33.68563,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            47.56522,
            0,
            0.88399,
            0,
            20.14784,
            0,
            -0.22845,
            0,
            0,
            0,
            29.29467,
            0,
            9.29752,
            "<D",
            113.127,
            0,
        ],
        1728001260: [
            0.27122,
            0,
            0.27122,
            0,
            33.45528,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            55.07473,
            0,
            0.8599,
            0,
            20.13216,
            0,
            -0.2119,
            0,
            0,
            0,
            29.29506,
            0,
            12.50983,
            "<D",
            111.6444,
            0,
        ],
        1728001320: [
            0.27572,
            0,
            0.27572,
            0,
            33.65997,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            66.29173,
            0,
            0.85283,
            0,
            20.0664,
            0,
            -0.20527,
            0,
            0,
            0,
            29.29506,
            0,
            13.47368,
            "<D",
            111.2731,
            0,
        ],
        1728001380: [
            0.24609,
            0,
            0.24609,
            0,
            34.75362,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            62.30745,
            0,
            0.96408,
            0,
            20.10111,
            0,
            -0.22183,
            0,
            0,
            0,
            29.29505,
            0,
            10.54892,
            "<D",
            114.6377,
            0,
        ],
        1728001440: [
            0.42211,
            0,
            0.42211,
            0,
            35.39733,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            68.63069,
            0,
            0.77732,
            0,
            20.09469,
            0,
            -0.23176,
            0,
            0,
            0,
            29.29505,
            0,
            12.97046,
            "<D",
            116.2063,
            0,
        ],
        1728001500: [
            0.33507,
            0,
            0.33507,
            0,
            35.27803,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            66.66397,
            0,
            0.97701,
            0,
            20.00853,
            0,
            -0.23507,
            0,
            0,
            0,
            29.295,
            0,
            13.11514,
            "<D",
            119.9848,
            0,
        ],
        1728001560: [
            0.33567,
            0,
            0.33567,
            0,
            36.81479,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            67.42503,
            0,
            0.96332,
            0,
            19.89153,
            0,
            -0.22845,
            0,
            0,
            0,
            29.29499,
            0,
            8.41625,
            "<D",
            120.3063,
            0,
        ],
        1728001620: [
            0.25356,
            0,
            0.25356,
            0,
            36.33712,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            48.05553,
            0,
            0.95313,
            0,
            19.9211,
            0,
            -0.21521,
            0,
            0,
            0,
            29.29498,
            0,
            11.97657,
            "<D",
            120.2636,
            0,
        ],
        1728001680: [
            0.36824,
            0,
            0.36824,
            0,
            37.28609,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            59.19204,
            0,
            0.73616,
            0,
            19.90232,
            0,
            -0.23176,
            0,
            0,
            0,
            29.29454,
            0,
            12.96197,
            "<D",
            119.8151,
            0,
        ],
        1728001740: [
            0.13596,
            0,
            0.13596,
            0,
            35.70937,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            51.50032,
            0,
            1.06741,
            0,
            19.9845,
            0,
            -0.23507,
            0,
            0,
            0,
            29.29447,
            0,
            10.38258,
            "<D",
            119.1628,
            0,
        ],
        1728001800: [
            0.40211,
            0,
            0.40211,
            0,
            36.84426,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            53.16189,
            0,
            0.9917,
            0,
            20.09125,
            0,
            -0.20527,
            0,
            0,
            0,
            29.29506,
            0,
            10.47815,
            "<D",
            119.8476,
            0,
        ],
        1728001860: [
            0.15042,
            0,
            0.15042,
            0,
            35.67012,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            37.8341,
            0,
            1.12933,
            0,
            20.12745,
            0,
            -0.19534,
            0,
            0,
            0,
            29.29506,
            0,
            10.6946,
            "<D",
            119.9724,
            0,
        ],
        1728001920: [
            0.14934,
            0,
            0.14934,
            0,
            36.76026,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            48.20556,
            0,
            1.18256,
            0,
            19.9091,
            0,
            -0.19203,
            0,
            0,
            0,
            29.29506,
            0,
            9.92449,
            "<D",
            118.653,
            0,
        ],
        1728001980: [
            0.11419,
            0,
            0.11419,
            0,
            35.60744,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            52.00515,
            0,
            0.969,
            0,
            19.85966,
            0,
            -0.17547,
            0,
            0,
            0,
            29.29506,
            0,
            10.86687,
            "<D",
            117.9217,
            0,
        ],
        1728002040: [
            0.35933,
            0,
            0.35933,
            0,
            34.11261,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            34.8414,
            0,
            0.93103,
            0,
            19.85056,
            0,
            -0.29466,
            0,
            0,
            0,
            29.29505,
            0,
            12.95041,
            "<D",
            118.3574,
            0,
        ],
        1728002100: [
            0.28605,
            0,
            0.28605,
            0,
            33.35444,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            34.82748,
            0,
            0.71909,
            0,
            19.68229,
            0,
            -0.28803,
            0,
            0,
            0,
            29.29506,
            0,
            11.64644,
            "<D",
            118.4952,
            0,
        ],
        1728002160: [
            0.27302,
            0,
            0.27302,
            0,
            33.46051,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            49.36496,
            0,
            0.83606,
            0,
            19.73094,
            0,
            -0.27149,
            0,
            0,
            0,
            29.29576,
            0,
            12.0462,
            "<D",
            118.5818,
            0,
        ],
        1728002220: [
            0.28513,
            0,
            0.28513,
            0,
            34.08324,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            50.63221,
            0,
            0.72149,
            0,
            19.53805,
            0,
            -0.26487,
            0,
            0,
            0,
            29.29549,
            0,
            13.08886,
            "<D",
            118.0635,
            0,
        ],
        1728002280: [
            0.36922,
            0,
            0.36922,
            0,
            33.90112,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            44.59406,
            0,
            0.98307,
            0,
            19.64246,
            0,
            -0.27149,
            0,
            0,
            0,
            29.29727,
            0,
            12.12338,
            "<D",
            119.232,
            0,
        ],
        1728002340: [
            0.21536,
            0,
            0.21536,
            0,
            34.1225,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            52.48988,
            0,
            0.96569,
            0,
            19.68962,
            0,
            -0.34101,
            0,
            0,
            0,
            29.29673,
            0,
            11.6867,
            "<D",
            120.9711,
            0,
        ],
        1728002400: [
            0.20518,
            0,
            0.20518,
            0,
            34.55613,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            41.01498,
            0,
            1.13442,
            0,
            19.73435,
            0,
            -0.31784,
            0,
            0,
            0,
            29.29798,
            0,
            10.58309,
            "<D",
            121.186,
            0,
        ],
        1728002460: [
            0.21393,
            0,
            0.21393,
            0,
            35.00699,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            30.40168,
            0,
            0.89093,
            0,
            19.73126,
            0,
            -0.32777,
            0,
            0,
            0,
            29.29814,
            0,
            14.43719,
            "<D",
            120.7132,
            0,
        ],
        1728002520: [
            0.33848,
            0,
            0.33848,
            0,
            34.22958,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            41.30814,
            0,
            0.80819,
            0,
            19.71361,
            0,
            -0.19202,
            0,
            0,
            0,
            29.29841,
            0,
            10.63537,
            "<D",
            120.3657,
            0,
        ],
        1728002580: [
            0.09262,
            0,
            0.09262,
            0,
            34.92999,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5786,
            0,
            34.65977,
            0,
            0.77603,
            0,
            19.65786,
            0,
            -0.0629,
            0,
            0,
            0,
            29.29852,
            0,
            11.38938,
            "<D",
            120.1031,
            0,
        ],
        1728002640: [
            0.2744,
            0,
            0.2744,
            0,
            33.86227,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            32.35052,
            0,
            0.8463,
            0,
            19.6533,
            0,
            -0.10263,
            0,
            0,
            0,
            29.29815,
            0,
            11.92789,
            "<D",
            119.5197,
            0,
        ],
        1728002700: [
            0.35351,
            0,
            0.35351,
            0,
            34.45769,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            18.98115,
            0,
            0.85079,
            0,
            19.62533,
            0,
            -0.16554,
            0,
            0,
            0,
            29.29877,
            0,
            12.50689,
            "<D",
            118.2506,
            0,
        ],
        1728002760: [
            0.22507,
            0,
            0.22507,
            0,
            34.03018,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            24.82683,
            0,
            0.69708,
            0,
            19.57413,
            0,
            -0.17216,
            0,
            0,
            0,
            29.29888,
            0,
            12.82947,
            "<D",
            118.2294,
            0,
        ],
        1728002820: [
            0.18068,
            0,
            0.18068,
            0,
            34.2883,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            17.21108,
            0,
            0.8469,
            0,
            19.56349,
            0,
            -0.1854,
            0,
            0,
            0,
            29.29867,
            0,
            9.96135,
            "<D",
            119.3771,
            0,
        ],
        1728002880: [
            0.09977,
            0,
            0.09977,
            0,
            34.24222,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            26.64746,
            0,
            0.59285,
            0,
            19.38571,
            0,
            -0.15229,
            0,
            0,
            0,
            29.29881,
            0,
            12.75257,
            "<D",
            118.783,
            0,
        ],
        1728002940: [
            0.27317,
            0,
            0.27317,
            0,
            33.81779,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            27.73559,
            0,
            0.58155,
            0,
            19.2787,
            0,
            -0.15229,
            0,
            0,
            0,
            29.29919,
            0,
            12.10307,
            "<D",
            118.6945,
            0,
        ],
        1728003000: [
            0.28035,
            0,
            0.28035,
            0,
            33.81266,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            22.75565,
            0,
            0.58495,
            0,
            19.17981,
            0,
            -0.09601,
            0,
            0,
            0,
            29.29892,
            0,
            12.76946,
            "<D",
            116.6531,
            0,
        ],
        1728003060: [
            0.1711,
            0,
            0.1711,
            0,
            33.28585,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            43.24606,
            0,
            0.56913,
            0,
            19.25548,
            0,
            -0.07283,
            0,
            0,
            0,
            29.30062,
            0,
            11.43087,
            "<D",
            118.3344,
            0,
        ],
        1728003120: [
            0.15556,
            0,
            0.15556,
            0,
            32.46183,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            40.09857,
            0,
            0.48009,
            0,
            19.11299,
            0,
            -0.14567,
            0,
            0,
            0,
            29.30061,
            0,
            12.43294,
            "<D",
            117.353,
            0,
        ],
        1728003180: [
            0.48593,
            0,
            0.48593,
            0,
            30.73493,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            59.89122,
            0,
            0.44425,
            0,
            19.1329,
            0,
            -0.11918,
            0,
            0,
            0,
            29.30142,
            0,
            12.45535,
            "<D",
            119.4442,
            0,
        ],
        1728003240: [
            0.31291,
            0,
            0.31291,
            0,
            31.10065,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            58.45057,
            0,
            0.40798,
            0,
            19.19616,
            0,
            -0.04966,
            0,
            0,
            0,
            29.30126,
            0,
            11.43506,
            "<D",
            119.1292,
            0,
        ],
        1728003300: [
            0.13865,
            0,
            0.13865,
            0,
            30.30181,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            65.91648,
            0,
            0.48199,
            0,
            19.2626,
            0,
            -0.0629,
            0,
            0,
            0,
            29.30174,
            0,
            12.61286,
            "<D",
            118.4241,
            0,
        ],
        1728003360: [
            0.28671,
            0,
            0.28671,
            0,
            29.94345,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            52.53376,
            0,
            0.5084,
            0,
            19.32823,
            0,
            -0.10594,
            0,
            0,
            0,
            29.30216,
            0,
            11.63969,
            "<D",
            118.4552,
            0,
        ],
        1728003420: [
            0.38168,
            0,
            0.38168,
            0,
            29.71648,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5829,
            0,
            61.80011,
            0,
            0.4851,
            0,
            19.31241,
            0,
            -0.06621,
            0,
            0,
            0,
            29.3022,
            0,
            11.3344,
            "<D",
            118.9182,
            0,
        ],
        1728003480: [
            0.30194,
            0,
            0.30194,
            0,
            30.09876,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5829,
            0,
            40.46884,
            0,
            0.66447,
            0,
            19.25109,
            0,
            -0.17216,
            0,
            0,
            0,
            29.30228,
            0,
            12.51997,
            "<D",
            119.1761,
            0,
        ],
        1728003540: [
            0.25853,
            0,
            0.25853,
            0,
            30.32236,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5657,
            0,
            44.25167,
            0,
            0.56548,
            0,
            19.20653,
            0,
            -0.19202,
            0,
            0,
            0,
            29.30229,
            0,
            8.39643,
            "<D",
            118.6887,
            0,
        ],
        1728003600: [
            0.24842,
            0,
            0.24842,
            0,
            30.37581,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            47.9902,
            0,
            0.53885,
            0,
            19.18003,
            0,
            -0.20196,
            0,
            0,
            0,
            29.30229,
            0,
            15.41995,
            "<D",
            118.3402,
            0,
        ],
        1728003660: [
            0.20058,
            0,
            0.20058,
            0,
            31.06567,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            52.76743,
            0,
            0.50675,
            0,
            19.1761,
            0,
            -0.19865,
            0,
            0,
            0,
            29.3023,
            0,
            9.80419,
            "<D",
            118.1871,
            0,
        ],
        1728003720: [
            0.0853,
            0,
            0.0853,
            0,
            31.99817,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5829,
            0,
            48.26984,
            0,
            0.52146,
            0,
            19.14664,
            0,
            -0.2152,
            0,
            0,
            0,
            29.30232,
            0,
            12.89521,
            "<D",
            118.0138,
            0,
        ],
        1728003780: [
            0.14114,
            0,
            0.14114,
            0,
            32.30309,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            49.43884,
            0,
            0.55947,
            0,
            19.10478,
            0,
            -0.22844,
            0,
            0,
            0,
            29.30233,
            0,
            11.78953,
            "<D",
            118.8686,
            0,
        ],
        1728003840: [
            0.09014,
            0,
            0.09014,
            0,
            32.16576,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            62.12621,
            0,
            0.57173,
            0,
            19.04124,
            0,
            -0.23176,
            0,
            0,
            0,
            29.30267,
            0,
            13.24617,
            "<D",
            119.1707,
            0,
        ],
        1728003900: [
            0.12704,
            0,
            0.12704,
            0,
            32.40763,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            60.51047,
            0,
            0.71208,
            0,
            18.94136,
            0,
            -0.24169,
            0,
            0,
            0,
            29.30315,
            0,
            10.39126,
            "<D",
            118.8867,
            0,
        ],
        1728003960: [
            0.11003,
            0,
            0.11003,
            0,
            32.73313,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            40.33559,
            0,
            0.78729,
            0,
            18.86217,
            0,
            -0.21189,
            0,
            0,
            0,
            29.3042,
            0,
            13.28617,
            "<D",
            118.5705,
            0,
        ],
        1728004020: [
            0.21574,
            0,
            0.21574,
            0,
            32.77615,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            49.37817,
            0,
            0.93393,
            0,
            18.71433,
            0,
            -0.20527,
            0,
            0,
            0,
            29.30532,
            0,
            12.1966,
            "<D",
            118.6742,
            0,
        ],
        1728004080: [
            0.24786,
            0,
            0.24786,
            0,
            33.1935,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            46.57022,
            0,
            0.76192,
            0,
            18.5913,
            0,
            -0.16223,
            0,
            0,
            0,
            29.30535,
            0,
            12.96862,
            "<D",
            119.3652,
            0,
        ],
        1728004140: [
            0.0608,
            0,
            0.0608,
            0,
            32.3078,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5829,
            0,
            48.37357,
            0,
            0.73223,
            0,
            18.66665,
            0,
            -0.1854,
            0,
            0,
            0,
            29.30508,
            0,
            12.7885,
            "<D",
            119.5442,
            0,
        ],
        1728004200: [
            0.31646,
            0,
            0.31646,
            0,
            32.78422,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            50.88404,
            0,
            0.56068,
            0,
            18.75503,
            0,
            -0.14898,
            0,
            0,
            0,
            29.3053,
            0,
            12.95028,
            "<D",
            118.4252,
            0,
        ],
        1728004260: [
            0.10217,
            0,
            0.10217,
            0,
            32.50912,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            36.85741,
            0,
            0.49494,
            0,
            18.79023,
            0,
            -0.19533,
            0,
            0,
            0,
            29.30535,
            0,
            11.10759,
            "<D",
            118.5619,
            0,
        ],
        1728004320: [
            -0.0456,
            0,
            -0.0456,
            0,
            32.13075,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5528,
            0,
            41.17091,
            0,
            0.64857,
            0,
            18.84877,
            0,
            -0.22513,
            0,
            0,
            0,
            29.30557,
            0,
            12.67985,
            "<D",
            118.6482,
            0,
        ],
        1728004380: [
            0.24283,
            0,
            0.24283,
            0,
            31.2899,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            61.59901,
            0,
            0.6022,
            0,
            18.9648,
            0,
            -0.23176,
            0,
            0,
            0,
            29.30558,
            0,
            10.86122,
            "<D",
            118.8522,
            0,
        ],
        1728004440: [
            0.20726,
            0,
            0.20726,
            0,
            30.97533,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            73.55289,
            0,
            0.56122,
            0,
            18.96906,
            0,
            -0.21851,
            0,
            0,
            0,
            29.30559,
            0,
            12.31563,
            "<D",
            118.958,
            0,
        ],
        1728004500: [
            0.16595,
            0,
            0.16595,
            0,
            30.46037,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            67.04227,
            0,
            0.59877,
            0,
            18.99213,
            0,
            -0.21189,
            0,
            0,
            0,
            29.30559,
            0,
            11.09432,
            "<D",
            118.3912,
            0,
        ],
        1728004560: [
            0.04594,
            0,
            0.04594,
            0,
            30.29454,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            83.78896,
            0,
            0.44878,
            0,
            19.01934,
            0,
            -0.18871,
            0,
            0,
            0,
            29.30609,
            0,
            12.14666,
            "<D",
            119.0583,
            0,
        ],
        1728004620: [
            0.01444,
            0,
            0.01444,
            0,
            30.59459,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            78.90247,
            0,
            0.46017,
            0,
            19.03477,
            0,
            -0.19865,
            0,
            0,
            0,
            29.30706,
            0,
            13.421,
            "<D",
            118.8847,
            0,
        ],
        1728004680: [
            0.34364,
            0,
            0.34364,
            0,
            30.63699,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            64.73082,
            0,
            0.61457,
            0,
            19.01268,
            0,
            -0.17547,
            0,
            0,
            0,
            29.3075,
            0,
            13.77782,
            "<D",
            118.4687,
            0,
        ],
        1728004740: [
            0.07416,
            0,
            0.07416,
            0,
            29.99873,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5571,
            0,
            72.88166,
            0,
            0.90165,
            0,
            18.99832,
            0,
            -0.19203,
            0,
            0,
            0,
            29.30773,
            0,
            14.1439,
            "<D",
            118.9312,
            0,
        ],
        1728004800: [
            0.2285,
            0,
            0.2285,
            0,
            32.13266,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5657,
            0,
            57.88728,
            0,
            0.87801,
            0,
            18.90267,
            0,
            -0.23507,
            0,
            0,
            0,
            29.30886,
            0,
            12.97623,
            "<D",
            119.4893,
            0,
        ],
        1728004860: [
            0.2021,
            0,
            0.2021,
            0,
            32.1023,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            50.71335,
            0,
            0.72406,
            0,
            18.75572,
            0,
            -0.22513,
            0,
            0,
            0,
            29.30905,
            0,
            13.8883,
            "<D",
            119.5214,
            0,
        ],
        1728004920: [
            0.31246,
            0,
            0.31246,
            0,
            32.69837,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.59581,
            0,
            49.37837,
            0,
            0.84893,
            0,
            18.64942,
            0,
            -0.2152,
            0,
            0,
            0,
            29.30925,
            0,
            13.74195,
            "<D",
            119.7303,
            0,
        ],
        1728004980: [
            0.18146,
            0,
            0.18146,
            0,
            32.86235,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            51.16943,
            0,
            0.94095,
            0,
            18.5601,
            0,
            -0.19534,
            0,
            0,
            0,
            29.30929,
            0,
            11.11028,
            "<D",
            119.7713,
            0,
        ],
        1728005040: [
            0.16771,
            0,
            0.16771,
            0,
            33.04921,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.59581,
            0,
            39.32313,
            0,
            0.6857,
            0,
            18.52399,
            0,
            -0.19533,
            0,
            0,
            0,
            29.30936,
            0,
            13.78421,
            "<D",
            120.8963,
            0,
        ],
        1728005100: [
            0.08069,
            0,
            0.08069,
            0,
            32.4227,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.57,
            0,
            42.19883,
            0,
            0.63395,
            0,
            18.54732,
            0,
            -0.23506,
            0,
            0,
            0,
            29.30992,
            0,
            15.20053,
            "<D",
            121.3318,
            0,
        ],
        1728005160: [
            0.27783,
            0,
            0.27783,
            0,
            30.73072,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5657,
            0,
            51.42529,
            0,
            1.00101,
            0,
            18.60163,
            0,
            -0.20196,
            0,
            0,
            0,
            29.3112,
            0,
            11.68727,
            "<D",
            121.3224,
            0,
        ],
        1728005220: [
            0.20657,
            0,
            0.20657,
            0,
            31.1946,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            45.11567,
            0,
            0.90326,
            0,
            18.60211,
            0,
            -0.20858,
            0,
            0,
            0,
            29.31263,
            0,
            12.58365,
            "<D",
            120.6375,
            0,
        ],
        1728005280: [
            0.10275,
            0,
            0.10275,
            0,
            26.89313,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5356,
            0,
            45.85233,
            0,
            0.72813,
            0,
            18.56963,
            0,
            -0.19203,
            0,
            0,
            0,
            29.31248,
            0,
            12.44389,
            "<D",
            120.3638,
            0,
        ],
        1728005340: [
            0.30247,
            0,
            0.30247,
            0,
            26.94521,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5313,
            0,
            24.54578,
            0,
            0.74411,
            0,
            18.52534,
            0,
            -0.22844,
            0,
            0,
            0,
            29.31276,
            0,
            13.22971,
            "<D",
            120.1713,
            0,
        ],
        1728005400: [
            0.41472,
            0,
            0.41472,
            0,
            26.85684,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.52271,
            0,
            25.12932,
            0,
            0.6032,
            0,
            18.53057,
            0,
            -0.16885,
            0,
            0,
            0,
            29.31231,
            0,
            10.36657,
            "<D",
            119.5802,
            0,
        ],
        1728005460: [
            0.34258,
            0,
            0.34258,
            0,
            28.57268,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.52271,
            0,
            27.18144,
            0,
            0.5598,
            0,
            18.37243,
            0,
            -0.13243,
            0,
            0,
            0,
            29.31218,
            0,
            12.83031,
            "<D",
            119.4445,
            0,
        ],
        1728005520: [
            0.06844,
            0,
            0.06844,
            0,
            27.88643,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.52271,
            0,
            20.62753,
            0,
            0.49354,
            0,
            18.42708,
            0,
            -0.11588,
            0,
            0,
            0,
            29.31338,
            0,
            12.4979,
            "<D",
            119.1087,
            0,
        ],
        1728005580: [
            0.23533,
            0,
            0.23533,
            0,
            27.7711,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.527,
            0,
            29.29024,
            0,
            0.48968,
            0,
            18.49898,
            0,
            -0.08608,
            0,
            0,
            0,
            29.3148,
            0,
            12.37455,
            "<D",
            118.9098,
            0,
        ],
        1728005640: [
            0.20597,
            0,
            0.20597,
            0,
            28.52022,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.527,
            0,
            22.04187,
            0,
            0.50125,
            0,
            18.53848,
            0,
            -0.05297,
            0,
            0,
            0,
            29.31589,
            0,
            13.17075,
            "<D",
            118.639,
            0,
        ],
        1728005700: [
            0.12712,
            0,
            0.12712,
            0,
            28.71044,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            32.93603,
            0,
            0.49925,
            0,
            18.54516,
            0,
            -0.07614,
            0,
            0,
            0,
            29.31586,
            0,
            12.01604,
            "<D",
            119.3409,
            0,
        ],
        1728005760: [
            0.20904,
            0,
            0.20904,
            0,
            28.75701,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            27.16839,
            0,
            0.54326,
            0,
            18.54751,
            0,
            -0.05297,
            0,
            0,
            0,
            29.31713,
            0,
            13.7343,
            "<D",
            120.0112,
            0,
        ],
        1728005820: [
            0.28981,
            0,
            0.28981,
            0,
            29.45721,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            31.01797,
            0,
            0.49252,
            0,
            18.53256,
            0,
            -0.05959,
            0,
            0,
            0,
            29.31719,
            0,
            10.99309,
            "<D",
            120.1625,
            0,
        ],
        1728005880: [
            0.41738,
            0,
            0.41738,
            0,
            29.29755,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            26.76201,
            0,
            0.4475,
            0,
            18.45597,
            0,
            -0.05959,
            0,
            0,
            0,
            29.31718,
            0,
            12.58634,
            "<D",
            120.181,
            0,
        ],
        1728005940: [
            0.2026,
            0,
            0.2026,
            0,
            29.5605,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5872,
            0,
            13.75393,
            0,
            0.56426,
            0,
            18.40932,
            0,
            -0.0629,
            0,
            0,
            0,
            29.31741,
            0,
            12.39366,
            "<D",
            119.8232,
            0,
        ],
        1728006000: [
            0.27046,
            0,
            0.27046,
            0,
            28.31763,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5829,
            0,
            4.26275,
            0,
            0.70567,
            0,
            18.23412,
            0,
            -0.20527,
            0,
            0,
            0,
            29.3184,
            0,
            12.36653,
            "<D",
            119.5623,
            0,
        ],
        1728006060: [
            0.03098,
            0,
            0.03098,
            0,
            26.11521,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            2.48348,
            0,
            1.07705,
            0,
            18.03447,
            0,
            -0.16885,
            0,
            0,
            0,
            29.31881,
            0,
            14.16651,
            "<D",
            119.879,
            0,
        ],
        1728006120: [
            0.38103,
            0,
            0.38103,
            0,
            24.44374,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5657,
            0,
            357.5312,
            0,
            1.17185,
            0,
            17.87586,
            0,
            -0.13905,
            0,
            0,
            0,
            29.32102,
            0,
            13.8433,
            "<D",
            121.2843,
            0,
        ],
        1728006180: [
            0.22282,
            0,
            0.22282,
            0,
            23.78282,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.53991,
            0,
            359.3401,
            0,
            1.06335,
            0,
            17.60225,
            0,
            -0.15229,
            0,
            0,
            0,
            29.32048,
            0,
            12.78451,
            "<D",
            120.2016,
            0,
        ],
        1728006240: [
            0.37567,
            0,
            0.37567,
            0,
            24.62424,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5356,
            0,
            1.30462,
            0,
            1.05332,
            0,
            17.51423,
            0,
            -0.0927,
            0,
            0,
            0,
            29.32104,
            0,
            10.53235,
            "<D",
            120.4457,
            0,
        ],
        1728006300: [
            0.08557,
            0,
            0.08557,
            0,
            24.22925,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.52271,
            0,
            9.14549,
            0,
            1.00613,
            0,
            17.55227,
            0,
            -0.05959,
            0,
            0,
            0,
            29.32108,
            0,
            14.45439,
            "<D",
            120.7942,
            0,
        ],
        1728006360: [
            0.15941,
            0,
            0.15941,
            0,
            23.83627,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.52271,
            0,
            7.47164,
            0,
            1.05453,
            0,
            17.48259,
            0,
            -0.08607,
            0,
            0,
            0,
            29.32121,
            0,
            12.03976,
            "<D",
            120.8004,
            0,
        ],
        1728006420: [
            0.04873,
            0,
            0.04873,
            0,
            22.79512,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5055,
            0,
            9.06938,
            0,
            1.12834,
            0,
            17.54434,
            0,
            -0.16222,
            0,
            0,
            0,
            29.32112,
            0,
            16.12346,
            "<D",
            120.3242,
            0,
        ],
        1728006480: [
            0.33705,
            0,
            0.33705,
            0,
            21.71811,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5055,
            0,
            13.27273,
            0,
            1.14386,
            0,
            17.65096,
            0,
            -0.17877,
            0,
            0,
            0,
            29.32158,
            0,
            14.09234,
            "<D",
            119.5415,
            0,
        ],
        1728006540: [
            0.25232,
            0,
            0.25232,
            0,
            24.12052,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4969,
            0,
            24.5132,
            0,
            1.05497,
            0,
            17.68399,
            0,
            -0.22513,
            0,
            0,
            0,
            29.32413,
            0,
            11.17519,
            "<D",
            119.7219,
            0,
        ],
        1728006600: [
            0.09629,
            0,
            0.09629,
            0,
            22.96817,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4969,
            0,
            24.8044,
            0,
            1.09306,
            0,
            17.83994,
            0,
            -0.23506,
            0,
            0,
            0,
            29.32431,
            0,
            13.06312,
            "<D",
            119.8055,
            0,
        ],
        1728006660: [
            0.18478,
            0,
            0.18478,
            0,
            22.81927,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5012,
            0,
            34.87866,
            0,
            1.10811,
            0,
            17.8859,
            0,
            -0.23837,
            0,
            0,
            0,
            29.32454,
            0,
            11.68293,
            "<D",
            119.1876,
            0,
        ],
        1728006720: [
            0.10517,
            0,
            0.10517,
            0,
            24.98815,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.51841,
            0,
            33.31237,
            0,
            1.11346,
            0,
            17.90489,
            0,
            -0.2152,
            0,
            0,
            0,
            29.32457,
            0,
            14.95475,
            "<D",
            119.682,
            0,
        ],
        1728006780: [
            0.08146,
            0,
            0.08146,
            0,
            24.76845,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5141,
            0,
            37.84901,
            0,
            1.11814,
            0,
            17.93427,
            0,
            -0.19202,
            0,
            0,
            0,
            29.32475,
            0,
            10.74885,
            "<D",
            119.2536,
            0,
        ],
        1728006840: [
            0.16484,
            0,
            0.16484,
            0,
            25.33358,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.52271,
            0,
            38.592,
            0,
            0.95844,
            0,
            17.91629,
            0,
            -0.23506,
            0,
            0,
            0,
            29.32461,
            0,
            10.13686,
            "<D",
            119.7526,
            0,
        ],
        1728006900: [
            0.23223,
            0,
            0.23223,
            0,
            25.59687,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.52271,
            0,
            43.52444,
            0,
            0.94158,
            0,
            17.87175,
            0,
            -0.19864,
            0,
            0,
            0,
            29.32475,
            0,
            14.04769,
            "<D",
            119.0662,
            0,
        ],
        1728006960: [
            0.20746,
            0,
            0.20746,
            0,
            26.02957,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.53991,
            0,
            50.28212,
            0,
            0.9673,
            0,
            17.78175,
            0,
            -0.16884,
            0,
            0,
            0,
            29.32494,
            0,
            14.50432,
            "<D",
            119.4841,
            0,
        ],
        1728007020: [
            0.21511,
            0,
            0.21511,
            0,
            24.87809,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5571,
            0,
            41.5673,
            0,
            0.73231,
            0,
            17.80647,
            0,
            -0.13574,
            0,
            0,
            0,
            29.32491,
            0,
            12.42668,
            "<D",
            118.2983,
            0,
        ],
        1728007080: [
            0.40709,
            0,
            0.40709,
            0,
            24.37994,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            45.0285,
            0,
            0.61374,
            0,
            17.8298,
            0,
            -0.07945,
            0,
            0,
            0,
            29.32463,
            0,
            12.2671,
            "<D",
            118.4715,
            0,
        ],
        1728007140: [
            0.19443,
            0,
            0.19443,
            0,
            24.12223,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            49.77391,
            0,
            0.6325,
            0,
            17.81849,
            0,
            -0.05628,
            0,
            0,
            0,
            29.32444,
            0,
            13.22499,
            "<D",
            119.2413,
            0,
        ],
        1728007200: [
            0.06742,
            0,
            0.06742,
            0,
            24.62671,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.52271,
            0,
            48.15939,
            0,
            0.54221,
            0,
            17.82098,
            0,
            -0.06952,
            0,
            0,
            0,
            29.32431,
            0,
            12.6218,
            "<D",
            119.4109,
            0,
        ],
        1728007260: [
            0.2028,
            0,
            0.2028,
            0,
            24.29779,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5098,
            0,
            55.30332,
            0,
            0.58882,
            0,
            17.82337,
            0,
            -0.04966,
            0,
            0,
            0,
            29.32431,
            0,
            12.21585,
            "<D",
            119.3626,
            0,
        ],
        1728007320: [
            0.15498,
            0,
            0.15498,
            0,
            23.16446,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5141,
            0,
            52.71281,
            0,
            0.55836,
            0,
            17.95308,
            0,
            -0.05297,
            0,
            0,
            0,
            29.32456,
            0,
            13.14103,
            "<D",
            119.014,
            0,
        ],
        1728007380: [
            0.14459,
            0,
            0.14459,
            0,
            22.64355,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4969,
            0,
            55.04117,
            0,
            0.62897,
            0,
            18.09857,
            0,
            -0.0629,
            0,
            0,
            0,
            29.32451,
            0,
            9.79195,
            "<D",
            119.1339,
            0,
        ],
        1728007440: [
            0.41678,
            0,
            0.41678,
            0,
            23.21504,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.484,
            0,
            51.53665,
            0,
            0.56789,
            0,
            18.1465,
            0,
            -0.10263,
            0,
            0,
            0,
            29.32477,
            0,
            12.89872,
            "<D",
            118.9377,
            0,
        ],
        1728007500: [
            0.29941,
            0,
            0.29941,
            0,
            23.12471,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4754,
            0,
            47.57798,
            0,
            0.78853,
            0,
            18.23344,
            0,
            -0.11256,
            0,
            0,
            0,
            29.32495,
            0,
            13.37063,
            "<D",
            118.4793,
            0,
        ],
        1728007560: [
            0.18835,
            0,
            0.18835,
            0,
            24.76308,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4797,
            0,
            46.41591,
            0,
            0.76726,
            0,
            18.23693,
            0,
            -0.12911,
            0,
            0,
            0,
            29.32493,
            0,
            13.3101,
            "<D",
            118.8227,
            0,
        ],
        1728007620: [
            0.14855,
            0,
            0.14855,
            0,
            25.37873,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4711,
            0,
            39.5212,
            0,
            0.77906,
            0,
            18.1549,
            0,
            -0.16885,
            0,
            0,
            0,
            29.32476,
            0,
            12.69083,
            "<D",
            118.716,
            0,
        ],
        1728007680: [
            0.24282,
            0,
            0.24282,
            0,
            25.89695,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4797,
            0,
            31.26438,
            0,
            0.67251,
            0,
            18.13983,
            0,
            -0.21851,
            0,
            0,
            0,
            29.32476,
            0,
            14.68378,
            "<D",
            119.275,
            0,
        ],
        1728007740: [
            -0.03192,
            0,
            -0.03192,
            0,
            26.37018,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4883,
            0,
            34.66875,
            0,
            0.7614,
            0,
            18.03942,
            0,
            -0.14236,
            0,
            0,
            0,
            29.32491,
            0,
            11.90942,
            "<D",
            119.482,
            0,
        ],
        1728007800: [
            0.02561,
            0,
            0.02561,
            0,
            28.28229,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4883,
            0,
            36.69889,
            0,
            0.85462,
            0,
            18.0465,
            0,
            -0.16553,
            0,
            0,
            0,
            29.32488,
            0,
            12.23554,
            "<D",
            119.0288,
            0,
        ],
        1728007860: [
            0.25808,
            0,
            0.25808,
            0,
            27.93559,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5012,
            0,
            35.602,
            0,
            0.73775,
            0,
            18.07256,
            0,
            -0.18871,
            0,
            0,
            0,
            29.325,
            0,
            12.61774,
            "<D",
            118.5936,
            0,
        ],
        1728007920: [
            0.03255,
            0,
            0.03255,
            0,
            27.88764,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5055,
            0,
            37.87537,
            0,
            0.81599,
            0,
            18.05778,
            0,
            -0.1854,
            0,
            0,
            0,
            29.32537,
            0,
            13.69016,
            "<D",
            118.2362,
            0,
        ],
        1728007980: [
            0.30897,
            0,
            0.30897,
            0,
            27.98161,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5098,
            0,
            43.47843,
            0,
            0.87574,
            0,
            18.11553,
            0,
            -0.17215,
            0,
            0,
            0,
            29.32596,
            0,
            13.10989,
            "<D",
            119.4669,
            0,
        ],
        1728008040: [
            0.33297,
            0,
            0.33297,
            0,
            27.6159,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.527,
            0,
            44.98381,
            0,
            0.91615,
            0,
            18.09677,
            0,
            -0.08938,
            0,
            0,
            0,
            29.32524,
            0,
            11.68861,
            "<D",
            120.1949,
            0,
        ],
        1728008100: [
            0.23049,
            0,
            0.23049,
            0,
            27.57221,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5356,
            0,
            44.05371,
            0,
            0.87549,
            0,
            18.11293,
            0,
            -0.07614,
            0,
            0,
            0,
            29.32703,
            0,
            15.15434,
            "<D",
            120.5671,
            0,
        ],
        1728008160: [
            0.09345,
            0,
            0.09345,
            0,
            27.48466,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.527,
            0,
            41.16805,
            0,
            0.86532,
            0,
            18.12803,
            0,
            -0.06621,
            0,
            0,
            0,
            29.32665,
            0,
            12.29524,
            "<D",
            120.2225,
            0,
        ],
        1728008220: [
            0.15966,
            0,
            0.15966,
            0,
            27.20949,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5313,
            0,
            43.51383,
            0,
            0.65329,
            0,
            18.15754,
            0,
            -0.0927,
            0,
            0,
            0,
            29.32737,
            0,
            12.82571,
            "<D",
            120.791,
            0,
        ],
        1728008280: [
            0.08744,
            0,
            0.08744,
            0,
            27.70176,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5098,
            0,
            20.99299,
            0,
            0.44115,
            0,
            18.14901,
            0,
            -0.05297,
            0,
            0,
            0,
            29.32736,
            0,
            14.16298,
            "<D",
            120.7648,
            0,
        ],
        1728008340: [
            0.14323,
            0,
            0.14323,
            0,
            24.9586,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5098,
            0,
            55.19136,
            0,
            0.4847,
            0,
            18.20873,
            0,
            -0.03973,
            0,
            0,
            0,
            29.32753,
            0,
            15.31108,
            "<D",
            120.2924,
            0,
        ],
        1728008400: [
            0.386,
            0,
            0.386,
            0,
            25.27775,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.484,
            0,
            70.63612,
            0,
            0.7913,
            0,
            18.14003,
            0,
            -0.23505,
            0,
            0,
            0,
            29.32744,
            0,
            12.79517,
            "<D",
            119.9968,
            0,
        ],
        1728008460: [
            0.33407,
            0,
            0.33407,
            0,
            25.20196,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4754,
            0,
            76.21159,
            0,
            0.75368,
            0,
            18.15851,
            0,
            -0.23505,
            0,
            0,
            0,
            29.32701,
            0,
            11.14464,
            "<D",
            120.2064,
            0,
        ],
        1728008520: [
            0.26153,
            0,
            0.26153,
            0,
            25.78401,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4668,
            0,
            73.81504,
            0,
            0.72912,
            0,
            18.18458,
            0,
            -0.25823,
            0,
            0,
            0,
            29.32642,
            0,
            10.20769,
            "<D",
            120.4231,
            0,
        ],
        1728008580: [
            0.15828,
            0,
            0.15828,
            0,
            27.04136,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4496,
            0,
            101.0049,
            0,
            0.4683,
            0,
            18.18307,
            0,
            -0.25823,
            0,
            0,
            0,
            29.32637,
            0,
            13.49576,
            "<D",
            119.9543,
            0,
        ],
        1728008640: [
            0.201,
            0,
            0.201,
            0,
            29.80212,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4453,
            0,
            93.48741,
            0,
            0.4612,
            0,
            18.20796,
            0,
            -0.28141,
            0,
            0,
            0,
            29.32759,
            0,
            13.39568,
            "<D",
            119.9179,
            0,
        ],
        1728008700: [
            0.24226,
            0,
            0.24226,
            0,
            29.67557,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.46251,
            0,
            93.41718,
            0,
            0.55596,
            0,
            18.2202,
            0,
            -0.25492,
            0,
            0,
            0,
            29.32769,
            0,
            12.40975,
            "<D",
            120.105,
            0,
        ],
        1728008760: [
            0.23695,
            0,
            0.23695,
            0,
            28.32942,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4539,
            0,
            77.92615,
            0,
            0.6809,
            0,
            18.1999,
            0,
            -0.25492,
            0,
            0,
            0,
            29.32799,
            0,
            14.46492,
            "<D",
            119.9611,
            0,
        ],
        1728008820: [
            0.29685,
            0,
            0.29685,
            0,
            28.78328,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4453,
            0,
            68.25489,
            0,
            0.92519,
            0,
            18.21195,
            0,
            -0.21189,
            0,
            0,
            0,
            29.32798,
            0,
            13.42313,
            "<D",
            120.0947,
            0,
        ],
        1728008880: [
            0.20025,
            0,
            0.20025,
            0,
            28.91677,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.46251,
            0,
            70.27618,
            0,
            0.92591,
            0,
            18.16617,
            0,
            -0.20195,
            0,
            0,
            0,
            29.32816,
            0,
            14.45996,
            "<D",
            120.2586,
            0,
        ],
        1728008940: [
            0.21924,
            0,
            0.21924,
            0,
            26.11363,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4711,
            0,
            76.65833,
            0,
            0.71939,
            0,
            18.12717,
            0,
            -0.22513,
            0,
            0,
            0,
            29.3283,
            0,
            12.38964,
            "<D",
            120.4817,
            0,
        ],
        1728009000: [
            0.14212,
            0,
            0.14212,
            0,
            26.04207,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4711,
            0,
            77.09876,
            0,
            0.52538,
            0,
            18.08413,
            0,
            -0.22844,
            0,
            0,
            0,
            29.3283,
            0,
            13.54228,
            "<D",
            120.7085,
            0,
        ],
        1728009060: [
            0.2326,
            0,
            0.2326,
            0,
            22.62536,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4969,
            0,
            78.23834,
            0,
            0.61449,
            0,
            18.06098,
            0,
            -0.22844,
            0,
            0,
            0,
            29.32932,
            0,
            13.29417,
            "<D",
            120.8497,
            0,
        ],
        1728009120: [
            0.17659,
            0,
            0.17659,
            0,
            22.55105,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5055,
            0,
            81.74746,
            0,
            0.57087,
            0,
            18.04215,
            0,
            -0.2185,
            0,
            0,
            0,
            29.33098,
            0,
            12.81232,
            "<D",
            120.8786,
            0,
        ],
        1728009180: [
            0.23421,
            0,
            0.23421,
            0,
            22.16727,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4969,
            0,
            66.4918,
            0,
            0.40589,
            0,
            18.04541,
            0,
            -0.23175,
            0,
            0,
            0,
            29.33157,
            0,
            14.12748,
            "<D",
            121.059,
            0,
        ],
        1728009240: [
            0.20963,
            0,
            0.20963,
            0,
            23.566,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5141,
            0,
            61.73494,
            0,
            0.22524,
            0,
            18.0393,
            0,
            -0.15891,
            0,
            0,
            0,
            29.33178,
            0,
            12.78813,
            "<D",
            120.4723,
            0,
        ],
        1728009300: [
            0.27435,
            0,
            0.27435,
            0,
            26.24963,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.527,
            0,
            56.80597,
            0,
            0.24464,
            0,
            18.03196,
            0,
            -0.08607,
            0,
            0,
            0,
            29.33175,
            0,
            13.08883,
            "<D",
            120.6894,
            0,
        ],
        1728009360: [
            0.32172,
            0,
            0.32172,
            0,
            23.95749,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5356,
            0,
            60.25503,
            0,
            0.3118,
            0,
            18.00428,
            0,
            -0.09601,
            0,
            0,
            0,
            29.3316,
            0,
            14.75751,
            "<D",
            120.6571,
            0,
        ],
        1728009420: [
            0.12922,
            0,
            0.12922,
            0,
            23.77448,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.52271,
            0,
            79.83913,
            0,
            0.2947,
            0,
            18.00963,
            0,
            -0.13243,
            0,
            0,
            0,
            29.33158,
            0,
            13.42201,
            "<D",
            121.1266,
            0,
        ],
        1728009480: [
            0.26159,
            0,
            0.26159,
            0,
            25.9367,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.51841,
            0,
            57.77571,
            0,
            0.33066,
            0,
            18.02138,
            0,
            -0.0927,
            0,
            0,
            0,
            29.33251,
            0,
            12.92323,
            "<D",
            121.3311,
            0,
        ],
        1728009540: [
            0.20054,
            0,
            0.20054,
            0,
            27.91469,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4969,
            0,
            48.77295,
            0,
            0.32844,
            0,
            18.0286,
            0,
            -0.04966,
            0,
            0,
            0,
            29.33224,
            0,
            13.64097,
            "<D",
            121.4332,
            0,
        ],
        1728009600: [
            0.20769,
            0,
            0.20769,
            0,
            28.60847,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4969,
            0,
            55.03549,
            0,
            0.38485,
            0,
            18.03545,
            0,
            -0.08277,
            0,
            0,
            0,
            29.33261,
            0,
            12.52317,
            "<D",
            121.3277,
            0,
        ],
        1728009660: [
            0.27605,
            0,
            0.27605,
            0,
            29.2096,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4883,
            0,
            58.90953,
            0,
            0.43705,
            0,
            18.0494,
            0,
            -0.04635,
            0,
            0,
            0,
            29.33294,
            0,
            15.11797,
            "<D",
            121.1916,
            0,
        ],
        1728009720: [
            0.21309,
            0,
            0.21309,
            0,
            30.09348,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4969,
            0,
            52.99947,
            0,
            0.55658,
            0,
            18.02445,
            0,
            -0.05628,
            0,
            0,
            0,
            29.33278,
            0,
            12.31046,
            "<D",
            121.424,
            0,
        ],
        1728009780: [
            0.05076,
            0,
            0.05076,
            0,
            30.10051,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4711,
            0,
            41.54357,
            0,
            0.4969,
            0,
            17.98306,
            0,
            -0.10594,
            0,
            0,
            0,
            29.33364,
            0,
            13.88133,
            "<D",
            121.3946,
            0,
        ],
        1728009840: [
            0.32022,
            0,
            0.32022,
            0,
            29.51148,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4883,
            0,
            44.24398,
            0,
            0.41056,
            0,
            17.90833,
            0,
            -0.18871,
            0,
            0,
            0,
            29.33258,
            0,
            14.23695,
            "<D",
            121.0392,
            0,
        ],
        1728009900: [
            0.18097,
            "<C",
            0.18097,
            "<C",
            29.88297,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4754,
            0,
            42.9176,
            0,
            0.46237,
            0,
            17.78721,
            0,
            -0.15229,
            0,
            0,
            0,
            29.33239,
            0,
            12.916,
            "<D",
            121.4355,
            0,
        ],
        1728009960: [
            123.9467,
            "<C",
            123.9467,
            "<C",
            92.95986,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.484,
            0,
            31.15691,
            0,
            0.36835,
            0,
            17.78485,
            0,
            -0.13905,
            0,
            0,
            0,
            29.33325,
            0,
            14.05695,
            "<D",
            121.1538,
            0,
        ],
        1728010020: [
            192.8957,
            "<C",
            192.8957,
            "<C",
            181.6772,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4969,
            0,
            66.17423,
            0,
            0.34711,
            0,
            17.83503,
            0,
            -0.16884,
            0,
            0,
            0,
            29.33405,
            0,
            12.80578,
            "<D",
            121.2161,
            0,
        ],
        1728010080: [
            196.4777,
            "<C",
            196.4777,
            "<C",
            193.4489,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5012,
            0,
            46.76081,
            0,
            0.28257,
            0,
            17.95906,
            0,
            -0.18539,
            0,
            0,
            0,
            29.33361,
            0,
            13.77464,
            "<D",
            120.8207,
            0,
        ],
        1728010140: [
            197.5247,
            "<C",
            197.5247,
            "<C",
            196.4008,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5313,
            0,
            66.21463,
            0,
            0.56806,
            0,
            18.04444,
            0,
            -0.14898,
            0,
            0,
            0,
            29.33313,
            0,
            14.08798,
            "<D",
            120.7228,
            0,
        ],
        1728010200: [
            197.7615,
            "<C",
            197.7615,
            "<C",
            197.8699,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            59.77197,
            0,
            0.69003,
            0,
            18.07596,
            0,
            -0.13243,
            0,
            0,
            0,
            29.33323,
            0,
            14.06157,
            "<D",
            121.3261,
            0,
        ],
        1728010260: [
            198.3334,
            "<C",
            198.3334,
            "<C",
            198.7568,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            77.88654,
            0,
            0.83454,
            0,
            18.0637,
            0,
            -0.17546,
            0,
            0,
            0,
            29.33244,
            0,
            14.54296,
            "<D",
            121.0277,
            0,
        ],
        1728010320: [
            198.3516,
            "<C",
            198.3516,
            "<C",
            198.88,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            79.24682,
            0,
            0.70526,
            0,
            18.06789,
            0,
            -0.05959,
            0,
            0,
            0,
            29.33222,
            0,
            15.74612,
            "<D",
            119.9432,
            0,
        ],
        1728010380: [
            198.2278,
            "<C",
            198.2278,
            "<C",
            199.0992,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            81.97286,
            0,
            0.49399,
            0,
            18.03185,
            0,
            -0.06621,
            0,
            0,
            0,
            29.33327,
            0,
            13.17835,
            "<D",
            120.0184,
            0,
        ],
        1728010440: [
            198.6048,
            "<C",
            198.6048,
            "<C",
            199.2438,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            73.13036,
            0,
            0.68852,
            0,
            18.11162,
            0,
            -0.11257,
            0,
            0,
            0,
            29.33391,
            0,
            12.66535,
            "<D",
            120.1751,
            0,
        ],
        1728010500: [
            199.1397,
            "<C",
            199.1397,
            "<C",
            199.3043,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            75.94755,
            0,
            0.70436,
            0,
            18.12416,
            0,
            -0.10925,
            0,
            0,
            0,
            29.33317,
            0,
            13.21015,
            "<D",
            120.7688,
            0,
        ],
        1728010560: [
            198.8977,
            "<C",
            198.8977,
            "<C",
            199.938,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            108.0076,
            0,
            0.56728,
            0,
            18.11146,
            0,
            -0.07946,
            0,
            0,
            0,
            29.33397,
            0,
            11.40216,
            "<D",
            122.0567,
            0,
        ],
        1728010620: [
            198.9189,
            "<C",
            198.9189,
            "<C",
            199.787,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            98.7877,
            0,
            0.87977,
            0,
            18.08271,
            0,
            -0.08277,
            0,
            0,
            0,
            29.33445,
            0,
            13.78527,
            "<D",
            122.1464,
            0,
        ],
        1728010680: [
            115.0807,
            "<C",
            115.0807,
            "<C",
            149.6812,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            142.0719,
            0,
            0.36937,
            0,
            18.01847,
            0,
            -0.08608,
            0,
            0,
            0,
            29.33483,
            0,
            13.23435,
            "<D",
            121.7678,
            0,
        ],
        1728010740: [
            62.11118,
            "<C",
            62.11118,
            "<C",
            62.83544,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            102.903,
            0,
            0.64482,
            0,
            17.99947,
            0,
            -0.05628,
            0,
            0,
            0,
            29.33465,
            0,
            13.35918,
            "<D",
            121.8829,
            0,
        ],
        1728010800: [
            60.75719,
            "<C",
            60.75719,
            "<C",
            61.18593,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            80.21463,
            0,
            0.56609,
            0,
            18.10047,
            0,
            -0.06621,
            0,
            0,
            0,
            29.33483,
            0,
            15.02964,
            "<D",
            121.6923,
            0,
        ],
        1728010860: [
            60.30104,
            "<C",
            60.30104,
            "<C",
            60.66884,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            78.4577,
            0,
            0.74983,
            0,
            18.08832,
            0,
            -0.04966,
            0,
            0,
            0,
            29.33483,
            0,
            13.94038,
            "<D",
            121.6002,
            0,
        ],
        1728010920: [
            60.22298,
            "<C",
            60.22298,
            "<C",
            60.19764,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            93.04187,
            0,
            0.50651,
            0,
            18.05908,
            0,
            -0.10925,
            0,
            0,
            0,
            29.3355,
            0,
            12.54965,
            "<D",
            121.4063,
            0,
        ],
        1728010980: [
            60.36163,
            "<C",
            60.36163,
            "<C",
            60.49942,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            89.66328,
            0,
            0.65919,
            0,
            18.1773,
            0,
            -0.16222,
            0,
            0,
            0,
            29.33669,
            0,
            14.4429,
            "<D",
            121.4703,
            0,
        ],
        1728011040: [
            60.05814,
            "<C",
            60.05814,
            "<C",
            60.44935,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            108.4771,
            0,
            0.48211,
            0,
            18.09808,
            0,
            -0.16222,
            0,
            0,
            0,
            29.33768,
            0,
            14.72,
            "<D",
            121.2183,
            0,
        ],
        1728011100: [
            60.34384,
            "<C",
            60.34384,
            "<C",
            60.40122,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            126.9753,
            0,
            0.25078,
            0,
            18.07366,
            0,
            -0.13243,
            0,
            0,
            0,
            29.33852,
            0,
            14.80672,
            "<D",
            120.7936,
            0,
        ],
        1728011160: [
            60.48226,
            "<C",
            60.48226,
            "<C",
            60.55213,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            102.8624,
            0,
            0.36571,
            0,
            18.13347,
            0,
            -0.08276,
            0,
            0,
            0,
            29.33818,
            0,
            13.08734,
            "<D",
            120.0448,
            0,
        ],
        1728011220: [
            60.14797,
            "<C",
            60.14797,
            "<C",
            60.46188,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            120.9235,
            0,
            0.36652,
            0,
            18.15391,
            0,
            -0.06952,
            0,
            0,
            0,
            29.33867,
            0,
            13.84836,
            "<D",
            120.2332,
            0,
        ],
        1728011280: [
            60.51764,
            "<C",
            60.51764,
            "<C",
            60.50843,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            99.17606,
            0,
            0.2183,
            0,
            18.16555,
            0,
            -0.11587,
            0,
            0,
            0,
            29.33874,
            0,
            11.06302,
            "<D",
            120.311,
            0,
        ],
        1728011340: [
            16.29459,
            "<C",
            16.29459,
            "<C",
            27.87605,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            103.8249,
            0,
            0.22478,
            0,
            18.17839,
            0,
            -0.06621,
            0,
            0,
            0,
            29.3397,
            0,
            17.81758,
            "<D",
            120.4173,
            0,
        ],
        1728011400: [
            -0.05163,
            "<C",
            -0.05163,
            "<C",
            0.19844,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            92.98563,
            0,
            0.4362,
            0,
            18.18976,
            0,
            -0.04304,
            0,
            0,
            0,
            29.34123,
            0,
            12.89087,
            "<D",
            119.9612,
            0,
        ],
        1728011460: [
            -0.12602,
            "<C",
            -0.12602,
            "<C",
            0.0339,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9699,
            0,
            97.22961,
            0,
            0.35611,
            0,
            18.21407,
            0,
            -0.0629,
            0,
            0,
            0,
            29.34213,
            0,
            11.39203,
            "<D",
            119.6555,
            0,
        ],
        1728011520: [
            0.42188,
            "<C",
            0.42188,
            "<C",
            -0.02034,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9742,
            0,
            90.8457,
            0,
            0.24823,
            0,
            18.21765,
            0,
            -0.12581,
            0,
            0,
            0,
            29.34215,
            0,
            12.63935,
            "<D",
            120.5795,
            0,
        ],
        1728011580: [
            0.07174,
            "<C",
            0.07174,
            "<C",
            0.22525,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9828,
            0,
            92.86703,
            0,
            0.24245,
            0,
            18.22153,
            0,
            -0.13905,
            0,
            0,
            0,
            29.34215,
            0,
            14.06766,
            "<D",
            122.1824,
            0,
        ],
        1728011640: [
            -0.00158,
            "<C",
            -0.00158,
            "<C",
            0.02385,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            77.68325,
            0,
            0.34791,
            0,
            18.20981,
            0,
            -0.10594,
            0,
            0,
            0,
            29.34215,
            0,
            12.9367,
            "<D",
            121.7864,
            0,
        ],
        1728011700: [
            0.06125,
            "<C",
            0.06125,
            "<C",
            30.52461,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            86.81515,
            0,
            0.487,
            0,
            18.20267,
            0,
            -0.0927,
            0,
            0,
            0,
            29.34377,
            0,
            14.73212,
            "<D",
            121.6121,
            0,
        ],
        1728011760: [
            -0.00953,
            0,
            -0.00953,
            0,
            36.89394,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.00487,
            0,
            84.328,
            0,
            0.41374,
            0,
            18.21539,
            0,
            -0.06621,
            0,
            0,
            0,
            29.34303,
            0,
            17.42685,
            "<D",
            121.5058,
            0,
        ],
        1728011820: [
            0.18372,
            0,
            0.18372,
            0,
            33.94207,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.99598,
            0,
            104.1332,
            0,
            0.39212,
            0,
            18.17512,
            0,
            -0.03973,
            0,
            0,
            0,
            29.34411,
            0,
            12.66776,
            "<D",
            121.6516,
            0,
        ],
        1728011880: [
            0.26593,
            0,
            0.26593,
            0,
            33.7462,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            93.16719,
            0,
            0.46962,
            0,
            18.13979,
            0,
            -0.0629,
            0,
            0,
            0,
            29.34492,
            0,
            12.32886,
            "<D",
            121.6638,
            0,
        ],
        1728011940: [
            0.21849,
            0,
            0.21849,
            0,
            33.55416,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            74.86789,
            0,
            0.35453,
            0,
            18.12738,
            0,
            -0.1556,
            0,
            0,
            0,
            29.34686,
            0,
            13.2886,
            "<D",
            121.6687,
            0,
        ],
        1728012000: [
            0.35577,
            0,
            0.35577,
            0,
            33.02097,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.02292,
            0,
            89.47881,
            0,
            0.36565,
            0,
            18.05494,
            0,
            -0.1887,
            0,
            0,
            0,
            29.34681,
            0,
            15.98163,
            "<D",
            121.2989,
            0,
        ],
        1728012060: [
            0.28838,
            0,
            0.28838,
            0,
            33.58513,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            53.54854,
            0,
            0.67667,
            0,
            18.0466,
            0,
            -0.20195,
            0,
            0,
            0,
            29.34687,
            0,
            12.20881,
            "<D",
            121.2753,
            0,
        ],
        1728012120: [
            0.20803,
            0,
            0.20803,
            0,
            29.9428,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            73.56607,
            0,
            0.43607,
            0,
            18.01306,
            0,
            -0.19202,
            0,
            0,
            0,
            29.34685,
            0,
            14.93441,
            "<D",
            121.4734,
            0,
        ],
        1728012180: [
            0.23019,
            0,
            0.23019,
            0,
            29.0564,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            45.98594,
            0,
            0.49695,
            0,
            17.97899,
            0,
            -0.16553,
            0,
            0,
            0,
            29.34691,
            0,
            12.7012,
            "<D",
            121.2101,
            0,
        ],
        1728012240: [
            0.19653,
            0,
            0.19653,
            0,
            27.19757,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.06876,
            0,
            47.42901,
            0,
            0.50255,
            0,
            17.97556,
            0,
            -0.14236,
            0,
            0,
            0,
            29.34686,
            0,
            15.4883,
            "<D",
            121.2807,
            0,
        ],
        1728012300: [
            0.24356,
            0,
            0.24356,
            0,
            28.88849,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.08251,
            0,
            39.693,
            0,
            0.47024,
            0,
            18.01487,
            0,
            -0.07283,
            0,
            0,
            0,
            29.3465,
            0,
            12.49284,
            "<D",
            121.3342,
            0,
        ],
        1728012360: [
            0.17717,
            0,
            0.17717,
            0,
            28.53402,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.09626,
            0,
            56.79528,
            0,
            0.41701,
            0,
            18.05411,
            0,
            -0.06952,
            0,
            0,
            0,
            29.34642,
            0,
            14.99825,
            "<D",
            121.6393,
            0,
        ],
        1728012420: [
            0.01917,
            0,
            0.01917,
            0,
            27.50976,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.1146,
            0,
            45.14592,
            0,
            0.38235,
            0,
            18.08208,
            0,
            -0.0629,
            0,
            0,
            0,
            29.3463,
            0,
            14.72353,
            "<D",
            121.6185,
            0,
        ],
        1728012480: [
            0.18771,
            0,
            0.18771,
            0,
            30.17328,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.12376,
            0,
            62.91225,
            0,
            0.47267,
            0,
            18.08856,
            0,
            -0.08608,
            0,
            0,
            0,
            29.34631,
            0,
            14.54051,
            "<D",
            120.8512,
            0,
        ],
        1728012540: [
            0.05911,
            0,
            0.05911,
            0,
            27.10605,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.12835,
            0,
            70.94324,
            0,
            0.43449,
            0,
            18.10851,
            0,
            -0.12581,
            0,
            0,
            0,
            29.34781,
            0,
            14.60877,
            "<D",
            120.6431,
            0,
        ],
        1728012600: [
            0.26851,
            0,
            0.26851,
            0,
            28.14375,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.13293,
            0,
            76.18497,
            0,
            0.3018,
            0,
            18.08206,
            0,
            -0.11257,
            0,
            0,
            0,
            29.34781,
            0,
            14.56366,
            "<D",
            119.045,
            0,
        ],
        1728012660: [
            0.25313,
            0,
            0.25313,
            0,
            26.64685,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.10085,
            0,
            52.13357,
            0,
            0.41947,
            0,
            18.11951,
            0,
            -0.21851,
            0,
            0,
            0,
            29.35011,
            0,
            13.37834,
            "<D",
            119.2185,
            0,
        ],
        1728012720: [
            0.2432,
            0,
            0.2432,
            0,
            30.15547,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.09167,
            0,
            42.53255,
            0,
            0.43098,
            0,
            18.13936,
            0,
            -0.22845,
            0,
            0,
            0,
            29.34796,
            0,
            15.29299,
            "<D",
            119.3023,
            0,
        ],
        1728012780: [
            0.07567,
            0,
            0.07567,
            0,
            30.5727,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.08709,
            0,
            45.71885,
            0,
            0.32806,
            0,
            18.15557,
            0,
            -0.16223,
            0,
            0,
            0,
            29.34808,
            0,
            13.87862,
            "<D",
            118.7887,
            0,
        ],
        1728012840: [
            0.06755,
            0,
            0.06755,
            0,
            32.28982,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.06876,
            0,
            39.24158,
            0,
            0.42008,
            0,
            18.16464,
            0,
            -0.11588,
            0,
            0,
            0,
            29.34888,
            0,
            13.78079,
            "<D",
            118.6673,
            0,
        ],
        1728012900: [
            0.46219,
            0,
            0.46219,
            0,
            30.69266,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.05959,
            0,
            39.91737,
            0,
            0.45996,
            0,
            18.18016,
            0,
            -0.13905,
            0,
            0,
            0,
            29.34899,
            0,
            13.29121,
            "<D",
            118.852,
            0,
        ],
        1728012960: [
            0.32239,
            0,
            0.32239,
            0,
            31.70787,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            29.35421,
            0,
            0.42563,
            0,
            18.1799,
            0,
            -0.06621,
            0,
            0,
            0,
            29.34955,
            0,
            12.94047,
            "<D",
            118.6069,
            0,
        ],
        1728013020: [
            0.24987,
            0,
            0.24987,
            0,
            31.32444,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04584,
            0,
            40.73655,
            0,
            0.4352,
            0,
            18.14539,
            0,
            -0.08607,
            0,
            0,
            0,
            29.34975,
            0,
            15.46626,
            "<D",
            118.4717,
            0,
        ],
        1728013080: [
            0.27542,
            0,
            0.27542,
            0,
            32.88013,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            24.01393,
            0,
            0.22374,
            0,
            18.10052,
            0,
            -0.13905,
            0,
            0,
            0,
            29.35005,
            0,
            15.31629,
            "<D",
            117.9405,
            0,
        ],
        1728013140: [
            0.36432,
            0,
            0.36432,
            0,
            33.27017,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.03209,
            0,
            19.92983,
            0,
            0.22529,
            0,
            18.10587,
            0,
            -0.17546,
            0,
            0,
            0,
            29.35019,
            0,
            13.58692,
            "<D",
            117.3355,
            0,
        ],
        1728013200: [
            0.20065,
            0,
            0.20065,
            0,
            33.58823,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            23.73089,
            0,
            0.22338,
            0,
            18.15559,
            0,
            -0.17547,
            0,
            0,
            0,
            29.35078,
            0,
            15.51808,
            "<D",
            116.9695,
            0,
        ],
        1728013260: [
            0.14186,
            0,
            0.14186,
            0,
            34.17598,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            36.62669,
            0,
            0.2237,
            0,
            18.13083,
            0,
            -0.08939,
            0,
            0,
            0,
            29.35066,
            0,
            13.91553,
            "<D",
            116.7655,
            0,
        ],
        1728013320: [
            0.26963,
            0,
            0.26963,
            0,
            33.85743,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.05042,
            0,
            41.44301,
            0,
            0.22236,
            0,
            18.22602,
            0,
            -0.09932,
            0,
            0,
            0,
            29.34978,
            0,
            17.07646,
            "<D",
            116.4865,
            0,
        ],
        1728013380: [
            0.19988,
            0,
            0.19988,
            0,
            34.17211,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            20.83567,
            0,
            0.21055,
            0,
            18.33187,
            0,
            -0.10594,
            0,
            0,
            0,
            29.34996,
            0,
            14.84818,
            "<D",
            116.382,
            0,
        ],
        1728013440: [
            0.27275,
            0,
            0.27275,
            0,
            33.10812,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            24.56781,
            0,
            0.21785,
            0,
            18.40848,
            0,
            -0.06621,
            0,
            0,
            0,
            29.34969,
            0,
            14.79644,
            "<D",
            115.8903,
            0,
        ],
        1728013500: [
            0.27069,
            0,
            0.27069,
            0,
            32.68894,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.05042,
            0,
            22.31517,
            0,
            0.33111,
            0,
            18.42391,
            0,
            -0.05628,
            0,
            0,
            0,
            29.34949,
            0,
            13.51883,
            "<D",
            114.442,
            0,
        ],
        1728013560: [
            0.126,
            0,
            0.126,
            0,
            33.24439,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.06876,
            0,
            33.14968,
            0,
            0.68948,
            0,
            18.38606,
            0,
            -0.06952,
            0,
            0,
            0,
            29.34958,
            0,
            16.54023,
            "<D",
            113.8432,
            0,
        ],
        1728013620: [
            0.23343,
            0,
            0.23343,
            0,
            31.64464,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.06876,
            0,
            36.82277,
            0,
            0.56363,
            0,
            18.29461,
            0,
            -0.05959,
            0,
            0,
            0,
            29.3496,
            0,
            13.56292,
            "<D",
            114.3146,
            0,
        ],
        1728013680: [
            0.20397,
            0,
            0.20397,
            0,
            31.25602,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.05959,
            0,
            43.96983,
            0,
            0.53717,
            0,
            18.20835,
            0,
            -0.18209,
            0,
            0,
            0,
            29.35007,
            0,
            12.45504,
            "<D",
            113.0723,
            0,
        ],
        1728013740: [
            0.05772,
            0,
            0.05772,
            0,
            31.15238,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.06417,
            0,
            62.69787,
            0,
            0.47528,
            0,
            18.24517,
            0,
            -0.20527,
            0,
            0,
            0,
            29.35015,
            0,
            14.71379,
            "<D",
            112.4847,
            0,
        ],
        1728013800: [
            0.20058,
            0,
            0.20058,
            0,
            27.458,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.06417,
            0,
            84.55941,
            0,
            0.40642,
            0,
            18.29141,
            0,
            -0.23838,
            0,
            0,
            0,
            29.35025,
            0,
            14.70889,
            "<D",
            114.0318,
            0,
        ],
        1728013860: [
            0.18472,
            0,
            0.18472,
            0,
            28.98967,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01834,
            0,
            70.67793,
            0,
            0.90759,
            0,
            18.35058,
            0,
            -0.23176,
            0,
            0,
            0,
            29.35024,
            0,
            15.57927,
            "<D",
            114.4527,
            0,
        ],
        1728013920: [
            0.21972,
            0,
            0.21972,
            0,
            28.06701,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            103.7999,
            0,
            0.56966,
            0,
            18.39905,
            0,
            -0.21189,
            0,
            0,
            0,
            29.3502,
            0,
            14.97915,
            "<D",
            114.3325,
            0,
        ],
        1728013980: [
            0.24132,
            0,
            0.24132,
            0,
            25.10395,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.98294,
            0,
            76.46175,
            0,
            0.49961,
            0,
            18.42279,
            0,
            -0.19865,
            0,
            0,
            0,
            29.35022,
            0,
            13.59648,
            "<D",
            114.0961,
            0,
        ],
        1728014040: [
            0.41129,
            0,
            0.41129,
            0,
            28.98084,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9699,
            0,
            86.86782,
            0,
            0.76218,
            0,
            18.39845,
            0,
            -0.20858,
            0,
            0,
            0,
            29.35022,
            0,
            15.72185,
            "<D",
            114.3076,
            0,
        ],
        1728014100: [
            -0.01332,
            0,
            -0.01332,
            0,
            26.78091,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.957,
            0,
            88.66161,
            0,
            0.35846,
            0,
            18.37759,
            0,
            -0.15561,
            0,
            0,
            0,
            29.35003,
            0,
            14.83665,
            "<D",
            114.0832,
            0,
        ],
        1728014160: [
            0.28858,
            0,
            0.28858,
            0,
            28.07895,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            76.72621,
            0,
            0.65189,
            0,
            18.34747,
            0,
            -0.08608,
            0,
            0,
            0,
            29.34963,
            0,
            13.48943,
            "<D",
            113.6693,
            0,
        ],
        1728014220: [
            0.27707,
            0,
            0.27707,
            0,
            33.24083,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9312,
            0,
            81.07204,
            0,
            0.43442,
            0,
            18.28117,
            0,
            -0.0629,
            0,
            0,
            0,
            29.35073,
            0,
            14.19208,
            "<D",
            113.2273,
            0,
        ],
        1728014280: [
            0.2051,
            0,
            0.2051,
            0,
            31.16392,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            111.0478,
            0,
            0.40428,
            0,
            18.20584,
            0,
            -0.0629,
            0,
            0,
            0,
            29.35153,
            0,
            15.7008,
            "<D",
            112.7571,
            0,
        ],
        1728014340: [
            0.28165,
            0,
            0.28165,
            0,
            30.97044,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            91.71685,
            0,
            0.42802,
            0,
            18.17879,
            0,
            -0.06621,
            0,
            0,
            0,
            29.35261,
            0,
            15.42966,
            "<D",
            112.9515,
            0,
        ],
        1728014400: [
            0.19696,
            0,
            0.19696,
            0,
            21.13279,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.914,
            0,
            83.51001,
            0,
            0.23541,
            0,
            18.15526,
            0,
            -0.09601,
            0,
            0,
            0,
            29.35286,
            0,
            15.22588,
            "<D",
            112.6521,
            0,
        ],
        1728014460: [
            0.34984,
            0,
            0.34984,
            0,
            32.34702,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            39.39516,
            0,
            0.18657,
            0,
            18.16457,
            0,
            -0.0927,
            0,
            0,
            0,
            29.35324,
            0,
            11.83388,
            "<D",
            112.2614,
            0,
        ],
        1728014520: [
            0.12246,
            0,
            0.12246,
            0,
            29.90355,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            46.52391,
            0,
            0.45836,
            0,
            18.15529,
            0,
            -0.03973,
            0,
            0,
            0,
            29.35341,
            0,
            15.50956,
            "<D",
            112.1282,
            0,
        ],
        1728014580: [
            0.18622,
            0,
            0.18622,
            0,
            32.69723,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            16.67672,
            0,
            0.36518,
            0,
            18.08896,
            0,
            -0.05959,
            0,
            0,
            0,
            29.35331,
            0,
            15.21878,
            "<D",
            111.5179,
            0,
        ],
        1728014640: [
            0.267,
            0,
            0.267,
            0,
            35.05177,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            45.28812,
            0,
            0.34837,
            0,
            17.93185,
            0,
            -0.07615,
            0,
            0,
            0,
            29.3535,
            0,
            15.10467,
            "<D",
            111.4739,
            0,
        ],
        1728014700: [
            0.07353,
            0,
            0.07353,
            0,
            34.49435,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            60.97767,
            0,
            0.30206,
            0,
            17.93421,
            0,
            -0.09932,
            0,
            0,
            0,
            29.35353,
            0,
            14.84471,
            "<D",
            111.1751,
            0,
        ],
        1728014760: [
            0.19671,
            0,
            0.19671,
            0,
            34.45375,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            28.36277,
            0,
            0.33004,
            0,
            17.99372,
            0,
            -0.17216,
            0,
            0,
            0,
            29.35364,
            0,
            13.2339,
            "<D",
            111.1792,
            0,
        ],
        1728014820: [
            0.32203,
            0,
            0.32203,
            0,
            34.98438,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            69.17561,
            0,
            0.26831,
            0,
            18.07379,
            0,
            -0.17547,
            0,
            0,
            0,
            29.35369,
            0,
            16.62941,
            "<D",
            111.2376,
            0,
        ],
        1728014880: [
            0.29503,
            0,
            0.29503,
            0,
            33.74588,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9441,
            0,
            115.5675,
            0,
            0.28119,
            0,
            18.20842,
            0,
            -0.20196,
            0,
            0,
            0,
            29.35484,
            0,
            16.49094,
            "<D",
            111.0109,
            0,
        ],
        1728014940: [
            0.35384,
            0,
            0.35384,
            0,
            36.35122,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            95.26472,
            0,
            0.45109,
            0,
            18.25042,
            0,
            -0.22513,
            0,
            0,
            0,
            29.35561,
            0,
            14.65486,
            "<D",
            110.5132,
            0,
        ],
        1728015000: [
            0.34089,
            0,
            0.34089,
            0,
            35.07888,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            86.14409,
            0,
            0.49044,
            0,
            18.22159,
            0,
            -0.22182,
            0,
            0,
            0,
            29.35611,
            0,
            13.6757,
            "<D",
            111.3691,
            0,
        ],
        1728015060: [
            0.11624,
            0,
            0.11624,
            0,
            35.27195,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            58.97168,
            0,
            0.54013,
            0,
            18.22256,
            0,
            -0.23506,
            0,
            0,
            0,
            29.3567,
            0,
            16.37756,
            "<D",
            112.1949,
            0,
        ],
        1728015120: [
            0.04543,
            0,
            0.04543,
            0,
            34.54171,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            52.29108,
            0,
            0.5507,
            0,
            18.26521,
            0,
            -0.24499,
            0,
            0,
            0,
            29.35666,
            0,
            13.80795,
            "<D",
            112.3946,
            0,
        ],
        1728015180: [
            0.03417,
            0,
            0.03417,
            0,
            32.7885,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            33.47525,
            0,
            0.51807,
            0,
            18.33139,
            0,
            -0.23837,
            0,
            0,
            0,
            29.35691,
            0,
            14.23086,
            "<D",
            112.1272,
            0,
        ],
        1728015240: [
            0.14878,
            0,
            0.14878,
            0,
            32.40724,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.871,
            0,
            66.75546,
            0,
            0.65565,
            0,
            18.39459,
            0,
            -0.24169,
            0,
            0,
            0,
            29.35712,
            0,
            17.54589,
            "<D",
            111.9657,
            0,
        ],
        1728015300: [
            0.25826,
            0,
            0.25826,
            0,
            29.45734,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            39.52184,
            0,
            0.40834,
            0,
            18.363,
            0,
            -0.22182,
            0,
            0,
            0,
            29.35685,
            0,
            13.99665,
            "<D",
            112.0952,
            0,
        ],
        1728015360: [
            0.40071,
            0,
            0.40071,
            0,
            30.94931,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            44.13902,
            0,
            0.30427,
            0,
            18.37528,
            0,
            -0.22513,
            0,
            0,
            0,
            29.3569,
            0,
            15.93256,
            "<D",
            111.8366,
            0,
        ],
        1728015420: [
            0.13034,
            0,
            0.13034,
            0,
            26.26967,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            71.0685,
            0,
            0.43896,
            0,
            18.36609,
            0,
            -0.19865,
            0,
            0,
            0,
            29.35707,
            0,
            14.69408,
            "<D",
            111.0435,
            0,
        ],
        1728015480: [
            0.16651,
            0,
            0.16651,
            0,
            28.88139,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            66.91173,
            0,
            0.4803,
            0,
            18.37889,
            0,
            -0.20196,
            0,
            0,
            0,
            29.3584,
            0,
            14.85135,
            "<D",
            111.0417,
            0,
        ],
        1728015540: [
            0.24341,
            0,
            0.24341,
            0,
            29.62181,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            85.54385,
            0,
            0.46631,
            0,
            18.3673,
            0,
            -0.17216,
            0,
            0,
            0,
            29.35849,
            0,
            14.57082,
            "<D",
            111.2053,
            0,
        ],
        1728015600: [
            0.26474,
            0,
            0.26474,
            0,
            31.063,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            106.7758,
            0,
            0.38088,
            0,
            18.31205,
            0,
            -0.21851,
            0,
            0,
            0,
            29.3599,
            0,
            16.85984,
            "<D",
            111.3675,
            0,
        ],
        1728015660: [
            0.27389,
            0,
            0.27389,
            0,
            33.8109,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            95.42802,
            0,
            0.34496,
            0,
            18.26833,
            0,
            -0.16885,
            0,
            0,
            0,
            29.35884,
            0,
            13.57738,
            "<D",
            111.2366,
            0,
        ],
        1728015720: [
            0.25289,
            0,
            0.25289,
            0,
            34.32417,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            75.7115,
            0,
            0.38196,
            0,
            18.2171,
            0,
            -0.19865,
            0,
            0,
            0,
            29.35899,
            0,
            14.60445,
            "<D",
            110.8246,
            0,
        ],
        1728015780: [
            0.11563,
            0,
            0.11563,
            0,
            34.22445,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            73.95033,
            0,
            0.22621,
            0,
            18.17101,
            0,
            -0.21189,
            0,
            0,
            0,
            29.35707,
            0,
            14.4427,
            "<D",
            110.608,
            0,
        ],
        1728015840: [
            0.11451,
            0,
            0.11451,
            0,
            33.90071,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            112.9727,
            0,
            0.21252,
            0,
            18.13584,
            0,
            -0.22844,
            0,
            0,
            0,
            29.35718,
            0,
            16.91567,
            "<D",
            110.4549,
            0,
        ],
        1728015900: [
            0.23422,
            0,
            0.23422,
            0,
            33.47615,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.871,
            0,
            157.4542,
            0,
            0.21495,
            0,
            18.09557,
            0,
            -0.22844,
            0,
            0,
            0,
            29.35723,
            0,
            15.2839,
            "<D",
            111.4007,
            0,
        ],
        1728015960: [
            0.12718,
            0,
            0.12718,
            0,
            33.04806,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            113.8334,
            0,
            0.15243,
            0,
            18.09147,
            0,
            -0.21189,
            0,
            0,
            0,
            29.35781,
            0,
            15.41247,
            "<D",
            110.9585,
            0,
        ],
        1728016020: [
            0.22615,
            0,
            0.22615,
            0,
            33.00824,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            41.07495,
            0,
            0.22373,
            0,
            18.07003,
            0,
            -0.19202,
            0,
            0,
            0,
            29.35871,
            0,
            14.63517,
            "<D",
            111.4195,
            0,
        ],
        1728016080: [
            0.21075,
            0,
            0.21075,
            0,
            33.01378,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            44.37607,
            0,
            0.22521,
            0,
            18.10262,
            0,
            -0.07283,
            0,
            0,
            0,
            29.35768,
            0,
            14.67649,
            "<D",
            110.6554,
            0,
        ],
        1728016140: [
            0.10756,
            0,
            0.10756,
            0,
            33.85512,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            74.77454,
            0,
            0.22581,
            0,
            18.11827,
            0,
            -0.08608,
            0,
            0,
            0,
            29.35844,
            0,
            15.68371,
            "<D",
            110.4277,
            0,
        ],
        1728016200: [
            0.27187,
            0,
            0.27187,
            0,
            33.91508,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            44.55257,
            0,
            0.26774,
            0,
            18.03168,
            0,
            -0.05959,
            0,
            0,
            0,
            29.35751,
            0,
            14.39126,
            "<D",
            111.7763,
            0,
        ],
        1728016260: [
            0.34183,
            0,
            0.34183,
            0,
            33.87569,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            63.47076,
            0,
            0.19308,
            0,
            18.00091,
            0,
            -0.04966,
            0,
            0,
            0,
            29.35904,
            0,
            14.45061,
            "<D",
            111.6729,
            0,
        ],
        1728016320: [
            0.2364,
            0,
            0.2364,
            0,
            33.99525,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            42.02086,
            0,
            0.20484,
            0,
            18.03769,
            0,
            -0.05628,
            0,
            0,
            0,
            29.35765,
            0,
            14.31793,
            "<D",
            111.8722,
            0,
        ],
        1728016380: [
            0.25338,
            0,
            0.25338,
            0,
            34.32969,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            57.21053,
            0,
            0.64864,
            0,
            18.05311,
            0,
            -0.0629,
            0,
            0,
            0,
            29.35985,
            0,
            15.42504,
            "<D",
            111.5335,
            0,
        ],
        1728016440: [
            0.20917,
            0,
            0.20917,
            0,
            34.10804,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            57.416,
            0,
            0.61887,
            0,
            18.08526,
            0,
            -0.10263,
            0,
            0,
            0,
            29.3583,
            0,
            13.68447,
            "<D",
            112.0173,
            0,
        ],
        1728016500: [
            0.27175,
            0,
            0.27175,
            0,
            31.578,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            109.1495,
            0,
            0.42096,
            0,
            18.09052,
            0,
            -0.15229,
            0,
            0,
            0,
            29.35976,
            0,
            16.07738,
            "<D",
            112.4011,
            0,
        ],
        1728016560: [
            0.33879,
            0,
            0.33879,
            0,
            32.7086,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            118.1558,
            0,
            0.4529,
            0,
            18.05932,
            0,
            -0.17878,
            0,
            0,
            0,
            29.35961,
            0,
            15.85116,
            "<D",
            112.372,
            0,
        ],
        1728016620: [
            0.36017,
            0,
            0.36017,
            0,
            32.58811,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            142.226,
            0,
            0.41267,
            0,
            18.05922,
            0,
            -0.13243,
            0,
            0,
            0,
            29.35917,
            0,
            17.29468,
            "<D",
            112.1104,
            0,
        ],
        1728016680: [
            0.17753,
            0,
            0.17753,
            0,
            33.26059,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            162.6575,
            0,
            0.4477,
            0,
            18.0741,
            0,
            -0.1854,
            0,
            0,
            0,
            29.35896,
            0,
            14.15832,
            "<D",
            111.9505,
            0,
        ],
        1728016740: [
            0.13239,
            0,
            0.13239,
            0,
            33.86935,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            168.0031,
            0,
            0.2535,
            0,
            18.0454,
            0,
            -0.15892,
            0,
            0,
            0,
            29.36001,
            0,
            15.64088,
            "<D",
            111.7273,
            0,
        ],
        1728016800: [
            0.25222,
            0,
            0.25222,
            0,
            34.15012,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            168.2452,
            0,
            0.22373,
            0,
            17.99968,
            0,
            -0.21189,
            0,
            0,
            0,
            29.35945,
            0,
            17.79877,
            "<D",
            111.3339,
            0,
        ],
        1728016860: [
            0.24974,
            0,
            0.24974,
            0,
            34.23292,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            168.3446,
            0,
            0.22373,
            0,
            17.95261,
            0,
            -0.20527,
            0,
            0,
            0,
            29.35955,
            0,
            15.07977,
            "<D",
            110.6159,
            0,
        ],
        1728016920: [
            0.09513,
            0,
            0.09513,
            0,
            33.4511,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            144.4005,
            0,
            0.22667,
            0,
            17.9084,
            0,
            -0.14898,
            0,
            0,
            0,
            29.36071,
            0,
            11.81064,
            "<D",
            111.0342,
            0,
        ],
        1728016980: [
            0.07014,
            0,
            0.07014,
            0,
            31.48355,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            139.8021,
            0,
            0.22373,
            0,
            17.90973,
            0,
            -0.18871,
            0,
            0,
            0,
            29.36077,
            0,
            16.52864,
            "<D",
            111.1151,
            0,
        ],
        1728017040: [
            0.22027,
            0,
            0.22027,
            0,
            30.77087,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            139.7119,
            0,
            0.22373,
            0,
            17.959,
            0,
            -0.10263,
            0,
            0,
            0,
            29.36067,
            0,
            16.60725,
            "<D",
            110.9522,
            0,
        ],
        1728017100: [
            0.30939,
            0,
            0.30939,
            0,
            32.15263,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            126.095,
            0,
            0.2094,
            0,
            18.09674,
            0,
            -0.05297,
            0,
            0,
            0,
            29.36035,
            0,
            14.83702,
            "<D",
            111.3928,
            0,
        ],
        1728017160: [
            0.23956,
            0,
            0.23956,
            0,
            33.34519,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            79.3933,
            0,
            0.22346,
            0,
            18.09935,
            0,
            -0.05297,
            0,
            0,
            0,
            29.36023,
            0,
            15.03973,
            "<D",
            111.6541,
            0,
        ],
        1728017220: [
            0.28555,
            0,
            0.28555,
            0,
            34.4885,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            74.22668,
            0,
            0.22336,
            0,
            18.04563,
            0,
            -0.05628,
            0,
            0,
            0,
            29.35996,
            0,
            13.04348,
            "<D",
            111.757,
            0,
        ],
        1728017280: [
            0.29759,
            0,
            0.29759,
            0,
            35.62074,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            69.04004,
            0,
            0.22373,
            0,
            17.96295,
            0,
            -0.07615,
            0,
            0,
            0,
            29.35819,
            0,
            15.34099,
            "<D",
            111.8741,
            0,
        ],
        1728017340: [
            0.29575,
            0,
            0.29575,
            0,
            35.00019,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            80.74001,
            0,
            0.22268,
            0,
            17.95828,
            0,
            -0.1821,
            0,
            0,
            0,
            29.35818,
            0,
            15.65096,
            "<D",
            111.1654,
            0,
        ],
        1728017400: [
            0.38508,
            0,
            0.38508,
            0,
            34.26136,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            74.89747,
            0,
            0.22373,
            0,
            17.87552,
            0,
            -0.2119,
            0,
            0,
            0,
            29.35704,
            0,
            14.7328,
            "<D",
            112.605,
            0,
        ],
        1728017460: [
            0.15069,
            0,
            0.15069,
            0,
            33.77734,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            76.10436,
            0,
            0.22371,
            0,
            17.97608,
            0,
            -0.20858,
            0,
            0,
            0,
            29.35678,
            0,
            14.12189,
            "<D",
            113.4647,
            0,
        ],
        1728017520: [
            0.31431,
            0,
            0.31431,
            0,
            32.47253,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            78.69897,
            0,
            0.22365,
            0,
            18.01587,
            0,
            -0.19865,
            0,
            0,
            0,
            29.35678,
            0,
            11.84537,
            "<D",
            114.2695,
            0,
        ],
        1728017580: [
            0.38688,
            0,
            0.38688,
            0,
            31.63692,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            85.45094,
            0,
            0.22264,
            0,
            18.04214,
            0,
            -0.20196,
            0,
            0,
            0,
            29.35692,
            0,
            13.0678,
            "<D",
            113.6492,
            0,
        ],
        1728017640: [
            0.31838,
            0,
            0.31838,
            0,
            31.43033,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            93.67293,
            0,
            0.22371,
            0,
            18.02819,
            0,
            -0.08277,
            0,
            0,
            0,
            29.3569,
            0,
            15.93136,
            "<D",
            113.4125,
            0,
        ],
        1728017700: [
            0.1298,
            0,
            0.1298,
            0,
            32.42415,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            100.7939,
            0,
            0.22313,
            0,
            18.00482,
            0,
            -0.04966,
            0,
            0,
            0,
            29.35678,
            0,
            16.0315,
            "<D",
            113.2605,
            0,
        ],
        1728017760: [
            0.12197,
            0,
            0.12197,
            0,
            33.36288,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            127.9804,
            0,
            0.22074,
            0,
            17.9402,
            0,
            -0.04966,
            0,
            0,
            0,
            29.35766,
            0,
            15.00229,
            "<D",
            112.903,
            0,
        ],
        1728017820: [
            0.21449,
            0,
            0.21449,
            0,
            33.15576,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            153.8398,
            0,
            0.22207,
            0,
            17.91743,
            0,
            -0.04304,
            0,
            0,
            0,
            29.35754,
            0,
            18.98598,
            "<D",
            112.1882,
            0,
        ],
        1728017880: [
            0.1281,
            0,
            0.1281,
            0,
            32.81513,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            168.6367,
            0,
            0.22246,
            0,
            17.9532,
            0,
            -0.05628,
            0,
            0,
            0,
            29.35777,
            0,
            15.71528,
            "<D",
            112.4799,
            0,
        ],
        1728017940: [
            0.28164,
            0,
            0.28164,
            0,
            32.58318,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            155.6008,
            0,
            0.2236,
            0,
            17.90146,
            0,
            -0.12912,
            0,
            0,
            0,
            29.35892,
            0,
            17.1879,
            "<D",
            112.2567,
            0,
        ],
        1728018000: [
            0.20272,
            0,
            0.20272,
            0,
            32.70523,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            152.9879,
            0,
            0.22373,
            0,
            17.89289,
            0,
            -0.1556,
            0,
            0,
            0,
            29.35745,
            0,
            15.08734,
            "<D",
            112.0692,
            0,
        ],
        1728018060: [
            0.24191,
            0,
            0.24191,
            0,
            32.69035,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            145.3242,
            0,
            0.20796,
            0,
            17.94606,
            0,
            -0.0927,
            0,
            0,
            0,
            29.35706,
            0,
            15.14756,
            "<D",
            111.6634,
            0,
        ],
        1728018120: [
            0.25093,
            0,
            0.25093,
            0,
            33.37011,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            77.01524,
            0,
            0.22383,
            0,
            17.95913,
            0,
            -0.09932,
            0,
            0,
            0,
            29.35705,
            0,
            16.15553,
            "<D",
            111.2952,
            0,
        ],
        1728018180: [
            0.26882,
            0,
            0.26882,
            0,
            33.10378,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            74.01953,
            0,
            0.53387,
            0,
            17.95886,
            0,
            -0.05959,
            0,
            0,
            0,
            29.35686,
            0,
            18.34485,
            "<D",
            111.534,
            0,
        ],
        1728018240: [
            0.323,
            0,
            0.323,
            0,
            33.259,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            58.38819,
            0,
            0.42887,
            0,
            17.93911,
            0,
            -0.07283,
            0,
            0,
            0,
            29.35691,
            0,
            14.69337,
            "<D",
            111.4081,
            0,
        ],
        1728018300: [
            0.22007,
            0,
            0.22007,
            0,
            33.75194,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            62.81263,
            0,
            0.30721,
            0,
            17.8927,
            0,
            -0.07614,
            0,
            0,
            0,
            29.35684,
            0,
            14.0641,
            "<D",
            111.1709,
            0,
        ],
        1728018360: [
            0.23308,
            0,
            0.23308,
            0,
            33.77078,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            90.28451,
            0,
            0.23966,
            0,
            17.83175,
            0,
            -0.06952,
            0,
            0,
            0,
            29.3569,
            0,
            16.43541,
            "<D",
            112.0268,
            0,
        ],
        1728018420: [
            0.24393,
            0,
            0.24393,
            0,
            33.82155,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            124.6684,
            0,
            0.35762,
            0,
            17.78823,
            0,
            -0.07284,
            0,
            0,
            0,
            29.35678,
            0,
            17.82687,
            "<D",
            112.3363,
            0,
        ],
        1728018480: [
            0.1523,
            0,
            0.1523,
            0,
            33.57338,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            139.7593,
            0,
            0.23069,
            0,
            17.72324,
            0,
            -0.10263,
            0,
            0,
            0,
            29.35716,
            0,
            14.80864,
            "<D",
            112.9636,
            0,
        ],
        1728018540: [
            0.15663,
            0,
            0.15663,
            0,
            33.08619,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            131.1609,
            0,
            0.27308,
            0,
            17.67947,
            0,
            -0.21189,
            0,
            0,
            0,
            29.35678,
            0,
            16.28358,
            "<D",
            112.6552,
            0,
        ],
        1728018600: [
            0.36026,
            0,
            0.36026,
            0,
            33.20547,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            130.7983,
            0,
            0.22608,
            0,
            17.61879,
            0,
            -0.23176,
            0,
            0,
            0,
            29.35678,
            0,
            15.01946,
            "<D",
            112.8761,
            0,
        ],
        1728018660: [
            0.27733,
            0,
            0.27733,
            0,
            33.35733,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            141.5715,
            0,
            0.2881,
            0,
            17.57755,
            0,
            -0.22514,
            0,
            0,
            0,
            29.35691,
            0,
            13.55213,
            "<D",
            112.2439,
            0,
        ],
        1728018720: [
            0.14151,
            0,
            0.14151,
            0,
            33.44803,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            150.9254,
            0,
            0.37096,
            0,
            17.52309,
            0,
            -0.1821,
            0,
            0,
            0,
            29.35706,
            0,
            15.47464,
            "<D",
            112.955,
            0,
        ],
        1728018780: [
            0.18356,
            0,
            0.18356,
            0,
            32.42305,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            145.1616,
            0,
            0.37795,
            0,
            17.47786,
            0,
            -0.19534,
            0,
            0,
            0,
            29.357,
            0,
            13.01189,
            "<D",
            113.0047,
            0,
        ],
        1728018840: [
            0.16979,
            0,
            0.16979,
            0,
            32.41491,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            140.1376,
            0,
            0.52908,
            0,
            17.44181,
            0,
            -0.19865,
            0,
            0,
            0,
            29.35689,
            0,
            14.39143,
            "<D",
            113.0826,
            0,
        ],
        1728018900: [
            0.29329,
            0,
            0.29329,
            0,
            32.22085,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            145.308,
            0,
            0.53111,
            0,
            17.40456,
            0,
            -0.245,
            0,
            0,
            0,
            29.35677,
            0,
            14.27495,
            "<D",
            112.7692,
            0,
        ],
        1728018960: [
            0.3199,
            0,
            0.3199,
            0,
            32.24606,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            138.095,
            0,
            0.50447,
            0,
            17.38044,
            0,
            -0.23507,
            0,
            0,
            0,
            29.35675,
            0,
            16.94636,
            "<D",
            112.8125,
            0,
        ],
        1728019020: [
            0.05858,
            0,
            0.05858,
            0,
            32.59823,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            115.047,
            0,
            0.39667,
            0,
            17.34517,
            0,
            -0.22845,
            0,
            0,
            0,
            29.35691,
            0,
            12.69648,
            "<D",
            113.2004,
            0,
        ],
        1728019080: [
            0.34599,
            0,
            0.34599,
            0,
            30.42403,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            61.90076,
            0,
            0.21424,
            0,
            17.31743,
            0,
            -0.16223,
            0,
            0,
            0,
            29.35678,
            0,
            18.33267,
            "<D",
            112.7195,
            0,
        ],
        1728019140: [
            0.23838,
            0,
            0.23838,
            0,
            30.63666,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            106.1869,
            0,
            0.4567,
            0,
            17.33813,
            0,
            -0.09932,
            0,
            0,
            0,
            29.35673,
            0,
            15.67556,
            "<D",
            112.2703,
            0,
        ],
        1728019200: [
            0.19494,
            0,
            0.19494,
            0,
            32.5914,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            69.6595,
            0,
            0.4166,
            0,
            17.33916,
            0,
            -0.05297,
            0,
            0,
            0,
            29.35579,
            0,
            14.24821,
            "<D",
            112.1408,
            0,
        ],
        1728019260: [
            0.28674,
            0,
            0.28674,
            0,
            31.37482,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            65.73923,
            0,
            0.66779,
            0,
            17.30114,
            0,
            -0.05297,
            0,
            0,
            0,
            29.35427,
            0,
            14.03055,
            "<D",
            111.5476,
            0,
        ],
        1728019320: [
            0.32377,
            0,
            0.32377,
            0,
            34.32351,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            32.01229,
            0,
            0.57768,
            0,
            17.29867,
            0,
            -0.07284,
            0,
            0,
            0,
            29.35359,
            0,
            15.53032,
            "<D",
            112.0636,
            0,
        ],
        1728019380: [
            0.1382,
            0,
            0.1382,
            0,
            34.17467,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            33.30127,
            0,
            0.43423,
            0,
            17.30995,
            0,
            -0.06952,
            0,
            0,
            0,
            29.35315,
            0,
            15.60086,
            "<D",
            112.2679,
            0,
        ],
        1728019440: [
            0.26493,
            0,
            0.26493,
            0,
            34.2227,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            22.72531,
            0,
            0.46379,
            0,
            17.30063,
            0,
            -0.05959,
            0,
            0,
            0,
            29.3533,
            0,
            15.26797,
            "<D",
            112.5421,
            0,
        ],
        1728019500: [
            0.1652,
            0,
            0.1652,
            0,
            34.88811,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            36.86274,
            0,
            0.26447,
            0,
            17.31715,
            0,
            -0.10925,
            0,
            0,
            0,
            29.35327,
            0,
            16.85201,
            "<D",
            111.9518,
            0,
        ],
        1728019560: [
            0.17761,
            0,
            0.17761,
            0,
            35.52679,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            44.27812,
            0,
            0.18134,
            0,
            17.2889,
            0,
            -0.24498,
            0,
            0,
            0,
            29.35343,
            0,
            15.2144,
            "<D",
            112.0838,
            0,
        ],
        1728019620: [
            0.36606,
            0,
            0.36606,
            0,
            35.68507,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            121.3703,
            0,
            0.3986,
            0,
            17.26655,
            0,
            -0.27809,
            0,
            0,
            0,
            29.354,
            0,
            14.41084,
            "<D",
            112.0946,
            0,
        ],
        1728019680: [
            0.04063,
            0,
            0.04063,
            0,
            35.56898,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            121.5654,
            0,
            0.60783,
            0,
            17.30691,
            0,
            -0.25823,
            0,
            0,
            0,
            29.35578,
            0,
            17.61504,
            "<D",
            112.1849,
            0,
        ],
        1728019740: [
            0.0107,
            0,
            0.0107,
            0,
            35.69596,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            129.1066,
            0,
            0.52398,
            0,
            17.17185,
            0,
            -0.29466,
            0,
            0,
            0,
            29.35486,
            0,
            14.25802,
            "<D",
            112.0874,
            0,
        ],
        1728019800: [
            0.20024,
            0,
            0.20024,
            0,
            35.66491,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            103.2483,
            0,
            0.49666,
            0,
            17.09088,
            0,
            -0.39068,
            0,
            0,
            0,
            29.35614,
            0,
            14.94934,
            "<D",
            112.2765,
            0,
        ],
        1728019860: [
            0.34,
            0,
            0.34,
            0,
            34.83994,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            96.08749,
            0,
            0.45043,
            0,
            17.11984,
            0,
            -0.36419,
            0,
            0,
            0,
            29.35645,
            0,
            14.2123,
            "<D",
            111.9779,
            0,
        ],
        1728019920: [
            0.21961,
            0,
            0.21961,
            0,
            35.5104,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            117.7085,
            0,
            0.42567,
            0,
            17.26695,
            0,
            -0.3675,
            0,
            0,
            0,
            29.35664,
            0,
            15.08684,
            "<D",
            111.3784,
            0,
        ],
        1728019980: [
            0.26112,
            0,
            0.26112,
            0,
            34.99319,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            113.0249,
            0,
            0.5417,
            0,
            17.30363,
            0,
            -0.37412,
            0,
            0,
            0,
            29.35674,
            0,
            13.9107,
            "<D",
            112.2048,
            0,
        ],
        1728020040: [
            0.24887,
            0,
            0.24887,
            0,
            34.77699,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            94.30984,
            0,
            0.51465,
            0,
            17.29477,
            0,
            -0.2748,
            0,
            0,
            0,
            29.35697,
            0,
            14.46556,
            "<D",
            113.5762,
            0,
        ],
        1728020100: [
            0.10409,
            0,
            0.10409,
            0,
            35.28516,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            83.83807,
            0,
            0.39361,
            0,
            17.35237,
            0,
            -0.23176,
            0,
            0,
            0,
            29.35696,
            0,
            13.04617,
            "<D",
            113.9716,
            0,
        ],
        1728020160: [
            0.25011,
            0,
            0.25011,
            0,
            36.54182,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            77.77882,
            0,
            0.21404,
            0,
            17.35217,
            0,
            -0.25163,
            0,
            0,
            0,
            29.35678,
            0,
            14.7005,
            "<D",
            113.861,
            0,
        ],
        1728020220: [
            0.15137,
            0,
            0.15137,
            0,
            38.74375,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            91.62368,
            0,
            0.2065,
            0,
            17.36742,
            0,
            -0.20196,
            0,
            0,
            0,
            29.35678,
            0,
            14.97824,
            "<D",
            113.7467,
            0,
        ],
        1728020280: [
            0.29831,
            0,
            0.29831,
            0,
            38.99726,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            88.17546,
            0,
            0.39717,
            0,
            17.35344,
            0,
            -0.20196,
            0,
            0,
            0,
            29.35678,
            0,
            16.1049,
            "<D",
            113.8553,
            0,
        ],
        1728020340: [
            0.09423,
            0,
            0.09423,
            0,
            38.87716,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            68.39336,
            0,
            0.55343,
            0,
            17.22709,
            0,
            -0.08608,
            0,
            0,
            0,
            29.35678,
            0,
            15.58311,
            "<D",
            112.9077,
            0,
        ],
        1728020400: [
            0.29742,
            0,
            0.29742,
            0,
            37.84358,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            72.73491,
            0,
            0.66693,
            0,
            17.10123,
            0,
            -0.06621,
            0,
            0,
            0,
            29.35678,
            0,
            16.95378,
            "<D",
            112.6455,
            0,
        ],
        1728020460: [
            0.32901,
            0,
            0.32901,
            0,
            38.01266,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            77.19052,
            0,
            0.72524,
            0,
            16.96437,
            0,
            -0.0629,
            0,
            0,
            0,
            29.35684,
            0,
            11.70781,
            "<D",
            112.8277,
            0,
        ],
        1728020520: [
            0.11253,
            0,
            0.11253,
            0,
            34.5178,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            77.85934,
            0,
            0.70826,
            0,
            16.93421,
            0,
            -0.18209,
            0,
            0,
            0,
            29.35724,
            0,
            13.75174,
            "<D",
            112.7603,
            0,
        ],
        1728020580: [
            0.42731,
            0,
            0.42731,
            0,
            24.0975,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            86.11259,
            0,
            0.70924,
            0,
            16.89716,
            0,
            -0.20196,
            0,
            0,
            0,
            29.35732,
            0,
            17.44303,
            "<D",
            112.3229,
            0,
        ],
        1728020640: [
            0.25826,
            0,
            0.25826,
            0,
            29.11174,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            88.26514,
            0,
            0.70149,
            0,
            16.82501,
            0,
            -0.11588,
            0,
            0,
            0,
            29.3574,
            0,
            14.99363,
            "<D",
            112.3523,
            0,
        ],
        1728020700: [
            0.19354,
            0,
            0.19354,
            0,
            26.83161,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            98.05916,
            0,
            0.68201,
            0,
            16.90064,
            0,
            -0.13243,
            0,
            0,
            0,
            29.35744,
            0,
            13.83107,
            "<D",
            111.8853,
            0,
        ],
        1728020760: [
            0.26005,
            0,
            0.26005,
            0,
            19.71636,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            88.40155,
            0,
            0.71414,
            0,
            16.99031,
            0,
            -0.10263,
            0,
            0,
            0,
            29.35728,
            0,
            15.56728,
            "<D",
            112.2035,
            0,
        ],
        1728020820: [
            0.1359,
            0,
            0.1359,
            0,
            23.10952,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            89.47034,
            0,
            0.60562,
            0,
            17.07666,
            0,
            -0.11256,
            0,
            0,
            0,
            29.35727,
            0,
            13.80457,
            "<D",
            112.2054,
            0,
        ],
        1728020880: [
            0.36348,
            0,
            0.36348,
            0,
            25.65505,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            84.09989,
            0,
            0.52351,
            0,
            17.17824,
            0,
            -0.05297,
            0,
            0,
            0,
            29.35685,
            0,
            14.98144,
            "<D",
            112.0923,
            0,
        ],
        1728020940: [
            0.15581,
            0,
            0.15581,
            0,
            33.86182,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            87.61013,
            0,
            0.42096,
            0,
            17.19701,
            0,
            -0.04966,
            0,
            0,
            0,
            29.35679,
            0,
            15.38297,
            "<D",
            112.0758,
            0,
        ],
        1728021000: [
            0.21853,
            0,
            0.21853,
            0,
            36.43571,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            84.70442,
            0,
            0.48091,
            0,
            17.11688,
            0,
            -0.0629,
            0,
            0,
            0,
            29.35682,
            0,
            15.07133,
            "<D",
            111.9049,
            0,
        ],
        1728021060: [
            0.14366,
            0,
            0.14366,
            0,
            36.76443,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            96.87097,
            0,
            0.50783,
            0,
            17.05619,
            0,
            -0.11588,
            0,
            0,
            0,
            29.35678,
            0,
            15.39729,
            "<D",
            112.0718,
            0,
        ],
        1728021120: [
            0.13896,
            0,
            0.13896,
            0,
            37.71507,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            105.2195,
            0,
            0.46426,
            0,
            17.08447,
            0,
            -0.17548,
            0,
            0,
            0,
            29.35678,
            0,
            11.5738,
            "<D",
            112.2055,
            0,
        ],
        1728021180: [
            0.1834,
            0,
            0.1834,
            0,
            36.59949,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            98.37399,
            0,
            0.4824,
            0,
            17.15473,
            0,
            -0.20858,
            0,
            0,
            0,
            29.35678,
            0,
            15.17909,
            "<D",
            111.9746,
            0,
        ],
        1728021240: [
            0.22714,
            0,
            0.22714,
            0,
            35.5395,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            104.1712,
            0,
            0.57803,
            0,
            17.27602,
            0,
            -0.18541,
            0,
            0,
            0,
            29.35678,
            0,
            14.28808,
            "<D",
            111.3426,
            0,
        ],
        1728021300: [
            0.31155,
            0,
            0.31155,
            0,
            34.10504,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            115.9823,
            0,
            0.68035,
            0,
            17.30851,
            0,
            -0.2119,
            0,
            0,
            0,
            29.35678,
            0,
            16.07025,
            "<D",
            112.2921,
            0,
        ],
        1728021360: [
            0.21407,
            0,
            0.21407,
            0,
            32.15035,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            130.4243,
            0,
            0.53302,
            0,
            17.28097,
            0,
            -0.22183,
            0,
            0,
            0,
            29.35677,
            0,
            15.16447,
            "<D",
            113.3605,
            0,
        ],
        1728021420: [
            0.25044,
            0,
            0.25044,
            0,
            30.38276,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            118.7408,
            0,
            0.5958,
            0,
            17.22564,
            0,
            -0.1821,
            0,
            0,
            0,
            29.35678,
            0,
            11.33869,
            "<D",
            113.8586,
            0,
        ],
        1728021480: [
            0.34142,
            0,
            0.34142,
            0,
            27.44598,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            122.3329,
            0,
            0.60692,
            0,
            17.18201,
            0,
            -0.20527,
            0,
            0,
            0,
            29.35678,
            0,
            14.71335,
            "<D",
            113.8485,
            0,
        ],
        1728021540: [
            0.19422,
            0,
            0.19422,
            0,
            24.55151,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            139.6407,
            0,
            0.5358,
            0,
            17.15503,
            0,
            -0.10595,
            0,
            0,
            0,
            29.35673,
            0,
            11.85016,
            "<D",
            114.2328,
            0,
        ],
        1728021600: [
            0.28884,
            0,
            0.28884,
            0,
            20.83018,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            136.3368,
            0,
            0.51339,
            0,
            17.10995,
            0,
            -0.05628,
            0,
            0,
            0,
            29.35682,
            0,
            17.6636,
            "<D",
            113.3783,
            0,
        ],
        1728021660: [
            0.13516,
            0,
            0.13516,
            0,
            18.51259,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            133.2404,
            0,
            0.49102,
            0,
            17.05671,
            0,
            -0.05628,
            0,
            0,
            0,
            29.35687,
            0,
            14.70641,
            "<D",
            113.0095,
            0,
        ],
        1728021720: [
            0.3379,
            0,
            0.3379,
            0,
            17.50693,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            129.1463,
            0,
            0.42143,
            0,
            17.01294,
            0,
            -0.11919,
            0,
            0,
            0,
            29.35715,
            0,
            14.27086,
            "<D",
            112.8741,
            0,
        ],
        1728021780: [
            0.20417,
            0,
            0.20417,
            0,
            18.69356,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            132.3643,
            0,
            0.57466,
            0,
            16.97071,
            0,
            -0.22182,
            0,
            0,
            0,
            29.35699,
            0,
            14.81763,
            "<D",
            112.9571,
            0,
        ],
        1728021840: [
            0.07331,
            0,
            0.07331,
            0,
            19.27285,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            138.7732,
            0,
            0.66553,
            0,
            16.9467,
            0,
            -0.15229,
            0,
            0,
            0,
            29.3573,
            0,
            15.664,
            "<D",
            112.5021,
            0,
        ],
        1728021900: [
            0.15894,
            0,
            0.15894,
            0,
            22.69462,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            136.4886,
            0,
            0.67207,
            0,
            16.91788,
            0,
            -0.15892,
            0,
            0,
            0,
            29.35733,
            0,
            14.79908,
            "<D",
            112.6106,
            0,
        ],
        1728021960: [
            0.22943,
            0,
            0.22943,
            0,
            24.69364,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            134.6285,
            0,
            0.63027,
            0,
            16.88357,
            0,
            -0.19202,
            0,
            0,
            0,
            29.35729,
            0,
            17.70554,
            "<D",
            112.3294,
            0,
        ],
        1728022020: [
            0.12286,
            0,
            0.12286,
            0,
            27.13258,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            128.7663,
            0,
            0.57658,
            0,
            16.85922,
            0,
            -0.10263,
            0,
            0,
            0,
            29.35708,
            0,
            15.54136,
            "<D",
            112.5159,
            0,
        ],
        1728022080: [
            0.40087,
            0,
            0.40087,
            0,
            26.29892,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            112.8699,
            0,
            0.65248,
            0,
            16.83056,
            0,
            -0.14567,
            0,
            0,
            0,
            29.35712,
            0,
            13.87304,
            "<D",
            112.3904,
            0,
        ],
        1728022140: [
            0.28474,
            0,
            0.28474,
            0,
            27.81611,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            124.9644,
            0,
            0.73091,
            0,
            16.81684,
            0,
            -0.17547,
            0,
            0,
            0,
            29.35696,
            0,
            15.19914,
            "<D",
            112.3066,
            0,
        ],
        1728022200: [
            0.241,
            0,
            0.241,
            0,
            29.60167,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            118.6698,
            0,
            0.78643,
            0,
            16.82915,
            0,
            -0.15892,
            0,
            0,
            0,
            29.3564,
            0,
            12.83531,
            "<D",
            112.2863,
            0,
        ],
        1728022260: [
            0.20531,
            0,
            0.20531,
            0,
            33.25293,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            117.6915,
            0,
            0.55869,
            0,
            16.86924,
            0,
            -0.11256,
            0,
            0,
            0,
            29.35633,
            0,
            17.5611,
            "<D",
            112.1559,
            0,
        ],
        1728022320: [
            0.15749,
            0,
            0.15749,
            0,
            34.2994,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            137.1021,
            0,
            0.91526,
            0,
            16.94586,
            0,
            -0.09932,
            0,
            0,
            0,
            29.3557,
            0,
            14.06788,
            "<D",
            112.4823,
            0,
        ],
        1728022380: [
            0.23549,
            0,
            0.23549,
            0,
            35.14616,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            139.6667,
            0,
            0.95504,
            0,
            16.90753,
            0,
            -0.06621,
            0,
            0,
            0,
            29.35512,
            0,
            14.07357,
            "<D",
            112.3153,
            0,
        ],
        1728022440: [
            0.40455,
            0,
            0.40455,
            0,
            36.87465,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            139.681,
            0,
            0.68911,
            0,
            16.96673,
            0,
            -0.05297,
            0,
            0,
            0,
            29.35599,
            0,
            16.41315,
            "<D",
            112.4802,
            0,
        ],
        1728022500: [
            0.39664,
            0,
            0.39664,
            0,
            36.76679,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            138.7314,
            0,
            0.74055,
            0,
            16.9774,
            0,
            -0.14237,
            0,
            0,
            0,
            29.35564,
            0,
            15.43143,
            "<D",
            111.9424,
            0,
        ],
        1728022560: [
            0.14797,
            0,
            0.14797,
            0,
            37.43509,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            136.172,
            0,
            0.76292,
            0,
            17.0662,
            0,
            -0.20858,
            0,
            0,
            0,
            29.35662,
            0,
            15.17866,
            "<D",
            112.0136,
            0,
        ],
        1728022620: [
            0.18171,
            0,
            0.18171,
            0,
            36.9622,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            142.7106,
            0,
            0.90105,
            0,
            17.07532,
            0,
            -0.21521,
            0,
            0,
            0,
            29.35645,
            0,
            16.10118,
            "<D",
            112.9056,
            0,
        ],
        1728022680: [
            0.27823,
            0,
            0.27823,
            0,
            38.11,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            138.8537,
            0,
            0.80182,
            0,
            16.97556,
            0,
            -0.2119,
            0,
            0,
            0,
            29.35678,
            0,
            17.6469,
            "<D",
            113.3039,
            0,
        ],
        1728022740: [
            0.18386,
            0,
            0.18386,
            0,
            39.28817,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            130.8265,
            0,
            0.82818,
            0,
            16.92816,
            0,
            -0.22845,
            0,
            0,
            0,
            29.35678,
            0,
            14.24746,
            "<D",
            113.12,
            0,
        ],
        1728022800: [
            0.08392,
            0,
            0.08392,
            0,
            39.18165,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            125.023,
            0,
            0.74941,
            0,
            16.89413,
            0,
            -0.16885,
            0,
            0,
            0,
            29.35678,
            0,
            17.12905,
            "<D",
            113.4605,
            0,
        ],
        1728022860: [
            0.21465,
            0,
            0.21465,
            0,
            37.31095,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            137.0513,
            0,
            0.97582,
            0,
            16.90376,
            0,
            -0.07946,
            0,
            0,
            0,
            29.35679,
            0,
            14.97394,
            "<D",
            113.3033,
            0,
        ],
        1728022920: [
            0.06447,
            0,
            0.06447,
            0,
            38.48939,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            122.2762,
            0,
            0.83882,
            0,
            16.93222,
            0,
            -0.05959,
            0,
            0,
            0,
            29.35699,
            0,
            16.63245,
            "<D",
            113.2993,
            0,
        ],
        1728022980: [
            0.12595,
            0,
            0.12595,
            0,
            38.35027,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            122.4264,
            0,
            1.03936,
            0,
            17.02365,
            0,
            -0.13243,
            0,
            0,
            0,
            29.35729,
            0,
            13.77863,
            "<D",
            113.1502,
            0,
        ],
        1728023040: [
            0.14318,
            0,
            0.14318,
            0,
            37.75319,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            125.1278,
            0,
            0.81751,
            0,
            17.00792,
            0,
            -0.10925,
            0,
            0,
            0,
            29.35651,
            0,
            15.68602,
            "<D",
            113.1683,
            0,
        ],
        1728023100: [
            0.15015,
            0,
            0.15015,
            0,
            37.10208,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            132.8861,
            0,
            0.96213,
            0,
            16.9813,
            0,
            -0.23176,
            0,
            0,
            0,
            29.35663,
            0,
            14.30738,
            "<D",
            112.9052,
            0,
        ],
        1728023160: [
            0.10058,
            0,
            0.10058,
            0,
            37.47824,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            126.8792,
            0,
            0.99497,
            0,
            16.96743,
            0,
            -0.23176,
            0,
            0,
            0,
            29.35641,
            0,
            15.86555,
            "<D",
            112.7633,
            0,
        ],
        1728023220: [
            0.14251,
            0,
            0.14251,
            0,
            37.882,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            143.6915,
            0,
            1.02977,
            0,
            16.91822,
            0,
            -0.23838,
            0,
            0,
            0,
            29.35639,
            0,
            14.46139,
            "<D",
            112.6817,
            0,
        ],
        1728023280: [
            0.39236,
            0,
            0.39236,
            0,
            39.4622,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            142.5883,
            0,
            1.21075,
            0,
            16.84861,
            0,
            -0.23176,
            0,
            0,
            0,
            29.35629,
            0,
            15.35431,
            "<D",
            112.4931,
            0,
        ],
        1728023340: [
            0.23119,
            0,
            0.23119,
            0,
            40.16905,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            143.4622,
            0,
            1.26936,
            0,
            16.79859,
            0,
            -0.22845,
            0,
            0,
            0,
            29.3568,
            0,
            16.34786,
            "<D",
            112.3596,
            0,
        ],
        1728023400: [
            0.35013,
            0,
            0.35013,
            0,
            37.14895,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            140.0308,
            0,
            1.35583,
            0,
            16.7746,
            0,
            -0.22845,
            0,
            0,
            0,
            29.35531,
            0,
            16.63665,
            "<D",
            112.4775,
            0,
        ],
        1728023460: [
            0.26657,
            0,
            0.26657,
            0,
            34.66927,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            153.3118,
            0,
            1.26211,
            0,
            16.76012,
            0,
            -0.22513,
            0,
            0,
            0,
            29.35476,
            0,
            15.56978,
            "<D",
            112.2212,
            0,
        ],
        1728023520: [
            0.17635,
            0,
            0.17635,
            0,
            35.0548,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            144.2159,
            0,
            1.8163,
            0,
            16.72765,
            0,
            -0.23176,
            0,
            0,
            0,
            29.35483,
            0,
            15.06587,
            "<D",
            112.4295,
            0,
        ],
        1728023580: [
            0.35408,
            0,
            0.35408,
            0,
            37.26263,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            157.6223,
            0,
            1.63094,
            0,
            16.66372,
            0,
            -0.23507,
            0,
            0,
            0,
            29.35349,
            0,
            18.78658,
            "<D",
            112.4409,
            0,
        ],
        1728023640: [
            0.22639,
            0,
            0.22639,
            0,
            36.30849,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            151.2826,
            0,
            1.79834,
            0,
            16.70025,
            0,
            -0.18871,
            0,
            0,
            0,
            29.35338,
            0,
            14.54813,
            "<D",
            112.6801,
            0,
        ],
        1728023700: [
            0.25112,
            0,
            0.25112,
            0,
            36.11531,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            148.5462,
            0,
            1.89406,
            0,
            16.73078,
            0,
            -0.12581,
            0,
            0,
            0,
            29.35335,
            0,
            18.67296,
            "<D",
            112.4006,
            0,
        ],
        1728023760: [
            0.41868,
            0,
            0.41868,
            0,
            33.98293,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            146.9038,
            0,
            1.78204,
            0,
            16.73356,
            0,
            -0.1225,
            0,
            0,
            0,
            29.35326,
            0,
            18.46489,
            "<D",
            112.8249,
            0,
        ],
        1728023820: [
            0.25875,
            0,
            0.25875,
            0,
            33.98069,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            154.7805,
            0,
            1.80747,
            0,
            16.77583,
            0,
            -0.04304,
            0,
            0,
            0,
            29.3533,
            0,
            16.00973,
            "<D",
            112.7488,
            0,
        ],
        1728023880: [
            0.27535,
            0,
            0.27535,
            0,
            35.94439,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            154.6541,
            0,
            1.50907,
            0,
            16.7398,
            0,
            -0.07615,
            0,
            0,
            0,
            29.35317,
            0,
            15.04793,
            "<D",
            112.8213,
            0,
        ],
        1728023940: [
            0.22622,
            0,
            0.22622,
            0,
            36.32103,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.871,
            0,
            155.647,
            0,
            1.52559,
            0,
            16.67899,
            0,
            -0.06621,
            0,
            0,
            0,
            29.35323,
            0,
            17.06026,
            "<D",
            112.2563,
            0,
        ],
        1728024000: [
            0.17667,
            0,
            0.17667,
            0,
            36.31833,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8796,
            0,
            160.1701,
            0,
            1.50996,
            0,
            16.72148,
            0,
            -0.04635,
            0,
            0,
            0,
            29.35318,
            0,
            15.50991,
            "<D",
            112.2375,
            0,
        ],
        1728024060: [
            0.16062,
            0,
            0.16062,
            0,
            37.32483,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            161.6828,
            0,
            1.79321,
            0,
            16.73693,
            0,
            -0.05959,
            0,
            0,
            0,
            29.3532,
            0,
            15.69808,
            "<D",
            112.7447,
            0,
        ],
        1728024120: [
            0.31112,
            0,
            0.31112,
            0,
            37.51292,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            156.9328,
            0,
            1.68515,
            0,
            16.80597,
            0,
            -0.07615,
            0,
            0,
            0,
            29.35346,
            0,
            16.65162,
            "<D",
            114.1813,
            0,
        ],
        1728024180: [
            0.24659,
            0,
            0.24659,
            0,
            37.35616,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            156.7529,
            0,
            1.76195,
            0,
            16.79928,
            0,
            -0.05628,
            0,
            0,
            0,
            29.35267,
            0,
            16.76216,
            "<D",
            112.9405,
            0,
        ],
        1728024240: [
            0.16929,
            0,
            0.16929,
            0,
            37.5575,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            165.0423,
            0,
            1.69681,
            0,
            16.85573,
            0,
            -0.05959,
            0,
            0,
            0,
            29.35249,
            0,
            14.25996,
            "<D",
            112.8725,
            0,
        ],
        1728024300: [
            0.36236,
            0,
            0.36236,
            0,
            37.11145,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            160.2793,
            0,
            1.65811,
            0,
            16.83782,
            0,
            -0.05959,
            0,
            0,
            0,
            29.35303,
            0,
            13.7238,
            "<D",
            112.8809,
            0,
        ],
        1728024360: [
            0.04265,
            0,
            0.04265,
            0,
            37.62782,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            154.4798,
            0,
            1.37906,
            0,
            16.8783,
            0,
            -0.04966,
            0,
            0,
            0,
            29.35258,
            0,
            17.63464,
            "<D",
            112.8889,
            0,
        ],
        1728024420: [
            0.09131,
            0,
            0.09131,
            0,
            37.74307,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            151.3973,
            0,
            1.756,
            0,
            16.94945,
            0,
            -0.07283,
            0,
            0,
            0,
            29.35204,
            0,
            15.98812,
            "<D",
            112.8664,
            0,
        ],
        1728024480: [
            0.12337,
            0,
            0.12337,
            0,
            37.09731,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            156.7078,
            0,
            1.27638,
            0,
            17.1417,
            0,
            -0.20195,
            0,
            0,
            0,
            29.3526,
            0,
            15.54246,
            "<D",
            112.8429,
            0,
        ],
        1728024540: [
            0.26164,
            0,
            0.26164,
            0,
            37.32094,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            155.7245,
            0,
            1.37286,
            0,
            16.89248,
            0,
            -0.19533,
            0,
            0,
            0,
            29.35266,
            0,
            16.7118,
            "<D",
            112.7204,
            0,
        ],
        1728024600: [
            0.23238,
            0,
            0.23238,
            0,
            37.06705,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            144.0229,
            0,
            1.65931,
            0,
            16.81858,
            0,
            -0.23507,
            0,
            0,
            0,
            29.35248,
            0,
            15.6662,
            "<D",
            112.3457,
            0,
        ],
        1728024660: [
            0.10891,
            0,
            0.10891,
            0,
            38.74408,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            143.7944,
            0,
            1.56127,
            0,
            16.79711,
            0,
            -0.23838,
            0,
            0,
            0,
            29.35195,
            0,
            14.87717,
            "<D",
            111.9875,
            0,
        ],
        1728024720: [
            0.33525,
            0,
            0.33525,
            0,
            38.72983,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            142.1992,
            0,
            1.49785,
            0,
            17.0136,
            0,
            -0.24831,
            0,
            0,
            0,
            29.35131,
            0,
            17.88752,
            "<D",
            112.5467,
            0,
        ],
        1728024780: [
            0.28734,
            0,
            0.28734,
            0,
            37.79554,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            145.7281,
            0,
            1.50033,
            0,
            17.05654,
            0,
            -0.22182,
            0,
            0,
            0,
            29.35109,
            0,
            17.74172,
            "<D",
            112.7398,
            0,
        ],
        1728024840: [
            0.14567,
            0,
            0.14567,
            0,
            38.90823,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            151.4262,
            0,
            1.51015,
            0,
            17.07158,
            0,
            -0.19865,
            0,
            0,
            0,
            29.35091,
            0,
            15.93587,
            "<D",
            112.5232,
            0,
        ],
        1728024900: [
            0.21349,
            0,
            0.21349,
            0,
            38.84586,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            143.8052,
            0,
            1.0679,
            0,
            16.98637,
            0,
            -0.23176,
            0,
            0,
            0,
            29.35102,
            0,
            19.87651,
            "<D",
            112.4193,
            0,
        ],
        1728024960: [
            0.15563,
            0,
            0.15563,
            0,
            37.80865,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            140.457,
            0,
            1.24399,
            0,
            16.95375,
            0,
            -0.2152,
            0,
            0,
            0,
            29.3507,
            0,
            16.10905,
            "<D",
            112.0876,
            0,
        ],
        1728025020: [
            0.20248,
            0,
            0.20248,
            0,
            36.91666,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            160.0991,
            0,
            1.15481,
            0,
            16.97492,
            0,
            -0.21851,
            0,
            0,
            0,
            29.35057,
            0,
            15.2599,
            "<D",
            112.2438,
            0,
        ],
        1728025080: [
            0.1067,
            0,
            0.1067,
            0,
            36.50734,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            149.1142,
            0,
            1.36809,
            0,
            17.06043,
            0,
            -0.23176,
            0,
            0,
            0,
            29.35052,
            0,
            16.94563,
            "<D",
            112.1821,
            0,
        ],
        1728025140: [
            0.25871,
            0,
            0.25871,
            0,
            38.52497,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            148.8244,
            0,
            1.49707,
            0,
            17.15981,
            0,
            -0.18871,
            0,
            0,
            0,
            29.35161,
            0,
            16.86657,
            "<D",
            111.7759,
            0,
        ],
        1728025200: [
            0.12693,
            0,
            0.12693,
            0,
            38.14439,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            144.899,
            0,
            1.03294,
            0,
            17.32721,
            0,
            -0.12912,
            0,
            0,
            0,
            29.35262,
            0,
            18.75511,
            "<D",
            112.0389,
            0,
        ],
        1728025260: [
            0.17344,
            0,
            0.17344,
            0,
            38.19822,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            143.7733,
            0,
            1.52561,
            0,
            17.23689,
            0,
            -0.15229,
            0,
            0,
            0,
            29.35243,
            0,
            16.63498,
            "<D",
            112.1424,
            0,
        ],
        1728025320: [
            0.15003,
            0,
            0.15003,
            0,
            37.90995,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            147.1975,
            0,
            1.1155,
            0,
            17.31068,
            0,
            -0.10263,
            0,
            0,
            0,
            29.35237,
            0,
            13.14245,
            "<D",
            112.0771,
            0,
        ],
        1728025380: [
            0.28974,
            0,
            0.28974,
            0,
            37.39013,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            149.8812,
            0,
            1.16174,
            0,
            17.25793,
            0,
            -0.07284,
            0,
            0,
            0,
            29.35198,
            0,
            13.03459,
            "<D",
            112.3944,
            0,
        ],
        1728025440: [
            0.27813,
            0,
            0.27813,
            0,
            38.0685,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            144.3051,
            0,
            1.19979,
            0,
            17.27738,
            0,
            -0.06621,
            0,
            0,
            0,
            29.35157,
            0,
            15.86646,
            "<D",
            111.6878,
            0,
        ],
        1728025500: [
            0.25777,
            0,
            0.25777,
            0,
            39.04871,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            158.8253,
            0,
            1.01357,
            0,
            17.40901,
            0,
            -0.13243,
            0,
            0,
            0,
            29.35201,
            0,
            16.39925,
            "<D",
            112.3357,
            0,
        ],
        1728025560: [
            0.26655,
            0,
            0.26655,
            0,
            39.59484,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            149.3589,
            0,
            1.15621,
            0,
            17.71643,
            0,
            -0.13574,
            0,
            0,
            0,
            29.35105,
            0,
            13.92292,
            "<D",
            113.6202,
            0,
        ],
        1728025620: [
            0.1834,
            0,
            0.1834,
            0,
            40.21167,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            151.3474,
            0,
            0.94322,
            0,
            17.58655,
            0,
            -0.12912,
            0,
            0,
            0,
            29.3511,
            0,
            16.34786,
            "<D",
            113.464,
            0,
        ],
        1728025680: [
            0.0745,
            0,
            0.0745,
            0,
            39.24391,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            152.0219,
            0,
            0.67724,
            0,
            17.40284,
            0,
            -0.0927,
            0,
            0,
            0,
            29.35038,
            0,
            15.20612,
            "<D",
            113.0968,
            0,
        ],
        1728025740: [
            0.02184,
            0,
            0.02184,
            0,
            38.50802,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            147.1078,
            0,
            1.02345,
            0,
            17.40771,
            0,
            -0.06952,
            0,
            0,
            0,
            29.35064,
            0,
            15.37433,
            "<D",
            113.0989,
            0,
        ],
        1728025800: [
            0.26109,
            0,
            0.26109,
            0,
            39.33189,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            149.16,
            0,
            1.55636,
            0,
            17.60295,
            0,
            -0.11918,
            0,
            0,
            0,
            29.35052,
            0,
            18.4794,
            "<D",
            113.0832,
            0,
        ],
        1728025860: [
            0.12676,
            0,
            0.12676,
            0,
            38.44802,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            154.2766,
            0,
            1.44786,
            0,
            17.47891,
            0,
            -0.18209,
            0,
            0,
            0,
            29.35045,
            0,
            15.22084,
            "<D",
            112.9648,
            0,
        ],
        1728025920: [
            0.26722,
            0,
            0.26722,
            0,
            38.35634,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            160.5033,
            0,
            1.21624,
            0,
            17.42553,
            0,
            -0.25162,
            0,
            0,
            0,
            29.35044,
            0,
            16.76659,
            "<D",
            112.8123,
            0,
        ],
        1728025980: [
            0.28377,
            0,
            0.28377,
            0,
            39.21444,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            163.4469,
            0,
            1.58411,
            0,
            17.21526,
            0,
            -0.32114,
            0,
            0,
            0,
            29.35048,
            0,
            17.83105,
            "<D",
            112.4933,
            0,
        ],
        1728026040: [
            0.20203,
            0,
            0.20203,
            0,
            37.32321,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            154.606,
            0,
            1.4682,
            0,
            17.17167,
            0,
            -0.3079,
            0,
            0,
            0,
            29.35027,
            0,
            15.32911,
            "<D",
            112.2889,
            0,
        ],
        1728026100: [
            0.23674,
            0,
            0.23674,
            0,
            39.58363,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            162.4876,
            0,
            1.09823,
            0,
            16.99595,
            0,
            -0.29466,
            0,
            0,
            0,
            29.35026,
            0,
            15.71474,
            "<D",
            112.0325,
            0,
        ],
        1728026160: [
            0.16041,
            0,
            0.16041,
            0,
            39.70366,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            146.8445,
            0,
            0.98426,
            0,
            16.94689,
            0,
            -0.30127,
            0,
            0,
            0,
            29.35019,
            0,
            15.83008,
            "<D",
            112.4442,
            0,
        ],
        1728026220: [
            0.03963,
            0,
            0.03963,
            0,
            38.72858,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            155.5976,
            0,
            1.34641,
            0,
            17.02114,
            0,
            -0.29134,
            0,
            0,
            0,
            29.34991,
            0,
            16.52385,
            "<D",
            112.4226,
            0,
        ],
        1728026280: [
            0.1796,
            0,
            0.1796,
            0,
            36.1718,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            144.0459,
            0,
            1.47555,
            0,
            17.03062,
            0,
            -0.29465,
            0,
            0,
            0,
            29.34912,
            0,
            16.54433,
            "<D",
            112.33,
            0,
        ],
        1728026340: [
            0.17563,
            0,
            0.17563,
            0,
            37.11002,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            152.5029,
            0,
            1.46019,
            0,
            16.95827,
            0,
            -0.31452,
            0,
            0,
            0,
            29.34962,
            0,
            16.08039,
            "<D",
            112.3871,
            0,
        ],
        1728026400: [
            0.22134,
            0,
            0.22134,
            0,
            37.73492,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            136.0446,
            0,
            1.09006,
            0,
            17.03985,
            0,
            -0.25161,
            0,
            0,
            0,
            29.35018,
            0,
            15.84651,
            "<D",
            112.2494,
            0,
        ],
        1728026460: [
            0.29603,
            0,
            0.29603,
            0,
            35.19835,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            108.7872,
            0,
            0.61893,
            0,
            17.18279,
            0,
            -0.22513,
            0,
            0,
            0,
            29.3501,
            0,
            16.31688,
            "<D",
            112.1291,
            0,
        ],
        1728026520: [
            0.18056,
            0,
            0.18056,
            0,
            35.74897,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            57.83484,
            0,
            0.64406,
            0,
            17.11305,
            0,
            -0.21851,
            0,
            0,
            0,
            29.35026,
            0,
            14.08625,
            "<D",
            111.8427,
            0,
        ],
        1728026580: [
            0.13438,
            0,
            0.13438,
            0,
            34.56367,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            14.26515,
            0,
            0.3706,
            0,
            17.17266,
            0,
            -0.21851,
            0,
            0,
            0,
            29.35025,
            0,
            17.72512,
            "<D",
            111.5686,
            0,
        ],
        1728026640: [
            0.15205,
            0,
            0.15205,
            0,
            36.55792,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            37.66996,
            0,
            0.71104,
            0,
            17.08033,
            0,
            -0.22844,
            0,
            0,
            0,
            29.35025,
            0,
            17.01119,
            "<D",
            111.7739,
            0,
        ],
        1728026700: [
            0.10503,
            0,
            0.10503,
            0,
            35.24619,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            45.02409,
            0,
            0.59438,
            0,
            17.15597,
            0,
            -0.17547,
            0,
            0,
            0,
            29.3503,
            0,
            15.78118,
            "<D",
            112.2581,
            0,
        ],
        1728026760: [
            0.21998,
            0,
            0.21998,
            0,
            35.45456,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            97.16177,
            0,
            0.17027,
            0,
            17.25126,
            0,
            -0.20858,
            0,
            0,
            0,
            29.35032,
            0,
            15.58746,
            "<D",
            111.8034,
            0,
        ],
        1728026820: [
            0.25876,
            0,
            0.25876,
            0,
            35.75824,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            35.79154,
            0,
            0.40497,
            0,
            17.1799,
            0,
            -0.16223,
            0,
            0,
            0,
            29.35044,
            0,
            16.10584,
            "<D",
            111.6252,
            0,
        ],
        1728026880: [
            0.27814,
            0,
            0.27814,
            0,
            33.7451,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            136.8112,
            0,
            0.66509,
            0,
            17.04912,
            0,
            -0.11256,
            0,
            0,
            0,
            29.34975,
            0,
            15.42371,
            "<D",
            111.5895,
            0,
        ],
        1728026940: [
            0.1187,
            0,
            0.1187,
            0,
            34.81261,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            125.0669,
            0,
            0.75115,
            0,
            17.05923,
            0,
            -0.07614,
            0,
            0,
            0,
            29.34969,
            0,
            13.82355,
            "<D",
            111.7763,
            0,
        ],
        1728027000: [
            0.23406,
            0,
            0.23406,
            0,
            34.18875,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            128.0472,
            0,
            0.67556,
            0,
            16.96021,
            0,
            -0.0629,
            0,
            0,
            0,
            29.34964,
            0,
            15.51312,
            "<D",
            111.7003,
            0,
        ],
        1728027060: [
            0.20769,
            0,
            0.20769,
            0,
            35.15027,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            144.361,
            0,
            0.96631,
            0,
            16.87188,
            0,
            -0.09932,
            0,
            0,
            0,
            29.3498,
            0,
            15.32424,
            "<D",
            111.1455,
            0,
        ],
        1728027120: [
            0.32025,
            0,
            0.32025,
            0,
            32.72552,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            135.8148,
            0,
            0.83794,
            0,
            16.88479,
            0,
            -0.12912,
            0,
            0,
            0,
            29.34998,
            0,
            15.61987,
            "<D",
            111.9258,
            0,
        ],
        1728027180: [
            0.33139,
            0,
            0.33139,
            0,
            28.16351,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            140.2368,
            0,
            1.1533,
            0,
            16.9718,
            0,
            -0.12912,
            0,
            0,
            0,
            29.34978,
            0,
            15.91488,
            "<D",
            112.6302,
            0,
        ],
        1728027240: [
            0.35875,
            0,
            0.35875,
            0,
            32.73514,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            137.307,
            0,
            0.81203,
            0,
            16.97356,
            0,
            -0.0927,
            0,
            0,
            0,
            29.34709,
            0,
            16.7184,
            "<D",
            112.9731,
            0,
        ],
        1728027300: [
            0.28963,
            0,
            0.28963,
            0,
            33.31688,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            150.0783,
            0,
            0.8612,
            0,
            16.93962,
            0,
            -0.05959,
            0,
            0,
            0,
            29.34691,
            0,
            15.11574,
            "<D",
            113.4239,
            0,
        ],
        1728027360: [
            0.26441,
            0,
            0.26441,
            0,
            33.99483,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            140.4141,
            0,
            0.77224,
            0,
            16.91369,
            0,
            -0.07283,
            0,
            0,
            0,
            29.34644,
            0,
            17.6972,
            "<D",
            113.2191,
            0,
        ],
        1728027420: [
            0.19326,
            0,
            0.19326,
            0,
            34.04117,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            132.3926,
            0,
            0.72119,
            0,
            16.97779,
            0,
            -0.14567,
            0,
            0,
            0,
            29.34668,
            0,
            15.83425,
            "<D",
            113.4182,
            0,
        ],
        1728027480: [
            0.16577,
            0,
            0.16577,
            0,
            35.90631,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            133.8025,
            0,
            0.77468,
            0,
            17.10929,
            0,
            -0.16222,
            0,
            0,
            0,
            29.34677,
            0,
            16.18199,
            "<D",
            113.0962,
            0,
        ],
        1728027540: [
            0.15862,
            0,
            0.15862,
            0,
            36.18018,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            132.5167,
            0,
            0.87853,
            0,
            17.19129,
            0,
            -0.18209,
            0,
            0,
            0,
            29.34649,
            0,
            14.58917,
            "<D",
            112.4963,
            0,
        ],
        1728027600: [
            0.18765,
            0,
            0.18765,
            0,
            36.39873,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            138.2552,
            0,
            0.81724,
            0,
            17.22968,
            0,
            -0.23176,
            0,
            0,
            0,
            29.34648,
            0,
            19.66682,
            "<D",
            113.1186,
            0,
        ],
        1728027660: [
            0.12989,
            0,
            0.12989,
            0,
            36.57353,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            133.065,
            0,
            0.78927,
            0,
            17.20778,
            0,
            -0.23175,
            0,
            0,
            0,
            29.34529,
            0,
            15.98276,
            "<D",
            113.1127,
            0,
        ],
        1728027720: [
            0.19914,
            0,
            0.19914,
            0,
            36.77187,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            139.5289,
            0,
            0.77957,
            0,
            17.16928,
            0,
            -0.19534,
            0,
            0,
            0,
            29.34537,
            0,
            16.71345,
            "<D",
            113.2338,
            0,
        ],
        1728027780: [
            0.21236,
            0,
            0.21236,
            0,
            36.2398,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            140.0715,
            0,
            0.75805,
            0,
            17.12691,
            0,
            -0.2152,
            0,
            0,
            0,
            29.3432,
            0,
            13.41391,
            "<D",
            113.4361,
            0,
        ],
        1728027840: [
            0.34611,
            0,
            0.34611,
            0,
            35.92236,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            140.483,
            0,
            0.73152,
            0,
            17.08961,
            0,
            -0.22845,
            0,
            0,
            0,
            29.34235,
            0,
            17.32535,
            "<D",
            113.6209,
            0,
        ],
        1728027900: [
            0.2357,
            0,
            0.2357,
            0,
            35.76143,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            124.6071,
            0,
            0.70085,
            0,
            17.03897,
            0,
            -0.22844,
            0,
            0,
            0,
            29.34509,
            0,
            15.2373,
            "<D",
            113.2432,
            0,
        ],
        1728027960: [
            0.19232,
            0,
            0.19232,
            0,
            35.84941,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            124.9663,
            0,
            0.61127,
            0,
            16.98101,
            0,
            -0.22513,
            0,
            0,
            0,
            29.34446,
            0,
            18.33359,
            "<D",
            112.994,
            0,
        ],
        1728028020: [
            0.31482,
            0,
            0.31482,
            0,
            35.59806,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            124.4904,
            0,
            0.63964,
            0,
            16.91073,
            0,
            -0.22182,
            0,
            0,
            0,
            29.34215,
            0,
            14.44563,
            "<D",
            112.9061,
            0,
        ],
        1728028080: [
            0.11851,
            0,
            0.11851,
            0,
            35.32001,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            126.1018,
            0,
            0.57144,
            0,
            16.86144,
            0,
            -0.20527,
            0,
            0,
            0,
            29.34304,
            0,
            13.57133,
            "<D",
            112.6078,
            0,
        ],
        1728028140: [
            0.3372,
            0,
            0.3372,
            0,
            35.45177,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            141.424,
            0,
            0.6356,
            0,
            16.80356,
            0,
            -0.21189,
            0,
            0,
            0,
            29.34256,
            0,
            18.02563,
            "<D",
            112.7126,
            0,
        ],
        1728028200: [
            0.22678,
            0,
            0.22678,
            0,
            35.97942,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            139.4347,
            0,
            0.74964,
            0,
            16.73536,
            0,
            -0.22844,
            0,
            0,
            0,
            29.34285,
            0,
            14.6851,
            "<D",
            112.6325,
            0,
        ],
        1728028260: [
            0.20313,
            0,
            0.20313,
            0,
            36.16352,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            153.2406,
            0,
            0.78467,
            0,
            16.66714,
            0,
            -0.22844,
            0,
            0,
            0,
            29.34386,
            0,
            15.82277,
            "<D",
            112.7602,
            0,
        ],
        1728028320: [
            0.22166,
            0,
            0.22166,
            0,
            36.836,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            144.4602,
            0,
            0.89986,
            0,
            16.62335,
            0,
            -0.2152,
            0,
            0,
            0,
            29.34438,
            0,
            18.8062,
            "<D",
            112.5648,
            0,
        ],
        1728028380: [
            0.23019,
            0,
            0.23019,
            0,
            37.37035,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            148.5049,
            0,
            0.76952,
            0,
            16.57549,
            0,
            -0.14567,
            0,
            0,
            0,
            29.34631,
            0,
            13.03984,
            "<D",
            112.6574,
            0,
        ],
        1728028440: [
            0.20774,
            0,
            0.20774,
            0,
            37.08905,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            152.1535,
            0,
            0.85929,
            0,
            16.54247,
            0,
            -0.12912,
            0,
            0,
            0,
            29.34647,
            0,
            13.47289,
            "<D",
            112.7784,
            0,
        ],
        1728028500: [
            0.14423,
            0,
            0.14423,
            0,
            37.18184,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            156.2397,
            0,
            1.07558,
            0,
            16.44672,
            0,
            -0.05959,
            0,
            0,
            0,
            29.34578,
            0,
            15.88361,
            "<D",
            112.4476,
            0,
        ],
        1728028560: [
            0.26324,
            0,
            0.26324,
            0,
            36.91769,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            147.8187,
            0,
            0.85803,
            0,
            16.38126,
            0,
            -0.04966,
            0,
            0,
            0,
            29.34594,
            0,
            18.28903,
            "<D",
            111.9692,
            0,
        ],
        1728028620: [
            0.27375,
            0,
            0.27375,
            0,
            34.76176,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            147.4193,
            0,
            1.26592,
            0,
            16.49396,
            0,
            -0.05959,
            0,
            0,
            0,
            29.3454,
            0,
            14.95623,
            "<D",
            112.4896,
            0,
        ],
        1728028680: [
            0.224,
            0,
            0.224,
            0,
            36.18622,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            137.5395,
            0,
            1.30013,
            0,
            16.48872,
            0,
            -0.17216,
            0,
            0,
            0,
            29.34567,
            0,
            14.57588,
            "<D",
            113.0541,
            0,
        ],
        1728028740: [
            0.32346,
            0,
            0.32346,
            0,
            35.70816,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            154.1196,
            0,
            1.09339,
            0,
            16.41483,
            0,
            -0.20858,
            0,
            0,
            0,
            29.34595,
            0,
            18.19853,
            "<D",
            113.4771,
            0,
        ],
        1728028800: [
            0.45515,
            0,
            0.45515,
            0,
            36.48477,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            141.6528,
            0,
            0.66608,
            0,
            16.36033,
            0,
            -0.1225,
            0,
            0,
            0,
            29.34621,
            0,
            15.23487,
            "<D",
            113.3412,
            0,
        ],
        1728028860: [
            0.02777,
            0,
            0.02777,
            0,
            35.29036,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            136.0827,
            0,
            0.98307,
            0,
            16.38705,
            0,
            -0.07284,
            0,
            0,
            0,
            29.34621,
            0,
            16.3209,
            "<D",
            113.432,
            0,
        ],
        1728028920: [
            0.10289,
            0,
            0.10289,
            0,
            34.6479,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            139.1574,
            0,
            0.9518,
            0,
            16.4032,
            0,
            -0.05628,
            0,
            0,
            0,
            29.34621,
            0,
            14.85483,
            "<D",
            113.6432,
            0,
        ],
        1728028980: [
            0.1231,
            0,
            0.1231,
            0,
            34.2539,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            144.2717,
            0,
            0.69006,
            0,
            16.45722,
            0,
            -0.04966,
            0,
            0,
            0,
            29.34622,
            0,
            17.89198,
            "<D",
            113.5887,
            0,
        ],
        1728029040: [
            0.31519,
            0,
            0.31519,
            0,
            32.75683,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            133.4844,
            0,
            0.84002,
            0,
            16.53227,
            0,
            -0.06621,
            0,
            0,
            0,
            29.3463,
            0,
            12.9818,
            "<D",
            113.3147,
            0,
        ],
        1728029100: [
            0.35215,
            0,
            0.35215,
            0,
            32.70118,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            128.4974,
            0,
            1.00265,
            0,
            16.6889,
            0,
            -0.14236,
            0,
            0,
            0,
            29.34639,
            0,
            16.43651,
            "<D",
            113.3753,
            0,
        ],
        1728029160: [
            0.3576,
            0,
            0.3576,
            0,
            32.2604,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            141.0242,
            0,
            1.09448,
            0,
            16.59469,
            0,
            -0.20858,
            0,
            0,
            0,
            29.34691,
            0,
            15.94499,
            "<D",
            113.6952,
            0,
        ],
        1728029220: [
            0.21516,
            0,
            0.21516,
            0,
            32.58868,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            128.9568,
            0,
            0.6902,
            0,
            16.53142,
            0,
            -0.22513,
            0,
            0,
            0,
            29.34682,
            0,
            17.0883,
            "<D",
            113.3326,
            0,
        ],
        1728029280: [
            0.36372,
            0,
            0.36372,
            0,
            30.49217,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            121.5953,
            0,
            0.56703,
            0,
            16.4434,
            0,
            -0.22844,
            0,
            0,
            0,
            29.34672,
            0,
            16.50797,
            "<D",
            113.3765,
            0,
        ],
        1728029340: [
            0.26357,
            0,
            0.26357,
            0,
            29.97657,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            130.5769,
            0,
            0.61092,
            0,
            16.36371,
            0,
            -0.22513,
            0,
            0,
            0,
            29.34658,
            0,
            15.90861,
            "<D",
            113.2728,
            0,
        ],
        1728029400: [
            0.15864,
            0,
            0.15864,
            0,
            30.83252,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            133.2911,
            0,
            0.80538,
            0,
            16.36115,
            0,
            -0.23176,
            0,
            0,
            0,
            29.34666,
            0,
            16.5846,
            "<D",
            113.0732,
            0,
        ],
        1728029460: [
            0.15373,
            0,
            0.15373,
            0,
            29.21348,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            126.8127,
            0,
            0.7489,
            0,
            16.32143,
            0,
            -0.20858,
            0,
            0,
            0,
            29.34675,
            0,
            15.79144,
            "<D",
            112.9207,
            0,
        ],
        1728029520: [
            0.24453,
            0,
            0.24453,
            0,
            28.00978,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            130.3202,
            0,
            0.74793,
            0,
            16.30447,
            0,
            -0.21851,
            0,
            0,
            0,
            29.34644,
            0,
            15.92791,
            "<D",
            112.7077,
            0,
        ],
        1728029580: [
            0.1718,
            0,
            0.1718,
            0,
            27.67478,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            116.9893,
            0,
            0.57218,
            0,
            16.31165,
            0,
            -0.23175,
            0,
            0,
            0,
            29.34681,
            0,
            16.57238,
            "<D",
            113.184,
            0,
        ],
        1728029640: [
            0.33527,
            0,
            0.33527,
            0,
            28.49222,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            137.2847,
            0,
            0.83821,
            0,
            16.32911,
            0,
            -0.22513,
            0,
            0,
            0,
            29.34694,
            0,
            15.6908,
            "<D",
            113.4156,
            0,
        ],
        1728029700: [
            0.36745,
            0,
            0.36745,
            0,
            30.97262,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            133.3125,
            0,
            0.99674,
            0,
            16.31476,
            0,
            -0.23838,
            0,
            0,
            0,
            29.34679,
            0,
            14.65744,
            "<D",
            113.5517,
            0,
        ],
        1728029760: [
            0.33714,
            0,
            0.33714,
            0,
            32.39094,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            140.326,
            0,
            0.85185,
            0,
            16.33279,
            0,
            -0.22182,
            0,
            0,
            0,
            29.34715,
            0,
            17.93379,
            "<D",
            113.5647,
            0,
        ],
        1728029820: [
            0.38211,
            0,
            0.38211,
            0,
            33.63413,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            128.8169,
            0,
            1.18966,
            0,
            16.30174,
            0,
            -0.23175,
            0,
            0,
            0,
            29.34707,
            0,
            16.34783,
            "<D",
            112.9505,
            0,
        ],
        1728029880: [
            0.13345,
            0,
            0.13345,
            0,
            34.49342,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            146.2925,
            0,
            1.04839,
            0,
            16.36374,
            0,
            -0.17547,
            0,
            0,
            0,
            29.34789,
            0,
            14.2411,
            "<D",
            113.2195,
            0,
        ],
        1728029940: [
            0.33677,
            0,
            0.33677,
            0,
            32.90119,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            148.672,
            0,
            0.93503,
            0,
            16.33243,
            0,
            -0.14567,
            0,
            0,
            0,
            29.34888,
            0,
            17.65918,
            "<D",
            113.2028,
            0,
        ],
        1728030000: [
            0.22161,
            0,
            0.22161,
            0,
            32.39176,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            157.0827,
            0,
            0.79628,
            0,
            16.31104,
            0,
            -0.1556,
            0,
            0,
            0,
            29.34832,
            0,
            16.71091,
            "<D",
            113.3907,
            0,
        ],
        1728030060: [
            0.31793,
            0,
            0.31793,
            0,
            32.30283,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            151.8821,
            0,
            1.01441,
            0,
            16.31215,
            0,
            -0.08939,
            0,
            0,
            0,
            29.34896,
            0,
            14.38686,
            "<D",
            113.7268,
            0,
        ],
        1728030120: [
            0.14814,
            0,
            0.14814,
            0,
            32.22714,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            145.287,
            0,
            0.71929,
            0,
            16.31939,
            0,
            -0.06952,
            0,
            0,
            0,
            29.34915,
            0,
            15.64596,
            "<D",
            113.9715,
            0,
        ],
        1728030180: [
            0.24244,
            0,
            0.24244,
            0,
            33.62169,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            154.134,
            0,
            0.80906,
            0,
            16.35589,
            0,
            -0.06621,
            0,
            0,
            0,
            29.34941,
            0,
            16.06595,
            "<D",
            113.9472,
            0,
        ],
        1728030240: [
            0.27363,
            0,
            0.27363,
            0,
            33.37638,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            144.744,
            0,
            1.11137,
            0,
            16.41182,
            0,
            -0.10926,
            0,
            0,
            0,
            29.34906,
            0,
            19.67042,
            "<D",
            114.0586,
            0,
        ],
        1728030300: [
            0.23368,
            0,
            0.23368,
            0,
            35.84398,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            146.1114,
            0,
            0.99528,
            0,
            16.39971,
            0,
            -0.16223,
            0,
            0,
            0,
            29.35006,
            0,
            18.25401,
            "<D",
            113.4063,
            0,
        ],
        1728030360: [
            0.24277,
            0,
            0.24277,
            0,
            35.13142,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            140.0015,
            0,
            1.24584,
            0,
            16.4555,
            0,
            -0.20858,
            0,
            0,
            0,
            29.34943,
            0,
            16.36906,
            "<D",
            114.1198,
            0,
        ],
        1728030420: [
            0.23318,
            0,
            0.23318,
            0,
            34.60492,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            146.8268,
            0,
            0.86602,
            0,
            16.45752,
            0,
            -0.21852,
            0,
            0,
            0,
            29.34961,
            0,
            16.20238,
            "<D",
            113.4547,
            0,
        ],
        1728030480: [
            0.22808,
            0,
            0.22808,
            0,
            36.88706,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            149.5309,
            0,
            1.43077,
            0,
            16.4662,
            0,
            -0.21521,
            0,
            0,
            0,
            29.34952,
            0,
            18.681,
            "<D",
            113.117,
            0,
        ],
        1728030540: [
            0.20437,
            0,
            0.20437,
            0,
            35.08529,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            155.9763,
            0,
            1.28964,
            0,
            16.45472,
            0,
            -0.12581,
            0,
            0,
            0,
            29.34934,
            0,
            13.61524,
            "<D",
            113.2553,
            0,
        ],
        1728030600: [
            0.29354,
            0,
            0.29354,
            0,
            37.72811,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            156.482,
            0,
            1.39013,
            0,
            16.44388,
            0,
            -0.08939,
            0,
            0,
            0,
            29.34907,
            0,
            18.12928,
            "<D",
            112.9228,
            0,
        ],
        1728030660: [
            0.3561,
            0,
            0.3561,
            0,
            36.51593,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            157.9616,
            0,
            1.34815,
            0,
            16.43595,
            0,
            -0.05628,
            0,
            0,
            0,
            29.34842,
            0,
            16.53838,
            "<D",
            113.0169,
            0,
        ],
        1728030720: [
            0.20488,
            0,
            0.20488,
            0,
            38.55657,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            138.0545,
            0,
            1.17704,
            0,
            16.39232,
            0,
            -0.09601,
            0,
            0,
            0,
            29.34887,
            0,
            17.1719,
            "<D",
            112.9674,
            0,
        ],
        1728030780: [
            0.26709,
            0,
            0.26709,
            0,
            37.3605,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            143.7521,
            0,
            0.76277,
            0,
            16.40343,
            0,
            -0.22182,
            0,
            0,
            0,
            29.35015,
            0,
            14.15798,
            "<D",
            113.0185,
            0,
        ],
        1728030840: [
            0.24739,
            0,
            0.24739,
            0,
            36.80363,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            161.3605,
            0,
            0.9525,
            0,
            16.44656,
            0,
            -0.23838,
            0,
            0,
            0,
            29.35007,
            0,
            19.37243,
            "<D",
            112.7916,
            0,
        ],
        1728030900: [
            0.34832,
            0,
            0.34832,
            0,
            36.43061,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            155.479,
            0,
            0.80053,
            0,
            16.5634,
            0,
            -0.23507,
            0,
            0,
            0,
            29.35013,
            0,
            14.49689,
            "<D",
            112.7487,
            0,
        ],
        1728030960: [
            0.26661,
            0,
            0.26661,
            0,
            37.03785,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            155.0679,
            0,
            0.70745,
            0,
            16.65283,
            0,
            -0.21189,
            0,
            0,
            0,
            29.35027,
            0,
            15.93085,
            "<D",
            112.5916,
            0,
        ],
        1728031020: [
            0.16562,
            0,
            0.16562,
            0,
            35.88961,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            109.892,
            0,
            0.48294,
            0,
            16.59575,
            0,
            -0.14236,
            0,
            0,
            0,
            29.35023,
            0,
            19.00337,
            "<D",
            112.0836,
            0,
        ],
        1728031080: [
            0.12114,
            0,
            0.12114,
            0,
            34.90903,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            52.83374,
            0,
            0.5748,
            0,
            16.3481,
            0,
            -0.12912,
            0,
            0,
            0,
            29.35021,
            0,
            14.2323,
            "<D",
            112.3961,
            0,
        ],
        1728031140: [
            0.23027,
            0,
            0.23027,
            0,
            23.72792,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            58.00071,
            0,
            0.63104,
            0,
            16.0861,
            0,
            -0.11919,
            0,
            0,
            0,
            29.35065,
            0,
            17.95231,
            "<D",
            112.167,
            0,
        ],
        1728031200: [
            0.18777,
            0,
            0.18777,
            0,
            19.12041,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            63.01476,
            0,
            0.63536,
            0,
            15.89344,
            0,
            -0.10925,
            0,
            0,
            0,
            29.35131,
            0,
            18.09317,
            "<D",
            112.1408,
            0,
        ],
        1728031260: [
            0.11167,
            0,
            0.11167,
            0,
            21.08807,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            60.02698,
            0,
            0.6701,
            0,
            15.7652,
            0,
            -0.08939,
            0,
            0,
            0,
            29.3533,
            0,
            15.48469,
            "<D",
            111.9089,
            0,
        ],
        1728031320: [
            0.14082,
            0,
            0.14082,
            0,
            18.36592,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            57.63548,
            0,
            0.66367,
            0,
            15.6392,
            0,
            -0.07615,
            0,
            0,
            0,
            29.3534,
            0,
            16.31727,
            "<D",
            111.7328,
            0,
        ],
        1728031380: [
            0.29949,
            0,
            0.29949,
            0,
            22.96277,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            82.44428,
            0,
            0.64407,
            0,
            15.56448,
            0,
            -0.12912,
            0,
            0,
            0,
            29.35293,
            0,
            16.62398,
            "<D",
            111.7274,
            0,
        ],
        1728031440: [
            0.19396,
            0,
            0.19396,
            0,
            15.38344,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            53.35815,
            0,
            0.62981,
            0,
            15.6084,
            0,
            -0.1854,
            0,
            0,
            0,
            29.3533,
            0,
            16.02693,
            "<D",
            111.6909,
            0,
        ],
        1728031500: [
            0.16753,
            0,
            0.16753,
            0,
            23.09322,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            70.95361,
            0,
            0.62322,
            0,
            15.67115,
            0,
            -0.2152,
            0,
            0,
            0,
            29.35282,
            0,
            16.47814,
            "<D",
            111.2757,
            0,
        ],
        1728031560: [
            0.22833,
            0,
            0.22833,
            0,
            22.11894,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            91.27353,
            0,
            0.68542,
            0,
            15.71362,
            0,
            -0.21189,
            0,
            0,
            0,
            29.3527,
            0,
            17.4067,
            "<D",
            111.7675,
            0,
        ],
        1728031620: [
            0.13768,
            0,
            0.13768,
            0,
            22.10776,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            86.89874,
            0,
            0.65957,
            0,
            16.01371,
            0,
            -0.17547,
            0,
            0,
            0,
            29.352,
            0,
            17.87266,
            "<D",
            111.8198,
            0,
        ],
        1728031680: [
            0.10108,
            0,
            0.10108,
            0,
            29.06193,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            93.41088,
            0,
            0.57099,
            0,
            16.23956,
            0,
            -0.15561,
            0,
            0,
            0,
            29.35159,
            0,
            13.78972,
            "<D",
            112.0044,
            0,
        ],
        1728031740: [
            0.25416,
            0,
            0.25416,
            0,
            32.36316,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            86.91978,
            0,
            0.4903,
            0,
            16.29201,
            0,
            -0.17878,
            0,
            0,
            0,
            29.35112,
            0,
            15.30745,
            "<D",
            112.1535,
            0,
        ],
        1728031800: [
            0.26154,
            0,
            0.26154,
            0,
            33.83637,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            83.30535,
            0,
            0.51056,
            0,
            16.2516,
            0,
            -0.11256,
            0,
            0,
            0,
            29.35287,
            0,
            18.49967,
            "<D",
            111.8693,
            0,
        ],
        1728031860: [
            0.20985,
            0,
            0.20985,
            0,
            34.50059,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            82.37463,
            0,
            0.48212,
            0,
            16.28939,
            0,
            -0.10263,
            0,
            0,
            0,
            29.35179,
            0,
            15.68338,
            "<D",
            112.0575,
            0,
        ],
        1728031920: [
            0.20877,
            0,
            0.20877,
            0,
            35.14393,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            69.46685,
            0,
            0.44233,
            0,
            16.38652,
            0,
            -0.06952,
            0,
            0,
            0,
            29.35234,
            0,
            16.15551,
            "<D",
            112.0561,
            0,
        ],
        1728031980: [
            0.22033,
            0,
            0.22033,
            0,
            35.80082,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            49.75779,
            0,
            0.43996,
            0,
            16.41031,
            0,
            -0.13243,
            0,
            0,
            0,
            29.35303,
            0,
            14.39517,
            "<D",
            112.0441,
            0,
        ],
        1728032040: [
            0.16731,
            0,
            0.16731,
            0,
            35.85343,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            64.52918,
            0,
            0.4808,
            0,
            16.46658,
            0,
            -0.22514,
            0,
            0,
            0,
            29.35342,
            0,
            16.90691,
            "<D",
            112.3283,
            0,
        ],
        1728032100: [
            0.11861,
            0,
            0.11861,
            0,
            36.35263,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            75.80939,
            0,
            0.57516,
            0,
            16.52544,
            0,
            -0.22845,
            0,
            0,
            0,
            29.35332,
            0,
            15.66604,
            "<D",
            112.6384,
            0,
        ],
        1728032160: [
            0.17654,
            0,
            0.17654,
            0,
            36.40037,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            60.69605,
            0,
            0.49328,
            0,
            16.53167,
            0,
            -0.23176,
            0,
            0,
            0,
            29.35351,
            0,
            14.60596,
            "<D",
            112.3767,
            0,
        ],
        1728032220: [
            0.18567,
            0,
            0.18567,
            0,
            36.39854,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            72.89804,
            0,
            0.47888,
            0,
            16.60664,
            0,
            -0.20196,
            0,
            0,
            0,
            29.35343,
            0,
            16.29033,
            "<D",
            111.7597,
            0,
        ],
        1728032280: [
            0.2548,
            0,
            0.2548,
            0,
            36.32137,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            96.83207,
            0,
            0.55333,
            0,
            16.61423,
            0,
            -0.25825,
            0,
            0,
            0,
            29.35348,
            0,
            16.30503,
            "<D",
            112.5118,
            0,
        ],
        1728032340: [
            0.26352,
            0,
            0.26352,
            0,
            36.28096,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            107.029,
            0,
            0.56794,
            0,
            16.48239,
            0,
            -0.25494,
            0,
            0,
            0,
            29.35349,
            0,
            12.68738,
            "<D",
            112.5709,
            0,
        ],
        1728032400: [
            0.20703,
            0,
            0.20703,
            0,
            35.75396,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            114.6471,
            0,
            0.54565,
            0,
            16.30951,
            0,
            -0.27811,
            0,
            0,
            0,
            29.35353,
            0,
            16.24692,
            "<D",
            112.5991,
            0,
        ],
        1728032460: [
            -0.00799,
            0,
            -0.00799,
            0,
            34.83659,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            115.493,
            0,
            0.59327,
            0,
            16.23575,
            0,
            -0.27811,
            0,
            0,
            0,
            29.35352,
            0,
            17.97721,
            "<D",
            112.7197,
            0,
        ],
        1728032520: [
            0.31583,
            0,
            0.31583,
            0,
            34.04294,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            134.1327,
            0,
            0.6493,
            0,
            16.10589,
            0,
            -0.28473,
            0,
            0,
            0,
            29.35353,
            0,
            15.46812,
            "<D",
            111.982,
            0,
        ],
        1728032580: [
            0.00332,
            0,
            0.00332,
            0,
            33.79523,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            156.0022,
            0,
            0.79134,
            0,
            16.02665,
            0,
            -0.20526,
            0,
            0,
            0,
            29.35347,
            0,
            16.46934,
            "<D",
            112.1438,
            0,
        ],
        1728032640: [
            0.23219,
            0,
            0.23219,
            0,
            35.01959,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            152.2597,
            0,
            0.84551,
            0,
            16.01165,
            0,
            -0.18871,
            0,
            0,
            0,
            29.35347,
            0,
            16.94703,
            "<D",
            112.3418,
            0,
        ],
        1728032700: [
            0.13467,
            0,
            0.13467,
            0,
            35.66608,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            149.663,
            0,
            0.93324,
            0,
            16.03974,
            0,
            -0.22843,
            0,
            0,
            0,
            29.3534,
            0,
            15.38674,
            "<D",
            111.7419,
            0,
        ],
        1728032760: [
            0.14618,
            0,
            0.14618,
            0,
            35.03419,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            157.6433,
            0,
            1.11721,
            0,
            15.92531,
            0,
            -0.20857,
            0,
            0,
            0,
            29.3533,
            0,
            14.47296,
            "<D",
            111.5537,
            0,
        ],
        1728032820: [
            0.13392,
            0,
            0.13392,
            0,
            36.12156,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            165.4177,
            0,
            1.41649,
            0,
            15.93097,
            0,
            -0.09932,
            0,
            0,
            0,
            29.35326,
            0,
            16.82181,
            "<D",
            111.492,
            0,
        ],
        1728032880: [
            0.12481,
            0,
            0.12481,
            0,
            36.16972,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            153.8553,
            0,
            1.39488,
            0,
            15.96312,
            0,
            -0.1556,
            0,
            0,
            0,
            29.35339,
            0,
            14.84621,
            "<D",
            111.6499,
            0,
        ],
        1728032940: [
            0.11552,
            0,
            0.11552,
            0,
            38.89229,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            145.5937,
            0,
            1.02686,
            0,
            16.00482,
            0,
            -0.1854,
            0,
            0,
            0,
            29.35345,
            0,
            17.28996,
            "<D",
            111.4991,
            0,
        ],
        1728033000: [
            0.21294,
            0,
            0.21294,
            0,
            37.72959,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            154.5392,
            0,
            1.02349,
            0,
            16.0042,
            0,
            -0.14236,
            0,
            0,
            0,
            29.3534,
            0,
            16.61133,
            "<D",
            110.8305,
            0,
        ],
        1728033060: [
            0.28618,
            0,
            0.28618,
            0,
            37.14141,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            134.3369,
            0,
            1.18473,
            0,
            16.28995,
            0,
            -0.22844,
            0,
            0,
            0,
            29.35348,
            0,
            15.90232,
            "<D",
            111.3621,
            0,
        ],
        1728033120: [
            0.26008,
            0,
            0.26008,
            0,
            38.22482,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            138.8616,
            0,
            1.2984,
            0,
            16.16723,
            0,
            -0.20858,
            0,
            0,
            0,
            29.35348,
            0,
            18.76126,
            "<D",
            111.5844,
            0,
        ],
        1728033180: [
            0.37278,
            0,
            0.37278,
            0,
            36.85204,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            150.7377,
            0,
            1.52981,
            0,
            16.19938,
            0,
            -0.21189,
            0,
            0,
            0,
            29.35346,
            0,
            16.57759,
            "<D",
            111.3686,
            0,
        ],
        1728033240: [
            0.34974,
            0,
            0.34974,
            0,
            37.64199,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            146.3925,
            0,
            0.74649,
            0,
            16.10795,
            0,
            -0.20196,
            0,
            0,
            0,
            29.35455,
            0,
            17.25839,
            "<D",
            111.5703,
            0,
        ],
        1728033300: [
            0.18971,
            0,
            0.18971,
            0,
            38.19674,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            140.5731,
            0,
            0.9689,
            0,
            16.16973,
            0,
            -0.1854,
            0,
            0,
            0,
            29.35351,
            0,
            14.91596,
            "<D",
            111.703,
            0,
        ],
        1728033360: [
            0.25988,
            0,
            0.25988,
            0,
            37.41455,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            140.1454,
            0,
            0.70676,
            0,
            16.17338,
            0,
            -0.20858,
            0,
            0,
            0,
            29.35557,
            0,
            19.13541,
            "<D",
            111.3522,
            0,
        ],
        1728033420: [
            0.24193,
            0,
            0.24193,
            0,
            36.39854,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            118.7066,
            0,
            0.92796,
            0,
            16.16451,
            0,
            -0.16223,
            0,
            0,
            0,
            29.35602,
            0,
            16.70564,
            "<D",
            111.4584,
            0,
        ],
        1728033480: [
            0.28239,
            0,
            0.28239,
            0,
            35.23996,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            140.0218,
            0,
            0.93232,
            0,
            16.10987,
            0,
            -0.13905,
            0,
            0,
            0,
            29.35633,
            0,
            17.34747,
            "<D",
            111.1893,
            0,
        ],
        1728033540: [
            0.37578,
            0,
            0.37578,
            0,
            36.18068,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            92.91812,
            0,
            0.83932,
            0,
            16.02368,
            0,
            -0.10263,
            0,
            0,
            0,
            29.35698,
            0,
            13.76314,
            "<D",
            111.4865,
            0,
        ],
        1728033600: [
            0.21026,
            0,
            0.21026,
            0,
            30.00014,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            128.1946,
            0,
            0.85493,
            0,
            16.0679,
            0,
            -0.04966,
            0,
            0,
            0,
            29.35691,
            0,
            15.14062,
            "<D",
            111.3595,
            0,
        ],
        1728033660: [
            0.37845,
            0,
            0.37845,
            0,
            34.26934,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            116.8262,
            0,
            0.53156,
            0,
            16.14852,
            0,
            -0.05628,
            0,
            0,
            0,
            29.35684,
            0,
            16.53102,
            "<D",
            111.6318,
            0,
        ],
        1728033720: [
            0.05851,
            0,
            0.05851,
            0,
            33.68801,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            117.7921,
            0,
            0.4006,
            0,
            16.20512,
            0,
            -0.05628,
            0,
            0,
            0,
            29.35685,
            0,
            18.34074,
            "<D",
            111.4868,
            0,
        ],
        1728033780: [
            0.22699,
            0,
            0.22699,
            0,
            33.4601,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            133.4213,
            0,
            0.54329,
            0,
            16.20722,
            0,
            -0.13905,
            0,
            0,
            0,
            29.35678,
            0,
            13.67136,
            "<D",
            111.5846,
            0,
        ],
        1728033840: [
            0.232,
            0,
            0.232,
            0,
            32.42983,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            118.6776,
            0,
            0.53705,
            0,
            16.18959,
            0,
            -0.17216,
            0,
            0,
            0,
            29.35678,
            0,
            14.08673,
            "<D",
            111.1761,
            0,
        ],
        1728033900: [
            0.10897,
            0,
            0.10897,
            0,
            33.832,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            123.4677,
            0,
            0.52878,
            0,
            16.132,
            0,
            -0.21189,
            0,
            0,
            0,
            29.35678,
            0,
            16.02151,
            "<D",
            111.4491,
            0,
        ],
        1728033960: [
            0.0692,
            0,
            0.0692,
            0,
            33.14389,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            133.6521,
            0,
            0.5176,
            0,
            16.12677,
            0,
            -0.22845,
            0,
            0,
            0,
            29.35678,
            0,
            15.77727,
            "<D",
            110.9782,
            0,
        ],
        1728034020: [
            0.06507,
            0,
            0.06507,
            0,
            34.21046,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            115.1777,
            0,
            0.40489,
            0,
            16.00644,
            0,
            -0.20196,
            0,
            0,
            0,
            29.35701,
            0,
            15.52868,
            "<D",
            110.9387,
            0,
        ],
        1728034080: [
            0.15,
            0,
            0.15,
            0,
            34.36737,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            112.3537,
            0,
            0.44107,
            0,
            15.93372,
            0,
            -0.18872,
            0,
            0,
            0,
            29.35751,
            0,
            16.23657,
            "<D",
            110.846,
            0,
        ],
        1728034140: [
            0.15046,
            0,
            0.15046,
            0,
            35.09234,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            111.5407,
            0,
            0.35371,
            0,
            15.98004,
            0,
            -0.15561,
            0,
            0,
            0,
            29.3577,
            0,
            14.06926,
            "<D",
            110.9346,
            0,
        ],
        1728034200: [
            0.30884,
            0,
            0.30884,
            0,
            35.57551,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            96.34901,
            0,
            0.34746,
            0,
            16.00326,
            0,
            -0.14237,
            0,
            0,
            0,
            29.35744,
            0,
            15.10288,
            "<D",
            111.357,
            0,
        ],
        1728034260: [
            0.24656,
            0,
            0.24656,
            0,
            35.86331,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            95.67048,
            0,
            0.22687,
            0,
            16.07604,
            0,
            -0.06622,
            0,
            0,
            0,
            29.35931,
            0,
            16.53428,
            "<D",
            113.0483,
            0,
        ],
        1728034320: [
            0.05073,
            0,
            0.05073,
            0,
            35.85784,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            102.3024,
            0,
            0.22277,
            0,
            16.12574,
            0,
            -0.13243,
            0,
            0,
            0,
            29.35907,
            0,
            16.49132,
            "<D",
            114.2505,
            0,
        ],
        1728034380: [
            0.03064,
            0,
            0.03064,
            0,
            36.15493,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            90.47454,
            0,
            0.22424,
            0,
            16.14676,
            0,
            -0.18872,
            0,
            0,
            0,
            29.36065,
            0,
            15.85315,
            "<D",
            114.3161,
            0,
        ],
        1728034440: [
            0.09634,
            0,
            0.09634,
            0,
            36.46768,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            77.15469,
            0,
            0.22682,
            0,
            16.10811,
            0,
            -0.23176,
            0,
            0,
            0,
            29.3608,
            0,
            15.45963,
            "<D",
            113.5332,
            0,
        ],
        1728034500: [
            0.1185,
            0,
            0.1185,
            0,
            35.88318,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            77.76322,
            0,
            0.2235,
            0,
            15.93382,
            0,
            -0.22514,
            0,
            0,
            0,
            29.36079,
            0,
            15.90167,
            "<D",
            112.9977,
            0,
        ],
        1728034560: [
            0.19484,
            0,
            0.19484,
            0,
            32.17933,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            74.63091,
            0,
            0.23378,
            0,
            15.95298,
            0,
            -0.20196,
            0,
            0,
            0,
            29.36073,
            0,
            16.66419,
            "<D",
            112.5828,
            0,
        ],
        1728034620: [
            0.03636,
            0,
            0.03636,
            0,
            29.93266,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            76.71651,
            0,
            0.59333,
            0,
            15.97092,
            0,
            -0.04966,
            0,
            0,
            0,
            29.36009,
            0,
            15.57202,
            "<D",
            112.5727,
            0,
        ],
        1728034680: [
            0.13473,
            0,
            0.13473,
            0,
            28.27505,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            79.93005,
            0,
            0.58419,
            0,
            15.97927,
            0,
            -0.05297,
            0,
            0,
            0,
            29.36015,
            0,
            15.12419,
            "<D",
            112.8283,
            0,
        ],
        1728034740: [
            0.21925,
            0,
            0.21925,
            0,
            24.67951,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            81.35957,
            0,
            0.61849,
            0,
            15.85237,
            0,
            -0.07283,
            0,
            0,
            0,
            29.36083,
            0,
            16.58225,
            "<D",
            112.5723,
            0,
        ],
        1728034800: [
            0.02905,
            0,
            0.02905,
            0,
            18.94013,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            65.12341,
            0,
            0.62934,
            0,
            15.75733,
            0,
            -0.05959,
            0,
            0,
            0,
            29.36118,
            0,
            13.45742,
            "<D",
            112.6683,
            0,
        ],
        1728034860: [
            0.08264,
            0,
            0.08264,
            0,
            15.64244,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            53.1279,
            0,
            0.61301,
            0,
            15.85978,
            0,
            -0.08608,
            0,
            0,
            0,
            29.36366,
            0,
            16.80309,
            "<D",
            112.4551,
            0,
        ],
        1728034920: [
            0.29914,
            0,
            0.29914,
            0,
            13.7209,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            50.59139,
            0,
            0.66243,
            0,
            15.84162,
            0,
            -0.10594,
            0,
            0,
            0,
            29.36358,
            0,
            16.11272,
            "<D",
            112.5999,
            0,
        ],
        1728034980: [
            0.22679,
            0,
            0.22679,
            0,
            18.42621,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            49.38597,
            0,
            0.65933,
            0,
            15.85132,
            0,
            -0.13243,
            0,
            0,
            0,
            29.36398,
            0,
            17.33859,
            "<D",
            112.5482,
            0,
        ],
        1728035040: [
            0.14865,
            0,
            0.14865,
            0,
            21.28513,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            49.32048,
            0,
            0.6684,
            0,
            15.92603,
            0,
            -0.11588,
            0,
            0,
            0,
            29.36401,
            0,
            15.54514,
            "<D",
            113.1565,
            0,
        ],
        1728035100: [
            0.55108,
            0,
            0.55108,
            0,
            24.61173,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            52.08294,
            0,
            0.66351,
            0,
            15.89776,
            0,
            -0.1854,
            0,
            0,
            0,
            29.36416,
            0,
            14.93325,
            "<D",
            113.2168,
            0,
        ],
        1728035160: [
            0.18522,
            0,
            0.18522,
            0,
            24.75804,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            58.77346,
            0,
            0.60733,
            0,
            15.85655,
            0,
            -0.18209,
            0,
            0,
            0,
            29.36474,
            0,
            14.78978,
            "<D",
            112.8645,
            0,
        ],
        1728035220: [
            0.191,
            0,
            0.191,
            0,
            25.28355,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            59.77582,
            0,
            0.5974,
            0,
            15.86768,
            0,
            -0.14898,
            0,
            0,
            0,
            29.36598,
            0,
            15.32737,
            "<D",
            112.8535,
            0,
        ],
        1728035280: [
            0.28708,
            0,
            0.28708,
            0,
            25.4961,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            60.8237,
            0,
            0.57837,
            0,
            15.7778,
            0,
            -0.10925,
            0,
            0,
            0,
            29.36682,
            0,
            14.85082,
            "<D",
            112.6423,
            0,
        ],
        1728035340: [
            0.00908,
            0,
            0.00908,
            0,
            25.06686,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            59.56609,
            0,
            0.56625,
            0,
            15.75212,
            0,
            -0.17216,
            0,
            0,
            0,
            29.36748,
            0,
            18.51761,
            "<D",
            112.4329,
            0,
        ],
        1728035400: [
            0.36948,
            0,
            0.36948,
            0,
            27.87942,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            72.32721,
            0,
            0.56418,
            0,
            15.55167,
            0,
            -0.10594,
            0,
            0,
            0,
            29.36791,
            0,
            14.02226,
            "<D",
            112.8743,
            0,
        ],
        1728035460: [
            0.35714,
            0,
            0.35714,
            0,
            28.83651,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            66.54293,
            0,
            0.51551,
            0,
            15.51133,
            0,
            -0.0629,
            0,
            0,
            0,
            29.36761,
            0,
            13.91372,
            "<D",
            112.9495,
            0,
        ],
        1728035520: [
            0.2174,
            0,
            0.2174,
            0,
            29.54508,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            71.49799,
            0,
            0.51866,
            0,
            15.58034,
            0,
            -0.06621,
            0,
            0,
            0,
            29.36757,
            0,
            13.61619,
            "<D",
            113.1556,
            0,
        ],
        1728035580: [
            0.26303,
            0,
            0.26303,
            0,
            30.97537,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            74.9846,
            0,
            0.57477,
            0,
            15.61016,
            0,
            -0.06621,
            0,
            0,
            0,
            29.3675,
            0,
            17.0067,
            "<D",
            113.2541,
            0,
        ],
        1728035640: [
            0.10547,
            0,
            0.10547,
            0,
            30.79286,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            68.95768,
            0,
            0.59123,
            0,
            15.5817,
            0,
            -0.05628,
            0,
            0,
            0,
            29.36736,
            0,
            15.19496,
            "<D",
            113.0148,
            0,
        ],
        1728035700: [
            0.31205,
            0,
            0.31205,
            0,
            31.34273,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            80.87811,
            0,
            0.56981,
            0,
            15.66202,
            0,
            -0.14899,
            0,
            0,
            0,
            29.36736,
            0,
            15.19839,
            "<D",
            112.5362,
            0,
        ],
        1728035760: [
            0.30373,
            0,
            0.30373,
            0,
            31.18817,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            81.57342,
            0,
            0.52655,
            0,
            15.74485,
            0,
            -0.05959,
            0,
            0,
            0,
            29.36736,
            0,
            16.9467,
            "<D",
            112.7949,
            0,
        ],
        1728035820: [
            0.24706,
            0,
            0.24706,
            0,
            33.98187,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            89.90208,
            0,
            0.58397,
            0,
            15.85518,
            0,
            -0.15892,
            0,
            0,
            0,
            29.36736,
            0,
            12.90215,
            "<D",
            113.3052,
            0,
        ],
        1728035880: [
            0.29491,
            0,
            0.29491,
            0,
            32.05101,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            94.45406,
            0,
            0.62008,
            0,
            15.86402,
            0,
            -0.19203,
            0,
            0,
            0,
            29.36736,
            0,
            14.51218,
            "<D",
            113.2712,
            0,
        ],
        1728035940: [
            0.20821,
            0,
            0.20821,
            0,
            33.40722,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            91.43658,
            0,
            0.61189,
            0,
            15.87216,
            0,
            -0.21852,
            0,
            0,
            0,
            29.36751,
            0,
            15.69336,
            "<D",
            112.985,
            0,
        ],
        1728036000: [
            0.15315,
            0,
            0.15315,
            0,
            33.22928,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            80.22601,
            0,
            0.56388,
            0,
            15.88833,
            0,
            -0.23176,
            0,
            0,
            0,
            29.36842,
            0,
            14.50522,
            "<D",
            112.7207,
            0,
        ],
        1728036060: [
            0.23969,
            0,
            0.23969,
            0,
            33.89547,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            72.07539,
            0,
            0.48202,
            0,
            15.79212,
            0,
            -0.21852,
            0,
            0,
            0,
            29.36928,
            0,
            15.10987,
            "<D",
            112.9754,
            0,
        ],
        1728036120: [
            0.12874,
            0,
            0.12874,
            0,
            32.2568,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            67.16109,
            0,
            0.52324,
            0,
            15.66909,
            0,
            -0.07946,
            0,
            0,
            0,
            29.37087,
            0,
            16.72825,
            "<D",
            113.1106,
            0,
        ],
        1728036180: [
            0.09704,
            0,
            0.09704,
            0,
            27.48355,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            66.04368,
            0,
            0.52641,
            0,
            15.61964,
            0,
            -0.06622,
            0,
            0,
            0,
            29.37113,
            0,
            15.09375,
            "<D",
            111.8049,
            0,
        ],
        1728036240: [
            0.37815,
            0,
            0.37815,
            0,
            24.258,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            70.6073,
            0,
            0.50264,
            0,
            15.67998,
            0,
            -0.07946,
            0,
            0,
            0,
            29.37119,
            0,
            14.32254,
            "<D",
            113.5941,
            0,
        ],
        1728036300: [
            0.39278,
            0,
            0.39278,
            0,
            28.6507,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            56.52978,
            0,
            0.42378,
            0,
            15.69292,
            0,
            -0.13574,
            0,
            0,
            0,
            29.37122,
            0,
            12.67886,
            "<D",
            113.1753,
            0,
        ],
        1728036360: [
            0.09465,
            0,
            0.09465,
            0,
            25.93258,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            68.75927,
            0,
            0.4926,
            0,
            15.52662,
            0,
            -0.22183,
            0,
            0,
            0,
            29.37136,
            0,
            16.93993,
            "<D",
            113.8719,
            0,
        ],
        1728036420: [
            0.28773,
            0,
            0.28773,
            0,
            27.60862,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            60.50869,
            0,
            0.43464,
            0,
            15.43727,
            0,
            -0.22845,
            0,
            0,
            0,
            29.3718,
            0,
            15.93712,
            "<D",
            114.1237,
            0,
        ],
        1728036480: [
            0.3597,
            0,
            0.3597,
            0,
            23.59818,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            58.21773,
            0,
            0.46263,
            0,
            15.40035,
            0,
            -0.17548,
            0,
            0,
            0,
            29.37221,
            0,
            14.98575,
            "<D",
            113.1325,
            0,
        ],
        1728036540: [
            0.13843,
            0,
            0.13843,
            0,
            24.49901,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            59.653,
            0,
            0.32328,
            0,
            15.50105,
            0,
            -0.07284,
            0,
            0,
            0,
            29.37282,
            0,
            15.21215,
            "<D",
            112.6146,
            0,
        ],
        1728036600: [
            0.2417,
            0,
            0.2417,
            0,
            25.30099,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            71.15922,
            0,
            0.31038,
            0,
            15.5807,
            0,
            -0.08277,
            0,
            0,
            0,
            29.37445,
            0,
            18.25314,
            "<D",
            112.278,
            0,
        ],
        1728036660: [
            0.3322,
            0,
            0.3322,
            0,
            23.03581,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            60.36942,
            0,
            0.26733,
            0,
            15.79103,
            0,
            -0.10925,
            0,
            0,
            0,
            29.37463,
            0,
            16.9108,
            "<D",
            112.0623,
            0,
        ],
        1728036720: [
            0.28357,
            0,
            0.28357,
            0,
            21.53519,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            59.78966,
            0,
            0.22242,
            0,
            15.55433,
            0,
            -0.18209,
            0,
            0,
            0,
            29.37532,
            0,
            15.22271,
            "<D",
            112.0401,
            0,
        ],
        1728036780: [
            0.2074,
            0,
            0.2074,
            0,
            21.54475,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            70.95875,
            0,
            0.22371,
            0,
            15.53335,
            0,
            -0.22182,
            0,
            0,
            0,
            29.37537,
            0,
            15.55446,
            "<D",
            111.9791,
            0,
        ],
        1728036840: [
            0.28255,
            0,
            0.28255,
            0,
            19.83723,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            68.01837,
            0,
            0.22361,
            0,
            15.52519,
            0,
            -0.20858,
            0,
            0,
            0,
            29.37563,
            0,
            15.82917,
            "<D",
            112.2437,
            0,
        ],
        1728036900: [
            0.24897,
            0,
            0.24897,
            0,
            19.83919,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            58.65312,
            0,
            0.22277,
            0,
            15.60765,
            0,
            -0.22513,
            0,
            0,
            0,
            29.37575,
            0,
            12.13629,
            "<D",
            112.4717,
            0,
        ],
        1728036960: [
            0.25865,
            0,
            0.25865,
            0,
            19.57248,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            42.37756,
            0,
            0.22402,
            0,
            15.60453,
            0,
            -0.20196,
            0,
            0,
            0,
            29.3767,
            0,
            14.8201,
            "<D",
            112.404,
            0,
        ],
        1728037020: [
            0.2789,
            0,
            0.2789,
            0,
            20.30946,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            38.28666,
            0,
            0.2237,
            0,
            15.67993,
            0,
            -0.30459,
            0,
            0,
            0,
            29.37682,
            0,
            14.31642,
            "<D",
            112.2924,
            0,
        ],
        1728037080: [
            0.06663,
            0,
            0.06663,
            0,
            21.4369,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            41.80966,
            0,
            0.22315,
            0,
            15.59981,
            0,
            -0.39066,
            0,
            0,
            0,
            29.37786,
            0,
            15.8724,
            "<D",
            112.5597,
            0,
        ],
        1728037140: [
            0.3197,
            0,
            0.3197,
            0,
            22.15015,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            59.83212,
            0,
            0.22338,
            0,
            15.53202,
            0,
            -0.36749,
            0,
            0,
            0,
            29.3778,
            0,
            14.73035,
            "<D",
            112.4519,
            0,
        ],
        1728037200: [
            0.38471,
            0,
            0.38471,
            0,
            19.09942,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            71.13529,
            0,
            0.22315,
            0,
            15.65719,
            0,
            -0.38073,
            0,
            0,
            0,
            29.37768,
            0,
            12.73403,
            "<D",
            112.3601,
            0,
        ],
        1728037260: [
            0.23041,
            0,
            0.23041,
            0,
            17.84859,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            81.3905,
            0,
            0.2237,
            0,
            15.66054,
            0,
            -0.36749,
            0,
            0,
            0,
            29.37852,
            0,
            16.6328,
            "<D",
            111.783,
            0,
        ],
        1728037320: [
            0.2347,
            0,
            0.2347,
            0,
            19.21539,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            80.85968,
            0,
            0.22422,
            0,
            15.6218,
            0,
            -0.38074,
            0,
            0,
            0,
            29.37864,
            0,
            14.64966,
            "<D",
            111.9407,
            0,
        ],
        1728037380: [
            0.22117,
            0,
            0.22117,
            0,
            18.8049,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            81.47985,
            0,
            0.25554,
            0,
            15.70344,
            0,
            -0.34763,
            0,
            0,
            0,
            29.37814,
            0,
            14.59826,
            "<D",
            112.1312,
            0,
        ],
        1728037440: [
            0.34313,
            0,
            0.34313,
            0,
            19.77678,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            73.86665,
            0,
            0.52504,
            0,
            15.75918,
            0,
            -0.31451,
            0,
            0,
            0,
            29.37826,
            0,
            13.57905,
            "<D",
            112.0586,
            0,
        ],
        1728037500: [
            0.15019,
            0,
            0.15019,
            0,
            25.73711,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            65.55918,
            0,
            0.58363,
            0,
            15.81858,
            0,
            -0.28802,
            0,
            0,
            0,
            29.37817,
            0,
            14.62886,
            "<D",
            111.5932,
            0,
        ],
        1728037560: [
            0.09016,
            0,
            0.09016,
            0,
            28.11876,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            64.15621,
            0,
            0.49579,
            0,
            15.86938,
            0,
            -0.26154,
            0,
            0,
            0,
            29.37818,
            0,
            17.22489,
            "<D",
            111.8641,
            0,
        ],
        1728037620: [
            0.15891,
            0,
            0.15891,
            0,
            28.06038,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            63.1054,
            0,
            0.52522,
            0,
            15.84671,
            0,
            -0.25823,
            0,
            0,
            0,
            29.3784,
            0,
            14.86824,
            "<D",
            112.1976,
            0,
        ],
        1728037680: [
            0.18438,
            0,
            0.18438,
            0,
            27.47292,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            63.15152,
            0,
            0.55104,
            0,
            15.83179,
            0,
            -0.25823,
            0,
            0,
            0,
            29.37823,
            0,
            13.90542,
            "<D",
            112.0141,
            0,
        ],
        1728037740: [
            0.14368,
            0,
            0.14368,
            0,
            31.35364,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            56.30587,
            0,
            0.45342,
            0,
            15.80529,
            0,
            -0.26154,
            0,
            0,
            0,
            29.37849,
            0,
            13.36573,
            "<D",
            111.8798,
            0,
        ],
        1728037800: [
            0.07422,
            0,
            0.07422,
            0,
            32.33752,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            42.55303,
            0,
            0.52244,
            0,
            15.83935,
            0,
            -0.27479,
            0,
            0,
            0,
            29.3787,
            0,
            14.42891,
            "<D",
            112.1467,
            0,
        ],
        1728037860: [
            0.29675,
            0,
            0.29675,
            0,
            32.01001,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            48.02393,
            0,
            0.57665,
            0,
            15.86162,
            0,
            -0.30128,
            0,
            0,
            0,
            29.37874,
            0,
            14.80079,
            "<D",
            112.2604,
            0,
        ],
        1728037920: [
            0.1188,
            0,
            0.1188,
            0,
            29.78463,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            70.3101,
            0,
            0.52717,
            0,
            15.84021,
            0,
            -0.35094,
            0,
            0,
            0,
            29.37875,
            0,
            13.70634,
            "<D",
            112.3477,
            0,
        ],
        1728037980: [
            0.24009,
            0,
            0.24009,
            0,
            23.31536,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            68.58264,
            0,
            0.49964,
            0,
            15.8253,
            0,
            -0.38737,
            0,
            0,
            0,
            29.37886,
            0,
            13.91581,
            "<D",
            112.3158,
            0,
        ],
        1728038040: [
            0.18653,
            0,
            0.18653,
            0,
            24.80019,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            84.67782,
            0,
            0.49489,
            0,
            15.81293,
            0,
            -0.3675,
            0,
            0,
            0,
            29.37954,
            0,
            15.67272,
            "<D",
            112.4971,
            0,
        ],
        1728038100: [
            0.1651,
            0,
            0.1651,
            0,
            29.45439,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            76.51105,
            0,
            0.44844,
            0,
            15.8518,
            0,
            -0.23838,
            0,
            0,
            0,
            29.3802,
            0,
            13.17771,
            "<D",
            112.3981,
            0,
        ],
        1728038160: [
            0.16061,
            0,
            0.16061,
            0,
            30.84363,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            82.92097,
            0,
            0.4706,
            0,
            15.86167,
            0,
            -0.13243,
            0,
            0,
            0,
            29.38091,
            0,
            16.6756,
            "<D",
            112.478,
            0,
        ],
        1728038220: [
            0.21865,
            0,
            0.21865,
            0,
            31.86623,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            87.86416,
            0,
            0.2919,
            0,
            15.89719,
            0,
            -0.09932,
            0,
            0,
            0,
            29.38093,
            0,
            16.0513,
            "<D",
            112.0926,
            0,
        ],
        1728038280: [
            0.0655,
            0,
            0.0655,
            0,
            33.26158,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            101.3032,
            0,
            0.22356,
            0,
            15.84044,
            0,
            -0.10264,
            0,
            0,
            0,
            29.38158,
            0,
            14.61212,
            "<D",
            112.2912,
            0,
        ],
        1728038340: [
            0.05528,
            0,
            0.05528,
            0,
            34.7975,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            92.75436,
            0,
            0.22351,
            0,
            15.86517,
            0,
            -0.11919,
            0,
            0,
            0,
            29.38177,
            0,
            14.57868,
            "<D",
            112.3974,
            0,
        ],
        1728038400: [
            0.26524,
            0,
            0.26524,
            0,
            33.96869,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            76.70449,
            0,
            0.21994,
            0,
            15.86996,
            0,
            -0.08939,
            0,
            0,
            0,
            29.38197,
            0,
            16.14658,
            "<D",
            113.2207,
            0,
        ],
        1728038460: [
            0.17378,
            0,
            0.17378,
            0,
            32.36751,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            58.13455,
            0,
            0.22102,
            0,
            15.87434,
            0,
            -0.13575,
            0,
            0,
            0,
            29.38201,
            0,
            14.54863,
            "<D",
            112.7068,
            0,
        ],
        1728038520: [
            0.32372,
            0,
            0.32372,
            0,
            31.14405,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            49.60708,
            0,
            0.22225,
            0,
            15.84812,
            0,
            -0.08939,
            0,
            0,
            0,
            29.38222,
            0,
            14.84536,
            "<D",
            112.9823,
            0,
        ],
        1728038580: [
            0.41336,
            0,
            0.41336,
            0,
            32.419,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            46.28593,
            0,
            0.36987,
            0,
            15.68235,
            0,
            -0.08608,
            0,
            0,
            0,
            29.38226,
            0,
            11.35733,
            "<D",
            113.5311,
            0,
        ],
        1728038640: [
            0.34027,
            0,
            0.34027,
            0,
            33.54359,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            58.19889,
            0,
            0.42188,
            0,
            15.64902,
            0,
            -0.08939,
            0,
            0,
            0,
            29.38215,
            0,
            15.29252,
            "<D",
            113.0284,
            0,
        ],
        1728038700: [
            0.34904,
            0,
            0.34904,
            0,
            34.17893,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            64.28629,
            0,
            0.40805,
            0,
            15.57304,
            0,
            -0.11257,
            0,
            0,
            0,
            29.38194,
            0,
            14.18706,
            "<D",
            112.5485,
            0,
        ],
        1728038760: [
            0.46594,
            0,
            0.46594,
            0,
            30.13375,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            53.83089,
            0,
            0.48684,
            0,
            15.51156,
            0,
            -0.18541,
            0,
            0,
            0,
            29.38191,
            0,
            14.49182,
            "<D",
            112.7621,
            0,
        ],
        1728038820: [
            0.18297,
            0,
            0.18297,
            0,
            31.76724,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            62.82891,
            0,
            0.46068,
            0,
            15.48372,
            0,
            -0.2119,
            0,
            0,
            0,
            29.38197,
            0,
            15.57274,
            "<D",
            111.8934,
            0,
        ],
        1728038880: [
            0.29264,
            0,
            0.29264,
            0,
            27.61295,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            79.9826,
            0,
            0.45776,
            0,
            15.49453,
            0,
            -0.13906,
            0,
            0,
            0,
            29.38212,
            0,
            14.84769,
            "<D",
            111.8983,
            0,
        ],
        1728038940: [
            0.22311,
            0,
            0.22311,
            0,
            30.08414,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            80.41628,
            0,
            0.43723,
            0,
            15.5223,
            0,
            -0.10264,
            0,
            0,
            0,
            29.38201,
            0,
            16.86279,
            "<D",
            111.4274,
            0,
        ],
        1728039000: [
            0.17892,
            0,
            0.17892,
            0,
            28.21842,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6732,
            0,
            80.37704,
            0,
            0.48343,
            0,
            15.2473,
            0,
            -0.08608,
            0,
            0,
            0,
            29.38201,
            0,
            14.42725,
            "<D",
            112.1131,
            0,
        ],
        1728039060: [
            0.20395,
            0,
            0.20395,
            0,
            22.85212,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            76.00134,
            0,
            0.4297,
            0,
            15.3663,
            0,
            -0.05628,
            0,
            0,
            0,
            29.38203,
            0,
            14.67055,
            "<D",
            112.0107,
            0,
        ],
        1728039120: [
            0.23328,
            0,
            0.23328,
            0,
            21.10083,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            67.99379,
            0,
            0.45159,
            0,
            15.44274,
            0,
            -0.03973,
            0,
            0,
            0,
            29.38213,
            0,
            15.50824,
            "<D",
            112.1812,
            0,
        ],
        1728039180: [
            0.38424,
            0,
            0.38424,
            0,
            21.31129,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            78.69383,
            0,
            0.47912,
            0,
            15.48384,
            0,
            -0.06621,
            0,
            0,
            0,
            29.3822,
            0,
            15.56518,
            "<D",
            111.8985,
            0,
        ],
        1728039240: [
            0.25752,
            0,
            0.25752,
            0,
            19.05125,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            63.45168,
            0,
            0.47952,
            0,
            15.53721,
            0,
            -0.14567,
            0,
            0,
            0,
            29.38274,
            0,
            15.54008,
            "<D",
            111.4865,
            0,
        ],
        1728039300: [
            0.13881,
            0,
            0.13881,
            0,
            27.42393,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            48.31792,
            0,
            0.57851,
            0,
            15.56993,
            0,
            -0.10925,
            0,
            0,
            0,
            29.3826,
            0,
            14.55594,
            "<D",
            111.4579,
            0,
        ],
        1728039360: [
            0.13741,
            0,
            0.13741,
            0,
            29.69936,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            58.5044,
            0,
            0.54412,
            0,
            15.56065,
            0,
            -0.07615,
            0,
            0,
            0,
            29.38227,
            0,
            15.11911,
            "<D",
            111.3661,
            0,
        ],
        1728039420: [
            0.15051,
            0,
            0.15051,
            0,
            26.2256,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            59.47729,
            0,
            0.56285,
            0,
            15.51209,
            0,
            -0.08608,
            0,
            0,
            0,
            29.38228,
            0,
            17.11324,
            "<D",
            111.4268,
            0,
        ],
        1728039480: [
            0.25924,
            0,
            0.25924,
            0,
            22.47248,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            69.97427,
            0,
            0.57379,
            0,
            15.43035,
            0,
            -0.07946,
            0,
            0,
            0,
            29.38309,
            0,
            17.86602,
            "<D",
            111.6536,
            0,
        ],
        1728039540: [
            0.22931,
            0,
            0.22931,
            0,
            22.60106,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            81.34367,
            0,
            0.42174,
            0,
            15.44426,
            0,
            -0.07615,
            0,
            0,
            0,
            29.38307,
            0,
            14.70666,
            "<D",
            111.5203,
            0,
        ],
        1728039600: [
            0.35589,
            0,
            0.35589,
            0,
            26.68218,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            66.18363,
            0,
            0.22338,
            0,
            15.50227,
            0,
            -0.07614,
            0,
            0,
            0,
            29.38502,
            0,
            15.63807,
            "<D",
            111.8663,
            0,
        ],
        1728039660: [
            0.0945,
            0,
            0.0945,
            0,
            27.02083,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            68.32008,
            0,
            0.22358,
            0,
            15.55898,
            0,
            -0.06621,
            0,
            0,
            0,
            29.38463,
            0,
            15.99705,
            "<D",
            112.0448,
            0,
        ],
        1728039720: [
            0.26207,
            0,
            0.26207,
            0,
            26.92475,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            57.95036,
            0,
            0.21913,
            0,
            15.6185,
            0,
            -0.06622,
            0,
            0,
            0,
            29.3851,
            0,
            15.4002,
            "<D",
            112.039,
            0,
        ],
        1728039780: [
            0.21251,
            0,
            0.21251,
            0,
            28.83094,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            69.99631,
            0,
            0.22373,
            0,
            15.60574,
            0,
            -0.04304,
            0,
            0,
            0,
            29.38519,
            0,
            14.18138,
            "<D",
            111.9546,
            0,
        ],
        1728039840: [
            0.07558,
            0,
            0.07558,
            0,
            26.85242,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            69.58443,
            0,
            0.22371,
            0,
            15.62623,
            0,
            -0.08277,
            0,
            0,
            0,
            29.38516,
            0,
            14.76423,
            "<D",
            112.0099,
            0,
        ],
        1728039900: [
            0.16446,
            0,
            0.16446,
            0,
            29.9023,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            66.05814,
            0,
            0.22362,
            0,
            15.62901,
            0,
            -0.17879,
            0,
            0,
            0,
            29.38517,
            0,
            14.31046,
            "<D",
            111.6974,
            0,
        ],
        1728039960: [
            0.15568,
            0,
            0.15568,
            0,
            31.50488,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            76.2589,
            0,
            0.22074,
            0,
            15.59979,
            0,
            -0.20858,
            0,
            0,
            0,
            29.38536,
            0,
            18.3347,
            "<D",
            112.0965,
            0,
        ],
        1728040020: [
            0.11253,
            0,
            0.11253,
            0,
            30.84074,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            81.76663,
            0,
            0.22373,
            0,
            15.59924,
            0,
            -0.19534,
            0,
            0,
            0,
            29.38537,
            0,
            12.88822,
            "<D",
            112.2018,
            0,
        ],
        1728040080: [
            0.20074,
            0,
            0.20074,
            0,
            30.48055,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            95.1518,
            0,
            0.27039,
            0,
            15.60705,
            0,
            -0.23176,
            0,
            0,
            0,
            29.38544,
            0,
            12.81608,
            "<D",
            112.2555,
            0,
        ],
        1728040140: [
            0.29836,
            0,
            0.29836,
            0,
            30.05902,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            145.3528,
            0,
            0.29442,
            0,
            15.47631,
            0,
            -0.21852,
            0,
            0,
            0,
            29.38576,
            0,
            15.34316,
            "<D",
            112.4264,
            0,
        ],
        1728040200: [
            0.2179,
            0,
            0.2179,
            0,
            29.8359,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            105.7832,
            0,
            0.21872,
            0,
            15.50716,
            0,
            -0.17879,
            0,
            0,
            0,
            29.38595,
            0,
            14.40819,
            "<D",
            112.1014,
            0,
        ],
        1728040260: [
            0.07736,
            0,
            0.07736,
            0,
            29.45484,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            133.7376,
            0,
            0.22283,
            0,
            15.46215,
            0,
            -0.11919,
            0,
            0,
            0,
            29.38597,
            0,
            14.30801,
            "<D",
            112.3891,
            0,
        ],
        1728040320: [
            0.31059,
            0,
            0.31059,
            0,
            29.11595,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            125.8433,
            0,
            0.22118,
            0,
            15.41092,
            0,
            -0.07284,
            0,
            0,
            0,
            29.3859,
            0,
            14.53742,
            "<D",
            112.0763,
            0,
        ],
        1728040380: [
            0.10545,
            0,
            0.10545,
            0,
            29.12496,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            105.8552,
            0,
            0.22373,
            0,
            15.42909,
            0,
            -0.04635,
            0,
            0,
            0,
            29.38553,
            0,
            14.81938,
            "<D",
            111.9527,
            0,
        ],
        1728040440: [
            0.21275,
            0,
            0.21275,
            0,
            28.6381,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            93.79608,
            0,
            0.21948,
            0,
            15.43255,
            0,
            -0.06291,
            0,
            0,
            0,
            29.38535,
            0,
            17.32975,
            "<D",
            112.4411,
            0,
        ],
        1728040500: [
            0.02422,
            0,
            0.02422,
            0,
            25.58311,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            74.86885,
            0,
            0.33651,
            0,
            15.40504,
            0,
            -0.1225,
            0,
            0,
            0,
            29.38553,
            0,
            14.36453,
            "<D",
            112.1437,
            0,
        ],
        1728040560: [
            0.04741,
            0,
            0.04741,
            0,
            27.74004,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            74.21041,
            0,
            0.57983,
            0,
            15.38946,
            0,
            -0.0927,
            0,
            0,
            0,
            29.38541,
            0,
            15.7428,
            "<D",
            111.831,
            0,
        ],
        1728040620: [
            0.08975,
            0,
            0.08975,
            0,
            18.63359,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            62.89476,
            0,
            0.55084,
            0,
            15.4418,
            0,
            -0.13906,
            0,
            0,
            0,
            29.38527,
            0,
            13.89717,
            "<D",
            112.7306,
            0,
        ],
        1728040680: [
            0.06405,
            0,
            0.06405,
            0,
            26.04891,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            60.27214,
            0,
            0.50796,
            0,
            15.47229,
            0,
            -0.17548,
            0,
            0,
            0,
            29.38527,
            0,
            14.79857,
            "<D",
            113.0969,
            0,
        ],
        1728040740: [
            0.11169,
            0,
            0.11169,
            0,
            27.69116,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            64.34235,
            0,
            0.44121,
            0,
            15.41424,
            0,
            -0.16886,
            0,
            0,
            0,
            29.38527,
            0,
            14.6768,
            "<D",
            114.0807,
            0,
        ],
        1728040800: [
            0.28097,
            0,
            0.28097,
            0,
            28.34332,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            59.61454,
            0,
            0.4524,
            0,
            15.31417,
            0,
            -0.0927,
            0,
            0,
            0,
            29.38536,
            0,
            14.68,
            "<D",
            113.5858,
            0,
        ],
        1728040860: [
            0.12842,
            0,
            0.12842,
            0,
            28.54067,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            57.21066,
            0,
            0.44514,
            0,
            15.38773,
            0,
            -0.04635,
            0,
            0,
            0,
            29.38571,
            0,
            17.30486,
            "<D",
            113.2183,
            0,
        ],
        1728040920: [
            0.18022,
            0,
            0.18022,
            0,
            28.21596,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            54.35812,
            0,
            0.22162,
            0,
            15.49125,
            0,
            -0.01655,
            0,
            0,
            0,
            29.38593,
            0,
            13.5494,
            "<D",
            113.1042,
            0,
        ],
        1728040980: [
            0.29568,
            0,
            0.29568,
            0,
            27.94043,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            45.39328,
            0,
            0.22117,
            0,
            15.45221,
            0,
            0.04304,
            0,
            0,
            0,
            29.38596,
            0,
            14.18224,
            "<D",
            113.852,
            0,
        ],
        1728041040: [
            0.07499,
            0,
            0.07499,
            0,
            27.77181,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            44.38353,
            0,
            0.21939,
            0,
            15.27312,
            0,
            0.13244,
            0,
            0,
            0,
            29.38583,
            0,
            15.06873,
            "<D",
            111.8815,
            0,
        ],
        1728041100: [
            0.19117,
            0,
            0.19117,
            0,
            27.41622,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            57.40413,
            0,
            0.22291,
            0,
            15.22315,
            0,
            0.17217,
            0,
            0,
            0,
            29.38553,
            0,
            15.43102,
            "<D",
            111.9825,
            0,
        ],
        1728041160: [
            0.16519,
            0,
            0.16519,
            0,
            27.24384,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            52.99502,
            0,
            0.22318,
            0,
            15.09638,
            0,
            0.23177,
            0,
            0,
            0,
            29.38608,
            0,
            15.03872,
            "<D",
            112.039,
            0,
        ],
        1728041220: [
            0.09692,
            0,
            0.09692,
            0,
            27.08563,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            60.12276,
            0,
            0.22367,
            0,
            15.18797,
            0,
            0.45028,
            0,
            0,
            0,
            29.38689,
            0,
            14.35303,
            "<D",
            112.3707,
            0,
        ],
        1728041280: [
            0.27675,
            0,
            0.27675,
            0,
            27.11993,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            71.16644,
            0,
            0.21944,
            0,
            15.01276,
            0,
            0.53636,
            0,
            0,
            0,
            29.38618,
            0,
            14.42667,
            "<D",
            112.1145,
            0,
        ],
        1728041340: [
            0.16466,
            0,
            0.16466,
            0,
            26.4702,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            97.05376,
            0,
            0.2234,
            0,
            14.97337,
            0,
            0.64232,
            0,
            0,
            0,
            29.38769,
            0,
            16.13656,
            "<D",
            111.5493,
            0,
        ],
        1728041400: [
            0.12527,
            0,
            0.12527,
            0,
            25.51929,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5872,
            0,
            106.0679,
            0,
            0.22131,
            0,
            15.17057,
            0,
            0.72841,
            0,
            0,
            0,
            29.38753,
            0,
            13.56676,
            "<D",
            111.9351,
            0,
        ],
        1728041460: [
            0.19863,
            0,
            0.19863,
            0,
            26.45339,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            118.4086,
            0,
            0.22372,
            0,
            14.96312,
            0,
            0.8046,
            0,
            0,
            0,
            29.38816,
            0,
            17.87714,
            "<D",
            112.4711,
            0,
        ],
        1728041520: [
            0.16755,
            0,
            0.16755,
            0,
            24.27586,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            123.4155,
            0,
            0.22349,
            0,
            15.08085,
            0,
            0.95692,
            0,
            0,
            0,
            29.38856,
            0,
            15.66777,
            "<D",
            112.7758,
            0,
        ],
        1728041580: [
            0.19795,
            0,
            0.19795,
            0,
            25.05734,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5872,
            0,
            120.6161,
            0,
            0.22373,
            0,
            15.15354,
            0,
            1.07943,
            0,
            0,
            0,
            29.38868,
            0,
            15.63712,
            "<D",
            112.2332,
            0,
        ],
        1728041640: [
            0.39483,
            0,
            0.39483,
            0,
            20.35678,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5786,
            0,
            120.5343,
            0,
            0.22356,
            0,
            15.19322,
            0,
            1.23507,
            0,
            0,
            0,
            29.38893,
            0,
            15.61609,
            "<D",
            112.3569,
            0,
        ],
        1728041700: [
            0.20585,
            0,
            0.20585,
            0,
            19.05248,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            101.8767,
            0,
            0.22119,
            0,
            15.14525,
            0,
            1.45361,
            0,
            0,
            0,
            29.38876,
            0,
            14.35737,
            "<D",
            112.0351,
            0,
        ],
        1728041760: [
            0.18143,
            0,
            0.18143,
            0,
            16.75726,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            82.67923,
            0,
            0.25733,
            0,
            15.11535,
            0,
            1.65233,
            0,
            0,
            0,
            29.38898,
            0,
            14.56238,
            "<D",
            112.4977,
            0,
        ],
        1728041820: [
            0.23661,
            0,
            0.23661,
            0,
            14.53807,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            77.06695,
            0,
            0.51238,
            0,
            15.11788,
            0,
            1.9206,
            0,
            0,
            0,
            29.38898,
            0,
            12.13808,
            "<D",
            112.5682,
            0,
        ],
        1728041880: [
            0.22477,
            0,
            0.22477,
            0,
            13.75426,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            81.10299,
            0,
            0.51724,
            0,
            15.12688,
            0,
            2.22199,
            0,
            0,
            0,
            29.38893,
            0,
            16.09842,
            "<D",
            112.6198,
            0,
        ],
        1728041940: [
            0.1719,
            0,
            0.1719,
            0,
            11.74881,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            77.6082,
            0,
            0.51872,
            0,
            15.17642,
            0,
            2.51345,
            0,
            0,
            0,
            29.38912,
            0,
            14.54032,
            "<D",
            112.7143,
            0,
        ],
        1728042000: [
            0.2914,
            0,
            0.2914,
            0,
            11.11852,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            66.91528,
            0,
            0.50911,
            0,
            15.16589,
            0,
            2.83477,
            0,
            0,
            0,
            29.38937,
            0,
            13.1326,
            "<D",
            112.9981,
            0,
        ],
        1728042060: [
            0.10638,
            0,
            0.10638,
            0,
            5.70541,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            58.89897,
            0,
            0.54739,
            0,
            14.99825,
            0,
            3.19916,
            0,
            0,
            0,
            29.38927,
            0,
            16.7106,
            "<D",
            113.1722,
            0,
        ],
        1728042120: [
            0.19844,
            0,
            0.19844,
            0,
            3.28788,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            47.71298,
            0,
            0.54,
            0,
            14.90971,
            0,
            3.53048,
            0,
            0,
            0,
            29.38922,
            0,
            17.57357,
            "<D",
            113.3378,
            0,
        ],
        1728042180: [
            0.22392,
            0,
            0.22392,
            0,
            5.30465,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            49.72817,
            0,
            0.55867,
            0,
            14.90737,
            0,
            3.92154,
            0,
            0,
            0,
            29.38934,
            0,
            14.72023,
            "<D",
            113.3055,
            0,
        ],
        1728042240: [
            0.40966,
            0,
            0.40966,
            0,
            5.38204,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            65.54713,
            0,
            0.54024,
            0,
            14.80729,
            0,
            4.37555,
            0,
            0,
            0,
            29.38934,
            0,
            13.39801,
            "<D",
            113.2783,
            0,
        ],
        1728042300: [
            0.25147,
            0,
            0.25147,
            0,
            5.24721,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            70.35509,
            0,
            0.52557,
            0,
            14.705,
            0,
            4.89262,
            0,
            0,
            0,
            29.38961,
            0,
            15.09279,
            "<D",
            113.1742,
            0,
        ],
        1728042360: [
            0.20541,
            0,
            0.20541,
            0,
            4.93928,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            73.60521,
            0,
            0.5306,
            0,
            14.7194,
            0,
            5.40964,
            0,
            0,
            0,
            29.38939,
            0,
            14.73701,
            "<D",
            113.6302,
            0,
        ],
        1728042420: [
            0.01552,
            0,
            0.01552,
            0,
            4.58161,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            77.17789,
            0,
            0.5322,
            0,
            14.98829,
            0,
            5.93668,
            0,
            0,
            0,
            29.38986,
            0,
            14.96578,
            "<D",
            113.6425,
            0,
        ],
        1728042480: [
            0.33835,
            0,
            0.33835,
            0,
            4.07361,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            84.042,
            0,
            0.57995,
            0,
            15.13372,
            0,
            6.49692,
            0,
            0,
            0,
            29.39191,
            0,
            14.20944,
            "<D",
            113.3234,
            0,
        ],
        1728042540: [
            0.14914,
            0,
            0.14914,
            0,
            4.88983,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            84.27541,
            0,
            0.53864,
            0,
            15.23019,
            0,
            7.06052,
            0,
            0,
            0,
            29.39216,
            0,
            15.14172,
            "<D",
            113.2922,
            0,
        ],
        1728042600: [
            0.22619,
            0,
            0.22619,
            0,
            9.74211,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            85.04092,
            0,
            0.55564,
            0,
            15.12957,
            0,
            7.65388,
            0,
            0,
            0,
            29.39238,
            0,
            14.80932,
            "<D",
            113.3291,
            0,
        ],
        1728042660: [
            0.14026,
            0,
            0.14026,
            0,
            12.64135,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            67.07432,
            0,
            0.54794,
            0,
            15.21853,
            0,
            8.22757,
            0,
            0,
            0,
            29.39233,
            0,
            15.16716,
            "<D",
            113.5387,
            0,
        ],
        1728042720: [
            0.31742,
            0,
            0.31742,
            0,
            19.65373,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            71.68179,
            0,
            0.54397,
            0,
            15.24277,
            0,
            8.8278,
            0,
            0,
            0,
            29.39257,
            0,
            14.36246,
            "<D",
            113.7291,
            0,
        ],
        1728042780: [
            0.26356,
            0,
            0.26356,
            0,
            21.67381,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            66.68364,
            0,
            0.56199,
            0,
            15.24377,
            0,
            9.41814,
            0,
            0,
            0,
            29.39238,
            0,
            14.73792,
            "<D",
            113.2769,
            0,
        ],
        1728042840: [
            0.16564,
            0,
            0.16564,
            0,
            15.02763,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            64.46267,
            0,
            0.47559,
            0,
            15.25741,
            0,
            9.99848,
            0,
            0,
            0,
            29.39285,
            0,
            14.50494,
            "<D",
            115.3354,
            0,
        ],
        1728042900: [
            0.01634,
            0,
            0.01634,
            0,
            14.57082,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            64.36347,
            0,
            0.51064,
            0,
            15.21402,
            0,
            10.4364,
            0,
            0,
            0,
            29.39272,
            0,
            12.85455,
            "<D",
            115.9452,
            0,
        ],
        1728042960: [
            0.21983,
            0,
            0.21983,
            0,
            19.05216,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            63.6211,
            0,
            0.52549,
            0,
            15.16941,
            0,
            10.95735,
            0,
            0,
            0,
            29.39273,
            0,
            15.40758,
            "<D",
            116.8879,
            0,
        ],
        1728043020: [
            0.25126,
            0,
            0.25126,
            0,
            18.3302,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            61.80669,
            0,
            0.52273,
            0,
            15.01776,
            0,
            11.50812,
            0,
            0,
            0,
            29.39278,
            0,
            13.10179,
            "<D",
            117.0146,
            0,
        ],
        1728043080: [
            0.31671,
            0,
            0.31671,
            0,
            17.75568,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            60.94933,
            0,
            0.49471,
            0,
            14.94429,
            0,
            12.03572,
            0,
            0,
            0,
            29.39293,
            0,
            14.69489,
            "<D",
            117.2474,
            0,
        ],
        1728043140: [
            0.06418,
            0,
            0.06418,
            0,
            17.46164,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            63.04032,
            0,
            0.46236,
            0,
            14.8571,
            0,
            12.5107,
            0,
            0,
            0,
            29.39293,
            0,
            14.49244,
            "<D",
            118.6784,
            0,
        ],
        1728043200: [
            0.09551,
            0,
            0.09551,
            0,
            15.92911,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            73.84402,
            0,
            0.46815,
            0,
            14.88364,
            0,
            13.022,
            0,
            0,
            0,
            29.39262,
            0,
            15.0537,
            "<D",
            118.8271,
            0,
        ],
        1728043260: [
            0.16561,
            0,
            0.16561,
            0,
            16.07438,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            76.26901,
            0,
            0.33731,
            0,
            14.95756,
            0,
            13.533,
            0,
            0,
            0,
            29.3926,
            0,
            14.12638,
            "<D",
            114.1994,
            0,
        ],
        1728043320: [
            0.11946,
            0,
            0.11946,
            0,
            15.30878,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            79.87845,
            0,
            0.40825,
            0,
            15.00308,
            0,
            14.05412,
            0,
            0,
            0,
            29.3926,
            0,
            14.39569,
            "<D",
            114.3908,
            0,
        ],
        1728043380: [
            0.08988,
            0,
            0.08988,
            0,
            14.77978,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            84.76087,
            0,
            0.42657,
            0,
            14.91445,
            0,
            14.74398,
            0,
            0,
            0,
            29.39271,
            0,
            14.36505,
            "<D",
            113.7579,
            0,
        ],
        1728043440: [
            0.19905,
            0,
            0.19905,
            0,
            8.74591,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            85.08344,
            0,
            0.37884,
            0,
            15.0768,
            0,
            15.36493,
            0,
            0,
            0,
            29.39287,
            0,
            15.03322,
            "<D",
            113.7191,
            0,
        ],
        1728043500: [
            0.17366,
            0,
            0.17366,
            0,
            7.53259,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            73.15001,
            0,
            0.37546,
            0,
            15.17897,
            0,
            15.87968,
            0,
            0,
            0,
            29.39369,
            0,
            14.07169,
            "<D",
            113.5741,
            0,
        ],
        1728043560: [
            0.17722,
            0,
            0.17722,
            0,
            8.76088,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            67.68123,
            0,
            0.29972,
            0,
            15.21528,
            0,
            16.40437,
            0,
            0,
            0,
            29.39365,
            0,
            14.50346,
            "<D",
            113.3134,
            0,
        ],
        1728043620: [
            0.28559,
            0,
            0.28559,
            0,
            7.62665,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            67.64339,
            0,
            0.22373,
            0,
            15.05905,
            0,
            16.90285,
            0,
            0,
            0,
            29.39433,
            0,
            14.52236,
            "<D",
            113.4546,
            0,
        ],
        1728043680: [
            0.37931,
            0,
            0.37931,
            0,
            7.34489,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            67.31826,
            0,
            0.2237,
            0,
            15.02872,
            0,
            17.40471,
            0,
            0,
            0,
            29.39356,
            0,
            14.50494,
            "<D",
            113.4162,
            0,
        ],
        1728043740: [
            0.3633,
            0,
            0.3633,
            0,
            7.80249,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            58.702,
            0,
            0.22373,
            0,
            15.00694,
            0,
            17.90634,
            0,
            0,
            0,
            29.39386,
            0,
            14.65385,
            "<D",
            112.9974,
            0,
        ],
        1728043800: [
            0.12463,
            0,
            0.12463,
            0,
            10.21867,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            56.24464,
            0,
            0.22352,
            0,
            15.05982,
            0,
            18.41475,
            0,
            0,
            0,
            29.39327,
            0,
            14.92916,
            "<D",
            113.185,
            0,
        ],
        1728043860: [
            0.30908,
            0,
            0.30908,
            0,
            12.39297,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            55.14606,
            0,
            0.22329,
            0,
            15.13527,
            0,
            18.95262,
            0,
            0,
            0,
            29.39457,
            0,
            14.17469,
            "<D",
            113.165,
            0,
        ],
        1728043920: [
            0.10999,
            0,
            0.10999,
            0,
            13.87705,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            68.08768,
            0,
            0.22373,
            0,
            15.14078,
            0,
            19.45769,
            0,
            0,
            0,
            29.39391,
            0,
            10.76109,
            "<D",
            113.2549,
            0,
        ],
        1728043980: [
            0.18482,
            0,
            0.18482,
            0,
            13.3099,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            76.06353,
            0,
            0.22262,
            0,
            15.1624,
            0,
            19.97288,
            0,
            0,
            0,
            29.39408,
            0,
            15.09672,
            "<D",
            113.5262,
            0,
        ],
        1728044040: [
            0.10458,
            0,
            0.10458,
            0,
            9.50321,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            92.96715,
            0,
            0.22142,
            0,
            15.16163,
            0,
            20.45483,
            0,
            0,
            0,
            29.39538,
            0,
            16.85529,
            "<D",
            113.1147,
            0,
        ],
        1728044100: [
            0.23055,
            0,
            0.23055,
            0,
            7.14355,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            104.5653,
            0,
            0.22372,
            0,
            15.18691,
            0,
            20.95013,
            0,
            0,
            0,
            29.39561,
            0,
            13.89445,
            "<D",
            112.9816,
            0,
        ],
        1728044160: [
            0.22966,
            0,
            0.22966,
            0,
            5.95805,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            91.45856,
            0,
            0.22312,
            0,
            15.16942,
            0,
            21.44209,
            0,
            0,
            0,
            29.39515,
            0,
            13.37079,
            "<D",
            113.0373,
            0,
        ],
        1728044220: [
            0.16785,
            0,
            0.16785,
            0,
            5.83,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            82.66426,
            0,
            0.22296,
            0,
            15.1403,
            0,
            21.93749,
            0,
            0,
            0,
            29.39588,
            0,
            14.47083,
            "<D",
            112.8358,
            0,
        ],
        1728044280: [
            0.12138,
            0,
            0.12138,
            0,
            5.69077,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            70.9158,
            0,
            0.22372,
            0,
            15.12768,
            0,
            22.37032,
            0,
            0,
            0,
            29.39586,
            0,
            12.89851,
            "<D",
            113.1836,
            0,
        ],
        1728044340: [
            0.21117,
            0,
            0.21117,
            0,
            7.08347,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            77.60361,
            0,
            0.22336,
            0,
            15.20562,
            0,
            22.81291,
            0,
            0,
            0,
            29.39587,
            0,
            14.98788,
            "<D",
            112.9659,
            0,
        ],
        1728044400: [
            0.22317,
            0,
            0.22317,
            0,
            7.58914,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            75.50839,
            0,
            0.22282,
            0,
            15.28477,
            0,
            23.30519,
            0,
            0,
            0,
            29.39586,
            0,
            14.27065,
            "<D",
            113.1855,
            0,
        ],
        1728044460: [
            0.18331,
            0,
            0.18331,
            0,
            8.40128,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            82.41363,
            0,
            0.22373,
            0,
            15.34877,
            0,
            23.72156,
            0,
            0,
            0,
            29.3964,
            0,
            13.90766,
            "<D",
            113.11,
            0,
        ],
        1728044520: [
            0.13125,
            0,
            0.13125,
            0,
            8.0548,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            78.28883,
            0,
            0.22312,
            0,
            15.40417,
            0,
            24.23048,
            0,
            0,
            0,
            29.39658,
            0,
            14.16609,
            "<D",
            113.2008,
            0,
        ],
        1728044580: [
            0.1867,
            0,
            0.1867,
            0,
            10.21707,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            89.61935,
            0,
            0.22373,
            0,
            15.35357,
            0,
            24.65657,
            0,
            0,
            0,
            29.39802,
            0,
            15.27126,
            "<D",
            113.0619,
            0,
        ],
        1728044640: [
            0.18363,
            0,
            0.18363,
            0,
            15.63458,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            85.32473,
            0,
            0.22361,
            0,
            15.30967,
            0,
            25.17864,
            0,
            0,
            0,
            29.39857,
            0,
            15.19909,
            "<D",
            113.1939,
            0,
        ],
        1728044700: [
            0.2762,
            0,
            0.2762,
            0,
            17.93922,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            83.56622,
            0,
            0.22342,
            0,
            15.2516,
            0,
            25.63724,
            0,
            0,
            0,
            29.39863,
            0,
            16.35547,
            "<D",
            112.6286,
            0,
        ],
        1728044760: [
            0.34909,
            0,
            0.34909,
            0,
            19.25071,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            69.93858,
            0,
            0.22069,
            0,
            15.23886,
            0,
            26.02023,
            0,
            0,
            0,
            29.39856,
            0,
            15.11082,
            "<D",
            113.043,
            0,
        ],
        1728044820: [
            0.21995,
            0,
            0.21995,
            0,
            16.1531,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            81.10735,
            0,
            0.2187,
            0,
            15.24336,
            0,
            26.43287,
            0,
            0,
            0,
            29.39898,
            0,
            14.43332,
            "<D",
            113.1263,
            0,
        ],
        1728044880: [
            0.06591,
            0,
            0.06591,
            0,
            14.74973,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            83.37259,
            0,
            0.26438,
            0,
            15.26335,
            0,
            26.85185,
            0,
            0,
            0,
            29.3987,
            0,
            15.31708,
            "<D",
            113.3767,
            0,
        ],
        1728044940: [
            0.20284,
            0,
            0.20284,
            0,
            16.29589,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            89.64346,
            0,
            0.34758,
            0,
            15.25186,
            0,
            27.21788,
            0,
            0,
            0,
            29.39835,
            0,
            16.60287,
            "<D",
            112.8465,
            0,
        ],
        1728045000: [
            0.08801,
            0,
            0.08801,
            0,
            14.56984,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            69.50092,
            0,
            0.25213,
            0,
            15.31258,
            0,
            27.61413,
            0,
            0,
            0,
            29.39872,
            0,
            14.23438,
            "<D",
            113.1755,
            0,
        ],
        1728045060: [
            0.16513,
            0,
            0.16513,
            0,
            14.27736,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            57.45301,
            0,
            0.43239,
            0,
            15.38427,
            0,
            27.93104,
            0,
            0,
            0,
            29.40002,
            0,
            13.90169,
            "<D",
            114.6478,
            0,
        ],
        1728045120: [
            0.22952,
            0,
            0.22952,
            0,
            13.53719,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            42.72364,
            0,
            0.52989,
            0,
            15.44452,
            0,
            28.26115,
            0,
            0,
            0,
            29.39933,
            0,
            14.49885,
            "<D",
            115.131,
            0,
        ],
        1728045180: [
            0.1189,
            0,
            0.1189,
            0,
            13.33699,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            34.42249,
            0,
            0.5893,
            0,
            15.49349,
            0,
            28.61434,
            0,
            0,
            0,
            29.40005,
            0,
            14.88177,
            "<D",
            113.4083,
            0,
        ],
        1728045240: [
            0.27988,
            0,
            0.27988,
            0,
            14.36293,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            26.70497,
            0,
            0.61251,
            0,
            15.50185,
            0,
            29.06379,
            0,
            0,
            0,
            29.40005,
            0,
            13.97397,
            "<D",
            113.5621,
            0,
        ],
        1728045300: [
            0.34351,
            0,
            0.34351,
            0,
            11.34566,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            32.60756,
            0,
            0.60716,
            0,
            15.4725,
            0,
            29.7559,
            0,
            0,
            0,
            29.40086,
            0,
            14.32341,
            "<D",
            114.9722,
            0,
        ],
        1728045360: [
            0.20271,
            0,
            0.20271,
            0,
            10.62398,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            39.99717,
            0,
            0.62343,
            0,
            15.45019,
            0,
            30.54717,
            0,
            0,
            0,
            29.40073,
            0,
            14.54038,
            "<D",
            115.5194,
            0,
        ],
        1728045420: [
            0.07557,
            0,
            0.07557,
            0,
            10.65146,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            48.44678,
            0,
            0.5276,
            0,
            15.43735,
            0,
            30.89404,
            0,
            0,
            0,
            29.40042,
            0,
            15.14538,
            "<D",
            116.7968,
            0,
        ],
        1728045480: [
            0.14407,
            0,
            0.14407,
            0,
            11.36914,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            36.01958,
            0,
            0.50987,
            0,
            15.49489,
            0,
            30.84798,
            0,
            0,
            0,
            29.40181,
            0,
            14.95321,
            "<D",
            111.6734,
            0,
        ],
        1728045540: [
            0.18972,
            0,
            0.18972,
            0,
            12.57392,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            37.53177,
            0,
            0.5038,
            0,
            15.65177,
            0,
            31.04821,
            0,
            0,
            0,
            29.40183,
            0,
            15.6242,
            "<D",
            110.73,
            0,
        ],
        1728045600: [
            0.16051,
            0,
            0.16051,
            0,
            14.38698,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            46.54662,
            0,
            0.49113,
            0,
            15.7118,
            0,
            31.76795,
            0,
            0,
            0,
            29.40301,
            0,
            14.94726,
            "<D",
            110.6031,
            0,
        ],
        1728045660: [
            0.23904,
            0,
            0.23904,
            0,
            14.2769,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            37.8859,
            0,
            0.52251,
            0,
            15.77466,
            0,
            31.89108,
            0,
            0,
            0,
            29.4032,
            0,
            18.91714,
            "<D",
            110.2966,
            0,
        ],
        1728045720: [
            0.26026,
            0,
            0.26026,
            0,
            15.82529,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            33.24982,
            0,
            0.53002,
            0,
            15.82996,
            0,
            32.13611,
            0,
            0,
            0,
            29.4032,
            0,
            18.75139,
            "<D",
            110.9999,
            0,
        ],
        1728045780: [
            0.03914,
            0,
            0.03914,
            0,
            17.81355,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            19.3218,
            0,
            0.45798,
            0,
            15.8703,
            0,
            33.2891,
            0,
            0,
            0,
            29.4032,
            0,
            16.50999,
            "<D",
            110.6846,
            0,
        ],
        1728045840: [
            0.4192,
            0,
            0.4192,
            0,
            19.71243,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            18.67673,
            0,
            0.41213,
            0,
            15.87029,
            0,
            33.72251,
            0,
            0,
            0,
            29.4033,
            0,
            16.32944,
            "<D",
            110.4829,
            0,
        ],
        1728045900: [
            0.29209,
            0,
            0.29209,
            0,
            20.44803,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            18.12217,
            0,
            0.45943,
            0,
            15.91744,
            0,
            33.26612,
            0,
            0,
            0,
            29.40328,
            0,
            17.59448,
            "<D",
            110.2921,
            0,
        ],
        1728045960: [
            0.32136,
            0,
            0.32136,
            0,
            19.90732,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            0.40947,
            0,
            0.4835,
            0,
            15.97335,
            0,
            33.55526,
            0,
            0,
            0,
            29.40378,
            0,
            17.65435,
            "<D",
            110.407,
            0,
        ],
        1728046020: [
            0.28405,
            0,
            0.28405,
            0,
            20.04975,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            2.35142,
            0,
            0.69346,
            0,
            16.01422,
            0,
            33.98798,
            0,
            0,
            0,
            29.40527,
            0,
            18.49405,
            "<D",
            110.5907,
            0,
        ],
        1728046080: [
            0.38619,
            0,
            0.38619,
            0,
            18.84132,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            1.81061,
            0,
            0.75191,
            0,
            16.00007,
            0,
            34.34127,
            0,
            0,
            0,
            29.40616,
            0,
            18.18924,
            "<D",
            110.4943,
            0,
        ],
        1728046140: [
            0.27918,
            0,
            0.27918,
            0,
            18.10592,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            5.24112,
            0,
            0.78154,
            0,
            16.07779,
            0,
            35.3681,
            0,
            0,
            0,
            29.40654,
            0,
            18.89352,
            "<D",
            110.2585,
            0,
        ],
        1728046200: [
            0.11583,
            0,
            0.11583,
            0,
            17.36653,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            5.71564,
            0,
            0.80178,
            0,
            16.11659,
            0,
            39.03373,
            0,
            0,
            0,
            29.40583,
            0,
            18.18393,
            "<D",
            110.9805,
            0,
        ],
        1728046260: [
            0.24405,
            0,
            0.24405,
            0,
            17.86751,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            8.31456,
            0,
            0.82173,
            0,
            16.14643,
            0,
            42.83469,
            0,
            0,
            0,
            29.40653,
            0,
            20.36711,
            "<D",
            110.8728,
            0,
        ],
        1728046320: [
            0.27354,
            0,
            0.27354,
            0,
            17.66774,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            4.57474,
            0,
            0.85896,
            0,
            16.16013,
            0,
            40.26447,
            0,
            0,
            0,
            29.40645,
            0,
            19.0518,
            "<D",
            111.312,
            0,
        ],
        1728046380: [
            0.05846,
            0,
            0.05846,
            0,
            17.85107,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            6.32058,
            0,
            0.87944,
            0,
            16.1657,
            0,
            36.86584,
            0,
            0,
            0,
            29.40645,
            0,
            18.60989,
            "<D",
            111.4438,
            0,
        ],
        1728046440: [
            0.22499,
            0,
            0.22499,
            0,
            17.05126,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            4.72604,
            0,
            0.62582,
            0,
            16.21532,
            0,
            36.59906,
            0,
            0,
            0,
            29.40679,
            0,
            19.69473,
            "<D",
            111.5591,
            0,
        ],
        1728046500: [
            0.35227,
            0,
            0.35227,
            0,
            17.62255,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            20.94722,
            0,
            0.51349,
            0,
            16.28309,
            0,
            36.85626,
            0,
            0,
            0,
            29.4071,
            0,
            17.81774,
            "<D",
            111.82,
            0,
        ],
        1728046560: [
            0.17133,
            0,
            0.17133,
            0,
            19.05809,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            21.55619,
            0,
            0.48287,
            0,
            16.32529,
            0,
            37.2564,
            0,
            0,
            0,
            29.40704,
            0,
            18.67697,
            "<D",
            111.4657,
            0,
        ],
        1728046620: [
            0.25554,
            0,
            0.25554,
            0,
            20.64524,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            38.47837,
            0,
            0.26736,
            0,
            16.46714,
            0,
            37.83769,
            0,
            0,
            0,
            29.40834,
            0,
            18.06034,
            "<D",
            110.8005,
            0,
        ],
        1728046680: [
            0.2775,
            0,
            0.2775,
            0,
            18.66204,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            52.57261,
            0,
            0.22766,
            0,
            16.62803,
            0,
            38.36872,
            0,
            0,
            0,
            29.40812,
            0,
            21.21894,
            "<D",
            110.7876,
            0,
        ],
        1728046740: [
            0.05824,
            0,
            0.05824,
            0,
            18.07961,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            47.59281,
            0,
            0.36688,
            0,
            16.74208,
            0,
            38.53535,
            0,
            0,
            0,
            29.40899,
            0,
            19.51801,
            "<D",
            110.7988,
            0,
        ],
        1728046800: [
            0.07877,
            0,
            0.07877,
            0,
            18.78442,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            42.50368,
            0,
            0.34648,
            0,
            16.8073,
            0,
            39.05115,
            0,
            0,
            0,
            29.41008,
            0,
            18.39339,
            "<D",
            110.7339,
            0,
        ],
        1728046860: [
            0.17942,
            0,
            0.17942,
            0,
            19.02305,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            61.78936,
            0,
            0.31394,
            0,
            16.85114,
            0,
            39.63514,
            0,
            0,
            0,
            29.41005,
            0,
            20.55578,
            "<D",
            110.5428,
            0,
        ],
        1728046920: [
            0.1373,
            0,
            0.1373,
            0,
            18.26678,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            53.72423,
            0,
            0.30283,
            0,
            16.85162,
            0,
            40.41925,
            0,
            0,
            0,
            29.41022,
            0,
            18.76356,
            "<D",
            110.5734,
            0,
        ],
        1728046980: [
            0.14673,
            0,
            0.14673,
            0,
            18.5365,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            45.1977,
            0,
            0.28454,
            0,
            16.88385,
            0,
            42.35817,
            0,
            0,
            0,
            29.40981,
            0,
            19.07063,
            "<D",
            110.4284,
            0,
        ],
        1728047040: [
            0.29773,
            0,
            0.29773,
            0,
            19.52302,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            57.30826,
            0,
            0.20178,
            0,
            16.84577,
            0,
            42.8799,
            0,
            0,
            0,
            29.4103,
            0,
            19.30119,
            "<D",
            110.9725,
            0,
        ],
        1728047100: [
            0.33191,
            0,
            0.33191,
            0,
            19.17594,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            56.27574,
            0,
            0.21281,
            0,
            16.94467,
            0,
            51.75726,
            0,
            0,
            0,
            29.40984,
            0,
            19.18377,
            "<D",
            112.1572,
            0,
        ],
        1728047160: [
            0.16346,
            0,
            0.16346,
            0,
            21.32521,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            54.85238,
            0,
            0.24192,
            0,
            16.98601,
            0,
            76.98138,
            0,
            0,
            0,
            29.40986,
            0,
            16.59724,
            "<D",
            111.7337,
            0,
        ],
        1728047220: [
            0.43786,
            0,
            0.43786,
            0,
            20.54529,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            40.89361,
            0,
            0.50606,
            0,
            17.03376,
            0,
            78.97678,
            0,
            0,
            0,
            29.40971,
            0,
            16.85822,
            "<D",
            112.6798,
            0,
        ],
        1728047280: [
            0.0309,
            0,
            0.0309,
            0,
            21.34813,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            67.89452,
            0,
            0.52592,
            0,
            17.05836,
            0,
            114.3769,
            0,
            0,
            0,
            29.40979,
            0,
            20.19114,
            "<D",
            112.7576,
            0,
        ],
        1728047340: [
            0.05302,
            0,
            0.05302,
            0,
            22.05812,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            91.95296,
            0,
            0.44181,
            0,
            17.12934,
            0,
            192.1243,
            0,
            0,
            0,
            29.41099,
            0,
            16.91814,
            "<D",
            113.5383,
            0,
        ],
        1728047400: [
            0.02974,
            0,
            0.02974,
            0,
            21.95565,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6732,
            0,
            95.10286,
            0,
            0.32794,
            0,
            17.21475,
            0,
            225.4688,
            0,
            0,
            0,
            29.41237,
            0,
            20.08665,
            "<D",
            112.2045,
            0,
        ],
        1728047460: [
            0.23123,
            0,
            0.23123,
            0,
            22.11545,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            142.084,
            0,
            0.57422,
            0,
            17.17366,
            0,
            230.0506,
            0,
            0,
            0,
            29.41336,
            0,
            16.38123,
            "<D",
            110.7822,
            0,
        ],
        1728047520: [
            0.34994,
            0,
            0.34994,
            0,
            21.12425,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            138.9349,
            0,
            0.56217,
            0,
            17.19974,
            0,
            233.0597,
            0,
            0,
            0,
            29.41372,
            0,
            19.56311,
            "<D",
            110.2028,
            0,
        ],
        1728047580: [
            0.18617,
            0,
            0.18617,
            0,
            18.34397,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            130.4109,
            0,
            0.58503,
            0,
            17.20462,
            0,
            235.9094,
            0,
            0,
            0,
            29.41375,
            0,
            20.21371,
            "<D",
            109.8724,
            0,
        ],
        1728047640: [
            0.23798,
            0,
            0.23798,
            0,
            17.05036,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            119.8837,
            0,
            0.64467,
            0,
            17.29029,
            0,
            238.8819,
            0,
            0,
            0,
            29.41443,
            0,
            23.03138,
            "<D",
            110.132,
            0,
        ],
        1728047700: [
            0.261,
            0,
            0.261,
            0,
            19.52117,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            129.7523,
            0,
            0.56618,
            0,
            17.34392,
            0,
            241.7542,
            0,
            0,
            0,
            29.41444,
            0,
            17.60854,
            "<D",
            110.266,
            0,
        ],
        1728047760: [
            0.22229,
            0,
            0.22229,
            0,
            17.03092,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            130.8309,
            0,
            0.65554,
            0,
            17.33625,
            0,
            244.681,
            0,
            0,
            0,
            29.41431,
            0,
            19.64487,
            "<D",
            110.0464,
            0,
        ],
        1728047820: [
            0.16059,
            0,
            0.16059,
            0,
            19.29625,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            123.3892,
            0,
            0.73064,
            0,
            17.42172,
            0,
            247.7464,
            0,
            0,
            0,
            29.41489,
            0,
            16.42967,
            "<D",
            110.2345,
            0,
        ],
        1728047880: [
            -0.02324,
            0,
            -0.02324,
            0,
            16.91794,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            142.5656,
            0,
            0.54931,
            0,
            17.42333,
            0,
            250.6751,
            0,
            0,
            0,
            29.41581,
            0,
            17.92597,
            "<D",
            110.2138,
            0,
        ],
        1728047940: [
            0.42317,
            0,
            0.42317,
            0,
            16.6218,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            125.7743,
            0,
            0.50939,
            0,
            17.47323,
            0,
            253.5316,
            0,
            0,
            0,
            29.41563,
            0,
            19.36123,
            "<D",
            110.3788,
            0,
        ],
        1728048000: [
            0.19055,
            0,
            0.19055,
            0,
            17.10631,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            123.066,
            0,
            0.73886,
            0,
            17.57797,
            0,
            256.6764,
            0,
            0,
            0,
            29.41494,
            0,
            23.35635,
            "<D",
            110.4429,
            0,
        ],
        1728048060: [
            0.10407,
            0,
            0.10407,
            0,
            20.82898,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            90.45258,
            0,
            0.37078,
            0,
            17.67676,
            0,
            259.6956,
            0,
            0,
            0,
            29.41641,
            0,
            22.3527,
            "<D",
            110.4983,
            0,
        ],
        1728048120: [
            0.12235,
            0,
            0.12235,
            0,
            14.24171,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            100.9178,
            0,
            0.44106,
            0,
            17.8247,
            0,
            262.2451,
            0,
            0,
            0,
            29.41668,
            0,
            22.19187,
            "<D",
            110.3213,
            0,
        ],
        1728048180: [
            0.05212,
            0,
            0.05212,
            0,
            18.63848,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            139.1289,
            0,
            0.23781,
            0,
            17.87886,
            0,
            264.7845,
            0,
            0,
            0,
            29.417,
            0,
            16.37784,
            "<D",
            109.8958,
            0,
        ],
        1728048240: [
            0.33624,
            0,
            0.33624,
            0,
            15.9479,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            125.7847,
            0,
            0.33214,
            0,
            17.9571,
            0,
            267.7281,
            0,
            0,
            0,
            29.417,
            0,
            20.76355,
            "<D",
            110.0875,
            0,
        ],
        1728048300: [
            0.13989,
            0,
            0.13989,
            0,
            20.7049,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6732,
            0,
            115.6093,
            0,
            0.55036,
            0,
            17.96412,
            0,
            271.0449,
            0,
            0,
            0,
            29.41701,
            0,
            20.06472,
            "<D",
            110.5009,
            0,
        ],
        1728048360: [
            0.1574,
            0,
            0.1574,
            0,
            22.75586,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6732,
            0,
            95.34748,
            0,
            0.41057,
            0,
            18.08006,
            0,
            274.5089,
            0,
            0,
            0,
            29.41702,
            0,
            17.72813,
            "<D",
            110.0883,
            0,
        ],
        1728048420: [
            0.26226,
            0,
            0.26226,
            0,
            19.35333,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            97.62602,
            0,
            0.29815,
            0,
            18.19892,
            0,
            277.9771,
            0,
            0,
            0,
            29.4171,
            0,
            20.15079,
            "<D",
            110.2272,
            0,
        ],
        1728048480: [
            0.21231,
            0,
            0.21231,
            0,
            20.57764,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            117.2587,
            0,
            0.21187,
            0,
            18.24285,
            0,
            281.3154,
            0,
            0,
            0,
            29.41805,
            0,
            19.91964,
            "<D",
            110.0804,
            0,
        ],
        1728048540: [
            0.38173,
            0,
            0.38173,
            0,
            20.79319,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            107.7239,
            0,
            0.30747,
            0,
            18.31257,
            0,
            284.1586,
            0,
            0,
            0,
            29.41892,
            0,
            21.58031,
            "<D",
            110.2988,
            0,
        ],
        1728048600: [
            0.30924,
            0,
            0.30924,
            0,
            22.13869,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            103.4485,
            0,
            0.36828,
            0,
            18.37272,
            0,
            287.0434,
            0,
            0,
            0,
            29.42004,
            0,
            19.07626,
            "<D",
            109.9909,
            0,
        ],
        1728048660: [
            0.2672,
            0,
            0.2672,
            0,
            21.15948,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            105.5098,
            0,
            0.39713,
            0,
            18.52376,
            0,
            289.9268,
            0,
            0,
            0,
            29.42073,
            0,
            19.91273,
            "<D",
            110.5392,
            0,
        ],
        1728048720: [
            0.16385,
            0,
            0.16385,
            0,
            23.76264,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            90.936,
            0,
            0.47966,
            0,
            18.61596,
            0,
            292.7338,
            0,
            0,
            0,
            29.42075,
            0,
            19.28784,
            "<D",
            111.238,
            0,
        ],
        1728048780: [
            0.17806,
            0,
            0.17806,
            0,
            21.52903,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            117.5179,
            0,
            0.51281,
            0,
            18.72695,
            0,
            295.5536,
            0,
            0,
            0,
            29.4207,
            0,
            22.16456,
            "<D",
            111.8681,
            0,
        ],
        1728048840: [
            0.30574,
            0,
            0.30574,
            0,
            24.11277,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6732,
            0,
            40.76789,
            0,
            0.48845,
            0,
            18.8196,
            0,
            298.1851,
            0,
            0,
            0,
            29.42049,
            0,
            19.18887,
            "<D",
            111.4572,
            0,
        ],
        1728048900: [
            0.33409,
            0,
            0.33409,
            0,
            22.04502,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            114.2554,
            0,
            0.25298,
            0,
            18.9823,
            0,
            301.2572,
            0,
            0,
            0,
            29.42079,
            0,
            19.25984,
            "<D",
            111.8748,
            0,
        ],
        1728048960: [
            0.19258,
            0,
            0.19258,
            0,
            22.7359,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            57.68351,
            0,
            0.37954,
            0,
            18.9496,
            0,
            304.3979,
            0,
            0,
            0,
            29.42093,
            0,
            22.75064,
            "<D",
            112.1415,
            0,
        ],
        1728049020: [
            0.25613,
            0,
            0.25613,
            0,
            22.63099,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            77.77043,
            0,
            0.36501,
            0,
            18.99272,
            0,
            307.1317,
            0,
            0,
            0,
            29.42103,
            0,
            17.75372,
            "<D",
            110.6803,
            0,
        ],
        1728049080: [
            0.20978,
            0,
            0.20978,
            0,
            22.97268,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            103.541,
            0,
            0.50982,
            0,
            19.20886,
            0,
            310.2387,
            0,
            0,
            0,
            29.42105,
            0,
            21.25461,
            "<D",
            109.7885,
            0,
        ],
        1728049140: [
            0.29902,
            0,
            0.29902,
            0,
            22.16663,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            89.54459,
            0,
            0.43019,
            0,
            19.30623,
            0,
            313.4371,
            0,
            0,
            0,
            29.42106,
            0,
            20.08699,
            "<D",
            109.82,
            0,
        ],
        1728049200: [
            0.27835,
            0,
            0.27835,
            0,
            22.04253,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            73.99444,
            0,
            0.41004,
            0,
            19.37478,
            0,
            316.7594,
            0,
            0,
            0,
            29.42106,
            0,
            20.42345,
            "<D",
            109.8245,
            0,
        ],
        1728049260: [
            0.48919,
            0,
            0.48919,
            0,
            23.05716,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            91.98379,
            0,
            0.49074,
            0,
            19.40812,
            0,
            319.8291,
            0,
            0,
            0,
            29.42086,
            0,
            20.92165,
            "<D",
            109.8333,
            0,
        ],
        1728049320: [
            0.25474,
            0,
            0.25474,
            0,
            22.95402,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            128.245,
            0,
            0.67644,
            0,
            19.65189,
            0,
            322.6999,
            0,
            0,
            0,
            29.42066,
            0,
            19.92681,
            "<D",
            109.687,
            0,
        ],
        1728049380: [
            0.33557,
            0,
            0.33557,
            0,
            24.28743,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            130.2481,
            0,
            0.4665,
            0,
            19.71611,
            0,
            325.5606,
            0,
            0,
            0,
            29.42086,
            0,
            20.56723,
            "<D",
            109.2075,
            0,
        ],
        1728049440: [
            0.11722,
            0,
            0.11722,
            0,
            24.87393,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            145.3616,
            0,
            0.39312,
            0,
            19.71848,
            0,
            328.8048,
            0,
            0,
            0,
            29.42116,
            0,
            22.11827,
            "<D",
            109.5878,
            0,
        ],
        1728049500: [
            0.2087,
            0,
            0.2087,
            0,
            23.98177,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            134.8402,
            0,
            0.30028,
            0,
            19.71688,
            0,
            332.1342,
            0,
            0,
            0,
            29.42126,
            0,
            20.21303,
            "<D",
            109.4737,
            0,
        ],
        1728049560: [
            0.33233,
            0,
            0.33233,
            0,
            22.836,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            140.5688,
            0,
            0.27299,
            0,
            19.87277,
            0,
            334.872,
            0,
            0,
            0,
            29.42154,
            0,
            22.82961,
            "<D",
            109.707,
            0,
        ],
        1728049620: [
            0.33369,
            0,
            0.33369,
            0,
            25.16374,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            133.3999,
            0,
            0.22373,
            0,
            20.00543,
            0,
            337.7517,
            0,
            0,
            0,
            29.42143,
            0,
            19.96778,
            "<D",
            109.7982,
            0,
        ],
        1728049680: [
            0.27245,
            0,
            0.27245,
            0,
            20.00886,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            132.5211,
            0,
            0.22521,
            0,
            20.01741,
            0,
            340.8716,
            0,
            0,
            0,
            29.42192,
            0,
            21.59472,
            "<D",
            109.7779,
            0,
        ],
        1728049740: [
            0.34821,
            0,
            0.34821,
            0,
            23.84372,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            104.4352,
            0,
            0.38927,
            0,
            20.07961,
            0,
            344.1399,
            0,
            0,
            0,
            29.42136,
            0,
            20.90068,
            "<D",
            110.3685,
            0,
        ],
        1728049800: [
            0.1303,
            0,
            0.1303,
            0,
            23.21736,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            123.1091,
            0,
            0.26964,
            0,
            20.1081,
            0,
            346.7511,
            0,
            0,
            0,
            29.42152,
            0,
            20.4761,
            "<D",
            110.0385,
            0,
        ],
        1728049860: [
            0.11316,
            0,
            0.11316,
            0,
            20.13847,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            206.4322,
            0,
            0.22346,
            0,
            20.44763,
            0,
            349.442,
            0,
            0,
            0,
            29.42112,
            0,
            21.06366,
            "<D",
            110.0219,
            0,
        ],
        1728049920: [
            0.17945,
            0,
            0.17945,
            0,
            25.66432,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            196.9406,
            0,
            0.20743,
            0,
            20.59907,
            0,
            352.2368,
            0,
            0,
            0,
            29.42191,
            0,
            20.58508,
            "<D",
            111.2156,
            0,
        ],
        1728049980: [
            0.14461,
            0,
            0.14461,
            0,
            24.32436,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            157.6943,
            0,
            0.52485,
            0,
            20.53559,
            0,
            355.2744,
            0,
            0,
            0,
            29.42086,
            0,
            20.60807,
            "<D",
            111.9941,
            0,
        ],
        1728050040: [
            0.23022,
            0,
            0.23022,
            0,
            27.69966,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            174.6694,
            0,
            0.65735,
            0,
            20.71896,
            0,
            358.1889,
            0,
            0,
            0,
            29.42213,
            0,
            21.58053,
            "<D",
            112.1694,
            0,
        ],
        1728050100: [
            0.25937,
            0,
            0.25937,
            0,
            24.62373,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            178.3309,
            0,
            0.70707,
            0,
            21.13661,
            0,
            362.2998,
            0,
            0,
            0,
            29.42148,
            0,
            20.30191,
            "<D",
            112.6805,
            0,
        ],
        1728050160: [
            0.29695,
            0,
            0.29695,
            0,
            25.95696,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            159.8951,
            0,
            0.60641,
            0,
            21.60958,
            0,
            365.5249,
            0,
            0,
            0,
            29.42217,
            0,
            22.02615,
            "<D",
            110.9583,
            0,
        ],
        1728050220: [
            0.10797,
            0,
            0.10797,
            0,
            30.0921,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            183.8601,
            0,
            0.53662,
            0,
            21.73741,
            0,
            368.069,
            0,
            0,
            0,
            29.42382,
            0,
            17.73058,
            "<D",
            110.9647,
            0,
        ],
        1728050280: [
            0.15063,
            0,
            0.15063,
            0,
            29.65612,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            165.1517,
            0,
            0.27319,
            0,
            22.15052,
            0,
            371.5772,
            0,
            0,
            0,
            29.42422,
            0,
            20.02555,
            "<D",
            110.4697,
            0,
        ],
        1728050340: [
            0.22363,
            0,
            0.22363,
            0,
            29.42938,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            141.3219,
            0,
            0.21983,
            0,
            22.34679,
            0,
            375.4955,
            0,
            0,
            0,
            29.42427,
            0,
            20.14793,
            "<D",
            109.884,
            0,
        ],
        1728050400: [
            0.20385,
            0,
            0.20385,
            0,
            27.07516,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            107.7775,
            0,
            0.22648,
            0,
            22.31161,
            0,
            378.9648,
            0,
            0,
            0,
            29.42457,
            0,
            20.87505,
            "<D",
            110.0513,
            0,
        ],
        1728050460: [
            0.1787,
            0,
            0.1787,
            0,
            23.83513,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            359.9321,
            0,
            0.21318,
            0,
            22.05723,
            0,
            382.062,
            0,
            0,
            0,
            29.42496,
            0,
            22.20736,
            "<D",
            110.3179,
            0,
        ],
        1728050520: [
            0.27599,
            0,
            0.27599,
            0,
            26.00412,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            343.6682,
            0,
            0.23149,
            0,
            22.02134,
            0,
            384.3738,
            0,
            0,
            0,
            29.42537,
            0,
            20.52319,
            "<D",
            110.6754,
            0,
        ],
        1728050580: [
            0.21738,
            0,
            0.21738,
            0,
            28.01777,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            55.15937,
            0,
            0.2946,
            0,
            21.99656,
            0,
            387.2286,
            0,
            0,
            0,
            29.42558,
            0,
            20.30103,
            "<D",
            110.532,
            0,
        ],
        1728050640: [
            0.32455,
            0,
            0.32455,
            0,
            28.49313,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            245.8685,
            0,
            0.24964,
            0,
            22.02702,
            0,
            390.052,
            0,
            0,
            0,
            29.42559,
            0,
            18.4365,
            "<D",
            111.1704,
            0,
        ],
        1728050700: [
            0.21873,
            0,
            0.21873,
            0,
            28.26951,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            133.7774,
            0,
            0.29644,
            0,
            22.084,
            0,
            392.7552,
            0,
            0,
            0,
            29.42623,
            0,
            21.08051,
            "<D",
            111.0414,
            0,
        ],
        1728050760: [
            0.23972,
            0,
            0.23972,
            0,
            28.2461,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            97.62318,
            0,
            0.24929,
            0,
            22.09391,
            0,
            395.7693,
            0,
            0,
            0,
            29.42659,
            0,
            20.82399,
            "<D",
            110.6163,
            0,
        ],
        1728050820: [
            0.2992,
            0,
            0.2992,
            0,
            29.23793,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            43.56667,
            0,
            0.22766,
            0,
            22.22124,
            0,
            399.0428,
            0,
            0,
            0,
            29.4251,
            0,
            19.07853,
            "<D",
            110.0866,
            0,
        ],
        1728050880: [
            0.07825,
            0,
            0.07825,
            0,
            30.02629,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            61.43099,
            0,
            0.18446,
            0,
            22.32942,
            0,
            402.0668,
            0,
            0,
            0,
            29.42548,
            0,
            20.87424,
            "<D",
            109.6443,
            0,
        ],
        1728050940: [
            0.37258,
            0,
            0.37258,
            0,
            29.43607,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            51.60717,
            0,
            0.31858,
            0,
            22.37038,
            0,
            403.9521,
            0,
            0,
            0,
            29.42654,
            0,
            18.83981,
            "<D",
            110.5086,
            0,
        ],
        1728051000: [
            0.28604,
            0,
            0.28604,
            0,
            30.74066,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            81.72584,
            0,
            0.22278,
            0,
            22.39428,
            0,
            406.1075,
            0,
            0,
            0,
            29.42562,
            0,
            19.68439,
            "<D",
            112.3463,
            0,
        ],
        1728051060: [
            0.25779,
            0,
            0.25779,
            0,
            30.36332,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            82.00774,
            0,
            0.21175,
            0,
            22.42253,
            0,
            408.5416,
            0,
            0,
            0,
            29.42713,
            0,
            21.72593,
            "<D",
            112.41,
            0,
        ],
        1728051120: [
            0.2712,
            0,
            0.2712,
            0,
            30.39514,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            140.306,
            0,
            0.64616,
            0,
            22.60361,
            0,
            410.968,
            0,
            0,
            0,
            29.42733,
            0,
            19.27368,
            "<D",
            111.4559,
            0,
        ],
        1728051180: [
            0.1892,
            0,
            0.1892,
            0,
            30.67591,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            164.9597,
            0,
            0.51789,
            0,
            22.73768,
            0,
            413.2626,
            0,
            0,
            0,
            29.42649,
            0,
            19.1018,
            "<D",
            110.9895,
            0,
        ],
        1728051240: [
            0.27039,
            0,
            0.27039,
            0,
            32.36736,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            172.1897,
            0,
            0.51632,
            0,
            22.9581,
            0,
            415.5693,
            0,
            0,
            0,
            29.42766,
            0,
            23.13637,
            "<D",
            110.6789,
            0,
        ],
        1728051300: [
            0.16758,
            0,
            0.16758,
            0,
            32.02509,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            103.0635,
            0,
            0.36783,
            0,
            23.11245,
            0,
            418.1554,
            0,
            0,
            0,
            29.4274,
            0,
            19.12407,
            "<D",
            110.7915,
            0,
        ],
        1728051360: [
            0.14451,
            0,
            0.14451,
            0,
            32.07686,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            92.79671,
            0,
            0.31125,
            0,
            23.33134,
            0,
            420.8322,
            0,
            0,
            0,
            29.428,
            0,
            20.42913,
            "<D",
            110.3627,
            0,
        ],
        1728051420: [
            0.06905,
            0,
            0.06905,
            0,
            32.13672,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            140.2106,
            0,
            0.40863,
            0,
            23.23161,
            0,
            424.221,
            0,
            0,
            0,
            29.4279,
            0,
            20.13042,
            "<D",
            109.9358,
            0,
        ],
        1728051480: [
            0.15666,
            0,
            0.15666,
            0,
            31.12075,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            224.0366,
            0,
            0.32471,
            0,
            23.50934,
            0,
            426.8252,
            0,
            0,
            0,
            29.42781,
            0,
            19.64524,
            "<D",
            110.2229,
            0,
        ],
        1728051540: [
            0.15785,
            0,
            0.15785,
            0,
            30.4838,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            156.3895,
            0,
            0.39717,
            0,
            23.64791,
            0,
            430.08,
            0,
            0,
            0,
            29.42766,
            0,
            22.1671,
            "<D",
            110.5401,
            0,
        ],
        1728051600: [
            0.11075,
            0,
            0.11075,
            0,
            30.65302,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            135.3834,
            0,
            0.53236,
            0,
            24.0245,
            0,
            432.4496,
            0,
            0,
            0,
            29.42748,
            0,
            20.91348,
            "<D",
            110.0898,
            0,
        ],
        1728051660: [
            0.29155,
            0,
            0.29155,
            0,
            30.51053,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            87.92113,
            0,
            0.42964,
            0,
            23.63662,
            0,
            434.8915,
            0,
            0,
            0,
            29.42735,
            0,
            20.36037,
            "<D",
            109.5501,
            0,
        ],
        1728051720: [
            0.25325,
            0,
            0.25325,
            0,
            30.39911,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            97.77425,
            0,
            0.40406,
            0,
            23.25159,
            0,
            437.6923,
            0,
            0,
            0,
            29.42723,
            0,
            20.1897,
            "<D",
            108.8811,
            0,
        ],
        1728051780: [
            0.29488,
            0,
            0.29488,
            0,
            31.12359,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            83.97655,
            0,
            0.58819,
            0,
            23.29278,
            0,
            440.5481,
            0,
            0,
            0,
            29.42772,
            0,
            16.93615,
            "<D",
            108.4171,
            0,
        ],
        1728051840: [
            0.24701,
            0,
            0.24701,
            0,
            30.05779,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            89.86593,
            0,
            0.63641,
            0,
            23.61845,
            0,
            443.9007,
            0,
            0,
            0,
            29.42783,
            0,
            20.31032,
            "<D",
            109.3765,
            0,
        ],
        1728051900: [
            0.25743,
            0,
            0.25743,
            0,
            29.64337,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6732,
            0,
            80.83105,
            0,
            0.65089,
            0,
            23.66484,
            0,
            447.61,
            0,
            0,
            0,
            29.42786,
            0,
            19.80066,
            "<D",
            111.3763,
            0,
        ],
        1728051960: [
            0.13606,
            0,
            0.13606,
            0,
            30.48211,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            98.41101,
            0,
            0.51258,
            0,
            23.76316,
            0,
            450.8235,
            0,
            0,
            0,
            29.42815,
            0,
            19.59806,
            "<D",
            112.7429,
            0,
        ],
        1728052020: [
            0.17013,
            0,
            0.17013,
            0,
            30.60128,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            194.5434,
            0,
            0.44751,
            0,
            23.58695,
            0,
            453.28,
            0,
            0,
            0,
            29.42812,
            0,
            19.32787,
            "<D",
            112.5053,
            0,
        ],
        1728052080: [
            0.01849,
            0,
            0.01849,
            0,
            30.35419,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            214.164,
            0,
            0.62965,
            0,
            23.58642,
            0,
            456.0044,
            0,
            0,
            0,
            29.4282,
            0,
            16.68771,
            "<D",
            112.607,
            0,
        ],
        1728052140: [
            0.16481,
            0,
            0.16481,
            0,
            28.36398,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            206.1605,
            0,
            0.72595,
            0,
            23.82023,
            0,
            457.6539,
            0,
            0,
            0,
            29.42776,
            0,
            21.85707,
            "<D",
            112.3162,
            0,
        ],
        1728052200: [
            0.23717,
            0,
            0.23717,
            0,
            27.39281,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            204.7832,
            0,
            0.55287,
            0,
            23.86848,
            0,
            459.5202,
            0,
            0,
            0,
            29.42769,
            0,
            18.76761,
            "<D",
            112.1568,
            0,
        ],
        1728052260: [
            -0.03869,
            0,
            -0.03869,
            0,
            27.61948,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            175.1351,
            0,
            0.55022,
            0,
            23.64964,
            0,
            462.1846,
            0,
            0,
            0,
            29.42782,
            0,
            22.16314,
            "<D",
            112.073,
            0,
        ],
        1728052320: [
            0.21305,
            0,
            0.21305,
            0,
            27.51797,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            185.6882,
            0,
            0.4615,
            0,
            23.70303,
            0,
            464.7156,
            0,
            0,
            0,
            29.42756,
            0,
            19.33986,
            "<D",
            112.0201,
            0,
        ],
        1728052380: [
            0.17862,
            0,
            0.17862,
            0,
            27.2111,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            227.267,
            0,
            0.33811,
            0,
            23.84538,
            0,
            467.5595,
            0,
            0,
            0,
            29.429,
            0,
            22.77069,
            "<D",
            111.5496,
            0,
        ],
        1728052440: [
            0.36865,
            0,
            0.36865,
            0,
            27.26506,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            222.5036,
            0,
            0.30711,
            0,
            24.1908,
            0,
            470.2731,
            0,
            0,
            0,
            29.43102,
            0,
            19.72728,
            "<D",
            111.2391,
            0,
        ],
        1728052500: [
            0.32125,
            0,
            0.32125,
            0,
            26.89055,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            159.8386,
            0,
            0.32045,
            0,
            24.14652,
            0,
            472.9776,
            0,
            0,
            0,
            29.43257,
            0,
            20.40693,
            "<D",
            110.9984,
            0,
        ],
        1728052560: [
            0.28194,
            0,
            0.28194,
            0,
            26.9373,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            207.1966,
            0,
            0.24029,
            0,
            24.44388,
            0,
            475.3986,
            0,
            0,
            0,
            29.43274,
            0,
            18.63796,
            "<D",
            111.7561,
            0,
        ],
        1728052620: [
            0.15675,
            0,
            0.15675,
            0,
            27.12713,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            91.38008,
            0,
            0.4124,
            0,
            24.52634,
            0,
            477.845,
            0,
            0,
            0,
            29.43278,
            0,
            19.40314,
            "<D",
            110.9434,
            0,
        ],
        1728052680: [
            0.20754,
            0,
            0.20754,
            0,
            26.22388,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            51.20584,
            0,
            0.82438,
            0,
            24.32121,
            0,
            479.8683,
            0,
            0,
            0,
            29.43309,
            0,
            22.96273,
            "<D",
            108.8591,
            0,
        ],
        1728052740: [
            0.18001,
            0,
            0.18001,
            0,
            27.0021,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            60.30139,
            0,
            0.84379,
            0,
            24.04795,
            0,
            481.6023,
            0,
            0,
            0,
            29.43313,
            0,
            17.06486,
            "<D",
            108.8313,
            0,
        ],
        1728052800: [
            0.15229,
            0,
            0.15229,
            0,
            27.67251,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            70.04685,
            0,
            0.75884,
            0,
            24.0314,
            0,
            483.6474,
            0,
            0,
            0,
            29.43313,
            0,
            20.5436,
            "<D",
            109.8071,
            0,
        ],
        1728052860: [
            0.23913,
            0,
            0.23913,
            0,
            27.72359,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            79.02529,
            0,
            0.72012,
            0,
            23.98156,
            0,
            486.1057,
            0,
            0,
            0,
            29.43313,
            0,
            20.00532,
            "<D",
            109.9601,
            0,
        ],
        1728052920: [
            0.13999,
            0,
            0.13999,
            0,
            27.53689,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            22.8014,
            0,
            0.58101,
            0,
            23.9224,
            0,
            488.8463,
            0,
            0,
            0,
            29.43313,
            0,
            20.07687,
            "<D",
            109.5163,
            0,
        ],
        1728052980: [
            0.11934,
            0,
            0.11934,
            0,
            27.76505,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            47.14641,
            0,
            0.35759,
            0,
            24.10358,
            0,
            491.612,
            0,
            0,
            0,
            29.43438,
            0,
            20.03391,
            "<D",
            109.797,
            0,
        ],
        1728053040: [
            0.38231,
            0,
            0.38231,
            0,
            26.88062,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            211.9095,
            0,
            0.75859,
            0,
            24.43775,
            0,
            494.1894,
            0,
            0,
            0,
            29.43447,
            0,
            19.65427,
            "<D",
            109.2432,
            0,
        ],
        1728053100: [
            0.26376,
            0,
            0.26376,
            0,
            27.58951,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            193.6937,
            0,
            0.67627,
            0,
            24.25913,
            0,
            495.8694,
            0,
            0,
            0,
            29.43543,
            0,
            18.14518,
            "<D",
            108.8379,
            0,
        ],
        1728053160: [
            0.12527,
            0,
            0.12527,
            0,
            28.70597,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            155.605,
            0,
            0.49104,
            0,
            24.17159,
            0,
            498.701,
            0,
            0,
            0,
            29.43571,
            0,
            19.6889,
            "<D",
            109.6013,
            0,
        ],
        1728053220: [
            0.25568,
            0,
            0.25568,
            0,
            29.58727,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            161.1061,
            0,
            0.70734,
            0,
            24.3802,
            0,
            501.2947,
            0,
            0,
            0,
            29.43623,
            0,
            16.44458,
            "<D",
            110.3847,
            0,
        ],
        1728053280: [
            0.28145,
            0,
            0.28145,
            0,
            28.68033,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            168.5855,
            0,
            0.77344,
            0,
            24.46146,
            0,
            503.9283,
            0,
            0,
            0,
            29.43643,
            0,
            18.33125,
            "<D",
            110.7897,
            0,
        ],
        1728053340: [
            0.24117,
            0,
            0.24117,
            0,
            28.82635,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            147.0054,
            0,
            0.63361,
            0,
            24.34078,
            0,
            507.0123,
            0,
            0,
            0,
            29.43641,
            0,
            18.20386,
            "<D",
            110.7503,
            0,
        ],
        1728053400: [
            0.09948,
            0,
            0.09948,
            0,
            28.82981,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            78.91145,
            0,
            0.56543,
            0,
            24.21748,
            0,
            510.0721,
            0,
            0,
            0,
            29.43639,
            0,
            21.55539,
            "<D",
            110.2216,
            0,
        ],
        1728053460: [
            0.23942,
            0,
            0.23942,
            0,
            28.46892,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            68.95114,
            0,
            0.71164,
            0,
            24.33051,
            0,
            512.2038,
            0,
            0,
            0,
            29.43638,
            0,
            16.28886,
            "<D",
            112.1166,
            0,
        ],
        1728053520: [
            0.17482,
            0,
            0.17482,
            0,
            28.68999,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            88.57982,
            0,
            0.54322,
            0,
            24.53835,
            0,
            514.7944,
            0,
            0,
            0,
            29.43638,
            0,
            18.39392,
            "<D",
            110.8022,
            0,
        ],
        1728053580: [
            0.18049,
            0,
            0.18049,
            0,
            28.36602,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            357.2998,
            0,
            0.32665,
            0,
            24.79387,
            0,
            517.2775,
            0,
            0,
            0,
            29.43624,
            0,
            22.54248,
            "<D",
            107.1415,
            0,
        ],
        1728053640: [
            0.19495,
            0,
            0.19495,
            0,
            28.23834,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            345.1319,
            0,
            1.14575,
            0,
            24.84782,
            0,
            518.9275,
            0,
            0,
            0,
            29.43643,
            0,
            20.13223,
            "<D",
            107.2522,
            0,
        ],
        1728053700: [
            0.13293,
            0,
            0.13293,
            0,
            28.64988,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            355.1848,
            0,
            1.21366,
            0,
            24.66925,
            0,
            520.2741,
            0,
            0,
            0,
            29.43634,
            0,
            19.52361,
            "<D",
            108.8813,
            0,
        ],
        1728053760: [
            0.16878,
            0,
            0.16878,
            0,
            29.10708,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            1.67089,
            0,
            1.36721,
            0,
            24.49224,
            0,
            521.7724,
            0,
            0,
            0,
            29.43896,
            0,
            17.22731,
            "<D",
            107.8988,
            0,
        ],
        1728053820: [
            0.31863,
            0,
            0.31863,
            0,
            29.07316,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            7.95248,
            0,
            1.39829,
            0,
            24.4758,
            0,
            524.7114,
            0,
            0,
            0,
            29.43948,
            0,
            18.02464,
            "<D",
            108.0839,
            0,
        ],
        1728053880: [
            0.07116,
            0,
            0.07116,
            0,
            29.10151,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            6.93962,
            0,
            1.20846,
            0,
            24.62511,
            0,
            526.3163,
            0,
            0,
            0,
            29.43981,
            0,
            19.65483,
            "<D",
            107.8805,
            0,
        ],
        1728053940: [
            0.13716,
            0,
            0.13716,
            0,
            29.41384,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            28.95061,
            0,
            1.09425,
            0,
            24.73571,
            0,
            528.7946,
            0,
            0,
            0,
            29.43957,
            0,
            17.71001,
            "<D",
            108.657,
            0,
        ],
        1728054000: [
            0.29951,
            0,
            0.29951,
            0,
            29.48879,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            19.37715,
            0,
            1.00124,
            0,
            24.87427,
            0,
            531.6191,
            0,
            0,
            0,
            29.43976,
            0,
            17.96744,
            "<D",
            109.0013,
            0,
        ],
        1728054060: [
            0.40078,
            0,
            0.40078,
            0,
            29.58445,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            354.4861,
            0,
            1.26848,
            0,
            24.9014,
            0,
            534.1851,
            0,
            0,
            0,
            29.43977,
            0,
            18.29147,
            "<D",
            108.3016,
            0,
        ],
        1728054120: [
            0.20445,
            0,
            0.20445,
            0,
            29.83346,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            343.8209,
            0,
            1.00706,
            0,
            24.85875,
            0,
            536.6147,
            0,
            0,
            0,
            29.43975,
            0,
            18.73042,
            "<D",
            108.85,
            0,
        ],
        1728054180: [
            0.28374,
            0,
            0.28374,
            0,
            29.68898,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            341.2707,
            0,
            1.1999,
            0,
            24.89449,
            0,
            537.9474,
            0,
            0,
            0,
            29.43977,
            0,
            18.60654,
            "<D",
            108.5654,
            0,
        ],
        1728054240: [
            0.10106,
            0,
            0.10106,
            0,
            29.62859,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            348.1382,
            0,
            1.11718,
            0,
            24.91213,
            0,
            539.4716,
            0,
            0,
            0,
            29.43976,
            0,
            18.24212,
            "<D",
            106.3964,
            0,
        ],
        1728054300: [
            0.15502,
            0,
            0.15502,
            0,
            30.13069,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            319.5201,
            0,
            0.69278,
            0,
            24.94725,
            0,
            541.2991,
            0,
            0,
            0,
            29.43974,
            0,
            16.20464,
            "<D",
            107.8218,
            0,
        ],
        1728054360: [
            0.26397,
            0,
            0.26397,
            0,
            30.03251,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            328.4423,
            0,
            1.09198,
            0,
            25.04198,
            0,
            544.3864,
            0,
            0,
            0,
            29.43972,
            0,
            20.48997,
            "<D",
            106.7341,
            0,
        ],
        1728054420: [
            0.35189,
            0,
            0.35189,
            0,
            30.14713,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            339.0855,
            0,
            0.60899,
            0,
            24.7655,
            0,
            547.4927,
            0,
            0,
            0,
            29.43974,
            0,
            20.2376,
            "<D",
            106.1138,
            0,
        ],
        1728054480: [
            0.40443,
            0,
            0.40443,
            0,
            30.75848,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            346.8159,
            0,
            0.53346,
            0,
            24.73408,
            0,
            550.4915,
            0,
            0,
            0,
            29.43969,
            0,
            18.82738,
            "<D",
            107.172,
            0,
        ],
        1728054540: [
            0.10431,
            0,
            0.10431,
            0,
            30.9465,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            43.30666,
            0,
            0.32454,
            0,
            24.75303,
            0,
            553.4273,
            0,
            0,
            0,
            29.43976,
            0,
            20.28784,
            "<D",
            108.6108,
            0,
        ],
        1728054600: [
            0.38033,
            0,
            0.38033,
            0,
            31.24804,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            37.73029,
            0,
            0.50029,
            0,
            24.79029,
            0,
            556.2349,
            0,
            0,
            0,
            29.43986,
            0,
            20.06299,
            "<D",
            108.6956,
            0,
        ],
        1728054660: [
            0.17541,
            0,
            0.17541,
            0,
            31.38011,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            36.12947,
            0,
            0.83925,
            0,
            24.79002,
            0,
            558.8229,
            0,
            0,
            0,
            29.43989,
            0,
            17.68362,
            "<D",
            108.713,
            0,
        ],
        1728054720: [
            0.30222,
            0,
            0.30222,
            0,
            31.10743,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            66.91106,
            0,
            0.95689,
            0,
            24.89954,
            0,
            560.4438,
            0,
            0,
            0,
            29.44023,
            0,
            17.58148,
            "<D",
            108.0791,
            0,
        ],
        1728054780: [
            0.24998,
            0,
            0.24998,
            0,
            31.51007,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            47.91116,
            0,
            0.57186,
            0,
            25.24202,
            0,
            561.9893,
            0,
            0,
            0,
            29.44011,
            0,
            16.45117,
            "<D",
            108.0717,
            0,
        ],
        1728054840: [
            0.13834,
            0,
            0.13834,
            0,
            31.39106,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            45.34665,
            0,
            0.77471,
            0,
            25.49124,
            0,
            563.8624,
            0,
            0,
            0,
            29.44032,
            0,
            17.58981,
            "<D",
            107.9564,
            0,
        ],
        1728054900: [
            0.22666,
            0,
            0.22666,
            0,
            30.8303,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            64.1314,
            0,
            0.80167,
            0,
            25.38895,
            0,
            566.5425,
            0,
            0,
            0,
            29.44206,
            0,
            21.18019,
            "<D",
            107.7398,
            0,
        ],
        1728054960: [
            0.16795,
            0,
            0.16795,
            0,
            31.49689,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            48.88184,
            0,
            0.5506,
            0,
            25.14519,
            0,
            569.0189,
            0,
            0,
            0,
            29.44265,
            0,
            17.03942,
            "<D",
            107.6961,
            0,
        ],
        1728055020: [
            0.25745,
            0,
            0.25745,
            0,
            31.3667,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            116.0757,
            0,
            0.1713,
            0,
            25.23693,
            0,
            571.1887,
            0,
            0,
            0,
            29.44295,
            0,
            17.68862,
            "<D",
            106.963,
            0,
        ],
        1728055080: [
            0.16497,
            0,
            0.16497,
            0,
            31.44148,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            354.0669,
            0,
            0.61823,
            0,
            25.49289,
            0,
            573.8386,
            0,
            0,
            0,
            29.44303,
            0,
            19.49943,
            "<D",
            106.5616,
            0,
        ],
        1728055140: [
            0.28054,
            0,
            0.28054,
            0,
            31.24974,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            19.79303,
            0,
            0.83829,
            0,
            25.62404,
            0,
            575.8459,
            0,
            0,
            0,
            29.44336,
            0,
            19.39746,
            "<D",
            106.2254,
            0,
        ],
        1728055200: [
            0.15244,
            0,
            0.15244,
            0,
            31.95972,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            11.46772,
            0,
            0.67191,
            0,
            25.46182,
            0,
            577.8571,
            0,
            0,
            0,
            29.44343,
            0,
            17.7145,
            "<D",
            104.4851,
            0,
        ],
        1728055260: [
            0.17909,
            0,
            0.17909,
            0,
            33.04747,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            332.7374,
            0,
            0.78994,
            0,
            25.32969,
            0,
            579.2039,
            0,
            0,
            0,
            29.44363,
            0,
            17.65512,
            "<D",
            104.7249,
            0,
        ],
        1728055320: [
            0.34862,
            0,
            0.34862,
            0,
            33.46129,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            336.2547,
            0,
            0.8332,
            0,
            25.37453,
            0,
            580.8943,
            0,
            0,
            0,
            29.44377,
            0,
            19.48359,
            "<D",
            107.7028,
            0,
        ],
        1728055380: [
            0.25085,
            0,
            0.25085,
            0,
            33.02493,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            337.5311,
            0,
            0.99921,
            0,
            25.41721,
            0,
            582.8004,
            0,
            0,
            0,
            29.44358,
            0,
            16.94263,
            "<D",
            108.9635,
            0,
        ],
        1728055440: [
            0.13609,
            0,
            0.13609,
            0,
            34.64857,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            327.6859,
            0,
            1.02007,
            0,
            25.38172,
            0,
            556.7811,
            0,
            0,
            0,
            29.44367,
            0,
            18.78547,
            "<D",
            108.9746,
            0,
        ],
        1728055500: [
            0.31591,
            0,
            0.31591,
            0,
            34.99361,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            332.2885,
            0,
            0.55549,
            0,
            25.47318,
            0,
            513.4235,
            0,
            0,
            0,
            29.44374,
            0,
            19.7069,
            "<D",
            108.8163,
            0,
        ],
        1728055560: [
            0.29245,
            0,
            0.29245,
            0,
            34.71549,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            41.36266,
            0,
            1.18919,
            0,
            25.62771,
            0,
            550.0907,
            0,
            0,
            0,
            29.44449,
            0,
            18.14037,
            "<D",
            109.0724,
            0,
        ],
        1728055620: [
            0.37954,
            0,
            0.37954,
            0,
            35.46325,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            40.90702,
            0,
            1.03022,
            0,
            25.51581,
            0,
            588.1114,
            0,
            0,
            0,
            29.44437,
            0,
            17.65725,
            "<D",
            109.7152,
            0,
        ],
        1728055680: [
            0.20584,
            0,
            0.20584,
            0,
            36.88436,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            2.0228,
            0,
            0.36369,
            0,
            25.60609,
            0,
            591.0836,
            0,
            0,
            0,
            29.44561,
            0,
            15.66498,
            "<D",
            109.7261,
            0,
        ],
        1728055740: [
            0.07266,
            0,
            0.07266,
            0,
            36.21181,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            343.4957,
            0,
            1.07416,
            0,
            25.9695,
            0,
            592.8511,
            0,
            0,
            0,
            29.44576,
            0,
            19.74001,
            "<D",
            108.9257,
            0,
        ],
        1728055800: [
            0.17955,
            0,
            0.17955,
            0,
            36.41159,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            346.8999,
            0,
            1.29983,
            0,
            25.84867,
            0,
            595.3788,
            0,
            0,
            0,
            29.44695,
            0,
            17.89686,
            "<D",
            108.2964,
            0,
        ],
        1728055860: [
            0.03659,
            0,
            0.03659,
            0,
            37.30673,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            2.24891,
            0,
            1.63803,
            0,
            25.67371,
            0,
            597.9386,
            0,
            0,
            0,
            29.44624,
            0,
            19.06236,
            "<D",
            107.6207,
            0,
        ],
        1728055920: [
            0.15295,
            0,
            0.15295,
            0,
            39.65486,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            353.643,
            0,
            1.89786,
            0,
            25.57889,
            0,
            599.5287,
            0,
            0,
            0,
            29.44695,
            0,
            18.13357,
            "<D",
            107.4931,
            0,
        ],
        1728055980: [
            0.37398,
            0,
            0.37398,
            0,
            39.62626,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            0.36919,
            0,
            1.57815,
            0,
            25.55052,
            0,
            601.8029,
            0,
            0,
            0,
            29.44684,
            0,
            15.69794,
            "<D",
            106.8565,
            0,
        ],
        1728056040: [
            0.15635,
            0,
            0.15635,
            0,
            39.9244,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            355.083,
            0,
            1.28379,
            0,
            25.77605,
            0,
            602.9386,
            0,
            0,
            0,
            29.4467,
            0,
            18.40112,
            "<D",
            106.1251,
            0,
        ],
        1728056100: [
            0.19109,
            0,
            0.19109,
            0,
            39.86076,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            5.26485,
            0,
            1.44776,
            0,
            25.86022,
            0,
            603.2685,
            0,
            0,
            0,
            29.44726,
            0,
            18.79674,
            "<D",
            106.3602,
            0,
        ],
        1728056160: [
            0.10763,
            0,
            0.10763,
            0,
            40.01295,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            23.43363,
            0,
            1.39498,
            0,
            25.8318,
            0,
            604.3041,
            0,
            0,
            0,
            29.44796,
            0,
            18.79569,
            "<D",
            108.2475,
            0,
        ],
        1728056220: [
            0.3126,
            0,
            0.3126,
            0,
            38.41398,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            23.02548,
            0,
            0.995,
            0,
            25.75998,
            0,
            605.3132,
            0,
            0,
            0,
            29.44759,
            0,
            18.14992,
            "<D",
            108.3511,
            0,
        ],
        1728056280: [
            0.16375,
            0,
            0.16375,
            0,
            39.20311,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            76.09742,
            0,
            1.01048,
            0,
            25.77384,
            0,
            607.8412,
            0,
            0,
            0,
            29.44726,
            0,
            16.63742,
            "<D",
            108.5397,
            0,
        ],
        1728056340: [
            0.24038,
            0,
            0.24038,
            0,
            38.49331,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            127.3663,
            0,
            0.83053,
            0,
            25.96568,
            0,
            611.9147,
            0,
            0,
            0,
            29.44797,
            0,
            15.84752,
            "<D",
            108.1711,
            0,
        ],
        1728056400: [
            0.32214,
            0,
            0.32214,
            0,
            37.83714,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            107.965,
            0,
            1.09336,
            0,
            26.0386,
            0,
            614.483,
            0,
            0,
            0,
            29.44776,
            0,
            17.94253,
            "<D",
            108.465,
            0,
        ],
        1728056460: [
            0.16957,
            0,
            0.16957,
            0,
            37.29572,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            43.83994,
            0,
            0.36699,
            0,
            26.19983,
            0,
            615.879,
            0,
            0,
            0,
            29.44944,
            0,
            18.27001,
            "<D",
            109.1733,
            0,
        ],
        1728056520: [
            0.33245,
            0,
            0.33245,
            0,
            37.20553,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            266.9551,
            0,
            0.02444,
            0,
            26.48577,
            0,
            617.6487,
            0,
            0,
            0,
            29.44835,
            0,
            17.09181,
            "<D",
            109.3909,
            0,
        ],
        1728056580: [
            0.20701,
            0,
            0.20701,
            0,
            37.1648,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            29.40583,
            0,
            1.23018,
            0,
            26.68982,
            0,
            618.7629,
            0,
            0,
            0,
            29.44996,
            0,
            17.76164,
            "<D",
            107.5177,
            0,
        ],
        1728056640: [
            0.27171,
            0,
            0.27171,
            0,
            37.16014,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            59.55041,
            0,
            0.91121,
            0,
            26.43988,
            0,
            620.5455,
            0,
            0,
            0,
            29.45008,
            0,
            16.24064,
            "<D",
            107.0002,
            0,
        ],
        1728056700: [
            0.10301,
            0,
            0.10301,
            0,
            38.54208,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            56.55885,
            0,
            1.09595,
            0,
            26.40521,
            0,
            621.4954,
            0,
            0,
            0,
            29.45043,
            0,
            15.31793,
            "<D",
            107.3831,
            0,
        ],
        1728056760: [
            0.24401,
            0,
            0.24401,
            0,
            38.48825,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            56.95728,
            0,
            1.00227,
            0,
            26.27055,
            0,
            623.1998,
            0,
            0,
            0,
            29.44896,
            0,
            15.79038,
            "<D",
            107.5127,
            0,
        ],
        1728056820: [
            0.35336,
            0,
            0.35336,
            0,
            37.99483,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            0.88385,
            0,
            0.76235,
            0,
            26.6075,
            0,
            626.2087,
            0,
            0,
            0,
            29.44721,
            0,
            18.3735,
            "<D",
            107.6285,
            0,
        ],
        1728056880: [
            0.33979,
            0,
            0.33979,
            0,
            37.65696,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            56.87473,
            0,
            1.17165,
            0,
            26.521,
            0,
            628.9506,
            0,
            0,
            0,
            29.44799,
            0,
            16.37521,
            "<D",
            107.0948,
            0,
        ],
        1728056940: [
            0.25534,
            0,
            0.25534,
            0,
            38.12492,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            45.73997,
            0,
            0.83111,
            0,
            26.52468,
            0,
            630.5416,
            0,
            0,
            0,
            29.44732,
            0,
            17.83899,
            "<D",
            108.0733,
            0,
        ],
        1728057000: [
            0.28065,
            0,
            0.28065,
            0,
            37.5542,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            81.35313,
            0,
            0.57512,
            0,
            26.67058,
            0,
            632.3757,
            0,
            0,
            0,
            29.44712,
            0,
            17.98896,
            "<D",
            108.6933,
            0,
        ],
        1728057060: [
            0.25877,
            0,
            0.25877,
            0,
            36.7625,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            111.2147,
            0,
            0.93431,
            0,
            26.91827,
            0,
            634.4351,
            0,
            0,
            0,
            29.44698,
            0,
            14.46668,
            "<D",
            109.3692,
            0,
        ],
        1728057120: [
            0.30913,
            0,
            0.30913,
            0,
            35.59401,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            98.35715,
            0,
            1.43811,
            0,
            26.84542,
            0,
            636.3618,
            0,
            0,
            0,
            29.44721,
            0,
            17.37194,
            "<D",
            109.8965,
            0,
        ],
        1728057180: [
            0.19441,
            0,
            0.19441,
            0,
            36.96611,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            2.52533,
            0,
            1.0791,
            0,
            26.52035,
            0,
            637.853,
            0,
            0,
            0,
            29.44765,
            0,
            18.1858,
            "<D",
            108.9308,
            0,
        ],
        1728057240: [
            0.19228,
            0,
            0.19228,
            0,
            37.26601,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            33.00409,
            0,
            1.51158,
            0,
            26.32063,
            0,
            638.349,
            0,
            0,
            0,
            29.44824,
            0,
            16.55604,
            "<D",
            108.8951,
            0,
        ],
        1728057300: [
            0.23305,
            0,
            0.23305,
            0,
            36.89095,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            348.1935,
            0,
            1.41701,
            0,
            26.59437,
            0,
            638.6439,
            0,
            0,
            0,
            29.44928,
            0,
            14.9894,
            "<D",
            109.4655,
            0,
        ],
        1728057360: [
            0.28055,
            0,
            0.28055,
            0,
            36.12469,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            28.34516,
            0,
            1.13644,
            0,
            26.84487,
            0,
            639.5734,
            0,
            0,
            0,
            29.4502,
            0,
            18.81559,
            "<D",
            107.6685,
            0,
        ],
        1728057420: [
            0.10071,
            0,
            0.10071,
            0,
            36.56624,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            37.40651,
            0,
            1.18106,
            0,
            26.60382,
            0,
            641.0049,
            0,
            0,
            0,
            29.45,
            0,
            17.30945,
            "<D",
            107.5888,
            0,
        ],
        1728057480: [
            0.24507,
            0,
            0.24507,
            0,
            37.14527,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            74.88545,
            0,
            1.22779,
            0,
            26.72785,
            0,
            641.5511,
            0,
            0,
            0,
            29.45016,
            0,
            15.56605,
            "<D",
            107.9687,
            0,
        ],
        1728057540: [
            0.27597,
            0,
            0.27597,
            0,
            37.40865,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            36.49386,
            0,
            0.34147,
            0,
            26.50025,
            0,
            641.7623,
            0,
            0,
            0,
            29.4499,
            0,
            20.011,
            "<D",
            108.6307,
            0,
        ],
        1728057600: [
            0.19468,
            0,
            0.19468,
            0,
            37.86074,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            355.5273,
            0,
            1.2259,
            0,
            26.62732,
            0,
            643.1952,
            0,
            0,
            0,
            29.45023,
            0,
            15.48787,
            "<D",
            108.6083,
            0,
        ],
        1728057660: [
            0.19027,
            0,
            0.19027,
            0,
            36.76778,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            45.5727,
            0,
            0.86499,
            0,
            26.82947,
            0,
            645.4174,
            0,
            0,
            0,
            29.45023,
            0,
            18.5403,
            "<D",
            108.3099,
            0,
        ],
        1728057720: [
            0.37678,
            0,
            0.37678,
            0,
            35.15297,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            287.723,
            0,
            0.69088,
            0,
            27.15482,
            0,
            647.8203,
            0,
            0,
            0,
            29.45025,
            0,
            17.75978,
            "<D",
            109.295,
            0,
        ],
        1728057780: [
            0.19348,
            0,
            0.19348,
            0,
            36.54104,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            327.6671,
            0,
            2.77363,
            0,
            26.94605,
            0,
            651.6281,
            0,
            0,
            0,
            29.45027,
            0,
            18.57645,
            "<D",
            109.4597,
            0,
        ],
        1728057840: [
            0.12731,
            0,
            0.12731,
            0,
            39.38488,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            331.6169,
            0,
            2.312,
            0,
            26.62886,
            0,
            653.5002,
            0,
            0,
            0,
            29.45026,
            0,
            16.66508,
            "<D",
            109.2958,
            0,
        ],
        1728057900: [
            0.22978,
            0,
            0.22978,
            0,
            41.39626,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            338.3943,
            0,
            1.77555,
            0,
            26.56678,
            0,
            655.4972,
            0,
            0,
            0,
            29.45023,
            0,
            17.89609,
            "<D",
            109.0554,
            0,
        ],
        1728057960: [
            0.19891,
            0,
            0.19891,
            0,
            40.55779,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            334.6445,
            0,
            2.05643,
            0,
            26.48306,
            0,
            656.323,
            0,
            0,
            0,
            29.45028,
            0,
            16.92957,
            "<D",
            109.0194,
            0,
        ],
        1728058020: [
            0.13917,
            0,
            0.13917,
            0,
            39.36612,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            354.3921,
            0,
            2.04204,
            0,
            26.63395,
            0,
            656.7642,
            0,
            0,
            0,
            29.45024,
            0,
            15.48339,
            "<D",
            109.4626,
            0,
        ],
        1728058080: [
            0.19593,
            0,
            0.19593,
            0,
            38.58026,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            358.4062,
            0,
            2.49851,
            0,
            26.75775,
            0,
            657.8727,
            0,
            0,
            0,
            29.45025,
            0,
            16.81006,
            "<D",
            109.054,
            0,
        ],
        1728058140: [
            0.17018,
            0,
            0.17018,
            0,
            41.56838,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            27.32408,
            0,
            1.93662,
            0,
            26.83848,
            0,
            659.8715,
            0,
            0,
            0,
            29.45087,
            0,
            17.61138,
            "<D",
            107.82,
            0,
        ],
        1728058200: [
            0.38795,
            0,
            0.38795,
            0,
            45.3119,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            12.13369,
            0,
            1.54162,
            0,
            26.74287,
            0,
            674.4913,
            0,
            0,
            0,
            29.45039,
            0,
            19.2039,
            "<D",
            108.8563,
            0,
        ],
        1728058260: [
            0.28994,
            0,
            0.28994,
            0,
            46.07618,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            333.9786,
            0,
            2.09556,
            0,
            26.69249,
            0,
            681.9661,
            0,
            0,
            0,
            29.45082,
            0,
            15.77967,
            "<D",
            110.7556,
            0,
        ],
        1728058320: [
            0.43458,
            0,
            0.43458,
            0,
            48.24886,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            336.2271,
            0,
            2.35878,
            0,
            26.76156,
            0,
            685.6174,
            0,
            0,
            0,
            29.45089,
            0,
            17.38503,
            "<D",
            110.4779,
            0,
        ],
        1728058380: [
            0.19386,
            0,
            0.19386,
            0,
            47.24349,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            353.6324,
            0,
            2.83294,
            0,
            26.80297,
            0,
            692.8958,
            0,
            0,
            0,
            29.45093,
            0,
            17.8942,
            "<D",
            111.1061,
            0,
        ],
        1728058440: [
            0.304,
            0,
            0.304,
            0,
            47.93523,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8624,
            0,
            10.15628,
            0,
            2.21776,
            0,
            26.66335,
            0,
            692.7697,
            0,
            0,
            0,
            29.45042,
            0,
            13.85045,
            "<D",
            111.247,
            0,
        ],
        1728058500: [
            0.46414,
            0,
            0.46414,
            0,
            49.9791,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            357.7995,
            0,
            2.90684,
            0,
            26.73016,
            0,
            479.2731,
            0,
            0,
            0,
            29.45026,
            0,
            14.37185,
            "<D",
            111.9595,
            0,
        ],
        1728058560: [
            0.14865,
            0,
            0.14865,
            0,
            49.72546,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            0.08862,
            0,
            2.64764,
            0,
            26.66389,
            0,
            410.3322,
            0,
            0,
            0,
            29.45045,
            0,
            18.3781,
            "<D",
            111.9026,
            0,
        ],
        1728058620: [
            0.20863,
            0,
            0.20863,
            0,
            50.60414,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            352.1492,
            0,
            3.07153,
            0,
            26.6252,
            0,
            711.2446,
            0,
            0,
            0,
            29.45052,
            0,
            16.44419,
            "<D",
            111.58,
            0,
        ],
        1728058680: [
            0.24728,
            0,
            0.24728,
            0,
            49.87334,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            329.9752,
            0,
            3.35584,
            0,
            26.77809,
            0,
            669.8764,
            0,
            0,
            0,
            29.45051,
            0,
            16.98586,
            "<D",
            111.9148,
            0,
        ],
        1728058740: [
            0.25156,
            0,
            0.25156,
            0,
            49.42397,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            326.3511,
            0,
            2.99683,
            0,
            26.78217,
            0,
            457.7329,
            0,
            0,
            0,
            29.45068,
            0,
            16.98433,
            "<D",
            110.9221,
            0,
        ],
        1728058800: [
            0.34531,
            0,
            0.34531,
            0,
            48.81604,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            354.8816,
            0,
            2.95546,
            0,
            26.49678,
            0,
            557.3243,
            0,
            0,
            0,
            29.45078,
            0,
            16.14351,
            "<D",
            110.2219,
            0,
        ],
        1728058860: [
            0.28535,
            0,
            0.28535,
            0,
            46.81052,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            7.91195,
            0,
            2.61963,
            0,
            26.52489,
            0,
            496.6993,
            0,
            0,
            0,
            29.45214,
            0,
            18.11833,
            "<D",
            109.8503,
            0,
        ],
        1728058920: [
            0.29087,
            0,
            0.29087,
            0,
            46.50589,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            6.27379,
            0,
            1.81224,
            0,
            26.37996,
            0,
            376.3412,
            0,
            0,
            0,
            29.45088,
            0,
            12.9296,
            "<D",
            110.4876,
            0,
        ],
        1728058980: [
            0.17635,
            0,
            0.17635,
            0,
            46.82556,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            343.1896,
            0,
            1.85125,
            0,
            26.30611,
            0,
            712.7444,
            0,
            0,
            0,
            29.45139,
            0,
            16.13517,
            "<D",
            111.6871,
            0,
        ],
        1728059040: [
            0.08139,
            0,
            0.08139,
            0,
            45.82884,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8796,
            0,
            351.7555,
            0,
            1.8044,
            0,
            26.6501,
            0,
            709.5548,
            0,
            0,
            0,
            29.45078,
            0,
            16.91244,
            "<D",
            110.8696,
            0,
        ],
        1728059100: [
            0.30502,
            0,
            0.30502,
            0,
            45.06397,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            344.9882,
            0,
            1.59391,
            0,
            26.97201,
            0,
            711.7457,
            0,
            0,
            0,
            29.45065,
            0,
            16.1359,
            "<D",
            110.291,
            0,
        ],
        1728059160: [
            0.17745,
            0,
            0.17745,
            0,
            45.51718,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            3.59499,
            0,
            3.96582,
            0,
            27.12301,
            0,
            712.1271,
            0,
            0,
            0,
            29.44954,
            0,
            19.05611,
            "<D",
            110.5412,
            0,
        ],
        1728059220: [
            0.31217,
            0,
            0.31217,
            0,
            46.12416,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8882,
            0,
            4.46452,
            0,
            3.78184,
            0,
            26.94333,
            0,
            713.1801,
            0,
            0,
            0,
            29.4503,
            0,
            18.46309,
            "<D",
            110.0705,
            0,
        ],
        1728059280: [
            0.2316,
            0,
            0.2316,
            0,
            48.71852,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            0.86905,
            0,
            2.84713,
            0,
            26.82022,
            0,
            718.601,
            0,
            0,
            0,
            29.45029,
            0,
            16.83248,
            "<D",
            110.9455,
            0,
        ],
        1728059340: [
            0.45011,
            0,
            0.45011,
            0,
            49.45519,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8624,
            0,
            329.884,
            0,
            2.72033,
            0,
            26.82401,
            0,
            725.7467,
            0,
            0,
            0,
            29.44961,
            0,
            17.72756,
            "<D",
            111.5556,
            0,
        ],
        1728059400: [
            0.18041,
            0,
            0.18041,
            0,
            48.34411,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            333.6701,
            0,
            3.04084,
            0,
            26.9765,
            0,
            733.88,
            0,
            0,
            0,
            29.44791,
            0,
            14.73775,
            "<D",
            112.4631,
            0,
        ],
        1728059460: [
            0.10901,
            0,
            0.10901,
            0,
            46.99578,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            335.6381,
            0,
            2.8626,
            0,
            26.83192,
            0,
            740.4985,
            0,
            0,
            0,
            29.44943,
            0,
            17.15775,
            "<D",
            111.7953,
            0,
        ],
        1728059520: [
            0.31861,
            0,
            0.31861,
            0,
            46.93899,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            333.7297,
            0,
            1.6597,
            0,
            26.76904,
            0,
            750.7302,
            0,
            0,
            0,
            29.44932,
            0,
            17.20939,
            "<D",
            110.8352,
            0,
        ],
        1728059580: [
            0.10722,
            0,
            0.10722,
            0,
            46.88849,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            327.8965,
            0,
            1.75976,
            0,
            26.92411,
            0,
            761.1987,
            0,
            0,
            0,
            29.44864,
            0,
            17.17361,
            "<D",
            110.2616,
            0,
        ],
        1728059640: [
            0.16776,
            0,
            0.16776,
            0,
            46.30203,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            334.1066,
            0,
            2.48607,
            0,
            27.13702,
            0,
            771.3515,
            0,
            0,
            0,
            29.44764,
            0,
            16.10986,
            "<D",
            109.7084,
            0,
        ],
        1728059700: [
            0.38225,
            0,
            0.38225,
            0,
            46.09085,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            359.3401,
            0,
            1.51185,
            0,
            27.2928,
            0,
            598.0483,
            0,
            0,
            0,
            29.4476,
            0,
            15.19772,
            "<D",
            111.5589,
            0,
        ],
        1728059760: [
            0.35613,
            0,
            0.35613,
            0,
            45.19718,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8624,
            0,
            10.84702,
            0,
            2.12736,
            0,
            27.217,
            0,
            310.7888,
            0,
            0,
            0,
            29.44825,
            0,
            17.2559,
            "<D",
            111.1261,
            0,
        ],
        1728059820: [
            0.22856,
            0,
            0.22856,
            0,
            44.54739,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            5.80604,
            0,
            2.66096,
            0,
            26.90915,
            0,
            746.5073,
            0,
            0,
            0,
            29.44784,
            0,
            16.19667,
            "<D",
            110.8515,
            0,
        ],
        1728059880: [
            0.11875,
            0,
            0.11875,
            0,
            46.23961,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            33.97075,
            0,
            2.07558,
            0,
            26.94744,
            0,
            790.9368,
            0,
            0,
            0,
            29.44719,
            0,
            17.34213,
            "<D",
            110.6847,
            0,
        ],
        1728059940: [
            0.11406,
            0,
            0.11406,
            0,
            47.13657,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8882,
            0,
            11.96158,
            0,
            1.81583,
            0,
            27.09562,
            0,
            789.3138,
            0,
            0,
            0,
            29.44741,
            0,
            16.87835,
            "<D",
            110.9153,
            0,
        ],
        1728060000: [
            0.20687,
            0,
            0.20687,
            0,
            47.95551,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            2.01271,
            0,
            2.26197,
            0,
            27.27638,
            0,
            787.8488,
            0,
            0,
            0,
            29.44698,
            0,
            18.88679,
            "<D",
            111.1889,
            0,
        ],
        1728060060: [
            0.17747,
            0,
            0.17747,
            0,
            48.79934,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            354.0742,
            0,
            1.57648,
            0,
            27.2635,
            0,
            778.064,
            0,
            0,
            0,
            29.447,
            0,
            16.5233,
            "<D",
            110.639,
            0,
        ],
        1728060120: [
            0.34563,
            0,
            0.34563,
            0,
            48.59167,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            13.36124,
            0,
            1.42099,
            0,
            27.41146,
            0,
            769.2343,
            0,
            0,
            0,
            29.44699,
            0,
            17.23993,
            "<D",
            111.34,
            0,
        ],
        1728060180: [
            0.1779,
            0,
            0.1779,
            0,
            47.85888,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            355.5687,
            0,
            2.07882,
            0,
            27.47629,
            0,
            773.5132,
            0,
            0,
            0,
            29.44555,
            0,
            12.92726,
            "<D",
            113.0009,
            0,
        ],
        1728060240: [
            0.29973,
            0,
            0.29973,
            0,
            48.20045,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            356.7507,
            0,
            1.249,
            0,
            27.50962,
            0,
            712.7862,
            0,
            0,
            0,
            29.44628,
            0,
            16.1767,
            "<D",
            111.6438,
            0,
        ],
        1728060300: [
            0.25108,
            0,
            0.25108,
            0,
            48.59331,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            2.46736,
            0,
            1.98792,
            0,
            27.49415,
            0,
            750.6457,
            0,
            0,
            0,
            29.4459,
            0,
            14.83474,
            "<D",
            111.2035,
            0,
        ],
        1728060360: [
            0.17651,
            0,
            0.17651,
            0,
            48.42226,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            337.2089,
            0,
            1.82547,
            0,
            27.23113,
            0,
            732.0139,
            0,
            0,
            0,
            29.44331,
            0,
            17.24943,
            "<D",
            111.1791,
            0,
        ],
        1728060420: [
            0.07651,
            0,
            0.07651,
            0,
            48.32421,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            340.0677,
            0,
            1.87832,
            0,
            27.08495,
            0,
            738.6987,
            0,
            0,
            0,
            29.44297,
            0,
            17.27818,
            "<D",
            111.5114,
            0,
        ],
        1728060480: [
            0.29281,
            0,
            0.29281,
            0,
            48.17491,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            341.1211,
            0,
            1.15672,
            0,
            27.14713,
            0,
            743.0631,
            0,
            0,
            0,
            29.44311,
            0,
            16.72871,
            "<D",
            110.9685,
            0,
        ],
        1728060540: [
            0.08838,
            0,
            0.08838,
            0,
            47.76878,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            332.9432,
            0,
            1.71508,
            0,
            27.24746,
            0,
            742.7779,
            0,
            0,
            0,
            29.44294,
            0,
            20.03598,
            "<D",
            111.1428,
            0,
        ],
        1728060600: [
            0.08695,
            0,
            0.08695,
            0,
            46.99125,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            358.0876,
            0,
            2.61071,
            0,
            27.35498,
            0,
            745.0351,
            0,
            0,
            0,
            29.44293,
            0,
            19.84556,
            "<D",
            110.2509,
            0,
        ],
        1728060660: [
            0.18713,
            0,
            0.18713,
            0,
            48.14388,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            345.715,
            0,
            1.93519,
            0,
            27.1042,
            0,
            751.4521,
            0,
            0,
            0,
            29.44315,
            0,
            17.8984,
            "<D",
            110.9371,
            0,
        ],
        1728060720: [
            0.35143,
            0,
            0.35143,
            0,
            49.16798,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            353.0216,
            0,
            2.7735,
            0,
            27.04956,
            0,
            759.9441,
            0,
            0,
            0,
            29.44339,
            0,
            18.60265,
            "<D",
            110.777,
            0,
        ],
        1728060780: [
            0.15693,
            0,
            0.15693,
            0,
            48.83508,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            353.4768,
            0,
            3.21534,
            0,
            27.18997,
            0,
            769.7061,
            0,
            0,
            0,
            29.44344,
            0,
            17.48028,
            "<D",
            110.8889,
            0,
        ],
        1728060840: [
            0.21992,
            0,
            0.21992,
            0,
            48.52841,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            342.7396,
            0,
            1.88329,
            0,
            27.07268,
            0,
            782.9749,
            0,
            0,
            0,
            29.44331,
            0,
            14.46952,
            "<D",
            111.6947,
            0,
        ],
        1728060900: [
            0.25858,
            0,
            0.25858,
            0,
            48.43769,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            19.84568,
            0,
            2.24637,
            0,
            27.16586,
            0,
            799.2933,
            0,
            0,
            0,
            29.44332,
            0,
            16.19569,
            "<D",
            112.8363,
            0,
        ],
        1728060960: [
            0.23367,
            0,
            0.23367,
            0,
            47.8447,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            23.32225,
            0,
            1.73583,
            0,
            27.0337,
            0,
            812.023,
            0,
            0,
            0,
            29.44321,
            0,
            15.48678,
            "<D",
            111.9896,
            0,
        ],
        1728061020: [
            0.38123,
            0,
            0.38123,
            0,
            47.89933,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            359.6516,
            0,
            1.38748,
            0,
            27.14239,
            0,
            817.1086,
            0,
            0,
            0,
            29.44314,
            0,
            18.46585,
            "<D",
            110.8753,
            0,
        ],
        1728061080: [
            0.39967,
            0,
            0.39967,
            0,
            47.25564,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            344.85,
            0,
            2.35654,
            0,
            27.32771,
            0,
            828.4628,
            0,
            0,
            0,
            29.44298,
            0,
            15.98317,
            "<D",
            111.0398,
            0,
        ],
        1728061140: [
            0.30012,
            0,
            0.30012,
            0,
            47.31026,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            3.38514,
            0,
            4.58046,
            0,
            27.42207,
            0,
            838.9586,
            0,
            0,
            0,
            29.4412,
            0,
            15.55635,
            "<D",
            110.4914,
            0,
        ],
        1728061200: [
            0.18286,
            0,
            0.18286,
            0,
            47.41868,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            29.45741,
            0,
            1.77974,
            0,
            27.20118,
            0,
            845.0322,
            0,
            0,
            0,
            29.4431,
            0,
            16.86534,
            "<D",
            111.341,
            0,
        ],
        1728061260: [
            0.21544,
            0,
            0.21544,
            0,
            47.6482,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            13.89972,
            0,
            1.06309,
            0,
            27.27036,
            0,
            845.1508,
            0,
            0,
            0,
            29.44319,
            0,
            19.49562,
            "<D",
            110.1251,
            0,
        ],
        1728061320: [
            0.18461,
            0,
            0.18461,
            0,
            47.37788,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            28.14303,
            0,
            1.59361,
            0,
            27.81503,
            0,
            627.5684,
            0,
            0,
            0,
            29.44314,
            0,
            12.71982,
            "<D",
            111.2117,
            0,
        ],
        1728061380: [
            0.3328,
            0,
            0.3328,
            0,
            46.56871,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            29.14216,
            0,
            1.21591,
            0,
            27.5607,
            0,
            175.0721,
            0,
            0,
            0,
            29.44337,
            0,
            15.75975,
            "<D",
            111.0978,
            0,
        ],
        1728061440: [
            0.38627,
            0,
            0.38627,
            0,
            48.17342,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            67.77799,
            0,
            1.62846,
            0,
            27.12686,
            0,
            174.989,
            0,
            0,
            0,
            29.44364,
            0,
            16.0872,
            "<D",
            111.7101,
            0,
        ],
        1728061500: [
            0.25174,
            0,
            0.25174,
            0,
            48.20491,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            85.31666,
            0,
            2.02423,
            0,
            26.7599,
            0,
            189.1416,
            0,
            0,
            0,
            29.44358,
            0,
            15.42882,
            "<D",
            111.5455,
            0,
        ],
        1728061560: [
            0.17632,
            0,
            0.17632,
            0,
            47.6779,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8624,
            0,
            87.72086,
            0,
            1.67918,
            0,
            26.63682,
            0,
            348.3141,
            0,
            0,
            0,
            29.4437,
            0,
            16.4922,
            "<D",
            112.2862,
            0,
        ],
        1728061620: [
            -0.00195,
            0,
            -0.00195,
            0,
            48.38087,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            116.8299,
            0,
            1.34137,
            0,
            26.61148,
            0,
            200.0841,
            0,
            0,
            0,
            29.44245,
            0,
            13.5528,
            "<D",
            111.9983,
            0,
        ],
        1728061680: [
            0.23544,
            0,
            0.23544,
            0,
            47.93368,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            342.836,
            0,
            0.47562,
            0,
            26.52492,
            0,
            330.9211,
            0,
            0,
            0,
            29.44033,
            0,
            14.06815,
            "<D",
            112.5221,
            0,
        ],
        1728061740: [
            0.18035,
            0,
            0.18035,
            0,
            48.2671,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            0.15636,
            0,
            1.48983,
            0,
            26.54511,
            0,
            461.3589,
            0,
            0,
            0,
            29.44043,
            0,
            17.43274,
            "<D",
            111.6303,
            0,
        ],
        1728061800: [
            0.13018,
            0,
            0.13018,
            0,
            48.58198,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            24.8972,
            0,
            1.76525,
            0,
            26.61518,
            0,
            698.2035,
            0,
            0,
            0,
            29.44039,
            0,
            18.86231,
            "<D",
            111.8866,
            0,
        ],
        1728061860: [
            0.30271,
            0,
            0.30271,
            0,
            48.57385,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            83.68176,
            0,
            0.8844,
            0,
            26.6865,
            0,
            286.6407,
            0,
            0,
            0,
            29.4399,
            0,
            18.58611,
            "<D",
            111.8046,
            0,
        ],
        1728061920: [
            0.25712,
            0,
            0.25712,
            0,
            48.83815,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            27.91908,
            0,
            1.74661,
            0,
            26.68238,
            0,
            134.7469,
            0,
            0,
            0,
            29.43973,
            0,
            14.59926,
            "<D",
            112.0221,
            0,
        ],
        1728061980: [
            0.1464,
            0,
            0.1464,
            0,
            48.59144,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8624,
            0,
            15.88153,
            0,
            1.80602,
            0,
            26.47467,
            0,
            147.6943,
            0,
            0,
            0,
            29.43967,
            0,
            16.08172,
            "<D",
            110.5454,
            0,
        ],
        1728062040: [
            0.27272,
            0,
            0.27272,
            0,
            48.42972,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            355.959,
            0,
            2.16573,
            0,
            26.23734,
            0,
            114.5599,
            0,
            0,
            0,
            29.43964,
            0,
            15.40719,
            "<D",
            111.1122,
            0,
        ],
        1728062100: [
            0.45281,
            0,
            0.45281,
            0,
            48.85253,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            33.88313,
            0,
            1.4323,
            0,
            26.04386,
            0,
            548.3777,
            0,
            0,
            0,
            29.43978,
            0,
            15.94984,
            "<D",
            110.2603,
            0,
        ],
        1728062160: [
            0.12883,
            0,
            0.12883,
            0,
            48.1222,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            5.53196,
            0,
            1.68089,
            0,
            26.2109,
            0,
            737.9626,
            0,
            0,
            0,
            29.43991,
            0,
            16.23985,
            "<D",
            111.7962,
            0,
        ],
        1728062220: [
            0.12018,
            0,
            0.12018,
            0,
            48.74857,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            11.89846,
            0,
            2.04361,
            0,
            26.57073,
            0,
            731.0598,
            0,
            0,
            0,
            29.43948,
            0,
            15.96714,
            "<D",
            111.3601,
            0,
        ],
        1728062280: [
            0.26058,
            0,
            0.26058,
            0,
            49.16973,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            43.94335,
            0,
            1.45469,
            0,
            26.72371,
            0,
            730.5487,
            0,
            0,
            0,
            29.43689,
            0,
            16.52944,
            "<D",
            110.7465,
            0,
        ],
        1728062340: [
            0.22165,
            0,
            0.22165,
            0,
            50.43024,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            339.2608,
            0,
            1.75892,
            0,
            26.93877,
            0,
            731.5547,
            0,
            0,
            0,
            29.43637,
            0,
            16.81332,
            "<D",
            111.2261,
            0,
        ],
        1728062400: [
            0.07144,
            0,
            0.07144,
            0,
            49.74179,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            349.0373,
            0,
            2.09825,
            0,
            27.1464,
            0,
            730.6135,
            0,
            0,
            0,
            29.43546,
            0,
            16.12811,
            "<D",
            112.1276,
            0,
        ],
        1728062460: [
            0.26248,
            0,
            0.26248,
            0,
            49.16943,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            0.59773,
            0,
            2.3775,
            0,
            27.34653,
            0,
            731.478,
            0,
            0,
            0,
            29.43556,
            0,
            17.43631,
            "<D",
            111.5203,
            0,
        ],
        1728062520: [
            0.17025,
            0,
            0.17025,
            0,
            49.11549,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            341.2662,
            0,
            2.8601,
            0,
            27.42833,
            0,
            733.6792,
            0,
            0,
            0,
            29.43367,
            0,
            16.89626,
            "<D",
            110.8093,
            0,
        ],
        1728062580: [
            0.12329,
            0,
            0.12329,
            0,
            49.64166,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            12.98405,
            0,
            2.3155,
            0,
            27.67035,
            0,
            735.9589,
            0,
            0,
            0,
            29.43351,
            0,
            16.31839,
            "<D",
            110.9026,
            0,
        ],
        1728062640: [
            0.20092,
            0,
            0.20092,
            0,
            47.6562,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            42.85792,
            0,
            1.98597,
            0,
            27.90891,
            0,
            739.2285,
            0,
            0,
            0,
            29.43443,
            0,
            13.59931,
            "<D",
            110.8559,
            0,
        ],
        1728062700: [
            0.20189,
            0,
            0.20189,
            0,
            47.21998,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            9.17246,
            0,
            2.68375,
            0,
            27.70603,
            0,
            746.2333,
            0,
            0,
            0,
            29.43345,
            0,
            17.42239,
            "<D",
            110.2333,
            0,
        ],
        1728062760: [
            0.22526,
            0,
            0.22526,
            0,
            50.16384,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            357.992,
            0,
            3.15883,
            0,
            27.38061,
            0,
            753.7299,
            0,
            0,
            0,
            29.43322,
            0,
            16.23881,
            "<D",
            110.1576,
            0,
        ],
        1728062820: [
            0.406,
            0,
            0.406,
            0,
            51.38454,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            354.7479,
            0,
            2.76131,
            0,
            27.2891,
            0,
            762.2888,
            0,
            0,
            0,
            29.43453,
            0,
            17.19758,
            "<D",
            110.8446,
            0,
        ],
        1728062880: [
            0.27217,
            0,
            0.27217,
            0,
            51.46371,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            354.5571,
            0,
            3.21743,
            0,
            27.25754,
            0,
            775.5106,
            0,
            0,
            0,
            29.43421,
            0,
            17.99201,
            "<D",
            109.7619,
            0,
        ],
        1728062940: [
            0.24117,
            0,
            0.24117,
            0,
            51.61692,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            1.96351,
            0,
            3.41274,
            0,
            27.17314,
            0,
            787.2402,
            0,
            0,
            0,
            29.43401,
            0,
            14.71631,
            "<D",
            112.1298,
            0,
        ],
        1728063000: [
            0.12847,
            0,
            0.12847,
            0,
            51.13176,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            23.66478,
            0,
            2.155,
            0,
            27.2474,
            0,
            793.4841,
            0,
            0,
            0,
            29.43575,
            0,
            12.67365,
            "<D",
            110.0644,
            0,
        ],
        1728063060: [
            0.0747,
            0,
            0.0747,
            0,
            51.03405,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            5.10853,
            0,
            1.82699,
            0,
            27.37965,
            0,
            783.6238,
            0,
            0,
            0,
            29.43544,
            0,
            15.62299,
            "<D",
            109.99,
            0,
        ],
        1728063120: [
            0.20933,
            0,
            0.20933,
            0,
            50.74957,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            0.65386,
            0,
            1.96058,
            0,
            27.42573,
            0,
            764.3998,
            0,
            0,
            0,
            29.43626,
            0,
            15.39767,
            "<D",
            110.4875,
            0,
        ],
        1728063180: [
            0.08533,
            0,
            0.08533,
            0,
            50.53756,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            352.4945,
            0,
            1.47326,
            0,
            27.45275,
            0,
            619.7122,
            0,
            0,
            0,
            29.43324,
            0,
            15.87733,
            "<D",
            111.1973,
            0,
        ],
        1728063240: [
            0.29849,
            0,
            0.29849,
            0,
            49.94244,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            8.97746,
            0,
            1.72615,
            0,
            27.3201,
            0,
            719.8663,
            0,
            0,
            0,
            29.43322,
            0,
            15.94619,
            "<D",
            110.0251,
            0,
        ],
        1728063300: [
            0.21837,
            0,
            0.21837,
            0,
            50.54571,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            7.32234,
            0,
            1.9198,
            0,
            27.34216,
            0,
            758.2046,
            0,
            0,
            0,
            29.4334,
            0,
            18.09911,
            "<D",
            110.2414,
            0,
        ],
        1728063360: [
            0.14204,
            0,
            0.14204,
            0,
            50.44344,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            28.475,
            0,
            2.35049,
            0,
            27.40513,
            0,
            754.9547,
            0,
            0,
            0,
            29.43361,
            0,
            17.29627,
            "<D",
            111.1627,
            0,
        ],
        1728063420: [
            0.18088,
            0,
            0.18088,
            0,
            51.08774,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            359.2935,
            0,
            2.32782,
            0,
            27.65477,
            0,
            751.3798,
            0,
            0,
            0,
            29.43394,
            0,
            14.00385,
            "<D",
            111.4343,
            0,
        ],
        1728063480: [
            0.13487,
            0,
            0.13487,
            0,
            50.85939,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            359.769,
            0,
            2.28209,
            0,
            27.47703,
            0,
            756.5535,
            0,
            0,
            0,
            29.43389,
            0,
            16.20529,
            "<D",
            110.6139,
            0,
        ],
        1728063540: [
            0.13942,
            0,
            0.13942,
            0,
            51.17169,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            9.26087,
            0,
            1.66153,
            0,
            27.40742,
            0,
            370.441,
            0,
            0,
            0,
            29.43324,
            0,
            16.33829,
            "<D",
            109.8902,
            0,
        ],
        1728063600: [
            0.25996,
            0,
            0.25996,
            0,
            50.97798,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            37.69366,
            0,
            0.96531,
            0,
            27.25093,
            0,
            254.0647,
            0,
            0,
            0,
            29.43313,
            0,
            16.12424,
            "<D",
            110.9981,
            0,
        ],
        1728063660: [
            0.10075,
            0,
            0.10075,
            0,
            50.55817,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            21.22997,
            0,
            1.25198,
            0,
            27.1396,
            0,
            187.7225,
            0,
            0,
            0,
            29.43308,
            0,
            13.60714,
            "<D",
            111.0893,
            0,
        ],
        1728063720: [
            0.16329,
            0,
            0.16329,
            0,
            50.53104,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            53.17315,
            0,
            0.97591,
            0,
            26.9467,
            0,
            410.106,
            0,
            0,
            0,
            29.433,
            0,
            18.89641,
            "<D",
            110.9412,
            0,
        ],
        1728063780: [
            0.30503,
            0,
            0.30503,
            0,
            50.57767,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            20.46724,
            0,
            0.63446,
            0,
            26.86062,
            0,
            287.1866,
            0,
            0,
            0,
            29.43309,
            0,
            15.18144,
            "<D",
            111.4777,
            0,
        ],
        1728063840: [
            0.23604,
            0,
            0.23604,
            0,
            51.51723,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            39.16008,
            0,
            1.05489,
            0,
            26.88073,
            0,
            759.9282,
            0,
            0,
            0,
            29.4331,
            0,
            16.69686,
            "<D",
            112.0102,
            0,
        ],
        1728063900: [
            0.34909,
            0,
            0.34909,
            0,
            50.81491,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            22.22993,
            0,
            0.40121,
            0,
            27.18542,
            0,
            785.014,
            0,
            0,
            0,
            29.43293,
            0,
            15.25933,
            "<D",
            111.9318,
            0,
        ],
        1728063960: [
            0.37959,
            0,
            0.37959,
            0,
            49.07377,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            341.7701,
            0,
            1.1848,
            0,
            27.49051,
            0,
            782.765,
            0,
            0,
            0,
            29.4325,
            0,
            19.23604,
            "<D",
            111.3527,
            0,
        ],
        1728064020: [
            0.21013,
            0,
            0.21013,
            0,
            49.80509,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            341.3761,
            0,
            1.18401,
            0,
            27.68271,
            0,
            778.8346,
            0,
            0,
            0,
            29.43313,
            0,
            16.10334,
            "<D",
            111.4514,
            0,
        ],
        1728064080: [
            0.08283,
            0,
            0.08283,
            0,
            49.48465,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            350.005,
            0,
            2.34681,
            0,
            28.06357,
            0,
            779.4094,
            0,
            0,
            0,
            29.43313,
            0,
            15.59937,
            "<D",
            111.5004,
            0,
        ],
        1728064140: [
            0.18476,
            0,
            0.18476,
            0,
            48.95422,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            341.8089,
            0,
            2.55633,
            0,
            27.86613,
            0,
            701.1448,
            0,
            0,
            0,
            29.43207,
            0,
            18.53046,
            "<D",
            111.6345,
            0,
        ],
        1728064200: [
            0.25913,
            0,
            0.25913,
            0,
            50.16366,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            0.1827,
            0,
            2.1444,
            0,
            27.78955,
            0,
            300.0303,
            0,
            0,
            0,
            29.43126,
            0,
            14.07503,
            "<D",
            110.4935,
            0,
        ],
        1728064260: [
            0.17289,
            0,
            0.17289,
            0,
            49.62927,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            338.6563,
            0,
            3.60475,
            0,
            27.53335,
            0,
            399.8992,
            0,
            0,
            0,
            29.43027,
            0,
            19.68516,
            "<D",
            110.9415,
            0,
        ],
        1728064320: [
            0.15568,
            0,
            0.15568,
            0,
            50.92588,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            342.3941,
            0,
            3.09544,
            0,
            27.18431,
            0,
            781.6256,
            0,
            0,
            0,
            29.42866,
            0,
            16.41673,
            "<D",
            113.1082,
            0,
        ],
        1728064380: [
            0.20778,
            0,
            0.20778,
            0,
            51.73736,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            359.0534,
            0,
            3.08383,
            0,
            27.30517,
            0,
            779.1707,
            0,
            0,
            0,
            29.43259,
            0,
            16.62015,
            "<D",
            113.0201,
            0,
        ],
        1728064440: [
            0.30965,
            0,
            0.30965,
            0,
            51.25827,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            356.7882,
            0,
            2.43019,
            0,
            27.50511,
            0,
            783.2487,
            0,
            0,
            0,
            29.43144,
            0,
            18.19204,
            "<D",
            111.2519,
            0,
        ],
        1728064500: [
            0.10949,
            0,
            0.10949,
            0,
            51.35403,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            345.3735,
            0,
            2.38913,
            0,
            27.52999,
            0,
            782.2393,
            0,
            0,
            0,
            29.42961,
            0,
            15.54465,
            "<D",
            112.6319,
            0,
        ],
        1728064560: [
            0.14748,
            0,
            0.14748,
            0,
            50.65293,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            350.1966,
            0,
            1.83355,
            0,
            27.9488,
            0,
            788.7374,
            0,
            0,
            0,
            29.42926,
            0,
            13.11629,
            "<D",
            113.3576,
            0,
        ],
        1728064620: [
            0.15872,
            0,
            0.15872,
            0,
            50.85683,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            20.7247,
            0,
            1.56502,
            0,
            27.98032,
            0,
            783.7,
            0,
            0,
            0,
            29.42862,
            0,
            16.97498,
            "<D",
            112.7786,
            0,
        ],
        1728064680: [
            0.10666,
            0,
            0.10666,
            0,
            51.36569,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            340.6317,
            0,
            1.10207,
            0,
            27.82978,
            0,
            798.0652,
            0,
            0,
            0,
            29.42813,
            0,
            13.35683,
            "<D",
            112.7538,
            0,
        ],
        1728064740: [
            0.14879,
            0,
            0.14879,
            0,
            51.36773,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            358.9028,
            0,
            1.22987,
            0,
            27.63824,
            0,
            506.7822,
            0,
            0,
            0,
            29.42825,
            0,
            15.13009,
            "<D",
            112.1677,
            0,
        ],
        1728064800: [
            0.18803,
            0,
            0.18803,
            0,
            51.46498,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            24.64417,
            0,
            1.11374,
            0,
            27.32471,
            0,
            581.0131,
            0,
            0,
            0,
            29.42716,
            0,
            19.98993,
            "<D",
            112.1199,
            0,
        ],
        1728064860: [
            0.19996,
            0,
            0.19996,
            0,
            52.1511,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            6.58064,
            0,
            1.73685,
            0,
            27.23064,
            0,
            376.2369,
            0,
            0,
            0,
            29.42722,
            0,
            14.73653,
            "<D",
            112.1393,
            0,
        ],
        1728064920: [
            0.14114,
            0,
            0.14114,
            0,
            52.12856,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.871,
            0,
            1.26764,
            0,
            2.2147,
            0,
            27.09394,
            0,
            182.1888,
            0,
            0,
            0,
            29.42724,
            0,
            17.11325,
            "<D",
            112.0518,
            0,
        ],
        1728064980: [
            0.07629,
            0,
            0.07629,
            0,
            51.11302,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            358.4545,
            0,
            2.64515,
            0,
            26.87916,
            0,
            194.2022,
            0,
            0,
            0,
            29.42589,
            0,
            14.26753,
            "<D",
            111.597,
            0,
        ],
        1728065040: [
            0.33329,
            0,
            0.33329,
            0,
            51.65113,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            345.462,
            0,
            2.55089,
            0,
            26.68451,
            0,
            224.2559,
            0,
            0,
            0,
            29.4273,
            0,
            18.62273,
            "<D",
            111.95,
            0,
        ],
        1728065100: [
            0.2449,
            0,
            0.2449,
            0,
            52.09661,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            339.9795,
            0,
            2.96245,
            0,
            26.58524,
            0,
            407.7493,
            0,
            0,
            0,
            29.42654,
            0,
            15.80455,
            "<D",
            112.2919,
            0,
        ],
        1728065160: [
            0.05725,
            0,
            0.05725,
            0,
            51.91076,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            344.6865,
            0,
            2.11153,
            0,
            26.68815,
            0,
            758.3546,
            0,
            0,
            0,
            29.42736,
            0,
            15.89707,
            "<D",
            113.2885,
            0,
        ],
        1728065220: [
            0.20068,
            0,
            0.20068,
            0,
            51.28404,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            342.3087,
            0,
            2.09356,
            0,
            27.0336,
            0,
            834.7027,
            0,
            0,
            0,
            29.42734,
            0,
            15.37614,
            "<D",
            113.2972,
            0,
        ],
        1728065280: [
            0.2161,
            0,
            0.2161,
            0,
            51.78985,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.871,
            0,
            355.2292,
            0,
            2.61311,
            0,
            27.37299,
            0,
            692.4378,
            0,
            0,
            0,
            29.42559,
            0,
            16.73795,
            "<D",
            113.5766,
            0,
        ],
        1728065340: [
            0.18601,
            0,
            0.18601,
            0,
            51.19553,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8624,
            0,
            3.4765,
            0,
            2.69547,
            0,
            27.3556,
            0,
            645.4238,
            0,
            0,
            0,
            29.42439,
            0,
            17.18387,
            "<D",
            112.5324,
            0,
        ],
        1728065400: [
            0.29246,
            0,
            0.29246,
            0,
            50.1902,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            20.66393,
            0,
            1.67906,
            0,
            27.42583,
            0,
            425.1965,
            0,
            0,
            0,
            29.42527,
            0,
            14.91693,
            "<D",
            112.3412,
            0,
        ],
        1728065460: [
            0.11291,
            0,
            0.11291,
            0,
            49.48739,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8882,
            0,
            30.9514,
            0,
            1.51597,
            0,
            27.45826,
            0,
            202.6501,
            0,
            0,
            0,
            29.42491,
            0,
            16.98678,
            "<D",
            111.013,
            0,
        ],
        1728065520: [
            0.17485,
            0,
            0.17485,
            0,
            49.84139,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            45.97161,
            0,
            1.87987,
            0,
            27.17747,
            0,
            186.0195,
            0,
            0,
            0,
            29.42454,
            0,
            14.70677,
            "<D",
            110.9382,
            0,
        ],
        1728065580: [
            -0.02385,
            0,
            -0.02385,
            0,
            50.16549,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            11.68827,
            0,
            1.73703,
            0,
            26.90359,
            0,
            183.6544,
            0,
            0,
            0,
            29.4242,
            0,
            14.04405,
            "<D",
            110.0216,
            0,
        ],
        1728065640: [
            0.32728,
            0,
            0.32728,
            0,
            50.0702,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            342.6557,
            0,
            2.16971,
            0,
            26.71569,
            0,
            177.8626,
            0,
            0,
            0,
            29.4242,
            0,
            14.12258,
            "<D",
            112.2774,
            0,
        ],
        1728065700: [
            0.22878,
            0,
            0.22878,
            0,
            50.47223,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9312,
            0,
            348.6003,
            0,
            2.14077,
            0,
            26.58616,
            0,
            167.7241,
            0,
            0,
            0,
            29.42419,
            0,
            16.15656,
            "<D",
            113.0545,
            0,
        ],
        1728065760: [
            0.21954,
            0,
            0.21954,
            0,
            50.99821,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            4.93823,
            0,
            3.15518,
            0,
            26.46267,
            0,
            165.0892,
            0,
            0,
            0,
            29.4242,
            0,
            16.00508,
            "<D",
            112.9696,
            0,
        ],
        1728065820: [
            0.19425,
            0,
            0.19425,
            0,
            50.75757,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            353.1463,
            0,
            2.84773,
            0,
            26.3555,
            0,
            162.1395,
            0,
            0,
            0,
            29.42431,
            0,
            15.21826,
            "<D",
            113.4321,
            0,
        ],
        1728065880: [
            0.14289,
            0,
            0.14289,
            0,
            50.37075,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8882,
            0,
            3.03844,
            0,
            2.58012,
            0,
            26.25911,
            0,
            159.662,
            0,
            0,
            0,
            29.42424,
            0,
            17.04939,
            "<D",
            113.4723,
            0,
        ],
        1728065940: [
            0.42449,
            0,
            0.42449,
            0,
            49.90905,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            1.72916,
            0,
            1.97406,
            0,
            26.16698,
            0,
            157.9321,
            0,
            0,
            0,
            29.42401,
            0,
            13.69636,
            "<D",
            114.1337,
            0,
        ],
        1728066000: [
            0.20153,
            0,
            0.20153,
            0,
            49.1767,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            6.85472,
            0,
            2.2805,
            0,
            26.06981,
            0,
            171.7601,
            0,
            0,
            0,
            29.42405,
            0,
            12.87228,
            "<D",
            113.4345,
            0,
        ],
        1728066060: [
            0.11902,
            0,
            0.11902,
            0,
            49.46484,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8882,
            0,
            358.4077,
            0,
            1.76061,
            0,
            26.05193,
            0,
            176.3341,
            0,
            0,
            0,
            29.42368,
            0,
            16.4131,
            "<D",
            112.5715,
            0,
        ],
        1728066120: [
            0.22583,
            0,
            0.22583,
            0,
            50.47569,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            2.06881,
            0,
            2.28339,
            0,
            26.04573,
            0,
            153.9558,
            0,
            0,
            0,
            29.42278,
            0,
            14.01021,
            "<D",
            112.8209,
            0,
        ],
        1728066180: [
            0.34749,
            0,
            0.34749,
            0,
            50.2392,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            359.2858,
            0,
            2.23644,
            0,
            26.04579,
            0,
            126.2855,
            0,
            0,
            0,
            29.42115,
            0,
            17.45511,
            "<D",
            111.769,
            0,
        ],
        1728066240: [
            0.10199,
            0,
            0.10199,
            0,
            50.56477,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            354.4636,
            0,
            1.75899,
            0,
            26.02532,
            0,
            115.8636,
            0,
            0,
            0,
            29.42094,
            0,
            16.30059,
            "<D",
            111.8192,
            0,
        ],
        1728066300: [
            0.33061,
            0,
            0.33061,
            0,
            50.32013,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            1.35604,
            0,
            1.39127,
            0,
            25.9553,
            0,
            112.0689,
            0,
            0,
            0,
            29.42083,
            0,
            13.88107,
            "<D",
            112.0252,
            0,
        ],
        1728066360: [
            0.1842,
            0,
            0.1842,
            0,
            49.90818,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            358.1658,
            0,
            0.95376,
            0,
            25.86465,
            0,
            107.4699,
            0,
            0,
            0,
            29.41986,
            0,
            16.41203,
            "<D",
            113.1692,
            0,
        ],
        1728066420: [
            0.04742,
            0,
            0.04742,
            0,
            47.97667,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            27.66503,
            0,
            0.62964,
            0,
            25.86554,
            0,
            105.5772,
            0,
            0,
            0,
            29.418,
            0,
            14.62704,
            "<D",
            112.8121,
            0,
        ],
        1728066480: [
            0.15814,
            0,
            0.15814,
            0,
            48.07464,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            350.9686,
            0,
            1.16972,
            0,
            25.83082,
            0,
            107.3763,
            0,
            0,
            0,
            29.41812,
            0,
            17.86551,
            "<D",
            112.388,
            0,
        ],
        1728066540: [
            0.23905,
            0,
            0.23905,
            0,
            48.00884,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            6.73406,
            0,
            1.16804,
            0,
            25.74841,
            0,
            115.5947,
            0,
            0,
            0,
            29.41796,
            0,
            15.74884,
            "<D",
            113.0367,
            0,
        ],
        1728066600: [
            0.11054,
            0,
            0.11054,
            0,
            49.44313,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            0.02381,
            0,
            1.64505,
            0,
            25.79273,
            0,
            140.947,
            0,
            0,
            0,
            29.41791,
            0,
            16.64332,
            "<D",
            113.4074,
            0,
        ],
        1728066660: [
            0.3102,
            0,
            0.3102,
            0,
            49.14225,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            350.1668,
            0,
            1.86682,
            0,
            25.74355,
            0,
            155.5619,
            0,
            0,
            0,
            29.41823,
            0,
            15.18036,
            "<D",
            113.2577,
            0,
        ],
        1728066720: [
            0.27437,
            0,
            0.27437,
            0,
            48.65917,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            11.6879,
            0,
            1.1934,
            0,
            25.86628,
            0,
            456.701,
            0,
            0,
            0,
            29.41873,
            0,
            14.94258,
            "<D",
            112.7906,
            0,
        ],
        1728066780: [
            0.34143,
            0,
            0.34143,
            0,
            47.20258,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            12.6422,
            0,
            0.92478,
            0,
            25.8755,
            0,
            753.7017,
            0,
            0,
            0,
            29.41818,
            0,
            13.2076,
            "<D",
            113.281,
            0,
        ],
        1728066840: [
            0.25321,
            0,
            0.25321,
            0,
            46.32648,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            343.5846,
            0,
            1.10792,
            0,
            26.21539,
            0,
            733.683,
            0,
            0,
            0,
            29.41829,
            0,
            15.36838,
            "<D",
            113.2783,
            0,
        ],
        1728066900: [
            0.27541,
            0,
            0.27541,
            0,
            46.85445,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            354.2981,
            0,
            1.43293,
            0,
            26.60084,
            0,
            745.1833,
            0,
            0,
            0,
            29.41812,
            0,
            14.29523,
            "<D",
            112.3956,
            0,
        ],
        1728066960: [
            0.34017,
            0,
            0.34017,
            0,
            49.70943,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.914,
            0,
            330.4054,
            0,
            1.29067,
            0,
            27.01381,
            0,
            740.7412,
            0,
            0,
            0,
            29.41765,
            0,
            18.07521,
            "<D",
            112.2335,
            0,
        ],
        1728067020: [
            0.21889,
            0,
            0.21889,
            0,
            49.80038,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            346.914,
            0,
            1.0196,
            0,
            27.20793,
            0,
            741.1892,
            0,
            0,
            0,
            29.41696,
            0,
            15.01057,
            "<D",
            113.4604,
            0,
        ],
        1728067080: [
            0.4919,
            0,
            0.4919,
            0,
            50.51296,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            355.7763,
            0,
            0.87859,
            0,
            27.41121,
            0,
            732.3028,
            0,
            0,
            0,
            29.41412,
            0,
            16.54116,
            "<D",
            111.3203,
            0,
        ],
        1728067140: [
            0.28801,
            0,
            0.28801,
            0,
            50.54281,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.914,
            0,
            359.4399,
            0,
            1.96886,
            0,
            27.61039,
            0,
            738.1426,
            0,
            0,
            0,
            29.41401,
            0,
            17.70311,
            "<D",
            111.2627,
            0,
        ],
        1728067200: [
            0.23392,
            0,
            0.23392,
            0,
            49.55972,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            345.8966,
            0,
            1.67083,
            0,
            27.70789,
            0,
            756.2343,
            0,
            0,
            0,
            29.41407,
            0,
            16.09744,
            "<D",
            111.501,
            0,
        ],
        1728067260: [
            0.17988,
            0,
            0.17988,
            0,
            51.55976,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            339.4338,
            0,
            0.96638,
            0,
            27.57757,
            0,
            775.6982,
            0,
            0,
            0,
            29.41399,
            0,
            17.44873,
            "<D",
            112.0268,
            0,
        ],
        1728067320: [
            0.13619,
            0,
            0.13619,
            0,
            50.8807,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            119.0566,
            0,
            0.15654,
            0,
            27.43574,
            0,
            592.539,
            0,
            0,
            0,
            29.41368,
            0,
            15.58403,
            "<D",
            112.5898,
            0,
        ],
        1728067380: [
            0.15987,
            0,
            0.15987,
            0,
            51.6162,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.871,
            0,
            232.6702,
            0,
            0.70058,
            0,
            27.43082,
            0,
            579.7138,
            0,
            0,
            0,
            29.41087,
            0,
            15.87782,
            "<D",
            112.2725,
            0,
        ],
        1728067440: [
            0.19754,
            0,
            0.19754,
            0,
            52.10694,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            231.4752,
            0,
            1.07884,
            0,
            27.41133,
            0,
            512.2847,
            0,
            0,
            0,
            29.41063,
            0,
            16.27839,
            "<D",
            112.379,
            0,
        ],
        1728067500: [
            0.24864,
            0,
            0.24864,
            0,
            52.21721,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            207.6124,
            0,
            1.1342,
            0,
            27.70815,
            0,
            265.6159,
            0,
            0,
            0,
            29.41028,
            0,
            15.01193,
            "<D",
            111.5736,
            0,
        ],
        1728067560: [
            0.25039,
            0,
            0.25039,
            0,
            51.5526,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            203.3671,
            0,
            0.9798,
            0,
            27.54008,
            0,
            459.23,
            0,
            0,
            0,
            29.41022,
            0,
            19.48419,
            "<D",
            110.4387,
            0,
        ],
        1728067620: [
            -0.02555,
            0,
            -0.02555,
            0,
            48.86231,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            79.86687,
            0,
            0.23915,
            0,
            27.66698,
            0,
            812.8702,
            0,
            0,
            0,
            29.41018,
            0,
            15.59868,
            "<D",
            111.4616,
            0,
        ],
        1728067680: [
            0.19162,
            0,
            0.19162,
            0,
            48.23889,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            359.866,
            0,
            1.34667,
            0,
            27.59602,
            0,
            792.9558,
            0,
            0,
            0,
            29.41043,
            0,
            15.13802,
            "<D",
            111.4236,
            0,
        ],
        1728067740: [
            0.28543,
            0,
            0.28543,
            0,
            50.93432,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            351.779,
            0,
            2.30843,
            0,
            27.68298,
            0,
            494.5893,
            0,
            0,
            0,
            29.41038,
            0,
            15.79012,
            "<D",
            111.8614,
            0,
        ],
        1728067800: [
            0.09329,
            0,
            0.09329,
            0,
            51.44036,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            356.6322,
            0,
            3.0571,
            0,
            27.47476,
            0,
            203.8852,
            0,
            0,
            0,
            29.41038,
            0,
            16.07885,
            "<D",
            111.8453,
            0,
        ],
        1728067860: [
            0.0889,
            0,
            0.0889,
            0,
            51.88622,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            358.178,
            0,
            3.83496,
            0,
            26.96723,
            0,
            155.0094,
            0,
            0,
            0,
            29.41038,
            0,
            16.38311,
            "<D",
            111.5904,
            0,
        ],
        1728067920: [
            0.06938,
            0,
            0.06938,
            0,
            52.62181,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.871,
            0,
            358.7367,
            0,
            2.65864,
            0,
            26.56779,
            0,
            162.941,
            0,
            0,
            0,
            29.41039,
            0,
            17.90989,
            "<D",
            112.204,
            0,
        ],
        1728067980: [
            0.37124,
            0,
            0.37124,
            0,
            52.97334,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            8.57459,
            0,
            2.50797,
            0,
            26.40843,
            0,
            180.4128,
            0,
            0,
            0,
            29.41028,
            0,
            17.82068,
            "<D",
            112.1064,
            0,
        ],
        1728068040: [
            0.18476,
            0,
            0.18476,
            0,
            52.25167,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            0.06154,
            0,
            2.24144,
            0,
            26.27572,
            0,
            219.9608,
            0,
            0,
            0,
            29.41038,
            0,
            17.73017,
            "<D",
            113.002,
            0,
        ],
        1728068100: [
            0.2961,
            0,
            0.2961,
            0,
            52.00355,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            354.6944,
            0,
            2.58373,
            0,
            26.23594,
            0,
            190.7229,
            0,
            0,
            0,
            29.41031,
            0,
            14.26236,
            "<D",
            112.5389,
            0,
        ],
        1728068160: [
            0.13941,
            0,
            0.13941,
            0,
            52.67747,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            350.1972,
            0,
            2.33815,
            0,
            26.19454,
            0,
            358.2818,
            0,
            0,
            0,
            29.40961,
            0,
            16.32177,
            "<D",
            112.5527,
            0,
        ],
        1728068220: [
            0.07692,
            0,
            0.07692,
            0,
            52.40638,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            350.8043,
            0,
            1.58106,
            0,
            26.36137,
            0,
            562.8309,
            0,
            0,
            0,
            29.40915,
            0,
            15.40795,
            "<D",
            112.5375,
            0,
        ],
        1728068280: [
            0.02223,
            0,
            0.02223,
            0,
            52.04569,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            23.93837,
            0,
            1.36041,
            0,
            26.79023,
            0,
            269.5022,
            0,
            0,
            0,
            29.40982,
            0,
            17.48993,
            "<D",
            112.4941,
            0,
        ],
        1728068340: [
            0.10036,
            0,
            0.10036,
            0,
            50.33589,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            355.8076,
            0,
            1.91124,
            0,
            26.98256,
            0,
            652.4137,
            0,
            0,
            0,
            29.4091,
            0,
            16.30039,
            "<D",
            112.9183,
            0,
        ],
        1728068400: [
            0.16205,
            0,
            0.16205,
            0,
            50.44259,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            355.9687,
            0,
            1.62691,
            0,
            27.33145,
            0,
            444.3587,
            0,
            0,
            0,
            29.40943,
            0,
            15.95388,
            "<D",
            113.2312,
            0,
        ],
        1728068460: [
            0.18769,
            0,
            0.18769,
            0,
            50.92844,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            342.8556,
            0,
            1.43337,
            0,
            27.13292,
            0,
            593.4467,
            0,
            0,
            0,
            29.41022,
            0,
            15.95508,
            "<D",
            112.8477,
            0,
        ],
        1728068520: [
            0.25266,
            0,
            0.25266,
            0,
            53.13258,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            340.3809,
            0,
            1.69494,
            0,
            27.23067,
            0,
            644.6766,
            0,
            0,
            0,
            29.4094,
            0,
            16.38409,
            "<D",
            112.0886,
            0,
        ],
        1728068580: [
            0.03932,
            0,
            0.03932,
            0,
            52.8843,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            351.6556,
            0,
            2.23306,
            0,
            27.39217,
            0,
            537.6188,
            0,
            0,
            0,
            29.40914,
            0,
            17.8925,
            "<D",
            112.6581,
            0,
        ],
        1728068640: [
            0.01451,
            0,
            0.01451,
            0,
            52.37933,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            25.07152,
            0,
            2.11964,
            0,
            27.52535,
            0,
            660.5363,
            0,
            0,
            0,
            29.41038,
            0,
            16.11313,
            "<D",
            112.6462,
            0,
        ],
        1728068700: [
            0.24115,
            0,
            0.24115,
            0,
            51.7111,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            1.37149,
            0,
            2.58604,
            0,
            27.48203,
            0,
            233.4672,
            0,
            0,
            0,
            29.40979,
            0,
            14.12327,
            "<D",
            112.4023,
            0,
        ],
        1728068760: [
            0.28364,
            0,
            0.28364,
            0,
            50.87753,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            358.992,
            0,
            2.36211,
            0,
            27.1621,
            0,
            373.4261,
            0,
            0,
            0,
            29.40987,
            0,
            16.63431,
            "<D",
            112.0286,
            0,
        ],
        1728068820: [
            0.20504,
            0,
            0.20504,
            0,
            53.18479,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            13.71901,
            0,
            1.56689,
            0,
            26.97911,
            0,
            249.484,
            0,
            0,
            0,
            29.41006,
            0,
            14.52275,
            "<D",
            112.1192,
            0,
        ],
        1728068880: [
            0.23779,
            0,
            0.23779,
            0,
            52.00462,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            63.00058,
            0,
            1.57282,
            0,
            27.09933,
            0,
            307.5905,
            0,
            0,
            0,
            29.41039,
            0,
            16.64852,
            "<D",
            112.2224,
            0,
        ],
        1728068940: [
            0.18913,
            0,
            0.18913,
            0,
            51.68049,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            70.33305,
            0,
            1.0693,
            0,
            27.03529,
            0,
            334.8821,
            0,
            0,
            0,
            29.41037,
            0,
            17.26919,
            "<D",
            112.061,
            0,
        ],
        1728069000: [
            0.26622,
            0,
            0.26622,
            0,
            52.88224,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            351.5653,
            0,
            1.0861,
            0,
            27.08771,
            0,
            652.7952,
            0,
            0,
            0,
            29.40988,
            0,
            14.31766,
            "<D",
            112.4139,
            0,
        ],
        1728069060: [
            0.3954,
            0,
            0.3954,
            0,
            51.82556,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            12.53934,
            0,
            1.64773,
            0,
            27.26603,
            0,
            687.6674,
            0,
            0,
            0,
            29.40922,
            0,
            14.92708,
            "<D",
            112.5672,
            0,
        ],
        1728069120: [
            0.12235,
            0,
            0.12235,
            0,
            52.84917,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            351.8608,
            0,
            2.33588,
            0,
            27.45557,
            0,
            674.3801,
            0,
            0,
            0,
            29.40698,
            0,
            17.06142,
            "<D",
            112.9614,
            0,
        ],
        1728069180: [
            -0.02851,
            0,
            -0.02851,
            0,
            53.35939,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8624,
            0,
            344.3899,
            0,
            2.59692,
            0,
            27.45374,
            0,
            661.2263,
            0,
            0,
            0,
            29.4069,
            0,
            16.76559,
            "<D",
            111.4813,
            0,
        ],
        1728069240: [
            0.1227,
            0,
            0.1227,
            0,
            53.04842,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            353.7563,
            0,
            2.52352,
            0,
            27.50229,
            0,
            677.3907,
            0,
            0,
            0,
            29.40708,
            0,
            15.83455,
            "<D",
            112.0588,
            0,
        ],
        1728069300: [
            0.14618,
            0,
            0.14618,
            0,
            52.46218,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            349.7672,
            0,
            1.65353,
            0,
            27.59841,
            0,
            683.6879,
            0,
            0,
            0,
            29.40681,
            0,
            15.1348,
            "<D",
            113.1208,
            0,
        ],
        1728069360: [
            0.24781,
            0,
            0.24781,
            0,
            51.78524,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            357.4773,
            0,
            1.03953,
            0,
            27.91012,
            0,
            695.0461,
            0,
            0,
            0,
            29.40668,
            0,
            15.22632,
            "<D",
            113.1905,
            0,
        ],
        1728069420: [
            0.39431,
            0,
            0.39431,
            0,
            51.59125,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            1.7726,
            0,
            0.79439,
            0,
            28.25337,
            0,
            705.5423,
            0,
            0,
            0,
            29.40646,
            0,
            12.97887,
            "<D",
            112.9666,
            0,
        ],
        1728069480: [
            0.1411,
            0,
            0.1411,
            0,
            51.31258,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            35.73967,
            0,
            1.1761,
            0,
            28.30606,
            0,
            718.0306,
            0,
            0,
            0,
            29.40619,
            0,
            13.92286,
            "<D",
            113.9127,
            0,
        ],
        1728069540: [
            0.32056,
            0,
            0.32056,
            0,
            52.33135,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            19.63461,
            0,
            1.99127,
            0,
            28.14381,
            0,
            729.9002,
            0,
            0,
            0,
            29.40605,
            0,
            14.02747,
            "<D",
            113.7006,
            0,
        ],
        1728069600: [
            0.02441,
            0,
            0.02441,
            0,
            52.89616,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            18.42769,
            0,
            1.49505,
            0,
            28.08906,
            0,
            736.4058,
            0,
            0,
            0,
            29.40618,
            0,
            12.68496,
            "<D",
            113.1044,
            0,
        ],
        1728069660: [
            0.13331,
            0,
            0.13331,
            0,
            53.00797,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            334.2029,
            0,
            2.47608,
            0,
            28.07594,
            0,
            565.8839,
            0,
            0,
            0,
            29.40536,
            0,
            16.04171,
            "<D",
            111.1498,
            0,
        ],
        1728069720: [
            0.17903,
            0,
            0.17903,
            0,
            53.31422,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            333.6051,
            0,
            3.19449,
            0,
            27.81567,
            0,
            384.4591,
            0,
            0,
            0,
            29.40545,
            0,
            18.56901,
            "<D",
            113.0823,
            0,
        ],
        1728069780: [
            0.23652,
            0,
            0.23652,
            0,
            53.33371,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            11.77295,
            0,
            1.77935,
            0,
            27.81482,
            0,
            728.2764,
            0,
            0,
            0,
            29.40643,
            0,
            14.53814,
            "<D",
            114.1147,
            0,
        ],
        1728069840: [
            0.17318,
            0,
            0.17318,
            0,
            50.29669,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            341.8642,
            0,
            1.92129,
            0,
            28.30224,
            0,
            725.1216,
            0,
            0,
            0,
            29.40673,
            0,
            17.1379,
            "<D",
            112.559,
            0,
        ],
        1728069900: [
            0.1062,
            0,
            0.1062,
            0,
            50.50359,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            344.0911,
            0,
            2.968,
            0,
            28.05303,
            0,
            723.16,
            0,
            0,
            0,
            29.4065,
            0,
            16.80348,
            "<D",
            112.732,
            0,
        ],
        1728069960: [
            -0.02337,
            0,
            -0.02337,
            0,
            52.8305,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            338.9786,
            0,
            4.18543,
            0,
            27.86748,
            0,
            719.8289,
            0,
            0,
            0,
            29.40639,
            0,
            18.82757,
            "<D",
            113.6206,
            0,
        ],
        1728070020: [
            0.13198,
            0,
            0.13198,
            0,
            53.1109,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            354.1586,
            0,
            2.63573,
            0,
            27.81687,
            0,
            720.0569,
            0,
            0,
            0,
            29.40641,
            0,
            16.76056,
            "<D",
            113.4814,
            0,
        ],
        1728070080: [
            0.2854,
            0,
            0.2854,
            0,
            52.20514,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            350.4946,
            0,
            2.64436,
            0,
            28.05108,
            0,
            712.5721,
            0,
            0,
            0,
            29.40653,
            0,
            15.33143,
            "<D",
            113.1556,
            0,
        ],
        1728070140: [
            0.18128,
            0,
            0.18128,
            0,
            51.98116,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            5.21764,
            0,
            2.6069,
            0,
            28.06127,
            0,
            698.1904,
            0,
            0,
            0,
            29.40672,
            0,
            17.52743,
            "<D",
            112.6768,
            0,
        ],
        1728070200: [
            0.18231,
            0,
            0.18231,
            0,
            52.35518,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            341.7175,
            0,
            2.52801,
            0,
            27.86259,
            0,
            684.9261,
            0,
            0,
            0,
            29.40636,
            0,
            12.85959,
            "<D",
            114.2941,
            0,
        ],
        1728070260: [
            0.16342,
            0,
            0.16342,
            0,
            52.85669,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            1.12773,
            0,
            2.57241,
            0,
            27.90537,
            0,
            678.2598,
            0,
            0,
            0,
            29.40661,
            0,
            13.21895,
            "<D",
            114.8193,
            0,
        ],
        1728070320: [
            0.23551,
            0,
            0.23551,
            0,
            51.72776,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            357.7253,
            0,
            2.61053,
            0,
            27.80124,
            0,
            669.1497,
            0,
            0,
            0,
            29.40641,
            0,
            12.81288,
            "<D",
            113.5505,
            0,
        ],
        1728070380: [
            0.24557,
            0,
            0.24557,
            0,
            52.83288,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            350.6913,
            0,
            2.5948,
            0,
            27.63293,
            0,
            657.3965,
            0,
            0,
            0,
            29.40643,
            0,
            15.27028,
            "<D",
            114.6512,
            0,
        ],
        1728070440: [
            0.30457,
            0,
            0.30457,
            0,
            53.46159,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            358.3001,
            0,
            3.16854,
            0,
            27.71759,
            0,
            648.1148,
            0,
            0,
            0,
            29.40668,
            0,
            17.14533,
            "<D",
            113.9564,
            0,
        ],
        1728070500: [
            0.10106,
            0,
            0.10106,
            0,
            53.82846,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            353.0401,
            0,
            2.91245,
            0,
            27.68561,
            0,
            640.2792,
            0,
            0,
            0,
            29.40663,
            0,
            17.08321,
            "<D",
            112.9561,
            0,
        ],
        1728070560: [
            0.16851,
            0,
            0.16851,
            0,
            53.13669,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            334.4497,
            0,
            2.77192,
            0,
            27.84346,
            0,
            631.9016,
            0,
            0,
            0,
            29.40657,
            0,
            13.03383,
            "<D",
            114.6973,
            0,
        ],
        1728070620: [
            0.29423,
            0,
            0.29423,
            0,
            53.06216,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            354.5472,
            0,
            3.74936,
            0,
            27.87185,
            0,
            627.9468,
            0,
            0,
            0,
            29.40828,
            0,
            15.7597,
            "<D",
            115.4035,
            0,
        ],
        1728070680: [
            0.24739,
            0,
            0.24739,
            0,
            53.72176,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            348.4872,
            0,
            3.15975,
            0,
            27.56931,
            0,
            619.5849,
            0,
            0,
            0,
            29.40693,
            0,
            17.52111,
            "<D",
            115.9003,
            0,
        ],
        1728070740: [
            0.31138,
            0,
            0.31138,
            0,
            54.21432,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            347.0762,
            0,
            2.91659,
            0,
            27.63065,
            0,
            610.4124,
            0,
            0,
            0,
            29.40667,
            0,
            15.66623,
            "<D",
            113.8981,
            0,
        ],
        1728070800: [
            0.07649,
            0,
            0.07649,
            0,
            53.13334,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            338.0594,
            0,
            2.50305,
            0,
            27.7295,
            0,
            605.0233,
            0,
            0,
            0,
            29.4071,
            0,
            13.69061,
            "<D",
            113.0122,
            0,
        ],
        1728070860: [
            0.28702,
            0,
            0.28702,
            0,
            53.41711,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            5.23219,
            0,
            1.90543,
            0,
            28.06027,
            0,
            597.9915,
            0,
            0,
            0,
            29.40708,
            0,
            15.57825,
            "<D",
            112.8329,
            0,
        ],
        1728070920: [
            0.31929,
            0,
            0.31929,
            0,
            53.84143,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            3.45231,
            0,
            2.26794,
            0,
            28.08276,
            0,
            593.4631,
            0,
            0,
            0,
            29.40704,
            0,
            16.52147,
            "<D",
            113.4657,
            0,
        ],
        1728070980: [
            0.18084,
            0,
            0.18084,
            0,
            54.18774,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            336.4317,
            0,
            2.86676,
            0,
            27.82391,
            0,
            586.1945,
            0,
            0,
            0,
            29.40557,
            0,
            16.68815,
            "<D",
            112.9343,
            0,
        ],
        1728071040: [
            0.20271,
            0,
            0.20271,
            0,
            53.99796,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            335.9523,
            0,
            2.9115,
            0,
            27.54787,
            0,
            580.7782,
            0,
            0,
            0,
            29.40409,
            0,
            15.31393,
            "<D",
            114.0505,
            0,
        ],
        1728071100: [
            0.24591,
            0,
            0.24591,
            0,
            54.46308,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            331.9702,
            0,
            3.43121,
            0,
            27.50599,
            0,
            581.2374,
            0,
            0,
            0,
            29.40439,
            0,
            19.67285,
            "<D",
            113.8587,
            0,
        ],
        1728071160: [
            0.04886,
            0,
            0.04886,
            0,
            54.69215,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            336.1768,
            0,
            2.83705,
            0,
            27.40046,
            0,
            587.2198,
            0,
            0,
            0,
            29.40386,
            0,
            12.92719,
            "<D",
            113.2703,
            0,
        ],
        1728071220: [
            0.06474,
            0,
            0.06474,
            0,
            54.45393,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            332.6076,
            0,
            3.13014,
            0,
            27.32614,
            0,
            154.285,
            0,
            0,
            0,
            29.40382,
            0,
            18.43845,
            "<D",
            112.9194,
            0,
        ],
        1728071280: [
            0.20013,
            0,
            0.20013,
            0,
            54.26073,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            335.1243,
            0,
            3.41613,
            0,
            27.13464,
            0,
            105.2904,
            0,
            0,
            0,
            29.40225,
            0,
            19.04313,
            "<D",
            113.6554,
            0,
        ],
        1728071340: [
            0.28315,
            0,
            0.28315,
            0,
            54.35011,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            350.1263,
            0,
            3.19403,
            0,
            26.94798,
            0,
            99.00896,
            0,
            0,
            0,
            29.40332,
            0,
            16.26305,
            "<D",
            114.1702,
            0,
        ],
        1728071400: [
            0.41122,
            0,
            0.41122,
            0,
            54.22384,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            7.89806,
            0,
            3.27718,
            0,
            26.80138,
            0,
            135.2395,
            0,
            0,
            0,
            29.40325,
            0,
            15.31737,
            "<D",
            113.3793,
            0,
        ],
        1728071460: [
            0.20467,
            0,
            0.20467,
            0,
            54.43019,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            342.4532,
            0,
            2.82627,
            0,
            26.69007,
            0,
            236.7141,
            0,
            0,
            0,
            29.40349,
            0,
            16.19972,
            "<D",
            112.8806,
            0,
        ],
        1728071520: [
            0.29738,
            0,
            0.29738,
            0,
            53.5747,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            347.2473,
            0,
            2.46798,
            0,
            26.70272,
            0,
            588.028,
            0,
            0,
            0,
            29.40321,
            0,
            14.70865,
            "<D",
            114.3187,
            0,
        ],
        1728071580: [
            0.40007,
            0,
            0.40007,
            0,
            54.40659,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            357.1104,
            0,
            2.30192,
            0,
            26.92021,
            0,
            582.001,
            0,
            0,
            0,
            29.40295,
            0,
            15.6241,
            "<D",
            113.5479,
            0,
        ],
        1728071640: [
            0.34099,
            0,
            0.34099,
            0,
            54.81171,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            335.392,
            0,
            2.10871,
            0,
            27.09173,
            0,
            496.6661,
            0,
            0,
            0,
            29.40335,
            0,
            15.59011,
            "<D",
            112.8396,
            0,
        ],
        1728071700: [
            0.24468,
            0,
            0.24468,
            0,
            54.39615,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            9.54897,
            0,
            1.89102,
            0,
            27.27301,
            0,
            549.1277,
            0,
            0,
            0,
            29.40194,
            0,
            16.28249,
            "<D",
            114.9954,
            0,
        ],
        1728071760: [
            0.06904,
            0,
            0.06904,
            0,
            54.1464,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            33.4208,
            0,
            1.24092,
            0,
            27.61792,
            0,
            559.9589,
            0,
            0,
            0,
            29.39999,
            0,
            14.29249,
            "<D",
            114.1458,
            0,
        ],
        1728071820: [
            -0.03639,
            0,
            -0.03639,
            0,
            53.94662,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            352.5899,
            0,
            1.29033,
            0,
            27.77756,
            0,
            561.883,
            0,
            0,
            0,
            29.39902,
            0,
            18.75301,
            "<D",
            113.3371,
            0,
        ],
        1728071880: [
            0.11164,
            0,
            0.11164,
            0,
            53.74083,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            352.9349,
            0,
            1.218,
            0,
            27.80289,
            0,
            560.7078,
            0,
            0,
            0,
            29.39902,
            0,
            16.3295,
            "<D",
            114.273,
            0,
        ],
        1728071940: [
            0.15613,
            0,
            0.15613,
            0,
            53.41232,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            343.3315,
            0,
            1.43816,
            0,
            27.69917,
            0,
            558.1608,
            0,
            0,
            0,
            29.39902,
            0,
            16.56121,
            "<D",
            115.3076,
            0,
        ],
        1728072000: [
            0.21302,
            0,
            0.21302,
            0,
            53.56119,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            350.4819,
            0,
            1.84201,
            0,
            27.74568,
            0,
            557.4376,
            0,
            0,
            0,
            29.39902,
            0,
            15.66635,
            "<D",
            114.8706,
            0,
        ],
        1728072060: [
            0.29121,
            0,
            0.29121,
            0,
            53.83947,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            333.0508,
            0,
            1.57646,
            0,
            27.74556,
            0,
            537.6215,
            0,
            0,
            0,
            29.39902,
            0,
            16.58752,
            "<D",
            114.3119,
            0,
        ],
        1728072120: [
            0.28738,
            0,
            0.28738,
            0,
            53.63025,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            351.0042,
            0,
            1.85834,
            0,
            27.6848,
            0,
            136.4006,
            0,
            0,
            0,
            29.39976,
            0,
            16.50203,
            "<D",
            113.9124,
            0,
        ],
        1728072180: [
            0.34841,
            0,
            0.34841,
            0,
            53.60678,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            9.68516,
            0,
            1.36996,
            0,
            27.46092,
            0,
            98.8225,
            0,
            0,
            0,
            29.39958,
            0,
            14.59918,
            "<D",
            114.23,
            0,
        ],
        1728072240: [
            0.16518,
            0,
            0.16518,
            0,
            53.31108,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            10.20725,
            0,
            1.38227,
            0,
            27.16219,
            0,
            99.13447,
            0,
            0,
            0,
            29.3989,
            0,
            15.37994,
            "<D",
            114.2137,
            0,
        ],
        1728072300: [
            0.2349,
            0,
            0.2349,
            0,
            52.89816,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            42.82364,
            0,
            1.13693,
            0,
            26.97932,
            0,
            106.8809,
            0,
            0,
            0,
            29.3983,
            0,
            16.19289,
            "<D",
            114.8665,
            0,
        ],
        1728072360: [
            0.25373,
            0,
            0.25373,
            0,
            53.40463,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            85.25003,
            0,
            0.64269,
            0,
            27.01035,
            0,
            140.9547,
            0,
            0,
            0,
            29.39679,
            0,
            16.01606,
            "<D",
            114.564,
            0,
        ],
        1728072420: [
            0.187,
            0,
            0.187,
            0,
            54.13891,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            60.65199,
            0,
            0.25476,
            0,
            27.02772,
            0,
            156.0076,
            0,
            0,
            0,
            29.39568,
            0,
            14.20814,
            "<D",
            113.5996,
            0,
        ],
        1728072480: [
            0.27248,
            0,
            0.27248,
            0,
            53.86559,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            358.5195,
            0,
            0.33888,
            0,
            27.03592,
            0,
            503.4045,
            0,
            0,
            0,
            29.39562,
            0,
            16.14512,
            "<D",
            113.2186,
            0,
        ],
        1728072540: [
            0.20878,
            0,
            0.20878,
            0,
            52.64292,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            317.0935,
            0,
            0.23337,
            0,
            27.52975,
            0,
            550.9417,
            0,
            0,
            0,
            29.39557,
            0,
            14.83874,
            "<D",
            113.7974,
            0,
        ],
        1728072600: [
            0.07541,
            0,
            0.07541,
            0,
            51.49523,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            308.5767,
            0,
            0.98848,
            0,
            27.70857,
            0,
            547.4437,
            0,
            0,
            0,
            29.39554,
            0,
            12.99412,
            "<D",
            114.7623,
            0,
        ],
        1728072660: [
            0.32634,
            0,
            0.32634,
            0,
            52.41959,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            343.6943,
            0,
            1.9978,
            0,
            27.76453,
            0,
            539.9055,
            0,
            0,
            0,
            29.39518,
            0,
            15.99922,
            "<D",
            114.0823,
            0,
        ],
        1728072720: [
            0.31744,
            0,
            0.31744,
            0,
            52.62552,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            339.8806,
            0,
            2.26766,
            0,
            27.69252,
            0,
            533.6448,
            0,
            0,
            0,
            29.39514,
            0,
            14.76978,
            "<D",
            113.9108,
            0,
        ],
        1728072780: [
            0.29266,
            0,
            0.29266,
            0,
            52.97054,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            345.7963,
            0,
            2.74191,
            0,
            27.78749,
            0,
            531.8643,
            0,
            0,
            0,
            29.39505,
            0,
            15.90021,
            "<D",
            114.07,
            0,
        ],
        1728072840: [
            0.1822,
            0,
            0.1822,
            0,
            53.52456,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            327.819,
            0,
            1.38099,
            0,
            27.60049,
            0,
            530.4003,
            0,
            0,
            0,
            29.39455,
            0,
            15.42302,
            "<D",
            114.9165,
            0,
        ],
        1728072900: [
            0.29255,
            0,
            0.29255,
            0,
            53.95763,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            295.1002,
            0,
            1.12718,
            0,
            27.62442,
            0,
            526.5836,
            0,
            0,
            0,
            29.39481,
            0,
            19.37638,
            "<D",
            113.8582,
            0,
        ],
        1728072960: [
            0.24249,
            0,
            0.24249,
            0,
            53.98226,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            341.3211,
            0,
            1.41433,
            0,
            27.71469,
            0,
            523.9406,
            0,
            0,
            0,
            29.39397,
            0,
            16.99621,
            "<D",
            114.567,
            0,
        ],
        1728073020: [
            0.23348,
            0,
            0.23348,
            0,
            53.45841,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            327.0115,
            0,
            1.56908,
            0,
            27.94372,
            0,
            519.1346,
            0,
            0,
            0,
            29.39472,
            0,
            17.63953,
            "<D",
            113.3396,
            0,
        ],
        1728073080: [
            0.23543,
            0,
            0.23543,
            0,
            52.9569,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            337.6215,
            0,
            2.22591,
            0,
            27.85708,
            0,
            516.995,
            0,
            0,
            0,
            29.394,
            0,
            16.89544,
            "<D",
            113.3735,
            0,
        ],
        1728073140: [
            0.08058,
            0,
            0.08058,
            0,
            52.29808,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            325.8808,
            0,
            2.31502,
            0,
            27.78362,
            0,
            519.3207,
            0,
            0,
            0,
            29.39397,
            0,
            15.06488,
            "<D",
            112.8687,
            0,
        ],
        1728073200: [
            0.20473,
            0,
            0.20473,
            0,
            53.30876,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            317.0374,
            0,
            2.28983,
            0,
            27.75683,
            0,
            511.9249,
            0,
            0,
            0,
            29.3928,
            0,
            20.12786,
            "<D",
            113.6429,
            0,
        ],
        1728073260: [
            0.24158,
            0,
            0.24158,
            0,
            53.2896,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            315.7216,
            0,
            1.95181,
            0,
            27.69974,
            0,
            493.5213,
            0,
            0,
            0,
            29.39255,
            0,
            17.85976,
            "<D",
            115.2363,
            0,
        ],
        1728073320: [
            0.27838,
            0,
            0.27838,
            0,
            53.56736,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            314.2978,
            0,
            1.60891,
            0,
            27.67258,
            0,
            484.1116,
            0,
            0,
            0,
            29.39249,
            0,
            16.24381,
            "<D",
            114.6681,
            0,
        ],
        1728073380: [
            0.1888,
            0,
            0.1888,
            0,
            53.7158,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            327.4149,
            0,
            1.46921,
            0,
            27.86899,
            0,
            480.9318,
            0,
            0,
            0,
            29.39263,
            0,
            17.20392,
            "<D",
            114.5455,
            0,
        ],
        1728073440: [
            0.22826,
            0,
            0.22826,
            0,
            52.02442,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            342.7499,
            0,
            2.27614,
            0,
            27.74113,
            0,
            479.3978,
            0,
            0,
            0,
            29.39027,
            0,
            14.46411,
            "<D",
            114.4969,
            0,
        ],
        1728073500: [
            0.02004,
            0,
            0.02004,
            0,
            52.46722,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            16.07974,
            0,
            2.20024,
            0,
            27.63493,
            0,
            476.9802,
            0,
            0,
            0,
            29.38962,
            0,
            15.35544,
            "<D",
            115.2554,
            0,
        ],
        1728073560: [
            0.18664,
            0,
            0.18664,
            0,
            51.32595,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            42.53499,
            0,
            1.01521,
            0,
            27.78954,
            0,
            474.1519,
            0,
            0,
            0,
            29.38949,
            0,
            14.08843,
            "<D",
            115.7374,
            0,
        ],
        1728073620: [
            0.21824,
            0,
            0.21824,
            0,
            51.0391,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            31.68055,
            0,
            0.97914,
            0,
            27.76821,
            0,
            472.5449,
            0,
            0,
            0,
            29.38892,
            0,
            14.09555,
            "<D",
            115.5021,
            0,
        ],
        1728073680: [
            0.21645,
            0,
            0.21645,
            0,
            51.50661,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            24.43388,
            0,
            1.43395,
            0,
            27.88733,
            0,
            470.2102,
            0,
            0,
            0,
            29.38695,
            0,
            16.04439,
            "<D",
            114.1487,
            0,
        ],
        1728073740: [
            0.13677,
            0,
            0.13677,
            0,
            52.21853,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            44.40899,
            0,
            0.95194,
            0,
            28.11709,
            0,
            468.52,
            0,
            0,
            0,
            29.38538,
            0,
            15.49358,
            "<D",
            113.136,
            0,
        ],
        1728073800: [
            0.07162,
            0,
            0.07162,
            0,
            51.62489,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            49.63624,
            0,
            1.56035,
            0,
            27.7215,
            0,
            466.1896,
            0,
            0,
            0,
            29.38582,
            0,
            14.96086,
            "<D",
            113.8021,
            0,
        ],
        1728073860: [
            0.29445,
            0,
            0.29445,
            0,
            53.75964,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            35.74857,
            0,
            0.97143,
            0,
            27.59463,
            0,
            463.1541,
            0,
            0,
            0,
            29.38531,
            0,
            16.45001,
            "<D",
            114.5277,
            0,
        ],
        1728073920: [
            0.31538,
            0,
            0.31538,
            0,
            53.2202,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            1.02148,
            0,
            1.5052,
            0,
            27.72917,
            0,
            459.7434,
            0,
            0,
            0,
            29.38538,
            0,
            17.02593,
            "<D",
            114.6798,
            0,
        ],
        1728073980: [
            0.44695,
            0,
            0.44695,
            0,
            52.96401,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            355.6235,
            0,
            2.38274,
            0,
            27.75859,
            0,
            456.4923,
            0,
            0,
            0,
            29.38588,
            0,
            16.61701,
            "<D",
            113.922,
            0,
        ],
        1728074040: [
            0.17531,
            0,
            0.17531,
            0,
            53.27,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            356.7397,
            0,
            1.86334,
            0,
            27.50073,
            0,
            454.0998,
            0,
            0,
            0,
            29.38592,
            0,
            12.9442,
            "<D",
            114.194,
            0,
        ],
        1728074100: [
            0.23644,
            0,
            0.23644,
            0,
            53.67839,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            3.43084,
            0,
            1.74131,
            0,
            27.4815,
            0,
            452.1579,
            0,
            0,
            0,
            29.38597,
            0,
            16.90636,
            "<D",
            113.8234,
            0,
        ],
        1728074160: [
            0.17535,
            0,
            0.17535,
            0,
            53.47967,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            348.6488,
            0,
            1.30026,
            0,
            27.5866,
            0,
            450.3386,
            0,
            0,
            0,
            29.38596,
            0,
            18.82833,
            "<D",
            114.8908,
            0,
        ],
        1728074220: [
            0.27125,
            0,
            0.27125,
            0,
            53.37561,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            329.8042,
            0,
            0.95178,
            0,
            27.80909,
            0,
            447.7322,
            0,
            0,
            0,
            29.38591,
            0,
            13.51655,
            "<D",
            115.116,
            0,
        ],
        1728074280: [
            0.19437,
            0,
            0.19437,
            0,
            52.14275,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            354.1574,
            0,
            0.69248,
            0,
            28.03552,
            0,
            445.3624,
            0,
            0,
            0,
            29.38298,
            0,
            13.56966,
            "<D",
            116.1269,
            0,
        ],
        1728074340: [
            0.1255,
            0,
            0.1255,
            0,
            51.97888,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            144.7028,
            0,
            0.33096,
            0,
            27.80539,
            0,
            443.3442,
            0,
            0,
            0,
            29.38253,
            0,
            18.93637,
            "<D",
            113.4719,
            0,
        ],
        1728074400: [
            0.23144,
            0,
            0.23144,
            0,
            51.3748,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            283.4355,
            0,
            0.24493,
            0,
            27.89243,
            0,
            440.7667,
            0,
            0,
            0,
            29.38227,
            0,
            13.58294,
            "<D",
            113.3341,
            0,
        ],
        1728074460: [
            0.26441,
            0,
            0.26441,
            0,
            51.90916,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            176.7592,
            0,
            0.94952,
            0,
            28.02897,
            0,
            438.4098,
            0,
            0,
            0,
            29.38206,
            0,
            16.2826,
            "<D",
            113.8097,
            0,
        ],
        1728074520: [
            -0.07936,
            0,
            -0.07936,
            0,
            53.04505,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            190.0433,
            0,
            0.78878,
            0,
            27.91776,
            0,
            435.6461,
            0,
            0,
            0,
            29.38195,
            0,
            13.16953,
            "<D",
            114.637,
            0,
        ],
        1728074580: [
            0.29144,
            0,
            0.29144,
            0,
            52.9542,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            187.4665,
            0,
            0.61896,
            0,
            28.11943,
            0,
            432.9951,
            0,
            0,
            0,
            29.38235,
            0,
            16.43136,
            "<D",
            115.3659,
            0,
        ],
        1728074640: [
            0.29057,
            0,
            0.29057,
            0,
            50.27444,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            346.0136,
            0,
            0.19709,
            0,
            28.00349,
            0,
            429.0403,
            0,
            0,
            0,
            29.38267,
            0,
            15.53236,
            "<D",
            113.7875,
            0,
        ],
        1728074700: [
            0.22054,
            0,
            0.22054,
            0,
            50.95875,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            345.6915,
            0,
            2.46736,
            0,
            27.93218,
            0,
            426.408,
            0,
            0,
            0,
            29.38244,
            0,
            17.993,
            "<D",
            113.8699,
            0,
        ],
        1728074760: [
            0.41753,
            0,
            0.41753,
            0,
            52.78502,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            342.8459,
            0,
            2.37021,
            0,
            27.62046,
            0,
            424.3277,
            0,
            0,
            0,
            29.38218,
            0,
            15.06695,
            "<D",
            114.3623,
            0,
        ],
        1728074820: [
            0.17591,
            0,
            0.17591,
            0,
            53.5515,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            345.6541,
            0,
            1.94719,
            0,
            27.42902,
            0,
            422.2128,
            0,
            0,
            0,
            29.38226,
            0,
            15.05888,
            "<D",
            114.43,
            0,
        ],
        1728074880: [
            0.15188,
            0,
            0.15188,
            0,
            54.05692,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            304.7212,
            0,
            1.2918,
            0,
            27.36147,
            0,
            419.7978,
            0,
            0,
            0,
            29.38225,
            0,
            13.18696,
            "<D",
            115.8223,
            0,
        ],
        1728074940: [
            0.08203,
            0,
            0.08203,
            0,
            53.62551,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            331.7139,
            0,
            1.2947,
            0,
            27.47216,
            0,
            417.3043,
            0,
            0,
            0,
            29.38241,
            0,
            18.88585,
            "<D",
            115.3248,
            0,
        ],
        1728075000: [
            0.24515,
            0,
            0.24515,
            0,
            53.15515,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            343.1459,
            0,
            0.94209,
            0,
            27.71118,
            0,
            414.5313,
            0,
            0,
            0,
            29.3824,
            0,
            16.50682,
            "<D",
            114.6322,
            0,
        ],
        1728075060: [
            0.12721,
            0,
            0.12721,
            0,
            52.79408,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            321.1273,
            0,
            2.06957,
            0,
            27.67997,
            0,
            410.9835,
            0,
            0,
            0,
            29.38051,
            0,
            19.18832,
            "<D",
            114.8021,
            0,
        ],
        1728075120: [
            0.32036,
            0,
            0.32036,
            0,
            53.30122,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            347.1402,
            0,
            1.92301,
            0,
            27.50724,
            0,
            408.0912,
            0,
            0,
            0,
            29.37902,
            0,
            17.3222,
            "<D",
            114.2012,
            0,
        ],
        1728075180: [
            0.2092,
            0,
            0.2092,
            0,
            53.23735,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            353.6498,
            0,
            2.09595,
            0,
            27.46226,
            0,
            405.3996,
            0,
            0,
            0,
            29.37864,
            0,
            15.40766,
            "<D",
            115.6167,
            0,
        ],
        1728075240: [
            0.26335,
            0,
            0.26335,
            0,
            52.8568,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            330.3834,
            0,
            2.03047,
            0,
            27.46391,
            0,
            402.7922,
            0,
            0,
            0,
            29.37751,
            0,
            14.29942,
            "<D",
            116.2258,
            0,
        ],
        1728075300: [
            0.2627,
            0,
            0.2627,
            0,
            53.04152,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            16.245,
            0,
            2.5899,
            0,
            27.42382,
            0,
            399.814,
            0,
            0,
            0,
            29.37587,
            0,
            15.34561,
            "<D",
            114.6436,
            0,
        ],
        1728075360: [
            0.2381,
            0,
            0.2381,
            0,
            50.92601,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            35.77422,
            0,
            1.94353,
            0,
            27.66968,
            0,
            396.775,
            0,
            0,
            0,
            29.37546,
            0,
            15.31601,
            "<D",
            114.1926,
            0,
        ],
        1728075420: [
            0.10865,
            0,
            0.10865,
            0,
            52.62469,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            30.96826,
            0,
            1.61605,
            0,
            27.52859,
            0,
            393.4563,
            0,
            0,
            0,
            29.37452,
            0,
            12.55872,
            "<D",
            114.748,
            0,
        ],
        1728075480: [
            0.31374,
            0,
            0.31374,
            0,
            53.46521,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            5.54515,
            0,
            1.33785,
            0,
            27.49826,
            0,
            390.7309,
            0,
            0,
            0,
            29.37299,
            0,
            16.39735,
            "<D",
            114.4118,
            0,
        ],
        1728075540: [
            0.20544,
            0,
            0.20544,
            0,
            52.11422,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            15.31911,
            0,
            1.17381,
            0,
            27.66224,
            0,
            388.8322,
            0,
            0,
            0,
            29.37173,
            0,
            16.78231,
            "<D",
            115.4238,
            0,
        ],
        1728075600: [
            0.30458,
            0,
            0.30458,
            0,
            50.10907,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            67.86891,
            0,
            0.96416,
            0,
            27.81881,
            0,
            386.9927,
            0,
            0,
            0,
            29.37132,
            0,
            16.05261,
            "<D",
            114.9892,
            0,
        ],
        1728075660: [
            0.21458,
            0,
            0.21458,
            0,
            49.08446,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            115.5136,
            0,
            0.56178,
            0,
            27.96576,
            0,
            384.5078,
            0,
            0,
            0,
            29.37132,
            0,
            14.61508,
            "<D",
            114.8636,
            0,
        ],
        1728075720: [
            0.31266,
            0,
            0.31266,
            0,
            49.90606,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            93.11547,
            0,
            1.01944,
            0,
            27.98866,
            0,
            381.1483,
            0,
            0,
            0,
            29.37132,
            0,
            14.89833,
            "<D",
            114.4962,
            0,
        ],
        1728075780: [
            0.25419,
            0,
            0.25419,
            0,
            50.3057,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            351.2036,
            0,
            1.68338,
            0,
            27.81273,
            0,
            378.3881,
            0,
            0,
            0,
            29.37099,
            0,
            18.51946,
            "<D",
            115.0847,
            0,
        ],
        1728075840: [
            0.30195,
            0,
            0.30195,
            0,
            52.08975,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            6.36007,
            0,
            1.83057,
            0,
            27.57607,
            0,
            375.1011,
            0,
            0,
            0,
            29.37115,
            0,
            19.29357,
            "<D",
            116.4542,
            0,
        ],
        1728075900: [
            0.32943,
            0,
            0.32943,
            0,
            52.63929,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            43.90639,
            0,
            1.42092,
            0,
            27.57652,
            0,
            371.4528,
            0,
            0,
            0,
            29.37134,
            0,
            16.8058,
            "<D",
            115.465,
            0,
        ],
        1728075960: [
            0.4491,
            0,
            0.4491,
            0,
            53.65156,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            60.9167,
            0,
            1.23711,
            0,
            27.46529,
            0,
            368.356,
            0,
            0,
            0,
            29.37138,
            0,
            15.23814,
            "<D",
            114.8399,
            0,
        ],
        1728076020: [
            0.25266,
            0,
            0.25266,
            0,
            53.40308,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            77.45174,
            0,
            0.55692,
            0,
            27.55516,
            0,
            365.1487,
            0,
            0,
            0,
            29.37101,
            0,
            15.08076,
            "<D",
            114.4751,
            0,
        ],
        1728076080: [
            0.32898,
            0,
            0.32898,
            0,
            53.31372,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            13.27288,
            0,
            0.78167,
            0,
            27.69637,
            0,
            361.6365,
            0,
            0,
            0,
            29.37138,
            0,
            15.87697,
            "<D",
            113.8433,
            0,
        ],
        1728076140: [
            0.22576,
            0,
            0.22576,
            0,
            53.22489,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            11.44945,
            0,
            1.14531,
            0,
            27.78098,
            0,
            358.742,
            0,
            0,
            0,
            29.36985,
            0,
            18.50258,
            "<D",
            113.7798,
            0,
        ],
        1728076200: [
            0.2088,
            0,
            0.2088,
            0,
            52.5272,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            39.65628,
            0,
            0.74891,
            0,
            27.74618,
            0,
            356.3863,
            0,
            0,
            0,
            29.36853,
            0,
            15.56161,
            "<D",
            115.631,
            0,
        ],
        1728076260: [
            0.21954,
            0,
            0.21954,
            0,
            52.25905,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            17.14489,
            0,
            0.75537,
            0,
            27.57631,
            0,
            353.2121,
            0,
            0,
            0,
            29.36802,
            0,
            17.6757,
            "<D",
            114.8271,
            0,
        ],
        1728076320: [
            0.14373,
            0,
            0.14373,
            0,
            52.95795,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            68.73627,
            0,
            1.13705,
            0,
            27.70271,
            0,
            350.9301,
            0,
            0,
            0,
            29.3679,
            0,
            14.15724,
            "<D",
            114.7442,
            0,
        ],
        1728076380: [
            0.13797,
            0,
            0.13797,
            0,
            52.96196,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            41.78038,
            0,
            1.0806,
            0,
            27.76333,
            0,
            348.1817,
            0,
            0,
            0,
            29.36799,
            0,
            16.29565,
            "<D",
            114.7286,
            0,
        ],
        1728076440: [
            0.31423,
            0,
            0.31423,
            0,
            53.90639,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            25.84463,
            0,
            0.62305,
            0,
            27.71018,
            0,
            344.5895,
            0,
            0,
            0,
            29.36795,
            0,
            16.55179,
            "<D",
            115.5472,
            0,
        ],
        1728076500: [
            0.26781,
            0,
            0.26781,
            0,
            53.99611,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            334.5763,
            0,
            0.66938,
            0,
            27.65729,
            0,
            340.8728,
            0,
            0,
            0,
            29.36796,
            0,
            20.69198,
            "<D",
            115.6757,
            0,
        ],
        1728076560: [
            0.32787,
            0,
            0.32787,
            0,
            53.05413,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            330.5919,
            0,
            1.03396,
            0,
            27.60942,
            0,
            338.1067,
            0,
            0,
            0,
            29.36762,
            0,
            17.04389,
            "<D",
            114.5787,
            0,
        ],
        1728076620: [
            0.2286,
            0,
            0.2286,
            0,
            54.04763,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            325.871,
            0,
            0.89796,
            0,
            27.48435,
            0,
            334.2255,
            0,
            0,
            0,
            29.3676,
            0,
            13.12176,
            "<D",
            113.7039,
            0,
        ],
        1728076680: [
            0.19886,
            0,
            0.19886,
            0,
            53.72201,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            346.2519,
            0,
            0.71723,
            0,
            27.60999,
            0,
            330.3746,
            0,
            0,
            0,
            29.36741,
            0,
            18.02423,
            "<D",
            114.0466,
            0,
        ],
        1728076740: [
            0.46366,
            0,
            0.46366,
            0,
            52.97296,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            337.4898,
            0,
            0.8245,
            0,
            27.65517,
            0,
            327.1761,
            0,
            0,
            0,
            29.36782,
            0,
            20.10631,
            "<D",
            114.2278,
            0,
        ],
        1728076800: [
            0.33171,
            0,
            0.33171,
            0,
            52.05291,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            310.942,
            0,
            0.91998,
            0,
            27.55375,
            0,
            324.1151,
            0,
            0,
            0,
            29.36814,
            0,
            17.25673,
            "<D",
            115.1401,
            0,
        ],
        1728076860: [
            0.20379,
            0,
            0.20379,
            0,
            52.93764,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            292.1007,
            0,
            1.08922,
            0,
            27.57881,
            0,
            321.3669,
            0,
            0,
            0,
            29.36803,
            0,
            16.54177,
            "<D",
            114.5124,
            0,
        ],
        1728076920: [
            0.26096,
            0,
            0.26096,
            0,
            53.51612,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            267.631,
            0,
            0.82345,
            0,
            27.62654,
            0,
            318.2684,
            0,
            0,
            0,
            29.36843,
            0,
            18.54623,
            "<D",
            114.1802,
            0,
        ],
        1728076980: [
            0.14774,
            0,
            0.14774,
            0,
            53.88224,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            233.6035,
            0,
            0.69744,
            0,
            27.67707,
            0,
            314.6087,
            0,
            0,
            0,
            29.36996,
            0,
            18.72262,
            "<D",
            112.4164,
            0,
        ],
        1728077040: [
            0.20834,
            0,
            0.20834,
            0,
            54.11985,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            268.8772,
            0,
            1.00462,
            0,
            27.79621,
            0,
            311.4053,
            0,
            0,
            0,
            29.37076,
            0,
            18.68584,
            "<D",
            113.1787,
            0,
        ],
        1728077100: [
            0.35341,
            0,
            0.35341,
            0,
            53.96048,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            289.3309,
            0,
            0.91722,
            0,
            27.84942,
            0,
            307.8377,
            0,
            0,
            0,
            29.37034,
            0,
            18.59367,
            "<D",
            114.3089,
            0,
        ],
        1728077160: [
            0.19894,
            0,
            0.19894,
            0,
            52.68979,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            293.55,
            0,
            0.63397,
            0,
            27.7638,
            0,
            305.1444,
            0,
            0,
            0,
            29.37034,
            0,
            15.90262,
            "<D",
            114.9684,
            0,
        ],
        1728077220: [
            0.15326,
            0,
            0.15326,
            0,
            51.81684,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            293.0356,
            0,
            1.04826,
            0,
            27.69094,
            0,
            302.0478,
            0,
            0,
            0,
            29.37022,
            0,
            13.90619,
            "<D",
            113.2519,
            0,
        ],
        1728077280: [
            0.36656,
            0,
            0.36656,
            0,
            52.43014,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            238.0124,
            0,
            0.66691,
            0,
            27.76735,
            0,
            298.6862,
            0,
            0,
            0,
            29.3674,
            0,
            16.31385,
            "<D",
            113.7253,
            0,
        ],
        1728077340: [
            0.24482,
            0,
            0.24482,
            0,
            51.30923,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            261.1114,
            0,
            0.80184,
            0,
            27.93231,
            0,
            295.5696,
            0,
            0,
            0,
            29.3678,
            0,
            19.41877,
            "<D",
            114.2864,
            0,
        ],
        1728077400: [
            0.25873,
            0,
            0.25873,
            0,
            52.116,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            293.188,
            0,
            0.53584,
            0,
            27.92975,
            0,
            292.0242,
            0,
            0,
            0,
            29.36735,
            0,
            16.83694,
            "<D",
            114.08,
            0,
        ],
        1728077460: [
            0.31027,
            0,
            0.31027,
            0,
            53.00299,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            222.2564,
            0,
            0.37643,
            0,
            28.0614,
            0,
            289.1709,
            0,
            0,
            0,
            29.36473,
            0,
            16.35323,
            "<D",
            114.247,
            0,
        ],
        1728077520: [
            0.27772,
            0,
            0.27772,
            0,
            52.83198,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            198.7146,
            0,
            0.36978,
            0,
            27.81799,
            0,
            286.3945,
            0,
            0,
            0,
            29.36511,
            0,
            18.33269,
            "<D",
            114.4684,
            0,
        ],
        1728077580: [
            0.16778,
            0,
            0.16778,
            0,
            53.5515,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            201.3953,
            0,
            0.58639,
            0,
            27.77445,
            0,
            283.4561,
            0,
            0,
            0,
            29.36464,
            0,
            14.82778,
            "<D",
            114.7921,
            0,
        ],
        1728077640: [
            0.33462,
            0,
            0.33462,
            0,
            53.80971,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            175.2272,
            0,
            0.878,
            0,
            27.9861,
            0,
            280.7433,
            0,
            0,
            0,
            29.36444,
            0,
            17.27688,
            "<D",
            113.2268,
            0,
        ],
        1728077700: [
            0.42992,
            0,
            0.42992,
            0,
            53.63084,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            206.077,
            0,
            1.03112,
            0,
            28.09126,
            0,
            278.3304,
            0,
            0,
            0,
            29.36399,
            0,
            13.65911,
            "<D",
            114.0925,
            0,
        ],
        1728077760: [
            0.10783,
            0,
            0.10783,
            0,
            53.10683,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            223.0231,
            0,
            1.05808,
            0,
            27.88032,
            0,
            275.9618,
            0,
            0,
            0,
            29.36407,
            0,
            15.27043,
            "<D",
            115.0658,
            0,
        ],
        1728077820: [
            0.134,
            0,
            0.134,
            0,
            52.61032,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            228.4002,
            0,
            1.01239,
            0,
            27.67379,
            0,
            272.6439,
            0,
            0,
            0,
            29.36319,
            0,
            16.66071,
            "<D",
            114.9529,
            0,
        ],
        1728077880: [
            0.24123,
            0,
            0.24123,
            0,
            52.74006,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            221.1429,
            0,
            0.58061,
            0,
            27.94041,
            0,
            268.9745,
            0,
            0,
            0,
            29.36374,
            0,
            14.17214,
            "<D",
            114.4545,
            0,
        ],
        1728077940: [
            0.15151,
            0,
            0.15151,
            0,
            52.35274,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            207.1569,
            0,
            0.52871,
            0,
            27.9496,
            0,
            265.4946,
            0,
            0,
            0,
            29.36368,
            0,
            17.00408,
            "<D",
            114.4312,
            0,
        ],
        1728078000: [
            0.1455,
            0,
            0.1455,
            0,
            51.93563,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            229.5394,
            0,
            0.79346,
            0,
            27.94242,
            0,
            261.9152,
            0,
            0,
            0,
            29.36346,
            0,
            14.58415,
            "<D",
            114.3675,
            0,
        ],
        1728078060: [
            0.16625,
            0,
            0.16625,
            0,
            52.65142,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            236.9775,
            0,
            0.5264,
            0,
            27.87079,
            0,
            258.6472,
            0,
            0,
            0,
            29.36161,
            0,
            13.23858,
            "<D",
            114.5041,
            0,
        ],
        1728078120: [
            0.24509,
            0,
            0.24509,
            0,
            53.37635,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            251.8142,
            0,
            0.76499,
            0,
            28.10547,
            0,
            255.5441,
            0,
            0,
            0,
            29.36107,
            0,
            20.46013,
            "<D",
            115.4255,
            0,
        ],
        1728078180: [
            0.33745,
            0,
            0.33745,
            0,
            52.94938,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            203.7677,
            0,
            0.69622,
            0,
            27.84194,
            0,
            252.7685,
            0,
            0,
            0,
            29.36074,
            0,
            12.49679,
            "<D",
            115.2884,
            0,
        ],
        1728078240: [
            0.28512,
            0,
            0.28512,
            0,
            53.51775,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            219.6426,
            0,
            0.88865,
            0,
            27.6473,
            0,
            250.2996,
            0,
            0,
            0,
            29.36084,
            0,
            13.41184,
            "<D",
            114.6194,
            0,
        ],
        1728078300: [
            0.20371,
            0,
            0.20371,
            0,
            52.94019,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            244.626,
            0,
            0.7619,
            0,
            27.74648,
            0,
            247.2151,
            0,
            0,
            0,
            29.36079,
            0,
            15.66056,
            "<D",
            114.8664,
            0,
        ],
        1728078360: [
            0.05555,
            0,
            0.05555,
            0,
            52.58667,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            227.1301,
            0,
            0.68009,
            0,
            27.40075,
            0,
            244.0985,
            0,
            0,
            0,
            29.36074,
            0,
            15.92911,
            "<D",
            115.6106,
            0,
        ],
        1728078420: [
            0.31546,
            0,
            0.31546,
            0,
            52.55241,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            212.4886,
            0,
            0.89573,
            0,
            27.21283,
            0,
            241.2606,
            0,
            0,
            0,
            29.36076,
            0,
            14.79846,
            "<D",
            116.8386,
            0,
        ],
        1728078480: [
            0.34217,
            0,
            0.34217,
            0,
            52.47603,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            212.7738,
            0,
            0.93611,
            0,
            27.12575,
            0,
            238.3904,
            0,
            0,
            0,
            29.36075,
            0,
            18.66045,
            "<D",
            114.8701,
            0,
        ],
        1728078540: [
            0.35523,
            0,
            0.35523,
            0,
            52.45973,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            230.1444,
            0,
            1.01062,
            0,
            27.20113,
            0,
            235.6411,
            0,
            0,
            0,
            29.36076,
            0,
            14.60806,
            "<D",
            114.7368,
            0,
        ],
        1728078600: [
            -0.13554,
            0,
            -0.13554,
            0,
            53.12945,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            206.0602,
            0,
            0.71199,
            0,
            27.20736,
            0,
            232.9815,
            0,
            0,
            0,
            29.36123,
            0,
            13.69738,
            "<D",
            114.9197,
            0,
        ],
        1728078660: [
            0.33869,
            0,
            0.33869,
            0,
            53.12919,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            234.9618,
            0,
            0.51236,
            0,
            27.53277,
            0,
            229.2426,
            0,
            0,
            0,
            29.36292,
            0,
            13.61496,
            "<D",
            114.9351,
            0,
        ],
        1728078720: [
            0.17432,
            0,
            0.17432,
            0,
            52.78647,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            309.9899,
            0,
            0.95336,
            0,
            27.42995,
            0,
            225.6558,
            0,
            0,
            0,
            29.36401,
            0,
            15.62042,
            "<D",
            116.0915,
            0,
        ],
        1728078780: [
            0.27339,
            0,
            0.27339,
            0,
            52.16516,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            284.908,
            0,
            1.38379,
            0,
            27.49032,
            0,
            222.2882,
            0,
            0,
            0,
            29.36406,
            0,
            17.42924,
            "<D",
            114.9714,
            0,
        ],
        1728078840: [
            0.07764,
            0,
            0.07764,
            0,
            53.38684,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            290.1083,
            0,
            1.16717,
            0,
            27.45344,
            0,
            218.8854,
            0,
            0,
            0,
            29.36414,
            0,
            14.66444,
            "<D",
            114.4377,
            0,
        ],
        1728078900: [
            0.24348,
            0,
            0.24348,
            0,
            53.74107,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            277.9333,
            0,
            0.82046,
            0,
            27.45583,
            0,
            215.8613,
            0,
            0,
            0,
            29.36411,
            0,
            13.95221,
            "<D",
            115.2163,
            0,
        ],
        1728078960: [
            0.26548,
            0,
            0.26548,
            0,
            52.70437,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            270.5831,
            0,
            0.84184,
            0,
            27.56089,
            0,
            207.1013,
            0,
            0,
            0,
            29.36403,
            0,
            14.6979,
            "<D",
            115.6941,
            0,
        ],
        1728079020: [
            0.28084,
            0,
            0.28084,
            0,
            53.17879,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            291.3375,
            0,
            0.49387,
            0,
            28.01523,
            0,
            198.715,
            0,
            0,
            0,
            29.36404,
            0,
            15.91504,
            "<D",
            116.8977,
            0,
        ],
        1728079080: [
            0.35104,
            0,
            0.35104,
            0,
            52.24311,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            309.6141,
            0,
            0.4992,
            0,
            27.78486,
            0,
            204.8195,
            0,
            0,
            0,
            29.36404,
            0,
            11.87971,
            "<D",
            114.7177,
            0,
        ],
        1728079140: [
            0.30515,
            0,
            0.30515,
            0,
            52.14104,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            284.8023,
            0,
            0.76238,
            0,
            27.61098,
            0,
            203.9263,
            0,
            0,
            0,
            29.36417,
            0,
            14.62959,
            "<D",
            114.1883,
            0,
        ],
        1728079200: [
            0.28676,
            0,
            0.28676,
            0,
            52.1095,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            273.4706,
            0,
            1.17641,
            0,
            27.62657,
            0,
            200.2117,
            0,
            0,
            0,
            29.36648,
            0,
            14.79863,
            "<D",
            114.8038,
            0,
        ],
        1728079260: [
            0.2681,
            0,
            0.2681,
            0,
            53.71579,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            273.6214,
            0,
            0.75516,
            0,
            27.4772,
            0,
            196.422,
            0,
            0,
            0,
            29.36734,
            0,
            14.99064,
            "<D",
            114.7514,
            0,
        ],
        1728079320: [
            0.28729,
            0,
            0.28729,
            0,
            52.67331,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            322.0145,
            0,
            0.67634,
            0,
            27.34765,
            0,
            193.2659,
            0,
            0,
            0,
            29.36733,
            0,
            17.1885,
            "<D",
            115.1249,
            0,
        ],
        1728079380: [
            0.3067,
            0,
            0.3067,
            0,
            52.13513,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            305.0266,
            0,
            1.18357,
            0,
            27.27108,
            0,
            189.9433,
            0,
            0,
            0,
            29.36918,
            0,
            13.41378,
            "<D",
            115.6739,
            0,
        ],
        1728079440: [
            0.1558,
            0,
            0.1558,
            0,
            52.9198,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            281.2349,
            0,
            0.90272,
            0,
            27.18413,
            0,
            186.0095,
            0,
            0,
            0,
            29.37094,
            0,
            12.47281,
            "<D",
            115.0728,
            0,
        ],
        1728079500: [
            0.24423,
            0,
            0.24423,
            0,
            53.68887,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            285.5081,
            0,
            1.09024,
            0,
            27.15415,
            0,
            182.4524,
            0,
            0,
            0,
            29.37134,
            0,
            16.97133,
            "<D",
            115.0558,
            0,
        ],
        1728079560: [
            0.16277,
            0,
            0.16277,
            0,
            54.0559,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            300.4809,
            0,
            0.89105,
            0,
            27.05788,
            0,
            174.1718,
            0,
            0,
            0,
            29.37336,
            0,
            16.1406,
            "<D",
            115.4466,
            0,
        ],
        1728079620: [
            0.06753,
            0,
            0.06753,
            0,
            53.85598,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            329.1251,
            0,
            1.00194,
            0,
            26.9188,
            0,
            135.0218,
            0,
            0,
            0,
            29.37493,
            0,
            16.3335,
            "<D",
            116.5032,
            0,
        ],
        1728079680: [
            0.32672,
            0,
            0.32672,
            0,
            53.4991,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            331.0013,
            0,
            1.13892,
            0,
            26.82322,
            0,
            93.32154,
            0,
            0,
            0,
            29.37493,
            0,
            13.64736,
            "<D",
            116.6136,
            0,
        ],
        1728079740: [
            0.24936,
            0,
            0.24936,
            0,
            53.66925,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            340.8172,
            0,
            1.49911,
            0,
            26.81547,
            0,
            76.17403,
            0,
            0,
            0,
            29.37654,
            0,
            15.29058,
            "<D",
            115.3266,
            0,
        ],
        1728079800: [
            0.37009,
            0,
            0.37009,
            0,
            53.36189,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            339.6928,
            0,
            1.51762,
            0,
            26.80502,
            0,
            60.65036,
            0,
            0,
            0,
            29.37774,
            0,
            17.25341,
            "<D",
            114.986,
            0,
        ],
        1728079860: [
            0.09406,
            0,
            0.09406,
            0,
            54.30476,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            340.1101,
            0,
            1.52479,
            0,
            26.92842,
            0,
            47.93481,
            0,
            0,
            0,
            29.37764,
            0,
            16.64958,
            "<D",
            115.1646,
            0,
        ],
        1728079920: [
            0.19045,
            0,
            0.19045,
            0,
            54.27996,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            327.7183,
            0,
            1.27473,
            0,
            26.95547,
            0,
            45.12839,
            0,
            0,
            0,
            29.37879,
            0,
            16.26588,
            "<D",
            116.1735,
            0,
        ],
        1728079980: [
            0.16194,
            0,
            0.16194,
            0,
            53.52197,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            313.1933,
            0,
            0.86412,
            0,
            26.96332,
            0,
            47.11281,
            0,
            0,
            0,
            29.37876,
            0,
            15.53498,
            "<D",
            116.9467,
            0,
        ],
        1728080040: [
            0.29689,
            0,
            0.29689,
            0,
            54.02346,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            347.8227,
            0,
            1.19916,
            0,
            27.01231,
            0,
            53.5224,
            0,
            0,
            0,
            29.37876,
            0,
            17.24125,
            "<D",
            115.3802,
            0,
        ],
        1728080100: [
            0.20743,
            0,
            0.20743,
            0,
            53.42637,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            336.1236,
            0,
            1.43083,
            0,
            26.92929,
            0,
            50.77745,
            0,
            0,
            0,
            29.37892,
            0,
            15.9584,
            "<D",
            114.2752,
            0,
        ],
        1728080160: [
            0.03761,
            0,
            0.03761,
            0,
            51.95195,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            337.8304,
            0,
            1.29357,
            0,
            26.73237,
            0,
            44.9785,
            0,
            0,
            0,
            29.37877,
            0,
            14.05086,
            "<D",
            113.6279,
            0,
        ],
        1728080220: [
            0.15693,
            0,
            0.15693,
            0,
            51.57616,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            353.1826,
            0,
            1.55367,
            0,
            26.63903,
            0,
            43.20452,
            0,
            0,
            0,
            29.37949,
            0,
            15.61257,
            "<D",
            114.1826,
            0,
        ],
        1728080280: [
            0.17258,
            0,
            0.17258,
            0,
            51.66409,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            354.8474,
            0,
            1.56605,
            0,
            26.71071,
            0,
            43.93905,
            0,
            0,
            0,
            29.37888,
            0,
            16.58851,
            "<D",
            115.1616,
            0,
        ],
        1728080340: [
            0.19326,
            0,
            0.19326,
            0,
            52.12157,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            344.9741,
            0,
            1.30904,
            0,
            26.65882,
            0,
            41.84258,
            0,
            0,
            0,
            29.37963,
            0,
            14.65155,
            "<D",
            114.981,
            0,
        ],
        1728080400: [
            0.28157,
            0,
            0.28157,
            0,
            52.6156,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            346.858,
            0,
            1.08366,
            0,
            26.63901,
            0,
            40.07566,
            0,
            0,
            0,
            29.37875,
            0,
            17.47036,
            "<D",
            114.5264,
            0,
        ],
        1728080460: [
            0.25744,
            0,
            0.25744,
            0,
            51.92905,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            333.1813,
            0,
            1.30124,
            0,
            26.53297,
            0,
            46.8815,
            0,
            0,
            0,
            29.3789,
            0,
            19.27477,
            "<D",
            114.6289,
            0,
        ],
        1728080520: [
            0.3513,
            0,
            0.3513,
            0,
            52.44136,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            335.0041,
            0,
            1.04745,
            0,
            26.63132,
            0,
            58.59887,
            0,
            0,
            0,
            29.3787,
            0,
            17.23286,
            "<D",
            114.853,
            0,
        ],
        1728080580: [
            0.13039,
            0,
            0.13039,
            0,
            52.10306,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            319.6311,
            0,
            1.08029,
            0,
            26.51084,
            0,
            49.0829,
            0,
            0,
            0,
            29.37868,
            0,
            12.81682,
            "<D",
            115.1459,
            0,
        ],
        1728080640: [
            0.18507,
            0,
            0.18507,
            0,
            53.06451,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            327.3853,
            0,
            0.89405,
            0,
            26.68314,
            0,
            41.99847,
            0,
            0,
            0,
            29.37879,
            0,
            15.88132,
            "<D",
            114.5448,
            0,
        ],
        1728080700: [
            0.2049,
            0,
            0.2049,
            0,
            53.16416,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            320.0135,
            0,
            1.28472,
            0,
            26.74375,
            0,
            45.25076,
            0,
            0,
            0,
            29.37874,
            0,
            17.36445,
            "<D",
            113.5452,
            0,
        ],
        1728080760: [
            0.22811,
            0,
            0.22811,
            0,
            53.24415,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            323.6231,
            0,
            0.77315,
            0,
            26.6219,
            0,
            40.84028,
            0,
            0,
            0,
            29.37881,
            0,
            15.78273,
            "<D",
            113.4635,
            0,
        ],
        1728080820: [
            0.21459,
            0,
            0.21459,
            0,
            52.19166,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            318.6993,
            0,
            0.96064,
            0,
            26.48067,
            0,
            39.2711,
            0,
            0,
            0,
            29.37879,
            0,
            15.13598,
            "<D",
            115.6914,
            0,
        ],
        1728080880: [
            0.06967,
            0,
            0.06967,
            0,
            52.08009,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            334.8657,
            0,
            1.1251,
            0,
            26.31179,
            0,
            36.73334,
            0,
            0,
            0,
            29.37943,
            0,
            13.68632,
            "<D",
            116.9557,
            0,
        ],
        1728080940: [
            0.18578,
            0,
            0.18578,
            0,
            52.14927,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            340.3341,
            0,
            0.86469,
            0,
            26.55381,
            0,
            36.15971,
            0,
            0,
            0,
            29.37894,
            0,
            18.64745,
            "<D",
            116.5171,
            0,
        ],
        1728081000: [
            0.19391,
            0,
            0.19391,
            0,
            52.1442,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            352.1792,
            0,
            0.83007,
            0,
            26.66902,
            0,
            37.15689,
            0,
            0,
            0,
            29.37884,
            0,
            14.32967,
            "<D",
            115.0239,
            0,
        ],
        1728081060: [
            0.26697,
            0,
            0.26697,
            0,
            48.00999,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            346.744,
            0,
            0.89827,
            0,
            26.51653,
            0,
            36.69998,
            0,
            0,
            0,
            29.38033,
            0,
            15.26116,
            "<D",
            114.2094,
            0,
        ],
        1728081120: [
            0.21001,
            0,
            0.21001,
            0,
            51.92744,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            349.8705,
            0,
            0.79265,
            0,
            26.30622,
            0,
            34.69936,
            0,
            0,
            0,
            29.37966,
            0,
            16.90392,
            "<D",
            114.2691,
            0,
        ],
        1728081180: [
            0.33637,
            0,
            0.33637,
            0,
            51.43423,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            349.912,
            0,
            1.18475,
            0,
            26.24323,
            0,
            34.08263,
            0,
            0,
            0,
            29.38057,
            0,
            16.55181,
            "<D",
            115.0667,
            0,
        ],
        1728081240: [
            0.135,
            0,
            0.135,
            0,
            51.55883,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            2.30088,
            0,
            1.1484,
            0,
            26.42553,
            0,
            33.88263,
            0,
            0,
            0,
            29.38188,
            0,
            13.8508,
            "<D",
            114.7373,
            0,
        ],
        1728081300: [
            0.37021,
            0,
            0.37021,
            0,
            51.03951,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            347.8396,
            0,
            0.99859,
            0,
            26.28518,
            0,
            33.53599,
            0,
            0,
            0,
            29.38193,
            0,
            16.336,
            "<D",
            115.0396,
            0,
        ],
        1728081360: [
            0.16918,
            0,
            0.16918,
            0,
            50.05847,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            20.87313,
            0,
            0.88291,
            0,
            25.86642,
            0,
            33.2227,
            0,
            0,
            0,
            29.38194,
            0,
            16.6699,
            "<D",
            114.6766,
            0,
        ],
        1728081420: [
            0.37396,
            0,
            0.37396,
            0,
            48.9611,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            19.72986,
            0,
            1.0145,
            0,
            25.76263,
            0,
            33.63599,
            0,
            0,
            0,
            29.38197,
            0,
            12.84997,
            "<D",
            114.777,
            0,
        ],
        1728081480: [
            0.1205,
            0,
            0.1205,
            0,
            49.46217,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            3.16594,
            0,
            0.96112,
            0,
            25.66088,
            0,
            34.13929,
            0,
            0,
            0,
            29.382,
            0,
            16.25925,
            "<D",
            115.6492,
            0,
        ],
        1728081540: [
            -0.02863,
            0,
            -0.02863,
            0,
            48.75159,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            21.94183,
            0,
            0.78026,
            0,
            25.64195,
            0,
            33.8493,
            0,
            0,
            0,
            29.38199,
            0,
            17.08692,
            "<D",
            117.1068,
            0,
        ],
        1728081600: [
            0.24671,
            0,
            0.24671,
            0,
            48.89502,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            15.10636,
            0,
            0.7244,
            0,
            25.62832,
            0,
            32.91607,
            0,
            0,
            0,
            29.38331,
            0,
            16.82321,
            "<D",
            116.4918,
            0,
        ],
        1728081660: [
            0.45672,
            0,
            0.45672,
            0,
            48.8683,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            3.90584,
            0,
            0.71816,
            0,
            25.53275,
            0,
            31.34637,
            0,
            0,
            0,
            29.38364,
            0,
            16.43614,
            "<D",
            116.3044,
            0,
        ],
        1728081720: [
            0.3389,
            0,
            0.3389,
            0,
            48.29441,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            22.37793,
            0,
            0.5883,
            0,
            25.45578,
            0,
            30.54275,
            0,
            0,
            0,
            29.38356,
            0,
            18.97531,
            "<D",
            114.356,
            0,
        ],
        1728081780: [
            0.116,
            0,
            0.116,
            0,
            48.39417,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            17.51171,
            0,
            0.43791,
            0,
            25.35414,
            0,
            30.17921,
            0,
            0,
            0,
            29.3848,
            0,
            14.30448,
            "<D",
            113.7525,
            0,
        ],
        1728081840: [
            0.37698,
            0,
            0.37698,
            0,
            47.05674,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            2.82224,
            0,
            0.58345,
            0,
            25.21819,
            0,
            29.58398,
            0,
            0,
            0,
            29.38532,
            0,
            16.68954,
            "<D",
            114.6342,
            0,
        ],
        1728081900: [
            0.30947,
            0,
            0.30947,
            0,
            47.00597,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            51.63148,
            0,
            0.57072,
            0,
            25.06882,
            0,
            28.74153,
            0,
            0,
            0,
            29.38528,
            0,
            18.60658,
            "<D",
            114.9129,
            0,
        ],
        1728081960: [
            0.29768,
            0,
            0.29768,
            0,
            45.72641,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            81.05859,
            0,
            0.55897,
            0,
            25.10013,
            0,
            28.10545,
            0,
            0,
            0,
            29.38529,
            0,
            16.0079,
            "<D",
            114.6158,
            0,
        ],
        1728082020: [
            0.29375,
            0,
            0.29375,
            0,
            45.59171,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            89.79713,
            0,
            0.46726,
            0,
            25.15605,
            0,
            27.58563,
            0,
            0,
            0,
            29.38537,
            0,
            15.98987,
            "<D",
            114.0138,
            0,
        ],
        1728082080: [
            0.18012,
            0,
            0.18012,
            0,
            46.07512,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            103.9026,
            0,
            0.5484,
            0,
            25.10102,
            0,
            27.11245,
            0,
            0,
            0,
            29.38436,
            0,
            14.53573,
            "<D",
            114.8574,
            0,
        ],
        1728082140: [
            0.19601,
            0,
            0.19601,
            0,
            45.9278,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            82.36755,
            0,
            0.18039,
            0,
            25.05757,
            0,
            28.09492,
            0,
            0,
            0,
            29.38449,
            0,
            17.17805,
            "<D",
            114.7564,
            0,
        ],
        1728082200: [
            0.14518,
            0,
            0.14518,
            0,
            47.08412,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            114.1691,
            0,
            0.22008,
            0,
            24.98022,
            0,
            28.95184,
            0,
            0,
            0,
            29.38451,
            0,
            15.16196,
            "<D",
            115.2474,
            0,
        ],
        1728082260: [
            0.28192,
            0,
            0.28192,
            0,
            47.49733,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            72.51444,
            0,
            0.34952,
            0,
            24.9511,
            0,
            29.21069,
            0,
            0,
            0,
            29.3838,
            0,
            16.69964,
            "<D",
            115.6901,
            0,
        ],
        1728082320: [
            0.06726,
            0,
            0.06726,
            0,
            47.18236,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            51.73034,
            0,
            0.35117,
            0,
            25.01974,
            0,
            34.31453,
            0,
            0,
            0,
            29.38456,
            0,
            16.52628,
            "<D",
            117.0897,
            0,
        ],
        1728082380: [
            0.13513,
            0,
            0.13513,
            0,
            47.02217,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            55.47409,
            0,
            0.54075,
            0,
            24.93396,
            0,
            38.44918,
            0,
            0,
            0,
            29.3856,
            0,
            16.23528,
            "<D",
            114.9514,
            0,
        ],
        1728082440: [
            0.3138,
            0,
            0.3138,
            0,
            46.44654,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            31.14454,
            0,
            0.42885,
            0,
            24.83636,
            0,
            40.58533,
            0,
            0,
            0,
            29.38544,
            0,
            16.71836,
            "<D",
            114.0652,
            0,
        ],
        1728082500: [
            0.35306,
            0,
            0.35306,
            0,
            46.36052,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            32.03578,
            0,
            0.47129,
            0,
            24.77114,
            0,
            44.60078,
            0,
            0,
            0,
            29.38596,
            0,
            14.06044,
            "<D",
            114.027,
            0,
        ],
        1728082560: [
            0.4248,
            0,
            0.4248,
            0,
            46.16571,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            350.9306,
            0,
            0.44217,
            0,
            24.64756,
            0,
            44.3208,
            0,
            0,
            0,
            29.38612,
            0,
            17.65921,
            "<D",
            114.4812,
            0,
        ],
        1728082620: [
            0.2425,
            0,
            0.2425,
            0,
            47.04867,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            15.71446,
            0,
            0.36954,
            0,
            24.54474,
            0,
            42.38018,
            0,
            0,
            0,
            29.3864,
            0,
            14.72062,
            "<D",
            114.2803,
            0,
        ],
        1728082680: [
            0.29582,
            0,
            0.29582,
            0,
            46.80304,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            19.39791,
            0,
            0.36963,
            0,
            24.60719,
            0,
            40.31723,
            0,
            0,
            0,
            29.38564,
            0,
            16.98949,
            "<D",
            114.2127,
            0,
        ],
        1728082740: [
            0.24343,
            0,
            0.24343,
            0,
            47.23788,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            4.17566,
            0,
            0.41204,
            0,
            24.52784,
            0,
            38.10231,
            0,
            0,
            0,
            29.38536,
            0,
            18.39022,
            "<D",
            114.3211,
            0,
        ],
        1728082800: [
            0.1601,
            0,
            0.1601,
            0,
            48.3005,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            71.35294,
            0,
            0.52703,
            0,
            24.48917,
            0,
            35.75791,
            0,
            0,
            0,
            29.38558,
            0,
            18.01107,
            "<D",
            115.132,
            0,
        ],
        1728082860: [
            0.18246,
            0,
            0.18246,
            0,
            48.19665,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            45.71502,
            0,
            0.45126,
            0,
            24.50757,
            0,
            33.50051,
            0,
            0,
            0,
            29.38595,
            0,
            16.10181,
            "<D",
            114.6479,
            0,
        ],
        1728082920: [
            0.19594,
            0,
            0.19594,
            0,
            48.50741,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            88.95794,
            0,
            0.26284,
            0,
            24.53209,
            0,
            31.33779,
            0,
            0,
            0,
            29.38578,
            0,
            13.74592,
            "<D",
            115.5061,
            0,
        ],
        1728082980: [
            0.18751,
            0,
            0.18751,
            0,
            48.19252,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            37.36076,
            0,
            0.51398,
            0,
            24.46503,
            0,
            29.03719,
            0,
            0,
            0,
            29.38843,
            0,
            16.6495,
            "<D",
            115.6499,
            0,
        ],
        1728083040: [
            0.21643,
            0,
            0.21643,
            0,
            47.95708,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            31.11153,
            0,
            0.65677,
            0,
            24.44007,
            0,
            26.69675,
            0,
            0,
            0,
            29.38921,
            0,
            15.75046,
            "<D",
            116.2582,
            0,
        ],
        1728083100: [
            0.35783,
            0,
            0.35783,
            0,
            47.49773,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            23.32572,
            0,
            0.7331,
            0,
            24.43073,
            0,
            24.58727,
            0,
            0,
            0,
            29.38925,
            0,
            17.88092,
            "<D",
            115.239,
            0,
        ],
        1728083160: [
            0.1902,
            0,
            0.1902,
            0,
            47.43773,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            29.66474,
            0,
            0.74456,
            0,
            24.4155,
            0,
            22.4854,
            0,
            0,
            0,
            29.38925,
            0,
            14.17292,
            "<D",
            114.3132,
            0,
        ],
        1728083220: [
            0.17795,
            0,
            0.17795,
            0,
            47.54823,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            24.8833,
            0,
            0.41335,
            0,
            24.35803,
            0,
            19.46991,
            0,
            0,
            0,
            29.38925,
            0,
            13.52337,
            "<D",
            115.169,
            0,
        ],
        1728083280: [
            0.08888,
            0,
            0.08888,
            0,
            47.7745,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            57.65277,
            0,
            0.31299,
            0,
            24.29042,
            0,
            15.75179,
            0,
            0,
            0,
            29.38921,
            0,
            16.30835,
            "<D",
            115.4316,
            0,
        ],
        1728083340: [
            0.01316,
            0,
            0.01316,
            0,
            47.77535,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            58.16953,
            0,
            0.224,
            0,
            24.30217,
            0,
            13.56031,
            0,
            0,
            0,
            29.38925,
            0,
            14.43566,
            "<D",
            114.9619,
            0,
        ],
        1728083400: [
            -0.11629,
            0,
            -0.11629,
            0,
            48.13102,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            88.20866,
            0,
            0.36511,
            0,
            24.25234,
            0,
            12.48146,
            0,
            0,
            0,
            29.3893,
            0,
            16.54112,
            "<D",
            114.3264,
            0,
        ],
        1728083460: [
            0.19696,
            0,
            0.19696,
            0,
            48.07341,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            40.47393,
            0,
            0.63806,
            0,
            24.22684,
            0,
            11.61846,
            0,
            0,
            0,
            29.38938,
            0,
            15.61112,
            "<D",
            114.2441,
            0,
        ],
        1728083520: [
            0.22343,
            0,
            0.22343,
            0,
            47.84573,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            32.74209,
            0,
            1.13668,
            0,
            24.06466,
            0,
            10.87164,
            0,
            0,
            0,
            29.38983,
            0,
            14.96324,
            "<D",
            114.8591,
            0,
        ],
        1728083580: [
            0.14386,
            0,
            0.14386,
            0,
            48.08965,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            32.58403,
            0,
            1.12012,
            0,
            24.04954,
            0,
            9.74036,
            0,
            0,
            0,
            29.3908,
            0,
            12.82083,
            "<D",
            115.1302,
            0,
        ],
        1728083640: [
            0.22491,
            0,
            0.22491,
            0,
            47.92719,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            49.19756,
            0,
            0.85617,
            0,
            24.06741,
            0,
            8.68558,
            0,
            0,
            0,
            29.39051,
            0,
            18.63765,
            "<D",
            115.6351,
            0,
        ],
        1728083700: [
            0.17386,
            0,
            0.17386,
            0,
            47.99815,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            32.79753,
            0,
            0.66162,
            0,
            23.90249,
            0,
            7.84981,
            0,
            0,
            0,
            29.38981,
            0,
            13.85682,
            "<D",
            116.6105,
            0,
        ],
        1728083760: [
            0.15491,
            0,
            0.15491,
            0,
            47.81048,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            111.6251,
            0,
            0.57081,
            0,
            23.83439,
            0,
            7.18993,
            0,
            0,
            0,
            29.38935,
            0,
            15.56187,
            "<D",
            115.1278,
            0,
        ],
        1728083820: [
            0.12478,
            0,
            0.12478,
            0,
            47.71942,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            117.3088,
            0,
            0.61493,
            0,
            23.77185,
            0,
            6.55344,
            0,
            0,
            0,
            29.38925,
            0,
            12.45619,
            "<D",
            114.515,
            0,
        ],
        1728083880: [
            0.17147,
            0,
            0.17147,
            0,
            47.12074,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            104.3718,
            0,
            0.67321,
            0,
            23.74424,
            0,
            5.95013,
            0,
            0,
            0,
            29.38993,
            0,
            14.65301,
            "<D",
            114.4026,
            0,
        ],
        1728083940: [
            0.24089,
            0,
            0.24089,
            0,
            46.981,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            72.98197,
            0,
            0.64989,
            0,
            23.7268,
            0,
            5.38003,
            0,
            0,
            0,
            29.39148,
            0,
            15.19612,
            "<D",
            114.5316,
            0,
        ],
        1728084000: [
            0.32692,
            0,
            0.32692,
            0,
            47.242,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            50.22744,
            0,
            0.81255,
            0,
            23.72533,
            0,
            4.84973,
            0,
            0,
            0,
            29.39231,
            0,
            13.5602,
            "<D",
            114.2075,
            0,
        ],
        1728084060: [
            0.14407,
            0,
            0.14407,
            0,
            46.99089,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            59.95922,
            0,
            0.73342,
            0,
            23.66678,
            0,
            4.35924,
            0,
            0,
            0,
            29.39297,
            0,
            15.15985,
            "<D",
            113.5417,
            0,
        ],
        1728084120: [
            0.22873,
            0,
            0.22873,
            0,
            47.25944,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            66.30048,
            0,
            0.6722,
            0,
            23.58142,
            0,
            3.88537,
            0,
            0,
            0,
            29.3927,
            0,
            14.55212,
            "<D",
            114.462,
            0,
        ],
        1728084180: [
            0.12037,
            0,
            0.12037,
            0,
            47.2939,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            61.14241,
            0,
            0.62848,
            0,
            23.57607,
            0,
            3.46788,
            0,
            0,
            0,
            29.39279,
            0,
            14.21608,
            "<D",
            114.6698,
            0,
        ],
        1728084240: [
            -0.04451,
            0,
            -0.04451,
            0,
            47.06842,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            62.93217,
            0,
            0.53943,
            0,
            23.59527,
            0,
            3.09679,
            0,
            0,
            0,
            29.39276,
            0,
            14.39077,
            "<D",
            114.2876,
            0,
        ],
        1728084300: [
            0.06002,
            0,
            0.06002,
            0,
            46.84368,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            65.19414,
            0,
            0.53912,
            0,
            23.56325,
            0,
            2.78538,
            0,
            0,
            0,
            29.39288,
            0,
            13.83604,
            "<D",
            114.4096,
            0,
        ],
        1728084360: [
            0.12328,
            0,
            0.12328,
            0,
            46.97996,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            45.10907,
            0,
            0.59395,
            0,
            23.44764,
            0,
            2.45079,
            0,
            0,
            0,
            29.39239,
            0,
            16.92503,
            "<D",
            114.4215,
            0,
        ],
        1728084420: [
            0.18748,
            0,
            0.18748,
            0,
            46.76004,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            47.44156,
            0,
            0.49164,
            0,
            23.40064,
            0,
            2.16259,
            0,
            0,
            0,
            29.39257,
            0,
            13.83718,
            "<D",
            115.2363,
            0,
        ],
        1728084480: [
            0.31379,
            0,
            0.31379,
            0,
            46.42896,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            48.58905,
            0,
            0.44979,
            0,
            23.30154,
            0,
            1.86778,
            0,
            0,
            0,
            29.3926,
            0,
            15.68184,
            "<D",
            114.4112,
            0,
        ],
        1728084540: [
            0.2456,
            0,
            0.2456,
            0,
            46.53725,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            54.21166,
            0,
            0.43438,
            0,
            23.14462,
            0,
            1.63592,
            0,
            0,
            0,
            29.3926,
            0,
            14.1924,
            "<D",
            114.8197,
            0,
        ],
        1728084600: [
            0.11016,
            0,
            0.11016,
            0,
            46.50883,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            49.75337,
            0,
            0.60607,
            0,
            22.98957,
            0,
            1.45705,
            0,
            0,
            0,
            29.3926,
            0,
            14.19895,
            "<D",
            114.6684,
            0,
        ],
        1728084660: [
            0.23565,
            0,
            0.23565,
            0,
            46.72255,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            50.43931,
            0,
            0.49505,
            0,
            22.92156,
            0,
            1.27156,
            0,
            0,
            0,
            29.3926,
            0,
            14.26023,
            "<D",
            114.548,
            0,
        ],
        1728084720: [
            0.15656,
            0,
            0.15656,
            0,
            46.63977,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            44.56415,
            0,
            0.37568,
            0,
            22.87376,
            0,
            1.05962,
            0,
            0,
            0,
            29.39266,
            0,
            13.59711,
            "<D",
            113.9737,
            0,
        ],
        1728084780: [
            0.27775,
            0,
            0.27775,
            0,
            47.01804,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            57.39569,
            0,
            0.35976,
            0,
            22.79212,
            0,
            0.90728,
            0,
            0,
            0,
            29.39271,
            0,
            11.90839,
            "<D",
            113.725,
            0,
        ],
        1728084840: [
            0.15299,
            0,
            0.15299,
            0,
            46.72989,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            59.3604,
            0,
            0.25687,
            0,
            22.70255,
            0,
            0.68544,
            0,
            0,
            0,
            29.39376,
            0,
            13.34953,
            "<D",
            113.4708,
            0,
        ],
        1728084900: [
            0.21823,
            0,
            0.21823,
            0,
            46.48125,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            51.82916,
            0,
            0.22373,
            0,
            22.44233,
            0,
            0.60926,
            0,
            0,
            0,
            29.39436,
            0,
            14.9852,
            "<D",
            113.7668,
            0,
        ],
        1728084960: [
            0.19683,
            0,
            0.19683,
            0,
            45.82301,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            48.69738,
            0,
            0.22351,
            0,
            22.30841,
            0,
            0.44701,
            0,
            0,
            0,
            29.39464,
            0,
            14.45875,
            "<D",
            114.4345,
            0,
        ],
        1728085020: [
            0.19741,
            0,
            0.19741,
            0,
            45.99137,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            48.49577,
            0,
            0.22371,
            0,
            22.48847,
            0,
            0.37085,
            0,
            0,
            0,
            29.39304,
            0,
            10.74026,
            "<D",
            114.9441,
            0,
        ],
        1728085080: [
            0.11203,
            0,
            0.11203,
            0,
            46.13948,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            98.53595,
            0,
            0.17397,
            0,
            22.59719,
            0,
            0.25826,
            0,
            0,
            0,
            29.3926,
            0,
            14.68402,
            "<D",
            114.974,
            0,
        ],
        1728085140: [
            0.328,
            0,
            0.328,
            0,
            45.91175,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6732,
            0,
            35.93471,
            0,
            0.22954,
            0,
            22.74815,
            0,
            0.27812,
            0,
            0,
            0,
            29.3926,
            0,
            13.92639,
            "<D",
            115.4558,
            0,
        ],
        1728085200: [
            0.09989,
            0,
            0.09989,
            0,
            46.81647,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            130.3829,
            0,
            0.327,
            0,
            22.77187,
            0,
            0.17548,
            0,
            0,
            0,
            29.3926,
            0,
            12.9749,
            "<D",
            115.9556,
            0,
        ],
        1728085260: [
            0.16054,
            0,
            0.16054,
            0,
            47.03382,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            86.34012,
            0,
            0.40013,
            0,
            22.74664,
            0,
            0.09601,
            0,
            0,
            0,
            29.39263,
            0,
            14.61256,
            "<D",
            115.7338,
            0,
        ],
        1728085320: [
            0.22061,
            0,
            0.22061,
            0,
            47.19658,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            67.56758,
            0,
            0.52946,
            0,
            22.72303,
            0,
            0.07615,
            0,
            0,
            0,
            29.39287,
            0,
            14.93093,
            "<D",
            115.2677,
            0,
        ],
        1728085380: [
            0.34332,
            0,
            0.34332,
            0,
            47.43535,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            58.35435,
            0,
            0.5278,
            0,
            22.67299,
            0,
            0.02649,
            0,
            0,
            0,
            29.39284,
            0,
            12.4813,
            "<D",
            114.818,
            0,
        ],
        1728085440: [
            0.22085,
            0,
            0.22085,
            0,
            47.31273,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            60.84269,
            0,
            0.44073,
            0,
            22.68435,
            0,
            -0.03642,
            0,
            0,
            0,
            29.39296,
            0,
            14.53718,
            "<D",
            114.1603,
            0,
        ],
        1728085500: [
            0.1788,
            0,
            0.1788,
            0,
            47.11028,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            69.94087,
            0,
            0.50843,
            0,
            22.72351,
            0,
            -0.06953,
            0,
            0,
            0,
            29.39282,
            0,
            14.25105,
            "<D",
            113.9842,
            0,
        ],
        1728085560: [
            0.17954,
            0,
            0.17954,
            0,
            47.76199,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            68.7165,
            0,
            0.50944,
            0,
            22.82262,
            0,
            -0.04635,
            0,
            0,
            0,
            29.39329,
            0,
            14.8639,
            "<D",
            112.7846,
            0,
        ],
        1728085620: [
            0.2913,
            0,
            0.2913,
            0,
            47.55489,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            65.8217,
            0,
            0.37034,
            0,
            22.85217,
            0,
            -0.03973,
            0,
            0,
            0,
            29.39391,
            0,
            13.07137,
            "<D",
            112.6552,
            0,
        ],
        1728085680: [
            0.13031,
            0,
            0.13031,
            0,
            47.14958,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            64.51393,
            0,
            0.4611,
            0,
            22.8142,
            0,
            -0.11919,
            0,
            0,
            0,
            29.39567,
            0,
            13.09679,
            "<D",
            112.907,
            0,
        ],
        1728085740: [
            0.15615,
            0,
            0.15615,
            0,
            48.74232,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            48.85259,
            0,
            0.54281,
            0,
            22.86751,
            0,
            -0.11257,
            0,
            0,
            0,
            29.39589,
            0,
            13.62266,
            "<D",
            114.2999,
            0,
        ],
        1728085800: [
            0.34339,
            0,
            0.34339,
            0,
            48.41262,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            43.00364,
            0,
            0.50967,
            0,
            22.79587,
            0,
            -0.11919,
            0,
            0,
            0,
            29.39629,
            0,
            15.90526,
            "<D",
            115.6276,
            0,
        ],
        1728085860: [
            0.16116,
            0,
            0.16116,
            0,
            48.12999,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            42.76797,
            0,
            0.53972,
            0,
            22.27297,
            0,
            -0.24832,
            0,
            0,
            0,
            29.39732,
            0,
            15.308,
            "<D",
            116.3711,
            0,
        ],
        1728085920: [
            0.2534,
            0,
            0.2534,
            0,
            47.94034,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            53.4999,
            0,
            0.50582,
            0,
            22.11111,
            0,
            -0.25162,
            0,
            0,
            0,
            29.39834,
            0,
            11.73869,
            "<D",
            116.365,
            0,
        ],
        1728085980: [
            0.04428,
            0,
            0.04428,
            0,
            48.152,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            62.89235,
            0,
            0.47711,
            0,
            22.09041,
            0,
            -0.21851,
            0,
            0,
            0,
            29.39832,
            0,
            14.7246,
            "<D",
            116.5864,
            0,
        ],
        1728086040: [
            0.14213,
            0,
            0.14213,
            0,
            47.7021,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            82.78592,
            0,
            0.50652,
            0,
            22.17026,
            0,
            -0.22513,
            0,
            0,
            0,
            29.39854,
            0,
            13.42505,
            "<D",
            115.6717,
            0,
        ],
        1728086100: [
            0.35994,
            0,
            0.35994,
            0,
            47.90028,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            80.28001,
            0,
            0.4757,
            0,
            22.21123,
            0,
            -0.22513,
            0,
            0,
            0,
            29.39875,
            0,
            14.53793,
            "<D",
            115.1975,
            0,
        ],
        1728086160: [
            0.21136,
            0,
            0.21136,
            0,
            47.74063,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            69.59941,
            0,
            0.58245,
            0,
            22.24195,
            0,
            -0.23838,
            0,
            0,
            0,
            29.39893,
            0,
            13.83622,
            "<D",
            115.0368,
            0,
        ],
        1728086220: [
            -0.00808,
            0,
            -0.00808,
            0,
            47.38742,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            63.45624,
            0,
            0.51561,
            0,
            22.26033,
            0,
            -0.24169,
            0,
            0,
            0,
            29.39889,
            0,
            13.20046,
            "<D",
            114.24,
            0,
        ],
        1728086280: [
            -0.0325,
            0,
            -0.0325,
            0,
            46.94408,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            60.25201,
            0,
            0.4351,
            0,
            22.21234,
            0,
            -0.27149,
            0,
            0,
            0,
            29.39904,
            0,
            10.55474,
            "<D",
            113.2039,
            0,
        ],
        1728086340: [
            0.00248,
            0,
            0.00248,
            0,
            46.69008,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            76.2468,
            0,
            0.34283,
            0,
            22.20726,
            0,
            -0.23838,
            0,
            0,
            0,
            29.39849,
            0,
            11.87984,
            "<D",
            113.0613,
            0,
        ],
        1728086400: [
            0.12681,
            0,
            0.12681,
            0,
            47.27517,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            92.50983,
            0,
            0.2601,
            0,
            22.25141,
            0,
            -0.09602,
            0,
            0,
            0,
            29.39811,
            0,
            15.46188,
            "<D",
            113.0314,
            0,
        ],
        1728086460: [
            0.07491,
            0,
            0.07491,
            0,
            47.15576,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            87.36117,
            0,
            0.56565,
            0,
            22.28643,
            0,
            -0.08608,
            0,
            0,
            0,
            29.39844,
            0,
            12.95725,
            "<D",
            114.4085,
            0,
        ],
        1728086520: [
            0.26395,
            0,
            0.26395,
            0,
            47.9215,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            93.00157,
            0,
            0.67802,
            0,
            22.23726,
            0,
            -0.06622,
            0,
            0,
            0,
            29.39861,
            0,
            17.38018,
            "<D",
            114.5363,
            0,
        ],
        1728086580: [
            0.32036,
            0,
            0.32036,
            0,
            48.25377,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            61.90812,
            0,
            0.61565,
            0,
            22.13987,
            0,
            -0.04635,
            0,
            0,
            0,
            29.39884,
            0,
            13.39547,
            "<D",
            114.674,
            0,
        ],
        1728086640: [
            0.22614,
            0,
            0.22614,
            0,
            48.21129,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            50.36424,
            0,
            0.76014,
            0,
            22.09169,
            0,
            -0.05297,
            0,
            0,
            0,
            29.39895,
            0,
            15.7467,
            "<D",
            114.5152,
            0,
        ],
        1728086700: [
            0.23553,
            0,
            0.23553,
            0,
            48.47492,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            76.82957,
            0,
            0.60278,
            0,
            22.09881,
            0,
            -0.04966,
            0,
            0,
            0,
            29.39895,
            0,
            12.35214,
            "<D",
            115.5925,
            0,
        ],
        1728086760: [
            0.29316,
            0,
            0.29316,
            0,
            47.81973,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            83.37977,
            0,
            0.66636,
            0,
            22.04136,
            0,
            -0.07946,
            0,
            0,
            0,
            29.39902,
            0,
            15.55478,
            "<D",
            115.2243,
            0,
        ],
        1728086820: [
            0.11865,
            0,
            0.11865,
            0,
            47.97767,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.59581,
            0,
            79.37074,
            0,
            0.74151,
            0,
            22.0568,
            0,
            -0.12913,
            0,
            0,
            0,
            29.3991,
            0,
            13.39295,
            "<D",
            115.0842,
            0,
        ],
        1728086880: [
            0.20673,
            0,
            0.20673,
            0,
            48.55497,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.59581,
            0,
            66.85784,
            0,
            0.84569,
            0,
            21.88966,
            0,
            -0.16223,
            0,
            0,
            0,
            29.39954,
            0,
            11.37091,
            "<D",
            114.4123,
            0,
        ],
        1728086940: [
            0.25876,
            0,
            0.25876,
            0,
            48.4908,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.59581,
            0,
            45.47495,
            0,
            0.72643,
            0,
            21.80916,
            0,
            -0.17879,
            0,
            0,
            0,
            29.39928,
            0,
            13.96743,
            "<D",
            113.7461,
            0,
        ],
        1728087000: [
            0.08476,
            0,
            0.08476,
            0,
            48.8375,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            65.12318,
            0,
            0.57605,
            0,
            21.75772,
            0,
            -0.22845,
            0,
            0,
            0,
            29.40136,
            0,
            14.31167,
            "<D",
            113.679,
            0,
        ],
        1728087060: [
            0.00082,
            0,
            0.00082,
            0,
            48.13235,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            45.40111,
            0,
            0.63167,
            0,
            21.68493,
            0,
            -0.23839,
            0,
            0,
            0,
            29.40279,
            0,
            13.62206,
            "<D",
            113.489,
            0,
        ],
        1728087120: [
            0.11492,
            0,
            0.11492,
            0,
            47.41661,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            55.53758,
            0,
            0.40996,
            0,
            21.66833,
            0,
            -0.22845,
            0,
            0,
            0,
            29.4032,
            0,
            13.84523,
            "<D",
            112.584,
            0,
        ],
        1728087180: [
            0.08368,
            0,
            0.08368,
            0,
            47.02756,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            83.27953,
            0,
            0.38813,
            0,
            21.58747,
            0,
            -0.2119,
            0,
            0,
            0,
            29.40318,
            0,
            15.35349,
            "<D",
            112.2794,
            0,
        ],
        1728087240: [
            0.29488,
            0,
            0.29488,
            0,
            47.65009,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            69.23132,
            0,
            0.33174,
            0,
            21.53442,
            0,
            -0.2119,
            0,
            0,
            0,
            29.40316,
            0,
            11.2239,
            "<D",
            113.3263,
            0,
        ],
        1728087300: [
            0.28321,
            0,
            0.28321,
            0,
            47.65558,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            83.40087,
            0,
            0.40868,
            0,
            21.56406,
            0,
            -0.22183,
            0,
            0,
            0,
            29.40328,
            0,
            14.53643,
            "<D",
            114.2309,
            0,
        ],
        1728087360: [
            0.21271,
            0,
            0.21271,
            0,
            48.06504,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            94.70749,
            0,
            0.27904,
            0,
            21.54259,
            0,
            -0.20528,
            0,
            0,
            0,
            29.40315,
            0,
            15.45185,
            "<D",
            114.0469,
            0,
        ],
        1728087420: [
            0.26387,
            0,
            0.26387,
            0,
            47.66767,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            115.5647,
            0,
            0.29995,
            0,
            21.45022,
            0,
            -0.23176,
            0,
            0,
            0,
            29.40319,
            0,
            11.41833,
            "<D",
            115.1386,
            0,
        ],
        1728087480: [
            0.25778,
            0,
            0.25778,
            0,
            47.85994,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5872,
            0,
            107.8841,
            0,
            0.43939,
            0,
            21.3366,
            0,
            -0.20859,
            0,
            0,
            0,
            29.4032,
            0,
            15.237,
            "<D",
            116.0471,
            0,
        ],
        1728087540: [
            0.22098,
            0,
            0.22098,
            0,
            47.84964,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            98.50742,
            0,
            0.45229,
            0,
            21.23815,
            0,
            -0.21521,
            0,
            0,
            0,
            29.4032,
            0,
            16.00633,
            "<D",
            115.6184,
            0,
        ],
        1728087600: [
            0.19561,
            0,
            0.19561,
            0,
            47.5894,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.57,
            0,
            85.06831,
            0,
            0.57993,
            0,
            21.21946,
            0,
            -0.22845,
            0,
            0,
            0,
            29.4032,
            0,
            14.49597,
            "<D",
            114.9458,
            0,
        ],
        1728087660: [
            0.00103,
            0,
            0.00103,
            0,
            47.27623,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5614,
            0,
            76.64759,
            0,
            0.49349,
            0,
            21.23207,
            0,
            -0.2119,
            0,
            0,
            0,
            29.40352,
            0,
            16.68779,
            "<D",
            115.1726,
            0,
        ],
        1728087720: [
            0.11809,
            0,
            0.11809,
            0,
            46.8216,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5657,
            0,
            61.38773,
            0,
            0.48111,
            0,
            21.22638,
            0,
            -0.10264,
            0,
            0,
            0,
            29.40401,
            0,
            11.4948,
            "<D",
            114.7799,
            0,
        ],
        1728087780: [
            0.48309,
            0,
            0.48309,
            0,
            46.09235,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5571,
            0,
            75.93359,
            0,
            0.49417,
            0,
            21.21207,
            0,
            -0.0629,
            0,
            0,
            0,
            29.40434,
            0,
            13.37633,
            "<D",
            114.2663,
            0,
        ],
        1728087840: [
            0.34631,
            0,
            0.34631,
            0,
            45.32109,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5614,
            0,
            77.67495,
            0,
            0.52123,
            0,
            21.17667,
            0,
            -0.04304,
            0,
            0,
            0,
            29.4055,
            0,
            14.86726,
            "<D",
            113.8541,
            0,
        ],
        1728087900: [
            0.1879,
            0,
            0.1879,
            0,
            44.4133,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            87.87173,
            0,
            0.44267,
            0,
            21.1328,
            0,
            -0.10264,
            0,
            0,
            0,
            29.40601,
            0,
            14.26057,
            "<D",
            111.8207,
            0,
        ],
        1728087960: [
            0.00508,
            0,
            0.00508,
            0,
            44.82755,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5872,
            0,
            91.85319,
            0,
            0.46115,
            0,
            21.06554,
            0,
            -0.05959,
            0,
            0,
            0,
            29.40637,
            0,
            14.20101,
            "<D",
            112.6288,
            0,
        ],
        1728088020: [
            0.20118,
            0,
            0.20118,
            0,
            45.3208,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            92.32123,
            0,
            0.46087,
            0,
            20.99787,
            0,
            -0.13244,
            0,
            0,
            0,
            29.40558,
            0,
            16.90388,
            "<D",
            114.2208,
            0,
        ],
        1728088080: [
            0.25308,
            0,
            0.25308,
            0,
            45.73387,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            124.3443,
            0,
            0.42109,
            0,
            20.94319,
            0,
            -0.16886,
            0,
            0,
            0,
            29.40645,
            0,
            13.82997,
            "<D",
            113.8657,
            0,
        ],
        1728088140: [
            0.25627,
            0,
            0.25627,
            0,
            45.95923,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            94.77607,
            0,
            0.36473,
            0,
            20.84571,
            0,
            -0.18872,
            0,
            0,
            0,
            29.40645,
            0,
            13.45216,
            "<D",
            114.7372,
            0,
        ],
        1728088200: [
            0.30911,
            0,
            0.30911,
            0,
            45.36438,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5786,
            0,
            100.4737,
            0,
            0.36764,
            0,
            20.84089,
            0,
            -0.17548,
            0,
            0,
            0,
            29.40645,
            0,
            14.91,
            "<D",
            114.2326,
            0,
        ],
        1728088260: [
            0.07302,
            0,
            0.07302,
            0,
            46.46032,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            81.85625,
            0,
            0.51376,
            0,
            20.81243,
            0,
            -0.21852,
            0,
            0,
            0,
            29.40634,
            0,
            16.1339,
            "<D",
            115.3084,
            0,
        ],
        1728088320: [
            0.10967,
            0,
            0.10967,
            0,
            46.15508,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.57,
            0,
            75.54105,
            0,
            0.42259,
            0,
            20.89078,
            0,
            -0.19534,
            0,
            0,
            0,
            29.40645,
            0,
            15.75368,
            "<D",
            115.6678,
            0,
        ],
        1728088380: [
            0.35088,
            0,
            0.35088,
            0,
            45.54053,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5528,
            0,
            88.61409,
            0,
            0.71004,
            0,
            20.85424,
            0,
            -0.18541,
            0,
            0,
            0,
            29.40645,
            0,
            13.46103,
            "<D",
            115.5432,
            0,
        ],
        1728088440: [
            0.27913,
            0,
            0.27913,
            0,
            46.07012,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            115.8677,
            0,
            0.63347,
            0,
            20.80089,
            0,
            -0.13575,
            0,
            0,
            0,
            29.40646,
            0,
            12.96435,
            "<D",
            115.7976,
            0,
        ],
        1728088500: [
            0.16418,
            0,
            0.16418,
            0,
            45.70107,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            113.0871,
            0,
            0.5322,
            0,
            20.75549,
            0,
            -0.1225,
            0,
            0,
            0,
            29.40645,
            0,
            14.19772,
            "<D",
            115.5518,
            0,
        ],
        1728088560: [
            0.22796,
            0,
            0.22796,
            0,
            46.10564,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            103.9621,
            0,
            0.52298,
            0,
            20.67923,
            0,
            -0.07946,
            0,
            0,
            0,
            29.40645,
            0,
            14.53818,
            "<D",
            115.7121,
            0,
        ],
        1728088620: [
            0.19525,
            0,
            0.19525,
            0,
            46.04496,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5571,
            0,
            111.618,
            0,
            0.5225,
            0,
            20.56882,
            0,
            -0.04304,
            0,
            0,
            0,
            29.40654,
            0,
            13.45495,
            "<D",
            115.2368,
            0,
        ],
        1728088680: [
            0.3024,
            0,
            0.3024,
            0,
            46.35012,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5614,
            0,
            109.8113,
            0,
            0.57651,
            0,
            20.46588,
            0,
            -0.05297,
            0,
            0,
            0,
            29.40672,
            0,
            14.1422,
            "<D",
            113.3781,
            0,
        ],
        1728088740: [
            0.22142,
            0,
            0.22142,
            0,
            45.54659,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            107.808,
            0,
            0.6165,
            0,
            20.33423,
            0,
            -0.07284,
            0,
            0,
            0,
            29.4084,
            0,
            14.97887,
            "<D",
            113.247,
            0,
        ],
        1728088800: [
            0.27881,
            0,
            0.27881,
            0,
            45.84254,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            115.7788,
            0,
            0.61005,
            0,
            20.26212,
            0,
            -0.05297,
            0,
            0,
            0,
            29.40734,
            0,
            16.54409,
            "<D",
            113.4855,
            0,
        ],
        1728088860: [
            0.10222,
            0,
            0.10222,
            0,
            44.50396,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            102.1836,
            0,
            0.65931,
            0,
            20.27598,
            0,
            -0.04304,
            0,
            0,
            0,
            29.4082,
            0,
            11.46317,
            "<D",
            112.5323,
            0,
        ],
        1728088920: [
            0.26319,
            0,
            0.26319,
            0,
            44.60536,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            105.3842,
            0,
            0.61835,
            0,
            20.24573,
            0,
            -0.05628,
            0,
            0,
            0,
            29.40799,
            0,
            13.85935,
            "<D",
            113.7251,
            0,
        ],
        1728088980: [
            0.29446,
            0,
            0.29446,
            0,
            45.22388,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5786,
            0,
            114.9031,
            0,
            0.58094,
            0,
            20.20828,
            0,
            -0.05959,
            0,
            0,
            0,
            29.40803,
            0,
            14.68669,
            "<D",
            114.6266,
            0,
        ],
        1728089040: [
            0.09455,
            0,
            0.09455,
            0,
            45.44935,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5829,
            0,
            114.311,
            0,
            0.58643,
            0,
            20.18282,
            0,
            -0.05628,
            0,
            0,
            0,
            29.40956,
            0,
            14.64182,
            "<D",
            114.7396,
            0,
        ],
        1728089100: [
            0.19239,
            0,
            0.19239,
            0,
            45.52179,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            119.5324,
            0,
            0.55676,
            0,
            20.1738,
            0,
            -0.04966,
            0,
            0,
            0,
            29.40932,
            0,
            13.11356,
            "<D",
            115.4913,
            0,
        ],
        1728089160: [
            0.05126,
            0,
            0.05126,
            0,
            45.86305,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5528,
            0,
            109.4754,
            0,
            0.59585,
            0,
            20.13762,
            0,
            -0.05297,
            0,
            0,
            0,
            29.40917,
            0,
            17.48568,
            "<D",
            115.628,
            0,
        ],
        1728089220: [
            0.09444,
            0,
            0.09444,
            0,
            45.90423,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5571,
            0,
            112.3246,
            0,
            0.58412,
            0,
            20.14429,
            0,
            -0.04966,
            0,
            0,
            0,
            29.40885,
            0,
            14.0757,
            "<D",
            115.5372,
            0,
        ],
        1728089280: [
            0.16109,
            0,
            0.16109,
            0,
            46.00831,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            111.4236,
            0,
            0.69857,
            0,
            20.11192,
            0,
            -0.0927,
            0,
            0,
            0,
            29.40865,
            0,
            14.15234,
            "<D",
            115.4046,
            0,
        ],
        1728089340: [
            0.18463,
            0,
            0.18463,
            0,
            46.07643,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            101.2668,
            0,
            0.67676,
            0,
            20.08197,
            0,
            -0.08939,
            0,
            0,
            0,
            29.40883,
            0,
            17.76115,
            "<D",
            115.1429,
            0,
        ],
        1728089400: [
            0.22337,
            0,
            0.22337,
            0,
            46.28289,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            85.6681,
            0,
            0.75958,
            0,
            20.02188,
            0,
            -0.16554,
            0,
            0,
            0,
            29.40876,
            0,
            14.02857,
            "<D",
            115.6206,
            0,
        ],
        1728089460: [
            0.19444,
            0,
            0.19444,
            0,
            45.50311,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.53991,
            0,
            97.84319,
            0,
            0.48229,
            0,
            20.01176,
            0,
            -0.21189,
            0,
            0,
            0,
            29.40921,
            0,
            13.95794,
            "<D",
            114.4334,
            0,
        ],
        1728089520: [
            0.20141,
            0,
            0.20141,
            0,
            43.76366,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            84.46539,
            0,
            0.49793,
            0,
            20.08778,
            0,
            -0.23507,
            0,
            0,
            0,
            29.40834,
            0,
            15.35069,
            "<D",
            113.6459,
            0,
        ],
        1728089580: [
            0.39636,
            0,
            0.39636,
            0,
            42.03902,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5657,
            0,
            101.4141,
            0,
            0.42499,
            0,
            20.05993,
            0,
            -0.22845,
            0,
            0,
            0,
            29.40893,
            0,
            13.49223,
            "<D",
            113.0677,
            0,
        ],
        1728089640: [
            0.11326,
            0,
            0.11326,
            0,
            40.89427,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            76.47912,
            0,
            0.44453,
            0,
            20.0592,
            0,
            -0.21851,
            0,
            0,
            0,
            29.40976,
            0,
            15.4381,
            "<D",
            113.5035,
            0,
        ],
        1728089700: [
            -0.00045,
            0,
            -0.00045,
            0,
            41.52682,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            79.62511,
            0,
            0.47064,
            0,
            20.08477,
            0,
            -0.20196,
            0,
            0,
            0,
            29.41029,
            0,
            14.38383,
            "<D",
            114.7668,
            0,
        ],
        1728089760: [
            0.19913,
            0,
            0.19913,
            0,
            41.2783,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            82.38467,
            0,
            0.4872,
            0,
            20.10885,
            0,
            -0.15229,
            0,
            0,
            0,
            29.41017,
            0,
            13.84781,
            "<D",
            114.3737,
            0,
        ],
        1728089820: [
            0.09221,
            0,
            0.09221,
            0,
            41.65737,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            99.6927,
            0,
            0.5804,
            0,
            20.15249,
            0,
            -0.10263,
            0,
            0,
            0,
            29.41012,
            0,
            15.49106,
            "<D",
            112.8033,
            0,
        ],
        1728089880: [
            0.25006,
            0,
            0.25006,
            0,
            41.96588,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            119.3593,
            0,
            0.59247,
            0,
            20.19948,
            0,
            -0.06952,
            0,
            0,
            0,
            29.40979,
            0,
            14.02516,
            "<D",
            115.9255,
            0,
        ],
        1728089940: [
            0.06861,
            0,
            0.06861,
            0,
            43.25524,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            113.6889,
            0,
            0.66585,
            0,
            20.17153,
            0,
            -0.07615,
            0,
            0,
            0,
            29.40974,
            0,
            15.1656,
            "<D",
            115.8516,
            0,
        ],
        1728090000: [
            0.15687,
            0,
            0.15687,
            0,
            42.79926,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            105.1529,
            0,
            0.57299,
            0,
            20.15049,
            0,
            -0.1225,
            0,
            0,
            0,
            29.41018,
            0,
            13.83035,
            "<D",
            116.3176,
            0,
        ],
        1728090060: [
            0.22477,
            0,
            0.22477,
            0,
            42.33439,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5657,
            0,
            95.21616,
            0,
            0.43436,
            0,
            20.11767,
            0,
            -0.14236,
            0,
            0,
            0,
            29.41092,
            0,
            17.53453,
            "<D",
            115.3886,
            0,
        ],
        1728090120: [
            0.24726,
            0,
            0.24726,
            0,
            41.62615,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            99.74091,
            0,
            0.46182,
            0,
            20.08502,
            0,
            -0.23506,
            0,
            0,
            0,
            29.41135,
            0,
            11.68997,
            "<D",
            115.0487,
            0,
        ],
        1728090180: [
            0.24023,
            0,
            0.24023,
            0,
            40.61166,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5829,
            0,
            86.80457,
            0,
            0.50502,
            0,
            20.0798,
            0,
            -0.2483,
            0,
            0,
            0,
            29.41317,
            0,
            14.21546,
            "<D",
            115.0381,
            0,
        ],
        1728090240: [
            0.34197,
            0,
            0.34197,
            0,
            40.43027,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            57.3163,
            0,
            0.42767,
            0,
            20.02009,
            0,
            -0.27149,
            0,
            0,
            0,
            29.41353,
            0,
            15.81028,
            "<D",
            114.8616,
            0,
        ],
        1728090300: [
            0.29773,
            0,
            0.29773,
            0,
            40.94323,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            38.9687,
            0,
            0.43278,
            0,
            19.83121,
            0,
            -0.35095,
            0,
            0,
            0,
            29.41304,
            0,
            12.38575,
            "<D",
            114.8617,
            0,
        ],
        1728090360: [
            0.16989,
            0,
            0.16989,
            0,
            41.03028,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            21.74863,
            0,
            0.34701,
            0,
            19.765,
            0,
            -0.32446,
            0,
            0,
            0,
            29.41375,
            0,
            16.64423,
            "<D",
            112.7614,
            0,
        ],
        1728090420: [
            0.15069,
            0,
            0.15069,
            0,
            41.36908,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5786,
            0,
            24.13522,
            0,
            0.35749,
            0,
            19.77197,
            0,
            -0.31784,
            0,
            0,
            0,
            29.41375,
            0,
            14.85302,
            "<D",
            113.4912,
            0,
        ],
        1728090480: [
            0.12278,
            0,
            0.12278,
            0,
            43.79965,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            26.15328,
            0,
            0.33413,
            0,
            19.78498,
            0,
            -0.31122,
            0,
            0,
            0,
            29.41375,
            0,
            13.1968,
            "<D",
            114.2777,
            0,
        ],
        1728090540: [
            0.3093,
            0,
            0.3093,
            0,
            42.80056,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            33.72007,
            0,
            0.33206,
            0,
            19.84373,
            0,
            -0.27811,
            0,
            0,
            0,
            29.41394,
            0,
            11.08541,
            "<D",
            116.6136,
            0,
        ],
        1728090600: [
            0.19436,
            0,
            0.19436,
            0,
            42.59184,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            37.95818,
            0,
            0.38272,
            0,
            19.90729,
            0,
            -0.26818,
            0,
            0,
            0,
            29.41536,
            0,
            16.6577,
            "<D",
            119.706,
            0,
        ],
        1728090660: [
            0.22013,
            0,
            0.22013,
            0,
            43.2232,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            44.31112,
            0,
            0.45559,
            0,
            19.90596,
            0,
            -0.13574,
            0,
            0,
            0,
            29.41522,
            0,
            12.45653,
            "<D",
            117.8751,
            0,
        ],
        1728090720: [
            0.21031,
            0,
            0.21031,
            0,
            44.6176,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            46.01973,
            0,
            0.4934,
            0,
            19.84166,
            0,
            -0.11919,
            0,
            0,
            0,
            29.41566,
            0,
            15.95428,
            "<D",
            115.1977,
            0,
        ],
        1728090780: [
            0.30155,
            0,
            0.30155,
            0,
            42.02328,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            45.21409,
            0,
            0.55433,
            0,
            19.84787,
            0,
            -0.08608,
            0,
            0,
            0,
            29.41699,
            0,
            14.10018,
            "<D",
            113.9438,
            0,
        ],
        1728090840: [
            0.23992,
            0,
            0.23992,
            0,
            44.37197,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            55.41932,
            0,
            0.54445,
            0,
            19.87353,
            0,
            -0.17547,
            0,
            0,
            0,
            29.41751,
            0,
            13.8936,
            "<D",
            113.3002,
            0,
        ],
        1728090900: [
            0.25576,
            0,
            0.25576,
            0,
            43.00899,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            89.97218,
            0,
            0.43223,
            0,
            19.90243,
            0,
            -0.20527,
            0,
            0,
            0,
            29.41665,
            0,
            12.54253,
            "<D",
            114.574,
            0,
        ],
        1728090960: [
            0.20727,
            0,
            0.20727,
            0,
            39.22438,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            83.42274,
            0,
            0.55917,
            0,
            20.00066,
            0,
            -0.21189,
            0,
            0,
            0,
            29.41768,
            0,
            14.49308,
            "<D",
            114.3471,
            0,
        ],
        1728091020: [
            0.04881,
            0,
            0.04881,
            0,
            37.67724,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            92.3283,
            0,
            0.53713,
            0,
            19.90717,
            0,
            -0.22845,
            0,
            0,
            0,
            29.41759,
            0,
            18.39836,
            "<D",
            113.6829,
            0,
        ],
        1728091080: [
            0.1672,
            0,
            0.1672,
            0,
            36.96572,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            71.39221,
            0,
            0.54508,
            0,
            19.90342,
            0,
            -0.23176,
            0,
            0,
            0,
            29.4169,
            0,
            16.9122,
            "<D",
            114.3581,
            0,
        ],
        1728091140: [
            0.13965,
            0,
            0.13965,
            0,
            34.58078,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.59581,
            0,
            49.32049,
            0,
            0.55447,
            0,
            19.9392,
            0,
            -0.23176,
            0,
            0,
            0,
            29.41732,
            0,
            16.59976,
            "<D",
            115.3976,
            0,
        ],
        1728091200: [
            0.17656,
            0,
            0.17656,
            0,
            35.75289,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            40.54593,
            0,
            0.54819,
            0,
            19.96731,
            0,
            -0.18541,
            0,
            0,
            0,
            29.41702,
            0,
            13.34933,
            "<D",
            117.1192,
            0,
        ],
        1728091260: [
            0.13543,
            0,
            0.13543,
            0,
            35.93447,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            43.36169,
            0,
            0.51114,
            0,
            19.98096,
            0,
            -0.1821,
            0,
            0,
            0,
            29.417,
            0,
            13.25335,
            "<D",
            117.2902,
            0,
        ],
        1728091320: [
            0.34838,
            0,
            0.34838,
            0,
            35.65812,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            33.29203,
            0,
            0.49338,
            0,
            19.99834,
            0,
            -0.23176,
            0,
            0,
            0,
            29.4171,
            0,
            15.12921,
            "<D",
            114.8675,
            0,
        ],
        1728091380: [
            0.54228,
            0,
            0.54228,
            0,
            35.91599,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            53.02596,
            0,
            0.35565,
            0,
            20.0043,
            0,
            -0.22514,
            0,
            0,
            0,
            29.4173,
            0,
            16.17674,
            "<D",
            113.4224,
            0,
        ],
        1728091440: [
            0.35065,
            0,
            0.35065,
            0,
            35.50223,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            76.36923,
            0,
            0.32549,
            0,
            20.01204,
            0,
            -0.23507,
            0,
            0,
            0,
            29.4173,
            0,
            13.80824,
            "<D",
            114.3683,
            0,
        ],
        1728091500: [
            0.27527,
            0,
            0.27527,
            0,
            38.74364,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            71.52831,
            0,
            0.34822,
            0,
            20.00409,
            0,
            -0.18872,
            0,
            0,
            0,
            29.4176,
            0,
            15.11053,
            "<D",
            114.3574,
            0,
        ],
        1728091560: [
            0.27107,
            0,
            0.27107,
            0,
            40.93364,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            67.3309,
            0,
            0.35899,
            0,
            19.99475,
            0,
            -0.19865,
            0,
            0,
            0,
            29.41759,
            0,
            13.93134,
            "<D",
            113.4901,
            0,
        ],
        1728091620: [
            0.06336,
            0,
            0.06336,
            0,
            41.3781,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            61.73919,
            0,
            0.30125,
            0,
            19.97279,
            0,
            -0.13243,
            0,
            0,
            0,
            29.4181,
            0,
            15.48518,
            "<D",
            114.008,
            0,
        ],
        1728091680: [
            0.08418,
            0,
            0.08418,
            0,
            40.71496,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            57.0024,
            0,
            0.22123,
            0,
            19.93769,
            0,
            -0.16885,
            0,
            0,
            0,
            29.4198,
            0,
            14.06088,
            "<D",
            114.1915,
            0,
        ],
        1728091740: [
            0.16475,
            0,
            0.16475,
            0,
            36.85223,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            47.0869,
            0,
            0.22373,
            0,
            19.82148,
            0,
            -0.19865,
            0,
            0,
            0,
            29.41968,
            0,
            13.95565,
            "<D",
            115.1146,
            0,
        ],
        1728091800: [
            0.26452,
            0,
            0.26452,
            0,
            31.14842,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            38.46557,
            0,
            0.31337,
            0,
            19.72239,
            0,
            -0.22183,
            0,
            0,
            0,
            29.42071,
            0,
            14.31392,
            "<D",
            115.6967,
            0,
        ],
        1728091860: [
            0.20574,
            0,
            0.20574,
            0,
            34.2766,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5829,
            0,
            45.59642,
            0,
            0.426,
            0,
            19.5894,
            0,
            -0.16554,
            0,
            0,
            0,
            29.42063,
            0,
            14.37148,
            "<D",
            116.4815,
            0,
        ],
        1728091920: [
            0.11226,
            0,
            0.11226,
            0,
            34.34883,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            62.79534,
            0,
            0.45578,
            0,
            19.55789,
            0,
            -0.22514,
            0,
            0,
            0,
            29.42101,
            0,
            15.09344,
            "<D",
            117.2286,
            0,
        ],
        1728091980: [
            0.26651,
            0,
            0.26651,
            0,
            33.96753,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            67.42162,
            0,
            0.44084,
            0,
            19.54668,
            0,
            -0.22183,
            0,
            0,
            0,
            29.42105,
            0,
            13.46506,
            "<D",
            114.8473,
            0,
        ],
        1728092040: [
            0.28849,
            0,
            0.28849,
            0,
            34.00473,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            74.68884,
            0,
            0.52365,
            0,
            19.51834,
            0,
            -0.22845,
            0,
            0,
            0,
            29.42106,
            0,
            13.58869,
            "<D",
            114.7035,
            0,
        ],
        1728092100: [
            0.36601,
            0,
            0.36601,
            0,
            37.24295,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5528,
            0,
            80.27042,
            0,
            0.59072,
            0,
            19.54029,
            0,
            -0.16885,
            0,
            0,
            0,
            29.42091,
            0,
            13.97277,
            "<D",
            114.6028,
            0,
        ],
        1728092160: [
            0.20877,
            0,
            0.20877,
            0,
            37.46937,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            81.13015,
            0,
            0.53781,
            0,
            19.60523,
            0,
            -0.15892,
            0,
            0,
            0,
            29.42099,
            0,
            13.51725,
            "<D",
            113.8542,
            0,
        ],
        1728092220: [
            0.18316,
            0,
            0.18316,
            0,
            38.64092,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            89.94971,
            0,
            0.5933,
            0,
            19.69444,
            0,
            -0.11588,
            0,
            0,
            0,
            29.42095,
            0,
            15.6972,
            "<D",
            113.9183,
            0,
        ],
        1728092280: [
            0.15431,
            0,
            0.15431,
            0,
            39.78202,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            86.02831,
            0,
            0.63387,
            0,
            19.66894,
            0,
            -0.14899,
            0,
            0,
            0,
            29.42074,
            0,
            13.85727,
            "<D",
            114.0986,
            0,
        ],
        1728092340: [
            0.14332,
            0,
            0.14332,
            0,
            36.58989,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            82.49937,
            0,
            0.50668,
            0,
            19.6343,
            0,
            -0.1225,
            0,
            0,
            0,
            29.42078,
            0,
            15.29007,
            "<D",
            112.0961,
            0,
        ],
        1728092400: [
            0.13928,
            0,
            0.13928,
            0,
            34.70081,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            93.99586,
            0,
            0.46542,
            0,
            19.62271,
            0,
            -0.12581,
            0,
            0,
            0,
            29.42091,
            0,
            11.93002,
            "<D",
            113.6894,
            0,
        ],
        1728092460: [
            0.25951,
            0,
            0.25951,
            0,
            32.74523,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            89.25211,
            0,
            0.51246,
            0,
            19.61492,
            0,
            -0.17548,
            0,
            0,
            0,
            29.42091,
            0,
            15.63015,
            "<D",
            113.6095,
            0,
        ],
        1728092520: [
            0.30127,
            0,
            0.30127,
            0,
            34.70502,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            75.26859,
            0,
            0.77592,
            0,
            19.61406,
            0,
            -0.23176,
            0,
            0,
            0,
            29.42103,
            0,
            15.00298,
            "<D",
            112.5324,
            0,
        ],
        1728092580: [
            0.28502,
            0,
            0.28502,
            0,
            36.18814,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            56.33016,
            0,
            0.57302,
            0,
            19.60533,
            0,
            -0.23507,
            0,
            0,
            0,
            29.42105,
            0,
            13.68357,
            "<D",
            110.9571,
            0,
        ],
        1728092640: [
            0.34196,
            0,
            0.34196,
            0,
            31.71253,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5872,
            0,
            31.66291,
            0,
            0.51591,
            0,
            19.63829,
            0,
            -0.22845,
            0,
            0,
            0,
            29.42106,
            0,
            15.30055,
            "<D",
            112.2693,
            0,
        ],
        1728092700: [
            0.15121,
            0,
            0.15121,
            0,
            33.50384,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            53.92967,
            0,
            0.71277,
            0,
            19.61326,
            0,
            -0.21852,
            0,
            0,
            0,
            29.42139,
            0,
            12.30824,
            "<D",
            111.5819,
            0,
        ],
        1728092760: [
            0.22401,
            0,
            0.22401,
            0,
            38.56541,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5657,
            0,
            15.36965,
            0,
            0.51526,
            0,
            19.44108,
            0,
            -0.18541,
            0,
            0,
            0,
            29.42105,
            0,
            15.21609,
            "<D",
            111.4938,
            0,
        ],
        1728092820: [
            0.10983,
            0,
            0.10983,
            0,
            37.34464,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5614,
            0,
            27.43215,
            0,
            0.6398,
            0,
            19.30048,
            0,
            -0.18872,
            0,
            0,
            0,
            29.42102,
            0,
            14.69845,
            "<D",
            112.0116,
            0,
        ],
        1728092880: [
            0.2041,
            0,
            0.2041,
            0,
            37.88483,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.527,
            0,
            36.82854,
            0,
            0.55766,
            0,
            19.37199,
            0,
            -0.11919,
            0,
            0,
            0,
            29.42132,
            0,
            14.78016,
            "<D",
            113.349,
            0,
        ],
        1728092940: [
            0.0142,
            0,
            0.0142,
            0,
            36.29944,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5356,
            0,
            25.44005,
            0,
            0.46003,
            0,
            19.43916,
            0,
            -0.07284,
            0,
            0,
            0,
            29.42118,
            0,
            15.82494,
            "<D",
            113.4044,
            0,
        ],
        1728093000: [
            0.23553,
            0,
            0.23553,
            0,
            37.47886,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.52271,
            0,
            55.14126,
            0,
            0.34302,
            0,
            19.47311,
            0,
            -0.08277,
            0,
            0,
            0,
            29.42148,
            0,
            11.57311,
            "<D",
            113.7421,
            0,
        ],
        1728093060: [
            0.12182,
            0,
            0.12182,
            0,
            40.18086,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.52271,
            0,
            80.03975,
            0,
            0.40401,
            0,
            19.53131,
            0,
            -0.12249,
            0,
            0,
            0,
            29.42342,
            0,
            15.53861,
            "<D",
            113.6081,
            0,
        ],
        1728093120: [
            0.19835,
            0,
            0.19835,
            0,
            37.97709,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.52271,
            0,
            60.45745,
            0,
            0.35829,
            0,
            19.54429,
            0,
            -0.15229,
            0,
            0,
            0,
            29.42319,
            0,
            11.34494,
            "<D",
            114.3622,
            0,
        ],
        1728093180: [
            0.16587,
            0,
            0.16587,
            0,
            33.53544,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.52271,
            0,
            59.93404,
            0,
            0.21113,
            0,
            19.57934,
            0,
            -0.1854,
            0,
            0,
            0,
            29.42357,
            0,
            14.67726,
            "<D",
            114.6648,
            0,
        ],
        1728093240: [
            0.25244,
            0,
            0.25244,
            0,
            34.85418,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5313,
            0,
            37.91982,
            0,
            0.22361,
            0,
            19.51637,
            0,
            -0.15891,
            0,
            0,
            0,
            29.42366,
            0,
            15.87255,
            "<D",
            115.0517,
            0,
        ],
        1728093300: [
            0.202,
            0,
            0.202,
            0,
            36.37231,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.53991,
            0,
            32.04937,
            0,
            0.2192,
            0,
            19.39131,
            0,
            -0.16884,
            0,
            0,
            0,
            29.42293,
            0,
            14.52925,
            "<D",
            114.0197,
            0,
        ],
        1728093360: [
            0.31974,
            0,
            0.31974,
            0,
            34.89534,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5528,
            0,
            35.6907,
            0,
            0.22053,
            0,
            19.37691,
            0,
            -0.1258,
            0,
            0,
            0,
            29.4241,
            0,
            15.61902,
            "<D",
            115.4796,
            0,
        ],
        1728093420: [
            0.09683,
            0,
            0.09683,
            0,
            36.55841,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5528,
            0,
            44.4027,
            0,
            0.21687,
            0,
            19.37926,
            0,
            -0.10594,
            0,
            0,
            0,
            29.42423,
            0,
            14.80802,
            "<D",
            115.7267,
            0,
        ],
        1728093480: [
            0.27557,
            0,
            0.27557,
            0,
            32.26833,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            41.46634,
            0,
            0.22166,
            0,
            19.39544,
            0,
            -0.12581,
            0,
            0,
            0,
            29.4235,
            0,
            15.27513,
            "<D",
            114.8708,
            0,
        ],
        1728093540: [
            0.13634,
            0,
            0.13634,
            0,
            33.35969,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            52.19168,
            0,
            0.21689,
            0,
            19.44666,
            0,
            -0.10594,
            0,
            0,
            0,
            29.42346,
            0,
            15.26905,
            "<D",
            114.0831,
            0,
        ],
        1728093600: [
            0.2226,
            0,
            0.2226,
            0,
            34.50555,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5657,
            0,
            46.54408,
            0,
            0.21999,
            0,
            19.46085,
            0,
            -0.17216,
            0,
            0,
            0,
            29.42409,
            0,
            15.6546,
            "<D",
            114.4914,
            0,
        ],
        1728093660: [
            0.07442,
            0,
            0.07442,
            0,
            37.0311,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            60.80891,
            0,
            0.21964,
            0,
            19.46854,
            0,
            -0.1523,
            0,
            0,
            0,
            29.42325,
            0,
            14.8018,
            "<D",
            117.1577,
            0,
        ],
        1728093720: [
            0.09088,
            0,
            0.09088,
            0,
            33.85885,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.53991,
            0,
            75.46506,
            0,
            0.22352,
            0,
            19.43736,
            0,
            -0.20196,
            0,
            0,
            0,
            29.42332,
            0,
            15.34119,
            "<D",
            116.5653,
            0,
        ],
        1728093780: [
            0.22799,
            0,
            0.22799,
            0,
            30.43515,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.53991,
            0,
            61.94578,
            0,
            0.22282,
            0,
            19.37196,
            0,
            -0.23507,
            0,
            0,
            0,
            29.42355,
            0,
            14.68676,
            "<D",
            115.3963,
            0,
        ],
        1728093840: [
            0.31684,
            0,
            0.31684,
            0,
            28.95526,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.52271,
            0,
            59.18924,
            0,
            0.22141,
            0,
            19.3735,
            0,
            -0.23176,
            0,
            0,
            0,
            29.42431,
            0,
            14.81038,
            "<D",
            115.4396,
            0,
        ],
        1728093900: [
            0.27003,
            0,
            0.27003,
            0,
            30.36699,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5012,
            0,
            60.1118,
            0,
            0.21914,
            0,
            19.39831,
            0,
            -0.16885,
            0,
            0,
            0,
            29.42431,
            0,
            13.75856,
            "<D",
            116.4869,
            0,
        ],
        1728093960: [
            0.23542,
            0,
            0.23542,
            0,
            30.04279,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.52271,
            0,
            58.24328,
            0,
            0.34858,
            0,
            19.4114,
            0,
            -0.08277,
            0,
            0,
            0,
            29.42431,
            0,
            14.98523,
            "<D",
            115.2551,
            0,
        ],
        1728094020: [
            0.19619,
            0,
            0.19619,
            0,
            29.19231,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4969,
            0,
            35.51397,
            0,
            0.42256,
            0,
            19.40349,
            0,
            -0.08939,
            0,
            0,
            0,
            29.4243,
            0,
            14.7094,
            "<D",
            114.9876,
            0,
        ],
        1728094080: [
            0.27906,
            0,
            0.27906,
            0,
            28.48192,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5098,
            0,
            29.31247,
            0,
            0.42269,
            0,
            19.40248,
            0,
            -0.11587,
            0,
            0,
            0,
            29.4241,
            0,
            12.80601,
            "<D",
            115.0423,
            0,
        ],
        1728094140: [
            0.22273,
            0,
            0.22273,
            0,
            29.34245,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.51841,
            0,
            26.86066,
            0,
            0.56892,
            0,
            19.38099,
            0,
            -0.13243,
            0,
            0,
            0,
            29.42403,
            0,
            16.08794,
            "<D",
            114.4319,
            0,
        ],
        1728094200: [
            0.1492,
            0,
            0.1492,
            0,
            29.96637,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5141,
            0,
            37.95798,
            0,
            0.50804,
            0,
            19.26022,
            0,
            -0.10594,
            0,
            0,
            0,
            29.42408,
            0,
            12.90383,
            "<D",
            114.8265,
            0,
        ],
        1728094260: [
            0.22068,
            0,
            0.22068,
            0,
            33.3359,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.52271,
            0,
            34.07331,
            0,
            0.43591,
            0,
            19.16171,
            0,
            -0.14236,
            0,
            0,
            0,
            29.42391,
            0,
            14.74057,
            "<D",
            114.5426,
            0,
        ],
        1728094320: [
            0.15781,
            0,
            0.15781,
            0,
            33.93325,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5313,
            0,
            37.45629,
            0,
            0.43626,
            0,
            19.16615,
            0,
            -0.05628,
            0,
            0,
            0,
            29.42391,
            0,
            13.47897,
            "<D",
            114.7776,
            0,
        ],
        1728094380: [
            0.10321,
            0,
            0.10321,
            0,
            35.8859,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5442,
            0,
            38.20242,
            0,
            0.42927,
            0,
            19.20948,
            0,
            -0.04304,
            0,
            0,
            0,
            29.42428,
            0,
            15.11327,
            "<D",
            114.7947,
            0,
        ],
        1728094440: [
            0.17091,
            0,
            0.17091,
            0,
            36.70363,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5528,
            0,
            41.73842,
            0,
            0.53506,
            0,
            19.22713,
            0,
            -0.08277,
            0,
            0,
            0,
            29.4243,
            0,
            15.05117,
            "<D",
            113.9462,
            0,
        ],
        1728094500: [
            0.23211,
            0,
            0.23211,
            0,
            33.04464,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            47.48867,
            0,
            0.63356,
            0,
            19.26268,
            0,
            -0.13574,
            0,
            0,
            0,
            29.42431,
            0,
            13.27349,
            "<D",
            113.9291,
            0,
        ],
        1728094560: [
            0.46949,
            0,
            0.46949,
            0,
            30.90985,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.57,
            0,
            22.15971,
            0,
            0.49661,
            0,
            19.30331,
            0,
            -0.13243,
            0,
            0,
            0,
            29.42408,
            0,
            15.55424,
            "<D",
            114.9039,
            0,
        ],
        1728094620: [
            0.16712,
            0,
            0.16712,
            0,
            32.61747,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            12.76762,
            0,
            0.41235,
            0,
            19.28149,
            0,
            -0.19203,
            0,
            0,
            0,
            29.42431,
            0,
            14.33909,
            "<D",
            116.6461,
            0,
        ],
        1728094680: [
            0.3024,
            0,
            0.3024,
            0,
            32.4958,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5528,
            0,
            28.02202,
            0,
            0.42698,
            0,
            19.22847,
            0,
            -0.22845,
            0,
            0,
            0,
            29.42426,
            0,
            16.78431,
            "<D",
            112.7878,
            0,
        ],
        1728094740: [
            0.25609,
            0,
            0.25609,
            0,
            32.56165,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.53991,
            0,
            31.48061,
            0,
            0.45649,
            0,
            19.16986,
            0,
            -0.20527,
            0,
            0,
            0,
            29.42426,
            0,
            13.09195,
            "<D",
            112.3751,
            0,
        ],
        1728094800: [
            0.31226,
            0,
            0.31226,
            0,
            29.59868,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.53991,
            0,
            43.85889,
            0,
            0.51463,
            0,
            19.15365,
            0,
            -0.21852,
            0,
            0,
            0,
            29.4243,
            0,
            15.4536,
            "<D",
            111.9596,
            0,
        ],
        1728094860: [
            0.07627,
            0,
            0.07627,
            0,
            27.55613,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.527,
            0,
            25.22538,
            0,
            0.38764,
            0,
            19.11339,
            0,
            -0.23176,
            0,
            0,
            0,
            29.42447,
            0,
            14.24576,
            "<D",
            111.8583,
            0,
        ],
        1728094920: [
            0.03127,
            0,
            0.03127,
            0,
            28.00986,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5141,
            0,
            32.04394,
            0,
            0.29074,
            0,
            19.1138,
            0,
            -0.20858,
            0,
            0,
            0,
            29.42431,
            0,
            11.80656,
            "<D",
            112.0461,
            0,
        ],
        1728094980: [
            0.10281,
            0,
            0.10281,
            0,
            24.55437,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5055,
            0,
            49.19789,
            0,
            0.22466,
            0,
            19.1639,
            0,
            -0.11588,
            0,
            0,
            0,
            29.42469,
            0,
            14.856,
            "<D",
            112.2049,
            0,
        ],
        1728095040: [
            0.31554,
            0,
            0.31554,
            0,
            24.79255,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4969,
            0,
            51.39569,
            0,
            0.22449,
            0,
            19.1397,
            0,
            -0.10263,
            0,
            0,
            0,
            29.42648,
            0,
            14.12035,
            "<D",
            112.3205,
            0,
        ],
        1728095100: [
            0.26355,
            0,
            0.26355,
            0,
            27.3165,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5141,
            0,
            65.41521,
            0,
            0.23949,
            0,
            18.96166,
            0,
            -0.06621,
            0,
            0,
            0,
            29.42758,
            0,
            16.61691,
            "<D",
            113.5171,
            0,
        ],
        1728095160: [
            0.07569,
            0,
            0.07569,
            0,
            23.96578,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4969,
            0,
            70.23642,
            0,
            0.44821,
            0,
            18.88991,
            0,
            -0.04966,
            0,
            0,
            0,
            29.42758,
            0,
            15.99717,
            "<D",
            114.7663,
            0,
        ],
        1728095220: [
            0.07125,
            0,
            0.07125,
            0,
            21.02617,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4969,
            0,
            91.57232,
            0,
            0.43427,
            0,
            18.95225,
            0,
            -0.05959,
            0,
            0,
            0,
            29.4276,
            0,
            13.34956,
            "<D",
            113.9094,
            0,
        ],
        1728095280: [
            0.07173,
            0,
            0.07173,
            0,
            20.11236,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4969,
            0,
            96.10146,
            0,
            0.32945,
            0,
            18.96985,
            0,
            -0.04966,
            0,
            0,
            0,
            29.4276,
            0,
            14.78576,
            "<D",
            114.1059,
            0,
        ],
        1728095340: [
            0.15835,
            0,
            0.15835,
            0,
            18.88271,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5141,
            0,
            89.21846,
            0,
            0.3158,
            0,
            18.90549,
            0,
            -0.04966,
            0,
            0,
            0,
            29.42756,
            0,
            13.62448,
            "<D",
            113.6341,
            0,
        ],
        1728095400: [
            0.30362,
            0,
            0.30362,
            0,
            20.45027,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.52271,
            0,
            73.74939,
            0,
            0.26244,
            0,
            18.91421,
            0,
            -0.04966,
            0,
            0,
            0,
            29.42756,
            0,
            15.72707,
            "<D",
            112.7506,
            0,
        ],
        1728095460: [
            0.19535,
            0,
            0.19535,
            0,
            26.56732,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5313,
            0,
            43.17644,
            0,
            0.22341,
            0,
            18.77456,
            0,
            -0.05297,
            0,
            0,
            0,
            29.42756,
            0,
            14.3962,
            "<D",
            112.9682,
            0,
        ],
        1728095520: [
            0.18193,
            0,
            0.18193,
            0,
            21.47763,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5313,
            0,
            52.0764,
            0,
            0.21811,
            0,
            18.69711,
            0,
            -0.07946,
            0,
            0,
            0,
            29.42756,
            0,
            13.38712,
            "<D",
            112.3483,
            0,
        ],
        1728095580: [
            0.06369,
            0,
            0.06369,
            0,
            22.62215,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            100.8621,
            0,
            0.22301,
            0,
            18.73013,
            0,
            -0.0629,
            0,
            0,
            0,
            29.42756,
            0,
            14.71668,
            "<D",
            111.1035,
            0,
        ],
        1728095640: [
            0.21362,
            0,
            0.21362,
            0,
            26.67067,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5442,
            0,
            91.11776,
            0,
            0.2231,
            0,
            18.86086,
            0,
            -0.10263,
            0,
            0,
            0,
            29.42756,
            0,
            13.81135,
            "<D",
            110.9292,
            0,
        ],
        1728095700: [
            0.30022,
            0,
            0.30022,
            0,
            36.93194,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5313,
            0,
            96.50784,
            0,
            0.21174,
            0,
            18.84769,
            0,
            -0.21521,
            0,
            0,
            0,
            29.42756,
            0,
            12.0827,
            "<D",
            110.5469,
            0,
        ],
        1728095760: [
            0.12307,
            0,
            0.12307,
            0,
            37.63653,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.51841,
            0,
            99.14065,
            0,
            0.60083,
            0,
            18.85056,
            0,
            -0.23838,
            0,
            0,
            0,
            29.4276,
            0,
            15.3756,
            "<D",
            110.3969,
            0,
        ],
        1728095820: [
            0.34591,
            0,
            0.34591,
            0,
            36.09072,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5055,
            0,
            116.9894,
            0,
            0.50546,
            0,
            18.8487,
            0,
            -0.22845,
            0,
            0,
            0,
            29.42758,
            0,
            11.09745,
            "<D",
            110.8734,
            0,
        ],
        1728095880: [
            0.32876,
            0,
            0.32876,
            0,
            29.85985,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4711,
            0,
            124.0497,
            0,
            0.52094,
            0,
            18.77852,
            0,
            -0.22183,
            0,
            0,
            0,
            29.4276,
            0,
            14.15328,
            "<D",
            111.1922,
            0,
        ],
        1728095940: [
            0.0814,
            0,
            0.0814,
            0,
            36.88841,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4754,
            0,
            115.0783,
            0,
            0.42473,
            0,
            18.61002,
            0,
            -0.35094,
            0,
            0,
            0,
            29.42789,
            0,
            16.00931,
            "<D",
            111.2868,
            0,
        ],
        1728096000: [
            0.1337,
            0,
            0.1337,
            0,
            38.67564,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4711,
            0,
            113.812,
            0,
            0.60618,
            0,
            18.6223,
            0,
            -0.32776,
            0,
            0,
            0,
            29.42756,
            0,
            13.21347,
            "<D",
            111.8193,
            0,
        ],
        1728096060: [
            0.22259,
            0,
            0.22259,
            0,
            38.24051,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.46251,
            0,
            103.6641,
            0,
            0.74657,
            0,
            18.65922,
            0,
            -0.30127,
            0,
            0,
            0,
            29.42756,
            0,
            14.8418,
            "<D",
            112.7559,
            0,
        ],
        1728096120: [
            0.01551,
            0,
            0.01551,
            0,
            39.2514,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4453,
            0,
            83.28047,
            0,
            0.47133,
            0,
            18.69415,
            0,
            -0.25161,
            0,
            0,
            0,
            29.42772,
            0,
            14.28717,
            "<D",
            113.4383,
            0,
        ],
        1728096180: [
            0.25219,
            0,
            0.25219,
            0,
            35.47311,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4453,
            0,
            71.22273,
            0,
            0.36497,
            0,
            18.76909,
            0,
            -0.23174,
            0,
            0,
            0,
            29.42756,
            0,
            13.59176,
            "<D",
            112.8255,
            0,
        ],
        1728096240: [
            0.07535,
            0,
            0.07535,
            0,
            37.33992,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4453,
            0,
            65.20948,
            0,
            0.38332,
            0,
            18.83989,
            0,
            -0.23505,
            0,
            0,
            0,
            29.42797,
            0,
            15.66242,
            "<D",
            112.1091,
            0,
        ],
        1728096300: [
            3.28675,
            "<C",
            3.28675,
            "<C",
            32.36377,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4496,
            0,
            35.80721,
            0,
            0.25745,
            0,
            18.81244,
            0,
            -0.26485,
            0,
            0,
            0,
            29.42789,
            0,
            10.84894,
            "<D",
            112.8913,
            0,
        ],
        1728096360: [
            150.1293,
            "<C",
            150.1293,
            "<C",
            125.2017,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4453,
            0,
            18.06041,
            0,
            0.2235,
            0,
            18.72916,
            0,
            -0.32775,
            0,
            0,
            0,
            29.42884,
            0,
            15.58844,
            "<D",
            111.9273,
            0,
        ],
        1728096420: [
            194.4302,
            "<C",
            194.4302,
            "<C",
            185.1037,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4539,
            0,
            2.66872,
            0,
            0.22373,
            0,
            18.48308,
            0,
            -0.33106,
            0,
            0,
            0,
            29.42886,
            0,
            17.25022,
            "<D",
            112.0605,
            0,
        ],
        1728096480: [
            196.94,
            "<C",
            196.94,
            "<C",
            193.7805,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4711,
            0,
            348.5421,
            0,
            0.20343,
            0,
            18.67019,
            0,
            -0.12581,
            0,
            0,
            0,
            29.4304,
            0,
            16.96485,
            "<D",
            113.0323,
            0,
        ],
        1728096540: [
            197.8986,
            "<C",
            197.8986,
            "<C",
            196.9456,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.4926,
            0,
            30.51514,
            0,
            0.23597,
            0,
            18.80366,
            0,
            -0.0629,
            0,
            0,
            0,
            29.42925,
            0,
            15.28293,
            "<D",
            112.7339,
            0,
        ],
        1728096600: [
            198.1009,
            "<C",
            198.1009,
            "<C",
            198.0064,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5098,
            0,
            59.22118,
            0,
            0.25373,
            0,
            18.92166,
            0,
            -0.08939,
            0,
            0,
            0,
            29.42932,
            0,
            14.86167,
            "<D",
            113.3197,
            0,
        ],
        1728096660: [
            198.599,
            "<C",
            198.599,
            "<C",
            198.6121,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5313,
            0,
            37.11409,
            0,
            0.22452,
            0,
            18.85674,
            0,
            -0.07946,
            0,
            0,
            0,
            29.4318,
            0,
            14.10151,
            "<D",
            113.4687,
            0,
        ],
        1728096720: [
            198.7441,
            "<C",
            198.7441,
            "<C",
            198.9621,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5614,
            0,
            42.11231,
            0,
            0.42592,
            0,
            18.72824,
            0,
            -0.07284,
            0,
            0,
            0,
            29.43065,
            0,
            17.63693,
            "<D",
            111.8601,
            0,
        ],
        1728096780: [
            198.9301,
            "<C",
            198.9301,
            "<C",
            199.3128,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            25.51736,
            0,
            0.22607,
            0,
            18.51767,
            0,
            -0.14899,
            0,
            0,
            0,
            29.43227,
            0,
            14.08136,
            "<D",
            111.1145,
            0,
        ],
        1728096840: [
            198.6855,
            "<C",
            198.6855,
            "<C",
            199.5294,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            334.9476,
            0,
            0.22371,
            0,
            18.33864,
            0,
            -0.23176,
            0,
            0,
            0,
            29.43248,
            0,
            14.36346,
            "<D",
            111.1152,
            0,
        ],
        1728096900: [
            199.0411,
            "<C",
            199.0411,
            "<C",
            199.739,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            5.23436,
            0,
            0.2081,
            0,
            18.45063,
            0,
            -0.1523,
            0,
            0,
            0,
            29.43227,
            0,
            18.73271,
            "<D",
            111.3806,
            0,
        ],
        1728096960: [
            199.1482,
            "<C",
            199.1482,
            "<C",
            199.8238,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            65.03268,
            0,
            0.08807,
            0,
            18.56327,
            0,
            -0.20527,
            0,
            0,
            0,
            29.43245,
            0,
            14.44788,
            "<D",
            111.9637,
            0,
        ],
        1728097020: [
            199.253,
            "<C",
            199.253,
            "<C",
            200.4262,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            110.6376,
            0,
            0.27348,
            0,
            18.51373,
            0,
            -0.10263,
            0,
            0,
            0,
            29.43244,
            0,
            15.00189,
            "<D",
            111.7344,
            0,
        ],
        1728097080: [
            85.73129,
            "<C",
            85.73129,
            "<C",
            118.7559,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            105.9366,
            0,
            0.36758,
            0,
            18.72417,
            0,
            -0.06621,
            0,
            0,
            0,
            29.43244,
            0,
            14.91889,
            "<D",
            111.9027,
            0,
        ],
        1728097140: [
            61.6946,
            "<C",
            61.6946,
            "<C",
            62.33957,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            96.3501,
            0,
            0.55599,
            0,
            18.7466,
            0,
            -0.08939,
            0,
            0,
            0,
            29.43244,
            0,
            11.5859,
            "<D",
            112.2753,
            0,
        ],
        1728097200: [
            60.66637,
            "<C",
            60.66637,
            "<C",
            60.9502,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            83.74425,
            0,
            0.39862,
            0,
            18.66694,
            0,
            -0.0629,
            0,
            0,
            0,
            29.43244,
            0,
            13.05287,
            "<D",
            113.5516,
            0,
        ],
        1728097260: [
            60.43154,
            "<C",
            60.43154,
            "<C",
            60.70033,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            42.06453,
            0,
            0.45228,
            0,
            18.66643,
            0,
            -0.04966,
            0,
            0,
            0,
            29.43244,
            0,
            15.62337,
            "<D",
            112.5903,
            0,
        ],
        1728097320: [
            60.19303,
            "<C",
            60.19303,
            "<C",
            60.37195,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            357.1436,
            0,
            0.52554,
            0,
            18.54985,
            0,
            -0.04966,
            0,
            0,
            0,
            29.43265,
            0,
            17.37349,
            "<D",
            111.6314,
            0,
        ],
        1728097380: [
            60.24454,
            "<C",
            60.24454,
            "<C",
            60.36284,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            357.8315,
            0,
            0.60334,
            0,
            18.48599,
            0,
            -0.04304,
            0,
            0,
            0,
            29.43344,
            0,
            18.59579,
            "<D",
            111.4737,
            0,
        ],
        1728097440: [
            60.27561,
            "<C",
            60.27561,
            "<C",
            60.58413,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            60.49657,
            0,
            0.2905,
            0,
            18.74739,
            0,
            -0.04635,
            0,
            0,
            0,
            29.43391,
            0,
            15.56742,
            "<D",
            111.1008,
            0,
        ],
        1728097500: [
            60.21464,
            "<C",
            60.21464,
            "<C",
            60.55954,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            102.7441,
            0,
            0.42442,
            0,
            18.79022,
            0,
            -0.05959,
            0,
            0,
            0,
            29.433,
            0,
            12.2603,
            "<D",
            110.6404,
            0,
        ],
        1728097560: [
            60.34122,
            "<C",
            60.34122,
            "<C",
            60.51972,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            122.531,
            0,
            0.35405,
            0,
            18.64876,
            0,
            -0.08939,
            0,
            0,
            0,
            29.43244,
            0,
            14.38497,
            "<D",
            110.8014,
            0,
        ],
        1728097620: [
            60.516,
            "<C",
            60.516,
            "<C",
            60.79722,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            116.3904,
            0,
            0.57181,
            0,
            18.60008,
            0,
            -0.07283,
            0,
            0,
            0,
            29.43255,
            0,
            15.40332,
            "<D",
            110.5714,
            0,
        ],
        1728097680: [
            60.26923,
            "<C",
            60.26923,
            "<C",
            60.54403,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            109.3992,
            0,
            0.38502,
            0,
            18.51783,
            0,
            -0.04635,
            0,
            0,
            0,
            29.43244,
            0,
            14.27414,
            "<D",
            111.4536,
            0,
        ],
        1728097740: [
            20.60496,
            "<C",
            20.60496,
            "<C",
            30.21685,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            89.77351,
            0,
            0.18234,
            0,
            18.41417,
            0,
            -0.05297,
            0,
            0,
            0,
            29.43244,
            0,
            13.82126,
            "<D",
            113.6557,
            0,
        ],
        1728097800: [
            0.39302,
            "<C",
            0.39302,
            "<C",
            0.13527,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.871,
            0,
            53.68564,
            0,
            0.21671,
            0,
            18.35024,
            0,
            -0.06621,
            0,
            0,
            0,
            29.43244,
            0,
            15.96492,
            "<D",
            114.4487,
            0,
        ],
        1728097860: [
            0.07213,
            "<C",
            0.07213,
            "<C",
            -0.0469,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            58.22465,
            0,
            0.21463,
            0,
            18.27094,
            0,
            -0.16223,
            0,
            0,
            0,
            29.43248,
            0,
            15.73852,
            "<D",
            112.9421,
            0,
        ],
        1728097920: [
            0.77778,
            "<C",
            0.77778,
            "<C",
            0.10929,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            68.38782,
            0,
            0.2942,
            0,
            18.4707,
            0,
            -0.20527,
            0,
            0,
            0,
            29.43268,
            0,
            15.27006,
            "<D",
            111.7432,
            0,
        ],
        1728097980: [
            0.40451,
            "<C",
            0.40451,
            "<C",
            0.01198,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            27.22995,
            0,
            0.33467,
            0,
            18.2941,
            0,
            -0.22514,
            0,
            0,
            0,
            29.43274,
            0,
            18.86284,
            "<D",
            110.8223,
            0,
        ],
        1728098040: [
            0.13277,
            "<C",
            0.13277,
            "<C",
            -0.04609,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            12.68023,
            0,
            0.31299,
            0,
            18.16599,
            0,
            -0.23838,
            0,
            0,
            0,
            29.43303,
            0,
            15.57547,
            "<D",
            111.2255,
            0,
        ],
        1728098100: [
            0.40461,
            "<C",
            0.40461,
            "<C",
            21.92138,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            50.09389,
            0,
            0.37973,
            0,
            18.28086,
            0,
            -0.19534,
            0,
            0,
            0,
            29.43333,
            0,
            14.57732,
            "<D",
            111.591,
            0,
        ],
        1728098160: [
            0.38104,
            0,
            0.38104,
            0,
            36.85558,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            38.41114,
            0,
            0.13086,
            0,
            18.55303,
            0,
            -0.21189,
            0,
            0,
            0,
            29.43248,
            0,
            14.3544,
            "<D",
            112.1236,
            0,
        ],
        1728098220: [
            0.29758,
            0,
            0.29758,
            0,
            34.11281,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            46.39714,
            0,
            0.31079,
            0,
            18.39324,
            0,
            -0.14899,
            0,
            0,
            0,
            29.43244,
            0,
            14.85142,
            "<D",
            112.5977,
            0,
        ],
        1728098280: [
            0.11663,
            0,
            0.11663,
            0,
            31.53906,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            89.4087,
            0,
            0.47107,
            0,
            18.45857,
            0,
            -0.09932,
            0,
            0,
            0,
            29.43249,
            0,
            15.19536,
            "<D",
            113.318,
            0,
        ],
        1728098340: [
            0.33972,
            0,
            0.33972,
            0,
            28.90523,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            68.91107,
            0,
            0.51888,
            0,
            18.49028,
            0,
            -0.05959,
            0,
            0,
            0,
            29.43245,
            0,
            16.61476,
            "<D",
            112.753,
            0,
        ],
        1728098400: [
            0.01145,
            0,
            0.01145,
            0,
            27.43411,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            62.26712,
            0,
            0.49538,
            0,
            18.45868,
            0,
            -0.04304,
            0,
            0,
            0,
            29.43254,
            0,
            18.07623,
            "<D",
            112.3018,
            0,
        ],
        1728098460: [
            0.19135,
            0,
            0.19135,
            0,
            28.14108,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            55.00016,
            0,
            1.20378,
            0,
            18.52431,
            0,
            -0.05297,
            0,
            0,
            0,
            29.43266,
            0,
            13.30391,
            "<D",
            112.3363,
            0,
        ],
        1728098520: [
            0.2463,
            0,
            0.2463,
            0,
            34.64986,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9527,
            0,
            48.05495,
            0,
            0.69003,
            0,
            18.41658,
            0,
            -0.06621,
            0,
            0,
            0,
            29.43342,
            0,
            17.8465,
            "<D",
            112.2818,
            0,
        ],
        1728098580: [
            0.26517,
            0,
            0.26517,
            0,
            29.05421,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            62.1424,
            0,
            0.45306,
            0,
            18.66638,
            0,
            -0.05297,
            0,
            0,
            0,
            29.43398,
            0,
            11.58867,
            "<D",
            112.0488,
            0,
        ],
        1728098640: [
            0.23994,
            0,
            0.23994,
            0,
            34.38428,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9656,
            0,
            52.94689,
            0,
            0.18728,
            0,
            18.74034,
            0,
            -0.08608,
            0,
            0,
            0,
            29.43306,
            0,
            18.06247,
            "<D",
            112.228,
            0,
        ],
        1728098700: [
            0.38625,
            0,
            0.38625,
            0,
            36.53013,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9785,
            0,
            68.27846,
            0,
            0.60927,
            0,
            18.50896,
            0,
            -0.14568,
            0,
            0,
            0,
            29.4333,
            0,
            18.79143,
            "<D",
            112.7181,
            0,
        ],
        1728098760: [
            0.18558,
            0,
            0.18558,
            0,
            35.31417,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.00042,
            0,
            112.9719,
            0,
            0.39229,
            0,
            18.54862,
            0,
            -0.15561,
            0,
            0,
            0,
            29.43394,
            0,
            15.87727,
            "<D",
            112.9687,
            0,
        ],
        1728098820: [
            0.1511,
            0,
            0.1511,
            0,
            33.10701,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            51.9345,
            0,
            0.41838,
            0,
            18.39412,
            0,
            -0.20527,
            0,
            0,
            0,
            29.43395,
            0,
            15.03273,
            "<D",
            112.9115,
            0,
        ],
        1728098880: [
            0.10211,
            0,
            0.10211,
            0,
            34.17918,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.02292,
            0,
            86.54668,
            0,
            0.63019,
            0,
            18.56432,
            0,
            -0.22845,
            0,
            0,
            0,
            29.43414,
            0,
            17.48483,
            "<D",
            111.8609,
            0,
        ],
        1728098940: [
            0.17452,
            0,
            0.17452,
            0,
            32.04683,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01834,
            0,
            93.8801,
            0,
            0.53415,
            0,
            18.53951,
            0,
            -0.23176,
            0,
            0,
            0,
            29.43316,
            0,
            15.05621,
            "<D",
            111.4618,
            0,
        ],
        1728099000: [
            0.07741,
            0,
            0.07741,
            0,
            28.32506,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            84.01199,
            0,
            0.75853,
            0,
            18.67398,
            0,
            -0.23507,
            0,
            0,
            0,
            29.43358,
            0,
            15.51192,
            "<D",
            111.3184,
            0,
        ],
        1728099060: [
            0.30969,
            0,
            0.30969,
            0,
            28.58356,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            76.62475,
            0,
            0.83378,
            0,
            18.7132,
            0,
            -0.20196,
            0,
            0,
            0,
            29.43326,
            0,
            16.48013,
            "<D",
            111.0099,
            0,
        ],
        1728099120: [
            0.1749,
            0,
            0.1749,
            0,
            29.62978,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.00042,
            0,
            102.2129,
            0,
            0.59088,
            0,
            18.70701,
            0,
            -0.23176,
            0,
            0,
            0,
            29.43386,
            0,
            14.94384,
            "<D",
            111.0706,
            0,
        ],
        1728099180: [
            0.19126,
            0,
            0.19126,
            0,
            33.81441,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.00042,
            0,
            77.087,
            0,
            0.82852,
            0,
            18.83604,
            0,
            -0.21852,
            0,
            0,
            0,
            29.43312,
            0,
            17.51212,
            "<D",
            111.1034,
            0,
        ],
        1728099240: [
            0.26773,
            0,
            0.26773,
            0,
            32.76515,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9699,
            0,
            79.25281,
            0,
            0.96329,
            0,
            18.87638,
            0,
            -0.22845,
            0,
            0,
            0,
            29.43371,
            0,
            14.97652,
            "<D",
            111.3553,
            0,
        ],
        1728099300: [
            0.28919,
            0,
            0.28919,
            0,
            33.91734,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            110.6901,
            0,
            0.62539,
            0,
            18.84233,
            0,
            -0.19865,
            0,
            0,
            0,
            29.43346,
            0,
            14.17169,
            "<D",
            111.9091,
            0,
        ],
        1728099360: [
            0.19455,
            0,
            0.19455,
            0,
            37.29918,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9742,
            0,
            72.23208,
            0,
            0.4829,
            0,
            18.90954,
            0,
            -0.23507,
            0,
            0,
            0,
            29.43334,
            0,
            18.15538,
            "<D",
            111.4279,
            0,
        ],
        1728099420: [
            0.13473,
            0,
            0.13473,
            0,
            36.50686,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            72.36621,
            0,
            0.54396,
            0,
            19.13233,
            0,
            -0.20858,
            0,
            0,
            0,
            29.43384,
            0,
            13.34057,
            "<D",
            111.4684,
            0,
        ],
        1728099480: [
            0.27598,
            0,
            0.27598,
            0,
            40.13948,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            84.90662,
            0,
            0.39442,
            0,
            19.09545,
            0,
            -0.15561,
            0,
            0,
            0,
            29.43459,
            0,
            15.88329,
            "<D",
            111.97,
            0,
        ],
        1728099540: [
            0.21918,
            0,
            0.21918,
            0,
            39.05385,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            75.54488,
            0,
            0.76105,
            0,
            19.05797,
            0,
            -0.16554,
            0,
            0,
            0,
            29.43598,
            0,
            18.34912,
            "<D",
            111.9792,
            0,
        ],
        1728099600: [
            0.26524,
            0,
            0.26524,
            0,
            37.62628,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            49.44513,
            0,
            0.94417,
            0,
            19.02309,
            0,
            -0.17879,
            0,
            0,
            0,
            29.43529,
            0,
            17.88715,
            "<D",
            112.1567,
            0,
        ],
        1728099660: [
            0.41256,
            0,
            0.41256,
            0,
            38.0148,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9785,
            0,
            42.85409,
            0,
            0.7054,
            0,
            19.14764,
            0,
            -0.22514,
            0,
            0,
            0,
            29.43623,
            0,
            14.39474,
            "<D",
            112.3994,
            0,
        ],
        1728099720: [
            0.42017,
            0,
            0.42017,
            0,
            35.19054,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9656,
            0,
            65.38815,
            0,
            0.61677,
            0,
            19.17168,
            0,
            -0.20858,
            0,
            0,
            0,
            29.43431,
            0,
            16.63229,
            "<D",
            112.1336,
            0,
        ],
        1728099780: [
            0.10584,
            0,
            0.10584,
            0,
            35.14129,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9785,
            0,
            63.27703,
            0,
            0.82564,
            0,
            19.12235,
            0,
            -0.24169,
            0,
            0,
            0,
            29.43555,
            0,
            16.22529,
            "<D",
            112.8406,
            0,
        ],
        1728099840: [
            -0.0075,
            0,
            -0.0075,
            0,
            36.33473,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            67.57308,
            0,
            0.66411,
            0,
            19.10356,
            0,
            -0.22845,
            0,
            0,
            0,
            29.43466,
            0,
            14.5563,
            "<D",
            113.1172,
            0,
        ],
        1728099900: [
            0.13324,
            0,
            0.13324,
            0,
            35.89661,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.99598,
            0,
            78.49493,
            0,
            0.63199,
            0,
            18.95851,
            0,
            -0.15561,
            0,
            0,
            0,
            29.43458,
            0,
            15.07677,
            "<D",
            112.7105,
            0,
        ],
        1728099960: [
            0.11215,
            0,
            0.11215,
            0,
            37.58884,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            67.11445,
            0,
            0.59073,
            0,
            18.98658,
            0,
            -0.1225,
            0,
            0,
            0,
            29.43382,
            0,
            13.39436,
            "<D",
            112.6056,
            0,
        ],
        1728100020: [
            0.03297,
            0,
            0.03297,
            0,
            34.29798,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.99598,
            0,
            98.96241,
            0,
            0.87564,
            0,
            18.84821,
            0,
            -0.06622,
            0,
            0,
            0,
            29.43516,
            0,
            15.88952,
            "<D",
            111.8442,
            0,
        ],
        1728100080: [
            0.18996,
            0,
            0.18996,
            0,
            34.85919,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9742,
            0,
            64.06166,
            0,
            0.56373,
            0,
            18.86324,
            0,
            -0.07946,
            0,
            0,
            0,
            29.43502,
            0,
            14.87459,
            "<D",
            111.8336,
            0,
        ],
        1728100140: [
            0.29484,
            0,
            0.29484,
            0,
            29.30204,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9613,
            0,
            61.47891,
            0,
            0.57612,
            0,
            18.95197,
            0,
            -0.05297,
            0,
            0,
            0,
            29.43433,
            0,
            16.1062,
            "<D",
            111.4306,
            0,
        ],
        1728100200: [
            0.15734,
            0,
            0.15734,
            0,
            25.13617,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            67.82261,
            0,
            0.89201,
            0,
            18.88302,
            0,
            -0.04635,
            0,
            0,
            0,
            29.43443,
            0,
            13.16698,
            "<D",
            111.5508,
            0,
        ],
        1728100260: [
            0.16365,
            0,
            0.16365,
            0,
            28.99581,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            37.20028,
            0,
            0.84763,
            0,
            18.79537,
            0,
            -0.06953,
            0,
            0,
            0,
            29.43517,
            0,
            14.98372,
            "<D",
            111.5825,
            0,
        ],
        1728100320: [
            0.16816,
            0,
            0.16816,
            0,
            30.85861,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            27.99533,
            0,
            0.51933,
            0,
            18.75372,
            0,
            -0.13243,
            0,
            0,
            0,
            29.43377,
            0,
            15.31671,
            "<D",
            111.7411,
            0,
        ],
        1728100380: [
            0.13432,
            0,
            0.13432,
            0,
            28.70872,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            55.92419,
            0,
            0.38382,
            0,
            18.70892,
            0,
            -0.1854,
            0,
            0,
            0,
            29.43383,
            0,
            16.22661,
            "<D",
            111.6435,
            0,
        ],
        1728100440: [
            0.15581,
            0,
            0.15581,
            0,
            29.6268,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            50.50166,
            0,
            0.34785,
            0,
            18.53306,
            0,
            -0.20527,
            0,
            0,
            0,
            29.43314,
            0,
            15.57855,
            "<D",
            112.1123,
            0,
        ],
        1728100500: [
            0.39161,
            0,
            0.39161,
            0,
            29.86756,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8624,
            0,
            59.77659,
            0,
            0.5025,
            0,
            18.549,
            0,
            -0.21189,
            0,
            0,
            0,
            29.43353,
            0,
            15.35095,
            "<D",
            112.537,
            0,
        ],
        1728100560: [
            0.38389,
            0,
            0.38389,
            0,
            33.92115,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            53.5538,
            0,
            0.47717,
            0,
            18.44117,
            0,
            -0.20858,
            0,
            0,
            0,
            29.43311,
            0,
            15.46795,
            "<D",
            113.6809,
            0,
        ],
        1728100620: [
            0.1478,
            0,
            0.1478,
            0,
            29.19343,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            21.91144,
            0,
            0.46738,
            0,
            18.44765,
            0,
            -0.22845,
            0,
            0,
            0,
            29.43288,
            0,
            16.12725,
            "<D",
            114.0622,
            0,
        ],
        1728100680: [
            0.26814,
            0,
            0.26814,
            0,
            31.67525,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            30.8693,
            0,
            0.59697,
            0,
            18.60944,
            0,
            -0.1821,
            0,
            0,
            0,
            29.43435,
            0,
            15.97255,
            "<D",
            113.3552,
            0,
        ],
        1728100740: [
            0.26204,
            0,
            0.26204,
            0,
            33.40352,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            64.59009,
            0,
            0.40359,
            0,
            18.61329,
            0,
            -0.12581,
            0,
            0,
            0,
            29.43474,
            0,
            15.62813,
            "<D",
            113.0744,
            0,
        ],
        1728100800: [
            0.18941,
            0,
            0.18941,
            0,
            31.51139,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            45.7914,
            0,
            0.24864,
            0,
            18.53163,
            0,
            -0.12912,
            0,
            0,
            0,
            29.43493,
            0,
            16.79828,
            "<D",
            112.8708,
            0,
        ],
        1728100860: [
            0.19585,
            0,
            0.19585,
            0,
            30.80298,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            58.8263,
            0,
            0.63484,
            0,
            18.66095,
            0,
            -0.19203,
            0,
            0,
            0,
            29.43504,
            0,
            16.64692,
            "<D",
            112.5269,
            0,
        ],
        1728100920: [
            0.11077,
            0,
            0.11077,
            0,
            31.77381,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            85.05927,
            0,
            0.76399,
            0,
            18.84432,
            0,
            -0.23176,
            0,
            0,
            0,
            29.4348,
            0,
            13.9772,
            "<D",
            113.1105,
            0,
        ],
        1728100980: [
            0.07216,
            0,
            0.07216,
            0,
            31.39698,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.871,
            0,
            62.1914,
            0,
            0.66325,
            0,
            18.76726,
            0,
            -0.23507,
            0,
            0,
            0,
            29.43372,
            0,
            16.02096,
            "<D",
            113.4421,
            0,
        ],
        1728101040: [
            0.17782,
            0,
            0.17782,
            0,
            29.40332,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8624,
            0,
            133.9843,
            0,
            0.34889,
            0,
            18.59279,
            0,
            -0.19203,
            0,
            0,
            0,
            29.43347,
            0,
            15.30708,
            "<D",
            113.7373,
            0,
        ],
        1728101100: [
            0.2573,
            0,
            0.2573,
            0,
            24.07364,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            69.2849,
            0,
            0.48095,
            0,
            18.58399,
            0,
            -0.20527,
            0,
            0,
            0,
            29.43351,
            0,
            15.2421,
            "<D",
            113.6589,
            0,
        ],
        1728101160: [
            0.23835,
            0,
            0.23835,
            0,
            29.06172,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            68.66276,
            0,
            0.45022,
            0,
            18.73592,
            0,
            -0.21189,
            0,
            0,
            0,
            29.43315,
            0,
            13.92394,
            "<D",
            114.2071,
            0,
        ],
        1728101220: [
            0.20798,
            0,
            0.20798,
            0,
            25.57549,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            37.84713,
            0,
            0.56286,
            0,
            18.85659,
            0,
            -0.14237,
            0,
            0,
            0,
            29.43298,
            0,
            14.0305,
            "<D",
            114.0914,
            0,
        ],
        1728101280: [
            0.30608,
            0,
            0.30608,
            0,
            24.15733,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            69.9351,
            0,
            0.78037,
            0,
            18.86563,
            0,
            -0.09932,
            0,
            0,
            0,
            29.4327,
            0,
            15.6274,
            "<D",
            113.1089,
            0,
        ],
        1728101340: [
            0.30054,
            0,
            0.30054,
            0,
            34.97452,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8882,
            0,
            26.23192,
            0,
            0.49731,
            0,
            18.62201,
            0,
            -0.05297,
            0,
            0,
            0,
            29.43248,
            0,
            16.62935,
            "<D",
            112.6435,
            0,
        ],
        1728101400: [
            0.19543,
            0,
            0.19543,
            0,
            27.83156,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            60.07218,
            0,
            0.36075,
            0,
            18.56962,
            0,
            -0.04966,
            0,
            0,
            0,
            29.43187,
            0,
            16.12817,
            "<D",
            111.7739,
            0,
        ],
        1728101460: [
            0.2896,
            0,
            0.2896,
            0,
            29.19743,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            74.68864,
            0,
            0.60941,
            0,
            18.66194,
            0,
            -0.07615,
            0,
            0,
            0,
            29.43043,
            0,
            15.61894,
            "<D",
            112.0126,
            0,
        ],
        1728101520: [
            0.13009,
            0,
            0.13009,
            0,
            26.94214,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            44.61796,
            0,
            0.56269,
            0,
            18.6196,
            0,
            -0.06622,
            0,
            0,
            0,
            29.43012,
            0,
            15.14085,
            "<D",
            112.2069,
            0,
        ],
        1728101580: [
            0.3546,
            0,
            0.3546,
            0,
            28.31165,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            80.23047,
            0,
            0.39401,
            0,
            18.617,
            0,
            -0.06953,
            0,
            0,
            0,
            29.43084,
            0,
            15.04092,
            "<D",
            112.6919,
            0,
        ],
        1728101640: [
            0.24867,
            0,
            0.24867,
            0,
            29.01852,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            65.34602,
            0,
            0.25789,
            0,
            18.60771,
            0,
            -0.08277,
            0,
            0,
            0,
            29.43068,
            0,
            15.63281,
            "<D",
            113.0088,
            0,
        ],
        1728101700: [
            0.15237,
            0,
            0.15237,
            0,
            30.8887,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            67.89507,
            0,
            0.15676,
            0,
            18.56228,
            0,
            -0.20858,
            0,
            0,
            0,
            29.43186,
            0,
            15.92065,
            "<D",
            113.1674,
            0,
        ],
        1728101760: [
            0.21983,
            0,
            0.21983,
            0,
            32.27216,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            47.72216,
            0,
            0.13802,
            0,
            18.60747,
            0,
            -0.22514,
            0,
            0,
            0,
            29.43262,
            0,
            17.12271,
            "<D",
            112.7592,
            0,
        ],
        1728101820: [
            0.28155,
            0,
            0.28155,
            0,
            32.06427,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            44.29537,
            0,
            0.22141,
            0,
            18.71362,
            0,
            -0.22183,
            0,
            0,
            0,
            29.43244,
            0,
            15.92663,
            "<D",
            112.3249,
            0,
        ],
        1728101880: [
            0.14574,
            0,
            0.14574,
            0,
            29.625,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            34.13991,
            0,
            0.31823,
            0,
            18.76097,
            0,
            -0.19865,
            0,
            0,
            0,
            29.4323,
            0,
            15.01071,
            "<D",
            112.3574,
            0,
        ],
        1728101940: [
            0.05218,
            0,
            0.05218,
            0,
            29.72576,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            27.9474,
            0,
            0.55108,
            0,
            18.85521,
            0,
            -0.22845,
            0,
            0,
            0,
            29.43101,
            0,
            15.86569,
            "<D",
            112.6708,
            0,
        ],
        1728102000: [
            0.05022,
            0,
            0.05022,
            0,
            34.53427,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            54.77671,
            0,
            0.63088,
            0,
            18.80417,
            0,
            -0.22514,
            0,
            0,
            0,
            29.43083,
            0,
            16.79384,
            "<D",
            112.511,
            0,
        ],
        1728102060: [
            0.21142,
            0,
            0.21142,
            0,
            34.14212,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            14.0064,
            0,
            0.54506,
            0,
            18.7763,
            0,
            -0.23838,
            0,
            0,
            0,
            29.43134,
            0,
            17.7288,
            "<D",
            112.1278,
            0,
        ],
        1728102120: [
            0.16951,
            0,
            0.16951,
            0,
            34.46082,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            30.10606,
            0,
            0.28532,
            0,
            18.76964,
            0,
            -0.23507,
            0,
            0,
            0,
            29.42927,
            0,
            19.33971,
            "<D",
            112.5965,
            0,
        ],
        1728102180: [
            0.25529,
            0,
            0.25529,
            0,
            34.32221,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            42.72415,
            0,
            0.70113,
            0,
            18.78876,
            0,
            -0.23176,
            0,
            0,
            0,
            29.43033,
            0,
            18.17546,
            "<D",
            113.0863,
            0,
        ],
        1728102240: [
            0.13542,
            0,
            0.13542,
            0,
            35.29366,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            41.04404,
            0,
            0.40781,
            0,
            18.78011,
            0,
            -0.23176,
            0,
            0,
            0,
            29.43037,
            0,
            14.25014,
            "<D",
            113.4821,
            0,
        ],
        1728102300: [
            0.15465,
            0,
            0.15465,
            0,
            33.40241,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            58.05635,
            0,
            0.33624,
            0,
            18.86593,
            0,
            -0.22514,
            0,
            0,
            0,
            29.43214,
            0,
            15.69827,
            "<D",
            113.9557,
            0,
        ],
        1728102360: [
            0.19031,
            0,
            0.19031,
            0,
            32.43588,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            35.8982,
            0,
            0.21829,
            0,
            18.88639,
            0,
            -0.16223,
            0,
            0,
            0,
            29.43241,
            0,
            16.82496,
            "<D",
            113.5399,
            0,
        ],
        1728102420: [
            0.11561,
            0,
            0.11561,
            0,
            29.78501,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            35.71706,
            0,
            0.22264,
            0,
            18.87775,
            0,
            -0.06953,
            0,
            0,
            0,
            29.43219,
            0,
            11.26848,
            "<D",
            114.3463,
            0,
        ],
        1728102480: [
            0.19776,
            0,
            0.19776,
            0,
            31.41176,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            46.05193,
            0,
            0.22626,
            0,
            18.79851,
            0,
            -0.0596,
            0,
            0,
            0,
            29.4329,
            0,
            18.03072,
            "<D",
            114.0793,
            0,
        ],
        1728102540: [
            0.40909,
            0,
            0.40909,
            0,
            30.52751,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            57.57059,
            0,
            0.34775,
            0,
            18.66529,
            0,
            -0.05959,
            0,
            0,
            0,
            29.4326,
            0,
            17.06727,
            "<D",
            113.3957,
            0,
        ],
        1728102600: [
            0.19227,
            0,
            0.19227,
            0,
            25.43449,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            40.99766,
            0,
            0.38094,
            0,
            18.73428,
            0,
            -0.06291,
            0,
            0,
            0,
            29.43216,
            0,
            15.1221,
            "<D",
            112.7421,
            0,
        ],
        1728102660: [
            0.11141,
            0,
            0.11141,
            0,
            29.25546,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            38.53897,
            0,
            0.43073,
            0,
            18.67107,
            0,
            -0.16223,
            0,
            0,
            0,
            29.43057,
            0,
            17.30222,
            "<D",
            112.7463,
            0,
        ],
        1728102720: [
            0.22631,
            0,
            0.22631,
            0,
            23.87565,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            46.77055,
            0,
            0.42841,
            0,
            18.71306,
            0,
            -0.14568,
            0,
            0,
            0,
            29.43024,
            0,
            16.25236,
            "<D",
            112.8581,
            0,
        ],
        1728102780: [
            0.17396,
            0,
            0.17396,
            0,
            22.11975,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            31.19549,
            0,
            0.48356,
            0,
            18.73538,
            0,
            -0.12581,
            0,
            0,
            0,
            29.43,
            0,
            14.14649,
            "<D",
            113.0452,
            0,
        ],
        1728102840: [
            0.2009,
            0,
            0.2009,
            0,
            21.28,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            47.82101,
            0,
            0.48856,
            0,
            18.69193,
            0,
            -0.12912,
            0,
            0,
            0,
            29.42887,
            0,
            15.55433,
            "<D",
            112.9591,
            0,
        ],
        1728102900: [
            0.29698,
            0,
            0.29698,
            0,
            24.80896,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            42.57258,
            0,
            0.43611,
            0,
            18.66493,
            0,
            -0.08939,
            0,
            0,
            0,
            29.42849,
            0,
            16.42876,
            "<D",
            113.1329,
            0,
        ],
        1728102960: [
            0.33996,
            0,
            0.33996,
            0,
            26.28845,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            53.29004,
            0,
            0.52735,
            0,
            18.61173,
            0,
            -0.0927,
            0,
            0,
            0,
            29.42793,
            0,
            15.6073,
            "<D",
            113.0706,
            0,
        ],
        1728103020: [
            0.21208,
            0,
            0.21208,
            0,
            25.12106,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            87.35825,
            0,
            0.44684,
            0,
            18.51114,
            0,
            -0.09933,
            0,
            0,
            0,
            29.4292,
            0,
            16.86945,
            "<D",
            113.1082,
            0,
        ],
        1728103080: [
            0.30296,
            0,
            0.30296,
            0,
            22.86656,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            77.43522,
            0,
            0.3753,
            0,
            18.38864,
            0,
            -0.12581,
            0,
            0,
            0,
            29.43106,
            0,
            15.54793,
            "<D",
            112.9565,
            0,
        ],
        1728103140: [
            0.33175,
            0,
            0.33175,
            0,
            24.98471,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            90.2753,
            0,
            0.21789,
            0,
            18.22935,
            0,
            -0.21852,
            0,
            0,
            0,
            29.42906,
            0,
            15.6295,
            "<D",
            113.5748,
            0,
        ],
        1728103200: [
            0.20437,
            0,
            0.20437,
            0,
            26.22026,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            79.97387,
            0,
            0.2185,
            0,
            18.23309,
            0,
            -0.22183,
            0,
            0,
            0,
            29.43012,
            0,
            12.42742,
            "<D",
            113.9955,
            0,
        ],
        1728103260: [
            0.22073,
            0,
            0.22073,
            0,
            26.80194,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            84.31253,
            0,
            0.22279,
            0,
            18.28816,
            0,
            -0.23176,
            0,
            0,
            0,
            29.43051,
            0,
            16.73706,
            "<D",
            113.5667,
            0,
        ],
        1728103320: [
            0.02504,
            0,
            0.02504,
            0,
            27.44653,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            87.50491,
            0,
            0.22252,
            0,
            18.30972,
            0,
            -0.22183,
            0,
            0,
            0,
            29.42892,
            0,
            16.88064,
            "<D",
            113.0813,
            0,
        ],
        1728103380: [
            0.10787,
            0,
            0.10787,
            0,
            28.56114,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            76.70391,
            0,
            0.22366,
            0,
            18.30993,
            0,
            -0.23507,
            0,
            0,
            0,
            29.42854,
            0,
            17.17217,
            "<D",
            113.5011,
            0,
        ],
        1728103440: [
            0.2023,
            0,
            0.2023,
            0,
            29.81094,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            77.86598,
            0,
            0.2237,
            0,
            18.35218,
            0,
            -0.20196,
            0,
            0,
            0,
            29.42799,
            0,
            15.34308,
            "<D",
            113.7967,
            0,
        ],
        1728103500: [
            0.26766,
            0,
            0.26766,
            0,
            29.37209,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            70.98895,
            0,
            0.20637,
            0,
            18.34288,
            0,
            -0.22513,
            0,
            0,
            0,
            29.42834,
            0,
            16.42629,
            "<D",
            114.3406,
            0,
        ],
        1728103560: [
            0.16402,
            0,
            0.16402,
            0,
            30.24442,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            73.48992,
            0,
            0.20602,
            0,
            18.42468,
            0,
            -0.1854,
            0,
            0,
            0,
            29.42787,
            0,
            15.25083,
            "<D",
            114.6614,
            0,
        ],
        1728103620: [
            0.18331,
            0,
            0.18331,
            0,
            31.44679,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            80.0186,
            0,
            0.23143,
            0,
            18.44167,
            0,
            -0.10594,
            0,
            0,
            0,
            29.42783,
            0,
            16.27371,
            "<D",
            114.7113,
            0,
        ],
        1728103680: [
            0.25021,
            0,
            0.25021,
            0,
            34.89396,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            43.61956,
            0,
            0.36843,
            0,
            18.40896,
            0,
            -0.05628,
            0,
            0,
            0,
            29.42827,
            0,
            17.5424,
            "<D",
            114.3041,
            0,
        ],
        1728103740: [
            0.39762,
            0,
            0.39762,
            0,
            32.48001,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            36.54953,
            0,
            0.27946,
            0,
            18.36425,
            0,
            -0.08608,
            0,
            0,
            0,
            29.42783,
            0,
            13.46643,
            "<D",
            114.135,
            0,
        ],
        1728103800: [
            0.11093,
            0,
            0.11093,
            0,
            28.78794,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            25.74095,
            0,
            0.23626,
            0,
            18.3739,
            0,
            -0.12581,
            0,
            0,
            0,
            29.42756,
            0,
            17.60424,
            "<D",
            113.919,
            0,
        ],
        1728103860: [
            0.17897,
            0,
            0.17897,
            0,
            28.80611,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            47.58447,
            0,
            0.22938,
            0,
            18.39704,
            0,
            -0.22845,
            0,
            0,
            0,
            29.42756,
            0,
            15.83509,
            "<D",
            113.377,
            0,
        ],
        1728103920: [
            0.1595,
            0,
            0.1595,
            0,
            29.86913,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            64.61056,
            0,
            0.2347,
            0,
            18.35014,
            0,
            -0.22845,
            0,
            0,
            0,
            29.42741,
            0,
            15.72356,
            "<D",
            113.0261,
            0,
        ],
        1728103980: [
            0.22123,
            0,
            0.22123,
            0,
            30.43532,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            76.55429,
            0,
            0.21544,
            0,
            18.35938,
            0,
            -0.22845,
            0,
            0,
            0,
            29.42744,
            0,
            15.42065,
            "<D",
            113.156,
            0,
        ],
        1728104040: [
            0.27692,
            0,
            0.27692,
            0,
            33.82924,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            94.49207,
            0,
            0.35813,
            0,
            18.22197,
            0,
            -0.22514,
            0,
            0,
            0,
            29.42734,
            0,
            14.95641,
            "<D",
            112.5853,
            0,
        ],
        1728104100: [
            0.24871,
            0,
            0.24871,
            0,
            32.52097,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            68.49296,
            0,
            0.21889,
            0,
            18.20367,
            0,
            -0.23508,
            0,
            0,
            0,
            29.42675,
            0,
            15.23263,
            "<D",
            112.8334,
            0,
        ],
        1728104160: [
            0.18086,
            0,
            0.18086,
            0,
            31.44721,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            34.91543,
            0,
            0.223,
            0,
            18.35768,
            0,
            -0.23508,
            0,
            0,
            0,
            29.42707,
            0,
            13.22771,
            "<D",
            113.6961,
            0,
        ],
        1728104220: [
            0.17797,
            0,
            0.17797,
            0,
            34.44325,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            40.05019,
            0,
            0.23642,
            0,
            18.35293,
            0,
            -0.16223,
            0,
            0,
            0,
            29.42707,
            0,
            17.91483,
            "<D",
            113.3997,
            0,
        ],
        1728104280: [
            0.14751,
            0,
            0.14751,
            0,
            30.01075,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            44.56012,
            0,
            0.21965,
            0,
            18.45214,
            0,
            -0.14899,
            0,
            0,
            0,
            29.42561,
            0,
            14.15583,
            "<D",
            113.8278,
            0,
        ],
        1728104340: [
            0.12522,
            0,
            0.12522,
            0,
            35.89273,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            39.16495,
            0,
            0.57113,
            0,
            18.35314,
            0,
            -0.16223,
            0,
            0,
            0,
            29.427,
            0,
            16.44962,
            "<D",
            113.8256,
            0,
        ],
        1728104400: [
            0.17447,
            0,
            0.17447,
            0,
            32.95445,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            45.90768,
            0,
            0.51258,
            0,
            18.27174,
            0,
            -0.07615,
            0,
            0,
            0,
            29.4271,
            0,
            15.72208,
            "<D",
            114.1082,
            0,
        ],
        1728104460: [
            0.32618,
            0,
            0.32618,
            0,
            34.14478,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            25.90553,
            0,
            0.51564,
            0,
            18.2269,
            0,
            -0.18209,
            0,
            0,
            0,
            29.42733,
            0,
            15.52804,
            "<D",
            114.4459,
            0,
        ],
        1728104520: [
            0.29891,
            0,
            0.29891,
            0,
            35.40584,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            30.33612,
            0,
            0.22905,
            0,
            18.15876,
            0,
            -0.16223,
            0,
            0,
            0,
            29.42633,
            0,
            16.82376,
            "<D",
            114.6603,
            0,
        ],
        1728104580: [
            0.30906,
            0,
            0.30906,
            0,
            34.20875,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            41.61248,
            0,
            0.4785,
            0,
            18.24298,
            0,
            -0.17216,
            0,
            0,
            0,
            29.42511,
            0,
            16.51976,
            "<D",
            114.3664,
            0,
        ],
        1728104640: [
            0.19084,
            0,
            0.19084,
            0,
            36.18932,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            4.58109,
            0,
            0.38936,
            0,
            18.23572,
            0,
            -0.19202,
            0,
            0,
            0,
            29.4254,
            0,
            13.59306,
            "<D",
            114.4585,
            0,
        ],
        1728104700: [
            0.18075,
            0,
            0.18075,
            0,
            33.88121,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            359.8778,
            0,
            0.22961,
            0,
            18.31482,
            0,
            -0.15561,
            0,
            0,
            0,
            29.42482,
            0,
            16.44407,
            "<D",
            114.1305,
            0,
        ],
        1728104760: [
            0.25306,
            0,
            0.25306,
            0,
            34.72198,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            42.63839,
            0,
            0.45475,
            0,
            18.25787,
            0,
            -0.13243,
            0,
            0,
            0,
            29.42446,
            0,
            15.97973,
            "<D",
            114.5045,
            0,
        ],
        1728104820: [
            0.2971,
            0,
            0.2971,
            0,
            34.91512,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            9.48219,
            0,
            0.34456,
            0,
            18.21016,
            0,
            -0.16554,
            0,
            0,
            0,
            29.42417,
            0,
            17.40904,
            "<D",
            114.6741,
            0,
        ],
        1728104880: [
            0.34274,
            0,
            0.34274,
            0,
            34.15518,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            15.83665,
            0,
            0.4159,
            0,
            18.2725,
            0,
            -0.12581,
            0,
            0,
            0,
            29.42385,
            0,
            15.48997,
            "<D",
            114.7132,
            0,
        ],
        1728104940: [
            0.0883,
            0,
            0.0883,
            0,
            36.54192,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            2.95723,
            0,
            0.31272,
            0,
            18.3086,
            0,
            -0.13905,
            0,
            0,
            0,
            29.42385,
            0,
            17.18801,
            "<D",
            114.9917,
            0,
        ],
        1728105000: [
            0.14552,
            0,
            0.14552,
            0,
            37.78745,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            27.40371,
            0,
            0.20143,
            0,
            18.2706,
            0,
            -0.07615,
            0,
            0,
            0,
            29.42406,
            0,
            16.7965,
            "<D",
            114.9212,
            0,
        ],
        1728105060: [
            0.0929,
            0,
            0.0929,
            0,
            35.81526,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            90.59741,
            0,
            1.18747,
            0,
            18.46262,
            0,
            -0.07284,
            0,
            0,
            0,
            29.42401,
            0,
            16.79139,
            "<D",
            115.1874,
            0,
        ],
        1728105120: [
            0.20059,
            0,
            0.20059,
            0,
            39.6839,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            359.6986,
            0,
            0.62545,
            0,
            18.39023,
            0,
            -0.04966,
            0,
            0,
            0,
            29.42428,
            0,
            16.35921,
            "<D",
            115.4519,
            0,
        ],
        1728105180: [
            0.21162,
            0,
            0.21162,
            0,
            32.25479,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            330.7835,
            0,
            0.29093,
            0,
            18.30897,
            0,
            -0.11588,
            0,
            0,
            0,
            29.42431,
            0,
            17.35219,
            "<D",
            114.3135,
            0,
        ],
        1728105240: [
            0.21793,
            0,
            0.21793,
            0,
            34.10227,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            66.79613,
            0,
            0.74306,
            0,
            18.2804,
            0,
            -0.19203,
            0,
            0,
            0,
            29.42431,
            0,
            15.99805,
            "<D",
            114.2176,
            0,
        ],
        1728105300: [
            0.22978,
            0,
            0.22978,
            0,
            41.71731,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            8.30347,
            0,
            0.2318,
            0,
            18.25746,
            0,
            -0.22514,
            0,
            0,
            0,
            29.42431,
            0,
            16.35623,
            "<D",
            113.691,
            0,
        ],
        1728105360: [
            0.24669,
            0,
            0.24669,
            0,
            37.37733,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            167.9178,
            0,
            0.11942,
            0,
            18.2477,
            0,
            -0.22514,
            0,
            0,
            0,
            29.42431,
            0,
            16.69805,
            "<D",
            113.1779,
            0,
        ],
        1728105420: [
            0.27034,
            0,
            0.27034,
            0,
            33.0348,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            119.8834,
            0,
            0.21144,
            0,
            18.13492,
            0,
            -0.23508,
            0,
            0,
            0,
            29.42436,
            0,
            13.0668,
            "<D",
            113.325,
            0,
        ],
        1728105480: [
            0.13547,
            0,
            0.13547,
            0,
            37.83118,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            97.12321,
            0,
            0.22643,
            0,
            18.09863,
            0,
            -0.21521,
            0,
            0,
            0,
            29.42431,
            0,
            16.58629,
            "<D",
            113.5727,
            0,
        ],
        1728105540: [
            0.18106,
            0,
            0.18106,
            0,
            37.50467,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            163.1071,
            0,
            0.18799,
            0,
            18.09111,
            0,
            -0.18872,
            0,
            0,
            0,
            29.42431,
            0,
            19.32515,
            "<D",
            113.6405,
            0,
        ],
        1728105600: [
            0.1103,
            0,
            0.1103,
            0,
            37.50876,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            131.4477,
            0,
            0.27349,
            0,
            18.01633,
            0,
            -0.1821,
            0,
            0,
            0,
            29.42442,
            0,
            17.89167,
            "<D",
            113.7588,
            0,
        ],
        1728105660: [
            0.34788,
            0,
            0.34788,
            0,
            31.93817,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            80.90292,
            0,
            0.29912,
            0,
            18.08558,
            0,
            -0.07615,
            0,
            0,
            0,
            29.42426,
            0,
            14.25408,
            "<D",
            113.4442,
            0,
        ],
        1728105720: [
            0.43255,
            0,
            0.43255,
            0,
            38.18897,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            24.36073,
            0,
            0.48418,
            0,
            18.15923,
            0,
            -0.10264,
            0,
            0,
            0,
            29.4243,
            0,
            16.68867,
            "<D",
            114.0935,
            0,
        ],
        1728105780: [
            0.16719,
            0,
            0.16719,
            0,
            36.36942,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            26.90991,
            0,
            0.57517,
            0,
            18.18941,
            0,
            -0.07284,
            0,
            0,
            0,
            29.42423,
            0,
            17.93088,
            "<D",
            114.0164,
            0,
        ],
        1728105840: [
            0.14445,
            0,
            0.14445,
            0,
            36.62356,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            16.93272,
            0,
            0.41299,
            0,
            18.15588,
            0,
            -0.05628,
            0,
            0,
            0,
            29.42411,
            0,
            13.28004,
            "<D",
            114.3084,
            0,
        ],
        1728105900: [
            0.11468,
            0,
            0.11468,
            0,
            38.50902,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6732,
            0,
            40.75319,
            0,
            0.47406,
            0,
            18.09939,
            0,
            -0.05628,
            0,
            0,
            0,
            29.42397,
            0,
            16.5012,
            "<D",
            113.9057,
            0,
        ],
        1728105960: [
            0.27718,
            0,
            0.27718,
            0,
            34.00202,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            25.60853,
            0,
            0.32101,
            0,
            18.05997,
            0,
            -0.04966,
            0,
            0,
            0,
            29.4242,
            0,
            15.13976,
            "<D",
            114.4533,
            0,
        ],
        1728106020: [
            0.12939,
            0,
            0.12939,
            0,
            36.19096,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            45.09951,
            0,
            0.22451,
            0,
            17.98043,
            0,
            -0.08939,
            0,
            0,
            0,
            29.42397,
            0,
            12.9534,
            "<D",
            115.3058,
            0,
        ],
        1728106080: [
            0.24362,
            0,
            0.24362,
            0,
            38.7551,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            48.41739,
            0,
            0.22446,
            0,
            17.91822,
            0,
            -0.11257,
            0,
            0,
            0,
            29.4239,
            0,
            17.33677,
            "<D",
            114.9999,
            0,
        ],
        1728106140: [
            0.21407,
            0,
            0.21407,
            0,
            39.85099,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            49.61506,
            0,
            0.22373,
            0,
            17.87787,
            0,
            -0.09601,
            0,
            0,
            0,
            29.42412,
            0,
            15.90242,
            "<D",
            114.7463,
            0,
        ],
        1728106200: [
            0.1821,
            0,
            0.1821,
            0,
            43.28374,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            103.9355,
            0,
            0.20393,
            0,
            17.82352,
            0,
            -0.06952,
            0,
            0,
            0,
            29.42442,
            0,
            16.18348,
            "<D",
            115.0687,
            0,
        ],
        1728106260: [
            0.08049,
            0,
            0.08049,
            0,
            42.84731,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            119.2133,
            0,
            0.22422,
            0,
            17.8984,
            0,
            -0.08939,
            0,
            0,
            0,
            29.4243,
            0,
            16.39008,
            "<D",
            115.5384,
            0,
        ],
        1728106320: [
            0.23786,
            0,
            0.23786,
            0,
            42.36174,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            130.1627,
            0,
            0.22352,
            0,
            17.9319,
            0,
            -0.09601,
            0,
            0,
            0,
            29.4242,
            0,
            18.78222,
            "<D",
            115.5078,
            0,
        ],
        1728106380: [
            0.33868,
            0,
            0.33868,
            0,
            42.99288,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            138.1448,
            0,
            0.22365,
            0,
            17.8869,
            0,
            -0.16223,
            0,
            0,
            0,
            29.42431,
            0,
            18.85384,
            "<D",
            115.7954,
            0,
        ],
        1728106440: [
            0.09206,
            0,
            0.09206,
            0,
            43.28668,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            119.6934,
            0,
            0.26293,
            0,
            17.87923,
            0,
            -0.23176,
            0,
            0,
            0,
            29.42408,
            0,
            16.02371,
            "<D",
            115.7749,
            0,
        ],
        1728106500: [
            0.19403,
            0,
            0.19403,
            0,
            42.86624,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            135.2599,
            0,
            0.22062,
            0,
            17.91213,
            0,
            -0.23176,
            0,
            0,
            0,
            29.42371,
            0,
            15.75883,
            "<D",
            115.6849,
            0,
        ],
        1728106560: [
            0.4069,
            0,
            0.4069,
            0,
            43.91969,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            142.7296,
            0,
            0.22373,
            0,
            17.8113,
            0,
            -0.27149,
            0,
            0,
            0,
            29.42384,
            0,
            15.99286,
            "<D",
            114.6504,
            0,
        ],
        1728106620: [
            0.27,
            0,
            0.27,
            0,
            43.68179,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            143.7561,
            0,
            0.22338,
            0,
            17.84456,
            0,
            -0.26487,
            0,
            0,
            0,
            29.42377,
            0,
            12.50078,
            "<D",
            113.5128,
            0,
        ],
        1728106680: [
            0.18569,
            0,
            0.18569,
            0,
            43.63798,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            179.6779,
            0,
            0.2234,
            0,
            17.84338,
            0,
            -0.25163,
            0,
            0,
            0,
            29.42288,
            0,
            13.06626,
            "<D",
            112.5736,
            0,
        ],
        1728106740: [
            0.29971,
            0,
            0.29971,
            0,
            43.21504,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            187.186,
            0,
            0.16588,
            0,
            17.83846,
            0,
            -0.27812,
            0,
            0,
            0,
            29.42326,
            0,
            15.97988,
            "<D",
            112.7187,
            0,
        ],
        1728106800: [
            0.27225,
            0,
            0.27225,
            0,
            43.53257,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            121.5471,
            0,
            0.22373,
            0,
            17.88189,
            0,
            -0.29798,
            0,
            0,
            0,
            29.42375,
            0,
            13.3796,
            "<D",
            113.0509,
            0,
        ],
        1728106860: [
            0.30424,
            0,
            0.30424,
            0,
            43.42947,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            115.5297,
            0,
            0.22135,
            0,
            17.84598,
            0,
            -0.31453,
            0,
            0,
            0,
            29.42323,
            0,
            16.7844,
            "<D",
            112.7466,
            0,
        ],
        1728106920: [
            0.22867,
            0,
            0.22867,
            0,
            43.79498,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            106.5585,
            0,
            0.22373,
            0,
            17.88906,
            0,
            -0.36088,
            0,
            0,
            0,
            29.42431,
            0,
            18.46226,
            "<D",
            112.3622,
            0,
        ],
        1728106980: [
            0.23256,
            0,
            0.23256,
            0,
            43.37439,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            105.9626,
            0,
            0.22449,
            0,
            17.90156,
            0,
            -0.35095,
            0,
            0,
            0,
            29.42408,
            0,
            14.98765,
            "<D",
            112.7262,
            0,
        ],
        1728107040: [
            0.06759,
            0,
            0.06759,
            0,
            43.27692,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            106.4968,
            0,
            0.22205,
            0,
            17.86444,
            0,
            -0.26486,
            0,
            0,
            0,
            29.42419,
            0,
            15.63297,
            "<D",
            112.2803,
            0,
        ],
        1728107100: [
            0.14897,
            0,
            0.14897,
            0,
            42.9929,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            86.56806,
            0,
            0.22361,
            0,
            17.88078,
            0,
            -0.13906,
            0,
            0,
            0,
            29.42431,
            0,
            17.12377,
            "<D",
            111.8206,
            0,
        ],
        1728107160: [
            0.25234,
            0,
            0.25234,
            0,
            42.67709,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            77.31354,
            0,
            0.22294,
            0,
            17.8875,
            0,
            -0.04966,
            0,
            0,
            0,
            29.42418,
            0,
            14.95293,
            "<D",
            112.6588,
            0,
        ],
        1728107220: [
            0.17988,
            0,
            0.17988,
            0,
            42.49492,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            64.01482,
            0,
            0.24902,
            0,
            17.8695,
            0,
            -0.0629,
            0,
            0,
            0,
            29.42419,
            0,
            14.25589,
            "<D",
            113.0321,
            0,
        ],
        1728107280: [
            -0.00468,
            0,
            -0.00468,
            0,
            42.58715,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            42.70969,
            0,
            0.24293,
            0,
            17.71244,
            0,
            -0.07615,
            0,
            0,
            0,
            29.42405,
            0,
            14.18637,
            "<D",
            113.1078,
            0,
        ],
        1728107340: [
            0.24294,
            0,
            0.24294,
            0,
            42.68414,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            38.40977,
            0,
            0.22269,
            0,
            17.5648,
            0,
            -0.08277,
            0,
            0,
            0,
            29.42397,
            0,
            16.30371,
            "<D",
            113.0742,
            0,
        ],
        1728107400: [
            0.18083,
            0,
            0.18083,
            0,
            42.63898,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            51.04902,
            0,
            0.22212,
            0,
            17.49356,
            0,
            -0.11919,
            0,
            0,
            0,
            29.42397,
            0,
            14.94954,
            "<D",
            113.3067,
            0,
        ],
        1728107460: [
            0.25718,
            0,
            0.25718,
            0,
            42.03679,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            39.16339,
            0,
            0.22,
            0,
            17.48287,
            0,
            -0.05959,
            0,
            0,
            0,
            29.42412,
            0,
            15.69524,
            "<D",
            113.7205,
            0,
        ],
        1728107520: [
            0.19723,
            0,
            0.19723,
            0,
            41.36738,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            42.82532,
            0,
            0.22343,
            0,
            17.53961,
            0,
            -0.09932,
            0,
            0,
            0,
            29.42395,
            0,
            15.06893,
            "<D",
            113.9643,
            0,
        ],
        1728107580: [
            0.10832,
            0,
            0.10832,
            0,
            40.67593,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            31.17338,
            0,
            0.22224,
            0,
            17.62147,
            0,
            -0.0629,
            0,
            0,
            0,
            29.42423,
            0,
            16.03521,
            "<D",
            113.4858,
            0,
        ],
        1728107640: [
            0.25625,
            0,
            0.25625,
            0,
            39.70684,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            27.18635,
            0,
            0.21872,
            0,
            17.67232,
            0,
            -0.10926,
            0,
            0,
            0,
            29.42431,
            0,
            14.421,
            "<D",
            113.8113,
            0,
        ],
        1728107700: [
            0.04467,
            0,
            0.04467,
            0,
            35.883,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            45.06137,
            0,
            0.33556,
            0,
            17.7015,
            0,
            -0.13906,
            0,
            0,
            0,
            29.42431,
            0,
            15.48983,
            "<D",
            113.8357,
            0,
        ],
        1728107760: [
            0.18913,
            0,
            0.18913,
            0,
            34.91977,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            47.81106,
            0,
            0.37463,
            0,
            17.68022,
            0,
            -0.19534,
            0,
            0,
            0,
            29.42431,
            0,
            16.27546,
            "<D",
            113.8733,
            0,
        ],
        1728107820: [
            0.13645,
            0,
            0.13645,
            0,
            37.03121,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            51.5747,
            0,
            0.31186,
            0,
            17.67051,
            0,
            -0.23176,
            0,
            0,
            0,
            29.42431,
            0,
            15.24228,
            "<D",
            113.5045,
            0,
        ],
        1728107880: [
            0.1458,
            0,
            0.1458,
            0,
            36.48616,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            33.35243,
            0,
            0.30289,
            0,
            17.70996,
            0,
            -0.23508,
            0,
            0,
            0,
            29.42489,
            0,
            14.64356,
            "<D",
            113.1215,
            0,
        ],
        1728107940: [
            0.12117,
            0,
            0.12117,
            0,
            37.28374,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            52.87959,
            0,
            0.23118,
            0,
            17.63186,
            0,
            -0.2417,
            0,
            0,
            0,
            29.42444,
            0,
            15.56415,
            "<D",
            112.4073,
            0,
        ],
        1728108000: [
            0.39735,
            0,
            0.39735,
            0,
            36.98715,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            56.56786,
            0,
            0.32163,
            0,
            17.55249,
            0,
            -0.2417,
            0,
            0,
            0,
            29.42544,
            0,
            15.19672,
            "<D",
            111.6422,
            0,
        ],
        1728108060: [
            0.25538,
            0,
            0.25538,
            0,
            38.0951,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            43.92813,
            0,
            0.2502,
            0,
            17.50457,
            0,
            -0.23839,
            0,
            0,
            0,
            29.42474,
            0,
            16.50952,
            "<D",
            111.5373,
            0,
        ],
        1728108120: [
            0.08779,
            0,
            0.08779,
            0,
            39.04945,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            29.427,
            0,
            0.28168,
            0,
            17.5635,
            0,
            -0.21521,
            0,
            0,
            0,
            29.42642,
            0,
            15.69361,
            "<D",
            111.8549,
            0,
        ],
        1728108180: [
            0.37375,
            0,
            0.37375,
            0,
            38.12368,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            43.72588,
            0,
            0.20546,
            0,
            17.59525,
            0,
            -0.22183,
            0,
            0,
            0,
            29.42539,
            0,
            16.6979,
            "<D",
            112.2815,
            0,
        ],
        1728108240: [
            0.0446,
            0,
            0.0446,
            0,
            39.35547,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            63.96009,
            0,
            0.20875,
            0,
            17.61553,
            0,
            -0.19534,
            0,
            0,
            0,
            29.42588,
            0,
            15.36102,
            "<D",
            112.6413,
            0,
        ],
        1728108300: [
            0.09528,
            0,
            0.09528,
            0,
            37.58389,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            73.6006,
            0,
            0.22365,
            0,
            17.56889,
            0,
            -0.17548,
            0,
            0,
            0,
            29.42534,
            0,
            14.97131,
            "<D",
            112.6479,
            0,
        ],
        1728108360: [
            0.08751,
            0,
            0.08751,
            0,
            37.11549,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            48.37549,
            0,
            0.19144,
            0,
            17.57892,
            0,
            -0.1225,
            0,
            0,
            0,
            29.42496,
            0,
            15.5065,
            "<D",
            112.6724,
            0,
        ],
        1728108420: [
            0.21076,
            0,
            0.21076,
            0,
            38.38085,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            46.48659,
            0,
            0.33419,
            0,
            17.59083,
            0,
            -0.07615,
            0,
            0,
            0,
            29.42474,
            0,
            15.41883,
            "<D",
            112.4693,
            0,
        ],
        1728108480: [
            0.25153,
            0,
            0.25153,
            0,
            41.49435,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            42.49892,
            0,
            0.30452,
            0,
            17.57412,
            0,
            -0.10263,
            0,
            0,
            0,
            29.42416,
            0,
            12.85894,
            "<D",
            112.6074,
            0,
        ],
        1728108540: [
            0.1921,
            0,
            0.1921,
            0,
            43.89236,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            40.6245,
            0,
            0.21355,
            0,
            17.59326,
            0,
            -0.06622,
            0,
            0,
            0,
            29.42397,
            0,
            14.7276,
            "<D",
            112.706,
            0,
        ],
        1728108600: [
            0.33531,
            0,
            0.33531,
            0,
            42.56707,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            42.70093,
            0,
            0.22794,
            0,
            17.59302,
            0,
            -0.08939,
            0,
            0,
            0,
            29.42425,
            0,
            16.73958,
            "<D",
            112.3053,
            0,
        ],
        1728108660: [
            0.2128,
            0,
            0.2128,
            0,
            44.57518,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            47.89399,
            0,
            0.21848,
            0,
            17.59701,
            0,
            -0.1523,
            0,
            0,
            0,
            29.42431,
            0,
            14.4715,
            "<D",
            112.7549,
            0,
        ],
        1728108720: [
            0.17829,
            0,
            0.17829,
            0,
            45.917,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            68.7857,
            0,
            0.19434,
            0,
            17.60393,
            0,
            -0.09932,
            0,
            0,
            0,
            29.42396,
            0,
            15.32738,
            "<D",
            113.1063,
            0,
        ],
        1728108780: [
            0.19687,
            0,
            0.19687,
            0,
            47.88016,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            59.99458,
            0,
            0.22449,
            0,
            17.6092,
            0,
            -0.09601,
            0,
            0,
            0,
            29.42401,
            0,
            14.94779,
            "<D",
            113.3304,
            0,
        ],
        1728108840: [
            0.32293,
            0,
            0.32293,
            0,
            48.03148,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            60.53959,
            0,
            0.22371,
            0,
            17.60505,
            0,
            -0.0629,
            0,
            0,
            0,
            29.42372,
            0,
            17.85997,
            "<D",
            113.3147,
            0,
        ],
        1728108900: [
            0.27048,
            0,
            0.27048,
            0,
            49.71699,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            58.51709,
            0,
            0.22373,
            0,
            17.59921,
            0,
            -0.06621,
            0,
            0,
            0,
            29.42366,
            0,
            18.33681,
            "<D",
            113.4467,
            0,
        ],
        1728108960: [
            0.26925,
            0,
            0.26925,
            0,
            50.07537,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            69.66852,
            0,
            0.21983,
            0,
            17.57454,
            0,
            -0.04966,
            0,
            0,
            0,
            29.42421,
            0,
            15.20257,
            "<D",
            113.6823,
            0,
        ],
        1728109020: [
            0.28635,
            0,
            0.28635,
            0,
            50.57288,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            14.88648,
            0,
            0.05713,
            0,
            17.56006,
            0,
            -0.06953,
            0,
            0,
            0,
            29.42384,
            0,
            14.47069,
            "<D",
            113.9282,
            0,
        ],
        1728109080: [
            0.23966,
            0,
            0.23966,
            0,
            50.83265,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            271.8877,
            0,
            0.22216,
            0,
            17.59826,
            0,
            -0.06953,
            0,
            0,
            0,
            29.42294,
            0,
            16.11036,
            "<D",
            113.6545,
            0,
        ],
        1728109140: [
            0.31417,
            0,
            0.31417,
            0,
            50.45586,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            198.6009,
            0,
            0.10794,
            0,
            17.55909,
            0,
            -0.16223,
            0,
            0,
            0,
            29.42334,
            0,
            17.89992,
            "<D",
            113.6539,
            0,
        ],
        1728109200: [
            0.23718,
            0,
            0.23718,
            0,
            50.034,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            290.6556,
            0,
            0.24018,
            0,
            17.59996,
            0,
            -0.21852,
            0,
            0,
            0,
            29.42339,
            0,
            12.65442,
            "<D",
            114.2726,
            0,
        ],
        1728109260: [
            0.2516,
            0,
            0.2516,
            0,
            50.12211,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            70.66212,
            0,
            0.177,
            0,
            17.62177,
            0,
            -0.23839,
            0,
            0,
            0,
            29.42339,
            0,
            18.42239,
            "<D",
            113.3346,
            0,
        ],
        1728109320: [
            0.11409,
            0,
            0.11409,
            0,
            50.50451,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            74.71107,
            0,
            0.47179,
            0,
            17.71666,
            0,
            -0.23839,
            0,
            0,
            0,
            29.42218,
            0,
            15.09407,
            "<D",
            112.7444,
            0,
        ],
        1728109380: [
            0.26358,
            0,
            0.26358,
            0,
            52.04998,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            51.25105,
            0,
            0.28362,
            0,
            17.6997,
            0,
            -0.22183,
            0,
            0,
            0,
            29.42171,
            0,
            14.32288,
            "<D",
            112.5787,
            0,
        ],
        1728109440: [
            0.19174,
            0,
            0.19174,
            0,
            50.27154,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            55.38553,
            0,
            0.30364,
            0,
            17.68757,
            0,
            -0.19534,
            0,
            0,
            0,
            29.42237,
            0,
            16.80046,
            "<D",
            111.5592,
            0,
        ],
        1728109500: [
            0.30783,
            0,
            0.30783,
            0,
            50.71969,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            44.29782,
            0,
            0.27156,
            0,
            17.71851,
            0,
            -0.22183,
            0,
            0,
            0,
            29.42109,
            0,
            16.68627,
            "<D",
            111.7803,
            0,
        ],
        1728109560: [
            0.13506,
            0,
            0.13506,
            0,
            50.48065,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            42.92192,
            0,
            0.30189,
            0,
            17.62251,
            0,
            -0.22183,
            0,
            0,
            0,
            29.42165,
            0,
            16.68162,
            "<D",
            111.3958,
            0,
        ],
        1728109620: [
            0.25142,
            0,
            0.25142,
            0,
            47.58104,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            59.91013,
            0,
            0.32724,
            0,
            17.42127,
            0,
            -0.22514,
            0,
            0,
            0,
            29.42126,
            0,
            13.83534,
            "<D",
            112.3922,
            0,
        ],
        1728109680: [
            0.18874,
            0,
            0.18874,
            0,
            47.67679,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            55.21951,
            0,
            0.21709,
            0,
            17.36398,
            0,
            -0.22845,
            0,
            0,
            0,
            29.42113,
            0,
            15.30135,
            "<D",
            112.6159,
            0,
        ],
        1728109740: [
            0.10515,
            0,
            0.10515,
            0,
            33.49829,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            19.47277,
            0,
            0.16042,
            0,
            17.42528,
            0,
            -0.17548,
            0,
            0,
            0,
            29.42117,
            0,
            13.18373,
            "<D",
            113.2429,
            0,
        ],
        1728109800: [
            0.2045,
            0,
            0.2045,
            0,
            42.35904,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            20.35372,
            0,
            0.21,
            0,
            17.46499,
            0,
            -0.06621,
            0,
            0,
            0,
            29.42067,
            0,
            16.46166,
            "<D",
            113.0474,
            0,
        ],
        1728109860: [
            0.21209,
            0,
            0.21209,
            0,
            45.12285,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            41.02797,
            0,
            0.22278,
            0,
            17.48088,
            0,
            -0.05297,
            0,
            0,
            0,
            29.42064,
            0,
            15.60154,
            "<D",
            113.1025,
            0,
        ],
        1728109920: [
            0.18607,
            0,
            0.18607,
            0,
            43.71092,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            9.03484,
            0,
            0.22082,
            0,
            17.47902,
            0,
            -0.09932,
            0,
            0,
            0,
            29.42053,
            0,
            15.42203,
            "<D",
            113.2953,
            0,
        ],
        1728109980: [
            0.13309,
            0,
            0.13309,
            0,
            44.34705,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            35.32582,
            0,
            0.21883,
            0,
            17.43646,
            0,
            -0.08277,
            0,
            0,
            0,
            29.42041,
            0,
            15.36695,
            "<D",
            113.3524,
            0,
        ],
        1728110040: [
            0.23785,
            0,
            0.23785,
            0,
            42.93022,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            11.18796,
            0,
            0.21887,
            0,
            17.42644,
            0,
            -0.07283,
            0,
            0,
            0,
            29.42034,
            0,
            16.02093,
            "<D",
            113.1966,
            0,
        ],
        1728110100: [
            0.42444,
            0,
            0.42444,
            0,
            42.03195,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            2.86249,
            0,
            0.2201,
            0,
            17.40413,
            0,
            -0.04304,
            0,
            0,
            0,
            29.42035,
            0,
            15.28231,
            "<D",
            113.0992,
            0,
        ],
        1728110160: [
            0.2973,
            0,
            0.2973,
            0,
            40.98053,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            14.91486,
            0,
            0.26293,
            0,
            17.38768,
            0,
            -0.07284,
            0,
            0,
            0,
            29.42044,
            0,
            15.92603,
            "<D",
            113.3366,
            0,
        ],
        1728110220: [
            0.2655,
            0,
            0.2655,
            0,
            40.11031,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            358.1396,
            0,
            0.62256,
            0,
            17.36367,
            0,
            -0.05959,
            0,
            0,
            0,
            29.4204,
            0,
            15.69528,
            "<D",
            113.1645,
            0,
        ],
        1728110280: [
            0.22553,
            0,
            0.22553,
            0,
            37.36323,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            2.37711,
            0,
            0.59716,
            0,
            17.35385,
            0,
            -0.05628,
            0,
            0,
            0,
            29.42043,
            0,
            13.41459,
            "<D",
            113.302,
            0,
        ],
        1728110340: [
            0.13018,
            0,
            0.13018,
            0,
            38.09703,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            19.15833,
            0,
            0.57751,
            0,
            17.33257,
            0,
            -0.10926,
            0,
            0,
            0,
            29.42063,
            0,
            16.01971,
            "<D",
            113.2132,
            0,
        ],
        1728110400: [
            0.11529,
            0,
            0.11529,
            0,
            37.74578,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            14.63838,
            0,
            0.56611,
            0,
            17.31376,
            0,
            -0.04966,
            0,
            0,
            0,
            29.42042,
            0,
            15.8804,
            "<D",
            113.2882,
            0,
        ],
        1728110460: [
            0.1494,
            0,
            0.1494,
            0,
            37.81238,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            37.05163,
            0,
            0.3562,
            0,
            17.22379,
            0,
            -0.04966,
            0,
            0,
            0,
            29.4208,
            0,
            15.73113,
            "<D",
            113.4351,
            0,
        ],
        1728110520: [
            0.11772,
            0,
            0.11772,
            0,
            38.44461,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            45.2042,
            0,
            0.39093,
            0,
            17.23105,
            0,
            -0.05628,
            0,
            0,
            0,
            29.4203,
            0,
            14.45313,
            "<D",
            113.6142,
            0,
        ],
        1728110580: [
            0.33695,
            0,
            0.33695,
            0,
            38.74871,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            44.18279,
            0,
            0.33657,
            0,
            17.19643,
            0,
            -0.06953,
            0,
            0,
            0,
            29.42071,
            0,
            15.54084,
            "<D",
            113.3545,
            0,
        ],
        1728110640: [
            0.23299,
            0,
            0.23299,
            0,
            39.98643,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            44.78428,
            0,
            0.34304,
            0,
            17.18622,
            0,
            -0.08939,
            0,
            0,
            0,
            29.42061,
            0,
            14.97531,
            "<D",
            113.7006,
            0,
        ],
        1728110700: [
            0.16134,
            0,
            0.16134,
            0,
            38.34399,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            20.46796,
            0,
            0.31814,
            0,
            17.17875,
            0,
            -0.19866,
            0,
            0,
            0,
            29.42102,
            0,
            14.21209,
            "<D",
            114.0567,
            0,
        ],
        1728110760: [
            0.27825,
            0,
            0.27825,
            0,
            38.56562,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            31.21572,
            0,
            0.55494,
            0,
            17.14261,
            0,
            -0.23839,
            0,
            0,
            0,
            29.42106,
            0,
            14.80552,
            "<D",
            113.784,
            0,
        ],
        1728110820: [
            0.25965,
            0,
            0.25965,
            0,
            38.01817,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            351.9456,
            0,
            0.45228,
            0,
            17.13699,
            0,
            -0.23839,
            0,
            0,
            0,
            29.42083,
            0,
            16.16636,
            "<D",
            113.1097,
            0,
        ],
        1728110880: [
            0.25079,
            0,
            0.25079,
            0,
            35.84466,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            41.23626,
            0,
            0.38431,
            0,
            17.04478,
            0,
            -0.23176,
            0,
            0,
            0,
            29.42105,
            0,
            18.95654,
            "<D",
            112.3862,
            0,
        ],
        1728110940: [
            0.27776,
            0,
            0.27776,
            0,
            37.82629,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            56.41611,
            0,
            0.2636,
            0,
            17.18822,
            0,
            -0.2417,
            0,
            0,
            0,
            29.42105,
            0,
            12.84638,
            "<D",
            111.7397,
            0,
        ],
        1728111000: [
            0.10847,
            0,
            0.10847,
            0,
            38.36136,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            59.33113,
            0,
            0.33609,
            0,
            17.15532,
            0,
            -0.23508,
            0,
            0,
            0,
            29.42008,
            0,
            14.99713,
            "<D",
            112.6114,
            0,
        ],
        1728111060: [
            0.03364,
            0,
            0.03364,
            0,
            39.68365,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            71.49287,
            0,
            0.23684,
            0,
            17.12559,
            0,
            -0.22183,
            0,
            0,
            0,
            29.41819,
            0,
            14.82866,
            "<D",
            112.8032,
            0,
        ],
        1728111120: [
            0.35505,
            0,
            0.35505,
            0,
            37.24589,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            61.87956,
            0,
            0.25067,
            0,
            17.21879,
            0,
            -0.11919,
            0,
            0,
            0,
            29.41787,
            0,
            18.45061,
            "<D",
            113.4764,
            0,
        ],
        1728111180: [
            0.15686,
            0,
            0.15686,
            0,
            36.79764,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            55.34925,
            0,
            0.22452,
            0,
            17.20363,
            0,
            -0.0927,
            0,
            0,
            0,
            29.41791,
            0,
            14.57139,
            "<D",
            113.5844,
            0,
        ],
        1728111240: [
            0.16936,
            0,
            0.16936,
            0,
            35.68456,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            75.3498,
            0,
            0.22177,
            0,
            17.24104,
            0,
            -0.06621,
            0,
            0,
            0,
            29.41935,
            0,
            15.31208,
            "<D",
            113.6848,
            0,
        ],
        1728111300: [
            0.20941,
            0,
            0.20941,
            0,
            38.15101,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            61.73182,
            0,
            0.22369,
            0,
            17.29359,
            0,
            -0.17878,
            0,
            0,
            0,
            29.41783,
            0,
            15.27096,
            "<D",
            113.8514,
            0,
        ],
        1728111360: [
            0.16111,
            0,
            0.16111,
            0,
            36.99883,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            54.54902,
            0,
            0.22328,
            0,
            17.28879,
            0,
            -0.24831,
            0,
            0,
            0,
            29.41887,
            0,
            16.54736,
            "<D",
            113.9837,
            0,
        ],
        1728111420: [
            0.23724,
            0,
            0.23724,
            0,
            37.47806,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            49.01346,
            0,
            0.22359,
            0,
            17.28736,
            0,
            -0.23838,
            0,
            0,
            0,
            29.41768,
            0,
            13.68545,
            "<D",
            114.1958,
            0,
        ],
        1728111480: [
            0.25961,
            0,
            0.25961,
            0,
            39.49598,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            28.82239,
            0,
            0.27681,
            0,
            17.26728,
            0,
            -0.24831,
            0,
            0,
            0,
            29.41777,
            0,
            18.78597,
            "<D",
            114.253,
            0,
        ],
        1728111540: [
            0.25626,
            0,
            0.25626,
            0,
            43.00562,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            81.67709,
            0,
            0.21615,
            0,
            17.23679,
            0,
            -0.2748,
            0,
            0,
            0,
            29.41755,
            0,
            14.93059,
            "<D",
            114.1691,
            0,
        ],
        1728111600: [
            0.14899,
            0,
            0.14899,
            0,
            46.02462,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            83.86764,
            0,
            0.2215,
            0,
            17.2003,
            0,
            -0.2748,
            0,
            0,
            0,
            29.4176,
            0,
            14.92282,
            "<D",
            114.3207,
            0,
        ],
        1728111660: [
            0.21997,
            0,
            0.21997,
            0,
            49.17351,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            130.5313,
            0,
            0.22587,
            0,
            17.15949,
            0,
            -0.30128,
            0,
            0,
            0,
            29.41701,
            0,
            17.45653,
            "<D",
            114.2923,
            0,
        ],
        1728111720: [
            0.39352,
            0,
            0.39352,
            0,
            50.03658,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            138.9961,
            0,
            0.22452,
            0,
            17.16161,
            0,
            -0.29797,
            0,
            0,
            0,
            29.41747,
            0,
            12.20433,
            "<D",
            114.6639,
            0,
        ],
        1728111780: [
            0.20629,
            0,
            0.20629,
            0,
            49.91304,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            136.2846,
            0,
            0.22362,
            0,
            17.18007,
            0,
            -0.3079,
            0,
            0,
            0,
            29.41734,
            0,
            16.37708,
            "<D",
            114.2209,
            0,
        ],
        1728111840: [
            0.29652,
            0,
            0.29652,
            0,
            50.57166,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            128.2536,
            0,
            0.21982,
            0,
            17.13321,
            0,
            -0.26487,
            0,
            0,
            0,
            29.41765,
            0,
            14.65003,
            "<D",
            114.3076,
            0,
        ],
        1728111900: [
            0.22025,
            0,
            0.22025,
            0,
            50.41968,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            111.0155,
            0,
            0.21788,
            0,
            17.2026,
            0,
            -0.22845,
            0,
            0,
            0,
            29.41709,
            0,
            15.96875,
            "<D",
            113.8904,
            0,
        ],
        1728111960: [
            0.375,
            0,
            0.375,
            0,
            51.36585,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            119.464,
            0,
            0.21981,
            0,
            17.20794,
            0,
            -0.245,
            0,
            0,
            0,
            29.41542,
            0,
            13.25378,
            "<D",
            114.5455,
            0,
        ],
        1728112020: [
            0.23364,
            0,
            0.23364,
            0,
            50.47277,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            133.6754,
            0,
            0.21552,
            0,
            17.236,
            0,
            -0.22182,
            0,
            0,
            0,
            29.41573,
            0,
            16.51947,
            "<D",
            114.3403,
            0,
        ],
        1728112080: [
            0.11655,
            0,
            0.11655,
            0,
            50.67411,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            120.9334,
            0,
            0.22364,
            0,
            17.18258,
            0,
            -0.10926,
            0,
            0,
            0,
            29.41434,
            0,
            16.35766,
            "<D",
            113.4683,
            0,
        ],
        1728112140: [
            0.33637,
            0,
            0.33637,
            0,
            50.22312,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            121.3267,
            0,
            0.22373,
            0,
            17.18044,
            0,
            -0.07284,
            0,
            0,
            0,
            29.41368,
            0,
            13.15549,
            "<D",
            114.0021,
            0,
        ],
        1728112200: [
            0.2655,
            0,
            0.2655,
            0,
            49.68264,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            121.309,
            0,
            0.22373,
            0,
            17.12196,
            0,
            -0.04635,
            0,
            0,
            0,
            29.41354,
            0,
            16.78452,
            "<D",
            113.7275,
            0,
        ],
        1728112260: [
            0.14557,
            0,
            0.14557,
            0,
            49.6377,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            120.9245,
            0,
            0.22373,
            0,
            17.112,
            0,
            -0.05297,
            0,
            0,
            0,
            29.41368,
            0,
            15.47069,
            "<D",
            113.9782,
            0,
        ],
        1728112320: [
            0.42797,
            0,
            0.42797,
            0,
            49.64153,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            121.1755,
            0,
            0.22373,
            0,
            17.13246,
            0,
            -0.10595,
            0,
            0,
            0,
            29.4129,
            0,
            16.92614,
            "<D",
            114.142,
            0,
        ],
        1728112380: [
            0.16432,
            0,
            0.16432,
            0,
            48.67675,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            121.7963,
            0,
            0.22373,
            0,
            17.13429,
            0,
            -0.10595,
            0,
            0,
            0,
            29.4131,
            0,
            14.4467,
            "<D",
            114.2396,
            0,
        ],
        1728112440: [
            0.16764,
            0,
            0.16764,
            0,
            47.94349,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            121.5439,
            0,
            0.22373,
            0,
            17.12996,
            0,
            -0.20528,
            0,
            0,
            0,
            29.41281,
            0,
            15.77857,
            "<D",
            113.6572,
            0,
        ],
        1728112500: [
            0.14203,
            0,
            0.14203,
            0,
            47.47043,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            121.9239,
            0,
            0.22372,
            0,
            17.04021,
            0,
            -0.1821,
            0,
            0,
            0,
            29.41125,
            0,
            16.24893,
            "<D",
            113.3576,
            0,
        ],
        1728112560: [
            0.1855,
            0,
            0.1855,
            0,
            47.21217,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            137.5002,
            0,
            0.2191,
            0,
            16.89515,
            0,
            -0.19866,
            0,
            0,
            0,
            29.41042,
            0,
            15.93577,
            "<D",
            112.6608,
            0,
        ],
        1728112620: [
            0.1494,
            0,
            0.1494,
            0,
            46.69048,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            121.9507,
            0,
            0.22373,
            0,
            16.88778,
            0,
            -0.23839,
            0,
            0,
            0,
            29.41103,
            0,
            15.32983,
            "<D",
            113.8888,
            0,
        ],
        1728112680: [
            0.17678,
            0,
            0.17678,
            0,
            46.52362,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            110.9103,
            0,
            0.22286,
            0,
            16.81297,
            0,
            -0.22514,
            0,
            0,
            0,
            29.41075,
            0,
            16.38866,
            "<D",
            114.2218,
            0,
        ],
        1728112740: [
            0.29123,
            0,
            0.29123,
            0,
            45.55537,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            96.74867,
            0,
            0.22421,
            0,
            16.84253,
            0,
            -0.22183,
            0,
            0,
            0,
            29.41114,
            0,
            17.37284,
            "<D",
            114.3462,
            0,
        ],
        1728112800: [
            0.08285,
            0,
            0.08285,
            0,
            45.74853,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            96.00352,
            0,
            0.22453,
            0,
            16.76015,
            0,
            -0.13906,
            0,
            0,
            0,
            29.41068,
            0,
            16.53893,
            "<D",
            114.6455,
            0,
        ],
        1728112860: [
            0.25217,
            0,
            0.25217,
            0,
            46.11014,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            90.64469,
            0,
            0.22451,
            0,
            16.63364,
            0,
            -0.11257,
            0,
            0,
            0,
            29.4111,
            0,
            14.03854,
            "<D",
            114.5694,
            0,
        ],
        1728112920: [
            0.31964,
            0,
            0.31964,
            0,
            46.64732,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            87.12333,
            0,
            0.22168,
            0,
            16.59164,
            0,
            -0.05297,
            0,
            0,
            0,
            29.41077,
            0,
            16.0559,
            "<D",
            114.7918,
            0,
        ],
        1728112980: [
            0.16825,
            0,
            0.16825,
            0,
            46.51799,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            73.97443,
            0,
            0.24668,
            0,
            16.43613,
            0,
            -0.05959,
            0,
            0,
            0,
            29.41076,
            0,
            15.89174,
            "<D",
            114.7222,
            0,
        ],
        1728113040: [
            0.32816,
            0,
            0.32816,
            0,
            47.00327,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            64.41056,
            0,
            0.25159,
            0,
            16.31193,
            0,
            -0.0629,
            0,
            0,
            0,
            29.41057,
            0,
            16.20006,
            "<D",
            114.3099,
            0,
        ],
        1728113100: [
            0.0769,
            0,
            0.0769,
            0,
            47.11177,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            67.48909,
            0,
            0.3799,
            0,
            16.19574,
            0,
            -0.09932,
            0,
            0,
            0,
            29.41215,
            0,
            16.0063,
            "<D",
            114.6185,
            0,
        ],
        1728113160: [
            0.20888,
            0,
            0.20888,
            0,
            46.65968,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            52.53827,
            0,
            0.38947,
            0,
            16.16733,
            0,
            -0.20527,
            0,
            0,
            0,
            29.41232,
            0,
            16.52083,
            "<D",
            114.6831,
            0,
        ],
        1728113220: [
            0.20298,
            0,
            0.20298,
            0,
            47.20343,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            57.38773,
            0,
            0.22115,
            0,
            16.15506,
            0,
            -0.17216,
            0,
            0,
            0,
            29.41152,
            0,
            15.71759,
            "<D",
            114.687,
            0,
        ],
        1728113280: [
            0.30405,
            0,
            0.30405,
            0,
            48.10894,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            65.76015,
            0,
            0.22364,
            0,
            16.24538,
            0,
            -0.22514,
            0,
            0,
            0,
            29.41103,
            0,
            16.19135,
            "<D",
            114.7872,
            0,
        ],
        1728113340: [
            0.198,
            0,
            0.198,
            0,
            42.89125,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            70.29993,
            0,
            0.2234,
            0,
            16.42414,
            0,
            -0.21851,
            0,
            0,
            0,
            29.41044,
            0,
            17.6728,
            "<D",
            114.6687,
            0,
        ],
        1728113400: [
            0.20786,
            0,
            0.20786,
            0,
            27.49653,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            69.3606,
            0,
            0.22345,
            0,
            16.50668,
            0,
            -0.21851,
            0,
            0,
            0,
            29.41042,
            0,
            16.28877,
            "<D",
            114.9931,
            0,
        ],
        1728113460: [
            0.22563,
            0,
            0.22563,
            0,
            33.66624,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            73.16492,
            0,
            0.22361,
            0,
            16.38769,
            0,
            -0.2152,
            0,
            0,
            0,
            29.41064,
            0,
            16.34403,
            "<D",
            115.0981,
            0,
        ],
        1728113520: [
            0.3052,
            0,
            0.3052,
            0,
            38.08619,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            71.91737,
            0,
            0.22342,
            0,
            16.43491,
            0,
            -0.16885,
            0,
            0,
            0,
            29.41068,
            0,
            16.47988,
            "<D",
            114.1195,
            0,
        ],
        1728113580: [
            0.32592,
            0,
            0.32592,
            0,
            38.62942,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            73.54457,
            0,
            0.22337,
            0,
            16.55372,
            0,
            -0.08939,
            0,
            0,
            0,
            29.40999,
            0,
            14.39394,
            "<D",
            114.19,
            0,
        ],
        1728113640: [
            0.33697,
            0,
            0.33697,
            0,
            40.03557,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            71.52409,
            0,
            0.22165,
            0,
            16.62816,
            0,
            -0.09932,
            0,
            0,
            0,
            29.41011,
            0,
            16.16953,
            "<D",
            114.3498,
            0,
        ],
        1728113700: [
            0.22606,
            0,
            0.22606,
            0,
            39.94247,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            63.04853,
            0,
            0.21105,
            0,
            16.7486,
            0,
            -0.05959,
            0,
            0,
            0,
            29.41003,
            0,
            17.50097,
            "<D",
            114.7035,
            0,
        ],
        1728113760: [
            0.32224,
            0,
            0.32224,
            0,
            44.13865,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            76.94095,
            0,
            0.22085,
            0,
            16.75594,
            0,
            -0.06952,
            0,
            0,
            0,
            29.40985,
            0,
            16.81808,
            "<D",
            114.695,
            0,
        ],
        1728113820: [
            0.25669,
            0,
            0.25669,
            0,
            44.97289,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            75.40571,
            0,
            0.22336,
            0,
            16.70161,
            0,
            -0.06953,
            0,
            0,
            0,
            29.41007,
            0,
            13.65179,
            "<D",
            114.6052,
            0,
        ],
        1728113880: [
            0.33767,
            0,
            0.33767,
            0,
            42.47175,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            74.24054,
            0,
            0.2232,
            0,
            16.72697,
            0,
            -0.06291,
            0,
            0,
            0,
            29.40983,
            0,
            13.4313,
            "<D",
            114.8544,
            0,
        ],
        1728113940: [
            0.24411,
            0,
            0.24411,
            0,
            43.36525,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            66.42992,
            0,
            0.22359,
            0,
            16.76616,
            0,
            -0.1225,
            0,
            0,
            0,
            29.40973,
            0,
            16.94602,
            "<D",
            115.1537,
            0,
        ],
        1728114000: [
            0.19661,
            0,
            0.19661,
            0,
            43.65839,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            65.94524,
            0,
            0.2208,
            0,
            16.77261,
            0,
            -0.1821,
            0,
            0,
            0,
            29.4101,
            0,
            15.27291,
            "<D",
            114.9101,
            0,
        ],
        1728114060: [
            0.29079,
            0,
            0.29079,
            0,
            43.06639,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            51.43156,
            0,
            0.22322,
            0,
            16.73621,
            0,
            -0.21521,
            0,
            0,
            0,
            29.41037,
            0,
            15.28456,
            "<D",
            114.2242,
            0,
        ],
        1728114120: [
            0.28988,
            0,
            0.28988,
            0,
            41.9682,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            49.36914,
            0,
            0.22371,
            0,
            16.63045,
            0,
            -0.23508,
            0,
            0,
            0,
            29.41047,
            0,
            16.17488,
            "<D",
            113.3385,
            0,
        ],
        1728114180: [
            0.25376,
            0,
            0.25376,
            0,
            39.95604,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            53.51709,
            0,
            0.22349,
            0,
            16.5255,
            0,
            -0.23508,
            0,
            0,
            0,
            29.41046,
            0,
            16.34265,
            "<D",
            113.0217,
            0,
        ],
        1728114240: [
            0.28095,
            0,
            0.28095,
            0,
            38.43883,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            58.5292,
            0,
            0.22351,
            0,
            16.47238,
            0,
            -0.23176,
            0,
            0,
            0,
            29.41046,
            0,
            13.63978,
            "<D",
            113.1592,
            0,
        ],
        1728114300: [
            0.15957,
            0,
            0.15957,
            0,
            42.76195,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            53.78876,
            0,
            0.22362,
            0,
            16.44548,
            0,
            -0.22514,
            0,
            0,
            0,
            29.41046,
            0,
            17.18071,
            "<D",
            113.5131,
            0,
        ],
        1728114360: [
            0.1275,
            0,
            0.1275,
            0,
            41.50604,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            57.76622,
            0,
            0.22268,
            0,
            16.34561,
            0,
            -0.23176,
            0,
            0,
            0,
            29.41044,
            0,
            13.84713,
            "<D",
            113.8248,
            0,
        ],
        1728114420: [
            0.06528,
            0,
            0.06528,
            0,
            42.63739,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            59.94986,
            0,
            0.22056,
            0,
            16.25253,
            0,
            -0.13575,
            0,
            0,
            0,
            29.41011,
            0,
            17.71912,
            "<D",
            114.2659,
            0,
        ],
        1728114480: [
            0.16739,
            0,
            0.16739,
            0,
            43.16516,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            55.4397,
            0,
            0.22102,
            0,
            16.22113,
            0,
            -0.05959,
            0,
            0,
            0,
            29.40973,
            0,
            15.12848,
            "<D",
            114.4285,
            0,
        ],
        1728114540: [
            0.05586,
            0,
            0.05586,
            0,
            41.85152,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            42.26011,
            0,
            0.22172,
            0,
            16.3579,
            0,
            -0.0629,
            0,
            0,
            0,
            29.4097,
            0,
            18.22492,
            "<D",
            114.3016,
            0,
        ],
        1728114600: [
            0.27372,
            0,
            0.27372,
            0,
            44.01254,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            50.09869,
            0,
            0.22014,
            0,
            16.44728,
            0,
            -0.03642,
            0,
            0,
            0,
            29.40972,
            0,
            15.24168,
            "<D",
            115.3575,
            0,
        ],
        1728114660: [
            0.08932,
            0,
            0.08932,
            0,
            46.64403,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            46.76247,
            0,
            0.21765,
            0,
            16.35531,
            0,
            -0.10925,
            0,
            0,
            0,
            29.40965,
            0,
            14.32882,
            "<D",
            115.5311,
            0,
        ],
        1728114720: [
            0.25046,
            0,
            0.25046,
            0,
            45.85237,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            48.43346,
            0,
            0.22373,
            0,
            16.37998,
            0,
            -0.17547,
            0,
            0,
            0,
            29.40956,
            0,
            17.69963,
            "<D",
            115.2549,
            0,
        ],
        1728114780: [
            0.36551,
            0,
            0.36551,
            0,
            44.16979,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6732,
            0,
            43.31751,
            0,
            0.22089,
            0,
            16.44313,
            0,
            -0.2152,
            0,
            0,
            0,
            29.4091,
            0,
            17.32993,
            "<D",
            115.0284,
            0,
        ],
        1728114840: [
            0.17151,
            0,
            0.17151,
            0,
            48.69831,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            37.10494,
            0,
            0.21561,
            0,
            16.4457,
            0,
            -0.22845,
            0,
            0,
            0,
            29.40986,
            0,
            17.88233,
            "<D",
            114.6223,
            0,
        ],
        1728114900: [
            0.27904,
            0,
            0.27904,
            0,
            50.32621,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            61.84157,
            0,
            0.21976,
            0,
            16.42692,
            0,
            -0.22845,
            0,
            0,
            0,
            29.41028,
            0,
            16.13111,
            "<D",
            114.9135,
            0,
        ],
        1728114960: [
            0.02659,
            0,
            0.02659,
            0,
            47.64161,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            65.28415,
            0,
            0.21452,
            0,
            16.49689,
            0,
            -0.23176,
            0,
            0,
            0,
            29.41003,
            0,
            14.33803,
            "<D",
            114.8044,
            0,
        ],
        1728115020: [
            0.11295,
            0,
            0.11295,
            0,
            50.56085,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            80.04742,
            0,
            0.22373,
            0,
            16.56456,
            0,
            -0.23176,
            0,
            0,
            0,
            29.40834,
            0,
            14.37428,
            "<D",
            114.9836,
            0,
        ],
        1728115080: [
            0.07234,
            0,
            0.07234,
            0,
            50.80011,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            79.50751,
            0,
            0.22373,
            0,
            16.5918,
            0,
            -0.22514,
            0,
            0,
            0,
            29.40857,
            0,
            15.63869,
            "<D",
            115.0309,
            0,
        ],
        1728115140: [
            0.20668,
            0,
            0.20668,
            0,
            52.20449,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            84.14346,
            0,
            0.22284,
            0,
            16.61507,
            0,
            -0.21521,
            0,
            0,
            0,
            29.40952,
            0,
            17.09413,
            "<D",
            115.1397,
            0,
        ],
        1728115200: [
            0.34731,
            0,
            0.34731,
            0,
            51.63684,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            80.59692,
            0,
            0.22292,
            0,
            16.67373,
            0,
            -0.23176,
            0,
            0,
            0,
            29.40798,
            0,
            16.63672,
            "<D",
            115.0557,
            0,
        ],
        1728115260: [
            0.3019,
            0,
            0.3019,
            0,
            52.60036,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            86.31519,
            0,
            0.2234,
            0,
            16.69517,
            0,
            -0.16554,
            0,
            0,
            0,
            29.40816,
            0,
            16.43281,
            "<D",
            114.9443,
            0,
        ],
        1728115320: [
            0.25533,
            0,
            0.25533,
            0,
            52.62487,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            87.31727,
            0,
            0.22342,
            0,
            16.68699,
            0,
            -0.1225,
            0,
            0,
            0,
            29.40733,
            0,
            14.11477,
            "<D",
            115.0729,
            0,
        ],
        1728115380: [
            0.13073,
            0,
            0.13073,
            0,
            52.35465,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            95.37829,
            0,
            0.22226,
            0,
            16.7059,
            0,
            -0.05628,
            0,
            0,
            0,
            29.40689,
            0,
            17.39939,
            "<D",
            115.2016,
            0,
        ],
        1728115440: [
            0.24549,
            0,
            0.24549,
            0,
            51.54068,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            84.87655,
            0,
            0.22249,
            0,
            16.68886,
            0,
            -0.04304,
            0,
            0,
            0,
            29.40774,
            0,
            16.62424,
            "<D",
            114.5717,
            0,
        ],
        1728115500: [
            0.22759,
            0,
            0.22759,
            0,
            50.28286,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            98.29547,
            0,
            0.22371,
            0,
            16.63276,
            0,
            -0.05297,
            0,
            0,
            0,
            29.40722,
            0,
            17.6545,
            "<D",
            114.5873,
            0,
        ],
        1728115560: [
            0.05748,
            0,
            0.05748,
            0,
            49.81998,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            98.40421,
            0,
            0.22364,
            0,
            16.57021,
            0,
            -0.07615,
            0,
            0,
            0,
            29.40718,
            0,
            15.68534,
            "<D",
            114.5237,
            0,
        ],
        1728115620: [
            0.09267,
            0,
            0.09267,
            0,
            48.78556,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            114.5048,
            0,
            0.21941,
            0,
            16.53591,
            0,
            -0.06622,
            0,
            0,
            0,
            29.4072,
            0,
            16.0369,
            "<D",
            114.8903,
            0,
        ],
        1728115680: [
            0.12478,
            0,
            0.12478,
            0,
            51.02457,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            141.4987,
            0,
            0.22273,
            0,
            16.48993,
            0,
            -0.21521,
            0,
            0,
            0,
            29.40672,
            0,
            15.77192,
            "<D",
            115.1763,
            0,
        ],
        1728115740: [
            0.30824,
            0,
            0.30824,
            0,
            50.86536,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            143.767,
            0,
            0.20791,
            0,
            16.46323,
            0,
            -0.20197,
            0,
            0,
            0,
            29.407,
            0,
            16.26735,
            "<D",
            115.3451,
            0,
        ],
        1728115800: [
            0.06934,
            0,
            0.06934,
            0,
            50.29456,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            156.4213,
            0,
            0.18508,
            0,
            16.45712,
            0,
            -0.22845,
            0,
            0,
            0,
            29.40647,
            0,
            14.83613,
            "<D",
            115.5849,
            0,
        ],
        1728115860: [
            0.27059,
            0,
            0.27059,
            0,
            50.74919,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            155.4369,
            0,
            0.21975,
            0,
            16.4388,
            0,
            -0.23508,
            0,
            0,
            0,
            29.40662,
            0,
            16.6536,
            "<D",
            115.0582,
            0,
        ],
        1728115920: [
            0.43483,
            0,
            0.43483,
            0,
            51.02432,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            149.3997,
            0,
            0.2226,
            0,
            16.47639,
            0,
            -0.2119,
            0,
            0,
            0,
            29.40725,
            0,
            18.29555,
            "<D",
            113.9463,
            0,
        ],
        1728115980: [
            0.30999,
            0,
            0.30999,
            0,
            50.73043,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            158.439,
            0,
            0.21598,
            0,
            16.43072,
            0,
            -0.17879,
            0,
            0,
            0,
            29.40735,
            0,
            15.50199,
            "<D",
            113.4195,
            0,
        ],
        1728116040: [
            0.32734,
            0,
            0.32734,
            0,
            50.84319,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            153.0044,
            0,
            0.22214,
            0,
            16.3244,
            0,
            -0.15561,
            0,
            0,
            0,
            29.4073,
            0,
            15.26136,
            "<D",
            113.8049,
            0,
        ],
        1728116100: [
            0.32916,
            0,
            0.32916,
            0,
            51.00373,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            159.3799,
            0,
            0.21562,
            0,
            16.28226,
            0,
            -0.20197,
            0,
            0,
            0,
            29.40746,
            0,
            15.89932,
            "<D",
            114.4415,
            0,
        ],
        1728116160: [
            0.24211,
            0,
            0.24211,
            0,
            51.30352,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            147.5877,
            0,
            0.22373,
            0,
            16.2552,
            0,
            -0.21521,
            0,
            0,
            0,
            29.40689,
            0,
            16.73639,
            "<D",
            114.8145,
            0,
        ],
        1728116220: [
            0.0127,
            0,
            0.0127,
            0,
            50.89417,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            104.8841,
            0,
            0.12906,
            0,
            16.25749,
            0,
            -0.23508,
            0,
            0,
            0,
            29.40652,
            0,
            18.36978,
            "<D",
            114.9944,
            0,
        ],
        1728116280: [
            0.0319,
            0,
            0.0319,
            0,
            50.78941,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6732,
            0,
            36.13973,
            0,
            0.22059,
            0,
            16.27103,
            0,
            -0.17548,
            0,
            0,
            0,
            29.40645,
            0,
            15.38156,
            "<D",
            115.7404,
            0,
        ],
        1728116340: [
            0.02566,
            0,
            0.02566,
            0,
            50.80659,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            36.89317,
            0,
            0.22239,
            0,
            16.27269,
            0,
            -0.12912,
            0,
            0,
            0,
            29.40656,
            0,
            19.13712,
            "<D",
            115.5715,
            0,
        ],
        1728116400: [
            0.21756,
            0,
            0.21756,
            0,
            50.75777,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            26.64787,
            0,
            0.22283,
            0,
            16.27507,
            0,
            -0.1225,
            0,
            0,
            0,
            29.40656,
            0,
            18.53023,
            "<D",
            115.9965,
            0,
        ],
        1728116460: [
            0.09115,
            0,
            0.09115,
            0,
            51.00771,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            35.99048,
            0,
            0.21992,
            0,
            16.28161,
            0,
            -0.06953,
            0,
            0,
            0,
            29.40732,
            0,
            16.862,
            "<D",
            115.9949,
            0,
        ],
        1728116520: [
            0.12926,
            0,
            0.12926,
            0,
            49.90165,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            103.7464,
            0,
            0.21518,
            0,
            16.28135,
            0,
            -0.06953,
            0,
            0,
            0,
            29.40703,
            0,
            17.19706,
            "<D",
            116.6299,
            0,
        ],
        1728116580: [
            0.0632,
            0,
            0.0632,
            0,
            50.01604,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            13.04463,
            0,
            0.22047,
            0,
            16.30347,
            0,
            -0.10925,
            0,
            0,
            0,
            29.40757,
            0,
            16.72075,
            "<D",
            116.6968,
            0,
        ],
        1728116640: [
            0.20371,
            0,
            0.20371,
            0,
            50.00822,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            7.85598,
            0,
            0.22367,
            0,
            16.3233,
            0,
            -0.14237,
            0,
            0,
            0,
            29.40834,
            0,
            19.84503,
            "<D",
            116.5974,
            0,
        ],
        1728116700: [
            0.19917,
            0,
            0.19917,
            0,
            49.29218,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            18.74463,
            0,
            0.20959,
            0,
            16.32517,
            0,
            -0.11919,
            0,
            0,
            0,
            29.40695,
            0,
            15.42975,
            "<D",
            116.3071,
            0,
        ],
        1728116760: [
            0.22084,
            0,
            0.22084,
            0,
            48.57341,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            4.88573,
            0,
            0.13025,
            0,
            16.39737,
            0,
            -0.05628,
            0,
            0,
            0,
            29.40756,
            0,
            18.52341,
            "<D",
            116.3713,
            0,
        ],
        1728116820: [
            0.08231,
            0,
            0.08231,
            0,
            48.37128,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            142.9098,
            0,
            0.21608,
            0,
            16.45387,
            0,
            -0.1225,
            0,
            0,
            0,
            29.40763,
            0,
            19.63239,
            "<D",
            115.88,
            0,
        ],
        1728116880: [
            0.21827,
            0,
            0.21827,
            0,
            47.64688,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            151.9635,
            0,
            0.21957,
            0,
            16.51158,
            0,
            -0.15892,
            0,
            0,
            0,
            29.40772,
            0,
            15.58291,
            "<D",
            115.9965,
            0,
        ],
        1728116940: [
            0.11936,
            0,
            0.11936,
            0,
            47.45404,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            135.3858,
            0,
            0.2218,
            0,
            16.49469,
            0,
            -0.11919,
            0,
            0,
            0,
            29.40682,
            0,
            19.08439,
            "<D",
            115.605,
            0,
        ],
        1728117000: [
            0.22602,
            0,
            0.22602,
            0,
            46.57337,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            163.2496,
            0,
            0.21993,
            0,
            16.56617,
            0,
            -0.06953,
            0,
            0,
            0,
            29.40693,
            0,
            16.56143,
            "<D",
            116.027,
            0,
        ],
        1728117060: [
            0.08505,
            0,
            0.08505,
            0,
            47.09765,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            156.5452,
            0,
            0.22373,
            0,
            16.59127,
            0,
            -0.06291,
            0,
            0,
            0,
            29.40667,
            0,
            17.69788,
            "<D",
            115.919,
            0,
        ],
        1728117120: [
            0.09881,
            0,
            0.09881,
            0,
            47.4098,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            122.1399,
            0,
            0.22104,
            0,
            16.58705,
            0,
            -0.04966,
            0,
            0,
            0,
            29.40659,
            0,
            17.46363,
            "<D",
            116.1235,
            0,
        ],
        1728117180: [
            0.1149,
            0,
            0.1149,
            0,
            48.07731,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            102.4783,
            0,
            0.21662,
            0,
            16.53097,
            0,
            -0.05628,
            0,
            0,
            0,
            29.40716,
            0,
            16.25973,
            "<D",
            115.9946,
            0,
        ],
        1728117240: [
            0.34697,
            0,
            0.34697,
            0,
            47.99633,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            48.86316,
            0,
            0.21619,
            0,
            16.41817,
            0,
            -0.08939,
            0,
            0,
            0,
            29.40668,
            0,
            16.44783,
            "<D",
            116.3566,
            0,
        ],
        1728117300: [
            0.14227,
            0,
            0.14227,
            0,
            49.11472,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            49.33758,
            0,
            0.22054,
            0,
            16.36013,
            0,
            -0.11257,
            0,
            0,
            0,
            29.40645,
            0,
            16.28995,
            "<D",
            116.1053,
            0,
        ],
        1728117360: [
            0.16601,
            0,
            0.16601,
            0,
            49.65863,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            70.98436,
            0,
            0.22241,
            0,
            16.28749,
            0,
            -0.11919,
            0,
            0,
            0,
            29.40656,
            0,
            16.06658,
            "<D",
            115.7516,
            0,
        ],
        1728117420: [
            0.11453,
            0,
            0.11453,
            0,
            49.75953,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            139.8643,
            0,
            0.21834,
            0,
            16.27122,
            0,
            -0.21852,
            0,
            0,
            0,
            29.40646,
            0,
            18.13793,
            "<D",
            115.6827,
            0,
        ],
        1728117480: [
            0.15957,
            0,
            0.15957,
            0,
            49.78231,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            105.3479,
            0,
            0.22369,
            0,
            16.24393,
            0,
            -0.23176,
            0,
            0,
            0,
            29.4066,
            0,
            18.38649,
            "<D",
            115.5869,
            0,
        ],
        1728117540: [
            0.25694,
            0,
            0.25694,
            0,
            50.14198,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            121.7934,
            0,
            0.18893,
            0,
            16.27924,
            0,
            -0.22514,
            0,
            0,
            0,
            29.40659,
            0,
            14.47771,
            "<D",
            115.7335,
            0,
        ],
        1728117600: [
            0.24251,
            0,
            0.24251,
            0,
            50.23772,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            138.7063,
            0,
            0.47553,
            0,
            16.18475,
            0,
            -0.17879,
            0,
            0,
            0,
            29.40697,
            0,
            16.66697,
            "<D",
            115.9324,
            0,
        ],
        1728117660: [
            0.28121,
            0,
            0.28121,
            0,
            48.76959,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            146.7786,
            0,
            0.39389,
            0,
            16.19791,
            0,
            -0.10264,
            0,
            0,
            0,
            29.40652,
            0,
            19.74975,
            "<D",
            115.7095,
            0,
        ],
        1728117720: [
            0.19622,
            0,
            0.19622,
            0,
            49.05878,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            127.7284,
            0,
            0.6499,
            0,
            16.29236,
            0,
            -0.15561,
            0,
            0,
            0,
            29.40529,
            0,
            15.304,
            "<D",
            115.3385,
            0,
        ],
        1728117780: [
            0.41467,
            0,
            0.41467,
            0,
            48.21735,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            144.1336,
            0,
            1.04234,
            0,
            16.14081,
            0,
            -0.17548,
            0,
            0,
            0,
            29.40479,
            0,
            17.05429,
            "<D",
            114.5242,
            0,
        ],
        1728117840: [
            0.44464,
            0,
            0.44464,
            0,
            48.30612,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            162.2159,
            0,
            0.73811,
            0,
            16.17754,
            0,
            -0.18541,
            0,
            0,
            0,
            29.40325,
            0,
            17.5286,
            "<D",
            115.2639,
            0,
        ],
        1728117900: [
            0.24285,
            0,
            0.24285,
            0,
            48.11314,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            134.1248,
            0,
            0.79131,
            0,
            16.2225,
            0,
            -0.20858,
            0,
            0,
            0,
            29.40335,
            0,
            19.63233,
            "<D",
            116.7335,
            0,
        ],
        1728117960: [
            0.23721,
            0,
            0.23721,
            0,
            48.24028,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            132.8443,
            0,
            0.66799,
            0,
            16.27224,
            0,
            -0.21852,
            0,
            0,
            0,
            29.40317,
            0,
            18.92591,
            "<D",
            117.3157,
            0,
        ],
        1728118020: [
            0.06343,
            0,
            0.06343,
            0,
            47.30147,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            113.9153,
            0,
            0.39163,
            0,
            16.04893,
            0,
            -0.2417,
            0,
            0,
            0,
            29.4031,
            0,
            15.38417,
            "<D",
            118.4042,
            0,
        ],
        1728118080: [
            0.21516,
            0,
            0.21516,
            0,
            46.96427,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            128.7034,
            0,
            0.52563,
            0,
            15.95136,
            0,
            -0.26487,
            0,
            0,
            0,
            29.40316,
            0,
            19.11448,
            "<D",
            119.1587,
            0,
        ],
        1728118140: [
            0.33745,
            0,
            0.33745,
            0,
            46.12643,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            120.7988,
            0,
            0.38757,
            0,
            15.87479,
            0,
            -0.26156,
            0,
            0,
            0,
            29.40319,
            0,
            16.29408,
            "<D",
            118.417,
            0,
        ],
        1728118200: [
            0.02292,
            0,
            0.02292,
            0,
            47.1316,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            144.3611,
            0,
            0.5049,
            0,
            15.7865,
            0,
            -0.28474,
            0,
            0,
            0,
            29.40319,
            0,
            18.79682,
            "<D",
            116.9936,
            0,
        ],
        1728118260: [
            0.1751,
            0,
            0.1751,
            0,
            48.94334,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            144.6937,
            0,
            0.46661,
            0,
            15.72602,
            0,
            -0.20528,
            0,
            0,
            0,
            29.4032,
            0,
            16.92132,
            "<D",
            116.4087,
            0,
        ],
        1728118320: [
            0.33876,
            0,
            0.33876,
            0,
            49.43293,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            147.7267,
            0,
            0.22937,
            0,
            15.6676,
            0,
            -0.11919,
            0,
            0,
            0,
            29.4032,
            0,
            18.15344,
            "<D",
            116.5585,
            0,
        ],
        1728118380: [
            0.25246,
            0,
            0.25246,
            0,
            48.51226,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            152.419,
            0,
            0.73238,
            0,
            15.67898,
            0,
            -0.0927,
            0,
            0,
            0,
            29.40319,
            0,
            14.51409,
            "<D",
            116.666,
            0,
        ],
        1728118440: [
            0.03274,
            0,
            0.03274,
            0,
            50.05514,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            157.7339,
            0,
            0.97396,
            0,
            15.69225,
            0,
            -0.05628,
            0,
            0,
            0,
            29.40313,
            0,
            17.16562,
            "<D",
            116.8712,
            0,
        ],
        1728118500: [
            0.10419,
            0,
            0.10419,
            0,
            51.66439,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            160.1069,
            0,
            1.04819,
            0,
            15.6309,
            0,
            -0.04635,
            0,
            0,
            0,
            29.40238,
            0,
            16.2152,
            "<D",
            116.9461,
            0,
        ],
        1728118560: [
            0.19228,
            0,
            0.19228,
            0,
            51.65644,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            157.7574,
            0,
            1.35872,
            0,
            15.69776,
            0,
            -0.08277,
            0,
            0,
            0,
            29.40182,
            0,
            17.47097,
            "<D",
            116.9198,
            0,
        ],
        1728118620: [
            0.17622,
            0,
            0.17622,
            0,
            54.18621,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            159.423,
            0,
            1.22198,
            0,
            15.69199,
            0,
            -0.08608,
            0,
            0,
            0,
            29.40061,
            0,
            14.1925,
            "<D",
            117.0897,
            0,
        ],
        1728118680: [
            0.40423,
            0,
            0.40423,
            0,
            54.14771,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            165.0285,
            0,
            0.91583,
            0,
            15.71166,
            0,
            -0.07946,
            0,
            0,
            0,
            29.40004,
            0,
            19.71862,
            "<D",
            117.0327,
            0,
        ],
        1728118740: [
            0.27483,
            0,
            0.27483,
            0,
            54.63976,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            156.6179,
            0,
            1.08726,
            0,
            15.80621,
            0,
            -0.0629,
            0,
            0,
            0,
            29.40043,
            0,
            17.55771,
            "<D",
            116.861,
            0,
        ],
        1728118800: [
            0.16585,
            0,
            0.16585,
            0,
            54.09586,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            148.1827,
            0,
            1.00904,
            0,
            15.82492,
            0,
            -0.05628,
            0,
            0,
            0,
            29.39914,
            0,
            17.85446,
            "<D",
            116.8766,
            0,
        ],
        1728118860: [
            0.22713,
            0,
            0.22713,
            0,
            53.68273,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            149.1468,
            0,
            1.15687,
            0,
            15.86357,
            0,
            -0.0629,
            0,
            0,
            0,
            29.40178,
            0,
            17.10599,
            "<D",
            116.632,
            0,
        ],
        1728118920: [
            0.22074,
            0,
            0.22074,
            0,
            54.57655,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            157.4397,
            0,
            1.493,
            0,
            15.98685,
            0,
            -0.06621,
            0,
            0,
            0,
            29.39958,
            0,
            17.0179,
            "<D",
            117.0596,
            0,
        ],
        1728118980: [
            0.11362,
            0,
            0.11362,
            0,
            51.66931,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            154.5998,
            0,
            1.10379,
            0,
            15.97669,
            0,
            -0.06621,
            0,
            0,
            0,
            29.40026,
            0,
            18.18426,
            "<D",
            117.065,
            0,
        ],
        1728119040: [
            0.11133,
            0,
            0.11133,
            0,
            54.41824,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            167.0649,
            0,
            0.90183,
            0,
            16.06642,
            0,
            -0.04635,
            0,
            0,
            0,
            29.40039,
            0,
            16.43915,
            "<D",
            117.1964,
            0,
        ],
        1728119100: [
            0.25318,
            0,
            0.25318,
            0,
            54.25183,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            145.4708,
            0,
            0.907,
            0,
            16.00219,
            0,
            -0.05628,
            0,
            0,
            0,
            29.39987,
            0,
            18.09865,
            "<D",
            117.2979,
            0,
        ],
        1728119160: [
            0.24257,
            0,
            0.24257,
            0,
            54.51122,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            154.7203,
            0,
            0.72475,
            0,
            15.92019,
            0,
            -0.06953,
            0,
            0,
            0,
            29.40229,
            0,
            15.89144,
            "<D",
            117.3726,
            0,
        ],
        1728119220: [
            0.24573,
            0,
            0.24573,
            0,
            53.66582,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            161.8002,
            0,
            0.87575,
            0,
            15.8586,
            0,
            -0.07284,
            0,
            0,
            0,
            29.4028,
            0,
            18.29379,
            "<D",
            117.1538,
            0,
        ],
        1728119280: [
            0.20376,
            0,
            0.20376,
            0,
            54.51168,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            159.7048,
            0,
            0.74691,
            0,
            15.72796,
            0,
            -0.20858,
            0,
            0,
            0,
            29.4032,
            0,
            17.39362,
            "<D",
            117.1488,
            0,
        ],
        1728119340: [
            0.21286,
            0,
            0.21286,
            0,
            53.26246,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            189.7718,
            0,
            0.85036,
            0,
            15.77145,
            0,
            -0.25163,
            0,
            0,
            0,
            29.4032,
            0,
            17.65259,
            "<D",
            116.9391,
            0,
        ],
        1728119400: [
            0.30075,
            0,
            0.30075,
            0,
            55.26058,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            36.62236,
            0,
            0.04018,
            0,
            15.7544,
            0,
            -0.22845,
            0,
            0,
            0,
            29.4032,
            0,
            20.02907,
            "<D",
            116.8915,
            0,
        ],
        1728119460: [
            0.19613,
            0,
            0.19613,
            0,
            52.23532,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            134.3885,
            0,
            0.74218,
            0,
            15.81413,
            0,
            -0.22845,
            0,
            0,
            0,
            29.40318,
            0,
            18.88697,
            "<D",
            116.8857,
            0,
        ],
        1728119520: [
            0.22946,
            0,
            0.22946,
            0,
            53.76523,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            104.5219,
            0,
            0.58555,
            0,
            15.80417,
            0,
            -0.19534,
            0,
            0,
            0,
            29.40324,
            0,
            16.46343,
            "<D",
            116.8976,
            0,
        ],
        1728119580: [
            0.08933,
            0,
            0.08933,
            0,
            49.36491,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            154.3971,
            0,
            0.94184,
            0,
            15.77755,
            0,
            -0.18872,
            0,
            0,
            0,
            29.40325,
            0,
            15.52681,
            "<D",
            117.1917,
            0,
        ],
        1728119640: [
            0.17082,
            0,
            0.17082,
            0,
            49.96392,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            82.79192,
            0,
            0.67567,
            0,
            15.83549,
            0,
            -0.16886,
            0,
            0,
            0,
            29.40333,
            0,
            15.37542,
            "<D",
            117.3297,
            0,
        ],
        1728119700: [
            0.31523,
            0,
            0.31523,
            0,
            46.6659,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            112.4183,
            0,
            0.77474,
            0,
            15.89221,
            0,
            -0.10264,
            0,
            0,
            0,
            29.40326,
            0,
            14.75682,
            "<D",
            117.1573,
            0,
        ],
        1728119760: [
            0.30156,
            0,
            0.30156,
            0,
            49.5627,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            97.63163,
            0,
            0.70611,
            0,
            15.94052,
            0,
            -0.04304,
            0,
            0,
            0,
            29.40372,
            0,
            19.10908,
            "<D",
            116.4548,
            0,
        ],
        1728119820: [
            0.26136,
            0,
            0.26136,
            0,
            44.87696,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            84.95969,
            0,
            0.7368,
            0,
            15.87231,
            0,
            -0.06622,
            0,
            0,
            0,
            29.40422,
            0,
            17.25184,
            "<D",
            116.9943,
            0,
        ],
        1728119880: [
            0.15195,
            0,
            0.15195,
            0,
            40.42981,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            99.31119,
            0,
            0.50991,
            0,
            15.8292,
            0,
            -0.08939,
            0,
            0,
            0,
            29.40431,
            0,
            17.08334,
            "<D",
            122.7468,
            0,
        ],
        1728119940: [
            0.12402,
            0,
            0.12402,
            0,
            43.04144,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            80.94986,
            0,
            0.54855,
            0,
            15.79158,
            0,
            -0.08608,
            0,
            0,
            0,
            29.40347,
            0,
            16.85739,
            "<D",
            123.9318,
            0,
        ],
        1728120000: [
            0.09636,
            0,
            0.09636,
            0,
            46.24887,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            88.36453,
            0,
            0.83897,
            0,
            15.91283,
            0,
            -0.18541,
            0,
            0,
            0,
            29.40359,
            0,
            16.03079,
            "<D",
            123.92,
            0,
        ],
        1728120060: [
            0.03492,
            0,
            0.03492,
            0,
            46.68771,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            63.83614,
            0,
            0.64602,
            0,
            15.96917,
            0,
            -0.21852,
            0,
            0,
            0,
            29.40371,
            0,
            19.82496,
            "<D",
            123.8987,
            0,
        ],
        1728120120: [
            0.16676,
            0,
            0.16676,
            0,
            47.16896,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            30.18114,
            0,
            0.64353,
            0,
            16.01054,
            0,
            -0.23176,
            0,
            0,
            0,
            29.40487,
            0,
            20.03852,
            "<D",
            124.0508,
            0,
        ],
        1728120180: [
            0.21906,
            0,
            0.21906,
            0,
            49.66515,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            37.14371,
            0,
            0.70657,
            0,
            16.01763,
            0,
            -0.23176,
            0,
            0,
            0,
            29.40626,
            0,
            15.43979,
            "<D",
            124.0549,
            0,
        ],
        1728120240: [
            0.13345,
            0,
            0.13345,
            0,
            50.71069,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            44.91718,
            0,
            0.72331,
            0,
            15.90226,
            0,
            -0.23176,
            0,
            0,
            0,
            29.40647,
            0,
            18.63453,
            "<D",
            124.0906,
            0,
        ],
        1728120300: [
            0.12505,
            0,
            0.12505,
            0,
            49.97299,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            45.7841,
            0,
            0.82826,
            0,
            15.69121,
            0,
            -0.11588,
            0,
            0,
            0,
            29.40656,
            0,
            14.42967,
            "<D",
            123.8672,
            0,
        ],
        1728120360: [
            0.34235,
            0,
            0.34235,
            0,
            49.64521,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            42.03568,
            0,
            0.85036,
            0,
            15.48367,
            0,
            -0.06622,
            0,
            0,
            0,
            29.40688,
            0,
            14.84578,
            "<D",
            124.2034,
            0,
        ],
        1728120420: [
            0.21253,
            0,
            0.21253,
            0,
            50.17736,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            40.281,
            0,
            0.86916,
            0,
            15.42399,
            0,
            -0.06622,
            0,
            0,
            0,
            29.40821,
            0,
            18.06127,
            "<D",
            124.1796,
            0,
        ],
        1728120480: [
            0.18793,
            0,
            0.18793,
            0,
            48.91251,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            33.3175,
            0,
            0.85026,
            0,
            15.63725,
            0,
            -0.05628,
            0,
            0,
            0,
            29.40868,
            0,
            15.17202,
            "<D",
            124.132,
            0,
        ],
        1728120540: [
            0.12316,
            0,
            0.12316,
            0,
            37.10746,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            10.65004,
            0,
            0.94347,
            0,
            15.49679,
            0,
            -0.10925,
            0,
            0,
            0,
            29.40956,
            0,
            18.37096,
            "<D",
            124.0685,
            0,
        ],
        1728120600: [
            0.28486,
            0,
            0.28486,
            0,
            38.81814,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            21.27743,
            0,
            0.76147,
            0,
            15.36329,
            0,
            -0.10594,
            0,
            0,
            0,
            29.40887,
            0,
            14.38109,
            "<D",
            124.0078,
            0,
        ],
        1728120660: [
            0.33267,
            0,
            0.33267,
            0,
            38.57348,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            18.71896,
            0,
            0.53469,
            0,
            15.39251,
            0,
            -0.20857,
            0,
            0,
            0,
            29.40809,
            0,
            13.86196,
            "<D",
            123.908,
            0,
        ],
        1728120720: [
            0.41889,
            0,
            0.41889,
            0,
            41.07479,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            10.72039,
            0,
            0.68294,
            0,
            15.35225,
            0,
            -0.15229,
            0,
            0,
            0,
            29.40821,
            0,
            18.71335,
            "<D",
            123.858,
            0,
        ],
        1728120780: [
            0.10835,
            0,
            0.10835,
            0,
            39.24593,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            26.00522,
            0,
            0.69158,
            0,
            15.24245,
            0,
            -0.20196,
            0,
            0,
            0,
            29.40888,
            0,
            16.66827,
            "<D",
            123.6884,
            0,
        ],
        1728120840: [
            0.18942,
            0,
            0.18942,
            0,
            38.49809,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            37.99729,
            0,
            0.67502,
            0,
            15.2983,
            0,
            -0.1523,
            0,
            0,
            0,
            29.40785,
            0,
            15.82406,
            "<D",
            123.9284,
            0,
        ],
        1728120900: [
            0.31163,
            0,
            0.31163,
            0,
            39.4883,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            34.51067,
            0,
            0.78816,
            0,
            15.4043,
            0,
            -0.16554,
            0,
            0,
            0,
            29.40908,
            0,
            18.16688,
            "<D",
            123.9641,
            0,
        ],
        1728120960: [
            0.30351,
            0,
            0.30351,
            0,
            40.44128,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            40.60548,
            0,
            0.79855,
            0,
            15.51627,
            0,
            -0.10263,
            0,
            0,
            0,
            29.4099,
            0,
            18.67168,
            "<D",
            123.9446,
            0,
        ],
        1728121020: [
            0.30673,
            0,
            0.30673,
            0,
            36.2424,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            53.10989,
            0,
            0.65974,
            0,
            15.57693,
            0,
            -0.05628,
            0,
            0,
            0,
            29.40942,
            0,
            15.18028,
            "<D",
            123.969,
            0,
        ],
        1728121080: [
            0.17041,
            0,
            0.17041,
            0,
            31.48111,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            73.40056,
            0,
            0.56363,
            0,
            15.66278,
            0,
            -0.06953,
            0,
            0,
            0,
            29.41005,
            0,
            17.87724,
            "<D",
            124.023,
            0,
        ],
        1728121140: [
            0.17272,
            0,
            0.17272,
            0,
            29.19847,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            84.96933,
            0,
            0.54626,
            0,
            15.63988,
            0,
            -0.05628,
            0,
            0,
            0,
            29.40905,
            0,
            18.71944,
            "<D",
            124.0254,
            0,
        ],
        1728121200: [
            0.12667,
            0,
            0.12667,
            0,
            29.84427,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            66.18465,
            0,
            0.4678,
            0,
            15.68913,
            0,
            -0.07615,
            0,
            0,
            0,
            29.40885,
            0,
            17.65403,
            "<D",
            124.0929,
            0,
        ],
        1728121260: [
            0.21017,
            0,
            0.21017,
            0,
            27.27923,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            95.69759,
            0,
            0.53026,
            0,
            15.80265,
            0,
            -0.08277,
            0,
            0,
            0,
            29.40862,
            0,
            18.1027,
            "<D",
            124.1199,
            0,
        ],
        1728121320: [
            0.15846,
            0,
            0.15846,
            0,
            27.08586,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            50.2706,
            0,
            0.45211,
            0,
            15.84687,
            0,
            -0.2119,
            0,
            0,
            0,
            29.40787,
            0,
            15.74543,
            "<D",
            123.8512,
            0,
        ],
        1728121380: [
            0.18747,
            0,
            0.18747,
            0,
            30.06195,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            38.52291,
            0,
            0.65428,
            0,
            15.90815,
            0,
            -0.14568,
            0,
            0,
            0,
            29.40781,
            0,
            15.87689,
            "<D",
            124.058,
            0,
        ],
        1728121440: [
            0.30022,
            0,
            0.30022,
            0,
            33.98913,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            24.18147,
            0,
            0.82475,
            0,
            15.92368,
            0,
            -0.18872,
            0,
            0,
            0,
            29.40745,
            0,
            17.3986,
            "<D",
            124.03,
            0,
        ],
        1728121500: [
            0.28992,
            0,
            0.28992,
            0,
            37.4435,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            58.72842,
            0,
            0.82813,
            0,
            15.97063,
            0,
            -0.21852,
            0,
            0,
            0,
            29.40644,
            0,
            16.95318,
            "<D",
            123.9808,
            0,
        ],
        1728121560: [
            0.08572,
            0,
            0.08572,
            0,
            44.68663,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            87.96391,
            0,
            0.62618,
            0,
            15.92117,
            0,
            -0.23176,
            0,
            0,
            0,
            29.40646,
            0,
            15.64797,
            "<D",
            123.9301,
            0,
        ],
        1728121620: [
            0.14214,
            0,
            0.14214,
            0,
            44.4974,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            87.30991,
            0,
            0.41952,
            0,
            15.86407,
            0,
            -0.23508,
            0,
            0,
            0,
            29.4058,
            0,
            17.35887,
            "<D",
            123.9712,
            0,
        ],
        1728121680: [
            0.24831,
            0,
            0.24831,
            0,
            41.07859,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            176.7543,
            0,
            0.38006,
            0,
            15.80658,
            0,
            -0.2119,
            0,
            0,
            0,
            29.40516,
            0,
            15.67519,
            "<D",
            123.9952,
            0,
        ],
        1728121740: [
            0.28845,
            0,
            0.28845,
            0,
            40.15959,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            176.2974,
            0,
            0.49203,
            0,
            15.78631,
            0,
            -0.17879,
            0,
            0,
            0,
            29.40336,
            0,
            14.63841,
            "<D",
            123.9666,
            0,
        ],
        1728121800: [
            0.30131,
            0,
            0.30131,
            0,
            43.52796,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            156.3872,
            0,
            0.7813,
            0,
            15.72424,
            0,
            -0.08277,
            0,
            0,
            0,
            29.40326,
            0,
            17.12326,
            "<D",
            123.8672,
            0,
        ],
        1728121860: [
            0.23552,
            0,
            0.23552,
            0,
            45.19827,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            138.3367,
            0,
            0.86724,
            0,
            15.68848,
            0,
            -0.07615,
            0,
            0,
            0,
            29.40341,
            0,
            15.90576,
            "<D",
            123.9663,
            0,
        ],
        1728121920: [
            0.3585,
            0,
            0.3585,
            0,
            45.29108,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            116.7429,
            0,
            0.61045,
            0,
            15.8268,
            0,
            -0.11588,
            0,
            0,
            0,
            29.40326,
            0,
            16.78678,
            "<D",
            123.9445,
            0,
        ],
        1728121980: [
            0.1681,
            0,
            0.1681,
            0,
            41.20426,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            90.73721,
            0,
            0.44147,
            0,
            15.88784,
            0,
            -0.14236,
            0,
            0,
            0,
            29.40323,
            0,
            19.32624,
            "<D",
            124.1842,
            0,
        ],
        1728122040: [
            0.22201,
            0,
            0.22201,
            0,
            40.74061,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            71.14798,
            0,
            0.23455,
            0,
            15.89889,
            0,
            -0.10263,
            0,
            0,
            0,
            29.40329,
            0,
            16.48016,
            "<D",
            124.1565,
            0,
        ],
        1728122100: [
            0.37402,
            0,
            0.37402,
            0,
            40.57213,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            146.0819,
            0,
            0.22437,
            0,
            15.84925,
            0,
            -0.16223,
            0,
            0,
            0,
            29.40313,
            0,
            17.24144,
            "<D",
            124.1418,
            0,
        ],
        1728122160: [
            0.20458,
            0,
            0.20458,
            0,
            42.79931,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            123.9673,
            0,
            0.2166,
            0,
            15.81461,
            0,
            -0.14899,
            0,
            0,
            0,
            29.40312,
            0,
            15.98133,
            "<D",
            124.0951,
            0,
        ],
        1728122220: [
            0.19434,
            0,
            0.19434,
            0,
            43.54846,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            89.11494,
            0,
            0.21769,
            0,
            15.83473,
            0,
            -0.22183,
            0,
            0,
            0,
            29.40298,
            0,
            16.1976,
            "<D",
            124.0624,
            0,
        ],
        1728122280: [
            0.13666,
            0,
            0.13666,
            0,
            44.44016,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            26.81694,
            0,
            0.40999,
            0,
            15.78145,
            0,
            -0.23839,
            0,
            0,
            0,
            29.40325,
            0,
            16.31144,
            "<D",
            123.8296,
            0,
        ],
        1728122340: [
            0.14309,
            0,
            0.14309,
            0,
            45.28999,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            36.29869,
            0,
            0.67247,
            0,
            15.77868,
            0,
            -0.23508,
            0,
            0,
            0,
            29.40586,
            0,
            15.34842,
            "<D",
            123.9252,
            0,
        ],
        1728122400: [
            0.19641,
            0,
            0.19641,
            0,
            45.83744,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            16.78671,
            0,
            0.75226,
            0,
            15.82188,
            0,
            -0.22845,
            0,
            0,
            0,
            29.40648,
            0,
            20.34732,
            "<D",
            123.8619,
            0,
        ],
        1728122460: [
            0.23789,
            0,
            0.23789,
            0,
            45.3854,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            19.20344,
            0,
            0.80791,
            0,
            15.85804,
            0,
            -0.18541,
            0,
            0,
            0,
            29.40634,
            0,
            13.79455,
            "<D",
            123.8646,
            0,
        ],
        1728122520: [
            0.2821,
            0,
            0.2821,
            0,
            44.95189,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            12.27645,
            0,
            0.79571,
            0,
            15.8738,
            0,
            -0.23506,
            0,
            0,
            0,
            29.40645,
            0,
            15.90387,
            "<D",
            123.7334,
            0,
        ],
        1728122580: [
            0.15188,
            0,
            0.15188,
            0,
            44.39189,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            24.43975,
            0,
            0.71202,
            0,
            15.89303,
            0,
            -0.25823,
            0,
            0,
            0,
            29.40663,
            0,
            19.18439,
            "<D",
            123.7445,
            0,
        ],
        1728122640: [
            0.36006,
            0,
            0.36006,
            0,
            43.03454,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            46.80515,
            0,
            0.45776,
            0,
            15.87519,
            0,
            -0.21188,
            0,
            0,
            0,
            29.40663,
            0,
            15.8312,
            "<D",
            123.6805,
            0,
        ],
        1728122700: [
            0.24786,
            0,
            0.24786,
            0,
            42.13003,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            60.96905,
            0,
            0.34551,
            0,
            15.89274,
            0,
            -0.2781,
            0,
            0,
            0,
            29.40682,
            0,
            14.20921,
            "<D",
            123.6461,
            0,
        ],
        1728122760: [
            0.05346,
            0,
            0.05346,
            0,
            41.08438,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            58.60183,
            0,
            0.23314,
            0,
            15.86083,
            0,
            -0.22513,
            0,
            0,
            0,
            29.40661,
            0,
            18.37026,
            "<D",
            123.5765,
            0,
        ],
        1728122820: [
            0.12944,
            0,
            0.12944,
            0,
            39.98934,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            68.43644,
            0,
            0.22358,
            0,
            15.73201,
            0,
            -0.20857,
            0,
            0,
            0,
            29.40696,
            0,
            15.48354,
            "<D",
            123.6311,
            0,
        ],
        1728122880: [
            0.15813,
            0,
            0.15813,
            0,
            40.31072,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            85.68562,
            0,
            0.19212,
            0,
            15.73799,
            0,
            -0.22844,
            0,
            0,
            0,
            29.40685,
            0,
            16.53917,
            "<D",
            123.7345,
            0,
        ],
        1728122940: [
            0.16699,
            0,
            0.16699,
            0,
            41.26838,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            147.9496,
            0,
            0.17326,
            0,
            15.81991,
            0,
            -0.1854,
            0,
            0,
            0,
            29.40683,
            0,
            17.02544,
            "<D",
            123.7676,
            0,
        ],
        1728123000: [
            0.19508,
            0,
            0.19508,
            0,
            40.87822,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            125.0192,
            0,
            0.22372,
            0,
            15.88223,
            0,
            -0.15229,
            0,
            0,
            0,
            29.40681,
            0,
            16.00675,
            "<D",
            123.8861,
            0,
        ],
        1728123060: [
            0.23716,
            0,
            0.23716,
            0,
            40.58855,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            124.199,
            0,
            0.22373,
            0,
            15.8519,
            0,
            -0.14898,
            0,
            0,
            0,
            29.40676,
            0,
            16.52724,
            "<D",
            123.8439,
            0,
        ],
        1728123120: [
            0.24742,
            0,
            0.24742,
            0,
            40.73437,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            122.9241,
            0,
            0.22373,
            0,
            15.88705,
            0,
            -0.22513,
            0,
            0,
            0,
            29.40676,
            0,
            16.02057,
            "<D",
            123.8707,
            0,
        ],
        1728123180: [
            0.25008,
            0,
            0.25008,
            0,
            41.99433,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            94.57333,
            0,
            0.30476,
            0,
            15.92186,
            0,
            -0.16222,
            0,
            0,
            0,
            29.40648,
            0,
            16.23985,
            "<D",
            123.796,
            0,
        ],
        1728123240: [
            0.20539,
            0,
            0.20539,
            0,
            41.466,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            57.54767,
            0,
            0.31741,
            0,
            15.87465,
            0,
            -0.20857,
            0,
            0,
            0,
            29.40647,
            0,
            18.39679,
            "<D",
            123.9237,
            0,
        ],
        1728123300: [
            0.35578,
            0,
            0.35578,
            0,
            41.56892,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            27.47267,
            0,
            0.34321,
            0,
            15.86591,
            0,
            -0.11587,
            0,
            0,
            0,
            29.40647,
            0,
            12.99384,
            "<D",
            123.7297,
            0,
        ],
        1728123360: [
            0.16323,
            0,
            0.16323,
            0,
            42.33312,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            60.93682,
            0,
            0.39811,
            0,
            15.84177,
            0,
            -0.12912,
            0,
            0,
            0,
            29.40385,
            0,
            16.32606,
            "<D",
            123.9644,
            0,
        ],
        1728123420: [
            0.2138,
            0,
            0.2138,
            0,
            39.02475,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            88.03388,
            0,
            0.43554,
            0,
            15.77677,
            0,
            -0.17547,
            0,
            0,
            0,
            29.4045,
            0,
            16.9527,
            "<D",
            123.8844,
            0,
        ],
        1728123480: [
            0.1291,
            0,
            0.1291,
            0,
            39.97654,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            94.29696,
            0,
            0.27533,
            0,
            15.70431,
            0,
            -0.22845,
            0,
            0,
            0,
            29.40576,
            0,
            17.11897,
            "<D",
            124.0131,
            0,
        ],
        1728123540: [
            0.34332,
            0,
            0.34332,
            0,
            39.93301,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            108.8737,
            0,
            0.22905,
            0,
            15.56975,
            0,
            -0.23176,
            0,
            0,
            0,
            29.40412,
            0,
            16.95176,
            "<D",
            123.924,
            0,
        ],
        1728123600: [
            0.32289,
            0,
            0.32289,
            0,
            33.18451,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            121.9754,
            0,
            0.41773,
            0,
            15.44865,
            0,
            -0.22514,
            0,
            0,
            0,
            29.40401,
            0,
            15.59425,
            "<D",
            124.0222,
            0,
        ],
        1728123660: [
            0.12782,
            0,
            0.12782,
            0,
            28.22843,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            117.261,
            0,
            0.2368,
            0,
            15.31421,
            0,
            -0.23508,
            0,
            0,
            0,
            29.40466,
            0,
            17.50533,
            "<D",
            123.9565,
            0,
        ],
        1728123720: [
            0.10615,
            0,
            0.10615,
            0,
            30.28699,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            119.9022,
            0,
            0.21771,
            0,
            15.25721,
            0,
            -0.2417,
            0,
            0,
            0,
            29.40569,
            0,
            15.3739,
            "<D",
            123.9831,
            0,
        ],
        1728123780: [
            0.10219,
            0,
            0.10219,
            0,
            36.94086,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            103.5561,
            0,
            0.38384,
            0,
            15.12425,
            0,
            -0.23176,
            0,
            0,
            0,
            29.40649,
            0,
            15.84466,
            "<D",
            123.6433,
            0,
        ],
        1728123840: [
            0.32655,
            0,
            0.32655,
            0,
            34.75803,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            88.62094,
            0,
            0.35606,
            0,
            15.03666,
            0,
            -0.20859,
            0,
            0,
            0,
            29.40588,
            0,
            17.675,
            "<D",
            123.8764,
            0,
        ],
        1728123900: [
            0.20016,
            0,
            0.20016,
            0,
            33.49077,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            81.40882,
            0,
            0.34493,
            0,
            14.99061,
            0,
            -0.17548,
            0,
            0,
            0,
            29.40565,
            0,
            15.34628,
            "<D",
            123.998,
            0,
        ],
        1728123960: [
            0.15249,
            0,
            0.15249,
            0,
            23.19046,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            83.11159,
            0,
            0.2723,
            0,
            14.97669,
            0,
            -0.12913,
            0,
            0,
            0,
            29.40504,
            0,
            16.03518,
            "<D",
            124.0025,
            0,
        ],
        1728124020: [
            0.39604,
            0,
            0.39604,
            0,
            27.70155,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            84.03951,
            0,
            0.38972,
            0,
            14.92529,
            0,
            -0.08277,
            0,
            0,
            0,
            29.40666,
            0,
            18.29964,
            "<D",
            124.0744,
            0,
        ],
        1728124080: [
            0.21616,
            0,
            0.21616,
            0,
            19.50182,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            76.34961,
            0,
            0.36969,
            0,
            14.86698,
            0,
            -0.07615,
            0,
            0,
            0,
            29.40548,
            0,
            13.20319,
            "<D",
            123.8968,
            0,
        ],
        1728124140: [
            0.25033,
            0,
            0.25033,
            0,
            23.14043,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            61.41138,
            0,
            0.51729,
            0,
            14.81297,
            0,
            -0.05297,
            0,
            0,
            0,
            29.40602,
            0,
            17.40644,
            "<D",
            123.8717,
            0,
        ],
        1728124200: [
            0.38295,
            0,
            0.38295,
            0,
            33.41514,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            51.64033,
            0,
            0.62277,
            0,
            14.80643,
            0,
            -0.06291,
            0,
            0,
            0,
            29.40658,
            0,
            12.49184,
            "<D",
            123.8529,
            0,
        ],
        1728124260: [
            0.13751,
            0,
            0.13751,
            0,
            41.62963,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            63.36201,
            0,
            0.70572,
            0,
            14.79005,
            0,
            -0.06622,
            0,
            0,
            0,
            29.40663,
            0,
            16.19284,
            "<D",
            123.8865,
            0,
        ],
        1728124320: [
            0.26361,
            0,
            0.26361,
            0,
            38.18583,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            61.93206,
            0,
            0.68031,
            0,
            14.88186,
            0,
            -0.06622,
            0,
            0,
            0,
            29.40689,
            0,
            13.17675,
            "<D",
            123.8248,
            0,
        ],
        1728124380: [
            0.13271,
            0,
            0.13271,
            0,
            31.91893,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            73.85411,
            0,
            0.59175,
            0,
            14.97703,
            0,
            -0.05297,
            0,
            0,
            0,
            29.40696,
            0,
            18.21184,
            "<D",
            123.85,
            0,
        ],
        1728124440: [
            0.22015,
            0,
            0.22015,
            0,
            25.03405,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            68.10347,
            0,
            0.48685,
            0,
            15.04741,
            0,
            -0.0596,
            0,
            0,
            0,
            29.4071,
            0,
            15.76027,
            "<D",
            123.7947,
            0,
        ],
        1728124500: [
            0.30839,
            0,
            0.30839,
            0,
            24.49614,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            81.41022,
            0,
            0.44175,
            0,
            15.13178,
            0,
            -0.13906,
            0,
            0,
            0,
            29.40618,
            0,
            14.51129,
            "<D",
            123.6888,
            0,
        ],
        1728124560: [
            0.12391,
            0,
            0.12391,
            0,
            26.20587,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            73.70553,
            0,
            0.32074,
            0,
            15.21538,
            0,
            -0.2417,
            0,
            0,
            0,
            29.4062,
            0,
            15.99518,
            "<D",
            123.7697,
            0,
        ],
        1728124620: [
            0.26324,
            0,
            0.26324,
            0,
            35.88988,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            73.9324,
            0,
            0.24822,
            0,
            15.26709,
            0,
            -0.16555,
            0,
            0,
            0,
            29.40569,
            0,
            16.73378,
            "<D",
            123.7248,
            0,
        ],
        1728124680: [
            0.15514,
            0,
            0.15514,
            0,
            38.61982,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            81.1011,
            0,
            0.22366,
            0,
            15.26524,
            0,
            -0.05959,
            0,
            0,
            0,
            29.40432,
            0,
            19.44036,
            "<D",
            123.5052,
            0,
        ],
        1728124740: [
            0.23344,
            0,
            0.23344,
            0,
            40.14483,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            86.95041,
            0,
            0.22242,
            0,
            15.22591,
            0,
            -0.04635,
            0,
            0,
            0,
            29.40397,
            0,
            15.7386,
            "<D",
            123.7828,
            0,
        ],
        1728124800: [
            0.41904,
            0,
            0.41904,
            0,
            40.52764,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            89.90142,
            0,
            0.22967,
            0,
            15.21781,
            0,
            -0.04966,
            0,
            0,
            0,
            29.40338,
            0,
            16.63923,
            "<D",
            123.783,
            0,
        ],
        1728124860: [
            0.34733,
            0,
            0.34733,
            0,
            42.98887,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            88.25694,
            0,
            0.36221,
            0,
            15.18814,
            0,
            -0.10263,
            0,
            0,
            0,
            29.40439,
            0,
            15.80138,
            "<D",
            123.9155,
            0,
        ],
        1728124920: [
            0.2915,
            0,
            0.2915,
            0,
            40.55905,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            92.17298,
            0,
            0.22347,
            0,
            15.14563,
            0,
            -0.19534,
            0,
            0,
            0,
            29.40428,
            0,
            14.81543,
            "<D",
            123.9199,
            0,
        ],
        1728124980: [
            0.14988,
            0,
            0.14988,
            0,
            22.28017,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            114.2924,
            0,
            0.21774,
            0,
            14.93741,
            0,
            -0.2152,
            0,
            0,
            0,
            29.40321,
            0,
            18.1494,
            "<D",
            123.9082,
            0,
        ],
        1728125040: [
            0.11123,
            0,
            0.11123,
            0,
            15.37998,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            161.2338,
            0,
            0.19152,
            0,
            14.77022,
            0,
            -0.23176,
            0,
            0,
            0,
            29.40326,
            0,
            16.63916,
            "<D",
            123.9097,
            0,
        ],
        1728125100: [
            0.24145,
            0,
            0.24145,
            0,
            15.03021,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            183.8535,
            0,
            0.32354,
            0,
            14.68671,
            0,
            -0.20196,
            0,
            0,
            0,
            29.40311,
            0,
            18.38808,
            "<D",
            123.8538,
            0,
        ],
        1728125160: [
            0.23938,
            0,
            0.23938,
            0,
            13.18394,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            173.8532,
            0,
            0.54274,
            0,
            14.63853,
            0,
            -0.2152,
            0,
            0,
            0,
            29.40312,
            0,
            19.7406,
            "<D",
            123.5891,
            0,
        ],
        1728125220: [
            0.22183,
            0,
            0.22183,
            0,
            21.13963,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            161.6757,
            0,
            0.70626,
            0,
            14.5915,
            0,
            -0.20858,
            0,
            0,
            0,
            29.40258,
            0,
            13.80738,
            "<D",
            123.6633,
            0,
        ],
        1728125280: [
            0.31286,
            0,
            0.31286,
            0,
            33.9328,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            160.9448,
            0,
            0.97733,
            0,
            14.58902,
            0,
            -0.2152,
            0,
            0,
            0,
            29.40036,
            0,
            16.91622,
            "<D",
            123.7524,
            0,
        ],
        1728125340: [
            0.25018,
            0,
            0.25018,
            0,
            31.2622,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            157.6058,
            0,
            0.98222,
            0,
            14.61968,
            0,
            -0.23176,
            0,
            0,
            0,
            29.3991,
            0,
            12.27803,
            "<D",
            123.7583,
            0,
        ],
        1728125400: [
            0.30092,
            0,
            0.30092,
            0,
            35.39178,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            143.816,
            0,
            0.9611,
            0,
            14.71114,
            0,
            -0.23838,
            0,
            0,
            0,
            29.39912,
            0,
            16.14379,
            "<D",
            123.6363,
            0,
        ],
        1728125460: [
            0.21036,
            0,
            0.21036,
            0,
            32.38625,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            115.0074,
            0,
            0.81784,
            0,
            14.75231,
            0,
            -0.20527,
            0,
            0,
            0,
            29.39899,
            0,
            14.89858,
            "<D",
            123.6109,
            0,
        ],
        1728125520: [
            0.20464,
            0,
            0.20464,
            0,
            28.35712,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            76.23093,
            0,
            0.79389,
            0,
            14.8534,
            0,
            -0.19865,
            0,
            0,
            0,
            29.39895,
            0,
            14.7023,
            "<D",
            123.5771,
            0,
        ],
        1728125580: [
            0.28157,
            0,
            0.28157,
            0,
            27.87363,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            47.56752,
            0,
            0.74243,
            0,
            15.00188,
            0,
            -0.07615,
            0,
            0,
            0,
            29.39948,
            0,
            13.87213,
            "<D",
            123.5826,
            0,
        ],
        1728125640: [
            0.34537,
            0,
            0.34537,
            0,
            34.75958,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            32.94899,
            0,
            0.74315,
            0,
            15.04887,
            0,
            -0.09601,
            0,
            0,
            0,
            29.40184,
            0,
            16.03485,
            "<D",
            123.3834,
            0,
        ],
        1728125700: [
            0.09395,
            0,
            0.09395,
            0,
            40.32526,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            25.45502,
            0,
            0.8456,
            0,
            15.06785,
            0,
            -0.04966,
            0,
            0,
            0,
            29.40317,
            0,
            15.64116,
            "<D",
            123.5726,
            0,
        ],
        1728125760: [
            0.11869,
            0,
            0.11869,
            0,
            40.72478,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            28.75583,
            0,
            0.75187,
            0,
            15.04566,
            0,
            -0.08608,
            0,
            0,
            0,
            29.40314,
            0,
            12.84064,
            "<D",
            123.6158,
            0,
        ],
        1728125820: [
            0.13911,
            0,
            0.13911,
            0,
            42.69459,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            30.04651,
            0,
            0.67611,
            0,
            15.0302,
            0,
            -0.07284,
            0,
            0,
            0,
            29.40352,
            0,
            19.1813,
            "<D",
            123.5455,
            0,
        ],
        1728125880: [
            0.40451,
            0,
            0.40451,
            0,
            42.0566,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            26.17423,
            0,
            0.69837,
            0,
            15.02721,
            0,
            -0.07284,
            0,
            0,
            0,
            29.40489,
            0,
            13.1337,
            "<D",
            123.4044,
            0,
        ],
        1728125940: [
            0.27613,
            0,
            0.27613,
            0,
            40.98429,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            21.55878,
            0,
            0.68131,
            0,
            15.04809,
            0,
            -0.14568,
            0,
            0,
            0,
            29.40482,
            0,
            18.53431,
            "<D",
            123.2759,
            0,
        ],
        1728126000: [
            0.12234,
            0,
            0.12234,
            0,
            41.13734,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            24.73672,
            0,
            0.64216,
            0,
            15.02286,
            0,
            -0.28142,
            0,
            0,
            0,
            29.40542,
            0,
            13.92126,
            "<D",
            123.4461,
            0,
        ],
        1728126060: [
            0.36204,
            0,
            0.36204,
            0,
            40.93459,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            2.12152,
            0,
            0.48112,
            0,
            15.04682,
            0,
            -0.22844,
            0,
            0,
            0,
            29.40591,
            0,
            17.36623,
            "<D",
            123.5487,
            0,
        ],
        1728126120: [
            0.30902,
            0,
            0.30902,
            0,
            40.29747,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            0.44448,
            0,
            0.28318,
            0,
            15.12313,
            0,
            -0.16886,
            0,
            0,
            0,
            29.40618,
            0,
            18.16407,
            "<D",
            123.2432,
            0,
        ],
        1728126180: [
            0.27891,
            0,
            0.27891,
            0,
            39.69624,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            337.6175,
            0,
            0.19363,
            0,
            15.1398,
            0,
            -0.23176,
            0,
            0,
            0,
            29.40532,
            0,
            15.47904,
            "<D",
            123.5596,
            0,
        ],
        1728126240: [
            0.28263,
            0,
            0.28263,
            0,
            39.32408,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            271.1641,
            0,
            0.22341,
            0,
            15.14056,
            0,
            -0.2119,
            0,
            0,
            0,
            29.40457,
            0,
            18.06865,
            "<D",
            123.5763,
            0,
        ],
        1728126300: [
            0.17928,
            0,
            0.17928,
            0,
            38.62223,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            255.8932,
            0,
            0.21258,
            0,
            15.06665,
            0,
            -0.22514,
            0,
            0,
            0,
            29.40587,
            0,
            17.37223,
            "<D",
            123.5356,
            0,
        ],
        1728126360: [
            0.16793,
            0,
            0.16793,
            0,
            37.61995,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            170.4406,
            0,
            0.2308,
            0,
            14.92089,
            0,
            -0.22514,
            0,
            0,
            0,
            29.40662,
            0,
            15.66128,
            "<D",
            123.6202,
            0,
        ],
        1728126420: [
            0.21784,
            0,
            0.21784,
            0,
            36.13753,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            155.0243,
            0,
            0.19934,
            0,
            14.8885,
            0,
            -0.18541,
            0,
            0,
            0,
            29.40674,
            0,
            13.24802,
            "<D",
            123.5446,
            0,
        ],
        1728126480: [
            0.03759,
            0,
            0.03759,
            0,
            36.35748,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            59.65453,
            0,
            0.23036,
            0,
            14.84195,
            0,
            -0.15892,
            0,
            0,
            0,
            29.40669,
            0,
            14.44253,
            "<D",
            123.5652,
            0,
        ],
        1728126540: [
            0.22276,
            0,
            0.22276,
            0,
            37.46344,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            43.80499,
            0,
            0.54968,
            0,
            14.71706,
            0,
            -0.13905,
            0,
            0,
            0,
            29.40669,
            0,
            19.13958,
            "<D",
            123.6289,
            0,
        ],
        1728126600: [
            0.14629,
            0,
            0.14629,
            0,
            38.27929,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            46.85921,
            0,
            0.58362,
            0,
            14.53508,
            0,
            -0.09602,
            0,
            0,
            0,
            29.40658,
            0,
            12.51977,
            "<D",
            123.6331,
            0,
        ],
        1728126660: [
            0.22456,
            0,
            0.22456,
            0,
            38.72506,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            47.24104,
            0,
            0.51584,
            0,
            14.42093,
            0,
            -0.12913,
            0,
            0,
            0,
            29.40646,
            0,
            17.16736,
            "<D",
            123.4939,
            0,
        ],
        1728126720: [
            0.23611,
            0,
            0.23611,
            0,
            39.63142,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            91.44832,
            0,
            0.25278,
            0,
            14.47395,
            0,
            -0.14237,
            0,
            0,
            0,
            29.40645,
            0,
            12.36654,
            "<D",
            123.5716,
            0,
        ],
        1728126780: [
            0.40939,
            0,
            0.40939,
            0,
            35.9958,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            131.0291,
            0,
            0.22077,
            0,
            14.59266,
            0,
            -0.1523,
            0,
            0,
            0,
            29.40613,
            0,
            15.7563,
            "<D",
            123.474,
            0,
        ],
        1728126840: [
            0.32279,
            0,
            0.32279,
            0,
            27.98983,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            164.3319,
            0,
            0.2184,
            0,
            14.53267,
            0,
            -0.13906,
            0,
            0,
            0,
            29.40645,
            0,
            12.87797,
            "<D",
            123.318,
            0,
        ],
        1728126900: [
            -0.10962,
            0,
            -0.10962,
            0,
            25.88604,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            176.8081,
            0,
            0.2215,
            0,
            14.59736,
            0,
            -0.13906,
            0,
            0,
            0,
            29.40574,
            0,
            16.3766,
            "<D",
            123.4032,
            0,
        ],
        1728126960: [
            0.19022,
            0,
            0.19022,
            0,
            22.13743,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            145.7171,
            0,
            0.22098,
            0,
            14.58796,
            0,
            -0.14237,
            0,
            0,
            0,
            29.40645,
            0,
            18.38267,
            "<D",
            123.5827,
            0,
        ],
        1728127020: [
            0.28907,
            0,
            0.28907,
            0,
            28.43911,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            121.5348,
            0,
            0.21959,
            0,
            14.59658,
            0,
            -0.17548,
            0,
            0,
            0,
            29.40645,
            0,
            13.99555,
            "<D",
            123.6236,
            0,
        ],
        1728127080: [
            0.25042,
            0,
            0.25042,
            0,
            29.73981,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            82.19685,
            0,
            0.22045,
            0,
            14.63471,
            0,
            -0.09933,
            0,
            0,
            0,
            29.40648,
            0,
            16.42175,
            "<D",
            123.6068,
            0,
        ],
        1728127140: [
            0.09238,
            0,
            0.09238,
            0,
            24.12261,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            54.37013,
            0,
            0.22315,
            0,
            14.70026,
            0,
            -0.09933,
            0,
            0,
            0,
            29.40693,
            0,
            15.4859,
            "<D",
            123.3132,
            0,
        ],
        1728127200: [
            0.30512,
            0,
            0.30512,
            0,
            26.70614,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            51.70466,
            0,
            0.2983,
            0,
            14.7681,
            0,
            -0.03973,
            0,
            0,
            0,
            29.40973,
            0,
            13.44837,
            "<D",
            123.5582,
            0,
        ],
        1728127260: [
            0.17395,
            0,
            0.17395,
            0,
            25.5892,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            54.59109,
            0,
            0.71213,
            0,
            14.78949,
            0,
            -0.03973,
            0,
            0,
            0,
            29.41018,
            0,
            12.98523,
            "<D",
            123.6768,
            0,
        ],
        1728127320: [
            0.14537,
            0,
            0.14537,
            0,
            26.03454,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            65.62579,
            0,
            0.59199,
            0,
            14.75144,
            0,
            -0.03311,
            0,
            0,
            0,
            29.4104,
            0,
            16.49107,
            "<D",
            123.6134,
            0,
        ],
        1728127380: [
            0.17548,
            0,
            0.17548,
            0,
            16.60097,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            65.9744,
            0,
            0.59749,
            0,
            14.84523,
            0,
            0.05629,
            0,
            0,
            0,
            29.41043,
            0,
            16.33536,
            "<D",
            123.6851,
            0,
        ],
        1728127440: [
            0.31353,
            0,
            0.31353,
            0,
            21.69965,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            71.37431,
            0,
            0.59328,
            0,
            14.62315,
            0,
            0.11589,
            0,
            0,
            0,
            29.41038,
            0,
            16.0147,
            "<D",
            123.7289,
            0,
        ],
        1728127500: [
            0.20845,
            0,
            0.20845,
            0,
            22.9718,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            59.91889,
            0,
            0.614,
            0,
            14.76557,
            0,
            0.18542,
            0,
            0,
            0,
            29.40981,
            0,
            15.66292,
            "<D",
            123.7457,
            0,
        ],
        1728127560: [
            0.11338,
            0,
            0.11338,
            0,
            29.7938,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            52.71047,
            0,
            0.66158,
            0,
            14.96248,
            0,
            0.32116,
            0,
            0,
            0,
            29.40971,
            0,
            14.30471,
            "<D",
            123.6526,
            0,
        ],
        1728127620: [
            0.08189,
            0,
            0.08189,
            0,
            34.72082,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            46.41957,
            0,
            0.63747,
            0,
            15.1187,
            0,
            0.43373,
            0,
            0,
            0,
            29.40974,
            0,
            16.10263,
            "<D",
            123.5981,
            0,
        ],
        1728127680: [
            0.20469,
            0,
            0.20469,
            0,
            40.63948,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            39.03617,
            0,
            0.5125,
            0,
            15.21383,
            0,
            0.54962,
            0,
            0,
            0,
            29.41004,
            0,
            16.23442,
            "<D",
            123.8327,
            0,
        ],
        1728127740: [
            0.23091,
            0,
            0.23091,
            0,
            42.44637,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            43.9566,
            0,
            0.62511,
            0,
            15.28003,
            0,
            0.66882,
            0,
            0,
            0,
            29.41123,
            0,
            14.62661,
            "<D",
            123.8234,
            0,
        ],
        1728127800: [
            0.35931,
            0,
            0.35931,
            0,
            42.30941,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            39.93333,
            0,
            0.667,
            0,
            15.30157,
            0,
            0.80128,
            0,
            0,
            0,
            29.41216,
            0,
            14.81447,
            "<D",
            123.9323,
            0,
        ],
        1728127860: [
            0.15714,
            0,
            0.15714,
            0,
            42.01416,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            46.58503,
            0,
            0.55633,
            0,
            15.33316,
            0,
            0.95028,
            0,
            0,
            0,
            29.41325,
            0,
            15.34238,
            "<D",
            123.8345,
            0,
        ],
        1728127920: [
            0.16185,
            0,
            0.16185,
            0,
            41.23774,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            46.38221,
            0,
            0.53654,
            0,
            15.35653,
            0,
            1.10924,
            0,
            0,
            0,
            29.41337,
            0,
            17.02302,
            "<D",
            123.7674,
            0,
        ],
        1728127980: [
            0.32906,
            0,
            0.32906,
            0,
            42.27055,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            43.798,
            0,
            0.43926,
            0,
            15.26458,
            0,
            1.30464,
            0,
            0,
            0,
            29.41353,
            0,
            12.53017,
            "<D",
            123.7704,
            0,
        ],
        1728128040: [
            0.22567,
            0,
            0.22567,
            0,
            40.42059,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            54.62965,
            0,
            0.52583,
            0,
            14.89315,
            0,
            1.47685,
            0,
            0,
            0,
            29.41348,
            0,
            14.95477,
            "<D",
            123.7394,
            0,
        ],
        1728128100: [
            0.19661,
            0,
            0.19661,
            0,
            38.17596,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            64.66726,
            0,
            0.45927,
            0,
            14.77995,
            0,
            1.69211,
            0,
            0,
            0,
            29.41361,
            0,
            13.14163,
            "<D",
            123.6454,
            0,
        ],
        1728128160: [
            0.38389,
            0,
            0.38389,
            0,
            38.55939,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            48.81866,
            0,
            0.47521,
            0,
            14.82966,
            0,
            1.92063,
            0,
            0,
            0,
            29.41383,
            0,
            16.1802,
            "<D",
            123.8842,
            0,
        ],
        1728128220: [
            0.25807,
            0,
            0.25807,
            0,
            38.82703,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            75.58068,
            0,
            0.52249,
            0,
            14.83636,
            0,
            2.19885,
            0,
            0,
            0,
            29.41424,
            0,
            14.97804,
            "<D",
            123.946,
            0,
        ],
        1728128280: [
            0.2618,
            0,
            0.2618,
            0,
            36.53507,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            89.23727,
            0,
            0.36778,
            0,
            14.72853,
            0,
            2.40752,
            0,
            0,
            0,
            29.41552,
            0,
            15.23541,
            "<D",
            124.0025,
            0,
        ],
        1728128340: [
            0.36301,
            0,
            0.36301,
            0,
            33.82728,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            58.28794,
            0,
            0.26536,
            0,
            14.71042,
            0,
            2.61955,
            0,
            0,
            0,
            29.41612,
            0,
            17.80287,
            "<D",
            123.9351,
            0,
        ],
        1728128400: [
            0.30973,
            0,
            0.30973,
            0,
            35.80714,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            45.96723,
            0,
            0.22514,
            0,
            14.74235,
            0,
            2.91775,
            0,
            0,
            0,
            29.41581,
            0,
            13.36141,
            "<D",
            123.8978,
            0,
        ],
        1728128460: [
            0.22736,
            0,
            0.22736,
            0,
            33.3068,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            54.66046,
            0,
            0.52166,
            0,
            14.8274,
            0,
            3.18947,
            0,
            0,
            0,
            29.417,
            0,
            16.70532,
            "<D",
            124.0635,
            0,
        ],
        1728128520: [
            0.22815,
            0,
            0.22815,
            0,
            21.40907,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            50.49473,
            0,
            0.62218,
            0,
            14.67938,
            0,
            3.63331,
            0,
            0,
            0,
            29.41726,
            0,
            12.92463,
            "<D",
            123.9641,
            0,
        ],
        1728128580: [
            0.28194,
            0,
            0.28194,
            0,
            30.40399,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            46.62846,
            0,
            0.63419,
            0,
            14.57478,
            0,
            3.93827,
            0,
            0,
            0,
            29.41753,
            0,
            17.85895,
            "<D",
            123.5963,
            0,
        ],
        1728128640: [
            0.11801,
            0,
            0.11801,
            0,
            35.77404,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            43.12742,
            0,
            0.56113,
            0,
            14.62118,
            0,
            4.36907,
            0,
            0,
            0,
            29.41844,
            0,
            15.73293,
            "<D",
            123.8999,
            0,
        ],
        1728128700: [
            0.31431,
            0,
            0.31431,
            0,
            35.02946,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            38.76032,
            0,
            0.50058,
            0,
            14.63696,
            0,
            4.86949,
            0,
            0,
            0,
            29.41743,
            0,
            17.33981,
            "<D",
            123.7869,
            0,
        ],
        1728128760: [
            0.23135,
            0,
            0.23135,
            0,
            34.54641,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            41.50142,
            0,
            0.34158,
            0,
            14.4961,
            0,
            5.35339,
            0,
            0,
            0,
            29.41829,
            0,
            12.13656,
            "<D",
            123.9759,
            0,
        ],
        1728128820: [
            0.22061,
            0,
            0.22061,
            0,
            35.79162,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            51.85274,
            0,
            0.21948,
            0,
            14.54509,
            0,
            5.86051,
            0,
            0,
            0,
            29.41941,
            0,
            15.92981,
            "<D",
            124.0179,
            0,
        ],
        1728128880: [
            0.35864,
            0,
            0.35864,
            0,
            34.29438,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            55.21934,
            0,
            0.22028,
            0,
            14.59118,
            0,
            6.39426,
            0,
            0,
            0,
            29.42101,
            0,
            15.67137,
            "<D",
            123.9253,
            0,
        ],
        1728128940: [
            0.19523,
            0,
            0.19523,
            0,
            32.2808,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            56.90161,
            0,
            0.2237,
            0,
            14.5145,
            0,
            6.94793,
            0,
            0,
            0,
            29.42102,
            0,
            11.62018,
            "<D",
            123.9553,
            0,
        ],
        1728129000: [
            0.15654,
            0,
            0.15654,
            0,
            26.92229,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            50.01031,
            0,
            0.2227,
            0,
            14.49071,
            0,
            7.55131,
            0,
            0,
            0,
            29.42094,
            0,
            13.60305,
            "<D",
            123.9656,
            0,
        ],
        1728129060: [
            0.04026,
            0,
            0.04026,
            0,
            26.79859,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            44.31979,
            0,
            0.22357,
            0,
            14.44149,
            0,
            8.11498,
            0,
            0,
            0,
            29.42138,
            0,
            14.40899,
            "<D",
            123.7395,
            0,
        ],
        1728129120: [
            0.20649,
            0,
            0.20649,
            0,
            25.1448,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            37.31495,
            0,
            0.2237,
            0,
            14.39054,
            0,
            8.725,
            0,
            0,
            0,
            29.42061,
            0,
            15.91063,
            "<D",
            124.0497,
            0,
        ],
        1728129180: [
            0.2125,
            0,
            0.2125,
            0,
            19.36506,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            33.34496,
            0,
            0.22345,
            0,
            14.28929,
            0,
            9.3186,
            0,
            0,
            0,
            29.42102,
            0,
            14.76956,
            "<D",
            123.7921,
            0,
        ],
        1728129240: [
            0.36296,
            0,
            0.36296,
            0,
            21.5347,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            34.49593,
            0,
            0.22366,
            0,
            14.21286,
            0,
            9.919,
            0,
            0,
            0,
            29.42257,
            0,
            14.59461,
            "<D",
            123.6331,
            0,
        ],
        1728129300: [
            0.1965,
            0,
            0.1965,
            0,
            24.60182,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            34.54001,
            0,
            0.22355,
            0,
            14.27061,
            0,
            10.46004,
            0,
            0,
            0,
            29.42116,
            0,
            14.98752,
            "<D",
            123.8736,
            0,
        ],
        1728129360: [
            0.27479,
            0,
            0.27479,
            0,
            22.32232,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            52.36365,
            0,
            0.22257,
            0,
            14.38152,
            0,
            11.12357,
            0,
            0,
            0,
            29.42323,
            0,
            15.03208,
            "<D",
            124.0791,
            0,
        ],
        1728129420: [
            0.23315,
            0,
            0.23315,
            0,
            23.71785,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            71.96085,
            0,
            0.22062,
            0,
            14.35978,
            0,
            11.711,
            0,
            0,
            0,
            29.42401,
            0,
            14.12539,
            "<D",
            124.0585,
            0,
        ],
        1728129480: [
            0.1216,
            0,
            0.1216,
            0,
            19.8084,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            76.77084,
            0,
            0.2212,
            0,
            14.33107,
            0,
            12.29838,
            0,
            0,
            0,
            29.424,
            0,
            13.19143,
            "<D",
            124.067,
            0,
        ],
        1728129540: [
            0.2157,
            0,
            0.2157,
            0,
            18.12665,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            69.50848,
            0,
            0.2202,
            0,
            14.37399,
            0,
            12.88552,
            0,
            0,
            0,
            29.4237,
            0,
            16.66041,
            "<D",
            123.8012,
            0,
        ],
        1728129600: [
            0.17399,
            0,
            0.17399,
            0,
            19.3896,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            63.67076,
            0,
            0.22315,
            0,
            14.30008,
            0,
            13.42029,
            0,
            0,
            0,
            29.42377,
            0,
            14.98113,
            "<D",
            123.7983,
            0,
        ],
        1728129660: [
            0.21522,
            0,
            0.21522,
            0,
            21.14754,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            72.29933,
            0,
            0.22373,
            0,
            14.22134,
            0,
            13.97813,
            0,
            0,
            0,
            29.42418,
            0,
            15.78718,
            "<D",
            123.7504,
            0,
        ],
        1728129720: [
            0.15395,
            0,
            0.15395,
            0,
            18.02331,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            69.73257,
            0,
            0.22183,
            0,
            14.21366,
            0,
            14.483,
            0,
            0,
            0,
            29.42422,
            0,
            17.08731,
            "<D",
            123.7183,
            0,
        ],
        1728129780: [
            0.26608,
            0,
            0.26608,
            0,
            20.33728,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            74.40695,
            0,
            0.2235,
            0,
            14.20917,
            0,
            15.01105,
            0,
            0,
            0,
            29.42427,
            0,
            14.50116,
            "<D",
            123.8279,
            0,
        ],
        1728129840: [
            0.04679,
            0,
            0.04679,
            0,
            19.50021,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            75.3632,
            0,
            0.22373,
            0,
            14.23009,
            0,
            15.52247,
            0,
            0,
            0,
            29.42441,
            0,
            16.62564,
            "<D",
            123.6433,
            0,
        ],
        1728129900: [
            0.20003,
            0,
            0.20003,
            0,
            18.7964,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            70.67558,
            0,
            0.2237,
            0,
            14.30614,
            0,
            16.13323,
            0,
            0,
            0,
            29.42485,
            0,
            14.36904,
            "<D",
            123.7623,
            0,
        ],
        1728129960: [
            0.3785,
            0,
            0.3785,
            0,
            18.14569,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            69.82873,
            0,
            0.22365,
            0,
            14.32245,
            0,
            16.72391,
            0,
            0,
            0,
            29.42422,
            0,
            16.91536,
            "<D",
            123.7206,
            0,
        ],
        1728130020: [
            0.24732,
            0,
            0.24732,
            0,
            18.44462,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            72.2944,
            0,
            0.22372,
            0,
            14.29998,
            0,
            17.2587,
            0,
            0,
            0,
            29.42402,
            0,
            17.82648,
            "<D",
            123.4202,
            0,
        ],
        1728130080: [
            0.24832,
            0,
            0.24832,
            0,
            18.50698,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            73.07559,
            0,
            0.22373,
            0,
            14.31974,
            0,
            17.81669,
            0,
            0,
            0,
            29.42485,
            0,
            12.81704,
            "<D",
            123.7342,
            0,
        ],
        1728130140: [
            0.20883,
            0,
            0.20883,
            0,
            17.98873,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            64.91204,
            0,
            0.22362,
            0,
            14.34064,
            0,
            18.29832,
            0,
            0,
            0,
            29.4243,
            0,
            17.6298,
            "<D",
            123.7431,
            0,
        ],
        1728130200: [
            0.3336,
            0,
            0.3336,
            0,
            19.69884,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            58.91991,
            0,
            0.22341,
            0,
            14.33139,
            0,
            18.83661,
            0,
            0,
            0,
            29.42409,
            0,
            14.32408,
            "<D",
            123.6966,
            0,
        ],
        1728130260: [
            0.31006,
            0,
            0.31006,
            0,
            21.5465,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            65.33333,
            0,
            0.22331,
            0,
            14.31337,
            0,
            19.30849,
            0,
            0,
            0,
            29.42462,
            0,
            15.57531,
            "<D",
            123.8463,
            0,
        ],
        1728130320: [
            0.15931,
            0,
            0.15931,
            0,
            21.73468,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            64.23072,
            0,
            0.22311,
            0,
            14.2995,
            0,
            19.81366,
            0,
            0,
            0,
            29.42534,
            0,
            11.93437,
            "<D",
            123.7118,
            0,
        ],
        1728130380: [
            0.18648,
            0,
            0.18648,
            0,
            21.0749,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            68.7198,
            0,
            0.22367,
            0,
            14.30114,
            0,
            20.29578,
            0,
            0,
            0,
            29.42604,
            0,
            15.40094,
            "<D",
            123.7573,
            0,
        ],
        1728130440: [
            0.00754,
            0,
            0.00754,
            0,
            19.91395,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            63.2314,
            0,
            0.22279,
            0,
            14.35083,
            0,
            20.76119,
            0,
            0,
            0,
            29.42696,
            0,
            15.54439,
            "<D",
            123.7366,
            0,
        ],
        1728130500: [
            0.21348,
            0,
            0.21348,
            0,
            21.87797,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            58.9349,
            0,
            0.22373,
            0,
            14.36676,
            0,
            21.23343,
            0,
            0,
            0,
            29.42654,
            0,
            15.09339,
            "<D",
            123.4894,
            0,
        ],
        1728130560: [
            0.25504,
            0,
            0.25504,
            0,
            18.65385,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            58.3518,
            0,
            0.22373,
            0,
            14.38458,
            0,
            21.73874,
            0,
            0,
            0,
            29.42748,
            0,
            15.70742,
            "<D",
            123.5203,
            0,
        ],
        1728130620: [
            0.23817,
            0,
            0.23817,
            0,
            16.63967,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            57.20464,
            0,
            0.22371,
            0,
            14.4058,
            0,
            22.16138,
            0,
            0,
            0,
            29.42756,
            0,
            18.21992,
            "<D",
            123.6979,
            0,
        ],
        1728130680: [
            0.1136,
            0,
            0.1136,
            0,
            15.82957,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            55.85699,
            0,
            0.22346,
            0,
            14.34848,
            0,
            22.60754,
            0,
            0,
            0,
            29.42756,
            0,
            13.96338,
            "<D",
            123.636,
            0,
        ],
        1728130740: [
            0.07542,
            0,
            0.07542,
            0,
            17.07648,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            51.11247,
            0,
            0.22359,
            0,
            14.26283,
            0,
            23.09647,
            0,
            0,
            0,
            29.42756,
            0,
            14.94665,
            "<D",
            123.7896,
            0,
        ],
        1728130800: [
            0.23566,
            0,
            0.23566,
            0,
            20.58936,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            53.19517,
            0,
            0.22371,
            0,
            14.34669,
            0,
            23.56552,
            0,
            0,
            0,
            29.42805,
            0,
            13.4333,
            "<D",
            123.7339,
            0,
        ],
        1728130860: [
            0.2221,
            0,
            0.2221,
            0,
            22.0961,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            56.10806,
            0,
            0.22283,
            0,
            14.34587,
            0,
            24.0348,
            0,
            0,
            0,
            29.43154,
            0,
            13.53914,
            "<D",
            123.7054,
            0,
        ],
        1728130920: [
            0.04584,
            0,
            0.04584,
            0,
            22.52321,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            56.97797,
            0,
            0.22372,
            0,
            14.31058,
            0,
            24.5239,
            0,
            0,
            0,
            29.43073,
            0,
            13.77899,
            "<D",
            123.5843,
            0,
        ],
        1728130980: [
            0.25313,
            0,
            0.25313,
            0,
            25.80404,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            46.08921,
            0,
            0.22354,
            0,
            14.36515,
            0,
            24.99975,
            0,
            0,
            0,
            29.43195,
            0,
            14.87011,
            "<D",
            123.4965,
            0,
        ],
        1728131040: [
            0.20972,
            0,
            0.20972,
            0,
            26.00789,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            41.60801,
            0,
            0.22356,
            0,
            14.39235,
            0,
            25.49553,
            0,
            0,
            0,
            29.43214,
            0,
            13.17339,
            "<D",
            123.3185,
            0,
        ],
        1728131100: [
            0.29379,
            0,
            0.29379,
            0,
            24.31234,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            49.32354,
            0,
            0.22183,
            0,
            14.43887,
            0,
            25.93147,
            0,
            0,
            0,
            29.43272,
            0,
            15.54578,
            "<D",
            123.3964,
            0,
        ],
        1728131160: [
            0.05543,
            0,
            0.05543,
            0,
            24.63219,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            56.15596,
            0,
            0.22364,
            0,
            14.48225,
            0,
            26.43068,
            0,
            0,
            0,
            29.43301,
            0,
            15.69804,
            "<D",
            123.2805,
            0,
        ],
        1728131220: [
            0.16498,
            0,
            0.16498,
            0,
            27.55781,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            45.45805,
            0,
            0.22354,
            0,
            14.5592,
            0,
            26.87002,
            0,
            0,
            0,
            29.43304,
            0,
            15.4974,
            "<D",
            123.4674,
            0,
        ],
        1728131280: [
            0.32485,
            0,
            0.32485,
            0,
            28.03408,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            35.31565,
            0,
            0.22088,
            0,
            14.688,
            0,
            27.34916,
            0,
            0,
            0,
            29.43312,
            0,
            14.86546,
            "<D",
            123.5368,
            0,
        ],
        1728131340: [
            0.12765,
            0,
            0.12765,
            0,
            27.13763,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            39.66972,
            0,
            0.21868,
            0,
            14.7182,
            0,
            27.81468,
            0,
            0,
            0,
            29.43307,
            0,
            17.24251,
            "<D",
            123.506,
            0,
        ],
        1728131400: [
            0.29245,
            0,
            0.29245,
            0,
            26.59138,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            40.22112,
            0,
            0.22303,
            0,
            14.87998,
            0,
            28.26069,
            0,
            0,
            0,
            29.43298,
            0,
            14.72913,
            "<D",
            123.6123,
            0,
        ],
        1728131460: [
            0.26093,
            0,
            0.26093,
            0,
            26.84641,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            40.36966,
            0,
            0.22252,
            0,
            14.99913,
            0,
            28.67358,
            0,
            0,
            0,
            29.43268,
            0,
            14.09756,
            "<D",
            123.5953,
            0,
        ],
        1728131520: [
            0.26237,
            0,
            0.26237,
            0,
            26.95236,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            48.4797,
            0,
            0.22147,
            0,
            15.11258,
            0,
            29.08654,
            0,
            0,
            0,
            29.43267,
            0,
            18.8301,
            "<D",
            123.4293,
            0,
        ],
        1728131580: [
            0.28526,
            0,
            0.28526,
            0,
            25.76027,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            27.27525,
            0,
            0.21472,
            0,
            15.16965,
            0,
            29.47363,
            0,
            0,
            0,
            29.43294,
            0,
            12.61658,
            "<D",
            123.6049,
            0,
        ],
        1728131640: [
            0.18379,
            0,
            0.18379,
            0,
            26.51697,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            21.67703,
            0,
            0.22022,
            0,
            15.24192,
            0,
            30.39282,
            0,
            0,
            0,
            29.43266,
            0,
            15.88324,
            "<D",
            123.4948,
            0,
        ],
        1728131700: [
            0.02498,
            0,
            0.02498,
            0,
            26.98596,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            34.95451,
            0,
            0.22203,
            0,
            15.29618,
            0,
            31.72556,
            0,
            0,
            0,
            29.43357,
            0,
            15.86586,
            "<D",
            123.534,
            0,
        ],
        1728131760: [
            0.12575,
            0,
            0.12575,
            0,
            27.77437,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            30.29223,
            0,
            0.22568,
            0,
            15.31877,
            0,
            32.07232,
            0,
            0,
            0,
            29.43304,
            0,
            15.54569,
            "<D",
            123.3812,
            0,
        ],
        1728131820: [
            0.26414,
            0,
            0.26414,
            0,
            28.99383,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            22.88707,
            0,
            0.89898,
            0,
            15.42363,
            0,
            31.65235,
            0,
            0,
            0,
            29.43495,
            0,
            14.54534,
            "<D",
            123.1804,
            0,
        ],
        1728131880: [
            0.19613,
            0,
            0.19613,
            0,
            30.09675,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            24.82792,
            0,
            0.89757,
            0,
            15.52509,
            0,
            31.63914,
            0,
            0,
            0,
            29.43495,
            0,
            16.20102,
            "<D",
            123.178,
            0,
        ],
        1728131940: [
            0.34441,
            0,
            0.34441,
            0,
            29.79738,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            16.65229,
            0,
            0.75731,
            0,
            15.58422,
            0,
            31.99244,
            0,
            0,
            0,
            29.43401,
            0,
            18.45067,
            "<D",
            123.0506,
            0,
        ],
        1728132000: [
            0.16793,
            0,
            0.16793,
            0,
            29.18026,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            21.78788,
            0,
            0.60992,
            0,
            15.59289,
            0,
            32.46226,
            0,
            0,
            0,
            29.43458,
            0,
            18.13338,
            "<D",
            122.9185,
            0,
        ],
        1728132060: [
            0.14683,
            0,
            0.14683,
            0,
            29.39533,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            24.53834,
            0,
            0.67964,
            0,
            15.52755,
            0,
            33.10875,
            0,
            0,
            0,
            29.43389,
            0,
            15.91803,
            "<D",
            123.2756,
            0,
        ],
        1728132120: [
            0.12951,
            0,
            0.12951,
            0,
            28.91202,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            27.11681,
            0,
            0.6204,
            0,
            15.54639,
            0,
            34.02531,
            0,
            0,
            0,
            29.43312,
            0,
            15.03828,
            "<D",
            123.2144,
            0,
        ],
        1728132180: [
            0.16025,
            0,
            0.16025,
            0,
            28.65754,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            22.86842,
            0,
            0.67809,
            0,
            15.59001,
            0,
            34.19864,
            0,
            0,
            0,
            29.43406,
            0,
            18.85581,
            "<D",
            123.2413,
            0,
        ],
        1728132240: [
            0.30267,
            0,
            0.30267,
            0,
            29.03243,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            20.51821,
            0,
            0.61992,
            0,
            15.67517,
            0,
            34.09782,
            0,
            0,
            0,
            29.43575,
            0,
            17.07309,
            "<D",
            123.1215,
            0,
        ],
        1728132300: [
            0.14519,
            0,
            0.14519,
            0,
            29.10219,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            6.4058,
            0,
            0.70763,
            0,
            15.75799,
            0,
            34.40754,
            0,
            0,
            0,
            29.43582,
            0,
            17.02396,
            "<D",
            123.2037,
            0,
        ],
        1728132360: [
            0.18606,
            0,
            0.18606,
            0,
            29.11154,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            6.35488,
            0,
            0.76787,
            0,
            15.79746,
            0,
            34.80627,
            0,
            0,
            0,
            29.43594,
            0,
            18.3731,
            "<D",
            123.4682,
            0,
        ],
        1728132420: [
            0.26898,
            0,
            0.26898,
            0,
            28.87576,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            10.28088,
            0,
            0.82896,
            0,
            15.81165,
            0,
            35.2366,
            0,
            0,
            0,
            29.43631,
            0,
            20.36191,
            "<D",
            123.3066,
            0,
        ],
        1728132480: [
            0.2356,
            0,
            0.2356,
            0,
            28.93248,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            22.64079,
            0,
            0.73669,
            0,
            15.86443,
            0,
            35.58453,
            0,
            0,
            0,
            29.43652,
            0,
            20.67693,
            "<D",
            123.052,
            0,
        ],
        1728132540: [
            0.21447,
            0,
            0.21447,
            0,
            28.5654,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            25.16343,
            0,
            0.64549,
            0,
            15.89623,
            0,
            35.99155,
            0,
            0,
            0,
            29.43666,
            0,
            17.54622,
            "<D",
            123.3798,
            0,
        ],
        1728132600: [
            0.24497,
            0,
            0.24497,
            0,
            28.79851,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            14.41621,
            0,
            0.66274,
            0,
            15.94649,
            0,
            36.40498,
            0,
            0,
            0,
            29.4366,
            0,
            17.52273,
            "<D",
            123.2934,
            0,
        ],
        1728132660: [
            0.19705,
            0,
            0.19705,
            0,
            28.59233,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            28.41198,
            0,
            0.63968,
            0,
            15.97962,
            0,
            37.1014,
            0,
            0,
            0,
            29.43657,
            0,
            17.92404,
            "<D",
            123.318,
            0,
        ],
        1728132720: [
            0.27191,
            0,
            0.27191,
            0,
            28.31998,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            20.87277,
            0,
            0.72737,
            0,
            16.00914,
            0,
            38.10517,
            0,
            0,
            0,
            29.43621,
            0,
            18.81184,
            "<D",
            123.2871,
            0,
        ],
        1728132780: [
            0.22166,
            0,
            0.22166,
            0,
            25.48988,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            13.57684,
            0,
            0.65713,
            0,
            16.01735,
            0,
            38.37233,
            0,
            0,
            0,
            29.43641,
            0,
            22.1849,
            "<D",
            123.1871,
            0,
        ],
        1728132840: [
            0.24211,
            0,
            0.24211,
            0,
            25.22772,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            11.25742,
            0,
            0.73647,
            0,
            16.06803,
            0,
            38.42187,
            0,
            0,
            0,
            29.43624,
            0,
            20.50169,
            "<D",
            123.0696,
            0,
        ],
        1728132900: [
            0.31345,
            0,
            0.31345,
            0,
            26.25303,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            13.33441,
            0,
            0.62999,
            0,
            16.10726,
            0,
            38.89811,
            0,
            0,
            0,
            29.43589,
            0,
            19.26985,
            "<D",
            122.9741,
            0,
        ],
        1728132960: [
            0.12621,
            0,
            0.12621,
            0,
            26.47188,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            32.6235,
            0,
            0.44376,
            0,
            16.13171,
            0,
            39.15124,
            0,
            0,
            0,
            29.43585,
            0,
            18.80307,
            "<D",
            122.5992,
            0,
        ],
        1728133020: [
            0.08897,
            0,
            0.08897,
            0,
            27.12961,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            34.17997,
            0,
            0.31414,
            0,
            16.20521,
            0,
            39.45309,
            0,
            0,
            0,
            29.436,
            0,
            18.36582,
            "<D",
            122.6983,
            0,
        ],
        1728133080: [
            0.18246,
            0,
            0.18246,
            0,
            27.67093,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            30.58657,
            0,
            0.29456,
            0,
            16.28353,
            0,
            39.83061,
            0,
            0,
            0,
            29.43617,
            0,
            18.53866,
            "<D",
            122.5496,
            0,
        ],
        1728133140: [
            0.32136,
            0,
            0.32136,
            0,
            26.45112,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            25.0137,
            0,
            0.53612,
            0,
            16.29718,
            0,
            40.27466,
            0,
            0,
            0,
            29.43736,
            0,
            18.7906,
            "<D",
            122.6321,
            0,
        ],
        1728133200: [
            0.16832,
            0,
            0.16832,
            0,
            27.76533,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            25.12818,
            0,
            0.66132,
            0,
            16.34836,
            0,
            40.87873,
            0,
            0,
            0,
            29.43655,
            0,
            22.48402,
            "<D",
            122.7741,
            0,
        ],
        1728133260: [
            0.26914,
            0,
            0.26914,
            0,
            27.86423,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            23.10772,
            0,
            0.67313,
            0,
            16.37226,
            0,
            41.66665,
            0,
            0,
            0,
            29.43674,
            0,
            19.67849,
            "<D",
            122.7537,
            0,
        ],
        1728133320: [
            0.22949,
            0,
            0.22949,
            0,
            27.80141,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            14.1595,
            0,
            0.6033,
            0,
            16.43228,
            0,
            43.64229,
            0,
            0,
            0,
            29.43653,
            0,
            21.83192,
            "<D",
            122.9321,
            0,
        ],
        1728133380: [
            0.2316,
            0,
            0.2316,
            0,
            27.70865,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            29.14691,
            0,
            0.51705,
            0,
            16.46521,
            0,
            46.29912,
            0,
            0,
            0,
            29.43627,
            0,
            17.99913,
            "<D",
            123.142,
            0,
        ],
        1728133440: [
            0.11797,
            0,
            0.11797,
            0,
            28.14503,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            33.88066,
            0,
            0.59703,
            0,
            16.48395,
            0,
            50.28005,
            0,
            0,
            0,
            29.4367,
            0,
            18.51175,
            "<D",
            122.6952,
            0,
        ],
        1728133500: [
            0.07799,
            0,
            0.07799,
            0,
            29.2298,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            30.96361,
            0,
            0.57525,
            0,
            16.47442,
            0,
            75.79235,
            0,
            0,
            0,
            29.43679,
            0,
            21.3716,
            "<D",
            122.8863,
            0,
        ],
        1728133560: [
            0.14215,
            0,
            0.14215,
            0,
            28.65979,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            29.10298,
            0,
            0.58149,
            0,
            16.46341,
            0,
            111.8042,
            0,
            0,
            0,
            29.43642,
            0,
            21.52459,
            "<D",
            122.7267,
            0,
        ],
        1728133620: [
            0.28267,
            0,
            0.28267,
            0,
            27.64553,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            26.22651,
            0,
            0.6895,
            0,
            16.52962,
            0,
            123.5861,
            0,
            0,
            0,
            29.43638,
            0,
            17.94453,
            "<D",
            122.7061,
            0,
        ],
        1728133680: [
            0.28038,
            0,
            0.28038,
            0,
            26.70369,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            31.68476,
            0,
            0.5805,
            0,
            16.5586,
            0,
            161.2347,
            0,
            0,
            0,
            29.43626,
            0,
            19.40869,
            "<D",
            122.736,
            0,
        ],
        1728133740: [
            0.12052,
            0,
            0.12052,
            0,
            25.83336,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            28.73538,
            0,
            0.66294,
            0,
            16.64265,
            0,
            206.9981,
            0,
            0,
            0,
            29.43591,
            0,
            17.20871,
            "<D",
            122.9394,
            0,
        ],
        1728133800: [
            0.21425,
            0,
            0.21425,
            0,
            26.46968,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            12.03573,
            0,
            0.73909,
            0,
            16.73907,
            0,
            221.7018,
            0,
            0,
            0,
            29.43575,
            0,
            20.88222,
            "<D",
            122.765,
            0,
        ],
        1728133860: [
            0.20213,
            0,
            0.20213,
            0,
            27.65941,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            24.95879,
            0,
            0.67632,
            0,
            16.873,
            0,
            225.129,
            0,
            0,
            0,
            29.43575,
            0,
            20.02035,
            "<D",
            122.7483,
            0,
        ],
        1728133920: [
            0.21612,
            0,
            0.21612,
            0,
            27.73713,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            14.54819,
            0,
            0.72083,
            0,
            16.94088,
            0,
            228.5535,
            0,
            0,
            0,
            29.43655,
            0,
            19.9165,
            "<D",
            122.5074,
            0,
        ],
        1728133980: [
            0.13152,
            0,
            0.13152,
            0,
            26.71416,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            13.94247,
            0,
            0.71214,
            0,
            17.03556,
            0,
            231.5081,
            0,
            0,
            0,
            29.43743,
            0,
            20.78639,
            "<D",
            122.5986,
            0,
        ],
        1728134040: [
            0.12206,
            0,
            0.12206,
            0,
            26.78582,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            24.43195,
            0,
            0.71726,
            0,
            17.06972,
            0,
            234.6291,
            0,
            0,
            0,
            29.43683,
            0,
            20.62019,
            "<D",
            122.7698,
            0,
        ],
        1728134100: [
            0.21662,
            0,
            0.21662,
            0,
            26.46934,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            21.52279,
            0,
            0.7648,
            0,
            17.15845,
            0,
            237.0275,
            0,
            0,
            0,
            29.43697,
            0,
            21.26199,
            "<D",
            122.7245,
            0,
        ],
        1728134160: [
            0.26859,
            0,
            0.26859,
            0,
            27.04566,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            19.83554,
            0,
            0.59699,
            0,
            17.22092,
            0,
            239.9306,
            0,
            0,
            0,
            29.43782,
            0,
            19.49491,
            "<D",
            122.7833,
            0,
        ],
        1728134220: [
            0.27204,
            0,
            0.27204,
            0,
            27.57942,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            19.81126,
            0,
            0.62853,
            0,
            17.29741,
            0,
            243.0796,
            0,
            0,
            0,
            29.43701,
            0,
            20.86147,
            "<D",
            122.9159,
            0,
        ],
        1728134280: [
            0.10185,
            0,
            0.10185,
            0,
            27.83069,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            5.71314,
            0,
            0.47717,
            0,
            17.33895,
            0,
            246.3591,
            0,
            0,
            0,
            29.43764,
            0,
            19.50016,
            "<D",
            122.8193,
            0,
        ],
        1728134340: [
            0.08591,
            0,
            0.08591,
            0,
            27.82811,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            359.8776,
            0,
            0.42498,
            0,
            17.42732,
            0,
            249.7088,
            0,
            0,
            0,
            29.43717,
            0,
            23.34768,
            "<D",
            122.6974,
            0,
        ],
        1728134400: [
            0.33082,
            0,
            0.33082,
            0,
            27.66175,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            24.26384,
            0,
            0.4249,
            0,
            17.50941,
            0,
            252.2297,
            0,
            0,
            0,
            29.43828,
            0,
            19.56083,
            "<D",
            122.5715,
            0,
        ],
        1728134460: [
            0.32107,
            0,
            0.32107,
            0,
            28.95455,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            94.5723,
            0,
            0.33892,
            0,
            17.58851,
            0,
            255.1338,
            0,
            0,
            0,
            29.43827,
            0,
            20.94065,
            "<D",
            122.7536,
            0,
        ],
        1728134520: [
            0.29533,
            0,
            0.29533,
            0,
            30.43036,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            24.44162,
            0,
            0.5924,
            0,
            17.71131,
            0,
            258.8884,
            0,
            0,
            0,
            29.43838,
            0,
            22.99436,
            "<D",
            122.6301,
            0,
        ],
        1728134580: [
            0.37115,
            0,
            0.37115,
            0,
            28.39429,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            19.04508,
            0,
            0.63947,
            0,
            17.78496,
            0,
            261.6783,
            0,
            0,
            0,
            29.4396,
            0,
            19.28521,
            "<D",
            122.5519,
            0,
        ],
        1728134640: [
            0.08315,
            0,
            0.08315,
            0,
            26.76968,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            11.43144,
            0,
            0.65457,
            0,
            17.77685,
            0,
            264.8566,
            0,
            0,
            0,
            29.4396,
            0,
            23.65915,
            "<D",
            122.5331,
            0,
        ],
        1728134700: [
            0.23923,
            0,
            0.23923,
            0,
            27.59892,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            8.10906,
            0,
            0.81214,
            0,
            17.81512,
            0,
            268.5678,
            0,
            0,
            0,
            29.43958,
            0,
            22.99139,
            "<D",
            122.4582,
            0,
        ],
        1728134760: [
            0.28031,
            0,
            0.28031,
            0,
            27.72423,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            343.4159,
            0,
            0.69097,
            0,
            17.98577,
            0,
            271.0248,
            0,
            0,
            0,
            29.43975,
            0,
            22.52395,
            "<D",
            122.7761,
            0,
        ],
        1728134820: [
            0.28752,
            0,
            0.28752,
            0,
            27.85183,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            356.2221,
            0,
            0.72148,
            0,
            18.05642,
            0,
            273.8982,
            0,
            0,
            0,
            29.43975,
            0,
            25.32875,
            "<D",
            123.2867,
            0,
        ],
        1728134880: [
            0.22952,
            0,
            0.22952,
            0,
            28.74123,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            29.37577,
            0,
            0.06047,
            0,
            18.21227,
            0,
            277.1889,
            0,
            0,
            0,
            29.43972,
            0,
            22.44343,
            "<D",
            123.0338,
            0,
        ],
        1728134940: [
            0.16005,
            0,
            0.16005,
            0,
            27.68464,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            23.75659,
            0,
            0.22216,
            0,
            18.2877,
            0,
            279.9365,
            0,
            0,
            0,
            29.43986,
            0,
            23.735,
            "<D",
            123.3763,
            0,
        ],
        1728135000: [
            0.28563,
            0,
            0.28563,
            0,
            28.1333,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            357.3437,
            0,
            0.52944,
            0,
            18.39532,
            0,
            282.7054,
            0,
            0,
            0,
            29.44006,
            0,
            23.0662,
            "<D",
            123.0816,
            0,
        ],
        1728135060: [
            0.09176,
            0,
            0.09176,
            0,
            26.75408,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            4.18402,
            0,
            0.4897,
            0,
            18.44822,
            0,
            285.8579,
            0,
            0,
            0,
            29.44062,
            0,
            22.89031,
            "<D",
            123.0678,
            0,
        ],
        1728135120: [
            0.10482,
            0,
            0.10482,
            0,
            29.0623,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            9.70287,
            0,
            0.10866,
            0,
            18.51273,
            0,
            288.2424,
            0,
            0,
            0,
            29.44158,
            0,
            22.28576,
            "<D",
            123.156,
            0,
        ],
        1728135180: [
            0.26358,
            0,
            0.26358,
            0,
            31.15124,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            37.45922,
            0,
            0.77206,
            0,
            18.63598,
            0,
            290.9196,
            0,
            0,
            0,
            29.44143,
            0,
            22.90574,
            "<D",
            123.0072,
            0,
        ],
        1728135240: [
            0.2004,
            0,
            0.2004,
            0,
            34.86766,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            70.23109,
            0,
            0.5605,
            0,
            18.73544,
            0,
            294.0926,
            0,
            0,
            0,
            29.44165,
            0,
            22.44837,
            "<D",
            122.9566,
            0,
        ],
        1728135300: [
            0.23238,
            0,
            0.23238,
            0,
            29.68835,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            8.12902,
            0,
            0.36705,
            0,
            18.75871,
            0,
            296.4499,
            0,
            0,
            0,
            29.4418,
            0,
            21.64241,
            "<D",
            123.087,
            0,
        ],
        1728135360: [
            0.3351,
            0,
            0.3351,
            0,
            29.85496,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            16.61351,
            0,
            0.80352,
            0,
            18.82766,
            0,
            299.6251,
            0,
            0,
            0,
            29.44182,
            0,
            20.66291,
            "<D",
            123.0519,
            0,
        ],
        1728135420: [
            0.28158,
            0,
            0.28158,
            0,
            30.41505,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            18.76351,
            0,
            0.69742,
            0,
            18.91489,
            0,
            302.833,
            0,
            0,
            0,
            29.44161,
            0,
            18.2095,
            "<D",
            122.9451,
            0,
        ],
        1728135480: [
            0.17728,
            0,
            0.17728,
            0,
            30.96965,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            22.16786,
            0,
            0.43567,
            0,
            19.12123,
            0,
            305.7644,
            0,
            0,
            0,
            29.44314,
            0,
            21.59705,
            "<D",
            122.8274,
            0,
        ],
        1728135540: [
            0.20935,
            0,
            0.20935,
            0,
            28.77252,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            3.17324,
            0,
            0.15068,
            0,
            19.59049,
            0,
            309.1083,
            0,
            0,
            0,
            29.44152,
            0,
            21.47374,
            "<D",
            122.7749,
            0,
        ],
        1728135600: [
            0.27064,
            0,
            0.27064,
            0,
            30.11492,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            1.31249,
            0,
            0.59189,
            0,
            19.46345,
            0,
            311.9838,
            0,
            0,
            0,
            29.44189,
            0,
            22.14445,
            "<D",
            122.673,
            0,
        ],
        1728135660: [
            0.24179,
            0,
            0.24179,
            0,
            28.92698,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            351.7887,
            0,
            0.57965,
            0,
            19.35711,
            0,
            314.8616,
            0,
            0,
            0,
            29.44219,
            0,
            21.00801,
            "<D",
            122.8051,
            0,
        ],
        1728135720: [
            0.2352,
            0,
            0.2352,
            0,
            29.71016,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            15.23439,
            0,
            0.60095,
            0,
            19.45405,
            0,
            318.0604,
            0,
            0,
            0,
            29.44217,
            0,
            22.50312,
            "<D",
            122.6758,
            0,
        ],
        1728135780: [
            0.24741,
            0,
            0.24741,
            0,
            29.68886,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            17.28277,
            0,
            0.65814,
            0,
            19.47961,
            0,
            320.8109,
            0,
            0,
            0,
            29.44294,
            0,
            23.97892,
            "<D",
            122.7987,
            0,
        ],
        1728135840: [
            0.22753,
            0,
            0.22753,
            0,
            28.7427,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            135.9834,
            0,
            0.5252,
            0,
            19.42928,
            0,
            323.7538,
            0,
            0,
            0,
            29.44283,
            0,
            22.87392,
            "<D",
            122.666,
            0,
        ],
        1728135900: [
            0.12667,
            0,
            0.12667,
            0,
            31.43506,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            77.06378,
            0,
            0.55486,
            0,
            19.5034,
            0,
            326.8097,
            0,
            0,
            0,
            29.4425,
            0,
            19.52508,
            "<D",
            122.5479,
            0,
        ],
        1728135960: [
            0.1497,
            0,
            0.1497,
            0,
            32.31254,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            174.9481,
            0,
            0.16105,
            0,
            19.82636,
            0,
            329.2346,
            0,
            0,
            0,
            29.44192,
            0,
            22.70268,
            "<D",
            122.8204,
            0,
        ],
        1728136020: [
            0.29768,
            0,
            0.29768,
            0,
            36.42842,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            89.36439,
            0,
            0.42275,
            0,
            19.93748,
            0,
            332.9925,
            0,
            0,
            0,
            29.44129,
            0,
            21.28412,
            "<D",
            122.8842,
            0,
        ],
        1728136080: [
            0.13124,
            0,
            0.13124,
            0,
            30.71113,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            226.4741,
            0,
            0.30973,
            0,
            20.0151,
            0,
            335.7865,
            0,
            0,
            0,
            29.44097,
            0,
            19.72947,
            "<D",
            122.9887,
            0,
        ],
        1728136140: [
            0.23599,
            0,
            0.23599,
            0,
            33.34013,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            80.19334,
            0,
            0.51287,
            0,
            20.27734,
            0,
            339.2129,
            0,
            0,
            0,
            29.44296,
            0,
            21.95531,
            "<D",
            123.0427,
            0,
        ],
        1728136200: [
            0.28438,
            0,
            0.28438,
            0,
            30.38334,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            61.56141,
            0,
            0.84703,
            0,
            20.08813,
            0,
            342.0576,
            0,
            0,
            0,
            29.44169,
            0,
            22.71652,
            "<D",
            123.1288,
            0,
        ],
        1728136260: [
            0.20577,
            0,
            0.20577,
            0,
            32.54316,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            101.6317,
            0,
            0.42914,
            0,
            20.09748,
            0,
            344.2188,
            0,
            0,
            0,
            29.44274,
            0,
            20.98926,
            "<D",
            123.0397,
            0,
        ],
        1728136320: [
            0.20213,
            0,
            0.20213,
            0,
            32.59214,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            68.98569,
            0,
            0.30633,
            0,
            20.44074,
            0,
            347.9284,
            0,
            0,
            0,
            29.44304,
            0,
            22.03808,
            "<D",
            123.073,
            0,
        ],
        1728136380: [
            0.30722,
            0,
            0.30722,
            0,
            32.24823,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            3.21704,
            0,
            0.33519,
            0,
            20.47447,
            0,
            352.0193,
            0,
            0,
            0,
            29.44258,
            0,
            21.80621,
            "<D",
            122.6958,
            0,
        ],
        1728136440: [
            0.20774,
            0,
            0.20774,
            0,
            29.88684,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            140.3832,
            0,
            0.49124,
            0,
            20.35239,
            0,
            354.9178,
            0,
            0,
            0,
            29.44333,
            0,
            21.11069,
            "<D",
            122.9278,
            0,
        ],
        1728136500: [
            0.3118,
            0,
            0.3118,
            0,
            32.23348,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            109.5449,
            0,
            0.5708,
            0,
            20.82408,
            0,
            358.8323,
            0,
            0,
            0,
            29.44306,
            0,
            20.66549,
            "<D",
            122.7247,
            0,
        ],
        1728136560: [
            0.23344,
            0,
            0.23344,
            0,
            30.77006,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            79.56952,
            0,
            0.39975,
            0,
            21.40012,
            0,
            362.0911,
            0,
            0,
            0,
            29.44207,
            0,
            20.70753,
            "<D",
            122.494,
            0,
        ],
        1728136620: [
            0.19283,
            0,
            0.19283,
            0,
            29.01689,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            127.3794,
            0,
            0.543,
            0,
            21.09715,
            0,
            364.2822,
            0,
            0,
            0,
            29.44313,
            0,
            20.64135,
            "<D",
            122.415,
            0,
        ],
        1728136680: [
            0.32078,
            0,
            0.32078,
            0,
            31.39989,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            141.7452,
            0,
            0.75272,
            0,
            20.73409,
            0,
            365.8886,
            0,
            0,
            0,
            29.44231,
            0,
            21.46435,
            "<D",
            122.4894,
            0,
        ],
        1728136740: [
            0.33105,
            0,
            0.33105,
            0,
            32.0947,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            171.2997,
            0,
            0.65212,
            0,
            21.01491,
            0,
            367.4278,
            0,
            0,
            0,
            29.44083,
            0,
            21.30333,
            "<D",
            122.653,
            0,
        ],
        1728136800: [
            0.14452,
            0,
            0.14452,
            0,
            33.14633,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            166.872,
            0,
            0.76132,
            0,
            21.21048,
            0,
            371.6811,
            0,
            0,
            0,
            29.4405,
            0,
            20.4091,
            "<D",
            122.7019,
            0,
        ],
        1728136860: [
            0.13753,
            0,
            0.13753,
            0,
            33.90973,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            186.4476,
            0,
            0.65896,
            0,
            21.40761,
            0,
            375.952,
            0,
            0,
            0,
            29.44132,
            0,
            20.86557,
            "<D",
            122.6207,
            0,
        ],
        1728136920: [
            0.27615,
            0,
            0.27615,
            0,
            34.64489,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            181.9218,
            0,
            0.67582,
            0,
            21.55056,
            0,
            379.0434,
            0,
            0,
            0,
            29.44089,
            0,
            23.07989,
            "<D",
            122.8911,
            0,
        ],
        1728136980: [
            0.2161,
            0,
            0.2161,
            0,
            35.36187,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            177.6944,
            0,
            0.73967,
            0,
            21.47768,
            0,
            382.0737,
            0,
            0,
            0,
            29.44049,
            0,
            21.63935,
            "<D",
            122.9142,
            0,
        ],
        1728137040: [
            0.11676,
            0,
            0.11676,
            0,
            35.4589,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            185.1181,
            0,
            0.5436,
            0,
            21.68384,
            0,
            385.1086,
            0,
            0,
            0,
            29.44063,
            0,
            18.51037,
            "<D",
            122.9226,
            0,
        ],
        1728137100: [
            0.03566,
            0,
            0.03566,
            0,
            36.57004,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            174.9657,
            0,
            0.55273,
            0,
            21.93759,
            0,
            389.4883,
            0,
            0,
            0,
            29.44066,
            0,
            21.85429,
            "<D",
            122.5858,
            0,
        ],
        1728137160: [
            0.07659,
            0,
            0.07659,
            0,
            35.82613,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            192.7342,
            0,
            0.6264,
            0,
            22.11562,
            0,
            393.1842,
            0,
            0,
            0,
            29.44131,
            0,
            19.02052,
            "<D",
            122.5445,
            0,
        ],
        1728137220: [
            -0.01266,
            0,
            -0.01266,
            0,
            34.48541,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            187.145,
            0,
            0.51182,
            0,
            22.08803,
            0,
            396.6302,
            0,
            0,
            0,
            29.44137,
            0,
            17.29436,
            "<D",
            122.8588,
            0,
        ],
        1728137280: [
            0.24237,
            0,
            0.24237,
            0,
            33.85384,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            187.8297,
            0,
            0.72969,
            0,
            22.05815,
            0,
            401.4554,
            0,
            0,
            0,
            29.44024,
            0,
            23.98305,
            "<D",
            123.3678,
            0,
        ],
        1728137340: [
            0.13316,
            0,
            0.13316,
            0,
            34.53225,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            201.4066,
            0,
            0.79536,
            0,
            22.1462,
            0,
            402.6723,
            0,
            0,
            0,
            29.44211,
            0,
            21.51635,
            "<D",
            123.0114,
            0,
        ],
        1728137400: [
            0.19315,
            0,
            0.19315,
            0,
            34.20203,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            197.1016,
            0,
            0.79988,
            0,
            22.26053,
            0,
            406.698,
            0,
            0,
            0,
            29.4427,
            0,
            20.38235,
            "<D",
            123.3855,
            0,
        ],
        1728137460: [
            0.26797,
            0,
            0.26797,
            0,
            34.05397,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            190.032,
            0,
            0.73044,
            0,
            22.15542,
            0,
            410.1005,
            0,
            0,
            0,
            29.44242,
            0,
            19.43847,
            "<D",
            123.2996,
            0,
        ],
        1728137520: [
            0.10625,
            0,
            0.10625,
            0,
            34.10212,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            208.6074,
            0,
            0.95872,
            0,
            22.35667,
            0,
            413.3321,
            0,
            0,
            0,
            29.44292,
            0,
            20.36,
            "<D",
            123.0265,
            0,
        ],
        1728137580: [
            0.24276,
            0,
            0.24276,
            0,
            34.50706,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            225.521,
            0,
            1.02543,
            0,
            22.59463,
            0,
            416.4741,
            0,
            0,
            0,
            29.44237,
            0,
            20.60819,
            "<D",
            122.8802,
            0,
        ],
        1728137640: [
            0.34874,
            0,
            0.34874,
            0,
            33.79295,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            198.7877,
            0,
            0.46986,
            0,
            22.71862,
            0,
            421.1832,
            0,
            0,
            0,
            29.44234,
            0,
            20.60572,
            "<D",
            122.9307,
            0,
        ],
        1728137700: [
            0.20508,
            0,
            0.20508,
            0,
            31.34228,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            252.7789,
            0,
            0.53619,
            0,
            22.36652,
            0,
            421.795,
            0,
            0,
            0,
            29.44262,
            0,
            18.93772,
            "<D",
            123.0638,
            0,
        ],
        1728137760: [
            0.08937,
            0,
            0.08937,
            0,
            31.66496,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            201.9375,
            0,
            0.43011,
            0,
            22.43691,
            0,
            423.0699,
            0,
            0,
            0,
            29.44266,
            0,
            21.48026,
            "<D",
            123.2078,
            0,
        ],
        1728137820: [
            0.18927,
            0,
            0.18927,
            0,
            31.55882,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            195.0602,
            0,
            0.59573,
            0,
            22.46543,
            0,
            426.65,
            0,
            0,
            0,
            29.44214,
            0,
            23.18019,
            "<D",
            122.9395,
            0,
        ],
        1728137880: [
            0.29198,
            0,
            0.29198,
            0,
            34.09498,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            186.3772,
            0,
            0.68513,
            0,
            22.76726,
            0,
            429.5669,
            0,
            0,
            0,
            29.443,
            0,
            20.68221,
            "<D",
            123.2507,
            0,
        ],
        1728137940: [
            0.10286,
            0,
            0.10286,
            0,
            32.75377,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            164.8594,
            0,
            0.6466,
            0,
            22.64072,
            0,
            432.5515,
            0,
            0,
            0,
            29.44221,
            0,
            19.37561,
            "<D",
            123.2559,
            0,
        ],
        1728138000: [
            0.09262,
            0,
            0.09262,
            0,
            32.19245,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            244.0305,
            0,
            0.45491,
            0,
            22.46979,
            0,
            434.4151,
            0,
            0,
            0,
            29.44267,
            0,
            20.14614,
            "<D",
            123.5434,
            0,
        ],
        1728138060: [
            0.28179,
            0,
            0.28179,
            0,
            35.35411,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            174.126,
            0,
            0.37644,
            0,
            22.28144,
            0,
            434.1519,
            0,
            0,
            0,
            29.44301,
            0,
            24.13984,
            "<D",
            123.7316,
            0,
        ],
        1728138120: [
            0.30031,
            0,
            0.30031,
            0,
            31.82171,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            198.125,
            0,
            0.9766,
            0,
            22.14519,
            0,
            441.5284,
            0,
            0,
            0,
            29.44284,
            0,
            20.39264,
            "<D",
            123.8998,
            0,
        ],
        1728138180: [
            0.13002,
            0,
            0.13002,
            0,
            34.88158,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            189.6392,
            0,
            1.3422,
            0,
            22.86035,
            0,
            444.9774,
            0,
            0,
            0,
            29.44248,
            0,
            22.06927,
            "<D",
            123.7228,
            0,
        ],
        1728138240: [
            0.07458,
            0,
            0.07458,
            0,
            36.68875,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            183.8975,
            0,
            1.28981,
            0,
            22.7297,
            0,
            446.1585,
            0,
            0,
            0,
            29.44283,
            0,
            17.92777,
            "<D",
            123.6809,
            0,
        ],
        1728138300: [
            0.40956,
            0,
            0.40956,
            0,
            36.31054,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            181.1586,
            0,
            1.50001,
            0,
            22.62973,
            0,
            449.2942,
            0,
            0,
            0,
            29.44302,
            0,
            22.85264,
            "<D",
            123.3508,
            0,
        ],
        1728138360: [
            0.29617,
            0,
            0.29617,
            0,
            34.14567,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            175.7141,
            0,
            1.85666,
            0,
            22.81906,
            0,
            451.0708,
            0,
            0,
            0,
            29.44289,
            0,
            21.87965,
            "<D",
            123.607,
            0,
        ],
        1728138420: [
            0.30483,
            0,
            0.30483,
            0,
            35.21033,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            163.6891,
            0,
            1.43347,
            0,
            22.9198,
            0,
            453.5222,
            0,
            0,
            0,
            29.44241,
            0,
            21.62695,
            "<D",
            123.5853,
            0,
        ],
        1728138480: [
            0.14102,
            0,
            0.14102,
            0,
            37.2978,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            173.3718,
            0,
            1.07603,
            0,
            23.16787,
            0,
            457.6421,
            0,
            0,
            0,
            29.44186,
            0,
            20.81994,
            "<D",
            123.3811,
            0,
        ],
        1728138540: [
            0.21105,
            0,
            0.21105,
            0,
            38.54127,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            178.9269,
            0,
            0.83091,
            0,
            23.34737,
            0,
            459.4064,
            0,
            0,
            0,
            29.44184,
            0,
            19.70656,
            "<D",
            123.3363,
            0,
        ],
        1728138600: [
            0.10756,
            0,
            0.10756,
            0,
            38.16066,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            178.2008,
            0,
            1.11614,
            0,
            23.67904,
            0,
            461.6584,
            0,
            0,
            0,
            29.443,
            0,
            18.95969,
            "<D",
            123.3436,
            0,
        ],
        1728138660: [
            0.27416,
            0,
            0.27416,
            0,
            36.45715,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            172.6165,
            0,
            1.27088,
            0,
            23.39201,
            0,
            462.9928,
            0,
            0,
            0,
            29.44305,
            0,
            21.70437,
            "<D",
            123.3624,
            0,
        ],
        1728138720: [
            0.04134,
            0,
            0.04134,
            0,
            36.68217,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6732,
            0,
            167.649,
            0,
            1.14722,
            0,
            23.52141,
            0,
            465.4683,
            0,
            0,
            0,
            29.44263,
            0,
            21.58191,
            "<D",
            123.199,
            0,
        ],
        1728138780: [
            0.16024,
            0,
            0.16024,
            0,
            37.57433,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            163.5184,
            0,
            1.04039,
            0,
            23.37461,
            0,
            468.6943,
            0,
            0,
            0,
            29.4426,
            0,
            19.88919,
            "<D",
            123.2598,
            0,
        ],
        1728138840: [
            0.29197,
            0,
            0.29197,
            0,
            32.21849,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            140.7642,
            0,
            1.16096,
            0,
            23.19652,
            0,
            470.9433,
            0,
            0,
            0,
            29.44215,
            0,
            19.93659,
            "<D",
            122.9988,
            0,
        ],
        1728138900: [
            0.20454,
            0,
            0.20454,
            0,
            32.4179,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            185.4109,
            0,
            0.76568,
            0,
            23.26409,
            0,
            474.2561,
            0,
            0,
            0,
            29.44295,
            0,
            21.68231,
            "<D",
            123.2389,
            0,
        ],
        1728138960: [
            0.20174,
            0,
            0.20174,
            0,
            36.27103,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            188.074,
            0,
            1.11211,
            0,
            23.29112,
            0,
            476.6916,
            0,
            0,
            0,
            29.44301,
            0,
            18.9968,
            "<D",
            123.2962,
            0,
        ],
        1728139020: [
            0.24152,
            0,
            0.24152,
            0,
            35.93577,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            176.3555,
            0,
            1.39871,
            0,
            23.47751,
            0,
            478.6554,
            0,
            0,
            0,
            29.44308,
            0,
            20.42555,
            "<D",
            123.0307,
            0,
        ],
        1728139080: [
            0.10666,
            0,
            0.10666,
            0,
            39.55453,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            154.4701,
            0,
            2.20142,
            0,
            23.39631,
            0,
            482.0217,
            0,
            0,
            0,
            29.44255,
            0,
            21.35733,
            "<D",
            122.9797,
            0,
        ],
        1728139140: [
            0.20364,
            0,
            0.20364,
            0,
            41.8838,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            161.1438,
            0,
            0.88797,
            0,
            23.38677,
            0,
            485.397,
            0,
            0,
            0,
            29.443,
            0,
            21.52447,
            "<D",
            123.0575,
            0,
        ],
        1728139200: [
            0.26754,
            0,
            0.26754,
            0,
            41.63744,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            157.9585,
            0,
            1.0682,
            0,
            23.57113,
            0,
            487.8654,
            0,
            0,
            0,
            29.44242,
            0,
            19.72504,
            "<D",
            123.2268,
            0,
        ],
        1728139260: [
            0.28601,
            0,
            0.28601,
            0,
            40.79951,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            176.6967,
            0,
            0.77955,
            0,
            23.76757,
            0,
            490.1748,
            0,
            0,
            0,
            29.44219,
            0,
            20.57342,
            "<D",
            123.1658,
            0,
        ],
        1728139320: [
            0.25634,
            0,
            0.25634,
            0,
            38.47887,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            152.6301,
            0,
            1.04061,
            0,
            23.89,
            0,
            493.0953,
            0,
            0,
            0,
            29.44302,
            0,
            18.98371,
            "<D",
            116.2011,
            0,
        ],
        1728139380: [
            0.10554,
            0,
            0.10554,
            0,
            36.54799,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            149.4475,
            0,
            1.48387,
            0,
            23.7726,
            0,
            496.5323,
            0,
            0,
            0,
            29.44311,
            0,
            18.32898,
            "<D",
            112.5058,
            0,
        ],
        1728139440: [
            0.13168,
            0,
            0.13168,
            0,
            39.651,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            169.5482,
            0,
            0.97508,
            0,
            23.87788,
            0,
            498.4102,
            0,
            0,
            0,
            29.44094,
            0,
            16.14511,
            "<D",
            112.9194,
            0,
        ],
        1728139500: [
            0.26436,
            0,
            0.26436,
            0,
            39.77456,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            174.8531,
            0,
            0.76685,
            0,
            24.00322,
            0,
            500.1918,
            0,
            0,
            0,
            29.44129,
            0,
            18.91668,
            "<D",
            112.3084,
            0,
        ],
        1728139560: [
            0.1415,
            0,
            0.1415,
            0,
            38.22344,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            167.0325,
            0,
            1.37983,
            0,
            23.96211,
            0,
            501.1341,
            0,
            0,
            0,
            29.44139,
            0,
            19.53733,
            "<D",
            111.5844,
            0,
        ],
        1728139620: [
            0.17998,
            0,
            0.17998,
            0,
            39.16845,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            177.6614,
            0,
            1.1163,
            0,
            23.95031,
            0,
            503.0097,
            0,
            0,
            0,
            29.44002,
            0,
            19.84657,
            "<D",
            111.7091,
            0,
        ],
        1728139680: [
            0.20982,
            0,
            0.20982,
            0,
            39.73513,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            172.877,
            0,
            1.24276,
            0,
            24.1539,
            0,
            505.8621,
            0,
            0,
            0,
            29.44114,
            0,
            19.95432,
            "<D",
            111.3642,
            0,
        ],
        1728139740: [
            0.21483,
            0,
            0.21483,
            0,
            39.81728,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            172.9642,
            0,
            0.9629,
            0,
            24.04529,
            0,
            508.8751,
            0,
            0,
            0,
            29.44123,
            0,
            18.12682,
            "<D",
            111.178,
            0,
        ],
        1728139800: [
            0.32494,
            0,
            0.32494,
            0,
            37.67615,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            203.3203,
            0,
            1.2995,
            0,
            23.98333,
            0,
            510.7522,
            0,
            0,
            0,
            29.44135,
            0,
            22.03872,
            "<D",
            109.9941,
            0,
        ],
        1728139860: [
            0.28499,
            0,
            0.28499,
            0,
            40.5565,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            167.5389,
            0,
            2.14761,
            0,
            23.97257,
            0,
            512.6049,
            0,
            0,
            0,
            29.44243,
            0,
            18.70586,
            "<D",
            111.1188,
            0,
        ],
        1728139920: [
            0.26767,
            0,
            0.26767,
            0,
            41.99389,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            163.0484,
            0,
            1.8047,
            0,
            24.22299,
            0,
            514.0587,
            0,
            0,
            0,
            29.44246,
            0,
            19.41366,
            "<D",
            111.4409,
            0,
        ],
        1728139980: [
            0.2205,
            0,
            0.2205,
            0,
            42.56246,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            169.2923,
            0,
            1.78096,
            0,
            24.30264,
            0,
            518.1046,
            0,
            0,
            0,
            29.44368,
            0,
            18.39518,
            "<D",
            111.7797,
            0,
        ],
        1728140040: [
            0.08853,
            0,
            0.08853,
            0,
            44.25151,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            194.0298,
            0,
            1.72623,
            0,
            24.32704,
            0,
            520.7543,
            0,
            0,
            0,
            29.44364,
            0,
            21.28582,
            "<D",
            111.665,
            0,
        ],
        1728140100: [
            0.30727,
            0,
            0.30727,
            0,
            43.4843,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            186.7717,
            0,
            1.94214,
            0,
            24.49974,
            0,
            521.0174,
            0,
            0,
            0,
            29.44333,
            0,
            18.61011,
            "<D",
            111.6364,
            0,
        ],
        1728140160: [
            0.2804,
            0,
            0.2804,
            0,
            43.14158,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            171.1121,
            0,
            1.51484,
            0,
            24.44959,
            0,
            523.9855,
            0,
            0,
            0,
            29.44286,
            0,
            20.6599,
            "<D",
            111.1602,
            0,
        ],
        1728140220: [
            0.24627,
            0,
            0.24627,
            0,
            43.61217,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            179.9555,
            0,
            1.27555,
            0,
            24.48312,
            0,
            526.2252,
            0,
            0,
            0,
            29.44287,
            0,
            19.48613,
            "<D",
            111.7763,
            0,
        ],
        1728140280: [
            0.17629,
            0,
            0.17629,
            0,
            43.20601,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            187.9359,
            0,
            1.79306,
            0,
            24.59665,
            0,
            528.175,
            0,
            0,
            0,
            29.44301,
            0,
            19.37371,
            "<D",
            111.9379,
            0,
        ],
        1728140340: [
            0.29019,
            0,
            0.29019,
            0,
            43.83281,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            149.8676,
            0,
            1.97878,
            0,
            24.50286,
            0,
            531.3148,
            0,
            0,
            0,
            29.44206,
            0,
            19.95389,
            "<D",
            111.6627,
            0,
        ],
        1728140400: [
            0.21129,
            0,
            0.21129,
            0,
            44.67894,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            164.702,
            0,
            1.47331,
            0,
            24.26528,
            0,
            533.6581,
            0,
            0,
            0,
            29.44267,
            0,
            18.76094,
            "<D",
            112.1711,
            0,
        ],
        1728140460: [
            0.17615,
            0,
            0.17615,
            0,
            44.04892,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            149.5722,
            0,
            1.63233,
            0,
            24.15792,
            0,
            535.9146,
            0,
            0,
            0,
            29.44256,
            0,
            18.61284,
            "<D",
            112.271,
            0,
        ],
        1728140520: [
            0.04667,
            0,
            0.04667,
            0,
            44.18543,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            193.2973,
            0,
            1.03111,
            0,
            24.49908,
            0,
            538.6223,
            0,
            0,
            0,
            29.44287,
            0,
            20.94757,
            "<D",
            112.3704,
            0,
        ],
        1728140580: [
            0.11547,
            0,
            0.11547,
            0,
            42.6104,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            196.6728,
            0,
            1.475,
            0,
            24.60143,
            0,
            541.7993,
            0,
            0,
            0,
            29.44113,
            0,
            22.40467,
            "<D",
            112.8084,
            0,
        ],
        1728140640: [
            0.20409,
            0,
            0.20409,
            0,
            44.96497,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            183.3169,
            0,
            1.24864,
            0,
            24.74479,
            0,
            543.6808,
            0,
            0,
            0,
            29.44219,
            0,
            21.63753,
            "<D",
            114.7157,
            0,
        ],
        1728140700: [
            0.02033,
            0,
            0.02033,
            0,
            47.1081,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            188.4706,
            0,
            0.98372,
            0,
            24.74562,
            0,
            545.8179,
            0,
            0,
            0,
            29.44099,
            0,
            19.13131,
            "<D",
            114.0022,
            0,
        ],
        1728140760: [
            0.1896,
            0,
            0.1896,
            0,
            46.53343,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            163.2925,
            0,
            1.2219,
            0,
            25.01933,
            0,
            547.5522,
            0,
            0,
            0,
            29.44235,
            0,
            20.01735,
            "<D",
            112.6047,
            0,
        ],
        1728140820: [
            0.17267,
            0,
            0.17267,
            0,
            45.05501,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            173.5325,
            0,
            1.17991,
            0,
            24.93453,
            0,
            550.9459,
            0,
            0,
            0,
            29.44268,
            0,
            19.354,
            "<D",
            113.8936,
            0,
        ],
        1728140880: [
            0.04851,
            0,
            0.04851,
            0,
            45.55517,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            205.266,
            0,
            1.24696,
            0,
            25.2373,
            0,
            553.7352,
            0,
            0,
            0,
            29.4432,
            0,
            17.69965,
            "<D",
            113.5427,
            0,
        ],
        1728140940: [
            0.08143,
            0,
            0.08143,
            0,
            46.30003,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            177.998,
            0,
            1.92183,
            0,
            24.86707,
            0,
            554.4935,
            0,
            0,
            0,
            29.44308,
            0,
            18.0363,
            "<D",
            113.6567,
            0,
        ],
        1728141000: [
            0.18995,
            0,
            0.18995,
            0,
            48.23975,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            189.5144,
            0,
            1.73748,
            0,
            24.86614,
            0,
            555.8249,
            0,
            0,
            0,
            29.443,
            0,
            18.57352,
            "<D",
            113.6342,
            0,
        ],
        1728141060: [
            0.31834,
            0,
            0.31834,
            0,
            48.13227,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            183.3206,
            0,
            1.61173,
            0,
            24.98845,
            0,
            559.0097,
            0,
            0,
            0,
            29.44232,
            0,
            20.57753,
            "<D",
            112.8509,
            0,
        ],
        1728141120: [
            0.30729,
            0,
            0.30729,
            0,
            49.13584,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            198.378,
            0,
            1.39326,
            0,
            25.03613,
            0,
            562.0371,
            0,
            0,
            0,
            29.4427,
            0,
            17.46185,
            "<D",
            112.9162,
            0,
        ],
        1728141180: [
            0.26489,
            0,
            0.26489,
            0,
            48.37273,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            189.8907,
            0,
            1.64864,
            0,
            24.93971,
            0,
            564.0355,
            0,
            0,
            0,
            29.44254,
            0,
            18.57928,
            "<D",
            113.6947,
            0,
        ],
        1728141240: [
            0.31502,
            0,
            0.31502,
            0,
            50.26902,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            194.1471,
            0,
            1.03067,
            0,
            25.03931,
            0,
            565.5959,
            0,
            0,
            0,
            29.44326,
            0,
            19.10876,
            "<D",
            113.9856,
            0,
        ],
        1728141300: [
            0.09783,
            0,
            0.09783,
            0,
            49.87806,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            184.6537,
            0,
            1.40819,
            0,
            25.28373,
            0,
            567.3594,
            0,
            0,
            0,
            29.44266,
            0,
            19.08554,
            "<D",
            113.4276,
            0,
        ],
        1728141360: [
            0.13479,
            0,
            0.13479,
            0,
            49.18085,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            187.694,
            0,
            0.99936,
            0,
            25.37474,
            0,
            569.5531,
            0,
            0,
            0,
            29.44314,
            0,
            18.39704,
            "<D",
            114.4654,
            0,
        ],
        1728141420: [
            0.31043,
            0,
            0.31043,
            0,
            49.06889,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            196.5908,
            0,
            1.54507,
            0,
            25.63656,
            0,
            571.4751,
            0,
            0,
            0,
            29.44225,
            0,
            18.6793,
            "<D",
            115.4477,
            0,
        ],
        1728141480: [
            0.18391,
            0,
            0.18391,
            0,
            50.16914,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            178.2048,
            0,
            1.83461,
            0,
            25.36483,
            0,
            574.6111,
            0,
            0,
            0,
            29.4424,
            0,
            18.37222,
            "<D",
            115.733,
            0,
        ],
        1728141540: [
            0.33533,
            0,
            0.33533,
            0,
            50.26868,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            159.7792,
            0,
            1.81971,
            0,
            25.26556,
            0,
            576.6609,
            0,
            0,
            0,
            29.44324,
            0,
            18.09646,
            "<D",
            115.7634,
            0,
        ],
        1728141600: [
            0.15161,
            0,
            0.15161,
            0,
            49.97605,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            172.5518,
            0,
            2.03251,
            0,
            25.00028,
            0,
            578.7324,
            0,
            0,
            0,
            29.44267,
            0,
            17.95991,
            "<D",
            114.6115,
            0,
        ],
        1728141660: [
            0.18619,
            0,
            0.18619,
            0,
            50.43454,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            196.5925,
            0,
            1.41204,
            0,
            25.04714,
            0,
            580.1535,
            0,
            0,
            0,
            29.44268,
            0,
            17.85274,
            "<D",
            114.7943,
            0,
        ],
        1728141720: [
            0.20027,
            0,
            0.20027,
            0,
            50.81414,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            157.465,
            0,
            1.74247,
            0,
            25.07121,
            0,
            582.0758,
            0,
            0,
            0,
            29.44302,
            0,
            18.71386,
            "<D",
            114.6159,
            0,
        ],
        1728141780: [
            0.26672,
            0,
            0.26672,
            0,
            50.18489,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            184.0692,
            0,
            2.04352,
            0,
            25.14174,
            0,
            548.1843,
            0,
            0,
            0,
            29.44301,
            0,
            19.3226,
            "<D",
            113.3784,
            0,
        ],
        1728141840: [
            0.16533,
            0,
            0.16533,
            0,
            51.49834,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            179.3946,
            0,
            1.9722,
            0,
            25.07758,
            0,
            513.5098,
            0,
            0,
            0,
            29.44147,
            0,
            18.59117,
            "<D",
            113.8736,
            0,
        ],
        1728141900: [
            0.22688,
            0,
            0.22688,
            0,
            52.09288,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            171.3938,
            0,
            1.32103,
            0,
            25.51664,
            0,
            561.9296,
            0,
            0,
            0,
            29.44283,
            0,
            18.98388,
            "<D",
            113.5689,
            0,
        ],
        1728141960: [
            0.17571,
            0,
            0.17571,
            0,
            50.88133,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            183.726,
            0,
            1.60903,
            0,
            25.76922,
            0,
            590.5797,
            0,
            0,
            0,
            29.44224,
            0,
            22.07802,
            "<D",
            113.5176,
            0,
        ],
        1728142020: [
            0.36619,
            0,
            0.36619,
            0,
            50.47366,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            185.5435,
            0,
            1.90632,
            0,
            25.43176,
            0,
            592.5566,
            0,
            0,
            0,
            29.44105,
            0,
            19.23879,
            "<D",
            113.5986,
            0,
        ],
        1728142080: [
            0.29338,
            0,
            0.29338,
            0,
            50.73737,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            181.0669,
            0,
            1.35765,
            0,
            25.57072,
            0,
            593.524,
            0,
            0,
            0,
            29.44004,
            0,
            19.04738,
            "<D",
            115.166,
            0,
        ],
        1728142140: [
            0.14984,
            0,
            0.14984,
            0,
            49.97503,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            184.9721,
            0,
            1.94956,
            0,
            25.3632,
            0,
            596.2809,
            0,
            0,
            0,
            29.44153,
            0,
            17.21886,
            "<D",
            116.1122,
            0,
        ],
        1728142200: [
            0.38765,
            0,
            0.38765,
            0,
            50.62096,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            191.9597,
            0,
            1.6877,
            0,
            25.27527,
            0,
            597.524,
            0,
            0,
            0,
            29.44002,
            0,
            15.85105,
            "<D",
            117.2111,
            0,
        ],
        1728142260: [
            0.29202,
            0,
            0.29202,
            0,
            51.09158,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            203.0436,
            0,
            1.40748,
            0,
            25.47079,
            0,
            599.5573,
            0,
            0,
            0,
            29.43999,
            0,
            15.36233,
            "<D",
            117.7007,
            0,
        ],
        1728142320: [
            0.35927,
            0,
            0.35927,
            0,
            52.02367,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            191.0273,
            0,
            1.35791,
            0,
            25.40586,
            0,
            601.9979,
            0,
            0,
            0,
            29.43994,
            0,
            17.62598,
            "<D",
            118.4184,
            0,
        ],
        1728142380: [
            0.13074,
            0,
            0.13074,
            0,
            51.80558,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            170.7209,
            0,
            1.05127,
            0,
            25.76318,
            0,
            604.0677,
            0,
            0,
            0,
            29.43991,
            0,
            18.36356,
            "<D",
            118.2569,
            0,
        ],
        1728142440: [
            0.32305,
            0,
            0.32305,
            0,
            49.89588,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            185.263,
            0,
            1.12514,
            0,
            25.73829,
            0,
            606.197,
            0,
            0,
            0,
            29.43973,
            0,
            18.79317,
            "<D",
            115.5366,
            0,
        ],
        1728142500: [
            0.18846,
            0,
            0.18846,
            0,
            51.64744,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            197.767,
            0,
            1.71937,
            0,
            25.78541,
            0,
            608.1406,
            0,
            0,
            0,
            29.43992,
            0,
            18.96304,
            "<D",
            114.789,
            0,
        ],
        1728142560: [
            0.17207,
            0,
            0.17207,
            0,
            51.91179,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            164.2375,
            0,
            1.33758,
            0,
            25.98127,
            0,
            610.3956,
            0,
            0,
            0,
            29.43997,
            0,
            18.14004,
            "<D",
            114.0081,
            0,
        ],
        1728142620: [
            0.28907,
            0,
            0.28907,
            0,
            49.77859,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            155.3246,
            0,
            1.92115,
            0,
            25.82173,
            0,
            612.1475,
            0,
            0,
            0,
            29.43992,
            0,
            19.25386,
            "<D",
            113.4894,
            0,
        ],
        1728142680: [
            0.16171,
            0,
            0.16171,
            0,
            49.99522,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            170.1126,
            0,
            2.1463,
            0,
            25.58201,
            0,
            613.1343,
            0,
            0,
            0,
            29.43976,
            0,
            17.94059,
            "<D",
            113.4179,
            0,
        ],
        1728142740: [
            0.25029,
            0,
            0.25029,
            0,
            52.61813,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            200.5734,
            0,
            2.09721,
            0,
            25.80683,
            0,
            615.1723,
            0,
            0,
            0,
            29.43975,
            0,
            20.44282,
            "<D",
            113.284,
            0,
        ],
        1728142800: [
            0.35086,
            0,
            0.35086,
            0,
            53.25118,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            177.2516,
            0,
            1.49051,
            0,
            25.80662,
            0,
            616.5836,
            0,
            0,
            0,
            29.43973,
            0,
            15.99644,
            "<D",
            114.1409,
            0,
        ],
        1728142860: [
            0.17491,
            0,
            0.17491,
            0,
            52.93231,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            196.4309,
            0,
            1.38042,
            0,
            25.93872,
            0,
            618.452,
            0,
            0,
            0,
            29.43969,
            0,
            20.58398,
            "<D",
            115.0067,
            0,
        ],
        1728142920: [
            0.07503,
            0,
            0.07503,
            0,
            52.79676,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            171.735,
            0,
            2.56721,
            0,
            25.98988,
            0,
            620.5326,
            0,
            0,
            0,
            29.43984,
            0,
            16.62316,
            "<D",
            114.0809,
            0,
        ],
        1728142980: [
            0.24127,
            0,
            0.24127,
            0,
            53.56196,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            195.8646,
            0,
            2.25907,
            0,
            25.6446,
            0,
            621.5944,
            0,
            0,
            0,
            29.4397,
            0,
            17.47288,
            "<D",
            114.6626,
            0,
        ],
        1728143040: [
            0.30133,
            0,
            0.30133,
            0,
            53.76483,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            184.8103,
            0,
            1.52192,
            0,
            25.67878,
            0,
            623.3833,
            0,
            0,
            0,
            29.4403,
            0,
            16.70967,
            "<D",
            115.5173,
            0,
        ],
        1728143100: [
            0.30377,
            0,
            0.30377,
            0,
            50.28342,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            152.6927,
            0,
            0.31678,
            0,
            25.80126,
            0,
            625.7039,
            0,
            0,
            0,
            29.43976,
            0,
            17.2309,
            "<D",
            116.1503,
            0,
        ],
        1728143160: [
            0.0823,
            0,
            0.0823,
            0,
            48.72692,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            158.0132,
            0,
            1.89703,
            0,
            26.10122,
            0,
            627.0538,
            0,
            0,
            0,
            29.44213,
            0,
            15.37331,
            "<D",
            116.9285,
            0,
        ],
        1728143220: [
            0.32905,
            0,
            0.32905,
            0,
            52.11803,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            140.8782,
            0,
            2.01586,
            0,
            26.01988,
            0,
            628.3221,
            0,
            0,
            0,
            29.44085,
            0,
            18.43108,
            "<D",
            116.5626,
            0,
        ],
        1728143280: [
            0.36791,
            0,
            0.36791,
            0,
            53.98375,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            162.1839,
            0,
            2.4318,
            0,
            25.88049,
            0,
            630.5651,
            0,
            0,
            0,
            29.44144,
            0,
            19.02653,
            "<D",
            117.4091,
            0,
        ],
        1728143340: [
            0.24878,
            0,
            0.24878,
            0,
            54.18634,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            169.2424,
            0,
            2.0863,
            0,
            25.81186,
            0,
            632.014,
            0,
            0,
            0,
            29.44186,
            0,
            15.24973,
            "<D",
            117.3539,
            0,
        ],
        1728143400: [
            0.09972,
            0,
            0.09972,
            0,
            53.54192,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            173.0158,
            0,
            2.35881,
            0,
            25.72402,
            0,
            632.8278,
            0,
            0,
            0,
            29.44111,
            0,
            18.55891,
            "<D",
            115.2086,
            0,
        ],
        1728143460: [
            0.35708,
            0,
            0.35708,
            0,
            53.95292,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            164.1591,
            0,
            1.67184,
            0,
            25.63878,
            0,
            635.1809,
            0,
            0,
            0,
            29.44025,
            0,
            18.37838,
            "<D",
            115.2072,
            0,
        ],
        1728143520: [
            0.12385,
            0,
            0.12385,
            0,
            53.7662,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            189.2297,
            0,
            1.1925,
            0,
            25.98541,
            0,
            638.1736,
            0,
            0,
            0,
            29.44044,
            0,
            15.82645,
            "<D",
            115.9037,
            0,
        ],
        1728143580: [
            0.2141,
            0,
            0.2141,
            0,
            53.24261,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            202.9538,
            0,
            1.19137,
            0,
            26.11401,
            0,
            639.3304,
            0,
            0,
            0,
            29.44004,
            0,
            21.38225,
            "<D",
            115.6907,
            0,
        ],
        1728143640: [
            0.1936,
            0,
            0.1936,
            0,
            53.67126,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            231.495,
            0,
            0.89507,
            0,
            26.16193,
            0,
            639.5626,
            0,
            0,
            0,
            29.43992,
            0,
            16.73148,
            "<D",
            115.5558,
            0,
        ],
        1728143700: [
            0.11879,
            0,
            0.11879,
            0,
            53.23943,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            204.9905,
            0,
            1.26072,
            0,
            26.23068,
            0,
            642.5121,
            0,
            0,
            0,
            29.43999,
            0,
            19.52643,
            "<D",
            114.8612,
            0,
        ],
        1728143760: [
            0.04715,
            0,
            0.04715,
            0,
            52.88667,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            181.8697,
            0,
            1.74806,
            0,
            26.25776,
            0,
            645.1636,
            0,
            0,
            0,
            29.4409,
            0,
            18.09052,
            "<D",
            113.5564,
            0,
        ],
        1728143820: [
            0.19016,
            0,
            0.19016,
            0,
            54.43501,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            192.1131,
            0,
            1.43428,
            0,
            26.21404,
            0,
            646.6608,
            0,
            0,
            0,
            29.44023,
            0,
            17.73617,
            "<D",
            114.3375,
            0,
        ],
        1728143880: [
            0.07275,
            0,
            0.07275,
            0,
            55.06437,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            203.9776,
            0,
            1.70284,
            0,
            26.31734,
            0,
            648.2254,
            0,
            0,
            0,
            29.43982,
            0,
            17.90207,
            "<D",
            114.6842,
            0,
        ],
        1728143940: [
            0.31074,
            0,
            0.31074,
            0,
            54.64013,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            187.1888,
            0,
            2.20505,
            0,
            26.22869,
            0,
            647.0698,
            0,
            0,
            0,
            29.4414,
            0,
            18.78839,
            "<D",
            114.9361,
            0,
        ],
        1728144000: [
            0.42079,
            0,
            0.42079,
            0,
            55.55107,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            182.6508,
            0,
            2.22079,
            0,
            25.96574,
            0,
            646.9673,
            0,
            0,
            0,
            29.44087,
            0,
            15.55316,
            "<D",
            116.2807,
            0,
        ],
        1728144060: [
            0.19459,
            0,
            0.19459,
            0,
            55.36854,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            217.86,
            0,
            1.54493,
            0,
            26.00118,
            0,
            649.6459,
            0,
            0,
            0,
            29.44025,
            0,
            14.30149,
            "<D",
            117.25,
            0,
        ],
        1728144120: [
            0.19757,
            0,
            0.19757,
            0,
            55.22809,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            206.5935,
            0,
            1.40979,
            0,
            26.2774,
            0,
            651.0632,
            0,
            0,
            0,
            29.43999,
            0,
            17.83837,
            "<D",
            115.9616,
            0,
        ],
        1728144180: [
            0.14302,
            0,
            0.14302,
            0,
            53.99589,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            188.9707,
            0,
            1.57871,
            0,
            26.40095,
            0,
            652.5056,
            0,
            0,
            0,
            29.43968,
            0,
            17.42766,
            "<D",
            114.9176,
            0,
        ],
        1728144240: [
            0.29637,
            0,
            0.29637,
            0,
            53.87608,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            195.2812,
            0,
            1.96709,
            0,
            26.35376,
            0,
            652.8724,
            0,
            0,
            0,
            29.43981,
            0,
            14.22311,
            "<D",
            115.5972,
            0,
        ],
        1728144300: [
            0.30397,
            0,
            0.30397,
            0,
            54.33458,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            185.6728,
            0,
            2.0578,
            0,
            26.42872,
            0,
            654.5093,
            0,
            0,
            0,
            29.43989,
            0,
            17.39203,
            "<D",
            115.1651,
            0,
        ],
        1728144360: [
            0.21074,
            0,
            0.21074,
            0,
            54.61831,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            204.373,
            0,
            1.66777,
            0,
            26.53077,
            0,
            656.2421,
            0,
            0,
            0,
            29.44028,
            0,
            17.5492,
            "<D",
            116.1411,
            0,
        ],
        1728144420: [
            0.19582,
            0,
            0.19582,
            0,
            54.23034,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            194.5831,
            0,
            1.69101,
            0,
            26.78161,
            0,
            657.3257,
            0,
            0,
            0,
            29.43974,
            0,
            18.42908,
            "<D",
            116.8932,
            0,
        ],
        1728144480: [
            0.24086,
            0,
            0.24086,
            0,
            54.19676,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            187.8342,
            0,
            2.13072,
            0,
            26.44366,
            0,
            659.2336,
            0,
            0,
            0,
            29.43964,
            0,
            13.24256,
            "<D",
            114.4608,
            0,
        ],
        1728144540: [
            0.33439,
            0,
            0.33439,
            0,
            56.22453,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            187.2687,
            0,
            1.24801,
            0,
            26.25093,
            0,
            661.1855,
            0,
            0,
            0,
            29.43952,
            0,
            18.56724,
            "<D",
            113.0172,
            0,
        ],
        1728144600: [
            0.16337,
            0,
            0.16337,
            0,
            55.89839,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            173.7041,
            0,
            1.28204,
            0,
            26.24819,
            0,
            662.5069,
            0,
            0,
            0,
            29.43963,
            0,
            13.52415,
            "<D",
            113.2318,
            0,
        ],
        1728144660: [
            0.12891,
            0,
            0.12891,
            0,
            55.2745,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            182.4156,
            0,
            0.63854,
            0,
            26.34848,
            0,
            663.348,
            0,
            0,
            0,
            29.43963,
            0,
            17.51271,
            "<D",
            114.1412,
            0,
        ],
        1728144720: [
            0.22966,
            0,
            0.22966,
            0,
            54.94324,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            181.1065,
            0,
            1.02634,
            0,
            26.71193,
            0,
            665.819,
            0,
            0,
            0,
            29.43963,
            0,
            16.39123,
            "<D",
            114.3275,
            0,
        ],
        1728144780: [
            0.17145,
            0,
            0.17145,
            0,
            53.32157,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            199.163,
            0,
            0.80754,
            0,
            27.12886,
            0,
            667.9479,
            0,
            0,
            0,
            29.43964,
            0,
            14.82436,
            "<D",
            115.7306,
            0,
        ],
        1728144840: [
            0.11161,
            0,
            0.11161,
            0,
            51.92459,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            176.718,
            0,
            1.18655,
            0,
            27.28754,
            0,
            667.3572,
            0,
            0,
            0,
            29.4391,
            0,
            16.95215,
            "<D",
            116.0361,
            0,
        ],
        1728144900: [
            0.10401,
            0,
            0.10401,
            0,
            52.63299,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            208.8741,
            0,
            1.38362,
            0,
            27.21651,
            0,
            667.0671,
            0,
            0,
            0,
            29.43861,
            0,
            18.14169,
            "<D",
            115.8593,
            0,
        ],
        1728144960: [
            0.25192,
            0,
            0.25192,
            0,
            54.37769,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            168.5654,
            0,
            1.74251,
            0,
            27.20465,
            0,
            668.8251,
            0,
            0,
            0,
            29.4372,
            0,
            17.06704,
            "<D",
            117.7343,
            0,
        ],
        1728145020: [
            0.18196,
            0,
            0.18196,
            0,
            53.52516,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            164.1226,
            0,
            1.4317,
            0,
            27.14123,
            0,
            668.6305,
            0,
            0,
            0,
            29.43643,
            0,
            18.11956,
            "<D",
            117.5067,
            0,
        ],
        1728145080: [
            0.23511,
            0,
            0.23511,
            0,
            54.51022,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            171.7644,
            0,
            1.9192,
            0,
            26.99277,
            0,
            670.8207,
            0,
            0,
            0,
            29.43643,
            0,
            18.3965,
            "<D",
            116.7679,
            0,
        ],
        1728145140: [
            0.2595,
            0,
            0.2595,
            0,
            55.15189,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            170.2908,
            0,
            1.37461,
            0,
            27.05148,
            0,
            672.3251,
            0,
            0,
            0,
            29.43676,
            0,
            17.65162,
            "<D",
            115.5784,
            0,
        ],
        1728145200: [
            0.18218,
            0,
            0.18218,
            0,
            55.87244,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            186.518,
            0,
            0.43523,
            0,
            27.30176,
            0,
            674.8135,
            0,
            0,
            0,
            29.43824,
            0,
            17.91967,
            "<D",
            116.339,
            0,
        ],
        1728145260: [
            0.06274,
            0,
            0.06274,
            0,
            55.91742,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            111.006,
            0,
            1.08325,
            0,
            27.40423,
            0,
            675.9033,
            0,
            0,
            0,
            29.43672,
            0,
            18.09184,
            "<D",
            114.984,
            0,
        ],
        1728145320: [
            0.30823,
            0,
            0.30823,
            0,
            55.27777,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            114.3794,
            0,
            1.28667,
            0,
            26.90065,
            0,
            676.2765,
            0,
            0,
            0,
            29.43634,
            0,
            16.66505,
            "<D",
            114.3735,
            0,
        ],
        1728145380: [
            0.20207,
            0,
            0.20207,
            0,
            57.32981,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            156.694,
            0,
            0.73938,
            0,
            27.01905,
            0,
            678.5352,
            0,
            0,
            0,
            29.43637,
            0,
            20.34977,
            "<D",
            114.8599,
            0,
        ],
        1728145440: [
            0.29377,
            0,
            0.29377,
            0,
            56.36752,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            166.8275,
            0,
            1.42507,
            0,
            27.01677,
            0,
            679.3355,
            0,
            0,
            0,
            29.43658,
            0,
            16.9141,
            "<D",
            115.9899,
            0,
        ],
        1728145500: [
            0.06375,
            0,
            0.06375,
            0,
            56.2552,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            142.826,
            0,
            1.48413,
            0,
            26.85011,
            0,
            679.6052,
            0,
            0,
            0,
            29.43651,
            0,
            20.70103,
            "<D",
            115.5417,
            0,
        ],
        1728145560: [
            0.21142,
            0,
            0.21142,
            0,
            54.60031,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            185.2493,
            0,
            1.48752,
            0,
            26.90922,
            0,
            681.5771,
            0,
            0,
            0,
            29.43637,
            0,
            15.78613,
            "<D",
            116.5865,
            0,
        ],
        1728145620: [
            0.3785,
            0,
            0.3785,
            0,
            56.70351,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            45.9804,
            0,
            1.15617,
            0,
            27.10327,
            0,
            681.3885,
            0,
            0,
            0,
            29.43638,
            0,
            13.98977,
            "<D",
            119.9974,
            0,
        ],
        1728145680: [
            0.31606,
            0,
            0.31606,
            0,
            56.27315,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            155.894,
            0,
            1.57716,
            0,
            26.83363,
            0,
            681.7459,
            0,
            0,
            0,
            29.43638,
            0,
            20.50345,
            "<D",
            119.8743,
            0,
        ],
        1728145740: [
            0.11725,
            0,
            0.11725,
            0,
            56.88013,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            159.7926,
            0,
            1.82773,
            0,
            26.83745,
            0,
            683.4065,
            0,
            0,
            0,
            29.43638,
            0,
            16.06409,
            "<D",
            117.4065,
            0,
        ],
        1728145800: [
            0.29233,
            0,
            0.29233,
            0,
            57.24737,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            177.1432,
            0,
            0.51893,
            0,
            26.6211,
            0,
            685.2755,
            0,
            0,
            0,
            29.43638,
            0,
            17.03367,
            "<D",
            116.5602,
            0,
        ],
        1728145860: [
            0.14078,
            0,
            0.14078,
            0,
            57.41639,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            150.5287,
            0,
            1.0592,
            0,
            26.72515,
            0,
            686.9924,
            0,
            0,
            0,
            29.43637,
            0,
            14.87747,
            "<D",
            117.4897,
            0,
        ],
        1728145920: [
            0.17237,
            0,
            0.17237,
            0,
            56.50996,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            131.402,
            0,
            1.51714,
            0,
            26.92231,
            0,
            686.8173,
            0,
            0,
            0,
            29.43623,
            0,
            16.16833,
            "<D",
            117.5643,
            0,
        ],
        1728145980: [
            0.18069,
            0,
            0.18069,
            0,
            56.77091,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            135.1396,
            0,
            1.10237,
            0,
            27.14253,
            0,
            687.5494,
            0,
            0,
            0,
            29.43618,
            0,
            16.8453,
            "<D",
            117.5859,
            0,
        ],
        1728146040: [
            0.22343,
            0,
            0.22343,
            0,
            57.11068,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            90.58015,
            0,
            1.72931,
            0,
            27.20518,
            0,
            688.9114,
            0,
            0,
            0,
            29.4348,
            0,
            16.10957,
            "<D",
            115.2857,
            0,
        ],
        1728146100: [
            0.22423,
            0,
            0.22423,
            0,
            56.82801,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            107.368,
            0,
            2.27212,
            0,
            27.07445,
            0,
            689.9568,
            0,
            0,
            0,
            29.43615,
            0,
            19.47667,
            "<D",
            115.2896,
            0,
        ],
        1728146160: [
            0.28731,
            0,
            0.28731,
            0,
            57.56644,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            99.30291,
            0,
            1.00229,
            0,
            27.13172,
            0,
            691.7011,
            0,
            0,
            0,
            29.43529,
            0,
            16.61733,
            "<D",
            116.2929,
            0,
        ],
        1728146220: [
            0.34272,
            0,
            0.34272,
            0,
            57.38969,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            163.7892,
            0,
            0.39204,
            0,
            27.24348,
            0,
            692.9543,
            0,
            0,
            0,
            29.43444,
            0,
            17.98415,
            "<D",
            116.6967,
            0,
        ],
        1728146280: [
            0.24434,
            0,
            0.24434,
            0,
            55.98133,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            128.9876,
            0,
            0.56974,
            0,
            27.6774,
            0,
            693.5048,
            0,
            0,
            0,
            29.43332,
            0,
            17.56994,
            "<D",
            117.5268,
            0,
        ],
        1728146340: [
            0.26457,
            0,
            0.26457,
            0,
            53.9566,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            100.7799,
            0,
            0.63976,
            0,
            27.79457,
            0,
            693.9088,
            0,
            0,
            0,
            29.4333,
            0,
            18.70061,
            "<D",
            118.9522,
            0,
        ],
        1728146400: [
            0.35169,
            0,
            0.35169,
            0,
            54.04766,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            132.9911,
            0,
            0.9941,
            0,
            27.35537,
            0,
            694.3746,
            0,
            0,
            0,
            29.43355,
            0,
            17.0609,
            "<D",
            119.9495,
            0,
        ],
        1728146460: [
            0.31979,
            0,
            0.31979,
            0,
            55.89089,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            214.7654,
            0,
            1.20434,
            0,
            27.02473,
            0,
            695.1375,
            0,
            0,
            0,
            29.43306,
            0,
            18.14833,
            "<D",
            119.8349,
            0,
        ],
        1728146520: [
            0.26495,
            0,
            0.26495,
            0,
            57.81495,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            233.3742,
            0,
            1.30409,
            0,
            26.93411,
            0,
            695.6832,
            0,
            0,
            0,
            29.43299,
            0,
            17.58148,
            "<D",
            118.0102,
            0,
        ],
        1728146580: [
            0.12388,
            0,
            0.12388,
            0,
            57.31219,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            195.6671,
            0,
            0.67511,
            0,
            27.61744,
            0,
            697.2653,
            0,
            0,
            0,
            29.43283,
            0,
            16.44904,
            "<D",
            116.6563,
            0,
        ],
        1728146640: [
            0.1781,
            0,
            0.1781,
            0,
            55.61243,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            167.1636,
            0,
            0.90673,
            0,
            27.67472,
            0,
            699.3303,
            0,
            0,
            0,
            29.4325,
            0,
            15.48825,
            "<D",
            116.1528,
            0,
        ],
        1728146700: [
            0.36434,
            0,
            0.36434,
            0,
            54.60906,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8796,
            0,
            100.1347,
            0,
            1.09787,
            0,
            27.68445,
            0,
            699.7172,
            0,
            0,
            0,
            29.43192,
            0,
            17.05446,
            "<D",
            116.9637,
            0,
        ],
        1728146760: [
            0.17505,
            0,
            0.17505,
            0,
            54.35135,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            46.53116,
            0,
            0.70356,
            0,
            27.41849,
            0,
            699.6026,
            0,
            0,
            0,
            29.43211,
            0,
            17.25669,
            "<D",
            117.0225,
            0,
        ],
        1728146820: [
            0.34578,
            0,
            0.34578,
            0,
            56.41419,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            103.9067,
            0,
            0.81195,
            0,
            27.59306,
            0,
            699.2878,
            0,
            0,
            0,
            29.43102,
            0,
            14.98238,
            "<D",
            114.6294,
            0,
        ],
        1728146880: [
            0.02149,
            0,
            0.02149,
            0,
            55.47527,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            130.0395,
            0,
            0.96259,
            0,
            27.73518,
            0,
            699.6168,
            0,
            0,
            0,
            29.43082,
            0,
            18.19535,
            "<D",
            114.2659,
            0,
        ],
        1728146940: [
            0.24752,
            0,
            0.24752,
            0,
            55.51614,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            161.1243,
            0,
            0.89346,
            0,
            27.51453,
            0,
            700.7126,
            0,
            0,
            0,
            29.43018,
            0,
            18.85285,
            "<D",
            115.591,
            0,
        ],
        1728147000: [
            0.19764,
            0,
            0.19764,
            0,
            56.10373,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            154.7292,
            0,
            1.10471,
            0,
            27.49475,
            0,
            701.3338,
            0,
            0,
            0,
            29.42787,
            0,
            14.07852,
            "<D",
            116.3665,
            0,
        ],
        1728147060: [
            0.22959,
            0,
            0.22959,
            0,
            55.88665,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            151.6412,
            0,
            1.39033,
            0,
            27.49075,
            0,
            701.6226,
            0,
            0,
            0,
            29.42809,
            0,
            17.7238,
            "<D",
            117.9424,
            0,
        ],
        1728147120: [
            0.08283,
            0,
            0.08283,
            0,
            55.09623,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            158.9294,
            0,
            1.73824,
            0,
            27.30944,
            0,
            704.3534,
            0,
            0,
            0,
            29.42764,
            0,
            17.74585,
            "<D",
            117.5344,
            0,
        ],
        1728147180: [
            0.17105,
            0,
            0.17105,
            0,
            56.00421,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            182.6994,
            0,
            1.77221,
            0,
            27.41152,
            0,
            705.7582,
            0,
            0,
            0,
            29.42773,
            0,
            15.49747,
            "<D",
            122.1963,
            0,
        ],
        1728147240: [
            0.14802,
            0,
            0.14802,
            0,
            55.69678,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            168.4413,
            0,
            2.37342,
            0,
            27.26545,
            0,
            704.5267,
            0,
            0,
            0,
            29.42764,
            0,
            16.68256,
            "<D",
            121.0343,
            0,
        ],
        1728147300: [
            0.33385,
            0,
            0.33385,
            0,
            55.5695,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            158.9576,
            0,
            2.3058,
            0,
            27.23458,
            0,
            703.5866,
            0,
            0,
            0,
            29.42767,
            0,
            17.63132,
            "<D",
            118.8793,
            0,
        ],
        1728147360: [
            0.29918,
            0,
            0.29918,
            0,
            57.49104,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            168.8564,
            0,
            1.78354,
            0,
            27.23335,
            0,
            705.1675,
            0,
            0,
            0,
            29.42758,
            0,
            16.05527,
            "<D",
            117.092,
            0,
        ],
        1728147420: [
            0.22073,
            0,
            0.22073,
            0,
            57.02994,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            147.9308,
            0,
            1.96214,
            0,
            27.21567,
            0,
            706.4912,
            0,
            0,
            0,
            29.42739,
            0,
            16.94929,
            "<D",
            116.9564,
            0,
        ],
        1728147480: [
            0.33952,
            0,
            0.33952,
            0,
            57.55508,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            136.3126,
            0,
            1.72145,
            0,
            27.12618,
            0,
            707.9703,
            0,
            0,
            0,
            29.42607,
            0,
            16.27249,
            "<D",
            117.3599,
            0,
        ],
        1728147540: [
            0.1017,
            0,
            0.1017,
            0,
            57.19989,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            194.8631,
            0,
            0.89684,
            0,
            27.51057,
            0,
            708.3812,
            0,
            0,
            0,
            29.42422,
            0,
            16.53147,
            "<D",
            115.6559,
            0,
        ],
        1728147600: [
            0.2093,
            0,
            0.2093,
            0,
            55.97046,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            177.7882,
            0,
            1.31166,
            0,
            27.74083,
            0,
            708.236,
            0,
            0,
            0,
            29.42422,
            0,
            17.07306,
            "<D",
            116.644,
            0,
        ],
        1728147660: [
            0.12115,
            0,
            0.12115,
            0,
            55.05535,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            159.5841,
            0,
            1.12777,
            0,
            27.67198,
            0,
            708.1049,
            0,
            0,
            0,
            29.42421,
            0,
            18.27747,
            "<D",
            116.3388,
            0,
        ],
        1728147720: [
            0.34317,
            0,
            0.34317,
            0,
            55.05454,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            172.7776,
            0,
            0.82528,
            0,
            27.79032,
            0,
            708.8276,
            0,
            0,
            0,
            29.42366,
            0,
            17.54482,
            "<D",
            115.4858,
            0,
        ],
        1728147780: [
            0.29128,
            0,
            0.29128,
            0,
            54.94956,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            133.4257,
            0,
            1.546,
            0,
            27.84747,
            0,
            708.4485,
            0,
            0,
            0,
            29.42362,
            0,
            13.34066,
            "<D",
            116.8436,
            0,
        ],
        1728147840: [
            0.16009,
            0,
            0.16009,
            0,
            55.10371,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            144.9371,
            0,
            0.37239,
            0,
            27.3786,
            0,
            709.274,
            0,
            0,
            0,
            29.42127,
            0,
            18.32597,
            "<D",
            116.9099,
            0,
        ],
        1728147900: [
            0.12715,
            0,
            0.12715,
            0,
            56.12636,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            183.1019,
            0,
            0.60134,
            0,
            27.46876,
            0,
            710.0884,
            0,
            0,
            0,
            29.421,
            0,
            16.31157,
            "<D",
            119.2227,
            0,
        ],
        1728147960: [
            0.21512,
            0,
            0.21512,
            0,
            55.35556,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            145.4504,
            0,
            0.92527,
            0,
            27.55082,
            0,
            710.9729,
            0,
            0,
            0,
            29.42124,
            0,
            16.84867,
            "<D",
            120.138,
            0,
        ],
        1728148020: [
            0.21596,
            0,
            0.21596,
            0,
            54.34404,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            154.3059,
            0,
            1.63205,
            0,
            27.56358,
            0,
            713.478,
            0,
            0,
            0,
            29.4215,
            0,
            17.49837,
            "<D",
            120.9513,
            0,
        ],
        1728148080: [
            0.22441,
            0,
            0.22441,
            0,
            54.94503,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            160.3447,
            0,
            2.08635,
            0,
            27.62706,
            0,
            714.9028,
            0,
            0,
            0,
            29.42097,
            0,
            19.56582,
            "<D",
            117.9865,
            0,
        ],
        1728148140: [
            0.19733,
            0,
            0.19733,
            0,
            56.06554,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            180.0019,
            0,
            2.25364,
            0,
            27.91047,
            0,
            714.5311,
            0,
            0,
            0,
            29.42103,
            0,
            17.51003,
            "<D",
            118.198,
            0,
        ],
        1728148200: [
            0.10818,
            0,
            0.10818,
            0,
            56.4412,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            192.5297,
            0,
            1.7738,
            0,
            27.92103,
            0,
            714.485,
            0,
            0,
            0,
            29.42105,
            0,
            14.65257,
            "<D",
            118.4813,
            0,
        ],
        1728148260: [
            0.1762,
            0,
            0.1762,
            0,
            57.61472,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            199.5363,
            0,
            1.10586,
            0,
            27.8976,
            0,
            715.2334,
            0,
            0,
            0,
            29.42096,
            0,
            20.75864,
            "<D",
            117.1375,
            0,
        ],
        1728148320: [
            0.39426,
            0,
            0.39426,
            0,
            57.9851,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            221.9572,
            0,
            1.72784,
            0,
            28.03442,
            0,
            714.6482,
            0,
            0,
            0,
            29.42094,
            0,
            14.77889,
            "<D",
            114.8094,
            0,
        ],
        1728148380: [
            0.32131,
            0,
            0.32131,
            0,
            57.7092,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            165.6109,
            0,
            2.31633,
            0,
            27.91486,
            0,
            714.5666,
            0,
            0,
            0,
            29.42095,
            0,
            17.19866,
            "<D",
            115.3082,
            0,
        ],
        1728148440: [
            0.20444,
            0,
            0.20444,
            0,
            59.05606,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            195.1602,
            0,
            1.0768,
            0,
            27.91875,
            0,
            712.8326,
            0,
            0,
            0,
            29.42095,
            0,
            16.01511,
            "<D",
            116.6975,
            0,
        ],
        1728148500: [
            0.21818,
            0,
            0.21818,
            0,
            58.25469,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            215.3802,
            0,
            1.65778,
            0,
            28.20813,
            0,
            713.2155,
            0,
            0,
            0,
            29.42096,
            0,
            19.63924,
            "<D",
            116.9692,
            0,
        ],
        1728148560: [
            0.18199,
            0,
            0.18199,
            0,
            59.01421,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8882,
            0,
            155.9453,
            0,
            1.48102,
            0,
            27.82526,
            0,
            714.063,
            0,
            0,
            0,
            29.42098,
            0,
            19.48294,
            "<D",
            116.9462,
            0,
        ],
        1728148620: [
            0.01486,
            0,
            0.01486,
            0,
            58.5974,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            79.66315,
            0,
            0.83739,
            0,
            27.72383,
            0,
            714.3821,
            0,
            0,
            0,
            29.42095,
            0,
            15.77092,
            "<D",
            120.4556,
            0,
        ],
        1728148680: [
            0.2639,
            0,
            0.2639,
            0,
            55.84295,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            62.9376,
            0,
            0.68197,
            0,
            28.00477,
            0,
            714.0488,
            0,
            0,
            0,
            29.42098,
            0,
            14.85367,
            "<D",
            119.4802,
            0,
        ],
        1728148740: [
            0.20154,
            0,
            0.20154,
            0,
            57.06876,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            132.3879,
            0,
            0.69334,
            0,
            28.01973,
            0,
            714.0311,
            0,
            0,
            0,
            29.4207,
            0,
            19.71453,
            "<D",
            120.8278,
            0,
        ],
        1728148800: [
            0.25546,
            0,
            0.25546,
            0,
            58.41176,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.914,
            0,
            317.7055,
            0,
            0.81471,
            0,
            28.11567,
            0,
            713.6552,
            0,
            0,
            0,
            29.42083,
            0,
            17.74313,
            "<D",
            120.1542,
            0,
        ],
        1728148860: [
            0.1974,
            0,
            0.1974,
            0,
            57.66767,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            276.8347,
            0,
            0.71933,
            0,
            28.37177,
            0,
            715.0986,
            0,
            0,
            0,
            29.42094,
            0,
            15.77602,
            "<D",
            120.5932,
            0,
        ],
        1728148920: [
            0.00365,
            0,
            0.00365,
            0,
            57.3033,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            306.603,
            0,
            1.15722,
            0,
            28.39001,
            0,
            715.6215,
            0,
            0,
            0,
            29.42037,
            0,
            17.12581,
            "<D",
            120.0193,
            0,
        ],
        1728148980: [
            0.24866,
            0,
            0.24866,
            0,
            57.98408,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            238.6576,
            0,
            0.97369,
            0,
            28.251,
            0,
            717.2273,
            0,
            0,
            0,
            29.41975,
            0,
            15.93975,
            "<D",
            118.3,
            0,
        ],
        1728149040: [
            0.1881,
            0,
            0.1881,
            0,
            57.71492,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            190.4516,
            0,
            1.49337,
            0,
            28.10418,
            0,
            717.2898,
            0,
            0,
            0,
            29.42094,
            0,
            15.70483,
            "<D",
            117.1422,
            0,
        ],
        1728149100: [
            0.18996,
            0,
            0.18996,
            0,
            58.0128,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            148.2785,
            0,
            0.85469,
            0,
            28.5943,
            0,
            718.1386,
            0,
            0,
            0,
            29.42056,
            0,
            17.47795,
            "<D",
            115.5901,
            0,
        ],
        1728149160: [
            0.25145,
            0,
            0.25145,
            0,
            56.54691,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            155.3584,
            0,
            0.78752,
            0,
            28.48196,
            0,
            718.4105,
            0,
            0,
            0,
            29.42094,
            0,
            16.80365,
            "<D",
            117.026,
            0,
        ],
        1728149220: [
            0.26618,
            0,
            0.26618,
            0,
            56.61538,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            191.1312,
            0,
            1.27538,
            0,
            28.53284,
            0,
            718.1507,
            0,
            0,
            0,
            29.42045,
            0,
            14.74314,
            "<D",
            119.1399,
            0,
        ],
        1728149280: [
            0.30591,
            0,
            0.30591,
            0,
            58.51606,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            191.1566,
            0,
            0.85213,
            0,
            28.57251,
            0,
            718.1116,
            0,
            0,
            0,
            29.42029,
            0,
            19.68564,
            "<D",
            118.6427,
            0,
        ],
        1728149340: [
            0.05499,
            0,
            0.05499,
            0,
            59.6932,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            153.5212,
            0,
            1.25488,
            0,
            28.39702,
            0,
            716.9385,
            0,
            0,
            0,
            29.42056,
            0,
            18.84421,
            "<D",
            120.2502,
            0,
        ],
        1728149400: [
            0.15933,
            0,
            0.15933,
            0,
            57.96354,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            214.3549,
            0,
            0.88429,
            0,
            28.40341,
            0,
            716.7617,
            0,
            0,
            0,
            29.41933,
            0,
            17.01819,
            "<D",
            119.6286,
            0,
        ],
        1728149460: [
            0.32471,
            0,
            0.32471,
            0,
            58.14942,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            185.5791,
            0,
            1.11143,
            0,
            28.71375,
            0,
            717.0115,
            0,
            0,
            0,
            29.41904,
            0,
            15.87931,
            "<D",
            120.9702,
            0,
        ],
        1728149520: [
            0.16376,
            0,
            0.16376,
            0,
            57.18071,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            194.1397,
            0,
            1.46517,
            0,
            28.56653,
            0,
            716.7767,
            0,
            0,
            0,
            29.41866,
            0,
            18.21028,
            "<D",
            119.225,
            0,
        ],
        1728149580: [
            0.16586,
            0,
            0.16586,
            0,
            58.99245,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            203.9773,
            0,
            1.19815,
            0,
            28.57735,
            0,
            716.0862,
            0,
            0,
            0,
            29.41768,
            0,
            15.24283,
            "<D",
            119.3369,
            0,
        ],
        1728149640: [
            0.11744,
            0,
            0.11744,
            0,
            58.36668,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            212.9919,
            0,
            1.2956,
            0,
            28.43898,
            0,
            715.684,
            0,
            0,
            0,
            29.4178,
            0,
            15.95873,
            "<D",
            118.3363,
            0,
        ],
        1728149700: [
            0.2253,
            0,
            0.2253,
            0,
            58.15396,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.914,
            0,
            208.6918,
            0,
            1.7225,
            0,
            28.53643,
            0,
            714.9943,
            0,
            0,
            0,
            29.41767,
            0,
            20.13283,
            "<D",
            117.1405,
            0,
        ],
        1728149760: [
            0.34706,
            0,
            0.34706,
            0,
            58.42714,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            226.3064,
            0,
            0.80998,
            0,
            28.0239,
            0,
            715.1837,
            0,
            0,
            0,
            29.41755,
            0,
            15.34216,
            "<D",
            116.6707,
            0,
        ],
        1728149820: [
            0.17615,
            0,
            0.17615,
            0,
            59.82356,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            197.2147,
            0,
            1.3379,
            0,
            28.12447,
            0,
            714.9191,
            0,
            0,
            0,
            29.41731,
            0,
            16.97297,
            "<D",
            116.7856,
            0,
        ],
        1728149880: [
            0.2504,
            0,
            0.2504,
            0,
            58.29115,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            183.7522,
            0,
            1.78445,
            0,
            28.03946,
            0,
            713.6679,
            0,
            0,
            0,
            29.4171,
            0,
            20.02692,
            "<D",
            117.4897,
            0,
        ],
        1728149940: [
            0.1807,
            0,
            0.1807,
            0,
            58.65246,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            224.926,
            0,
            1.35394,
            0,
            28.22439,
            0,
            712.8694,
            0,
            0,
            0,
            29.41734,
            0,
            16.4505,
            "<D",
            118.4483,
            0,
        ],
        1728150000: [
            0.03789,
            0,
            0.03789,
            0,
            57.53956,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            200.6414,
            0,
            1.20388,
            0,
            28.60563,
            0,
            713.0637,
            0,
            0,
            0,
            29.41593,
            0,
            16.0499,
            "<D",
            117.9332,
            0,
        ],
        1728150060: [
            0.21239,
            0,
            0.21239,
            0,
            58.08629,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            189.1963,
            0,
            1.01951,
            0,
            28.7756,
            0,
            715.2356,
            0,
            0,
            0,
            29.41567,
            0,
            16.36034,
            "<D",
            119.8617,
            0,
        ],
        1728150120: [
            0.08411,
            0,
            0.08411,
            0,
            58.35291,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            203.2722,
            0,
            0.96222,
            0,
            28.68947,
            0,
            716.1487,
            0,
            0,
            0,
            29.41501,
            0,
            15.64658,
            "<D",
            119.9915,
            0,
        ],
        1728150180: [
            0.14606,
            0,
            0.14606,
            0,
            57.95279,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            242.9646,
            0,
            0.5097,
            0,
            28.73952,
            0,
            716.4786,
            0,
            0,
            0,
            29.41442,
            0,
            16.57563,
            "<D",
            121.5215,
            0,
        ],
        1728150240: [
            0.20993,
            0,
            0.20993,
            0,
            57.73566,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.914,
            0,
            205.1548,
            0,
            0.77483,
            0,
            29.10777,
            0,
            715.7471,
            0,
            0,
            0,
            29.4142,
            0,
            17.80956,
            "<D",
            120.3898,
            0,
        ],
        1728150300: [
            0.20345,
            0,
            0.20345,
            0,
            57.159,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            232.3213,
            0,
            1.07918,
            0,
            29.11919,
            0,
            714.6191,
            0,
            0,
            0,
            29.414,
            0,
            13.99736,
            "<D",
            117.7609,
            0,
        ],
        1728150360: [
            0.1124,
            0,
            0.1124,
            0,
            57.61069,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            193.8519,
            0,
            0.96839,
            0,
            28.85217,
            0,
            714.0729,
            0,
            0,
            0,
            29.41362,
            0,
            14.30602,
            "<D",
            117.3522,
            0,
        ],
        1728150420: [
            0.12171,
            0,
            0.12171,
            0,
            57.31054,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            193.3355,
            0,
            1.1006,
            0,
            28.7798,
            0,
            713.5268,
            0,
            0,
            0,
            29.41203,
            0,
            13.20836,
            "<D",
            117.2024,
            0,
        ],
        1728150480: [
            0.15481,
            0,
            0.15481,
            0,
            58.55944,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            169.8873,
            0,
            2.028,
            0,
            28.88764,
            0,
            712.9538,
            0,
            0,
            0,
            29.41233,
            0,
            17.48354,
            "<D",
            116.5523,
            0,
        ],
        1728150540: [
            0.26923,
            0,
            0.26923,
            0,
            57.78762,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            174.6006,
            0,
            1.77444,
            0,
            28.86187,
            0,
            712.7439,
            0,
            0,
            0,
            29.41274,
            0,
            16.26055,
            "<D",
            116.3588,
            0,
        ],
        1728150600: [
            0.20386,
            0,
            0.20386,
            0,
            57.26472,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            148.6543,
            0,
            1.01157,
            0,
            29.10239,
            0,
            711.8568,
            0,
            0,
            0,
            29.41173,
            0,
            14.67316,
            "<D",
            117.269,
            0,
        ],
        1728150660: [
            0.17944,
            0,
            0.17944,
            0,
            56.72287,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.871,
            0,
            191.8972,
            0,
            0.86502,
            0,
            29.26363,
            0,
            711.8178,
            0,
            0,
            0,
            29.4125,
            0,
            15.58407,
            "<D",
            117.5312,
            0,
        ],
        1728150720: [
            0.21334,
            0,
            0.21334,
            0,
            57.11027,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            179.8529,
            0,
            1.58805,
            0,
            29.19039,
            0,
            711.4719,
            0,
            0,
            0,
            29.4103,
            0,
            19.1899,
            "<D",
            117.3009,
            0,
        ],
        1728150780: [
            0.23069,
            0,
            0.23069,
            0,
            57.1815,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            147.2559,
            0,
            1.5121,
            0,
            28.92193,
            0,
            711.0089,
            0,
            0,
            0,
            29.40997,
            0,
            17.75427,
            "<D",
            118.561,
            0,
        ],
        1728150840: [
            0.19747,
            0,
            0.19747,
            0,
            58.32205,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            139.9936,
            0,
            0.58345,
            0,
            28.47953,
            0,
            710.1952,
            0,
            0,
            0,
            29.4101,
            0,
            18.28123,
            "<D",
            118.463,
            0,
        ],
        1728150900: [
            0.15511,
            0,
            0.15511,
            0,
            59.14561,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            222.8898,
            0,
            0.85535,
            0,
            28.50092,
            0,
            709.4878,
            0,
            0,
            0,
            29.40972,
            0,
            16.30793,
            "<D",
            118.6891,
            0,
        ],
        1728150960: [
            0.20624,
            0,
            0.20624,
            0,
            59.38034,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            194.1183,
            0,
            1.53035,
            0,
            28.46237,
            0,
            708.9868,
            0,
            0,
            0,
            29.40985,
            0,
            16.13643,
            "<D",
            118.3226,
            0,
        ],
        1728151020: [
            0.07807,
            0,
            0.07807,
            0,
            59.79063,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            166.7966,
            0,
            1.10291,
            0,
            28.4498,
            0,
            707.017,
            0,
            0,
            0,
            29.40756,
            0,
            16.10559,
            "<D",
            116.2912,
            0,
        ],
        1728151080: [
            0.13092,
            0,
            0.13092,
            0,
            59.96728,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            186.8283,
            0,
            1.72446,
            0,
            28.49534,
            0,
            706.2267,
            0,
            0,
            0,
            29.40648,
            0,
            16.35241,
            "<D",
            114.8499,
            0,
        ],
        1728151140: [
            0.23653,
            0,
            0.23653,
            0,
            59.68694,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            176.9586,
            0,
            1.92498,
            0,
            28.5281,
            0,
            705.7049,
            0,
            0,
            0,
            29.4064,
            0,
            14.87199,
            "<D",
            115.2499,
            0,
        ],
        1728151200: [
            0.10811,
            0,
            0.10811,
            0,
            59.25881,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            133.7702,
            0,
            2.1021,
            0,
            28.60434,
            0,
            706.0703,
            0,
            0,
            0,
            29.40643,
            0,
            13.18992,
            "<D",
            115.8047,
            0,
        ],
        1728151260: [
            0.2328,
            0,
            0.2328,
            0,
            58.92841,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            136.9094,
            0,
            2.20405,
            0,
            28.3632,
            0,
            705.2898,
            0,
            0,
            0,
            29.4067,
            0,
            13.76354,
            "<D",
            117.2887,
            0,
        ],
        1728151320: [
            0.24162,
            0,
            0.24162,
            0,
            59.55743,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            136.6375,
            0,
            1.80475,
            0,
            28.2737,
            0,
            705.7507,
            0,
            0,
            0,
            29.40705,
            0,
            17.49831,
            "<D",
            117.5752,
            0,
        ],
        1728151380: [
            0.22509,
            0,
            0.22509,
            0,
            59.80575,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            268.393,
            0,
            0.32721,
            0,
            28.10776,
            0,
            704.6573,
            0,
            0,
            0,
            29.40616,
            0,
            16.65047,
            "<D",
            117.6282,
            0,
        ],
        1728151440: [
            0.18833,
            0,
            0.18833,
            0,
            60.2354,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            211.3532,
            0,
            0.48767,
            0,
            28.37202,
            0,
            702.4658,
            0,
            0,
            0,
            29.40347,
            0,
            15.47542,
            "<D",
            118.4586,
            0,
        ],
        1728151500: [
            0.26841,
            0,
            0.26841,
            0,
            59.83908,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8624,
            0,
            159.9755,
            0,
            1.1632,
            0,
            28.71391,
            0,
            702.6328,
            0,
            0,
            0,
            29.40353,
            0,
            16.9624,
            "<D",
            119.1132,
            0,
        ],
        1728151560: [
            0.10193,
            0,
            0.10193,
            0,
            59.40486,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            173.1565,
            0,
            2.02412,
            0,
            28.63233,
            0,
            703.1984,
            0,
            0,
            0,
            29.40328,
            0,
            17.1374,
            "<D",
            118.9779,
            0,
        ],
        1728151620: [
            0.23764,
            0,
            0.23764,
            0,
            58.72105,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            95.38699,
            0,
            0.83658,
            0,
            28.74747,
            0,
            702.4758,
            0,
            0,
            0,
            29.4032,
            0,
            15.33873,
            "<D",
            118.5966,
            0,
        ],
        1728151680: [
            0.14546,
            0,
            0.14546,
            0,
            58.48304,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            96.11759,
            0,
            2.28801,
            0,
            28.57946,
            0,
            702.2387,
            0,
            0,
            0,
            29.403,
            0,
            15.28329,
            "<D",
            116.3883,
            0,
        ],
        1728151740: [
            0.2839,
            0,
            0.2839,
            0,
            58.01572,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            146.5369,
            0,
            1.59379,
            0,
            28.6659,
            0,
            701.0145,
            0,
            0,
            0,
            29.40312,
            0,
            16.7969,
            "<D",
            116.314,
            0,
        ],
        1728151800: [
            0.31337,
            0,
            0.31337,
            0,
            58.44411,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            163.785,
            0,
            1.3359,
            0,
            28.79873,
            0,
            700.048,
            0,
            0,
            0,
            29.40311,
            0,
            16.05216,
            "<D",
            116.383,
            0,
        ],
        1728151860: [
            0.15093,
            0,
            0.15093,
            0,
            56.6021,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            96.10859,
            0,
            0.61618,
            0,
            29.17108,
            0,
            699.1603,
            0,
            0,
            0,
            29.40286,
            0,
            16.77652,
            "<D",
            116.4042,
            0,
        ],
        1728151920: [
            0.15049,
            0,
            0.15049,
            0,
            58.30382,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            150.3411,
            0,
            1.20885,
            0,
            29.41415,
            0,
            698.1169,
            0,
            0,
            0,
            29.40191,
            0,
            15.88108,
            "<D",
            117.263,
            0,
        ],
        1728151980: [
            0.19201,
            0,
            0.19201,
            0,
            58.62667,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            174.898,
            0,
            1.46594,
            0,
            29.06682,
            0,
            697.0498,
            0,
            0,
            0,
            29.40125,
            0,
            16.91692,
            "<D",
            117.8603,
            0,
        ],
        1728152040: [
            0.47236,
            0,
            0.47236,
            0,
            58.76358,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            211.3812,
            0,
            1.13127,
            0,
            28.98603,
            0,
            696.0641,
            0,
            0,
            0,
            29.39988,
            0,
            16.00833,
            "<D",
            117.6438,
            0,
        ],
        1728152100: [
            0.37527,
            0,
            0.37527,
            0,
            59.65181,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            171.5095,
            0,
            1.36582,
            0,
            28.99494,
            0,
            695.2796,
            0,
            0,
            0,
            29.39895,
            0,
            16.17793,
            "<D",
            118.3692,
            0,
        ],
        1728152160: [
            0.27394,
            0,
            0.27394,
            0,
            60.13425,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            200.9437,
            0,
            1.27322,
            0,
            29.10874,
            0,
            694.1635,
            0,
            0,
            0,
            29.39868,
            0,
            18.11837,
            "<D",
            118.7423,
            0,
        ],
        1728152220: [
            0.09584,
            0,
            0.09584,
            0,
            59.96147,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            215.0454,
            0,
            1.21705,
            0,
            29.18842,
            0,
            693.2126,
            0,
            0,
            0,
            29.39896,
            0,
            17.74488,
            "<D",
            118.99,
            0,
        ],
        1728152280: [
            0.18336,
            0,
            0.18336,
            0,
            59.73275,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            164.4636,
            0,
            1.03243,
            0,
            29.37557,
            0,
            692.932,
            0,
            0,
            0,
            29.39881,
            0,
            15.6001,
            "<D",
            117.9703,
            0,
        ],
        1728152340: [
            0.19653,
            0,
            0.19653,
            0,
            58.92945,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8667,
            0,
            168.2214,
            0,
            1.05914,
            0,
            29.02408,
            0,
            692.3167,
            0,
            0,
            0,
            29.39787,
            0,
            15.42857,
            "<D",
            116.4228,
            0,
        ],
        1728152400: [
            0.1092,
            0,
            0.1092,
            0,
            58.91071,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            152.2541,
            0,
            1.61285,
            0,
            29.11182,
            0,
            692.2192,
            0,
            0,
            0,
            29.39875,
            0,
            16.42572,
            "<D",
            115.3181,
            0,
        ],
        1728152460: [
            0.18298,
            0,
            0.18298,
            0,
            57.21795,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            162.7185,
            0,
            1.60164,
            0,
            29.42844,
            0,
            691.3204,
            0,
            0,
            0,
            29.39791,
            0,
            17.32177,
            "<D",
            115.354,
            0,
        ],
        1728152520: [
            0.26686,
            0,
            0.26686,
            0,
            58.31461,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            170.6907,
            0,
            1.58671,
            0,
            29.29729,
            0,
            690.4405,
            0,
            0,
            0,
            29.39631,
            0,
            16.18541,
            "<D",
            115.4245,
            0,
        ],
        1728152580: [
            0.22021,
            0,
            0.22021,
            0,
            59.74241,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            198.8068,
            0,
            1.41853,
            0,
            29.01426,
            0,
            690.2703,
            0,
            0,
            0,
            29.39504,
            0,
            16.5951,
            "<D",
            117.7789,
            0,
        ],
        1728152640: [
            0.2899,
            0,
            0.2899,
            0,
            60.60145,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            149.6542,
            0,
            1.35343,
            0,
            29.14852,
            0,
            689.4309,
            0,
            0,
            0,
            29.39503,
            0,
            16.05238,
            "<D",
            118.0548,
            0,
        ],
        1728152700: [
            0.21073,
            0,
            0.21073,
            0,
            59.84549,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            60.86322,
            0,
            0.99319,
            0,
            29.10093,
            0,
            687.9111,
            0,
            0,
            0,
            29.39513,
            0,
            17.53547,
            "<D",
            116.9417,
            0,
        ],
        1728152760: [
            0.29956,
            0,
            0.29956,
            0,
            60.07576,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            175.5087,
            0,
            1.15554,
            0,
            29.09085,
            0,
            686.8752,
            0,
            0,
            0,
            29.39454,
            0,
            16.68933,
            "<D",
            118.501,
            0,
        ],
        1728152820: [
            0.18479,
            0,
            0.18479,
            0,
            60.11244,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            37.60117,
            0,
            0.72504,
            0,
            28.89751,
            0,
            685.8376,
            0,
            0,
            0,
            29.39322,
            0,
            17.35356,
            "<D",
            119.7406,
            0,
        ],
        1728152880: [
            0.04964,
            0,
            0.04964,
            0,
            60.08058,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            77.9229,
            0,
            1.37701,
            0,
            28.76928,
            0,
            684.6878,
            0,
            0,
            0,
            29.39268,
            0,
            16.4287,
            "<D",
            119.2924,
            0,
        ],
        1728152940: [
            0.138,
            0,
            0.138,
            0,
            60.07137,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8624,
            0,
            110.5031,
            0,
            1.1629,
            0,
            29.36335,
            0,
            684.0315,
            0,
            0,
            0,
            29.39251,
            0,
            17.23248,
            "<D",
            116.6173,
            0,
        ],
        1728153000: [
            0.17165,
            0,
            0.17165,
            0,
            59.13358,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            140.3417,
            0,
            1.4793,
            0,
            29.73979,
            0,
            683.285,
            0,
            0,
            0,
            29.39255,
            0,
            16.10511,
            "<D",
            114.8765,
            0,
        ],
        1728153060: [
            0.28045,
            0,
            0.28045,
            0,
            59.4705,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            150.843,
            0,
            2.17457,
            0,
            29.34058,
            0,
            681.7933,
            0,
            0,
            0,
            29.39249,
            0,
            14.83641,
            "<D",
            113.8406,
            0,
        ],
        1728153120: [
            0.07598,
            0,
            0.07598,
            0,
            59.68929,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            143.5993,
            0,
            1.86638,
            0,
            29.13757,
            0,
            680.8217,
            0,
            0,
            0,
            29.3925,
            0,
            17.40279,
            "<D",
            114.4817,
            0,
        ],
        1728153180: [
            0.35985,
            0,
            0.35985,
            0,
            59.83059,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            179.8608,
            0,
            1.24586,
            0,
            28.86135,
            0,
            679.4249,
            0,
            0,
            0,
            29.39207,
            0,
            15.01286,
            "<D",
            114.8734,
            0,
        ],
        1728153240: [
            0.25338,
            0,
            0.25338,
            0,
            60.77506,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            138.0696,
            0,
            0.73937,
            0,
            28.95175,
            0,
            677.3233,
            0,
            0,
            0,
            29.39058,
            0,
            18.25473,
            "<D",
            114.6115,
            0,
        ],
        1728153300: [
            0.21699,
            0,
            0.21699,
            0,
            60.54803,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            99.04636,
            0,
            1.09872,
            0,
            29.09482,
            0,
            676.767,
            0,
            0,
            0,
            29.39007,
            0,
            16.7236,
            "<D",
            114.9653,
            0,
        ],
        1728153360: [
            0.29302,
            0,
            0.29302,
            0,
            60.93667,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            141.0674,
            0,
            1.60143,
            0,
            28.97696,
            0,
            676.3779,
            0,
            0,
            0,
            29.38936,
            0,
            15.80524,
            "<D",
            116.8161,
            0,
        ],
        1728153420: [
            0.16399,
            0,
            0.16399,
            0,
            60.71128,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            209.8348,
            0,
            0.44141,
            0,
            28.7908,
            0,
            674.7592,
            0,
            0,
            0,
            29.38928,
            0,
            14.84088,
            "<D",
            117.1299,
            0,
        ],
        1728153480: [
            0.15933,
            0,
            0.15933,
            0,
            61.79735,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            239.0855,
            0,
            1.77012,
            0,
            28.84695,
            0,
            673.1685,
            0,
            0,
            0,
            29.38871,
            0,
            19.30192,
            "<D",
            117.5767,
            0,
        ],
        1728153540: [
            0.27148,
            0,
            0.27148,
            0,
            62.53927,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            167.2716,
            0,
            1.45272,
            0,
            28.80616,
            0,
            672.2026,
            0,
            0,
            0,
            29.38566,
            0,
            18.37417,
            "<D",
            116.1038,
            0,
        ],
        1728153600: [
            0.1347,
            0,
            0.1347,
            0,
            62.13276,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            170.1645,
            0,
            1.51578,
            0,
            28.87019,
            0,
            671.4409,
            0,
            0,
            0,
            29.38583,
            0,
            14.58984,
            "<D",
            117.1285,
            0,
        ],
        1728153660: [
            0.11736,
            0,
            0.11736,
            0,
            59.76125,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            192.5155,
            0,
            1.19851,
            0,
            28.93868,
            0,
            670.3331,
            0,
            0,
            0,
            29.38549,
            0,
            16.23727,
            "<D",
            115.3091,
            0,
        ],
        1728153720: [
            0.19753,
            0,
            0.19753,
            0,
            60.78101,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            203.818,
            0,
            1.44866,
            0,
            28.9034,
            0,
            669.5759,
            0,
            0,
            0,
            29.38459,
            0,
            16.14921,
            "<D",
            114.0978,
            0,
        ],
        1728153780: [
            0.234,
            0,
            0.234,
            0,
            61.43134,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            202.7454,
            0,
            1.61988,
            0,
            28.78465,
            0,
            668.5641,
            0,
            0,
            0,
            29.38342,
            0,
            15.1685,
            "<D",
            114.9539,
            0,
        ],
        1728153840: [
            0.28403,
            0,
            0.28403,
            0,
            61.93642,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            185.0642,
            0,
            1.21926,
            0,
            29.0536,
            0,
            667.2106,
            0,
            0,
            0,
            29.38276,
            0,
            15.67495,
            "<D",
            115.438,
            0,
        ],
        1728153900: [
            0.19239,
            0,
            0.19239,
            0,
            60.95412,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            178.748,
            0,
            1.23348,
            0,
            28.83393,
            0,
            666.0237,
            0,
            0,
            0,
            29.38209,
            0,
            17.09446,
            "<D",
            115.8653,
            0,
        ],
        1728153960: [
            0.24872,
            0,
            0.24872,
            0,
            59.51462,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            202.7686,
            0,
            1.16592,
            0,
            29.11755,
            0,
            665.5471,
            0,
            0,
            0,
            29.38205,
            0,
            15.5465,
            "<D",
            115.9931,
            0,
        ],
        1728154020: [
            0.17894,
            0,
            0.17894,
            0,
            59.75715,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            188.6713,
            0,
            1.30102,
            0,
            29.0524,
            0,
            664.035,
            0,
            0,
            0,
            29.38214,
            0,
            17.58301,
            "<D",
            118.1085,
            0,
        ],
        1728154080: [
            0.16399,
            0,
            0.16399,
            0,
            60.88054,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            194.5375,
            0,
            1.49404,
            0,
            29.18803,
            0,
            662.8707,
            0,
            0,
            0,
            29.38224,
            0,
            16.08158,
            "<D",
            118.2492,
            0,
        ],
        1728154140: [
            0.39513,
            0,
            0.39513,
            0,
            60.55465,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            181.8134,
            0,
            1.51815,
            0,
            29.38748,
            0,
            661.371,
            0,
            0,
            0,
            29.38203,
            0,
            14.95304,
            "<D",
            118.6235,
            0,
        ],
        1728154200: [
            0.30887,
            0,
            0.30887,
            0,
            61.4472,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            220.9647,
            0,
            1.00511,
            0,
            29.74569,
            0,
            659.8019,
            0,
            0,
            0,
            29.38192,
            0,
            15.6872,
            "<D",
            119.1896,
            0,
        ],
        1728154260: [
            0.29193,
            0,
            0.29193,
            0,
            59.60633,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            187.7617,
            0,
            1.47501,
            0,
            29.71567,
            0,
            658.2545,
            0,
            0,
            0,
            29.38188,
            0,
            17.19066,
            "<D",
            117.3028,
            0,
        ],
        1728154320: [
            0.21175,
            0,
            0.21175,
            0,
            60.22793,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            171.3134,
            0,
            2.10871,
            0,
            29.13514,
            0,
            657.1247,
            0,
            0,
            0,
            29.38103,
            0,
            14.51793,
            "<D",
            116.3779,
            0,
        ],
        1728154380: [
            0.26654,
            0,
            0.26654,
            0,
            62.08047,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            161.6661,
            0,
            1.16718,
            0,
            28.89727,
            0,
            655.6096,
            0,
            0,
            0,
            29.38153,
            0,
            15.94568,
            "<D",
            114.6566,
            0,
        ],
        1728154440: [
            0.08834,
            0,
            0.08834,
            0,
            62.34646,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            175.7052,
            0,
            1.12569,
            0,
            29.06617,
            0,
            654.0973,
            0,
            0,
            0,
            29.37968,
            0,
            17.46604,
            "<D",
            114.8299,
            0,
        ],
        1728154500: [
            0.22638,
            0,
            0.22638,
            0,
            61.37901,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            226.7535,
            0,
            0.78076,
            0,
            29.34458,
            0,
            653.4193,
            0,
            0,
            0,
            29.37864,
            0,
            15.00145,
            "<D",
            115.67,
            0,
        ],
        1728154560: [
            0.20531,
            0,
            0.20531,
            0,
            58.55974,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            212.6328,
            0,
            0.69942,
            0,
            29.53453,
            0,
            651.5922,
            0,
            0,
            0,
            29.37867,
            0,
            17.55935,
            "<D",
            115.3521,
            0,
        ],
        1728154620: [
            0.2226,
            0,
            0.2226,
            0,
            59.17639,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            201.795,
            0,
            0.67805,
            0,
            29.77887,
            0,
            650.4039,
            0,
            0,
            0,
            29.3787,
            0,
            17.05259,
            "<D",
            116.2628,
            0,
        ],
        1728154680: [
            0.32005,
            0,
            0.32005,
            0,
            59.546,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            184.3724,
            0,
            1.31984,
            0,
            29.81726,
            0,
            649.3568,
            0,
            0,
            0,
            29.37866,
            0,
            16.53749,
            "<D",
            117.9174,
            0,
        ],
        1728154740: [
            0.24275,
            0,
            0.24275,
            0,
            61.0155,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            167.1648,
            0,
            1.08993,
            0,
            29.39701,
            0,
            647.3196,
            0,
            0,
            0,
            29.37869,
            0,
            16.9776,
            "<D",
            117.9569,
            0,
        ],
        1728154800: [
            0.14048,
            0,
            0.14048,
            0,
            62.30742,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            213.0193,
            0,
            1.24244,
            0,
            29.01259,
            0,
            645.1071,
            0,
            0,
            0,
            29.37819,
            0,
            15.59294,
            "<D",
            117.0434,
            0,
        ],
        1728154860: [
            0.29048,
            0,
            0.29048,
            0,
            62.37689,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            231.7624,
            0,
            1.1458,
            0,
            28.99684,
            0,
            644.2583,
            0,
            0,
            0,
            29.37722,
            0,
            15.11944,
            "<D",
            117.9405,
            0,
        ],
        1728154920: [
            0.14083,
            0,
            0.14083,
            0,
            60.89216,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            204.5326,
            0,
            0.61299,
            0,
            28.92903,
            0,
            643.1455,
            0,
            0,
            0,
            29.37567,
            0,
            18.96454,
            "<D",
            116.2908,
            0,
        ],
        1728154980: [
            0.26429,
            0,
            0.26429,
            0,
            61.72238,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            128.9852,
            0,
            0.46129,
            0,
            28.70607,
            0,
            641.6809,
            0,
            0,
            0,
            29.37538,
            0,
            14.99457,
            "<D",
            115.7695,
            0,
        ],
        1728155040: [
            0.22707,
            0,
            0.22707,
            0,
            61.28406,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            17.68488,
            0,
            0.67306,
            0,
            28.80168,
            0,
            640.4103,
            0,
            0,
            0,
            29.37539,
            0,
            16.27332,
            "<D",
            114.2062,
            0,
        ],
        1728155100: [
            0.13811,
            0,
            0.13811,
            0,
            61.66308,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            115.7046,
            0,
            1.41667,
            0,
            29.0613,
            0,
            638.8972,
            0,
            0,
            0,
            29.3754,
            0,
            15.91635,
            "<D",
            114.6706,
            0,
        ],
        1728155160: [
            0.1648,
            0,
            0.1648,
            0,
            61.3151,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            134.6432,
            0,
            1.25402,
            0,
            29.05179,
            0,
            637.4655,
            0,
            0,
            0,
            29.3754,
            0,
            15.66783,
            "<D",
            115.1204,
            0,
        ],
        1728155220: [
            0.16828,
            0,
            0.16828,
            0,
            62.3998,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            133.0099,
            0,
            0.88541,
            0,
            29.25476,
            0,
            635.0642,
            0,
            0,
            0,
            29.37524,
            0,
            17.516,
            "<D",
            114.7783,
            0,
        ],
        1728155280: [
            0.1287,
            0,
            0.1287,
            0,
            61.96946,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            226.4682,
            0,
            0.38079,
            0,
            29.70792,
            0,
            633.8197,
            0,
            0,
            0,
            29.3729,
            0,
            16.42897,
            "<D",
            114.7551,
            0,
        ],
        1728155340: [
            0.13855,
            0,
            0.13855,
            0,
            61.59248,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            138.0924,
            0,
            2.11544,
            0,
            29.75855,
            0,
            632.4736,
            0,
            0,
            0,
            29.37281,
            0,
            17.0913,
            "<D",
            115.915,
            0,
        ],
        1728155400: [
            0.27475,
            0,
            0.27475,
            0,
            61.69464,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            157.2635,
            0,
            1.66778,
            0,
            29.5232,
            0,
            631.5664,
            0,
            0,
            0,
            29.37183,
            0,
            15.42424,
            "<D",
            117.1876,
            0,
        ],
        1728155460: [
            0.17118,
            0,
            0.17118,
            0,
            60.95796,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            144.626,
            0,
            1.55858,
            0,
            29.57182,
            0,
            628.8477,
            0,
            0,
            0,
            29.37114,
            0,
            17.3798,
            "<D",
            116.4898,
            0,
        ],
        1728155520: [
            0.24003,
            0,
            0.24003,
            0,
            61.60411,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8581,
            0,
            170.1122,
            0,
            1.44312,
            0,
            29.56343,
            0,
            627.0378,
            0,
            0,
            0,
            29.37126,
            0,
            17.5513,
            "<D",
            116.0028,
            0,
        ],
        1728155580: [
            0.264,
            0,
            0.264,
            0,
            61.08591,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            140.9414,
            0,
            1.25006,
            0,
            29.40401,
            0,
            625.7022,
            0,
            0,
            0,
            29.37129,
            0,
            16.46284,
            "<D",
            115.8124,
            0,
        ],
        1728155640: [
            0.1919,
            0,
            0.1919,
            0,
            61.37159,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            156.8791,
            0,
            1.03967,
            0,
            29.25165,
            0,
            624.6175,
            0,
            0,
            0,
            29.3713,
            0,
            18.62555,
            "<D",
            118.7267,
            0,
        ],
        1728155700: [
            0.25955,
            0,
            0.25955,
            0,
            62.27322,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            116.3702,
            0,
            0.6213,
            0,
            29.35859,
            0,
            623.4625,
            0,
            0,
            0,
            29.37125,
            0,
            15.27773,
            "<D",
            114.9517,
            0,
        ],
        1728155760: [
            0.13433,
            0,
            0.13433,
            0,
            61.06336,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            70.993,
            0,
            0.47678,
            0,
            29.66057,
            0,
            621.0526,
            0,
            0,
            0,
            29.36927,
            0,
            16.59328,
            "<D",
            114.6614,
            0,
        ],
        1728155820: [
            0.23036,
            0,
            0.23036,
            0,
            60.24488,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            70.95157,
            0,
            1.37459,
            0,
            30.11234,
            0,
            618.8654,
            0,
            0,
            0,
            29.3687,
            0,
            16.06461,
            "<D",
            114.6982,
            0,
        ],
        1728155880: [
            0.06869,
            0,
            0.06869,
            0,
            60.49537,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            100.9863,
            0,
            1.02026,
            0,
            29.7346,
            0,
            617.2306,
            0,
            0,
            0,
            29.36798,
            0,
            16.00001,
            "<D",
            114.3342,
            0,
        ],
        1728155940: [
            0.02299,
            0,
            0.02299,
            0,
            60.49767,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            171.7693,
            0,
            1.14053,
            0,
            29.92334,
            0,
            616.0305,
            0,
            0,
            0,
            29.36775,
            0,
            17.02104,
            "<D",
            114.0499,
            0,
        ],
        1728156000: [
            0.40721,
            0,
            0.40721,
            0,
            59.84658,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            131.2968,
            0,
            2.14719,
            0,
            29.96663,
            0,
            613.7776,
            0,
            0,
            0,
            29.36759,
            0,
            18.31137,
            "<D",
            114.803,
            0,
        ],
        1728156060: [
            0.30455,
            0,
            0.30455,
            0,
            60.27279,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            147.2982,
            0,
            1.49898,
            0,
            29.46664,
            0,
            611.811,
            0,
            0,
            0,
            29.36751,
            0,
            13.43419,
            "<D",
            116.5715,
            0,
        ],
        1728156120: [
            0.24254,
            0,
            0.24254,
            0,
            62.08786,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            136.6057,
            0,
            1.21528,
            0,
            29.57487,
            0,
            609.8458,
            0,
            0,
            0,
            29.36743,
            0,
            17.21695,
            "<D",
            116.3412,
            0,
        ],
        1728156180: [
            0.22732,
            0,
            0.22732,
            0,
            62.45842,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8452,
            0,
            183.034,
            0,
            0.91441,
            0,
            29.33515,
            0,
            607.8754,
            0,
            0,
            0,
            29.36745,
            0,
            15.7349,
            "<D",
            116.1875,
            0,
        ],
        1728156240: [
            0.22291,
            0,
            0.22291,
            0,
            63.01976,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8538,
            0,
            179.5281,
            0,
            1.08659,
            0,
            29.15756,
            0,
            605.7537,
            0,
            0,
            0,
            29.3648,
            0,
            15.56364,
            "<D",
            115.7345,
            0,
        ],
        1728156300: [
            0.20199,
            0,
            0.20199,
            0,
            62.16687,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            172.996,
            0,
            1.1255,
            0,
            29.29376,
            0,
            603.3635,
            0,
            0,
            0,
            29.36432,
            0,
            17.73223,
            "<D",
            114.9313,
            0,
        ],
        1728156360: [
            0.20671,
            0,
            0.20671,
            0,
            61.27174,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            155.6312,
            0,
            1.83216,
            0,
            29.25472,
            0,
            601.5096,
            0,
            0,
            0,
            29.36399,
            0,
            16.85241,
            "<D",
            113.9096,
            0,
        ],
        1728156420: [
            0.25796,
            0,
            0.25796,
            0,
            61.23168,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            171.1888,
            0,
            1.36474,
            0,
            29.07182,
            0,
            600.0229,
            0,
            0,
            0,
            29.364,
            0,
            15.59101,
            "<D",
            114.1018,
            0,
        ],
        1728156480: [
            0.15588,
            0,
            0.15588,
            0,
            61.92332,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            173.6707,
            0,
            0.73146,
            0,
            29.35134,
            0,
            598.1592,
            0,
            0,
            0,
            29.36403,
            0,
            15.26204,
            "<D",
            114.3808,
            0,
        ],
        1728156540: [
            0.31272,
            0,
            0.31272,
            0,
            62.03877,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            143.8833,
            0,
            1.54369,
            0,
            29.41101,
            0,
            596.8755,
            0,
            0,
            0,
            29.36401,
            0,
            15.87332,
            "<D",
            113.9402,
            0,
        ],
        1728156600: [
            0.12716,
            0,
            0.12716,
            0,
            61.64037,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            154.9637,
            0,
            1.35528,
            0,
            29.32974,
            0,
            595.3766,
            0,
            0,
            0,
            29.36319,
            0,
            15.16074,
            "<D",
            114.4211,
            0,
        ],
        1728156660: [
            0.3243,
            0,
            0.3243,
            0,
            61.65467,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            138.1012,
            0,
            2.13645,
            0,
            29.32961,
            0,
            593.3776,
            0,
            0,
            0,
            29.36239,
            0,
            16.24534,
            "<D",
            114.3481,
            0,
        ],
        1728156720: [
            0.34314,
            0,
            0.34314,
            0,
            61.90527,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8022,
            0,
            181.8725,
            0,
            0.83,
            0,
            29.12111,
            0,
            591.0156,
            0,
            0,
            0,
            29.36219,
            0,
            15.06906,
            "<D",
            115.7588,
            0,
        ],
        1728156780: [
            0.40289,
            0,
            0.40289,
            0,
            62.15989,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            105.3768,
            0,
            0.23683,
            0,
            29.32431,
            0,
            588.5803,
            0,
            0,
            0,
            29.3612,
            0,
            15.78618,
            "<D",
            116.344,
            0,
        ],
        1728156840: [
            0.22208,
            0,
            0.22208,
            0,
            61.32064,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            173.2599,
            0,
            1.84102,
            0,
            29.42135,
            0,
            587.2281,
            0,
            0,
            0,
            29.36072,
            0,
            16.22393,
            "<D",
            115.8441,
            0,
        ],
        1728156900: [
            0.11983,
            0,
            0.11983,
            0,
            61.50867,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            143.1332,
            0,
            1.52655,
            0,
            29.41758,
            0,
            586.0118,
            0,
            0,
            0,
            29.36149,
            0,
            15.43563,
            "<D",
            115.7183,
            0,
        ],
        1728156960: [
            0.46276,
            0,
            0.46276,
            0,
            59.99186,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            158.6171,
            0,
            2.51695,
            0,
            29.54713,
            0,
            583.1503,
            0,
            0,
            0,
            29.36091,
            0,
            16.12449,
            "<D",
            113.9908,
            0,
        ],
        1728157020: [
            0.26693,
            0,
            0.26693,
            0,
            61.43802,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            163.3069,
            0,
            2.44214,
            0,
            29.31208,
            0,
            580.9987,
            0,
            0,
            0,
            29.36061,
            0,
            16.95725,
            "<D",
            113.4337,
            0,
        ],
        1728157080: [
            0.14559,
            0,
            0.14559,
            0,
            61.84788,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            169.0135,
            0,
            2.55006,
            0,
            29.10782,
            0,
            579.3077,
            0,
            0,
            0,
            29.36067,
            0,
            15.69116,
            "<D",
            113.8906,
            0,
        ],
        1728157140: [
            0.16888,
            0,
            0.16888,
            0,
            62.45011,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            161.4427,
            0,
            1.64515,
            0,
            29.27818,
            0,
            576.6531,
            0,
            0,
            0,
            29.36057,
            0,
            17.57866,
            "<D",
            114.1002,
            0,
        ],
        1728157200: [
            0.27162,
            0,
            0.27162,
            0,
            62.47262,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            177.1621,
            0,
            1.6617,
            0,
            29.2721,
            0,
            574.3673,
            0,
            0,
            0,
            29.36035,
            0,
            16.74562,
            "<D",
            114.1133,
            0,
        ],
        1728157260: [
            0.26372,
            0,
            0.26372,
            0,
            62.12744,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            164.5975,
            0,
            1.96433,
            0,
            29.13495,
            0,
            571.8079,
            0,
            0,
            0,
            29.36037,
            0,
            15.2697,
            "<D",
            113.8776,
            0,
        ],
        1728157320: [
            0.27005,
            0,
            0.27005,
            0,
            62.62099,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            167.7923,
            0,
            1.34673,
            0,
            29.23928,
            0,
            569.1855,
            0,
            0,
            0,
            29.36048,
            0,
            16.99121,
            "<D",
            113.5317,
            0,
        ],
        1728157380: [
            0.16905,
            0,
            0.16905,
            0,
            62.52842,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7893,
            0,
            191.9857,
            0,
            1.00856,
            0,
            29.1404,
            0,
            567.1428,
            0,
            0,
            0,
            29.36004,
            0,
            15.16294,
            "<D",
            114.2478,
            0,
        ],
        1728157440: [
            0.30113,
            0,
            0.30113,
            0,
            62.82752,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7979,
            0,
            179.8182,
            0,
            0.65025,
            0,
            29.04449,
            0,
            564.7271,
            0,
            0,
            0,
            29.35795,
            0,
            15.93784,
            "<D",
            114.1445,
            0,
        ],
        1728157500: [
            0.37463,
            0,
            0.37463,
            0,
            62.87485,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            188.0049,
            0,
            1.05787,
            0,
            29.13841,
            0,
            562.7313,
            0,
            0,
            0,
            29.35759,
            0,
            17.94199,
            "<D",
            116.9694,
            0,
        ],
        1728157560: [
            0.18286,
            0,
            0.18286,
            0,
            62.24492,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            142.1261,
            0,
            1.61938,
            0,
            29.33554,
            0,
            560.9388,
            0,
            0,
            0,
            29.35801,
            0,
            13.39614,
            "<D",
            115.2072,
            0,
        ],
        1728157620: [
            0.13316,
            0,
            0.13316,
            0,
            60.66348,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            146.5976,
            0,
            1.49,
            0,
            29.55099,
            0,
            559.0093,
            0,
            0,
            0,
            29.35746,
            0,
            15.94522,
            "<D",
            113.209,
            0,
        ],
        1728157680: [
            0.14407,
            0,
            0.14407,
            0,
            61.65283,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            142.631,
            0,
            1.66831,
            0,
            29.24788,
            0,
            557.1453,
            0,
            0,
            0,
            29.3574,
            0,
            14.71987,
            "<D",
            114.2174,
            0,
        ],
        1728157740: [
            0.19891,
            0,
            0.19891,
            0,
            62.57249,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            168.3766,
            0,
            1.13548,
            0,
            29.22712,
            0,
            555.6164,
            0,
            0,
            0,
            29.35737,
            0,
            17.53304,
            "<D",
            113.8319,
            0,
        ],
        1728157800: [
            0.19804,
            0,
            0.19804,
            0,
            63.28723,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            137.395,
            0,
            1.34427,
            0,
            29.27482,
            0,
            553.5946,
            0,
            0,
            0,
            29.35747,
            0,
            15.53788,
            "<D",
            115.0498,
            0,
        ],
        1728157860: [
            0.29541,
            0,
            0.29541,
            0,
            62.35902,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            139.2878,
            0,
            1.04133,
            0,
            29.29149,
            0,
            550.9335,
            0,
            0,
            0,
            29.3571,
            0,
            14.15152,
            "<D",
            114.6336,
            0,
        ],
        1728157920: [
            0.25833,
            0,
            0.25833,
            0,
            62.06797,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            158.6898,
            0,
            1.01293,
            0,
            29.26231,
            0,
            548.1703,
            0,
            0,
            0,
            29.35553,
            0,
            18.17048,
            "<D",
            114.4712,
            0,
        ],
        1728157980: [
            0.10544,
            0,
            0.10544,
            0,
            62.36954,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            3.8959,
            0,
            0.57494,
            0,
            29.50385,
            0,
            545.6844,
            0,
            0,
            0,
            29.35358,
            0,
            16.07312,
            "<D",
            114.2412,
            0,
        ],
        1728158040: [
            0.17035,
            0,
            0.17035,
            0,
            62.12043,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            275.5623,
            0,
            0.44036,
            0,
            29.64632,
            0,
            544.3377,
            0,
            0,
            0,
            29.35385,
            0,
            12.36496,
            "<D",
            115.7798,
            0,
        ],
        1728158100: [
            0.17781,
            0,
            0.17781,
            0,
            61.62871,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            220.7766,
            0,
            0.62224,
            0,
            29.50797,
            0,
            541.8866,
            0,
            0,
            0,
            29.35396,
            0,
            16.88472,
            "<D",
            115.2083,
            0,
        ],
        1728158160: [
            0.32685,
            0,
            0.32685,
            0,
            61.59786,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            195.5073,
            0,
            1.073,
            0,
            29.54155,
            0,
            538.801,
            0,
            0,
            0,
            29.35359,
            0,
            17.62526,
            "<D",
            115.7696,
            0,
        ],
        1728158220: [
            0.24918,
            0,
            0.24918,
            0,
            61.2385,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            157.7053,
            0,
            2.02402,
            0,
            29.58952,
            0,
            537.6321,
            0,
            0,
            0,
            29.35341,
            0,
            17.11371,
            "<D",
            113.7863,
            0,
        ],
        1728158280: [
            0.18273,
            0,
            0.18273,
            0,
            60.85191,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            155.2735,
            0,
            1.57692,
            0,
            29.60434,
            0,
            535.4289,
            0,
            0,
            0,
            29.35345,
            0,
            16.40597,
            "<D",
            113.9607,
            0,
        ],
        1728158340: [
            0.27861,
            0,
            0.27861,
            0,
            60.92453,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            129.3571,
            0,
            1.24172,
            0,
            29.70126,
            0,
            532.7659,
            0,
            0,
            0,
            29.35341,
            0,
            13.73931,
            "<D",
            115.097,
            0,
        ],
        1728158400: [
            0.29579,
            0,
            0.29579,
            0,
            59.58855,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            129.1397,
            0,
            1.88731,
            0,
            29.49079,
            0,
            530.7281,
            0,
            0,
            0,
            29.35343,
            0,
            14.96592,
            "<D",
            115.0926,
            0,
        ],
        1728158460: [
            0.28241,
            0,
            0.28241,
            0,
            61.09394,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            121.9952,
            0,
            2.33038,
            0,
            29.41017,
            0,
            527.6077,
            0,
            0,
            0,
            29.35342,
            0,
            16.18572,
            "<D",
            114.3185,
            0,
        ],
        1728158520: [
            0.09386,
            0,
            0.09386,
            0,
            61.56926,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            109.6696,
            0,
            1.93044,
            0,
            29.42889,
            0,
            525.0554,
            0,
            0,
            0,
            29.35276,
            0,
            16.72152,
            "<D",
            114.1937,
            0,
        ],
        1728158580: [
            0.11298,
            0,
            0.11298,
            0,
            61.13408,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            160.2025,
            0,
            1.84994,
            0,
            29.55087,
            0,
            523.1733,
            0,
            0,
            0,
            29.35223,
            0,
            15.84511,
            "<D",
            115.144,
            0,
        ],
        1728158640: [
            0.15207,
            0,
            0.15207,
            0,
            60.40808,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            129.0297,
            0,
            1.50544,
            0,
            29.61277,
            0,
            520.5963,
            0,
            0,
            0,
            29.35088,
            0,
            16.78347,
            "<D",
            114.8824,
            0,
        ],
        1728158700: [
            0.20324,
            0,
            0.20324,
            0,
            60.64375,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            153.4803,
            0,
            0.69347,
            0,
            29.57327,
            0,
            517.9871,
            0,
            0,
            0,
            29.35018,
            0,
            16.26941,
            "<D",
            114.6907,
            0,
        ],
        1728158760: [
            0.14499,
            0,
            0.14499,
            0,
            61.16405,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            171.8999,
            0,
            1.49,
            0,
            29.62442,
            0,
            515.6481,
            0,
            0,
            0,
            29.35035,
            0,
            16.29635,
            "<D",
            115.4496,
            0,
        ],
        1728158820: [
            0.08586,
            0,
            0.08586,
            0,
            61.40725,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            179.6049,
            0,
            1.34878,
            0,
            29.56562,
            0,
            513.4807,
            0,
            0,
            0,
            29.35061,
            0,
            15.24551,
            "<D",
            115.4897,
            0,
        ],
        1728158880: [
            0.14969,
            0,
            0.14969,
            0,
            60.20963,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            120.2674,
            0,
            0.75131,
            0,
            29.78996,
            0,
            510.8968,
            0,
            0,
            0,
            29.35018,
            0,
            16.34386,
            "<D",
            114.6139,
            0,
        ],
        1728158940: [
            0.22189,
            0,
            0.22189,
            0,
            59.62611,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            164.0347,
            0,
            0.68374,
            0,
            29.88952,
            0,
            508.0119,
            0,
            0,
            0,
            29.35017,
            0,
            17.23205,
            "<D",
            114.9712,
            0,
        ],
        1728159000: [
            0.2209,
            0,
            0.2209,
            0,
            61.15831,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            160.125,
            0,
            1.2372,
            0,
            29.75377,
            0,
            505.4932,
            0,
            0,
            0,
            29.35007,
            0,
            15.9607,
            "<D",
            115.2697,
            0,
        ],
        1728159060: [
            0.07729,
            0,
            0.07729,
            0,
            61.71722,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            157.9508,
            0,
            1.08268,
            0,
            29.59094,
            0,
            503.0625,
            0,
            0,
            0,
            29.35018,
            0,
            15.4483,
            "<D",
            115.2351,
            0,
        ],
        1728159120: [
            0.06862,
            0,
            0.06862,
            0,
            61.44868,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            109.4118,
            0,
            1.02947,
            0,
            30.02069,
            0,
            499.8939,
            0,
            0,
            0,
            29.35017,
            0,
            16.59903,
            "<D",
            115.3357,
            0,
        ],
        1728159180: [
            0.25457,
            0,
            0.25457,
            0,
            60.86848,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            131.5001,
            0,
            1.0305,
            0,
            29.78772,
            0,
            497.7063,
            0,
            0,
            0,
            29.34875,
            0,
            15.81957,
            "<D",
            115.8927,
            0,
        ],
        1728159240: [
            0.16698,
            0,
            0.16698,
            0,
            60.30299,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            152.6482,
            0,
            1.25983,
            0,
            29.96637,
            0,
            495.2278,
            0,
            0,
            0,
            29.34957,
            0,
            15.6671,
            "<D",
            116.8094,
            0,
        ],
        1728159300: [
            0.31973,
            0,
            0.31973,
            0,
            59.12849,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            94.59542,
            0,
            1.23043,
            0,
            29.71468,
            0,
            492.6372,
            0,
            0,
            0,
            29.34921,
            0,
            16.93999,
            "<D",
            115.1725,
            0,
        ],
        1728159360: [
            0.25261,
            0,
            0.25261,
            0,
            61.21395,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            127.3807,
            0,
            1.52295,
            0,
            29.47493,
            0,
            490.8563,
            0,
            0,
            0,
            29.34748,
            0,
            15.89913,
            "<D",
            115.8622,
            0,
        ],
        1728159420: [
            0.23184,
            0,
            0.23184,
            0,
            60.85251,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.785,
            0,
            131.2111,
            0,
            1.89119,
            0,
            29.61805,
            0,
            488.3724,
            0,
            0,
            0,
            29.34948,
            0,
            15.94165,
            "<D",
            115.9364,
            0,
        ],
        1728159480: [
            0.38741,
            0,
            0.38741,
            0,
            62.13795,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            157.8497,
            0,
            1.84,
            0,
            29.40664,
            0,
            485.0924,
            0,
            0,
            0,
            29.34739,
            0,
            15.66937,
            "<D",
            114.9247,
            0,
        ],
        1728159540: [
            0.23476,
            0,
            0.23476,
            0,
            62.44197,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            122.8363,
            0,
            0.93488,
            0,
            29.52569,
            0,
            482.89,
            0,
            0,
            0,
            29.34698,
            0,
            15.48461,
            "<D",
            115.0716,
            0,
        ],
        1728159600: [
            0.16119,
            0,
            0.16119,
            0,
            61.09723,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            92.56908,
            0,
            0.49563,
            0,
            29.71523,
            0,
            480.8092,
            0,
            0,
            0,
            29.34646,
            0,
            16.64507,
            "<D",
            115.6162,
            0,
        ],
        1728159660: [
            0.38929,
            0,
            0.38929,
            0,
            59.35263,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            157.6162,
            0,
            1.25202,
            0,
            29.60579,
            0,
            478.7993,
            0,
            0,
            0,
            29.34635,
            0,
            17.59616,
            "<D",
            115.7944,
            0,
        ],
        1728159720: [
            0.08921,
            0,
            0.08921,
            0,
            59.07507,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            153.5621,
            0,
            1.44116,
            0,
            29.55378,
            0,
            475.567,
            0,
            0,
            0,
            29.34623,
            0,
            15.38385,
            "<D",
            115.4042,
            0,
        ],
        1728159780: [
            0.23635,
            0,
            0.23635,
            0,
            58.45313,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            141.2171,
            0,
            1.63015,
            0,
            29.55328,
            0,
            472.5449,
            0,
            0,
            0,
            29.34619,
            0,
            16.1471,
            "<D",
            115.8129,
            0,
        ],
        1728159840: [
            0.46883,
            0,
            0.46883,
            0,
            60.75053,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            151.8633,
            0,
            1.69376,
            0,
            29.4011,
            0,
            469.4344,
            0,
            0,
            0,
            29.34622,
            0,
            14.68178,
            "<D",
            115.5377,
            0,
        ],
        1728159900: [
            0.28055,
            0,
            0.28055,
            0,
            61.08998,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            147.5808,
            0,
            1.72955,
            0,
            29.46484,
            0,
            467.2656,
            0,
            0,
            0,
            29.34563,
            0,
            16.48941,
            "<D",
            116.3509,
            0,
        ],
        1728159960: [
            0.15307,
            0,
            0.15307,
            0,
            60.85474,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            139.7122,
            0,
            0.89219,
            0,
            29.65769,
            0,
            465.9614,
            0,
            0,
            0,
            29.34452,
            0,
            15.41358,
            "<D",
            114.8421,
            0,
        ],
        1728160020: [
            0.15394,
            0,
            0.15394,
            0,
            60.39524,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            301.0745,
            0,
            0.34046,
            0,
            29.60478,
            0,
            463.1909,
            0,
            0,
            0,
            29.34205,
            0,
            15.80114,
            "<D",
            115.4662,
            0,
        ],
        1728160080: [
            0.0282,
            0,
            0.0282,
            0,
            61.55698,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            22.71731,
            0,
            0.30561,
            0,
            29.55325,
            0,
            460.0822,
            0,
            0,
            0,
            29.34204,
            0,
            17.78761,
            "<D",
            115.354,
            0,
        ],
        1728160140: [
            0.03904,
            0,
            0.03904,
            0,
            61.07657,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            80.22677,
            0,
            0.90597,
            0,
            29.76324,
            0,
            456.1966,
            0,
            0,
            0,
            29.34204,
            0,
            15.16383,
            "<D",
            115.0585,
            0,
        ],
        1728160200: [
            0.4763,
            0,
            0.4763,
            0,
            59.64592,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            14.87078,
            0,
            1.6597,
            0,
            29.74816,
            0,
            452.49,
            0,
            0,
            0,
            29.34172,
            0,
            15.71805,
            "<D",
            115.4426,
            0,
        ],
        1728160260: [
            0.16972,
            0,
            0.16972,
            0,
            59.80144,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            18.26068,
            0,
            1.79277,
            0,
            29.46964,
            0,
            448.8656,
            0,
            0,
            0,
            29.34194,
            0,
            16.92026,
            "<D",
            115.1625,
            0,
        ],
        1728160320: [
            0.22748,
            0,
            0.22748,
            0,
            59.93848,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            27.39419,
            0,
            1.05129,
            0,
            29.58507,
            0,
            446.446,
            0,
            0,
            0,
            29.33988,
            0,
            15.97973,
            "<D",
            114.8319,
            0,
        ],
        1728160380: [
            0.28385,
            0,
            0.28385,
            0,
            57.92755,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            94.75935,
            0,
            0.5138,
            0,
            29.44965,
            0,
            444.4628,
            0,
            0,
            0,
            29.33938,
            0,
            13.06748,
            "<D",
            115.2447,
            0,
        ],
        1728160440: [
            0.3729,
            0,
            0.3729,
            0,
            59.86463,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            19.67118,
            0,
            0.35246,
            0,
            29.57011,
            0,
            443.1326,
            0,
            0,
            0,
            29.33875,
            0,
            17.96446,
            "<D",
            115.673,
            0,
        ],
        1728160500: [
            0.05447,
            0,
            0.05447,
            0,
            60.35522,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            210.8553,
            0,
            0.4624,
            0,
            29.51067,
            0,
            440.9461,
            0,
            0,
            0,
            29.33868,
            0,
            15.66919,
            "<D",
            115.815,
            0,
        ],
        1728160560: [
            0.1875,
            0,
            0.1875,
            0,
            60.74137,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            181.487,
            0,
            0.54603,
            0,
            29.63326,
            0,
            439.1639,
            0,
            0,
            0,
            29.33852,
            0,
            16.57272,
            "<D",
            115.8498,
            0,
        ],
        1728160620: [
            0.28501,
            0,
            0.28501,
            0,
            60.55408,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            180.2941,
            0,
            0.57584,
            0,
            29.70529,
            0,
            437.1447,
            0,
            0,
            0,
            29.33876,
            0,
            16.28682,
            "<D",
            115.0303,
            0,
        ],
        1728160680: [
            0.24019,
            0,
            0.24019,
            0,
            59.73421,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            138.5101,
            0,
            0.98263,
            0,
            29.77701,
            0,
            433.3703,
            0,
            0,
            0,
            29.33872,
            0,
            16.14742,
            "<D",
            115.0709,
            0,
        ],
        1728160740: [
            0.17993,
            0,
            0.17993,
            0,
            58.34687,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            72.49648,
            0,
            0.80145,
            0,
            29.87455,
            0,
            429.6724,
            0,
            0,
            0,
            29.33866,
            0,
            16.61874,
            "<D",
            114.8835,
            0,
        ],
        1728160800: [
            0.11232,
            0,
            0.11232,
            0,
            59.12582,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            116.9372,
            0,
            0.56301,
            0,
            29.82446,
            0,
            426.5205,
            0,
            0,
            0,
            29.33888,
            0,
            16.89124,
            "<D",
            115.3446,
            0,
        ],
        1728160860: [
            0.40383,
            0,
            0.40383,
            0,
            58.31249,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            121.1866,
            0,
            0.20771,
            0,
            29.82271,
            0,
            424.498,
            0,
            0,
            0,
            29.33864,
            0,
            17.09382,
            "<D",
            115.1832,
            0,
        ],
        1728160920: [
            0.09864,
            0,
            0.09864,
            0,
            57.20642,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            181.6827,
            0,
            1.41033,
            0,
            29.81321,
            0,
            424.1267,
            0,
            0,
            0,
            29.3369,
            0,
            16.043,
            "<D",
            115.4437,
            0,
        ],
        1728160980: [
            0.19006,
            0,
            0.19006,
            0,
            59.60464,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            150.7896,
            0,
            1.73962,
            0,
            29.64932,
            0,
            422.3291,
            0,
            0,
            0,
            29.33815,
            0,
            15.46353,
            "<D",
            115.6018,
            0,
        ],
        1728161040: [
            0.37965,
            0,
            0.37965,
            0,
            58.23525,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            132.3311,
            0,
            0.93972,
            0,
            29.5991,
            0,
            419.655,
            0,
            0,
            0,
            29.33838,
            0,
            16.85571,
            "<D",
            116.0789,
            0,
        ],
        1728161100: [
            0.21953,
            0,
            0.21953,
            0,
            57.29223,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            144.6262,
            0,
            1.0453,
            0,
            29.46378,
            0,
            416.4781,
            0,
            0,
            0,
            29.33752,
            0,
            15.24108,
            "<D",
            116.087,
            0,
        ],
        1728161160: [
            0.26752,
            0,
            0.26752,
            0,
            57.28156,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            133.4723,
            0,
            0.98875,
            0,
            29.33905,
            0,
            413.1696,
            0,
            0,
            0,
            29.33814,
            0,
            14.96724,
            "<D",
            116.4357,
            0,
        ],
        1728161220: [
            0.07528,
            0,
            0.07528,
            0,
            56.93472,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            154.9488,
            0,
            1.51161,
            0,
            29.50997,
            0,
            410.9711,
            0,
            0,
            0,
            29.33674,
            0,
            15.34442,
            "<D",
            115.5119,
            0,
        ],
        1728161280: [
            0.30239,
            0,
            0.30239,
            0,
            58.32253,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            124.1551,
            0,
            0.95349,
            0,
            29.67053,
            0,
            408.2876,
            0,
            0,
            0,
            29.33697,
            0,
            17.99626,
            "<D",
            115.1818,
            0,
        ],
        1728161340: [
            0.49143,
            0,
            0.49143,
            0,
            59.77707,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            134.3124,
            0,
            0.6708,
            0,
            29.45507,
            0,
            405.1666,
            0,
            0,
            0,
            29.33518,
            0,
            16.52296,
            "<D",
            116.0608,
            0,
        ],
        1728161400: [
            0.04857,
            0,
            0.04857,
            0,
            59.85837,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            171.3304,
            0,
            1.03178,
            0,
            29.56138,
            0,
            401.746,
            0,
            0,
            0,
            29.33489,
            0,
            16.54124,
            "<D",
            115.2662,
            0,
        ],
        1728161460: [
            0.13272,
            0,
            0.13272,
            0,
            59.11705,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            189.0585,
            0,
            0.52681,
            0,
            29.72644,
            0,
            398.2139,
            0,
            0,
            0,
            29.33499,
            0,
            16.1599,
            "<D",
            115.3386,
            0,
        ],
        1728161520: [
            0.19304,
            0,
            0.19304,
            0,
            59.16353,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            140.729,
            0,
            0.68271,
            0,
            29.63802,
            0,
            394.8445,
            0,
            0,
            0,
            29.3348,
            0,
            18.74101,
            "<D",
            115.7043,
            0,
        ],
        1728161580: [
            0.17386,
            0,
            0.17386,
            0,
            58.67172,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            173.8829,
            0,
            0.83806,
            0,
            29.75107,
            0,
            392.1591,
            0,
            0,
            0,
            29.33498,
            0,
            17.52756,
            "<D",
            114.9975,
            0,
        ],
        1728161640: [
            0.20743,
            0,
            0.20743,
            0,
            58.36098,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            131.3584,
            0,
            1.19823,
            0,
            29.79897,
            0,
            387.9663,
            0,
            0,
            0,
            29.33496,
            0,
            16.48049,
            "<D",
            115.7797,
            0,
        ],
        1728161700: [
            0.24023,
            0,
            0.24023,
            0,
            58.92805,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            130.3563,
            0,
            1.2992,
            0,
            29.44729,
            0,
            383.6472,
            0,
            0,
            0,
            29.33553,
            0,
            17.00661,
            "<D",
            115.9413,
            0,
        ],
        1728161760: [
            0.31831,
            0,
            0.31831,
            0,
            60.14448,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            150.1599,
            0,
            0.81803,
            0,
            29.39205,
            0,
            383.2464,
            0,
            0,
            0,
            29.33285,
            0,
            16.16764,
            "<D",
            116.1313,
            0,
        ],
        1728161820: [
            0.23723,
            0,
            0.23723,
            0,
            58.16193,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            120.7252,
            0,
            1.09232,
            0,
            29.45651,
            0,
            381.2619,
            0,
            0,
            0,
            29.33403,
            0,
            15.35087,
            "<D",
            115.4444,
            0,
        ],
        1728161880: [
            0.08717,
            0,
            0.08717,
            0,
            59.30649,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            158.7863,
            0,
            0.8568,
            0,
            29.48603,
            0,
            379.2488,
            0,
            0,
            0,
            29.33411,
            0,
            15.93176,
            "<D",
            115.333,
            0,
        ],
        1728161940: [
            0.21277,
            0,
            0.21277,
            0,
            59.28548,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            58.19442,
            0,
            0.62312,
            0,
            29.58262,
            0,
            377.1054,
            0,
            0,
            0,
            29.33387,
            0,
            16.54992,
            "<D",
            115.3786,
            0,
        ],
        1728162000: [
            0.29274,
            0,
            0.29274,
            0,
            58.28331,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            122.0577,
            0,
            0.41513,
            0,
            29.37491,
            0,
            374.2661,
            0,
            0,
            0,
            29.33268,
            0,
            14.7938,
            "<D",
            114.7685,
            0,
        ],
        1728162060: [
            0.24096,
            0,
            0.24096,
            0,
            58.48672,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            177.5509,
            0,
            0.84021,
            0,
            29.50259,
            0,
            370.6749,
            0,
            0,
            0,
            29.33369,
            0,
            16.67125,
            "<D",
            115.2737,
            0,
        ],
        1728162120: [
            0.24713,
            0,
            0.24713,
            0,
            58.13427,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            135.5634,
            0,
            0.88037,
            0,
            29.54006,
            0,
            367.4336,
            0,
            0,
            0,
            29.33343,
            0,
            16.93121,
            "<D",
            115.2919,
            0,
        ],
        1728162180: [
            0.19345,
            0,
            0.19345,
            0,
            58.36622,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            127.149,
            0,
            1.94205,
            0,
            29.49302,
            0,
            363.8669,
            0,
            0,
            0,
            29.33313,
            0,
            16.00905,
            "<D",
            115.6705,
            0,
        ],
        1728162240: [
            -0.01935,
            0,
            -0.01935,
            0,
            58.93879,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            148.4942,
            0,
            1.11855,
            0,
            29.3658,
            0,
            361.7854,
            0,
            0,
            0,
            29.33206,
            0,
            16.61032,
            "<D",
            115.8302,
            0,
        ],
        1728162300: [
            0.20576,
            0,
            0.20576,
            0,
            59.43276,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            143.2485,
            0,
            1.35142,
            0,
            29.47504,
            0,
            359.0812,
            0,
            0,
            0,
            29.33199,
            0,
            17.134,
            "<D",
            115.6148,
            0,
        ],
        1728162360: [
            0.25816,
            0,
            0.25816,
            0,
            58.67089,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            143.051,
            0,
            1.50218,
            0,
            29.34836,
            0,
            356.8605,
            0,
            0,
            0,
            29.3321,
            0,
            13.66214,
            "<D",
            115.9428,
            0,
        ],
        1728162420: [
            0.15714,
            0,
            0.15714,
            0,
            59.20704,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            132.1393,
            0,
            1.6177,
            0,
            29.35345,
            0,
            354.0783,
            0,
            0,
            0,
            29.33198,
            0,
            13.26629,
            "<D",
            115.7515,
            0,
        ],
        1728162480: [
            0.20754,
            0,
            0.20754,
            0,
            59.83027,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            87.20862,
            0,
            1.44823,
            0,
            29.41417,
            0,
            351.5732,
            0,
            0,
            0,
            29.33177,
            0,
            17.10622,
            "<D",
            114.5998,
            0,
        ],
        1728162540: [
            0.18579,
            0,
            0.18579,
            0,
            60.08302,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            83.00587,
            0,
            1.13656,
            0,
            29.24415,
            0,
            348.9295,
            0,
            0,
            0,
            29.33184,
            0,
            17.10883,
            "<D",
            115.0354,
            0,
        ],
        1728162600: [
            0.17917,
            0,
            0.17917,
            0,
            58.86285,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            168.271,
            0,
            1.13425,
            0,
            29.30371,
            0,
            345.3663,
            0,
            0,
            0,
            29.33218,
            0,
            17.0401,
            "<D",
            115.1464,
            0,
        ],
        1728162660: [
            0.30697,
            0,
            0.30697,
            0,
            58.52211,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            171.55,
            0,
            1.21091,
            0,
            29.25802,
            0,
            342.0007,
            0,
            0,
            0,
            29.33224,
            0,
            13.17053,
            "<D",
            115.5771,
            0,
        ],
        1728162720: [
            0.17656,
            0,
            0.17656,
            0,
            60.12624,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            177.8175,
            0,
            1.22074,
            0,
            29.27237,
            0,
            339.0475,
            0,
            0,
            0,
            29.33207,
            0,
            16.89917,
            "<D",
            116.4797,
            0,
        ],
        1728162780: [
            0.10851,
            0,
            0.10851,
            0,
            59.96546,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            156.705,
            0,
            1.03436,
            0,
            29.24449,
            0,
            335.4783,
            0,
            0,
            0,
            29.33201,
            0,
            12.40702,
            "<D",
            116.069,
            0,
        ],
        1728162840: [
            0.23737,
            0,
            0.23737,
            0,
            59.75753,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            177.9417,
            0,
            1.2433,
            0,
            29.47907,
            0,
            331.9659,
            0,
            0,
            0,
            29.33132,
            0,
            18.01029,
            "<D",
            115.8661,
            0,
        ],
        1728162900: [
            0.21124,
            0,
            0.21124,
            0,
            60.84063,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            177.0159,
            0,
            1.32004,
            0,
            29.5942,
            0,
            328.9752,
            0,
            0,
            0,
            29.33131,
            0,
            16.27535,
            "<D",
            116.5308,
            0,
        ],
        1728162960: [
            0.26629,
            0,
            0.26629,
            0,
            60.47164,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            169.4185,
            0,
            1.0995,
            0,
            29.31767,
            0,
            326.5674,
            0,
            0,
            0,
            29.33153,
            0,
            16.1755,
            "<D",
            115.6141,
            0,
        ],
        1728163020: [
            0.19512,
            0,
            0.19512,
            0,
            58.22889,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            172.2734,
            0,
            1.35963,
            0,
            29.30259,
            0,
            324.1573,
            0,
            0,
            0,
            29.33145,
            0,
            17.05639,
            "<D",
            115.463,
            0,
        ],
        1728163080: [
            0.28511,
            0,
            0.28511,
            0,
            58.24205,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            136.8034,
            0,
            1.62221,
            0,
            29.26868,
            0,
            321.2736,
            0,
            0,
            0,
            29.33045,
            0,
            15.54198,
            "<D",
            115.3067,
            0,
        ],
        1728163140: [
            0.13421,
            0,
            0.13421,
            0,
            59.59087,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            146.2976,
            0,
            0.80782,
            0,
            29.42079,
            0,
            318.4835,
            0,
            0,
            0,
            29.32893,
            0,
            15.73844,
            "<D",
            115.0229,
            0,
        ],
        1728163200: [
            0.16865,
            0,
            0.16865,
            0,
            58.56827,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            177.931,
            0,
            2.01837,
            0,
            29.38353,
            0,
            315.2719,
            0,
            0,
            0,
            29.32821,
            0,
            16.70705,
            "<D",
            114.8338,
            0,
        ],
        1728163260: [
            0.16861,
            0,
            0.16861,
            0,
            60.06177,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            158.306,
            0,
            2.16161,
            0,
            29.29964,
            0,
            311.8951,
            0,
            0,
            0,
            29.32821,
            0,
            15.24775,
            "<D",
            115.1553,
            0,
        ],
        1728163320: [
            0.28988,
            0,
            0.28988,
            0,
            61.20377,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            160.7989,
            0,
            1.20571,
            0,
            29.16495,
            0,
            308.1763,
            0,
            0,
            0,
            29.32824,
            0,
            15.47277,
            "<D",
            115.6625,
            0,
        ],
        1728163380: [
            0.30463,
            0,
            0.30463,
            0,
            61.57913,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            149.9577,
            0,
            0.99918,
            0,
            29.1347,
            0,
            304.7532,
            0,
            0,
            0,
            29.32827,
            0,
            17.14477,
            "<D",
            114.5968,
            0,
        ],
        1728163440: [
            0.22775,
            0,
            0.22775,
            0,
            61.65514,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            182.1153,
            0,
            0.71953,
            0,
            29.23659,
            0,
            301.3014,
            0,
            0,
            0,
            29.32801,
            0,
            15.29174,
            "<D",
            114.6068,
            0,
        ],
        1728163500: [
            0.12573,
            0,
            0.12573,
            0,
            60.28577,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            174.1305,
            0,
            0.6359,
            0,
            29.34843,
            0,
            298.3337,
            0,
            0,
            0,
            29.32646,
            0,
            15.43774,
            "<D",
            115.2377,
            0,
        ],
        1728163560: [
            0.29498,
            0,
            0.29498,
            0,
            59.15118,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            113.7381,
            0,
            1.12262,
            0,
            29.20122,
            0,
            295.6859,
            0,
            0,
            0,
            29.32768,
            0,
            16.37173,
            "<D",
            115.5903,
            0,
        ],
        1728163620: [
            0.22192,
            0,
            0.22192,
            0,
            57.38106,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            110.7717,
            0,
            1.04007,
            0,
            29.1591,
            0,
            292.9154,
            0,
            0,
            0,
            29.3274,
            0,
            14.35208,
            "<D",
            115.7255,
            0,
        ],
        1728163680: [
            0.18418,
            0,
            0.18418,
            0,
            57.79144,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            155.8852,
            0,
            1.12744,
            0,
            29.04884,
            0,
            290.4933,
            0,
            0,
            0,
            29.32785,
            0,
            16.3323,
            "<D",
            115.4644,
            0,
        ],
        1728163740: [
            0.18461,
            0,
            0.18461,
            0,
            58.47548,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            141.9389,
            0,
            1.39494,
            0,
            29.04624,
            0,
            287.6376,
            0,
            0,
            0,
            29.32683,
            0,
            15.68111,
            "<D",
            115.4865,
            0,
        ],
        1728163800: [
            0.28223,
            0,
            0.28223,
            0,
            59.34816,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            159.8233,
            0,
            1.90165,
            0,
            28.99896,
            0,
            284.8962,
            0,
            0,
            0,
            29.32714,
            0,
            15.45444,
            "<D",
            115.1384,
            0,
        ],
        1728163860: [
            0.26643,
            0,
            0.26643,
            0,
            58.58411,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            159.8558,
            0,
            1.56903,
            0,
            28.97566,
            0,
            281.5252,
            0,
            0,
            0,
            29.32736,
            0,
            14.82938,
            "<D",
            115.195,
            0,
        ],
        1728163920: [
            0.31442,
            0,
            0.31442,
            0,
            60.37826,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            158.3871,
            0,
            1.25889,
            0,
            29.10854,
            0,
            278.0637,
            0,
            0,
            0,
            29.3258,
            0,
            15.48007,
            "<D",
            115.406,
            0,
        ],
        1728163980: [
            0.16139,
            0,
            0.16139,
            0,
            59.07755,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            165.6325,
            0,
            1.40979,
            0,
            29.28441,
            0,
            274.7078,
            0,
            0,
            0,
            29.32492,
            0,
            14.3207,
            "<D",
            114.6101,
            0,
        ],
        1728164040: [
            0.24119,
            0,
            0.24119,
            0,
            59.11788,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            141.8814,
            0,
            0.66303,
            0,
            29.28311,
            0,
            271.2173,
            0,
            0,
            0,
            29.32429,
            0,
            18.57121,
            "<D",
            115.1368,
            0,
        ],
        1728164100: [
            0.17242,
            0,
            0.17242,
            0,
            58.96479,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            166.6252,
            0,
            0.7523,
            0,
            29.40512,
            0,
            267.8305,
            0,
            0,
            0,
            29.32428,
            0,
            15.44155,
            "<D",
            115.296,
            0,
        ],
        1728164160: [
            0.37922,
            0,
            0.37922,
            0,
            58.02575,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            130.2018,
            0,
            0.92565,
            0,
            29.19699,
            0,
            264.313,
            0,
            0,
            0,
            29.32428,
            0,
            16.86073,
            "<D",
            114.7921,
            0,
        ],
        1728164220: [
            0.25011,
            0,
            0.25011,
            0,
            57.085,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            130.6139,
            0,
            1.1119,
            0,
            29.16177,
            0,
            260.5075,
            0,
            0,
            0,
            29.3243,
            0,
            17.56629,
            "<D",
            115.3238,
            0,
        ],
        1728164280: [
            0.07444,
            0,
            0.07444,
            0,
            59.40919,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            144.0079,
            0,
            0.77983,
            0,
            29.11342,
            0,
            256.9775,
            0,
            0,
            0,
            29.3243,
            0,
            14.67352,
            "<D",
            115.9269,
            0,
        ],
        1728164340: [
            0.03954,
            0,
            0.03954,
            0,
            58.62609,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            126.7937,
            0,
            1.08697,
            0,
            29.00587,
            0,
            254.2513,
            0,
            0,
            0,
            29.32428,
            0,
            18.13972,
            "<D",
            114.9875,
            0,
        ],
        1728164400: [
            0.0838,
            0,
            0.0838,
            0,
            59.88775,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            176.3636,
            0,
            1.32731,
            0,
            29.08339,
            0,
            251.5775,
            0,
            0,
            0,
            29.32428,
            0,
            14.06868,
            "<D",
            115.549,
            0,
        ],
        1728164460: [
            0.19652,
            0,
            0.19652,
            0,
            61.50095,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            178.1578,
            0,
            1.09705,
            0,
            28.93533,
            0,
            248.5715,
            0,
            0,
            0,
            29.32417,
            0,
            17.80083,
            "<D",
            114.905,
            0,
        ],
        1728164520: [
            0.26178,
            0,
            0.26178,
            0,
            61.37874,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            175.0505,
            0,
            0.97539,
            0,
            28.45442,
            0,
            245.3659,
            0,
            0,
            0,
            29.32431,
            0,
            14.91242,
            "<D",
            116.3892,
            0,
        ],
        1728164580: [
            0.2101,
            0,
            0.2101,
            0,
            59.81799,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            176.1848,
            0,
            0.78536,
            0,
            28.75666,
            0,
            242.3396,
            0,
            0,
            0,
            29.32442,
            0,
            16.06527,
            "<D",
            116.4145,
            0,
        ],
        1728164640: [
            0.187,
            0,
            0.187,
            0,
            60.24568,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            150.2085,
            0,
            0.70245,
            0,
            28.88872,
            0,
            240.1476,
            0,
            0,
            0,
            29.32435,
            0,
            15.90891,
            "<D",
            116.6155,
            0,
        ],
        1728164700: [
            0.31412,
            0,
            0.31412,
            0,
            59.73433,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            152.6774,
            0,
            1.04828,
            0,
            28.83513,
            0,
            237.6683,
            0,
            0,
            0,
            29.32451,
            0,
            15.87311,
            "<D",
            116.1105,
            0,
        ],
        1728164760: [
            0.1769,
            0,
            0.1769,
            0,
            60.25719,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            118.0273,
            0,
            1.25487,
            0,
            28.89652,
            0,
            234.5587,
            0,
            0,
            0,
            29.32496,
            0,
            15.27919,
            "<D",
            116.6771,
            0,
        ],
        1728164820: [
            0.30778,
            0,
            0.30778,
            0,
            60.41592,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            105.7247,
            0,
            0.73034,
            0,
            28.85314,
            0,
            231.1348,
            0,
            0,
            0,
            29.32495,
            0,
            14.6121,
            "<D",
            116.652,
            0,
        ],
        1728164880: [
            0.2318,
            0,
            0.2318,
            0,
            59.69813,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            100.1938,
            0,
            0.64621,
            0,
            28.76498,
            0,
            227.3703,
            0,
            0,
            0,
            29.32494,
            0,
            15.21383,
            "<D",
            116.3329,
            0,
        ],
        1728164940: [
            0.10181,
            0,
            0.10181,
            0,
            58.94387,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            132.9277,
            0,
            0.82171,
            0,
            28.77889,
            0,
            224.0053,
            0,
            0,
            0,
            29.3248,
            0,
            14.98483,
            "<D",
            115.4547,
            0,
        ],
        1728165000: [
            -0.00239,
            0,
            -0.00239,
            0,
            59.33651,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            157.0841,
            0,
            0.66792,
            0,
            29.00042,
            0,
            220.836,
            0,
            0,
            0,
            29.32465,
            0,
            16.74402,
            "<D",
            115.4966,
            0,
        ],
        1728165060: [
            0.23604,
            0,
            0.23604,
            0,
            59.96385,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            57.46452,
            0,
            0.32021,
            0,
            28.85074,
            0,
            217.9398,
            0,
            0,
            0,
            29.32486,
            0,
            15.82406,
            "<D",
            116.0926,
            0,
        ],
        1728165120: [
            0.12191,
            0,
            0.12191,
            0,
            58.47971,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6732,
            0,
            128.6537,
            0,
            0.23229,
            0,
            28.91257,
            0,
            214.9708,
            0,
            0,
            0,
            29.32495,
            0,
            16.1118,
            "<D",
            115.8881,
            0,
        ],
        1728165180: [
            0.16776,
            0,
            0.16776,
            0,
            58.77621,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            123.2359,
            0,
            1.2597,
            0,
            28.96158,
            0,
            211.9288,
            0,
            0,
            0,
            29.32435,
            0,
            16.04463,
            "<D",
            116.4719,
            0,
        ],
        1728165240: [
            0.32062,
            0,
            0.32062,
            0,
            60.11276,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            128.1279,
            0,
            0.82964,
            0,
            28.94509,
            0,
            208.9294,
            0,
            0,
            0,
            29.32419,
            0,
            17.55744,
            "<D",
            116.283,
            0,
        ],
        1728165300: [
            0.02676,
            0,
            0.02676,
            0,
            59.11188,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            121.8908,
            0,
            0.78421,
            0,
            28.79839,
            0,
            205.6745,
            0,
            0,
            0,
            29.3235,
            0,
            15.11154,
            "<D",
            117.198,
            0,
        ],
        1728165360: [
            0.23298,
            0,
            0.23298,
            0,
            56.8141,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            117.0018,
            0,
            0.81713,
            0,
            28.67797,
            0,
            196.2989,
            0,
            0,
            0,
            29.32283,
            0,
            15.4583,
            "<D",
            116.388,
            0,
        ],
        1728165420: [
            0.37093,
            0,
            0.37093,
            0,
            59.30503,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            115.8903,
            0,
            0.70546,
            0,
            28.48003,
            0,
            188.7936,
            0,
            0,
            0,
            29.32402,
            0,
            16.45939,
            "<D",
            115.5184,
            0,
        ],
        1728165480: [
            0.28149,
            0,
            0.28149,
            0,
            57.04722,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            84.4617,
            0,
            0.41392,
            0,
            28.33547,
            0,
            194.1975,
            0,
            0,
            0,
            29.32368,
            0,
            15.76913,
            "<D",
            116.8674,
            0,
        ],
        1728165540: [
            0.2154,
            0,
            0.2154,
            0,
            57.53299,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            163.568,
            0,
            0.43041,
            0,
            28.52017,
            0,
            192.5465,
            0,
            0,
            0,
            29.32308,
            0,
            16.68287,
            "<D",
            116.0612,
            0,
        ],
        1728165600: [
            0.21827,
            0,
            0.21827,
            0,
            57.10672,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            123.0863,
            0,
            0.67062,
            0,
            28.64083,
            0,
            189.2366,
            0,
            0,
            0,
            29.3226,
            0,
            17.08093,
            "<D",
            115.7522,
            0,
        ],
        1728165660: [
            -0.02941,
            0,
            -0.02941,
            0,
            57.98876,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            62.75161,
            0,
            0.54136,
            0,
            28.25492,
            0,
            186.0429,
            0,
            0,
            0,
            29.32184,
            0,
            15.22183,
            "<D",
            116.0227,
            0,
        ],
        1728165720: [
            0.12322,
            0,
            0.12322,
            0,
            57.60918,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            98.60143,
            0,
            0.496,
            0,
            28.19198,
            0,
            182.7388,
            0,
            0,
            0,
            29.32243,
            0,
            15.03018,
            "<D",
            116.3027,
            0,
        ],
        1728165780: [
            0.06586,
            0,
            0.06586,
            0,
            56.96175,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            113.7614,
            0,
            0.78669,
            0,
            28.21366,
            0,
            179.3152,
            0,
            0,
            0,
            29.32228,
            0,
            17.57078,
            "<D",
            115.7948,
            0,
        ],
        1728165840: [
            0.1596,
            0,
            0.1596,
            0,
            57.8286,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            129.6974,
            0,
            0.70589,
            0,
            28.01139,
            0,
            176.1491,
            0,
            0,
            0,
            29.3221,
            0,
            14.90016,
            "<D",
            116.1591,
            0,
        ],
        1728165900: [
            0.50629,
            0,
            0.50629,
            0,
            58.18649,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            98.84905,
            0,
            0.66294,
            0,
            27.87564,
            0,
            171.7552,
            0,
            0,
            0,
            29.32195,
            0,
            15.47497,
            "<D",
            116.7009,
            0,
        ],
        1728165960: [
            0.35812,
            0,
            0.35812,
            0,
            58.29131,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            140.5197,
            0,
            0.45757,
            0,
            27.9903,
            0,
            138.3427,
            0,
            0,
            0,
            29.32183,
            0,
            15.68714,
            "<D",
            116.8058,
            0,
        ],
        1728166020: [
            0.31868,
            0,
            0.31868,
            0,
            57.05075,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            141.704,
            0,
            0.35479,
            0,
            27.93562,
            0,
            90.54466,
            0,
            0,
            0,
            29.32227,
            0,
            16.68953,
            "<D",
            116.0974,
            0,
        ],
        1728166080: [
            0.26009,
            0,
            0.26009,
            0,
            55.79485,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            106.426,
            0,
            0.32234,
            0,
            28.35228,
            0,
            66.33913,
            0,
            0,
            0,
            29.3219,
            0,
            14.7863,
            "<D",
            116.2608,
            0,
        ],
        1728166140: [
            0.03912,
            0,
            0.03912,
            0,
            56.55705,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            88.34288,
            0,
            0.31851,
            0,
            28.20853,
            0,
            55.00849,
            0,
            0,
            0,
            29.32154,
            0,
            16.04408,
            "<D",
            115.8688,
            0,
        ],
        1728166200: [
            0.18642,
            0,
            0.18642,
            0,
            56.26226,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            131.7799,
            0,
            0.60409,
            0,
            27.95888,
            0,
            49.08649,
            0,
            0,
            0,
            29.32161,
            0,
            16.33632,
            "<D",
            116.3703,
            0,
        ],
        1728166260: [
            0.16346,
            0,
            0.16346,
            0,
            57.02077,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            92.75867,
            0,
            0.5459,
            0,
            27.79777,
            0,
            48.02399,
            0,
            0,
            0,
            29.32126,
            0,
            16.06542,
            "<D",
            116.7971,
            0,
        ],
        1728166320: [
            0.14029,
            0,
            0.14029,
            0,
            54.88351,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            112.6056,
            0,
            0.47259,
            0,
            27.79722,
            0,
            47.9838,
            0,
            0,
            0,
            29.32113,
            0,
            15.47548,
            "<D",
            116.6114,
            0,
        ],
        1728166380: [
            0.17722,
            0,
            0.17722,
            0,
            55.06839,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            139.9052,
            0,
            0.60687,
            0,
            27.68041,
            0,
            49.52066,
            0,
            0,
            0,
            29.32137,
            0,
            15.82974,
            "<D",
            116.2202,
            0,
        ],
        1728166440: [
            0.2057,
            0,
            0.2057,
            0,
            54.97255,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            118.3322,
            0,
            0.73402,
            0,
            27.6116,
            0,
            56.92199,
            0,
            0,
            0,
            29.32104,
            0,
            16.80674,
            "<D",
            115.9745,
            0,
        ],
        1728166500: [
            0.30355,
            0,
            0.30355,
            0,
            54.94978,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            114.784,
            0,
            0.65698,
            0,
            27.51389,
            0,
            65.25677,
            0,
            0,
            0,
            29.32126,
            0,
            16.53608,
            "<D",
            116.7786,
            0,
        ],
        1728166560: [
            0.23676,
            0,
            0.23676,
            0,
            55.41724,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            74.80433,
            0,
            0.48842,
            0,
            27.519,
            0,
            58.63515,
            0,
            0,
            0,
            29.32135,
            0,
            17.52877,
            "<D",
            116.7962,
            0,
        ],
        1728166620: [
            0.0587,
            0,
            0.0587,
            0,
            54.29,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            120.4473,
            0,
            0.75027,
            0,
            27.43547,
            0,
            43.64501,
            0,
            0,
            0,
            29.32103,
            0,
            15.77047,
            "<D",
            117.2187,
            0,
        ],
        1728166680: [
            0.19391,
            0,
            0.19391,
            0,
            54.64547,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            93.33292,
            0,
            0.54992,
            0,
            27.36789,
            0,
            40.39208,
            0,
            0,
            0,
            29.32106,
            0,
            16.26257,
            "<D",
            117.0438,
            0,
        ],
        1728166740: [
            0.27514,
            0,
            0.27514,
            0,
            55.25052,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            59.90416,
            0,
            0.34694,
            0,
            27.39729,
            0,
            40.03759,
            0,
            0,
            0,
            29.3212,
            0,
            16.10678,
            "<D",
            116.5493,
            0,
        ],
        1728166800: [
            0.19143,
            0,
            0.19143,
            0,
            53.859,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            59.80725,
            0,
            0.22291,
            0,
            27.30654,
            0,
            41.73393,
            0,
            0,
            0,
            29.32106,
            0,
            14.50079,
            "<D",
            116.6097,
            0,
        ],
        1728166860: [
            0.19193,
            0,
            0.19193,
            0,
            53.44769,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            67.57327,
            0,
            0.22373,
            0,
            27.19385,
            0,
            53.89052,
            0,
            0,
            0,
            29.32105,
            0,
            17.55311,
            "<D",
            116.9303,
            0,
        ],
        1728166920: [
            0.3079,
            0,
            0.3079,
            0,
            53.33546,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            71.50992,
            0,
            0.22385,
            0,
            27.31488,
            0,
            60.38645,
            0,
            0,
            0,
            29.32103,
            0,
            15.61534,
            "<D",
            117.0168,
            0,
        ],
        1728166980: [
            0.22489,
            0,
            0.22489,
            0,
            53.32704,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            76.31918,
            0,
            0.22371,
            0,
            27.2409,
            0,
            48.18769,
            0,
            0,
            0,
            29.32106,
            0,
            15.32948,
            "<D",
            117.1456,
            0,
        ],
        1728167040: [
            0.23161,
            0,
            0.23161,
            0,
            51.32172,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            109.1508,
            0,
            0.23725,
            0,
            27.16659,
            0,
            44.75696,
            0,
            0,
            0,
            29.32103,
            0,
            16.35715,
            "<D",
            116.6277,
            0,
        ],
        1728167100: [
            0.1777,
            0,
            0.1777,
            0,
            52.58101,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            112.1634,
            0,
            0.43707,
            0,
            27.11361,
            0,
            46.31366,
            0,
            0,
            0,
            29.32096,
            0,
            17.30222,
            "<D",
            117.1215,
            0,
        ],
        1728167160: [
            0.22967,
            0,
            0.22967,
            0,
            52.64713,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            120.7008,
            0,
            0.34995,
            0,
            27.14071,
            0,
            40.42881,
            0,
            0,
            0,
            29.32103,
            0,
            17.48114,
            "<D",
            116.917,
            0,
        ],
        1728167220: [
            0.22003,
            0,
            0.22003,
            0,
            53.25554,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            119.825,
            0,
            0.3461,
            0,
            27.11509,
            0,
            37.67585,
            0,
            0,
            0,
            29.32104,
            0,
            18.74339,
            "<D",
            116.9847,
            0,
        ],
        1728167280: [
            0.34175,
            0,
            0.34175,
            0,
            52.22107,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            134.3453,
            0,
            0.41412,
            0,
            27.11923,
            0,
            36.4317,
            0,
            0,
            0,
            29.32103,
            0,
            15.74087,
            "<D",
            116.9102,
            0,
        ],
        1728167340: [
            0.22694,
            0,
            0.22694,
            0,
            52.86067,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            119.1421,
            0,
            0.56441,
            0,
            27.09392,
            0,
            36.95507,
            0,
            0,
            0,
            29.32113,
            0,
            15.27813,
            "<D",
            116.7998,
            0,
        ],
        1728167400: [
            -0.00987,
            0,
            -0.00987,
            0,
            53.82365,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            114.6061,
            0,
            0.53365,
            0,
            26.95023,
            0,
            38.88006,
            0,
            0,
            0,
            29.32122,
            0,
            19.67205,
            "<D",
            116.3753,
            0,
        ],
        1728167460: [
            0.21814,
            0,
            0.21814,
            0,
            51.74545,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            123.9146,
            0,
            0.46636,
            0,
            26.88292,
            0,
            39.06675,
            0,
            0,
            0,
            29.32099,
            0,
            15.85527,
            "<D",
            116.8993,
            0,
        ],
        1728167520: [
            0.0202,
            0,
            0.0202,
            0,
            53.9702,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            99.51215,
            0,
            0.54309,
            0,
            26.78646,
            0,
            40.15795,
            0,
            0,
            0,
            29.32088,
            0,
            16.01228,
            "<D",
            117.2548,
            0,
        ],
        1728167580: [
            0.15442,
            0,
            0.15442,
            0,
            52.23786,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            113.9811,
            0,
            0.59956,
            0,
            26.74655,
            0,
            41.34585,
            0,
            0,
            0,
            29.32095,
            0,
            16.42584,
            "<D",
            117.251,
            0,
        ],
        1728167640: [
            0.17241,
            0,
            0.17241,
            0,
            52.29198,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            112.5436,
            0,
            0.44587,
            0,
            26.73298,
            0,
            37.52915,
            0,
            0,
            0,
            29.32087,
            0,
            14.45991,
            "<D",
            117.8107,
            0,
        ],
        1728167700: [
            0.18921,
            0,
            0.18921,
            0,
            51.71835,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            115.4473,
            0,
            0.41006,
            0,
            26.71165,
            0,
            34.18814,
            0,
            0,
            0,
            29.3211,
            0,
            17.20872,
            "<D",
            117.148,
            0,
        ],
        1728167760: [
            0.20283,
            0,
            0.20283,
            0,
            52.22829,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            119.3632,
            0,
            0.31574,
            0,
            26.70009,
            0,
            33.75466,
            0,
            0,
            0,
            29.32087,
            0,
            16.12507,
            "<D",
            118.2568,
            0,
        ],
        1728167820: [
            0.25766,
            0,
            0.25766,
            0,
            52.72391,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            127.1663,
            0,
            0.23178,
            0,
            26.67059,
            0,
            36.33467,
            0,
            0,
            0,
            29.32048,
            0,
            15.90882,
            "<D",
            118.1652,
            0,
        ],
        1728167880: [
            0.17929,
            0,
            0.17929,
            0,
            52.52372,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            99.1024,
            0,
            0.32742,
            0,
            26.6503,
            0,
            39.71017,
            0,
            0,
            0,
            29.32104,
            0,
            16.49356,
            "<D",
            118.4511,
            0,
        ],
        1728167940: [
            0.28612,
            0,
            0.28612,
            0,
            52.77169,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            116.452,
            0,
            0.37429,
            0,
            26.59935,
            0,
            36.02168,
            0,
            0,
            0,
            29.32147,
            0,
            17.36199,
            "<D",
            118.7024,
            0,
        ],
        1728168000: [
            0.36344,
            0,
            0.36344,
            0,
            53.09311,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            119.8797,
            0,
            0.21543,
            0,
            26.50057,
            0,
            31.88225,
            0,
            0,
            0,
            29.32103,
            0,
            18.19159,
            "<D",
            117.7041,
            0,
        ],
        1728168060: [
            0.39301,
            0,
            0.39301,
            0,
            52.96445,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            139.2644,
            0,
            0.31171,
            0,
            26.40765,
            0,
            30.68595,
            0,
            0,
            0,
            29.32044,
            0,
            14.17648,
            "<D",
            117.5117,
            0,
        ],
        1728168120: [
            0.21974,
            0,
            0.21974,
            0,
            52.83812,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            113.8591,
            0,
            0.39786,
            0,
            26.35296,
            0,
            30.33969,
            0,
            0,
            0,
            29.31909,
            0,
            16.58764,
            "<D",
            117.7669,
            0,
        ],
        1728168180: [
            0.18592,
            0,
            0.18592,
            0,
            53.01457,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            135.1988,
            0,
            0.40097,
            0,
            26.37409,
            0,
            29.77376,
            0,
            0,
            0,
            29.31831,
            0,
            18.77305,
            "<D",
            117.328,
            0,
        ],
        1728168240: [
            0.24724,
            0,
            0.24724,
            0,
            52.87646,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            143.0781,
            0,
            0.49257,
            0,
            26.32432,
            0,
            28.95442,
            0,
            0,
            0,
            29.3177,
            0,
            15.81295,
            "<D",
            117.4427,
            0,
        ],
        1728168300: [
            0.12058,
            0,
            0.12058,
            0,
            53.28695,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            124.8047,
            0,
            0.27129,
            0,
            26.24685,
            0,
            28.33501,
            0,
            0,
            0,
            29.31812,
            0,
            15.06006,
            "<D",
            117.1099,
            0,
        ],
        1728168360: [
            0.06819,
            0,
            0.06819,
            0,
            52.71764,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            158.3699,
            0,
            0.44589,
            0,
            26.22886,
            0,
            27.81848,
            0,
            0,
            0,
            29.31785,
            0,
            16.87062,
            "<D",
            116.6521,
            0,
        ],
        1728168420: [
            0.16726,
            0,
            0.16726,
            0,
            54.1627,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            173.3569,
            0,
            0.24254,
            0,
            26.16457,
            0,
            27.28649,
            0,
            0,
            0,
            29.31801,
            0,
            16.6117,
            "<D",
            117.2541,
            0,
        ],
        1728168480: [
            0.05974,
            0,
            0.05974,
            0,
            53.5494,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            134.4302,
            0,
            0.14004,
            0,
            26.18695,
            0,
            27.75599,
            0,
            0,
            0,
            29.31994,
            0,
            12.34612,
            "<D",
            117.824,
            0,
        ],
        1728168540: [
            0.20219,
            0,
            0.20219,
            0,
            53.16731,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            67.64525,
            0,
            0.22321,
            0,
            26.10344,
            0,
            31.07965,
            0,
            0,
            0,
            29.31959,
            0,
            15.7745,
            "<D",
            117.3398,
            0,
        ],
        1728168600: [
            0.00332,
            0,
            0.00332,
            0,
            52.82895,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            122.6831,
            0,
            0.21592,
            0,
            26.1129,
            0,
            32.51209,
            0,
            0,
            0,
            29.31968,
            0,
            14.31882,
            "<D",
            117.0356,
            0,
        ],
        1728168660: [
            0.10872,
            0,
            0.10872,
            0,
            53.51187,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            138.231,
            0,
            0.28237,
            0,
            26.16632,
            0,
            30.42339,
            0,
            0,
            0,
            29.32,
            0,
            16.53632,
            "<D",
            116.939,
            0,
        ],
        1728168720: [
            0.23327,
            0,
            0.23327,
            0,
            53.8884,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            142.493,
            0,
            0.30921,
            0,
            26.12258,
            0,
            30.45007,
            0,
            0,
            0,
            29.32062,
            0,
            15.07369,
            "<D",
            116.8963,
            0,
        ],
        1728168780: [
            0.13322,
            0,
            0.13322,
            0,
            52.18733,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            127.3305,
            0,
            0.44561,
            0,
            26.14001,
            0,
            36.3028,
            0,
            0,
            0,
            29.32093,
            0,
            15.51751,
            "<D",
            117.6753,
            0,
        ],
        1728168840: [
            0.13312,
            0,
            0.13312,
            0,
            53.53205,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            131.3636,
            0,
            0.39197,
            0,
            26.06776,
            0,
            41.62069,
            0,
            0,
            0,
            29.32016,
            0,
            14.80769,
            "<D",
            118.2008,
            0,
        ],
        1728168900: [
            0.24463,
            0,
            0.24463,
            0,
            52.77149,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            114.4852,
            0,
            0.56194,
            0,
            26.01208,
            0,
            41.20348,
            0,
            0,
            0,
            29.31999,
            0,
            17.28699,
            "<D",
            117.7222,
            0,
        ],
        1728168960: [
            0.15144,
            0,
            0.15144,
            0,
            52.74095,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            107.9328,
            0,
            0.58734,
            0,
            25.97859,
            0,
            39.18778,
            0,
            0,
            0,
            29.32059,
            0,
            14.28273,
            "<D",
            117.96,
            0,
        ],
        1728169020: [
            0.13279,
            0,
            0.13279,
            0,
            52.91035,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            76.12395,
            0,
            0.44862,
            0,
            25.99021,
            0,
            37.11603,
            0,
            0,
            0,
            29.32093,
            0,
            14.3626,
            "<D",
            117.8413,
            0,
        ],
        1728169080: [
            0.14096,
            0,
            0.14096,
            0,
            53.57166,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            82.9775,
            0,
            0.3607,
            0,
            25.90746,
            0,
            35.0153,
            0,
            0,
            0,
            29.32047,
            0,
            13.63354,
            "<D",
            118.783,
            0,
        ],
        1728169140: [
            0.27257,
            0,
            0.27257,
            0,
            52.96567,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            86.69302,
            0,
            0.67909,
            0,
            25.83201,
            0,
            32.89216,
            0,
            0,
            0,
            29.32093,
            0,
            15.80414,
            "<D",
            118.5819,
            0,
        ],
        1728169200: [
            0.17809,
            0,
            0.17809,
            0,
            53.33444,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            82.59808,
            0,
            0.57119,
            0,
            25.75965,
            0,
            30.82311,
            0,
            0,
            0,
            29.32091,
            0,
            15.39189,
            "<D",
            118.7386,
            0,
        ],
        1728169260: [
            0.07572,
            0,
            0.07572,
            0,
            53.06788,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            73.8275,
            0,
            0.50657,
            0,
            25.55851,
            0,
            28.83471,
            0,
            0,
            0,
            29.32115,
            0,
            14.41235,
            "<D",
            118.0967,
            0,
        ],
        1728169320: [
            0.22596,
            0,
            0.22596,
            0,
            53.14257,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            65.28307,
            0,
            0.49254,
            0,
            25.27953,
            0,
            26.75407,
            0,
            0,
            0,
            29.32103,
            0,
            15.6278,
            "<D",
            117.5665,
            0,
        ],
        1728169380: [
            0.29781,
            0,
            0.29781,
            0,
            52.59555,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            85.06318,
            0,
            0.38856,
            0,
            25.14728,
            0,
            24.78731,
            0,
            0,
            0,
            29.32097,
            0,
            14.6525,
            "<D",
            117.8032,
            0,
        ],
        1728169440: [
            0.27237,
            0,
            0.27237,
            0,
            52.64246,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6732,
            0,
            111.1099,
            0,
            0.34654,
            0,
            25.0297,
            0,
            22.79803,
            0,
            0,
            0,
            29.32098,
            0,
            15.46971,
            "<D",
            118.3289,
            0,
        ],
        1728169500: [
            0.2801,
            0,
            0.2801,
            0,
            52.63058,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            106.1702,
            0,
            0.53758,
            0,
            24.96222,
            0,
            20.43056,
            0,
            0,
            0,
            29.32097,
            0,
            14.70635,
            "<D",
            118.4335,
            0,
        ],
        1728169560: [
            0.29448,
            0,
            0.29448,
            0,
            52.55304,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            109.7896,
            0,
            0.53736,
            0,
            24.97884,
            0,
            16.96116,
            0,
            0,
            0,
            29.32093,
            0,
            15.22575,
            "<D",
            118.2822,
            0,
        ],
        1728169620: [
            0.27644,
            0,
            0.27644,
            0,
            52.21798,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            115.1339,
            0,
            0.70599,
            0,
            24.9654,
            0,
            13.72306,
            0,
            0,
            0,
            29.321,
            0,
            14.73456,
            "<D",
            116.9134,
            0,
        ],
        1728169680: [
            0.2595,
            0,
            0.2595,
            0,
            51.77081,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            118.2949,
            0,
            0.76904,
            0,
            24.93834,
            0,
            12.19936,
            0,
            0,
            0,
            29.32148,
            0,
            15.32354,
            "<D",
            118.0403,
            0,
        ],
        1728169740: [
            0.19636,
            0,
            0.19636,
            0,
            51.76468,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            111.1488,
            0,
            0.67956,
            0,
            24.93254,
            0,
            11.26017,
            0,
            0,
            0,
            29.32096,
            0,
            14.74693,
            "<D",
            118.104,
            0,
        ],
        1728169800: [
            0.181,
            0,
            0.181,
            0,
            51.53004,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            112.2744,
            0,
            0.78681,
            0,
            24.89288,
            0,
            10.24816,
            0,
            0,
            0,
            29.32098,
            0,
            14.72534,
            "<D",
            118.5417,
            0,
        ],
        1728169860: [
            0.09011,
            0,
            0.09011,
            0,
            51.1135,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            107.8389,
            0,
            0.63864,
            0,
            24.80371,
            0,
            9.40552,
            0,
            0,
            0,
            29.32236,
            0,
            13.74899,
            "<D",
            118.3055,
            0,
        ],
        1728169920: [
            0.28022,
            0,
            0.28022,
            0,
            50.76413,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            99.25777,
            0,
            0.59102,
            0,
            24.75162,
            0,
            8.65253,
            0,
            0,
            0,
            29.32281,
            0,
            15.6098,
            "<D",
            117.7222,
            0,
        ],
        1728169980: [
            0.22534,
            0,
            0.22534,
            0,
            50.81905,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            100.3671,
            0,
            0.61581,
            0,
            24.6826,
            0,
            7.89288,
            0,
            0,
            0,
            29.32254,
            0,
            15.00236,
            "<D",
            117.8491,
            0,
        ],
        1728170040: [
            0.3377,
            0,
            0.3377,
            0,
            50.7667,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            88.002,
            0,
            0.60771,
            0,
            24.60021,
            0,
            7.1964,
            0,
            0,
            0,
            29.32422,
            0,
            12.31372,
            "<D",
            118.323,
            0,
        ],
        1728170100: [
            0.25548,
            0,
            0.25548,
            0,
            50.42746,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            92.64674,
            0,
            0.55288,
            0,
            24.58277,
            0,
            6.57963,
            0,
            0,
            0,
            29.32429,
            0,
            16.05488,
            "<D",
            118.7498,
            0,
        ],
        1728170160: [
            0.20427,
            0,
            0.20427,
            0,
            50.50137,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            99.9907,
            0,
            0.56938,
            0,
            24.4752,
            0,
            5.91349,
            0,
            0,
            0,
            29.32428,
            0,
            12.09679,
            "<D",
            118.5934,
            0,
        ],
        1728170220: [
            0.12505,
            0,
            0.12505,
            0,
            50.06379,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            90.36418,
            0,
            0.51441,
            0,
            24.41521,
            0,
            5.38968,
            0,
            0,
            0,
            29.32428,
            0,
            15.92377,
            "<D",
            117.6811,
            0,
        ],
        1728170280: [
            0.0155,
            0,
            0.0155,
            0,
            50.69263,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            96.00654,
            0,
            0.37614,
            0,
            24.35452,
            0,
            4.8826,
            0,
            0,
            0,
            29.32431,
            0,
            12.86309,
            "<D",
            117.7073,
            0,
        ],
        1728170340: [
            0.12619,
            0,
            0.12619,
            0,
            50.37732,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            120.7382,
            0,
            0.6566,
            0,
            24.22828,
            0,
            4.35581,
            0,
            0,
            0,
            29.32429,
            0,
            15.15464,
            "<D",
            117.6501,
            0,
        ],
        1728170400: [
            0.18966,
            0,
            0.18966,
            0,
            50.10082,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            134.1672,
            0,
            0.39336,
            0,
            24.18169,
            0,
            3.93166,
            0,
            0,
            0,
            29.32427,
            0,
            15.26952,
            "<D",
            117.7501,
            0,
        ],
        1728170460: [
            0.18871,
            0,
            0.18871,
            0,
            50.15249,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            149.4311,
            0,
            0.30586,
            0,
            24.12582,
            0,
            3.56379,
            0,
            0,
            0,
            29.32428,
            0,
            14.6772,
            "<D",
            117.8641,
            0,
        ],
        1728170520: [
            0.2908,
            0,
            0.2908,
            0,
            50.43731,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            116.0349,
            0,
            0.21846,
            0,
            24.07888,
            0,
            3.24573,
            0,
            0,
            0,
            29.32431,
            0,
            13.70981,
            "<D",
            117.9861,
            0,
        ],
        1728170580: [
            0.31857,
            0,
            0.31857,
            0,
            50.36974,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            132.5631,
            0,
            0.33012,
            0,
            24.04911,
            0,
            2.94089,
            0,
            0,
            0,
            29.32438,
            0,
            15.69583,
            "<D",
            118.3174,
            0,
        ],
        1728170640: [
            0.18448,
            0,
            0.18448,
            0,
            49.70836,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            108.6926,
            0,
            0.5338,
            0,
            24.03698,
            0,
            2.61958,
            0,
            0,
            0,
            29.32455,
            0,
            14.76644,
            "<D",
            118.0255,
            0,
        ],
        1728170700: [
            0.15774,
            0,
            0.15774,
            0,
            50.28506,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            126.1973,
            0,
            0.55575,
            0,
            23.9773,
            0,
            2.32152,
            0,
            0,
            0,
            29.32482,
            0,
            14.54555,
            "<D",
            118.3096,
            0,
        ],
        1728170760: [
            0.14154,
            0,
            0.14154,
            0,
            49.98201,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            112.6797,
            0,
            0.81565,
            0,
            23.93217,
            0,
            2.07311,
            0,
            0,
            0,
            29.32491,
            0,
            12.7057,
            "<D",
            117.6386,
            0,
        ],
        1728170820: [
            0.07129,
            0,
            0.07129,
            0,
            50.21679,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            93.98297,
            0,
            0.64908,
            0,
            23.88781,
            0,
            1.84126,
            0,
            0,
            0,
            29.32492,
            0,
            17.4987,
            "<D",
            118.3453,
            0,
        ],
        1728170880: [
            0.27343,
            0,
            0.27343,
            0,
            50.26115,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            93.64411,
            0,
            0.45348,
            0,
            23.87594,
            0,
            1.63258,
            0,
            0,
            0,
            29.32473,
            0,
            15.52154,
            "<D",
            118.4535,
            0,
        ],
        1728170940: [
            0.20265,
            0,
            0.20265,
            0,
            50.02662,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            80.11771,
            0,
            0.44192,
            0,
            23.86643,
            0,
            1.43052,
            0,
            0,
            0,
            29.32438,
            0,
            15.23673,
            "<D",
            118.0239,
            0,
        ],
        1728171000: [
            0.21775,
            0,
            0.21775,
            0,
            49.83262,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            107.8048,
            0,
            0.5126,
            0,
            23.79611,
            0,
            1.24508,
            0,
            0,
            0,
            29.3245,
            0,
            14.58741,
            "<D",
            116.7661,
            0,
        ],
        1728171060: [
            0.15471,
            0,
            0.15471,
            0,
            49.29246,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            100.2644,
            0,
            0.29515,
            0,
            23.68297,
            0,
            1.09602,
            0,
            0,
            0,
            29.32433,
            0,
            16.08585,
            "<D",
            117.0615,
            0,
        ],
        1728171120: [
            0.28898,
            0,
            0.28898,
            0,
            48.92493,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            105.9486,
            0,
            0.22198,
            0,
            23.57395,
            0,
            0.93045,
            0,
            0,
            0,
            29.32449,
            0,
            15.95566,
            "<D",
            117.7708,
            0,
        ],
        1728171180: [
            0.1687,
            0,
            0.1687,
            0,
            49.40857,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            95.74371,
            0,
            0.31409,
            0,
            23.49689,
            0,
            0.75496,
            0,
            0,
            0,
            29.32458,
            0,
            13.25301,
            "<D",
            117.8156,
            0,
        ],
        1728171240: [
            0.28559,
            0,
            0.28559,
            0,
            49.34234,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            106.2426,
            0,
            0.31667,
            0,
            23.44811,
            0,
            0.61919,
            0,
            0,
            0,
            29.32476,
            0,
            14.36243,
            "<D",
            117.0899,
            0,
        ],
        1728171300: [
            0.13079,
            0,
            0.13079,
            0,
            49.18023,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            158.8495,
            0,
            0.22105,
            0,
            23.38749,
            0,
            0.47681,
            0,
            0,
            0,
            29.325,
            0,
            15.12831,
            "<D",
            117.4192,
            0,
        ],
        1728171360: [
            0.34465,
            0,
            0.34465,
            0,
            49.5452,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            130.9224,
            0,
            0.21718,
            0,
            23.31086,
            0,
            0.35429,
            0,
            0,
            0,
            29.32503,
            0,
            14.57772,
            "<D",
            117.4854,
            0,
        ],
        1728171420: [
            0.25497,
            0,
            0.25497,
            0,
            49.63355,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            158.3544,
            0,
            0.40576,
            0,
            23.2772,
            0,
            0.32118,
            0,
            0,
            0,
            29.32497,
            0,
            15.44064,
            "<D",
            118.0113,
            0,
        ],
        1728171480: [
            -0.00944,
            0,
            -0.00944,
            0,
            49.17577,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            147.4632,
            0,
            0.54195,
            0,
            23.2578,
            0,
            0.17218,
            0,
            0,
            0,
            29.32499,
            0,
            11.74213,
            "<D",
            119.6235,
            0,
        ],
        1728171540: [
            0.15605,
            0,
            0.15605,
            0,
            49.33327,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            146.7337,
            0,
            0.52159,
            0,
            23.27571,
            0,
            0.16224,
            0,
            0,
            0,
            29.32568,
            0,
            16.31595,
            "<D",
            118.6454,
            0,
        ],
        1728171600: [
            0.23192,
            0,
            0.23192,
            0,
            49.74737,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            148.971,
            0,
            0.60308,
            0,
            23.29422,
            0,
            0.06953,
            0,
            0,
            0,
            29.32664,
            0,
            13.38084,
            "<D",
            118.9959,
            0,
        ],
        1728171660: [
            0.06155,
            0,
            0.06155,
            0,
            49.88552,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            144.4171,
            0,
            0.75296,
            0,
            23.33503,
            0,
            0.00331,
            0,
            0,
            0,
            29.3259,
            0,
            14.37857,
            "<D",
            118.2924,
            0,
        ],
        1728171720: [
            0.23077,
            0,
            0.23077,
            0,
            50.25279,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            143.775,
            0,
            0.59393,
            0,
            23.34716,
            0,
            -0.03973,
            0,
            0,
            0,
            29.32584,
            0,
            12.62397,
            "<D",
            117.6509,
            0,
        ],
        1728171780: [
            0.0793,
            0,
            0.0793,
            0,
            49.99608,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            139.6167,
            0,
            0.58305,
            0,
            23.22699,
            0,
            -0.0596,
            0,
            0,
            0,
            29.32523,
            0,
            14.41266,
            "<D",
            117.5002,
            0,
        ],
        1728171840: [
            0.26454,
            0,
            0.26454,
            0,
            49.16433,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            130.3852,
            0,
            0.68692,
            0,
            23.15301,
            0,
            -0.08608,
            0,
            0,
            0,
            29.32539,
            0,
            13.37869,
            "<D",
            117.5983,
            0,
        ],
        1728171900: [
            0.17523,
            0,
            0.17523,
            0,
            48.49097,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            142.2781,
            0,
            0.61846,
            0,
            23.05572,
            0,
            -0.09933,
            0,
            0,
            0,
            29.32606,
            0,
            13.90607,
            "<D",
            117.8519,
            0,
        ],
        1728171960: [
            0.19574,
            0,
            0.19574,
            0,
            48.68385,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            147.4563,
            0,
            0.66827,
            0,
            22.9495,
            0,
            -0.19534,
            0,
            0,
            0,
            29.32464,
            0,
            16.25978,
            "<D",
            117.5818,
            0,
        ],
        1728172020: [
            0.24272,
            0,
            0.24272,
            0,
            48.68087,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            145.9671,
            0,
            0.84938,
            0,
            22.9067,
            0,
            -0.16223,
            0,
            0,
            0,
            29.3264,
            0,
            12.72373,
            "<D",
            117.821,
            0,
        ],
        1728172080: [
            0.26462,
            0,
            0.26462,
            0,
            48.68416,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.59581,
            0,
            148.2429,
            0,
            0.82597,
            0,
            22.88895,
            0,
            -0.13906,
            0,
            0,
            0,
            29.32647,
            0,
            15.7406,
            "<D",
            118.239,
            0,
        ],
        1728172140: [
            0.21833,
            0,
            0.21833,
            0,
            49.1982,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            150.5191,
            0,
            0.78955,
            0,
            22.87122,
            0,
            -0.11588,
            0,
            0,
            0,
            29.32695,
            0,
            12.12501,
            "<D",
            118.6941,
            0,
        ],
        1728172200: [
            0.20255,
            0,
            0.20255,
            0,
            49.85212,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            146.7448,
            0,
            0.61841,
            0,
            22.83949,
            0,
            -0.06622,
            0,
            0,
            0,
            29.32728,
            0,
            15.06382,
            "<D",
            119.3421,
            0,
        ],
        1728172260: [
            0.28262,
            0,
            0.28262,
            0,
            48.96358,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            153.2587,
            0,
            0.64981,
            0,
            22.83469,
            0,
            -0.05959,
            0,
            0,
            0,
            29.32663,
            0,
            13.37983,
            "<D",
            119.3929,
            0,
        ],
        1728172320: [
            0.26654,
            0,
            0.26654,
            0,
            48.06258,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            142.2817,
            0,
            0.7921,
            0,
            22.76451,
            0,
            -0.04635,
            0,
            0,
            0,
            29.32662,
            0,
            14.28516,
            "<D",
            120.1052,
            0,
        ],
        1728172380: [
            0.12379,
            0,
            0.12379,
            0,
            48.00787,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            152.5494,
            0,
            0.80835,
            0,
            22.58767,
            0,
            -0.08608,
            0,
            0,
            0,
            29.32731,
            0,
            14.17326,
            "<D",
            119.8522,
            0,
        ],
        1728172440: [
            0.10439,
            0,
            0.10439,
            0,
            46.52502,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            148.4578,
            0,
            0.80862,
            0,
            22.45387,
            0,
            -0.16554,
            0,
            0,
            0,
            29.32785,
            0,
            16.16026,
            "<D",
            118.4663,
            0,
        ],
        1728172500: [
            0.11026,
            0,
            0.11026,
            0,
            46.72581,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            145.6826,
            0,
            0.75135,
            0,
            22.53275,
            0,
            -0.15892,
            0,
            0,
            0,
            29.32731,
            0,
            14.77176,
            "<D",
            117.8489,
            0,
        ],
        1728172560: [
            0.3595,
            0,
            0.3595,
            0,
            46.25809,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            133.333,
            0,
            0.69774,
            0,
            22.46905,
            0,
            -0.14237,
            0,
            0,
            0,
            29.32805,
            0,
            12.89778,
            "<D",
            118.5032,
            0,
        ],
        1728172620: [
            0.20992,
            0,
            0.20992,
            0,
            44.28044,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5872,
            0,
            139.7901,
            0,
            0.79443,
            0,
            22.40139,
            0,
            -0.07615,
            0,
            0,
            0,
            29.32775,
            0,
            12.5131,
            "<D",
            119.2225,
            0,
        ],
        1728172680: [
            0.18369,
            0,
            0.18369,
            0,
            45.17465,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5829,
            0,
            141.0117,
            0,
            0.79499,
            0,
            22.38006,
            0,
            -0.07946,
            0,
            0,
            0,
            29.32777,
            0,
            11.18706,
            "<D",
            118.9135,
            0,
        ],
        1728172740: [
            0.22577,
            0,
            0.22577,
            0,
            44.83592,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5657,
            0,
            129.3095,
            0,
            0.74907,
            0,
            22.407,
            0,
            -0.06953,
            0,
            0,
            0,
            29.32754,
            0,
            14.00665,
            "<D",
            118.2458,
            0,
        ],
        1728172800: [
            0.20335,
            0,
            0.20335,
            0,
            44.98666,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            151.6228,
            0,
            0.89991,
            0,
            22.40335,
            0,
            -0.06952,
            0,
            0,
            0,
            29.32755,
            0,
            14.75239,
            "<D",
            118.2205,
            0,
        ],
        1728172860: [
            0.21117,
            0,
            0.21117,
            0,
            45.36243,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            152.3112,
            0,
            0.7545,
            0,
            22.39454,
            0,
            -0.15891,
            0,
            0,
            0,
            29.32756,
            0,
            14.19502,
            "<D",
            118.8791,
            0,
        ],
        1728172920: [
            0.21651,
            0,
            0.21651,
            0,
            47.27293,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            142.0577,
            0,
            0.85595,
            0,
            22.33797,
            0,
            -0.10925,
            0,
            0,
            0,
            29.32758,
            0,
            13.87749,
            "<D",
            120.3502,
            0,
        ],
        1728172980: [
            0.20647,
            0,
            0.20647,
            0,
            45.60587,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            143.5923,
            0,
            0.6935,
            0,
            22.33358,
            0,
            -0.13243,
            0,
            0,
            0,
            29.32766,
            0,
            14.7262,
            "<D",
            119.7336,
            0,
        ],
        1728173040: [
            0.37174,
            0,
            0.37174,
            0,
            46.59699,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            134.383,
            0,
            0.79996,
            0,
            22.21703,
            0,
            -0.18871,
            0,
            0,
            0,
            29.32791,
            0,
            15.17645,
            "<D",
            119.6672,
            0,
        ],
        1728173100: [
            0.27637,
            0,
            0.27637,
            0,
            47.27639,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            144.5753,
            0,
            0.80137,
            0,
            22.29014,
            0,
            -0.07946,
            0,
            0,
            0,
            29.32754,
            0,
            14.27871,
            "<D",
            120.6597,
            0,
        ],
        1728173160: [
            0.13257,
            0,
            0.13257,
            0,
            47.96785,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            132.187,
            0,
            1.07175,
            0,
            22.26472,
            0,
            -0.07615,
            0,
            0,
            0,
            29.32755,
            0,
            14.7223,
            "<D",
            119.7695,
            0,
        ],
        1728173220: [
            0.34423,
            0,
            0.34423,
            0,
            49.30133,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            152.9695,
            0,
            0.87619,
            0,
            22.21881,
            0,
            -0.04635,
            0,
            0,
            0,
            29.32775,
            0,
            13.98794,
            "<D",
            119.1488,
            0,
        ],
        1728173280: [
            0.11786,
            0,
            0.11786,
            0,
            49.2555,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            150.7177,
            0,
            1.13899,
            0,
            22.33625,
            0,
            -0.04966,
            0,
            0,
            0,
            29.32764,
            0,
            10.35471,
            "<D",
            118.5773,
            0,
        ],
        1728173340: [
            0.2502,
            0,
            0.2502,
            0,
            50.74934,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5872,
            0,
            138.3019,
            0,
            0.64489,
            0,
            22.28316,
            0,
            -0.05959,
            0,
            0,
            0,
            29.32972,
            0,
            14.00353,
            "<D",
            118.4909,
            0,
        ],
        1728173400: [
            0.12556,
            0,
            0.12556,
            0,
            52.11446,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5786,
            0,
            124.302,
            0,
            0.61899,
            0,
            22.22073,
            0,
            -0.06621,
            0,
            0,
            0,
            29.32932,
            0,
            15.28961,
            "<D",
            118.1929,
            0,
        ],
        1728173460: [
            0.26208,
            0,
            0.26208,
            0,
            51.48364,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            120.5672,
            0,
            0.64741,
            0,
            22.03876,
            0,
            -0.08277,
            0,
            0,
            0,
            29.32895,
            0,
            12.96682,
            "<D",
            118.2019,
            0,
        ],
        1728173520: [
            0.2653,
            0,
            0.2653,
            0,
            50.69917,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            147.6577,
            0,
            0.72591,
            0,
            22.05546,
            0,
            -0.11919,
            0,
            0,
            0,
            29.32903,
            0,
            14.06004,
            "<D",
            118.6279,
            0,
        ],
        1728173580: [
            0.03064,
            0,
            0.03064,
            0,
            52.83781,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5657,
            0,
            127.8338,
            0,
            0.78457,
            0,
            22.01411,
            0,
            -0.12581,
            0,
            0,
            0,
            29.32875,
            0,
            10.88617,
            "<D",
            118.5995,
            0,
        ],
        1728173640: [
            0.11268,
            0,
            0.11268,
            0,
            51.38596,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5657,
            0,
            154.5263,
            0,
            0.86514,
            0,
            22.041,
            0,
            -0.16885,
            0,
            0,
            0,
            29.32839,
            0,
            16.13052,
            "<D",
            118.3622,
            0,
        ],
        1728173700: [
            0.16021,
            0,
            0.16021,
            0,
            52.29393,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5657,
            0,
            137.5953,
            0,
            0.88265,
            0,
            22.05313,
            0,
            -0.20196,
            0,
            0,
            0,
            29.32803,
            0,
            14.70548,
            "<D",
            119.3536,
            0,
        ],
        1728173760: [
            0.28804,
            0,
            0.28804,
            0,
            52.4522,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            143.7691,
            0,
            0.63679,
            0,
            22.01003,
            0,
            -0.18872,
            0,
            0,
            0,
            29.32824,
            0,
            14.78513,
            "<D",
            120.3138,
            0,
        ],
        1728173820: [
            0.09997,
            0,
            0.09997,
            0,
            52.28692,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5829,
            0,
            154.9313,
            0,
            0.92674,
            0,
            21.98073,
            0,
            -0.22845,
            0,
            0,
            0,
            29.32856,
            0,
            12.81121,
            "<D",
            120.9343,
            0,
        ],
        1728173880: [
            0.11151,
            0,
            0.11151,
            0,
            52.06192,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            153.6312,
            0,
            0.70413,
            0,
            21.97359,
            0,
            -0.17547,
            0,
            0,
            0,
            29.32806,
            0,
            13.83138,
            "<D",
            120.5859,
            0,
        ],
        1728173940: [
            0.14471,
            0,
            0.14471,
            0,
            51.59495,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            145.4663,
            0,
            0.82678,
            0,
            21.89207,
            0,
            -0.0927,
            0,
            0,
            0,
            29.32775,
            0,
            12.104,
            "<D",
            120.4828,
            0,
        ],
        1728174000: [
            0.21157,
            0,
            0.21157,
            0,
            52.42859,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            148.4385,
            0,
            0.93806,
            0,
            21.91743,
            0,
            -0.07615,
            0,
            0,
            0,
            29.32765,
            0,
            13.58983,
            "<D",
            119.5429,
            0,
        ],
        1728174060: [
            0.09459,
            0,
            0.09459,
            0,
            50.44763,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5872,
            0,
            155.1199,
            0,
            1.00773,
            0,
            21.95206,
            0,
            -0.08277,
            0,
            0,
            0,
            29.32763,
            0,
            15.55534,
            "<D",
            118.8219,
            0,
        ],
        1728174120: [
            0.17321,
            0,
            0.17321,
            0,
            51.31251,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            145.2271,
            0,
            1.16535,
            0,
            21.84791,
            0,
            -0.08608,
            0,
            0,
            0,
            29.32705,
            0,
            12.08165,
            "<D",
            118.2602,
            0,
        ],
        1728174180: [
            0.12678,
            0,
            0.12678,
            0,
            51.08388,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            151.1998,
            0,
            0.9214,
            0,
            21.9275,
            0,
            -0.0629,
            0,
            0,
            0,
            29.32755,
            0,
            15.09333,
            "<D",
            118.3379,
            0,
        ],
        1728174240: [
            0.33341,
            0,
            0.33341,
            0,
            50.84414,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5657,
            0,
            144.903,
            0,
            0.86775,
            0,
            21.90236,
            0,
            -0.09932,
            0,
            0,
            0,
            29.3277,
            0,
            16.41025,
            "<D",
            118.4466,
            0,
        ],
        1728174300: [
            0.15838,
            0,
            0.15838,
            0,
            50.47758,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5528,
            0,
            152.7324,
            0,
            0.86265,
            0,
            21.89444,
            0,
            -0.13905,
            0,
            0,
            0,
            29.32759,
            0,
            15.38757,
            "<D",
            118.6225,
            0,
        ],
        1728174360: [
            0.20058,
            0,
            0.20058,
            0,
            48.20817,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            150.7535,
            0,
            0.84103,
            0,
            21.85979,
            0,
            -0.13243,
            0,
            0,
            0,
            29.32778,
            0,
            15.42742,
            "<D",
            118.7421,
            0,
        ],
        1728174420: [
            0.19959,
            0,
            0.19959,
            0,
            51.00799,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            139.4794,
            0,
            1.02495,
            0,
            21.79079,
            0,
            -0.1821,
            0,
            0,
            0,
            29.32815,
            0,
            15.65242,
            "<D",
            119.0348,
            0,
        ],
        1728174480: [
            0.28009,
            0,
            0.28009,
            0,
            50.85973,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5571,
            0,
            157.7708,
            0,
            0.76643,
            0,
            21.72995,
            0,
            -0.22183,
            0,
            0,
            0,
            29.32817,
            0,
            16.66379,
            "<D",
            120.0065,
            0,
        ],
        1728174540: [
            0.2616,
            0,
            0.2616,
            0,
            51.27208,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5571,
            0,
            150.9757,
            0,
            0.92497,
            0,
            21.72945,
            0,
            -0.22514,
            0,
            0,
            0,
            29.32817,
            0,
            13.01576,
            "<D",
            120.5507,
            0,
        ],
        1728174600: [
            0.38079,
            0,
            0.38079,
            0,
            52.37526,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5657,
            0,
            153.5677,
            0,
            1.24188,
            0,
            21.84374,
            0,
            -0.23507,
            0,
            0,
            0,
            29.32831,
            0,
            13.72266,
            "<D",
            120.312,
            0,
        ],
        1728174660: [
            0.1101,
            0,
            0.1101,
            0,
            53.65865,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5571,
            0,
            147.22,
            0,
            1.06852,
            0,
            21.6592,
            0,
            -0.23176,
            0,
            0,
            0,
            29.32823,
            0,
            16.25396,
            "<D",
            119.965,
            0,
        ],
        1728174720: [
            0.17688,
            0,
            0.17688,
            0,
            52.08718,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            151.8483,
            0,
            1.03706,
            0,
            21.55505,
            0,
            -0.22514,
            0,
            0,
            0,
            29.32805,
            0,
            14.34536,
            "<D",
            120.283,
            0,
        ],
        1728174780: [
            0.29304,
            0,
            0.29304,
            0,
            52.92104,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            150.9186,
            0,
            0.99291,
            0,
            21.58569,
            0,
            -0.1854,
            0,
            0,
            0,
            29.32794,
            0,
            14.67297,
            "<D",
            119.5437,
            0,
        ],
        1728174840: [
            0.0704,
            0,
            0.0704,
            0,
            52.67004,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            150.4668,
            0,
            1.22218,
            0,
            21.63176,
            0,
            -0.19534,
            0,
            0,
            0,
            29.32816,
            0,
            16.28787,
            "<D",
            118.5894,
            0,
        ],
        1728174900: [
            0.14439,
            0,
            0.14439,
            0,
            51.28934,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            140.5679,
            0,
            1.18749,
            0,
            21.56873,
            0,
            -0.25824,
            0,
            0,
            0,
            29.32822,
            0,
            12.72275,
            "<D",
            119.7011,
            0,
        ],
        1728174960: [
            0.23371,
            0,
            0.23371,
            0,
            51.74017,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            155.0011,
            0,
            1.02262,
            0,
            21.4308,
            0,
            -0.15892,
            0,
            0,
            0,
            29.32809,
            0,
            16.15612,
            "<D",
            118.3058,
            0,
        ],
        1728175020: [
            0.34205,
            0,
            0.34205,
            0,
            51.93372,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5614,
            0,
            147.5167,
            0,
            0.95103,
            0,
            21.50966,
            0,
            -0.18872,
            0,
            0,
            0,
            29.32824,
            0,
            14.17492,
            "<D",
            118.8051,
            0,
        ],
        1728175080: [
            0.2509,
            0,
            0.2509,
            0,
            52.06605,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            144.9375,
            0,
            0.88234,
            0,
            21.55977,
            0,
            -0.23176,
            0,
            0,
            0,
            29.32842,
            0,
            15.03963,
            "<D",
            119.2653,
            0,
        ],
        1728175140: [
            0.22905,
            0,
            0.22905,
            0,
            52.69786,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5528,
            0,
            152.6546,
            0,
            1.07163,
            0,
            21.36876,
            0,
            -0.23838,
            0,
            0,
            0,
            29.32832,
            0,
            13.42055,
            "<D",
            120.0184,
            0,
        ],
        1728175200: [
            0.30019,
            0,
            0.30019,
            0,
            51.52295,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5528,
            0,
            154.6379,
            0,
            1.15554,
            0,
            21.22483,
            0,
            -0.17879,
            0,
            0,
            0,
            29.32833,
            0,
            14.74704,
            "<D",
            119.8491,
            0,
        ],
        1728175260: [
            0.02814,
            0,
            0.02814,
            0,
            53.75806,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5485,
            0,
            156.7476,
            0,
            0.83577,
            0,
            21.21033,
            0,
            -0.16885,
            0,
            0,
            0,
            29.32833,
            0,
            13.79293,
            "<D",
            120.1547,
            0,
        ],
        1728175320: [
            0.28667,
            0,
            0.28667,
            0,
            54.94846,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5528,
            0,
            159.8951,
            0,
            0.88818,
            0,
            21.07965,
            0,
            -0.16554,
            0,
            0,
            0,
            29.32828,
            0,
            16.01984,
            "<D",
            121.7435,
            0,
        ],
        1728175380: [
            0.06776,
            0,
            0.06776,
            0,
            52.85474,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5614,
            0,
            161.5524,
            0,
            0.91755,
            0,
            21.06999,
            0,
            -0.18872,
            0,
            0,
            0,
            29.32831,
            0,
            14.30119,
            "<D",
            120.8548,
            0,
        ],
        1728175440: [
            0.22906,
            0,
            0.22906,
            0,
            54.78883,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.57,
            0,
            157.7811,
            0,
            1.07436,
            0,
            21.0996,
            0,
            -0.18872,
            0,
            0,
            0,
            29.32832,
            0,
            12.52434,
            "<D",
            120.634,
            0,
        ],
        1728175500: [
            0.2138,
            0,
            0.2138,
            0,
            55.24275,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5786,
            0,
            146.6738,
            0,
            1.23405,
            0,
            21.11723,
            0,
            -0.16885,
            0,
            0,
            0,
            29.32831,
            0,
            16.11763,
            "<D",
            121.7252,
            0,
        ],
        1728175560: [
            0.09984,
            0,
            0.09984,
            0,
            55.48022,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            155.8929,
            0,
            1.12885,
            0,
            21.17505,
            0,
            -0.23176,
            0,
            0,
            0,
            29.32833,
            0,
            13.98396,
            "<D",
            119.3948,
            0,
        ],
        1728175620: [
            0.23603,
            0,
            0.23603,
            0,
            54.05434,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            152.2991,
            0,
            1.06045,
            0,
            21.29685,
            0,
            -0.23176,
            0,
            0,
            0,
            29.32843,
            0,
            12.56308,
            "<D",
            120.837,
            0,
        ],
        1728175680: [
            0.14807,
            0,
            0.14807,
            0,
            55.51263,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            154.1592,
            0,
            1.14788,
            0,
            21.48492,
            0,
            -0.1821,
            0,
            0,
            0,
            29.33045,
            0,
            12.51703,
            "<D",
            119.8841,
            0,
        ],
        1728175740: [
            0.22215,
            0,
            0.22215,
            0,
            55.1073,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            148.5617,
            0,
            1.11039,
            0,
            21.52852,
            0,
            -0.20527,
            0,
            0,
            0,
            29.33134,
            0,
            14.66374,
            "<D",
            118.9737,
            0,
        ],
        1728175800: [
            0.26736,
            0,
            0.26736,
            0,
            55.2663,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            149.8111,
            0,
            1.10156,
            0,
            21.40633,
            0,
            -0.21852,
            0,
            0,
            0,
            29.33165,
            0,
            12.95382,
            "<D",
            118.9244,
            0,
        ],
        1728175860: [
            0.13063,
            0,
            0.13063,
            0,
            55.81253,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            152.6269,
            0,
            0.91658,
            0,
            21.24636,
            0,
            -0.24169,
            0,
            0,
            0,
            29.33161,
            0,
            16.02415,
            "<D",
            118.5697,
            0,
        ],
        1728175920: [
            0.17568,
            0,
            0.17568,
            0,
            54.12914,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5872,
            0,
            138.2131,
            0,
            0.98989,
            0,
            21.19834,
            0,
            -0.3046,
            0,
            0,
            0,
            29.33158,
            0,
            13.27466,
            "<D",
            119.8597,
            0,
        ],
        1728175980: [
            0.05768,
            0,
            0.05768,
            0,
            53.52659,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            144.181,
            0,
            0.91881,
            0,
            21.31592,
            0,
            -0.25825,
            0,
            0,
            0,
            29.3338,
            0,
            15.05013,
            "<D",
            119.0684,
            0,
        ],
        1728176040: [
            0.18382,
            0,
            0.18382,
            0,
            53.57238,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            167.1648,
            0,
            0.70506,
            0,
            21.38374,
            0,
            -0.18872,
            0,
            0,
            0,
            29.33307,
            0,
            13.94862,
            "<D",
            118.6821,
            0,
        ],
        1728176100: [
            0.21107,
            0,
            0.21107,
            0,
            54.6852,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5743,
            0,
            158.9381,
            0,
            1.06411,
            0,
            21.28255,
            0,
            -0.1821,
            0,
            0,
            0,
            29.33399,
            0,
            13.9854,
            "<D",
            119.1479,
            0,
        ],
        1728176160: [
            0.32354,
            0,
            0.32354,
            0,
            54.03286,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            166.3749,
            0,
            0.75719,
            0,
            21.33629,
            0,
            -0.20527,
            0,
            0,
            0,
            29.33505,
            0,
            14.48362,
            "<D",
            120.1658,
            0,
        ],
        1728176220: [
            0.20293,
            0,
            0.20293,
            0,
            54.68491,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            150.162,
            0,
            0.93888,
            0,
            21.38369,
            0,
            -0.2119,
            0,
            0,
            0,
            29.33447,
            0,
            10.89743,
            "<D",
            120.8232,
            0,
        ],
        1728176280: [
            0.23782,
            0,
            0.23782,
            0,
            54.36343,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            133.2775,
            0,
            0.90156,
            0,
            21.30563,
            0,
            -0.20527,
            0,
            0,
            0,
            29.33509,
            0,
            14.32154,
            "<D",
            120.5165,
            0,
        ],
        1728176340: [
            0.1317,
            0,
            0.1317,
            0,
            54.48904,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            138.1195,
            0,
            0.95071,
            0,
            21.35504,
            0,
            -0.23507,
            0,
            0,
            0,
            29.33547,
            0,
            13.85383,
            "<D",
            119.7674,
            0,
        ],
        1728176400: [
            0.44897,
            0,
            0.44897,
            0,
            53.10585,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            147.5826,
            0,
            1.11941,
            0,
            21.2963,
            0,
            -0.24169,
            0,
            0,
            0,
            29.33553,
            0,
            14.3687,
            "<D",
            120.148,
            0,
        ],
        1728176460: [
            0.29935,
            0,
            0.29935,
            0,
            52.63454,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            154.5373,
            0,
            0.8314,
            0,
            21.28498,
            0,
            -0.23507,
            0,
            0,
            0,
            29.33556,
            0,
            14.11455,
            "<D",
            120.3408,
            0,
        ],
        1728176520: [
            0.27783,
            0,
            0.27783,
            0,
            52.12379,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            153.8861,
            0,
            0.78079,
            0,
            21.31434,
            0,
            -0.23176,
            0,
            0,
            0,
            29.33549,
            0,
            15.65851,
            "<D",
            120.2453,
            0,
        ],
        1728176580: [
            0.08428,
            0,
            0.08428,
            0,
            53.9055,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            128.6968,
            0,
            1.01721,
            0,
            21.25891,
            0,
            -0.22183,
            0,
            0,
            0,
            29.33557,
            0,
            14.24235,
            "<D",
            119.6922,
            0,
        ],
        1728176640: [
            0.29593,
            0,
            0.29593,
            0,
            49.39707,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.59581,
            0,
            153.3298,
            0,
            0.74043,
            0,
            21.15243,
            0,
            -0.23838,
            0,
            0,
            0,
            29.33556,
            0,
            15.43107,
            "<D",
            119.0703,
            0,
        ],
        1728176700: [
            0.27281,
            0,
            0.27281,
            0,
            52.34751,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5829,
            0,
            164.9188,
            0,
            0.76784,
            0,
            21.0446,
            0,
            -0.22514,
            0,
            0,
            0,
            29.33651,
            0,
            15.06176,
            "<D",
            119.4346,
            0,
        ],
        1728176760: [
            0.17953,
            0,
            0.17953,
            0,
            48.85514,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            148.878,
            0,
            1.02182,
            0,
            20.91036,
            0,
            -0.24831,
            0,
            0,
            0,
            29.33619,
            0,
            14.91628,
            "<D",
            120.1327,
            0,
        ],
        1728176820: [
            0.17928,
            0,
            0.17928,
            0,
            50.62633,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.59581,
            0,
            156.1492,
            0,
            0.77413,
            0,
            20.94688,
            0,
            -0.23507,
            0,
            0,
            0,
            29.3352,
            0,
            13.15535,
            "<D",
            120.0732,
            0,
        ],
        1728176880: [
            0.11026,
            0,
            0.11026,
            0,
            53.35566,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5872,
            0,
            145.4709,
            0,
            0.85271,
            0,
            21.00206,
            0,
            -0.23507,
            0,
            0,
            0,
            29.33533,
            0,
            13.32355,
            "<D",
            120.3613,
            0,
        ],
        1728176940: [
            0.20546,
            0,
            0.20546,
            0,
            54.38204,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            147.4598,
            0,
            0.74918,
            0,
            21.027,
            0,
            -0.15561,
            0,
            0,
            0,
            29.33559,
            0,
            16.72417,
            "<D",
            121.3222,
            0,
        ],
        1728177000: [
            0.18306,
            0,
            0.18306,
            0,
            53.25665,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            147.7156,
            0,
            0.79397,
            0,
            20.96049,
            0,
            -0.14899,
            0,
            0,
            0,
            29.33555,
            0,
            14.14777,
            "<D",
            121.9713,
            0,
        ],
        1728177060: [
            0.21571,
            0,
            0.21571,
            0,
            53.56707,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            154.8005,
            0,
            0.91968,
            0,
            20.95506,
            0,
            -0.14237,
            0,
            0,
            0,
            29.33543,
            0,
            14.65743,
            "<D",
            121.7145,
            0,
        ],
        1728177120: [
            0.38892,
            0,
            0.38892,
            0,
            54.85735,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            153.6136,
            0,
            1.20015,
            0,
            20.97798,
            0,
            -0.1821,
            0,
            0,
            0,
            29.33646,
            0,
            13.76445,
            "<D",
            120.7518,
            0,
        ],
        1728177180: [
            0.13081,
            0,
            0.13081,
            0,
            55.04501,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            154.2461,
            0,
            1.06613,
            0,
            21.01696,
            0,
            -0.17547,
            0,
            0,
            0,
            29.33753,
            0,
            13.16429,
            "<D",
            121.8809,
            0,
        ],
        1728177240: [
            0.08038,
            0,
            0.08038,
            0,
            55.0393,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            145.3651,
            0,
            0.87603,
            0,
            21.02116,
            0,
            -0.12581,
            0,
            0,
            0,
            29.33737,
            0,
            15.10723,
            "<D",
            120.8111,
            0,
        ],
        1728177300: [
            0.08207,
            0,
            0.08207,
            0,
            55.01946,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            151.0695,
            0,
            0.90931,
            0,
            21.08524,
            0,
            -0.20527,
            0,
            0,
            0,
            29.33887,
            0,
            14.26613,
            "<D",
            121.9819,
            0,
        ],
        1728177360: [
            0.28231,
            0,
            0.28231,
            0,
            54.43288,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            136.4268,
            0,
            1.14234,
            0,
            21.18032,
            0,
            -0.17548,
            0,
            0,
            0,
            29.33888,
            0,
            14.78423,
            "<D",
            120.6581,
            0,
        ],
        1728177420: [
            0.10408,
            0,
            0.10408,
            0,
            55.3275,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            145.9081,
            0,
            1.01813,
            0,
            20.96363,
            0,
            -0.17548,
            0,
            0,
            0,
            29.33884,
            0,
            12.79571,
            "<D",
            119.5114,
            0,
        ],
        1728177480: [
            0.25053,
            0,
            0.25053,
            0,
            54.4722,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            148.2966,
            0,
            0.75617,
            0,
            20.82081,
            0,
            -0.20196,
            0,
            0,
            0,
            29.33889,
            0,
            14.88132,
            "<D",
            117.8732,
            0,
        ],
        1728177540: [
            0.12034,
            0,
            0.12034,
            0,
            54.02645,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            127.3937,
            0,
            1.21214,
            0,
            20.79823,
            0,
            -0.23176,
            0,
            0,
            0,
            29.33894,
            0,
            15.16965,
            "<D",
            118.4708,
            0,
        ],
        1728177600: [
            0.36533,
            0,
            0.36533,
            0,
            53.93512,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            133.1222,
            0,
            1.70115,
            0,
            21.09256,
            0,
            -0.20539,
            0,
            0,
            0,
            29.33895,
            0,
            17.49689,
            "<D",
            120.7684,
            0,
        ],
        1728177660: [
            0.39264,
            0,
            0.39264,
            0,
            54.78101,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            138.0985,
            0,
            1.3851,
            0,
            20.90409,
            0,
            -0.19203,
            0,
            0,
            0,
            29.3389,
            0,
            13.34005,
            "<D",
            120.3105,
            0,
        ],
        1728177720: [
            0.08715,
            0,
            0.08715,
            0,
            53.51933,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            147.0493,
            0,
            0.96839,
            0,
            20.8253,
            0,
            -0.21852,
            0,
            0,
            0,
            29.33905,
            0,
            14.35256,
            "<D",
            119.7274,
            0,
        ],
        1728177780: [
            0.09661,
            0,
            0.09661,
            0,
            54.1723,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            149.4879,
            0,
            1.35233,
            0,
            20.81253,
            0,
            -0.13905,
            0,
            0,
            0,
            29.33887,
            0,
            11.67922,
            "<D",
            121.2501,
            0,
        ],
        1728177840: [
            0.04406,
            0,
            0.04406,
            0,
            54.10945,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            149.2721,
            0,
            1.04614,
            0,
            20.72719,
            0,
            -0.09444,
            0,
            0,
            0,
            29.33868,
            0,
            11.40786,
            "<D",
            121.1941,
            0,
        ],
        1728177900: [
            0.24629,
            0,
            0.24629,
            0,
            53.71451,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            143.0531,
            0,
            0.99501,
            0,
            20.64649,
            0,
            -0.07615,
            0,
            0,
            0,
            29.34064,
            0,
            15.11943,
            "<D",
            121.3801,
            0,
        ],
        1728177960: [
            0.21308,
            0,
            0.21308,
            0,
            51.90208,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            155.506,
            0,
            1.00855,
            0,
            20.49617,
            0,
            -0.07615,
            0,
            0,
            0,
            29.34169,
            0,
            10.43591,
            "<D",
            120.0915,
            0,
        ],
        1728178020: [
            0.1426,
            0,
            0.1426,
            0,
            53.8105,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            145.7352,
            0,
            0.97598,
            0,
            20.46072,
            0,
            -0.09601,
            0,
            0,
            0,
            29.34215,
            0,
            15.24476,
            "<D",
            121.0106,
            0,
        ],
        1728178080: [
            0.13124,
            0,
            0.13124,
            0,
            54.29762,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            143.9425,
            0,
            1.0672,
            0,
            20.617,
            0,
            -0.10263,
            0,
            0,
            0,
            29.34215,
            0,
            12.29216,
            "<D",
            123.7319,
            0,
        ],
        1728178140: [
            0.16094,
            0,
            0.16094,
            0,
            53.23671,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            150.0284,
            0,
            0.95392,
            0,
            20.58623,
            0,
            -0.10926,
            0,
            0,
            0,
            29.34215,
            0,
            12.02824,
            "<D",
            121.7743,
            0,
        ],
        1728178200: [
            0.36314,
            0,
            0.36314,
            0,
            53.91978,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            136.2114,
            0,
            1.12368,
            0,
            20.52804,
            0,
            -0.07946,
            0,
            0,
            0,
            29.34215,
            0,
            14.79939,
            "<D",
            120.389,
            0,
        ],
        1728178260: [
            0.32266,
            0,
            0.32266,
            0,
            54.44923,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            158.4724,
            0,
            0.9939,
            0,
            20.62481,
            0,
            -0.20858,
            0,
            0,
            0,
            29.34215,
            0,
            16.06623,
            "<D",
            119.6002,
            0,
        ],
        1728178320: [
            0.03078,
            0,
            0.03078,
            0,
            52.86102,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            139.9061,
            0,
            0.96329,
            0,
            20.63203,
            0,
            -0.23176,
            0,
            0,
            0,
            29.34214,
            0,
            13.27011,
            "<D",
            118.6728,
            0,
        ],
        1728178380: [
            0.09617,
            0,
            0.09617,
            0,
            53.36208,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            141.5052,
            0,
            0.76679,
            0,
            20.62058,
            0,
            -0.17216,
            0,
            0,
            0,
            29.34309,
            0,
            18.28098,
            "<D",
            118.9033,
            0,
        ],
        1728178440: [
            0.23065,
            0,
            0.23065,
            0,
            54.86828,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            156.0262,
            0,
            0.83832,
            0,
            20.5079,
            0,
            -0.10263,
            0,
            0,
            0,
            29.34243,
            0,
            12.80307,
            "<D",
            119.3386,
            0,
        ],
        1728178500: [
            0.14134,
            0,
            0.14134,
            0,
            54.17842,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            143.2204,
            0,
            0.99981,
            0,
            20.65174,
            0,
            -0.05628,
            0,
            0,
            0,
            29.34228,
            0,
            14.45627,
            "<D",
            120.225,
            0,
        ],
        1728178560: [
            0.08686,
            0,
            0.08686,
            0,
            53.78765,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            141.3674,
            0,
            0.95257,
            0,
            20.72427,
            0,
            -0.08608,
            0,
            0,
            0,
            29.34364,
            0,
            12.9757,
            "<D",
            120.3881,
            0,
        ],
        1728178620: [
            0.33459,
            0,
            0.33459,
            0,
            54.60575,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            142.557,
            0,
            1.14681,
            0,
            20.89061,
            0,
            -0.07283,
            0,
            0,
            0,
            29.34217,
            0,
            15.96802,
            "<D",
            119.6756,
            0,
        ],
        1728178680: [
            0.33226,
            0,
            0.33226,
            0,
            55.75791,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            145.6631,
            0,
            0.98557,
            0,
            20.82907,
            0,
            -0.21851,
            0,
            0,
            0,
            29.34187,
            0,
            11.7554,
            "<D",
            121.0132,
            0,
        ],
        1728178740: [
            0.03818,
            0,
            0.03818,
            0,
            55.54908,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            144.1087,
            0,
            1.31203,
            0,
            20.60761,
            0,
            -0.22513,
            0,
            0,
            0,
            29.34253,
            0,
            11.93748,
            "<D",
            121.0597,
            0,
        ],
        1728178800: [
            0.17415,
            0,
            0.17415,
            0,
            55.77244,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            158.9533,
            0,
            1.1423,
            0,
            20.75866,
            0,
            -0.23506,
            0,
            0,
            0,
            29.34285,
            0,
            16.98658,
            "<D",
            120.8924,
            0,
        ],
        1728178860: [
            0.32541,
            0,
            0.32541,
            0,
            55.07005,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            140.3027,
            0,
            1.26218,
            0,
            20.78906,
            0,
            -0.245,
            0,
            0,
            0,
            29.34209,
            0,
            10.98414,
            "<D",
            121.5039,
            0,
        ],
        1728178920: [
            0.17174,
            0,
            0.17174,
            0,
            54.04317,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            148.3129,
            0,
            1.25617,
            0,
            20.77795,
            0,
            -0.21519,
            0,
            0,
            0,
            29.34257,
            0,
            13.51086,
            "<D",
            120.5479,
            0,
        ],
        1728178980: [
            0.22467,
            0,
            0.22467,
            0,
            54.95128,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            154.1027,
            0,
            0.99723,
            0,
            20.65587,
            0,
            -0.21519,
            0,
            0,
            0,
            29.34228,
            0,
            16.01383,
            "<D",
            120.169,
            0,
        ],
        1728179040: [
            0.25716,
            0,
            0.25716,
            0,
            55.33149,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            145.6644,
            0,
            1.27755,
            0,
            20.50005,
            0,
            -0.15229,
            0,
            0,
            0,
            29.34367,
            0,
            13.90013,
            "<D",
            120.9414,
            0,
        ],
        1728179100: [
            0.20796,
            0,
            0.20796,
            0,
            54.34712,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            138.4462,
            0,
            1.30495,
            0,
            20.44213,
            0,
            -0.1556,
            0,
            0,
            0,
            29.34383,
            0,
            15.9342,
            "<D",
            121.2727,
            0,
        ],
        1728179160: [
            0.16675,
            0,
            0.16675,
            0,
            53.78376,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            146.588,
            0,
            1.50699,
            0,
            20.48276,
            0,
            -0.08277,
            0,
            0,
            0,
            29.34567,
            0,
            14.72959,
            "<D",
            121.3965,
            0,
        ],
        1728179220: [
            0.0496,
            0,
            0.0496,
            0,
            54.54224,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            149.4807,
            0,
            1.35499,
            0,
            20.45411,
            0,
            -0.07283,
            0,
            0,
            0,
            29.34506,
            0,
            15.23771,
            "<D",
            120.7245,
            0,
        ],
        1728179280: [
            0.39814,
            0,
            0.39814,
            0,
            54.08046,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            147.5343,
            0,
            1.1874,
            0,
            20.34951,
            0,
            -0.07284,
            0,
            0,
            0,
            29.34621,
            0,
            12.24007,
            "<D",
            119.3547,
            0,
        ],
        1728179340: [
            0.13434,
            0,
            0.13434,
            0,
            54.07841,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            149.462,
            0,
            1.36923,
            0,
            20.44249,
            0,
            -0.05959,
            0,
            0,
            0,
            29.34621,
            0,
            15.14695,
            "<D",
            119.7159,
            0,
        ],
        1728179400: [
            0.2507,
            0,
            0.2507,
            0,
            53.67495,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            148.3894,
            0,
            1.0255,
            0,
            20.32058,
            0,
            -0.17215,
            0,
            0,
            0,
            29.34623,
            0,
            14.77782,
            "<D",
            118.9057,
            0,
        ],
        1728179460: [
            0.20438,
            0,
            0.20438,
            0,
            54.73751,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            153.9422,
            0,
            1.10924,
            0,
            20.238,
            0,
            -0.15229,
            0,
            0,
            0,
            29.34621,
            0,
            15.40682,
            "<D",
            119.9047,
            0,
        ],
        1728179520: [
            0.23626,
            0,
            0.23626,
            0,
            54.64102,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            139.0423,
            0,
            1.42956,
            0,
            20.39032,
            0,
            -0.19864,
            0,
            0,
            0,
            29.34621,
            0,
            13.1476,
            "<D",
            120.7066,
            0,
        ],
        1728179580: [
            0.2008,
            0,
            0.2008,
            0,
            55.30753,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            154.8493,
            0,
            1.38104,
            0,
            20.36661,
            0,
            -0.17215,
            0,
            0,
            0,
            29.34632,
            0,
            16.95271,
            "<D",
            120.1011,
            0,
        ],
        1728179640: [
            0.13247,
            0,
            0.13247,
            0,
            53.54227,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            156.5054,
            0,
            1.1734,
            0,
            20.27551,
            0,
            -0.17877,
            0,
            0,
            0,
            29.3465,
            0,
            13.6162,
            "<D",
            121.9966,
            0,
        ],
        1728179700: [
            0.10571,
            0,
            0.10571,
            0,
            54.59927,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            147.6136,
            0,
            1.16928,
            0,
            20.20661,
            0,
            -0.1854,
            0,
            0,
            0,
            29.34711,
            0,
            14.90355,
            "<D",
            121.3694,
            0,
        ],
        1728179760: [
            0.16995,
            0,
            0.16995,
            0,
            54.43381,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            147.157,
            0,
            1.27178,
            0,
            20.25005,
            0,
            -0.21519,
            0,
            0,
            0,
            29.34695,
            0,
            17.5224,
            "<D",
            122.7513,
            0,
        ],
        1728179820: [
            0.1324,
            0,
            0.1324,
            0,
            54.8582,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            139.0109,
            0,
            1.35888,
            0,
            20.77112,
            0,
            -0.17877,
            0,
            0,
            0,
            29.34726,
            0,
            14.44821,
            "<D",
            124.4417,
            0,
        ],
        1728179880: [
            0.22236,
            0,
            0.22236,
            0,
            54.60318,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            147.0893,
            0,
            1.36678,
            0,
            21.07095,
            0,
            -0.19202,
            0,
            0,
            0,
            29.34659,
            0,
            15.31789,
            "<D",
            122.9039,
            0,
        ],
        1728179940: [
            0.1957,
            0,
            0.1957,
            0,
            55.02859,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            144.9113,
            0,
            1.41104,
            0,
            21.09856,
            0,
            -0.19202,
            0,
            0,
            0,
            29.34716,
            0,
            15.59743,
            "<D",
            121.3187,
            0,
        ],
        1728180000: [
            0.3147,
            0,
            0.3147,
            0,
            55.01607,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            148.1092,
            0,
            1.56283,
            0,
            20.84568,
            0,
            -0.1225,
            0,
            0,
            0,
            29.34717,
            0,
            14.66195,
            "<D",
            120.6065,
            0,
        ],
        1728180060: [
            -0.00701,
            0,
            -0.00701,
            0,
            55.03595,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            143.177,
            0,
            1.02086,
            0,
            20.69316,
            0,
            -0.11918,
            0,
            0,
            0,
            29.34735,
            0,
            15.29695,
            "<D",
            119.5608,
            0,
        ],
        1728180120: [
            0.122,
            0,
            0.122,
            0,
            55.0232,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            148.0647,
            0,
            1.65316,
            0,
            20.71982,
            0,
            -0.07614,
            0,
            0,
            0,
            29.3469,
            0,
            14.05721,
            "<D",
            120.8252,
            0,
        ],
        1728180180: [
            0.37675,
            0,
            0.37675,
            0,
            55.13599,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            137.7629,
            0,
            1.15224,
            0,
            20.86946,
            0,
            -0.12911,
            0,
            0,
            0,
            29.3467,
            0,
            13.25723,
            "<D",
            119.6159,
            0,
        ],
        1728180240: [
            0.20526,
            0,
            0.20526,
            0,
            54.74632,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            137.3426,
            0,
            1.27469,
            0,
            20.75273,
            0,
            -0.11918,
            0,
            0,
            0,
            29.34662,
            0,
            17.2526,
            "<D",
            118.5606,
            0,
        ],
        1728180300: [
            0.40455,
            0,
            0.40455,
            0,
            54.74464,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            152.2865,
            0,
            1.05577,
            0,
            20.71825,
            0,
            -0.05959,
            0,
            0,
            0,
            29.34621,
            0,
            14.6575,
            "<D",
            118.6653,
            0,
        ],
        1728180360: [
            0.30282,
            0,
            0.30282,
            0,
            53.60411,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            147.784,
            0,
            1.19692,
            0,
            20.76967,
            0,
            -0.12911,
            0,
            0,
            0,
            29.34621,
            0,
            14.4314,
            "<D",
            119.3975,
            0,
        ],
        1728180420: [
            0.33868,
            0,
            0.33868,
            0,
            54.33864,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            144.8487,
            0,
            1.36665,
            0,
            20.48071,
            0,
            -0.10925,
            0,
            0,
            0,
            29.34622,
            0,
            13.7186,
            "<D",
            119.6976,
            0,
        ],
        1728180480: [
            0.19008,
            0,
            0.19008,
            0,
            54.24799,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            165.9832,
            0,
            1.24313,
            0,
            20.82882,
            0,
            -0.08939,
            0,
            0,
            0,
            29.34568,
            0,
            15.81726,
            "<D",
            119.9147,
            0,
        ],
        1728180540: [
            0.27122,
            0,
            0.27122,
            0,
            54.04142,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            145.5339,
            0,
            1.45046,
            0,
            20.75276,
            0,
            -0.14477,
            0,
            0,
            0,
            29.34578,
            0,
            13.46837,
            "<D",
            120.8279,
            0,
        ],
        1728180600: [
            0.299,
            0,
            0.299,
            0,
            53.95873,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            150.8658,
            0,
            1.10798,
            0,
            20.6799,
            0,
            -0.21188,
            0,
            0,
            0,
            29.34648,
            0,
            17.03871,
            "<D",
            121.3034,
            0,
        ],
        1728180660: [
            0.16249,
            0,
            0.16249,
            0,
            53.65763,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            127.4615,
            0,
            1.10006,
            0,
            20.55794,
            0,
            -0.16884,
            0,
            0,
            0,
            29.34514,
            0,
            16.7852,
            "<D",
            122.047,
            0,
        ],
        1728180720: [
            0.18731,
            0,
            0.18731,
            0,
            52.45174,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            122.2663,
            0,
            0.86664,
            0,
            20.32766,
            0,
            -0.18871,
            0,
            0,
            0,
            29.34593,
            0,
            14.32658,
            "<D",
            121.824,
            0,
        ],
        1728180780: [
            0.13061,
            0,
            0.13061,
            0,
            52.10146,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            133.9298,
            0,
            1.00322,
            0,
            20.23987,
            0,
            -0.17547,
            0,
            0,
            0,
            29.3465,
            0,
            13.62791,
            "<D",
            121.937,
            0,
        ],
        1728180840: [
            0.26733,
            0,
            0.26733,
            0,
            52.64516,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            137.8063,
            0,
            1.19562,
            0,
            20.28517,
            0,
            -0.22182,
            0,
            0,
            0,
            29.34669,
            0,
            15.24788,
            "<D",
            122.6261,
            0,
        ],
        1728180900: [
            0.14677,
            0,
            0.14677,
            0,
            51.87252,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            144.8109,
            0,
            1.36393,
            0,
            20.36476,
            0,
            -0.23176,
            0,
            0,
            0,
            29.34682,
            0,
            15.35672,
            "<D",
            120.239,
            0,
        ],
        1728180960: [
            0.21139,
            0,
            0.21139,
            0,
            52.83298,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            126.8038,
            0,
            1.02234,
            0,
            20.54786,
            0,
            -0.21851,
            0,
            0,
            0,
            29.34628,
            0,
            14.82136,
            "<D",
            120.7008,
            0,
        ],
        1728181020: [
            0.14667,
            0,
            0.14667,
            0,
            50.86705,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            135.2412,
            0,
            0.89304,
            0,
            20.41068,
            0,
            -0.20857,
            0,
            0,
            0,
            29.3463,
            0,
            14.07973,
            "<D",
            120.2245,
            0,
        ],
        1728181080: [
            0.27952,
            0,
            0.27952,
            0,
            51.39439,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            141.9239,
            0,
            0.92397,
            0,
            20.60953,
            0,
            -0.17215,
            0,
            0,
            0,
            29.34627,
            0,
            19.00336,
            "<D",
            120.3476,
            0,
        ],
        1728181140: [
            0.17247,
            0,
            0.17247,
            0,
            52.1414,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            135.6047,
            0,
            0.84801,
            0,
            20.55014,
            0,
            -0.13905,
            0,
            0,
            0,
            29.34631,
            0,
            13.74122,
            "<D",
            121.5691,
            0,
        ],
        1728181200: [
            0.21801,
            0,
            0.21801,
            0,
            51.19217,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            137.9125,
            0,
            1.0432,
            0,
            20.45947,
            0,
            -0.10594,
            0,
            0,
            0,
            29.34532,
            0,
            15.37473,
            "<D",
            123.2697,
            0,
        ],
        1728181260: [
            0.13575,
            0,
            0.13575,
            0,
            51.33508,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            142.0665,
            0,
            0.88698,
            0,
            20.56672,
            0,
            -0.08939,
            0,
            0,
            0,
            29.34533,
            0,
            14.09493,
            "<D",
            123.7499,
            0,
        ],
        1728181320: [
            0.25353,
            0,
            0.25353,
            0,
            51.38756,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            134.4357,
            0,
            1.28175,
            0,
            20.70641,
            0,
            -0.10925,
            0,
            0,
            0,
            29.34587,
            0,
            16.6414,
            "<D",
            123.0627,
            0,
        ],
        1728181380: [
            0.24162,
            0,
            0.24162,
            0,
            51.5014,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            134.8652,
            0,
            0.84793,
            0,
            20.58278,
            0,
            -0.13905,
            0,
            0,
            0,
            29.34241,
            0,
            14.23203,
            "<D",
            121.955,
            0,
        ],
        1728181440: [
            0.28685,
            0,
            0.28685,
            0,
            51.13852,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            133.6196,
            0,
            0.96089,
            0,
            20.511,
            0,
            -0.14898,
            0,
            0,
            0,
            29.34195,
            0,
            13.67539,
            "<D",
            121.7402,
            0,
        ],
        1728181500: [
            0.29222,
            0,
            0.29222,
            0,
            50.43226,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            139.2622,
            0,
            0.96813,
            0,
            20.54481,
            0,
            -0.16553,
            0,
            0,
            0,
            29.34236,
            0,
            16.85962,
            "<D",
            121.2138,
            0,
        ],
        1728181560: [
            0.25789,
            0,
            0.25789,
            0,
            50.54136,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5958,
            0,
            133.6485,
            0,
            1.2958,
            0,
            20.55078,
            0,
            -0.21519,
            0,
            0,
            0,
            29.34209,
            0,
            14.08941,
            "<D",
            120.4234,
            0,
        ],
        1728181620: [
            0.09816,
            0,
            0.09816,
            0,
            51.03751,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            139.4471,
            0,
            0.99075,
            0,
            20.53467,
            0,
            -0.22844,
            0,
            0,
            0,
            29.34189,
            0,
            14.85727,
            "<D",
            120.0251,
            0,
        ],
        1728181680: [
            0.10062,
            0,
            0.10062,
            0,
            51.45556,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            132.9152,
            0,
            0.99718,
            0,
            20.3889,
            0,
            -0.22513,
            0,
            0,
            0,
            29.34211,
            0,
            13.28492,
            "<D",
            121.0818,
            0,
        ],
        1728181740: [
            0.16525,
            0,
            0.16525,
            0,
            52.20527,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            139.0574,
            0,
            1.27212,
            0,
            20.37533,
            0,
            -0.23837,
            0,
            0,
            0,
            29.34208,
            0,
            14.7756,
            "<D",
            122.2531,
            0,
        ],
        1728181800: [
            0.13796,
            0,
            0.13796,
            0,
            51.39505,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            137.3344,
            0,
            1.14433,
            0,
            20.18259,
            0,
            -0.23837,
            0,
            0,
            0,
            29.34206,
            0,
            16.98707,
            "<D",
            121.4593,
            0,
        ],
        1728181860: [
            0.13375,
            0,
            0.13375,
            0,
            51.34665,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            134.6868,
            0,
            1.39111,
            0,
            20.51667,
            0,
            -0.25162,
            0,
            0,
            0,
            29.34206,
            0,
            13.42937,
            "<D",
            120.1523,
            0,
        ],
        1728181920: [
            0.17553,
            0,
            0.17553,
            0,
            50.62955,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            157.3752,
            0,
            1.33339,
            0,
            20.51369,
            0,
            -0.30127,
            0,
            0,
            0,
            29.342,
            0,
            16.34801,
            "<D",
            120.3922,
            0,
        ],
        1728181980: [
            0.30934,
            0,
            0.30934,
            0,
            52.15961,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6474,
            0,
            151.5054,
            0,
            1.19384,
            0,
            20.44464,
            0,
            -0.32444,
            0,
            0,
            0,
            29.34178,
            0,
            13.15569,
            "<D",
            120.5126,
            0,
        ],
        1728182040: [
            0.2736,
            0,
            0.2736,
            0,
            49.30197,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            143.7644,
            0,
            1.10211,
            0,
            20.10783,
            0,
            -0.28802,
            0,
            0,
            0,
            29.34185,
            0,
            16.33463,
            "<D",
            119.3985,
            0,
        ],
        1728182100: [
            0.39496,
            0,
            0.39496,
            0,
            50.04735,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            152.8956,
            0,
            1.50295,
            0,
            19.97631,
            0,
            -0.28802,
            0,
            0,
            0,
            29.34206,
            0,
            14.0447,
            "<D",
            120.6947,
            0,
        ],
        1728182160: [
            0.19643,
            0,
            0.19643,
            0,
            51.98059,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            141.3815,
            0,
            1.55742,
            0,
            19.9599,
            0,
            -0.2814,
            0,
            0,
            0,
            29.34215,
            0,
            15.65095,
            "<D",
            120.3142,
            0,
        ],
        1728182220: [
            0.23635,
            0,
            0.23635,
            0,
            52.23923,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6388,
            0,
            153.4134,
            0,
            1.37342,
            0,
            19.96367,
            0,
            -0.26816,
            0,
            0,
            0,
            29.34215,
            0,
            14.32168,
            "<D",
            118.9937,
            0,
        ],
        1728182280: [
            0.13417,
            0,
            0.13417,
            0,
            52.50546,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            137.7534,
            0,
            1.75119,
            0,
            19.91923,
            0,
            -0.30127,
            0,
            0,
            0,
            29.34215,
            0,
            13.60405,
            "<D",
            119.417,
            0,
        ],
        1728182340: [
            0.2018,
            0,
            0.2018,
            0,
            52.59393,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            135.7291,
            0,
            1.76234,
            0,
            19.84768,
            0,
            -0.31119,
            0,
            0,
            0,
            29.34215,
            0,
            13.15569,
            "<D",
            119.5909,
            0,
        ],
        1728182400: [
            0.29117,
            0,
            0.29117,
            0,
            52.44378,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            140.4087,
            0,
            1.33074,
            0,
            19.93411,
            0,
            -0.25822,
            0,
            0,
            0,
            29.34215,
            0,
            14.56568,
            "<D",
            120.3436,
            0,
        ],
        1728182460: [
            0.27556,
            0,
            0.27556,
            0,
            53.05123,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            151.996,
            0,
            1.35772,
            0,
            20.0858,
            0,
            -0.08276,
            0,
            0,
            0,
            29.34207,
            0,
            13.86018,
            "<D",
            120.6953,
            0,
        ],
        1728182520: [
            0.19677,
            0,
            0.19677,
            0,
            52.55729,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5872,
            0,
            149.2612,
            0,
            1.31016,
            0,
            19.90833,
            0,
            -0.20857,
            0,
            0,
            0,
            29.34172,
            0,
            17.77269,
            "<D",
            119.7874,
            0,
        ],
        1728182580: [
            0.15266,
            0,
            0.15266,
            0,
            52.40194,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            144.7964,
            0,
            1.75166,
            0,
            20.28282,
            0,
            -0.2185,
            0,
            0,
            0,
            29.3418,
            0,
            15.4081,
            "<D",
            120.5493,
            0,
        ],
        1728182640: [
            0.10616,
            0,
            0.10616,
            0,
            53.40508,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.59581,
            0,
            146.5155,
            0,
            1.51066,
            0,
            20.37343,
            0,
            -0.22513,
            0,
            0,
            0,
            29.34184,
            0,
            13.50317,
            "<D",
            121.4946,
            0,
        ],
        1728182700: [
            0.23432,
            "<C",
            0.23432,
            "<C",
            53.10557,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            145.6317,
            0,
            1.12795,
            0,
            20.38207,
            0,
            -0.22469,
            0,
            0,
            0,
            29.34189,
            0,
            15.12942,
            "<D",
            122.8654,
            0,
        ],
        1728182760: [
            114.061,
            "<C",
            114.061,
            "<C",
            107.0027,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            145.8611,
            0,
            1.35572,
            0,
            20.51748,
            0,
            -0.20195,
            0,
            0,
            0,
            29.34182,
            0,
            14.00022,
            "<D",
            121.4855,
            0,
        ],
        1728182820: [
            194.0178,
            "<C",
            194.0178,
            "<C",
            186.32,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            154.7603,
            0,
            1.01889,
            0,
            20.27053,
            0,
            -0.20857,
            0,
            0,
            0,
            29.34131,
            0,
            14.62781,
            "<D",
            121.1773,
            0,
        ],
        1728182880: [
            196.9395,
            "<C",
            196.9395,
            "<C",
            194.2783,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            139.7051,
            0,
            0.97523,
            0,
            20.22703,
            0,
            -0.20857,
            0,
            0,
            0,
            29.34185,
            0,
            13.04046,
            "<D",
            121.3987,
            0,
        ],
        1728182940: [
            197.7657,
            "<C",
            197.7657,
            "<C",
            197.0584,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            140.0368,
            0,
            1.82394,
            0,
            20.30176,
            0,
            -0.20526,
            0,
            0,
            0,
            29.34077,
            0,
            15.21449,
            "<D",
            119.9845,
            0,
        ],
        1728183000: [
            198.462,
            "<C",
            198.462,
            "<C",
            198.2588,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            144.3097,
            0,
            1.3117,
            0,
            20.37132,
            0,
            -0.16553,
            0,
            0,
            0,
            29.34011,
            0,
            15.59864,
            "<D",
            119.7461,
            0,
        ],
        1728183060: [
            198.8026,
            "<C",
            198.8026,
            "<C",
            199.0109,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            139.1272,
            0,
            1.36207,
            0,
            20.32014,
            0,
            -0.11256,
            0,
            0,
            0,
            29.33949,
            0,
            15.05173,
            "<D",
            119.1331,
            0,
        ],
        1728183120: [
            198.6492,
            "<C",
            198.6492,
            "<C",
            199.1007,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            144.9948,
            0,
            1.24218,
            0,
            20.3714,
            0,
            -0.06952,
            0,
            0,
            0,
            29.34037,
            0,
            15.32827,
            "<D",
            119.2065,
            0,
        ],
        1728183180: [
            198.911,
            "<C",
            198.911,
            "<C",
            199.7081,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            139.8184,
            0,
            1.37625,
            0,
            20.4184,
            0,
            -0.06953,
            0,
            0,
            0,
            29.34052,
            0,
            11.92577,
            "<D",
            121.0225,
            0,
        ],
        1728183240: [
            199.2328,
            "<C",
            199.2328,
            "<C",
            199.7514,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            158.6508,
            0,
            1.56077,
            0,
            20.3633,
            0,
            -0.12912,
            0,
            0,
            0,
            29.34147,
            0,
            17.28088,
            "<D",
            119.214,
            0,
        ],
        1728183300: [
            199.0958,
            "<C",
            199.0958,
            "<C",
            199.7786,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            140.6889,
            0,
            1.54441,
            0,
            20.48622,
            0,
            -0.20527,
            0,
            0,
            0,
            29.34204,
            0,
            11.21667,
            "<D",
            119.2919,
            0,
        ],
        1728183360: [
            199.1952,
            "<C",
            199.1952,
            "<C",
            200.0444,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            145.2285,
            0,
            1.20752,
            0,
            20.50927,
            0,
            -0.13574,
            0,
            0,
            0,
            29.34215,
            0,
            18.11236,
            "<D",
            118.6345,
            0,
        ],
        1728183420: [
            199.2587,
            "<C",
            199.2587,
            "<C",
            200.0886,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            137.2219,
            0,
            1.61431,
            0,
            20.24294,
            0,
            -0.12581,
            0,
            0,
            0,
            29.34152,
            0,
            14.64592,
            "<D",
            119.7115,
            0,
        ],
        1728183480: [
            111.184,
            "<C",
            111.184,
            "<C",
            133.3201,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            148.2624,
            0,
            0.98152,
            0,
            20.23145,
            0,
            -0.13131,
            0,
            0,
            0,
            29.34215,
            0,
            15.23183,
            "<D",
            120.8001,
            0,
        ],
        1728183540: [
            62.00433,
            "<C",
            62.00433,
            "<C",
            63.25006,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            125.6355,
            0,
            1.22702,
            0,
            20.47841,
            0,
            -0.13574,
            0,
            0,
            0,
            29.34215,
            0,
            14.56305,
            "<D",
            120.061,
            0,
        ],
        1728183600: [
            60.80821,
            "<C",
            60.80821,
            "<C",
            61.4002,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7936,
            0,
            134.0432,
            0,
            1.65972,
            0,
            20.59808,
            0,
            -0.11588,
            0,
            0,
            0,
            29.34189,
            0,
            14.91551,
            "<D",
            119.462,
            0,
        ],
        1728183660: [
            60.52854,
            "<C",
            60.52854,
            "<C",
            60.62642,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            145.8661,
            0,
            1.39647,
            0,
            20.52746,
            0,
            -0.08277,
            0,
            0,
            0,
            29.34055,
            0,
            15.06502,
            "<D",
            120.5809,
            0,
        ],
        1728183720: [
            60.52673,
            "<C",
            60.52673,
            "<C",
            60.53537,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            143.0612,
            0,
            1.4639,
            0,
            20.57799,
            0,
            -0.05628,
            0,
            0,
            0,
            29.34063,
            0,
            15.2949,
            "<D",
            120.9251,
            0,
        ],
        1728183780: [
            60.3367,
            "<C",
            60.3367,
            "<C",
            60.55572,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            137.1598,
            0,
            1.28156,
            0,
            20.45092,
            0,
            -0.08277,
            0,
            0,
            0,
            29.34079,
            0,
            15.47298,
            "<D",
            119.8251,
            0,
        ],
        1728183840: [
            60.46011,
            "<C",
            60.46011,
            "<C",
            60.65267,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8753,
            0,
            141.2083,
            0,
            0.99261,
            0,
            20.29533,
            0,
            -0.09601,
            0,
            0,
            0,
            29.33964,
            0,
            14.86127,
            "<D",
            120.0114,
            0,
        ],
        1728183900: [
            60.66017,
            "<C",
            60.66017,
            "<C",
            60.74163,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            145.8392,
            0,
            1.3069,
            0,
            20.22423,
            0,
            -0.10263,
            0,
            0,
            0,
            29.33891,
            0,
            14.90764,
            "<D",
            120.7255,
            0,
        ],
        1728183960: [
            60.39355,
            "<C",
            60.39355,
            "<C",
            60.64496,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            146.7363,
            0,
            1.40236,
            0,
            20.22218,
            0,
            -0.07946,
            0,
            0,
            0,
            29.34039,
            0,
            15.86122,
            "<D",
            121.0821,
            0,
        ],
        1728184020: [
            60.2958,
            "<C",
            60.2958,
            "<C",
            60.60289,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.957,
            0,
            138.7327,
            0,
            1.3645,
            0,
            20.32075,
            0,
            -0.08277,
            0,
            0,
            0,
            29.34048,
            0,
            14.95988,
            "<D",
            121.3953,
            0,
        ],
        1728184080: [
            60.21546,
            "<C",
            60.21546,
            "<C",
            60.61802,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.99598,
            0,
            143.3876,
            0,
            1.26357,
            0,
            20.53365,
            0,
            -0.08608,
            0,
            0,
            0,
            29.3409,
            0,
            15.91323,
            "<D",
            121.6467,
            0,
        ],
        1728184140: [
            11.70781,
            "<C",
            11.70781,
            "<C",
            20.29302,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            140.5214,
            0,
            0.99694,
            0,
            20.66154,
            0,
            -0.10926,
            0,
            0,
            0,
            29.34021,
            0,
            14.59251,
            "<D",
            120.7355,
            0,
        ],
        1728184200: [
            0.20656,
            "<C",
            0.20656,
            "<C",
            0.2551,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            136.7328,
            0,
            1.25105,
            0,
            20.42016,
            0,
            -0.17548,
            0,
            0,
            0,
            29.34214,
            0,
            15.6323,
            "<D",
            119.8673,
            0,
        ],
        1728184260: [
            0.23651,
            "<C",
            0.23651,
            "<C",
            -0.10207,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.01375,
            0,
            137.8622,
            0,
            1.03539,
            0,
            20.47041,
            0,
            -0.15892,
            0,
            0,
            0,
            29.34155,
            0,
            13.79005,
            "<D",
            120.1777,
            0,
        ],
        1728184320: [
            0.13421,
            "<C",
            0.13421,
            "<C",
            0.23203,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.0275,
            0,
            136.1719,
            0,
            1.18494,
            0,
            20.5217,
            0,
            -0.19534,
            0,
            0,
            0,
            29.34215,
            0,
            16.20128,
            "<D",
            121.195,
            0,
        ],
        1728184380: [
            0.3506,
            "<C",
            0.3506,
            "<C",
            0.14922,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.05042,
            0,
            135.2608,
            0,
            1.05298,
            0,
            20.4805,
            0,
            -0.1225,
            0,
            0,
            0,
            29.34215,
            0,
            14.14314,
            "<D",
            121.0669,
            0,
        ],
        1728184440: [
            0.33969,
            "<C",
            0.33969,
            "<C",
            0.10553,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.05042,
            0,
            137.8266,
            0,
            0.98032,
            0,
            20.41833,
            0,
            -0.07284,
            0,
            0,
            0,
            29.34215,
            0,
            18.30254,
            "<D",
            121.136,
            0,
        ],
        1728184500: [
            0.00519,
            "<C",
            0.00519,
            "<C",
            38.01062,
            "<C",
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.055,
            0,
            131.7212,
            0,
            1.3677,
            0,
            20.37768,
            0,
            -0.10594,
            0,
            0,
            0,
            29.34215,
            0,
            14.25745,
            "<D",
            121.8284,
            0,
        ],
        1728184560: [
            0.16951,
            0,
            0.16951,
            0,
            55.07846,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.06876,
            0,
            139.1275,
            0,
            1.05925,
            0,
            20.41023,
            0,
            -0.09601,
            0,
            0,
            0,
            29.34215,
            0,
            16.36743,
            "<D",
            121.2512,
            0,
        ],
        1728184620: [
            0.39929,
            0,
            0.39929,
            0,
            52.36488,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.08709,
            0,
            135.4638,
            0,
            1.34305,
            0,
            20.33505,
            0,
            -0.07615,
            0,
            0,
            0,
            29.34215,
            0,
            13.32361,
            "<D",
            120.842,
            0,
        ],
        1728184680: [
            0.06279,
            0,
            0.06279,
            0,
            51.78352,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.09626,
            0,
            136.8641,
            0,
            0.87278,
            0,
            20.24137,
            0,
            -0.06621,
            0,
            0,
            0,
            29.34215,
            0,
            14.99251,
            "<D",
            120.6343,
            0,
        ],
        1728184740: [
            0.1627,
            0,
            0.1627,
            0,
            51.10204,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.10543,
            0,
            141.4095,
            0,
            1.04276,
            0,
            20.2787,
            0,
            -0.05959,
            0,
            0,
            0,
            29.34215,
            0,
            14.33006,
            "<D",
            120.3765,
            0,
        ],
        1728184800: [
            0.43844,
            0,
            0.43844,
            0,
            51.45743,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.12376,
            0,
            126.1575,
            0,
            1.0433,
            0,
            20.12894,
            0,
            -0.08608,
            0,
            0,
            0,
            29.34215,
            0,
            16.77249,
            "<D",
            120.4798,
            0,
        ],
        1728184860: [
            0.31153,
            0,
            0.31153,
            0,
            51.18845,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.14668,
            0,
            133.1565,
            0,
            1.58091,
            0,
            20.03528,
            0,
            -0.13574,
            0,
            0,
            0,
            29.34215,
            0,
            11.0513,
            "<D",
            121.0844,
            0,
        ],
        1728184920: [
            0.20253,
            0,
            0.20253,
            0,
            51.45861,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.16043,
            0,
            138.4208,
            0,
            1.51916,
            0,
            19.99567,
            0,
            -0.16885,
            0,
            0,
            0,
            29.34214,
            0,
            15.24883,
            "<D",
            120.0135,
            0,
        ],
        1728184980: [
            0.26824,
            0,
            0.26824,
            0,
            51.66794,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.17877,
            0,
            143.4194,
            0,
            1.22382,
            0,
            20.27161,
            0,
            -0.21852,
            0,
            0,
            0,
            29.34214,
            0,
            15.05522,
            "<D",
            119.9704,
            0,
        ],
        1728185040: [
            0.13735,
            0,
            0.13735,
            0,
            51.894,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.17877,
            0,
            136.4617,
            0,
            0.95814,
            0,
            20.11235,
            0,
            -0.22845,
            0,
            0,
            0,
            29.34208,
            0,
            14.09683,
            "<D",
            120.1768,
            0,
        ],
        1728185100: [
            0.09418,
            0,
            0.09418,
            0,
            51.23394,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.17877,
            0,
            140.6548,
            0,
            1.26499,
            0,
            20.08731,
            0,
            -0.20858,
            0,
            0,
            0,
            29.34206,
            0,
            14.73398,
            "<D",
            119.9507,
            0,
        ],
        1728185160: [
            0.20474,
            0,
            0.20474,
            0,
            50.0579,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.15126,
            0,
            136.2269,
            0,
            0.92575,
            0,
            20.04587,
            0,
            -0.23176,
            0,
            0,
            0,
            29.34208,
            0,
            15.04227,
            "<D",
            118.8294,
            0,
        ],
        1728185220: [
            0.27848,
            0,
            0.27848,
            0,
            49.95775,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.15126,
            0,
            146.6164,
            0,
            0.86236,
            0,
            19.94367,
            0,
            -0.22513,
            0,
            0,
            0,
            29.3419,
            0,
            14.26875,
            "<D",
            119.7171,
            0,
        ],
        1728185280: [
            0.20622,
            0,
            0.20622,
            0,
            51.08144,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.1421,
            0,
            138.2671,
            0,
            1.00446,
            0,
            19.92909,
            0,
            -0.21189,
            0,
            0,
            0,
            29.34197,
            0,
            14.65038,
            "<D",
            119.9864,
            0,
        ],
        1728185340: [
            0.25165,
            0,
            0.25165,
            0,
            51.45518,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.13293,
            0,
            136.7347,
            0,
            0.95152,
            0,
            19.96458,
            0,
            -0.21852,
            0,
            0,
            0,
            29.34214,
            0,
            15.46645,
            "<D",
            120.5891,
            0,
        ],
        1728185400: [
            0.05805,
            0,
            0.05805,
            0,
            51.9479,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.12835,
            0,
            130.1815,
            0,
            1.19266,
            0,
            20.00732,
            0,
            -0.21521,
            0,
            0,
            0,
            29.34214,
            0,
            16.61576,
            "<D",
            120.9901,
            0,
        ],
        1728185460: [
            0.19655,
            0,
            0.19655,
            0,
            52.08435,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.10543,
            0,
            132.3035,
            0,
            1.03647,
            0,
            20.07654,
            0,
            -0.23838,
            0,
            0,
            0,
            29.34214,
            0,
            17.05847,
            "<D",
            120.7052,
            0,
        ],
        1728185520: [
            0.12979,
            0,
            0.12979,
            0,
            51.54119,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.12376,
            0,
            139.2742,
            0,
            1.18462,
            0,
            20.00718,
            0,
            -0.21189,
            0,
            0,
            0,
            29.34212,
            0,
            14.40254,
            "<D",
            120.7737,
            0,
        ],
        1728185580: [
            0.20069,
            0,
            0.20069,
            0,
            51.46555,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.12376,
            0,
            143.2178,
            0,
            0.85905,
            0,
            20.17809,
            0,
            -0.22845,
            0,
            0,
            0,
            29.34241,
            0,
            15.71273,
            "<D",
            120.8409,
            0,
        ],
        1728185640: [
            0.34994,
            0,
            0.34994,
            0,
            50.90073,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.11918,
            0,
            144.8465,
            0,
            1.00241,
            0,
            20.10566,
            0,
            -0.19865,
            0,
            0,
            0,
            29.34241,
            0,
            15.06078,
            "<D",
            120.5551,
            0,
        ],
        1728185700: [
            0.09347,
            0,
            0.09347,
            0,
            50.43134,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.12376,
            0,
            144.4381,
            0,
            1.1897,
            0,
            20.00306,
            0,
            -0.10263,
            0,
            0,
            0,
            29.34318,
            0,
            14.31737,
            "<D",
            121.6872,
            0,
        ],
        1728185760: [
            0.30266,
            0,
            0.30266,
            0,
            51.14539,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.12835,
            0,
            148.1835,
            0,
            1.2224,
            0,
            19.8843,
            0,
            -0.18541,
            0,
            0,
            0,
            29.34276,
            0,
            15.28491,
            "<D",
            121.5881,
            0,
        ],
        1728185820: [
            0.34407,
            0,
            0.34407,
            0,
            51.70469,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.13293,
            0,
            143.6224,
            0,
            1.26186,
            0,
            20.06344,
            0,
            -0.22845,
            0,
            0,
            0,
            29.34533,
            0,
            15.12229,
            "<D",
            121.8679,
            0,
        ],
        1728185880: [
            0.39423,
            0,
            0.39423,
            0,
            51.35351,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.13751,
            0,
            133.736,
            0,
            1.13595,
            0,
            19.95181,
            0,
            -0.22514,
            0,
            0,
            0,
            29.34524,
            0,
            17.37342,
            "<D",
            121.1537,
            0,
        ],
        1728185940: [
            0.18395,
            0,
            0.18395,
            0,
            51.34468,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.15126,
            0,
            151.2752,
            0,
            1.49078,
            0,
            20.02141,
            0,
            -0.19865,
            0,
            0,
            0,
            29.34396,
            0,
            14.98585,
            "<D",
            122.2776,
            0,
        ],
        1728186000: [
            0.18107,
            0,
            0.18107,
            0,
            52.94932,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.1696,
            0,
            141.6086,
            0,
            1.49304,
            0,
            19.97204,
            0,
            -0.11588,
            0,
            0,
            0,
            29.34468,
            0,
            15.31035,
            "<D",
            121.0745,
            0,
        ],
        1728186060: [
            0.32934,
            0,
            0.32934,
            0,
            51.65275,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.15126,
            0,
            138.9977,
            0,
            1.22527,
            0,
            20.56379,
            0,
            -0.08608,
            0,
            0,
            0,
            29.3435,
            0,
            15.35384,
            "<D",
            121.2485,
            0,
        ],
        1728186120: [
            0.14039,
            0,
            0.14039,
            0,
            52.28645,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.12376,
            0,
            130.1205,
            0,
            1.22302,
            0,
            20.58331,
            0,
            -0.1225,
            0,
            0,
            0,
            29.34353,
            0,
            15.84178,
            "<D",
            119.5498,
            0,
        ],
        1728186180: [
            0.22238,
            0,
            0.22238,
            0,
            52.0622,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.12376,
            0,
            145.097,
            0,
            1.25265,
            0,
            20.20742,
            0,
            -0.13906,
            0,
            0,
            0,
            29.34249,
            0,
            15.84477,
            "<D",
            119.6768,
            0,
        ],
        1728186240: [
            0.23583,
            0,
            0.23583,
            0,
            53.26256,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.10085,
            0,
            143.638,
            0,
            1.07813,
            0,
            19.99271,
            0,
            -0.09932,
            0,
            0,
            0,
            29.34242,
            0,
            14.35884,
            "<D",
            119.7477,
            0,
        ],
        1728186300: [
            0.11783,
            0,
            0.11783,
            0,
            54.07744,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.07792,
            0,
            150.5181,
            0,
            1.06974,
            0,
            19.95656,
            0,
            -0.05628,
            0,
            0,
            0,
            29.34239,
            0,
            15.85904,
            "<D",
            119.7583,
            0,
        ],
        1728186360: [
            0.12346,
            0,
            0.12346,
            0,
            53.10238,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.06876,
            0,
            146.4384,
            0,
            1.25678,
            0,
            19.77387,
            0,
            -0.08608,
            0,
            0,
            0,
            29.34204,
            0,
            15.38654,
            "<D",
            119.9107,
            0,
        ],
        1728186420: [
            0.37332,
            0,
            0.37332,
            0,
            53.95182,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.05042,
            0,
            140.1594,
            0,
            1.52032,
            0,
            19.69762,
            0,
            -0.16223,
            0,
            0,
            0,
            29.34205,
            0,
            14.26441,
            "<D",
            120.9302,
            0,
        ],
        1728186480: [
            0.12846,
            0,
            0.12846,
            0,
            54.98106,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            149.7468,
            0,
            1.17647,
            0,
            19.76938,
            0,
            -0.13574,
            0,
            0,
            0,
            29.34185,
            0,
            15.4785,
            "<D",
            120.9367,
            0,
        ],
        1728186540: [
            0.11505,
            0,
            0.11505,
            0,
            54.4993,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            145.6161,
            0,
            1.14493,
            0,
            19.69731,
            0,
            -0.16554,
            0,
            0,
            0,
            29.34185,
            0,
            15.70552,
            "<D",
            120.8178,
            0,
        ],
        1728186600: [
            0.37831,
            0,
            0.37831,
            0,
            54.44875,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.0275,
            0,
            145.0371,
            0,
            1.62158,
            0,
            19.83119,
            0,
            -0.22514,
            0,
            0,
            0,
            29.34244,
            0,
            14.29712,
            "<D",
            120.4178,
            0,
        ],
        1728186660: [
            0.25893,
            0,
            0.25893,
            0,
            53.83067,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.02292,
            0,
            150.735,
            0,
            1.52271,
            0,
            19.9345,
            0,
            -0.23176,
            0,
            0,
            0,
            29.34275,
            0,
            15.66706,
            "<D",
            121.2766,
            0,
        ],
        1728186720: [
            0.17683,
            0,
            0.17683,
            0,
            54.5238,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.03667,
            0,
            137.2392,
            0,
            1.44212,
            0,
            19.68838,
            0,
            -0.23176,
            0,
            0,
            0,
            29.34209,
            0,
            14.37299,
            "<D",
            122.0428,
            0,
        ],
        1728186780: [
            0.27172,
            0,
            0.27172,
            0,
            54.95694,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.03209,
            0,
            156.8708,
            0,
            1.17032,
            0,
            19.55422,
            0,
            -0.22845,
            0,
            0,
            0,
            29.34208,
            0,
            17.61782,
            "<D",
            121.7802,
            0,
        ],
        1728186840: [
            -0.00602,
            0,
            -0.00602,
            0,
            54.57051,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.03209,
            0,
            151.3468,
            0,
            1.3476,
            0,
            19.53197,
            0,
            -0.22514,
            0,
            0,
            0,
            29.34207,
            0,
            14.74072,
            "<D",
            121.4601,
            0,
        ],
        1728186900: [
            0.19519,
            0,
            0.19519,
            0,
            55.06415,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.03209,
            0,
            151.6757,
            0,
            1.64489,
            0,
            19.53643,
            0,
            -0.23507,
            0,
            0,
            0,
            29.34205,
            0,
            17.2493,
            "<D",
            120.8299,
            0,
        ],
        1728186960: [
            0.24479,
            0,
            0.24479,
            0,
            55.235,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.02292,
            0,
            140.6221,
            0,
            1.22392,
            0,
            20.39183,
            0,
            -0.18541,
            0,
            0,
            0,
            29.34199,
            0,
            13.95123,
            "<D",
            120.9777,
            0,
        ],
        1728187020: [
            0.41869,
            0,
            0.41869,
            0,
            55.02631,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            134.8738,
            0,
            1.46165,
            0,
            20.10411,
            0,
            -0.08939,
            0,
            0,
            0,
            29.34196,
            0,
            16.43071,
            "<D",
            120.7965,
            0,
        ],
        1728187080: [
            0.15868,
            0,
            0.15868,
            0,
            55.20359,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.05042,
            0,
            150.2944,
            0,
            1.35226,
            0,
            19.82925,
            0,
            -0.08277,
            0,
            0,
            0,
            29.3421,
            0,
            15.40347,
            "<D",
            120.2805,
            0,
        ],
        1728187140: [
            0.31322,
            0,
            0.31322,
            0,
            54.33811,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.05042,
            0,
            148.9301,
            0,
            1.39345,
            0,
            19.85561,
            0,
            -0.05628,
            0,
            0,
            0,
            29.34215,
            0,
            15.40073,
            "<D",
            121.3047,
            0,
        ],
        1728187200: [
            0.15629,
            0,
            0.15629,
            0,
            53.72504,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.04126,
            0,
            129.2589,
            0,
            1.23209,
            0,
            19.83614,
            0,
            -0.06953,
            0,
            0,
            0,
            29.34215,
            0,
            15.43248,
            "<D",
            120.3626,
            0,
        ],
        1728187260: [
            0.21706,
            0,
            0.21706,
            0,
            55.04102,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            35.00487,
            0,
            142.2547,
            0,
            1.28676,
            0,
            19.83348,
            0,
            -0.07615,
            0,
            0,
            0,
            29.34242,
            0,
            15.07474,
            "<D",
            119.9486,
            0,
        ],
        1728187320: [
            0.23323,
            0,
            0.23323,
            0,
            54.67446,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9871,
            0,
            141.4239,
            0,
            1.24391,
            0,
            20.05985,
            0,
            -0.06953,
            0,
            0,
            0,
            29.34316,
            0,
            13.48031,
            "<D",
            119.0467,
            0,
        ],
        1728187380: [
            0.25062,
            0,
            0.25062,
            0,
            54.92503,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9699,
            0,
            150.9922,
            0,
            0.95235,
            0,
            19.92605,
            0,
            -0.05628,
            0,
            0,
            0,
            29.34233,
            0,
            16.42125,
            "<D",
            119.5849,
            0,
        ],
        1728187440: [
            0.12477,
            0,
            0.12477,
            0,
            54.62332,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9527,
            0,
            137.9173,
            0,
            1.31845,
            0,
            20.10426,
            0,
            -0.06953,
            0,
            0,
            0,
            29.34253,
            0,
            15.26697,
            "<D",
            120.0794,
            0,
        ],
        1728187500: [
            0.40257,
            0,
            0.40257,
            0,
            55.14862,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            145.4804,
            0,
            1.1595,
            0,
            19.8351,
            0,
            -0.0629,
            0,
            0,
            0,
            29.34492,
            0,
            15.02908,
            "<D",
            121.397,
            0,
        ],
        1728187560: [
            0.17605,
            0,
            0.17605,
            0,
            54.76933,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9355,
            0,
            154.6942,
            0,
            1.22633,
            0,
            20.05555,
            0,
            -0.13574,
            0,
            0,
            0,
            29.34485,
            0,
            12.53367,
            "<D",
            120.4955,
            0,
        ],
        1728187620: [
            0.13319,
            0,
            0.13319,
            0,
            54.94991,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.914,
            0,
            143.0181,
            0,
            1.17861,
            0,
            19.72202,
            0,
            -0.20527,
            0,
            0,
            0,
            29.34519,
            0,
            16.04246,
            "<D",
            120.4871,
            0,
        ],
        1728187680: [
            0.19692,
            0,
            0.19692,
            0,
            54.25318,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            140.5129,
            0,
            0.86668,
            0,
            19.54029,
            0,
            -0.21851,
            0,
            0,
            0,
            29.3467,
            0,
            15.07181,
            "<D",
            121.8922,
            0,
        ],
        1728187740: [
            0.33863,
            0,
            0.33863,
            0,
            50.73954,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            141.7304,
            0,
            0.89221,
            0,
            19.32302,
            0,
            -0.25162,
            0,
            0,
            0,
            29.34682,
            0,
            15.04612,
            "<D",
            121.2375,
            0,
        ],
        1728187800: [
            0.21059,
            0,
            0.21059,
            0,
            51.08001,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            145.4955,
            0,
            0.883,
            0,
            19.29082,
            0,
            -0.24831,
            0,
            0,
            0,
            29.34653,
            0,
            14.97501,
            "<D",
            121.1641,
            0,
        ],
        1728187860: [
            0.36496,
            0,
            0.36496,
            0,
            46.77705,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            132.842,
            0,
            1.02763,
            0,
            19.23798,
            0,
            -0.29466,
            0,
            0,
            0,
            29.347,
            0,
            13.96712,
            "<D",
            121.0082,
            0,
        ],
        1728187920: [
            0.4152,
            0,
            0.4152,
            0,
            47.18746,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8968,
            0,
            133.3713,
            0,
            0.91013,
            0,
            19.24881,
            0,
            -0.29466,
            0,
            0,
            0,
            29.34681,
            0,
            15.332,
            "<D",
            120.2434,
            0,
        ],
        1728187980: [
            0.20321,
            0,
            0.20321,
            0,
            43.49936,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9011,
            0,
            139.8958,
            0,
            1.119,
            0,
            19.22964,
            0,
            -0.28473,
            0,
            0,
            0,
            29.34716,
            0,
            13.79123,
            "<D",
            120.9207,
            0,
        ],
        1728188040: [
            0.11336,
            0,
            0.11336,
            0,
            46.12079,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            135.6451,
            0,
            0.76057,
            0,
            19.11159,
            0,
            -0.30791,
            0,
            0,
            0,
            29.34704,
            0,
            13.60703,
            "<D",
            120.5173,
            0,
        ],
        1728188100: [
            0.17197,
            0,
            0.17197,
            0,
            37.36634,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9097,
            0,
            144.4576,
            0,
            1.10276,
            0,
            19.02338,
            0,
            -0.2748,
            0,
            0,
            0,
            29.34686,
            0,
            14.55867,
            "<D",
            120.3286,
            0,
        ],
        1728188160: [
            0.19743,
            0,
            0.19743,
            0,
            51.94814,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            151.0579,
            0,
            0.93278,
            0,
            18.8967,
            0,
            -0.27148,
            0,
            0,
            0,
            29.34681,
            0,
            14.70239,
            "<D",
            120.5285,
            0,
        ],
        1728188220: [
            0.2206,
            0,
            0.2206,
            0,
            48.5792,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9226,
            0,
            145.3733,
            0,
            1.1032,
            0,
            18.91884,
            0,
            -0.23175,
            0,
            0,
            0,
            29.34633,
            0,
            15.50861,
            "<D",
            120.3365,
            0,
        ],
        1728188280: [
            0.11482,
            0,
            0.11482,
            0,
            52.44231,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9269,
            0,
            139.1144,
            0,
            0.97471,
            0,
            18.86495,
            0,
            -0.07284,
            0,
            0,
            0,
            29.34621,
            0,
            11.69227,
            "<D",
            120.4306,
            0,
        ],
        1728188340: [
            0.05159,
            0,
            0.05159,
            0,
            52.08991,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9183,
            0,
            150.6494,
            0,
            1.10684,
            0,
            18.74794,
            0,
            -0.10595,
            0,
            0,
            0,
            29.34621,
            0,
            17.08074,
            "<D",
            120.358,
            0,
        ],
        1728188400: [
            0.07838,
            0,
            0.07838,
            0,
            51.68838,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.9054,
            0,
            148.1453,
            0,
            0.83245,
            0,
            18.92197,
            0,
            -0.06622,
            0,
            0,
            0,
            29.34621,
            0,
            11.46019,
            "<D",
            119.9422,
            0,
        ],
        1728188460: [
            0.0993,
            0,
            0.0993,
            0,
            51.93205,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8925,
            0,
            148.6687,
            0,
            1.00687,
            0,
            18.90254,
            0,
            -0.06953,
            0,
            0,
            0,
            29.34621,
            0,
            15.46525,
            "<D",
            120.0377,
            0,
        ],
        1728188520: [
            0.10318,
            0,
            0.10318,
            0,
            53.64444,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8839,
            0,
            150.7003,
            0,
            1.14466,
            0,
            19.09356,
            0,
            -0.11257,
            0,
            0,
            0,
            29.34621,
            0,
            13.92282,
            "<D",
            120.3161,
            0,
        ],
        1728188580: [
            0.1177,
            0,
            0.1177,
            0,
            53.0511,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8624,
            0,
            151.0618,
            0,
            1.48796,
            0,
            18.88623,
            0,
            -0.05959,
            0,
            0,
            0,
            29.34608,
            0,
            15.63171,
            "<D",
            119.868,
            0,
        ],
        1728188640: [
            0.34925,
            0,
            0.34925,
            0,
            53.57165,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            148.0574,
            0,
            1.33864,
            0,
            18.96006,
            0,
            -0.06621,
            0,
            0,
            0,
            29.34621,
            0,
            14.77985,
            "<D",
            120.0183,
            0,
        ],
        1728188700: [
            0.08594,
            0,
            0.08594,
            0,
            53.77216,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            138.9097,
            0,
            1.11705,
            0,
            19.03213,
            0,
            -0.05297,
            0,
            0,
            0,
            29.34621,
            0,
            16.04746,
            "<D",
            120.3441,
            0,
        ],
        1728188760: [
            0.3186,
            0,
            0.3186,
            0,
            52.75293,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8409,
            0,
            143.0908,
            0,
            1.49956,
            0,
            18.85379,
            0,
            -0.09601,
            0,
            0,
            0,
            29.34518,
            0,
            13.00171,
            "<D",
            121.1343,
            0,
        ],
        1728188820: [
            0.1587,
            0,
            0.1587,
            0,
            53.42511,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            137.8751,
            0,
            1.11784,
            0,
            18.73029,
            0,
            -0.08939,
            0,
            0,
            0,
            29.34402,
            0,
            14.51291,
            "<D",
            120.9883,
            0,
        ],
        1728188880: [
            0.32243,
            0,
            0.32243,
            0,
            51.93165,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8151,
            0,
            136.1622,
            0,
            1.44403,
            0,
            19.05857,
            0,
            -0.10263,
            0,
            0,
            0,
            29.34552,
            0,
            15.10908,
            "<D",
            120.4225,
            0,
        ],
        1728188940: [
            0.15565,
            0,
            0.15565,
            0,
            52.7925,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            142.882,
            0,
            1.24127,
            0,
            19.07788,
            0,
            -0.05628,
            0,
            0,
            0,
            29.34506,
            0,
            14.41928,
            "<D",
            120.4642,
            0,
        ],
        1728189000: [
            0.17834,
            0,
            0.17834,
            0,
            52.49378,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8194,
            0,
            142.2473,
            0,
            1.17475,
            0,
            19.04478,
            0,
            -0.0927,
            0,
            0,
            0,
            29.34622,
            0,
            15.63876,
            "<D",
            121.3566,
            0,
        ],
        1728189060: [
            0.20543,
            0,
            0.20543,
            0,
            52.42854,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            142.0507,
            0,
            1.0428,
            0,
            19.13523,
            0,
            -0.14898,
            0,
            0,
            0,
            29.34616,
            0,
            15.90274,
            "<D",
            120.6537,
            0,
        ],
        1728189120: [
            0.41572,
            0,
            0.41572,
            0,
            52.11149,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.828,
            0,
            147.0464,
            0,
            1.24861,
            0,
            19.38005,
            0,
            -0.06952,
            0,
            0,
            0,
            29.34621,
            0,
            13.96687,
            "<D",
            120.6758,
            0,
        ],
        1728189180: [
            0.31954,
            0,
            0.31954,
            0,
            53.58695,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            146.3223,
            0,
            0.96599,
            0,
            19.24273,
            0,
            -0.05959,
            0,
            0,
            0,
            29.34621,
            0,
            15.09339,
            "<D",
            120.4611,
            0,
        ],
        1728189240: [
            0.09323,
            0,
            0.09323,
            0,
            52.32023,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8237,
            0,
            149.2021,
            0,
            0.84856,
            0,
            19.22467,
            0,
            -0.08939,
            0,
            0,
            0,
            29.34621,
            0,
            16.3131,
            "<D",
            120.1337,
            0,
        ],
        1728189300: [
            0.27284,
            0,
            0.27284,
            0,
            52.53404,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8366,
            0,
            143.1178,
            0,
            1.03194,
            0,
            19.07052,
            0,
            -0.10263,
            0,
            0,
            0,
            29.34621,
            0,
            13.64748,
            "<D",
            120.8377,
            0,
        ],
        1728189360: [
            0.32583,
            0,
            0.32583,
            0,
            53.21979,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            150.4313,
            0,
            1.04779,
            0,
            18.95935,
            0,
            -0.11257,
            0,
            0,
            0,
            29.34608,
            0,
            13.85024,
            "<D",
            120.5708,
            0,
        ],
        1728189420: [
            0.15854,
            0,
            0.15854,
            0,
            53.34288,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8495,
            0,
            153.6151,
            0,
            0.85571,
            0,
            19.25615,
            0,
            -0.10926,
            0,
            0,
            0,
            29.34608,
            0,
            17.35011,
            "<D",
            120.1109,
            0,
        ],
        1728189480: [
            0.10484,
            0,
            0.10484,
            0,
            53.06178,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8323,
            0,
            151.8537,
            0,
            1.25791,
            0,
            19.34196,
            0,
            -0.13575,
            0,
            0,
            0,
            29.34594,
            0,
            14.03014,
            "<D",
            119.6521,
            0,
        ],
        1728189540: [
            0.29592,
            0,
            0.29592,
            0,
            53.57209,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8108,
            0,
            138.3457,
            0,
            1.0343,
            0,
            19.36743,
            0,
            -0.20859,
            0,
            0,
            0,
            29.34632,
            0,
            14.97017,
            "<D",
            118.6618,
            0,
        ],
        1728189600: [
            0.2156,
            0,
            0.2156,
            0,
            53.09862,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.8065,
            0,
            142.939,
            0,
            1.15464,
            0,
            19.31963,
            0,
            -0.22845,
            0,
            0,
            0,
            29.34547,
            0,
            14.97843,
            "<D",
            118.5097,
            0,
        ],
        1728189660: [
            0.18291,
            0,
            0.18291,
            0,
            53.63108,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            147.0419,
            0,
            1.33305,
            0,
            19.37218,
            0,
            -0.23839,
            0,
            0,
            0,
            29.34462,
            0,
            15.56959,
            "<D",
            118.9837,
            0,
        ],
        1728189720: [
            0.17563,
            0,
            0.17563,
            0,
            52.94767,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            143.7127,
            0,
            1.41369,
            0,
            19.29897,
            0,
            -0.22514,
            0,
            0,
            0,
            29.344,
            0,
            15.23746,
            "<D",
            119.2814,
            0,
        ],
        1728189780: [
            0.29503,
            0,
            0.29503,
            0,
            54.01299,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            148.6976,
            0,
            1.08976,
            0,
            19.46104,
            0,
            -0.2119,
            0,
            0,
            0,
            29.34289,
            0,
            14.85195,
            "<D",
            119.6334,
            0,
        ],
        1728189840: [
            0.2384,
            0,
            0.2384,
            0,
            53.87207,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            148.4359,
            0,
            1.29379,
            0,
            19.42559,
            0,
            -0.15561,
            0,
            0,
            0,
            29.34392,
            0,
            14.7251,
            "<D",
            119.3479,
            0,
        ],
        1728189900: [
            0.32265,
            0,
            0.32265,
            0,
            53.7596,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            151.3602,
            0,
            1.1138,
            0,
            19.13387,
            0,
            -0.07284,
            0,
            0,
            0,
            29.34263,
            0,
            14.37226,
            "<D",
            119.3739,
            0,
        ],
        1728189960: [
            0.21516,
            0,
            0.21516,
            0,
            53.59838,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            153.3159,
            0,
            1.4173,
            0,
            19.0182,
            0,
            -0.05959,
            0,
            0,
            0,
            29.34385,
            0,
            15.19255,
            "<D",
            120.159,
            0,
        ],
        1728190020: [
            0.15364,
            0,
            0.15364,
            0,
            53.61858,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            158.0334,
            0,
            1.20566,
            0,
            18.80744,
            0,
            -0.07946,
            0,
            0,
            0,
            29.34503,
            0,
            14.3247,
            "<D",
            120.3075,
            0,
        ],
        1728190080: [
            0.20622,
            0,
            0.20622,
            0,
            53.40341,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            149.9316,
            0,
            1.28251,
            0,
            18.77219,
            0,
            -0.05959,
            0,
            0,
            0,
            29.34514,
            0,
            14.96259,
            "<D",
            119.7514,
            0,
        ],
        1728190140: [
            0.15666,
            0,
            0.15666,
            0,
            53.40927,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            141.9978,
            0,
            1.20416,
            0,
            18.80558,
            0,
            -0.05628,
            0,
            0,
            0,
            29.34545,
            0,
            13.95959,
            "<D",
            121.0779,
            0,
        ],
        1728190200: [
            0.1616,
            0,
            0.1616,
            0,
            53.67112,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            147.2393,
            0,
            0.95019,
            0,
            19.04825,
            0,
            -0.06621,
            0,
            0,
            0,
            29.34507,
            0,
            14.34823,
            "<D",
            120.6433,
            0,
        ],
        1728190260: [
            0.30375,
            0,
            0.30375,
            0,
            53.43356,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            137.0521,
            0,
            1.1639,
            0,
            19.03231,
            0,
            -0.07946,
            0,
            0,
            0,
            29.34573,
            0,
            14.42421,
            "<D",
            120.487,
            0,
        ],
        1728190320: [
            0.12862,
            0,
            0.12862,
            0,
            53.21676,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            135.4365,
            0,
            1.02435,
            0,
            18.98454,
            0,
            -0.10595,
            0,
            0,
            0,
            29.34624,
            0,
            14.37705,
            "<D",
            121.1081,
            0,
        ],
        1728190380: [
            0.14386,
            0,
            0.14386,
            0,
            53.06146,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            146.9344,
            0,
            1.05254,
            0,
            19.09906,
            0,
            -0.06953,
            0,
            0,
            0,
            29.34608,
            0,
            16.0111,
            "<D",
            120.6409,
            0,
        ],
        1728190440: [
            0.22678,
            0,
            0.22678,
            0,
            52.00916,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7635,
            0,
            150.3809,
            0,
            1.07272,
            0,
            19.00738,
            0,
            -0.14568,
            0,
            0,
            0,
            29.34621,
            0,
            14.95668,
            "<D",
            120.2222,
            0,
        ],
        1728190500: [
            0.26405,
            0,
            0.26405,
            0,
            52.54303,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7764,
            0,
            148.4478,
            0,
            0.79544,
            0,
            19.05128,
            0,
            -0.11588,
            0,
            0,
            0,
            29.34621,
            0,
            13.54597,
            "<D",
            120.0891,
            0,
        ],
        1728190560: [
            0.28303,
            0,
            0.28303,
            0,
            51.70207,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7807,
            0,
            150.6209,
            0,
            1.4181,
            0,
            19.38075,
            0,
            -0.17879,
            0,
            0,
            0,
            29.34608,
            0,
            13.77806,
            "<D",
            120.1916,
            0,
        ],
        1728190620: [
            0.37751,
            0,
            0.37751,
            0,
            52.17016,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            145.7357,
            0,
            0.83663,
            0,
            19.26111,
            0,
            -0.23508,
            0,
            0,
            0,
            29.34625,
            0,
            14.65501,
            "<D",
            120.2157,
            0,
        ],
        1728190680: [
            0.27232,
            0,
            0.27232,
            0,
            52.43325,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            149.4222,
            0,
            0.96718,
            0,
            19.15716,
            0,
            -0.23176,
            0,
            0,
            0,
            29.3463,
            0,
            13.60028,
            "<D",
            120.2308,
            0,
        ],
        1728190740: [
            0.08058,
            0,
            0.08058,
            0,
            53.21452,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            149.8055,
            0,
            0.67982,
            0,
            18.8642,
            0,
            -0.23508,
            0,
            0,
            0,
            29.34646,
            0,
            15.46767,
            "<D",
            119.436,
            0,
        ],
        1728190800: [
            0.12602,
            0,
            0.12602,
            0,
            53.18185,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            146.6011,
            0,
            0.75857,
            0,
            18.84225,
            0,
            -0.22183,
            0,
            0,
            0,
            29.34601,
            0,
            14.05303,
            "<D",
            119.2936,
            0,
        ],
        1728190860: [
            0.14027,
            0,
            0.14027,
            0,
            53.21296,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            136.353,
            0,
            0.70415,
            0,
            18.74795,
            0,
            -0.23839,
            0,
            0,
            0,
            29.34623,
            0,
            14.78463,
            "<D",
            119.2733,
            0,
        ],
        1728190920: [
            0.07123,
            0,
            0.07123,
            0,
            53.12695,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            130.6401,
            0,
            0.79818,
            0,
            18.68902,
            0,
            -0.17548,
            0,
            0,
            0,
            29.34581,
            0,
            14.60181,
            "<D",
            118.9388,
            0,
        ],
        1728190980: [
            0.10444,
            0,
            0.10444,
            0,
            52.5956,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            138.3797,
            0,
            0.6089,
            0,
            18.60666,
            0,
            -0.17879,
            0,
            0,
            0,
            29.34574,
            0,
            14.31221,
            "<D",
            119.104,
            0,
        ],
        1728191040: [
            0.16527,
            0,
            0.16527,
            0,
            51.21156,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            134.3743,
            0,
            0.61703,
            0,
            18.60752,
            0,
            -0.05297,
            0,
            0,
            0,
            29.34473,
            0,
            13.81202,
            "<D",
            119.3194,
            0,
        ],
        1728191100: [
            0.13786,
            0,
            0.13786,
            0,
            51.87207,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            129.7857,
            0,
            0.74756,
            0,
            18.62506,
            0,
            -0.08277,
            0,
            0,
            0,
            29.34276,
            0,
            14.16408,
            "<D",
            119.1689,
            0,
        ],
        1728191160: [
            0.41001,
            0,
            0.41001,
            0,
            51.42879,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            136.2997,
            0,
            0.6923,
            0,
            18.55144,
            0,
            -0.11919,
            0,
            0,
            0,
            29.34193,
            0,
            15.51776,
            "<D",
            118.9519,
            0,
        ],
        1728191220: [
            0.35257,
            0,
            0.35257,
            0,
            48.07225,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            146.8298,
            0,
            0.63067,
            0,
            18.61831,
            0,
            -0.14899,
            0,
            0,
            0,
            29.34193,
            0,
            14.75189,
            "<D",
            119.7084,
            0,
        ],
        1728191280: [
            0.20151,
            0,
            0.20151,
            0,
            49.27416,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            138.7971,
            0,
            0.90105,
            0,
            18.51969,
            0,
            -0.18872,
            0,
            0,
            0,
            29.34195,
            0,
            14.86387,
            "<D",
            121.1437,
            0,
        ],
        1728191340: [
            0.13087,
            0,
            0.13087,
            0,
            51.87802,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            144.4298,
            0,
            0.87444,
            0,
            18.48434,
            0,
            -0.08939,
            0,
            0,
            0,
            29.34194,
            0,
            16.27665,
            "<D",
            121.3127,
            0,
        ],
        1728191400: [
            0.19455,
            0,
            0.19455,
            0,
            52.09444,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            138.8073,
            0,
            0.95468,
            0,
            18.33698,
            0,
            -0.12912,
            0,
            0,
            0,
            29.34197,
            0,
            12.98507,
            "<D",
            121.4661,
            0,
        ],
        1728191460: [
            0.1488,
            0,
            0.1488,
            0,
            53.65076,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            149.0681,
            0,
            0.88678,
            0,
            18.2562,
            0,
            -0.08939,
            0,
            0,
            0,
            29.34146,
            0,
            15.13993,
            "<D",
            119.8616,
            0,
        ],
        1728191520: [
            0.3233,
            0,
            0.3233,
            0,
            53.01567,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            162.6056,
            0,
            1.11826,
            0,
            18.24043,
            0,
            -0.13905,
            0,
            0,
            0,
            29.34008,
            0,
            14.46859,
            "<D",
            119.622,
            0,
        ],
        1728191580: [
            0.23085,
            0,
            0.23085,
            0,
            52.98454,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            156.4252,
            0,
            1.16773,
            0,
            18.22822,
            0,
            -0.08277,
            0,
            0,
            0,
            29.33914,
            0,
            14.06241,
            "<D",
            119.4177,
            0,
        ],
        1728191640: [
            0.23937,
            0,
            0.23937,
            0,
            53.3904,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            162.843,
            0,
            1.30931,
            0,
            18.18463,
            0,
            -0.06621,
            0,
            0,
            0,
            29.33838,
            0,
            15.2648,
            "<D",
            119.7626,
            0,
        ],
        1728191700: [
            0.20264,
            0,
            0.20264,
            0,
            49.84445,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            164.5369,
            0,
            1.18807,
            0,
            18.15086,
            0,
            -0.11588,
            0,
            0,
            0,
            29.33833,
            0,
            14.93297,
            "<D",
            120.0609,
            0,
        ],
        1728191760: [
            0.35711,
            0,
            0.35711,
            0,
            52.28242,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7592,
            0,
            150.7712,
            0,
            1.05691,
            0,
            18.07388,
            0,
            -0.20197,
            0,
            0,
            0,
            29.33899,
            0,
            13.29568,
            "<D",
            120.0171,
            0,
        ],
        1728191820: [
            0.25139,
            0,
            0.25139,
            0,
            53.48613,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7678,
            0,
            165.457,
            0,
            1.347,
            0,
            18.06563,
            0,
            -0.23508,
            0,
            0,
            0,
            29.33909,
            0,
            13.59749,
            "<D",
            120.2721,
            0,
        ],
        1728191880: [
            0.09049,
            0,
            0.09049,
            0,
            53.95639,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7721,
            0,
            166.3841,
            0,
            1.41297,
            0,
            18.14126,
            0,
            -0.22183,
            0,
            0,
            0,
            29.33886,
            0,
            14.10293,
            "<D",
            119.8178,
            0,
        ],
        1728191940: [
            0.03785,
            0,
            0.03785,
            0,
            54.20115,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            168.7399,
            0,
            1.25535,
            0,
            18.11999,
            0,
            -0.23176,
            0,
            0,
            0,
            29.33885,
            0,
            16.02628,
            "<D",
            118.7538,
            0,
        ],
        1728192000: [
            0.22575,
            0,
            0.22575,
            0,
            52.29705,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            170.6599,
            0,
            1.54766,
            0,
            18.25634,
            0,
            -0.22845,
            0,
            0,
            0,
            29.33888,
            0,
            15.22846,
            "<D",
            118.3806,
            0,
        ],
        1728192060: [
            0.04142,
            0,
            0.04142,
            0,
            51.16422,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            164.9782,
            0,
            1.43328,
            0,
            18.36791,
            0,
            -0.22514,
            0,
            0,
            0,
            29.33882,
            0,
            14.41999,
            "<D",
            119.4248,
            0,
        ],
        1728192120: [
            0.1425,
            0,
            0.1425,
            0,
            51.55882,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            169.2724,
            0,
            1.12738,
            0,
            18.40564,
            0,
            -0.22183,
            0,
            0,
            0,
            29.33886,
            0,
            18.99129,
            "<D",
            118.706,
            0,
        ],
        1728192180: [
            0.17977,
            0,
            0.17977,
            0,
            52.21646,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7162,
            0,
            171.9551,
            0,
            1.30645,
            0,
            18.05946,
            0,
            -0.21852,
            0,
            0,
            0,
            29.33886,
            0,
            16.63933,
            "<D",
            118.75,
            0,
        ],
        1728192240: [
            0.23634,
            0,
            0.23634,
            0,
            51.93227,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            159.6978,
            0,
            1.17475,
            0,
            18.04256,
            0,
            -0.22183,
            0,
            0,
            0,
            29.33887,
            0,
            15.79888,
            "<D",
            119.3348,
            0,
        ],
        1728192300: [
            0.38859,
            0,
            0.38859,
            0,
            52.37456,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            163.4559,
            0,
            1.4358,
            0,
            18.15109,
            0,
            -0.18872,
            0,
            0,
            0,
            29.33876,
            0,
            15.39157,
            "<D",
            119.1492,
            0,
        ],
        1728192360: [
            0.19973,
            0,
            0.19973,
            0,
            52.64869,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            160.5185,
            0,
            1.27421,
            0,
            18.27158,
            0,
            -0.14237,
            0,
            0,
            0,
            29.33844,
            0,
            15.68941,
            "<D",
            119.4882,
            0,
        ],
        1728192420: [
            0.16182,
            0,
            0.16182,
            0,
            51.62286,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            165.3111,
            0,
            1.08822,
            0,
            18.29001,
            0,
            -0.11588,
            0,
            0,
            0,
            29.33834,
            0,
            17.60154,
            "<D",
            119.6976,
            0,
        ],
        1728192480: [
            0.02462,
            0,
            0.02462,
            0,
            51.74954,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            159.2008,
            0,
            1.0299,
            0,
            18.28304,
            0,
            -0.05628,
            0,
            0,
            0,
            29.3386,
            0,
            17.34343,
            "<D",
            120.3023,
            0,
        ],
        1728192540: [
            0.1678,
            0,
            0.1678,
            0,
            51.33196,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            177.8944,
            0,
            1.28714,
            0,
            18.35661,
            0,
            -0.06953,
            0,
            0,
            0,
            29.33842,
            0,
            15.39723,
            "<D",
            120.0776,
            0,
        ],
        1728192600: [
            0.24909,
            0,
            0.24909,
            0,
            52.16003,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            151.3257,
            0,
            0.94333,
            0,
            18.1318,
            0,
            -0.1225,
            0,
            0,
            0,
            29.33853,
            0,
            15.59383,
            "<D",
            119.7527,
            0,
        ],
        1728192660: [
            0.2831,
            0,
            0.2831,
            0,
            52.87015,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            164.452,
            0,
            1.02003,
            0,
            18.14129,
            0,
            -0.08608,
            0,
            0,
            0,
            29.33849,
            0,
            15.05264,
            "<D",
            119.8996,
            0,
        ],
        1728192720: [
            0.38341,
            0,
            0.38341,
            0,
            52.34261,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            154.1993,
            0,
            1.29611,
            0,
            18.5259,
            0,
            -0.06622,
            0,
            0,
            0,
            29.33811,
            0,
            16.39355,
            "<D",
            120.2326,
            0,
        ],
        1728192780: [
            0.22717,
            0,
            0.22717,
            0,
            53.27468,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            170.4265,
            0,
            1.21569,
            0,
            18.67986,
            0,
            -0.07284,
            0,
            0,
            0,
            29.3382,
            0,
            15.15971,
            "<D",
            121.4782,
            0,
        ],
        1728192840: [
            0.14495,
            0,
            0.14495,
            0,
            53.25397,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            152.4748,
            0,
            1.01958,
            0,
            18.42349,
            0,
            -0.13244,
            0,
            0,
            0,
            29.33825,
            0,
            15.0885,
            "<D",
            120.8265,
            0,
        ],
        1728192900: [
            0.20922,
            0,
            0.20922,
            0,
            53.09612,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.742,
            0,
            149.764,
            0,
            0.96975,
            0,
            18.22622,
            0,
            -0.09933,
            0,
            0,
            0,
            29.33831,
            0,
            17.1496,
            "<D",
            120.2357,
            0,
        ],
        1728192960: [
            0.2286,
            0,
            0.2286,
            0,
            53.25863,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7377,
            0,
            158.5132,
            0,
            1.06282,
            0,
            18.0097,
            0,
            -0.2119,
            0,
            0,
            0,
            29.33874,
            0,
            16.384,
            "<D",
            120.2393,
            0,
        ],
        1728193020: [
            0.17814,
            0,
            0.17814,
            0,
            53.1633,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7506,
            0,
            158.2451,
            0,
            1.1356,
            0,
            17.95642,
            0,
            -0.21852,
            0,
            0,
            0,
            29.33881,
            0,
            16.70856,
            "<D",
            120.2092,
            0,
        ],
        1728193080: [
            0.20969,
            0,
            0.20969,
            0,
            52.98299,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            156.6158,
            0,
            1.02057,
            0,
            17.84644,
            0,
            -0.23839,
            0,
            0,
            0,
            29.33805,
            0,
            15.78399,
            "<D",
            120.1519,
            0,
        ],
        1728193140: [
            0.2464,
            0,
            0.2464,
            0,
            52.81615,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7549,
            0,
            162.4653,
            0,
            1.03711,
            0,
            17.84228,
            0,
            -0.23177,
            0,
            0,
            0,
            29.3387,
            0,
            15.74596,
            "<D",
            120.0667,
            0,
        ],
        1728193200: [
            0.19835,
            0,
            0.19835,
            0,
            52.64992,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            165.9692,
            0,
            0.89064,
            0,
            18.31765,
            0,
            -0.22514,
            0,
            0,
            0,
            29.33843,
            0,
            15.24595,
            "<D",
            118.6249,
            0,
        ],
        1728193260: [
            0.24675,
            0,
            0.24675,
            0,
            52.69014,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            150.2781,
            0,
            0.87317,
            0,
            18.26213,
            0,
            -0.23508,
            0,
            0,
            0,
            29.33825,
            0,
            13.5728,
            "<D",
            118.2872,
            0,
        ],
        1728193320: [
            0.25967,
            0,
            0.25967,
            0,
            52.43761,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7076,
            0,
            161.841,
            0,
            0.9813,
            0,
            18.24209,
            0,
            -0.18541,
            0,
            0,
            0,
            29.33601,
            0,
            17.59465,
            "<D",
            118.0327,
            0,
        ],
        1728193380: [
            0.16051,
            0,
            0.16051,
            0,
            52.36039,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            156.8147,
            0,
            0.96715,
            0,
            18.07133,
            0,
            -0.22514,
            0,
            0,
            0,
            29.33511,
            0,
            14.71094,
            "<D",
            118.5825,
            0,
        ],
        1728193440: [
            0.13684,
            0,
            0.13684,
            0,
            52.07549,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            162.2826,
            0,
            1.12371,
            0,
            18.13958,
            0,
            -0.19203,
            0,
            0,
            0,
            29.33485,
            0,
            12.12936,
            "<D",
            119.2428,
            0,
        ],
        1728193500: [
            0.26509,
            0,
            0.26509,
            0,
            52.63718,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6904,
            0,
            160.691,
            0,
            1.04908,
            0,
            18.12184,
            0,
            -0.10595,
            0,
            0,
            0,
            29.33484,
            0,
            16.76573,
            "<D",
            118.7367,
            0,
        ],
        1728193560: [
            0.18789,
            0,
            0.18789,
            0,
            52.56083,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            150.9924,
            0,
            1.14093,
            0,
            18.25975,
            0,
            -0.09601,
            0,
            0,
            0,
            29.33473,
            0,
            14.7281,
            "<D",
            118.6993,
            0,
        ],
        1728193620: [
            0.28974,
            0,
            0.28974,
            0,
            52.79112,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            157.4468,
            0,
            1.01665,
            0,
            18.36018,
            0,
            -0.13244,
            0,
            0,
            0,
            29.33475,
            0,
            14.98726,
            "<D",
            119.1941,
            0,
        ],
        1728193680: [
            0.29849,
            0,
            0.29849,
            0,
            52.22844,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            153.5894,
            0,
            1.32718,
            0,
            18.39782,
            0,
            -0.08277,
            0,
            0,
            0,
            29.33386,
            0,
            16.01952,
            "<D",
            119.4267,
            0,
        ],
        1728193740: [
            0.19984,
            0,
            0.19984,
            0,
            52.23493,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            156.5025,
            0,
            1.14808,
            0,
            18.07307,
            0,
            -0.05959,
            0,
            0,
            0,
            29.33401,
            0,
            12.90402,
            "<D",
            119.8985,
            0,
        ],
        1728193800: [
            0.12646,
            0,
            0.12646,
            0,
            51.992,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            159.4862,
            0,
            1.493,
            0,
            17.9495,
            0,
            -0.09601,
            0,
            0,
            0,
            29.3337,
            0,
            14.65882,
            "<D",
            119.8895,
            0,
        ],
        1728193860: [
            0.18797,
            0,
            0.18797,
            0,
            51.89551,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            154.6066,
            0,
            1.73882,
            0,
            18.02767,
            0,
            -0.10263,
            0,
            0,
            0,
            29.3323,
            0,
            14.91446,
            "<D",
            120.0016,
            0,
        ],
        1728193920: [
            0.08702,
            0,
            0.08702,
            0,
            52.13609,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            145.7754,
            0,
            1.32748,
            0,
            17.9018,
            0,
            -0.07946,
            0,
            0,
            0,
            29.33451,
            0,
            17.32175,
            "<D",
            119.93,
            0,
        ],
        1728193980: [
            0.09181,
            0,
            0.09181,
            0,
            51.96595,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            149.3643,
            0,
            1.13718,
            0,
            17.904,
            0,
            -0.08608,
            0,
            0,
            0,
            29.33459,
            0,
            15.27281,
            "<D",
            119.8685,
            0,
        ],
        1728194040: [
            0.08192,
            0,
            0.08192,
            0,
            51.71638,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7033,
            0,
            154.6563,
            0,
            1.11058,
            0,
            17.8357,
            0,
            -0.06622,
            0,
            0,
            0,
            29.33485,
            0,
            14.93574,
            "<D",
            120.0295,
            0,
        ],
        1728194100: [
            0.307,
            0,
            0.307,
            0,
            51.69351,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            153.5787,
            0,
            1.06629,
            0,
            17.96974,
            0,
            -0.20527,
            0,
            0,
            0,
            29.33473,
            0,
            15.42823,
            "<D",
            120.8787,
            0,
        ],
        1728194160: [
            0.35351,
            0,
            0.35351,
            0,
            51.8028,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7205,
            0,
            153.1064,
            0,
            1.25538,
            0,
            17.78166,
            0,
            -0.2417,
            0,
            0,
            0,
            29.33485,
            0,
            16.29519,
            "<D",
            121.1126,
            0,
        ],
        1728194220: [
            0.11125,
            0,
            0.11125,
            0,
            51.89626,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7248,
            0,
            156.5338,
            0,
            1.04861,
            0,
            17.60676,
            0,
            -0.23839,
            0,
            0,
            0,
            29.33492,
            0,
            15.54326,
            "<D",
            120.2467,
            0,
        ],
        1728194280: [
            0.11053,
            0,
            0.11053,
            0,
            51.69123,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            146.2434,
            0,
            1.05917,
            0,
            17.53638,
            0,
            -0.26156,
            0,
            0,
            0,
            29.33496,
            0,
            14.12397,
            "<D",
            120.3506,
            0,
        ],
        1728194340: [
            0.28653,
            0,
            0.28653,
            0,
            51.61385,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7463,
            0,
            157.296,
            0,
            0.92451,
            0,
            17.75794,
            0,
            -0.21852,
            0,
            0,
            0,
            29.33445,
            0,
            15.77112,
            "<D",
            119.6831,
            0,
        ],
        1728194400: [
            0.32014,
            0,
            0.32014,
            0,
            51.93371,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7334,
            0,
            169.351,
            0,
            1.15827,
            0,
            17.82648,
            0,
            -0.24832,
            0,
            0,
            0,
            29.33478,
            0,
            15.26177,
            "<D",
            119.5772,
            0,
        ],
        1728194460: [
            0.31778,
            0,
            0.31778,
            0,
            51.50668,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7291,
            0,
            169.9513,
            0,
            1.3678,
            0,
            17.79287,
            0,
            -0.24832,
            0,
            0,
            0,
            29.3344,
            0,
            15.04933,
            "<D",
            117.3993,
            0,
        ],
        1728194520: [
            0.15191,
            0,
            0.15191,
            0,
            51.49935,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.7119,
            0,
            174.4291,
            0,
            1.31111,
            0,
            17.77587,
            0,
            -0.23176,
            0,
            0,
            0,
            29.3333,
            0,
            15.54662,
            "<D",
            116.9541,
            0,
        ],
        1728194580: [
            0.26898,
            0,
            0.26898,
            0,
            51.17966,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.699,
            0,
            171.5004,
            0,
            1.28411,
            0,
            17.92291,
            0,
            -0.24832,
            0,
            0,
            0,
            29.33432,
            0,
            14.8398,
            "<D",
            117.8862,
            0,
        ],
        1728194640: [
            0.37677,
            0,
            0.37677,
            0,
            50.31443,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            160.7882,
            0,
            1.59392,
            0,
            18.08458,
            0,
            -0.20197,
            0,
            0,
            0,
            29.33459,
            0,
            16.42848,
            "<D",
            118.1643,
            0,
        ],
        1728194700: [
            0.27077,
            0,
            0.27077,
            0,
            51.04219,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            166.7336,
            0,
            1.53436,
            0,
            18.38391,
            0,
            -0.17217,
            0,
            0,
            0,
            29.33462,
            0,
            11.9936,
            "<D",
            118.5652,
            0,
        ],
        1728194760: [
            0.13435,
            0,
            0.13435,
            0,
            50.4547,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            171.7759,
            0,
            1.30926,
            0,
            18.39646,
            0,
            -0.16554,
            0,
            0,
            0,
            29.33473,
            0,
            16.97541,
            "<D",
            118.1996,
            0,
        ],
        1728194820: [
            0.19879,
            0,
            0.19879,
            0,
            50.42614,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            174.5622,
            0,
            1.3746,
            0,
            18.49641,
            0,
            -0.08277,
            0,
            0,
            0,
            29.33411,
            0,
            16.40889,
            "<D",
            118.2937,
            0,
        ],
        1728194880: [
            0.08693,
            0,
            0.08693,
            0,
            49.96327,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            167.7902,
            0,
            1.45712,
            0,
            18.32631,
            0,
            -0.09601,
            0,
            0,
            0,
            29.33419,
            0,
            15.99012,
            "<D",
            117.2557,
            0,
        ],
        1728194940: [
            0.15532,
            0,
            0.15532,
            0,
            50.22008,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            168.735,
            0,
            1.37803,
            0,
            18.25174,
            0,
            -0.13243,
            0,
            0,
            0,
            29.33318,
            0,
            15.94964,
            "<D",
            117.6328,
            0,
        ],
        1728195000: [
            0.27438,
            0,
            0.27438,
            0,
            50.56676,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            161.299,
            0,
            1.25368,
            0,
            18.24624,
            0,
            -0.18872,
            0,
            0,
            0,
            29.33205,
            0,
            15.95431,
            "<D",
            117.7805,
            0,
        ],
        1728195060: [
            0.1502,
            0,
            0.1502,
            0,
            50.49947,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            161.5765,
            0,
            1.24072,
            0,
            18.46619,
            0,
            -0.16554,
            0,
            0,
            0,
            29.33164,
            0,
            13.96345,
            "<D",
            117.9129,
            0,
        ],
        1728195120: [
            0.23222,
            0,
            0.23222,
            0,
            50.20593,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            171.4589,
            0,
            1.49961,
            0,
            18.75125,
            0,
            -0.23176,
            0,
            0,
            0,
            29.33181,
            0,
            16.32764,
            "<D",
            117.5394,
            0,
        ],
        1728195180: [
            0.3215,
            0,
            0.3215,
            0,
            50.19901,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            164.5289,
            0,
            1.11191,
            0,
            18.86511,
            0,
            -0.19865,
            0,
            0,
            0,
            29.33155,
            0,
            16.36373,
            "<D",
            117.9943,
            0,
        ],
        1728195240: [
            0.07522,
            0,
            0.07522,
            0,
            50.69584,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            162.8626,
            0,
            2.13356,
            0,
            18.96138,
            0,
            -0.11257,
            0,
            0,
            0,
            29.33175,
            0,
            17.36367,
            "<D",
            117.689,
            0,
        ],
        1728195300: [
            0.21134,
            0,
            0.21134,
            0,
            50.71183,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            159.7246,
            0,
            1.51017,
            0,
            18.87568,
            0,
            -0.21189,
            0,
            0,
            0,
            29.33177,
            0,
            13.86807,
            "<D",
            118.1028,
            0,
        ],
        1728195360: [
            0.18438,
            0,
            0.18438,
            0,
            50.55251,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            162.6331,
            0,
            1.1915,
            0,
            18.52125,
            0,
            -0.20858,
            0,
            0,
            0,
            29.3325,
            0,
            15.50135,
            "<D",
            118.3275,
            0,
        ],
        1728195420: [
            0.30463,
            0,
            0.30463,
            0,
            50.02029,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            159.6688,
            0,
            1.28107,
            0,
            18.95053,
            0,
            -0.14899,
            0,
            0,
            0,
            29.33169,
            0,
            13.10838,
            "<D",
            118.6048,
            0,
        ],
        1728195480: [
            0.21525,
            0,
            0.21525,
            0,
            50.55033,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            159.7298,
            0,
            1.15966,
            0,
            18.99604,
            0,
            -0.14898,
            0,
            0,
            0,
            29.33225,
            0,
            13.12494,
            "<D",
            118.7516,
            0,
        ],
        1728195540: [
            0.33936,
            0,
            0.33936,
            0,
            51.14744,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            166.4288,
            0,
            1.14127,
            0,
            18.97709,
            0,
            -0.09932,
            0,
            0,
            0,
            29.33188,
            0,
            15.84854,
            "<D",
            118.2722,
            0,
        ],
        1728195600: [
            0.18634,
            0,
            0.18634,
            0,
            50.5841,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            158.9073,
            0,
            1.1133,
            0,
            19.00652,
            0,
            -0.06953,
            0,
            0,
            0,
            29.33182,
            0,
            15.94228,
            "<D",
            118.2141,
            0,
        ],
        1728195660: [
            0.20651,
            0,
            0.20651,
            0,
            50.94727,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            165.9043,
            0,
            1.21326,
            0,
            18.83361,
            0,
            -0.10926,
            0,
            0,
            0,
            29.33218,
            0,
            13.86244,
            "<D",
            118.6085,
            0,
        ],
        1728195720: [
            0.28249,
            0,
            0.28249,
            0,
            51.20205,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            163.7251,
            0,
            1.50301,
            0,
            18.96091,
            0,
            -0.16223,
            0,
            0,
            0,
            29.33169,
            0,
            16.68308,
            "<D",
            118.5919,
            0,
        ],
        1728195780: [
            0.11607,
            0,
            0.11607,
            0,
            51.06834,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            159.1625,
            0,
            1.4583,
            0,
            18.58694,
            0,
            -0.19865,
            0,
            0,
            0,
            29.33158,
            0,
            14.44762,
            "<D",
            117.8568,
            0,
        ],
        1728195840: [
            0.17078,
            0,
            0.17078,
            0,
            50.99657,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6775,
            0,
            177.1466,
            0,
            1.17705,
            0,
            18.49451,
            0,
            -0.20859,
            0,
            0,
            0,
            29.3323,
            0,
            15.61053,
            "<D",
            117.4211,
            0,
        ],
        1728195900: [
            0.37431,
            0,
            0.37431,
            0,
            50.26165,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            167.1662,
            0,
            1.26486,
            0,
            18.4047,
            0,
            -0.22845,
            0,
            0,
            0,
            29.33235,
            0,
            16.07015,
            "<D",
            117.5763,
            0,
        ],
        1728195960: [
            0.16875,
            0,
            0.16875,
            0,
            50.49411,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            167.0625,
            0,
            1.23637,
            0,
            18.4488,
            0,
            -0.23839,
            0,
            0,
            0,
            29.33158,
            0,
            14.64709,
            "<D",
            117.817,
            0,
        ],
        1728196020: [
            0.3206,
            0,
            0.3206,
            0,
            50.37828,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            167.9292,
            0,
            1.20255,
            0,
            18.38483,
            0,
            -0.2119,
            0,
            0,
            0,
            29.3318,
            0,
            16.075,
            "<D",
            117.7907,
            0,
        ],
        1728196080: [
            0.06109,
            0,
            0.06109,
            0,
            50.45179,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            158.3455,
            0,
            2.03645,
            0,
            18.71964,
            0,
            -0.1821,
            0,
            0,
            0,
            29.33158,
            0,
            15.59955,
            "<D",
            118.03,
            0,
        ],
        1728196140: [
            0.24827,
            0,
            0.24827,
            0,
            50.7086,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            157.4774,
            0,
            1.38242,
            0,
            18.98426,
            0,
            -0.05959,
            0,
            0,
            0,
            29.33158,
            0,
            14.83075,
            "<D",
            118.2914,
            0,
        ],
        1728196200: [
            0.11076,
            0,
            0.11076,
            0,
            50.73973,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            156.0422,
            0,
            1.23163,
            0,
            18.51927,
            0,
            -0.06953,
            0,
            0,
            0,
            29.3316,
            0,
            15.90973,
            "<D",
            118.2973,
            0,
        ],
        1728196260: [
            0.11579,
            0,
            0.11579,
            0,
            50.56828,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            168.4355,
            0,
            1.34221,
            0,
            18.2431,
            0,
            -0.05959,
            0,
            0,
            0,
            29.33158,
            0,
            16.46921,
            "<D",
            118.0706,
            0,
        ],
        1728196320: [
            0.23946,
            0,
            0.23946,
            0,
            50.19094,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            160.8714,
            0,
            1.33566,
            0,
            18.07157,
            0,
            -0.0629,
            0,
            0,
            0,
            29.3316,
            0,
            13.95288,
            "<D",
            117.958,
            0,
        ],
        1728196380: [
            0.09937,
            0,
            0.09937,
            0,
            50.31954,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            157.9895,
            0,
            1.05254,
            0,
            18.11811,
            0,
            -0.06621,
            0,
            0,
            0,
            29.33159,
            0,
            14.38659,
            "<D",
            117.7791,
            0,
        ],
        1728196440: [
            0.14801,
            0,
            0.14801,
            0,
            50.06582,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.613,
            0,
            159.0944,
            0,
            1.03107,
            0,
            18.13568,
            0,
            -0.07946,
            0,
            0,
            0,
            29.33171,
            0,
            18.28113,
            "<D",
            117.9807,
            0,
        ],
        1728196500: [
            0.15532,
            0,
            0.15532,
            0,
            50.07726,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            157.8746,
            0,
            1.10093,
            0,
            18.15855,
            0,
            -0.07284,
            0,
            0,
            0,
            29.33178,
            0,
            14.42193,
            "<D",
            118.8387,
            0,
        ],
        1728196560: [
            0.2995,
            0,
            0.2995,
            0,
            49.94738,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            155.8533,
            0,
            1.07171,
            0,
            18.31596,
            0,
            -0.11919,
            0,
            0,
            0,
            29.33177,
            0,
            14.34063,
            "<D",
            118.7477,
            0,
        ],
        1728196620: [
            0.30293,
            0,
            0.30293,
            0,
            50.35085,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6431,
            0,
            145.601,
            0,
            1.2945,
            0,
            18.22318,
            0,
            -0.07615,
            0,
            0,
            0,
            29.33179,
            0,
            15.40926,
            "<D",
            118.7829,
            0,
        ],
        1728196680: [
            0.23017,
            0,
            0.23017,
            0,
            50.35198,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            144.9177,
            0,
            1.28776,
            0,
            18.34058,
            0,
            -0.08939,
            0,
            0,
            0,
            29.33149,
            0,
            15.94549,
            "<D",
            118.7476,
            0,
        ],
        1728196740: [
            0.2314,
            0,
            0.2314,
            0,
            50.1502,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            138.5567,
            0,
            1.07392,
            0,
            18.44545,
            0,
            -0.06622,
            0,
            0,
            0,
            29.33175,
            0,
            13.51247,
            "<D",
            119.0261,
            0,
        ],
        1728196800: [
            0.20927,
            0,
            0.20927,
            0,
            50.38583,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.656,
            0,
            143.4756,
            0,
            1.16976,
            0,
            18.26696,
            0,
            -0.10264,
            0,
            0,
            0,
            29.3316,
            0,
            14.77233,
            "<D",
            119.1225,
            0,
        ],
        1728196860: [
            0.18475,
            0,
            0.18475,
            0,
            50.57099,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6603,
            0,
            148.2353,
            0,
            1.46446,
            0,
            17.9918,
            0,
            -0.11588,
            0,
            0,
            0,
            29.3316,
            0,
            18.69008,
            "<D",
            118.568,
            0,
        ],
        1728196920: [
            0.23191,
            0,
            0.23191,
            0,
            50.43818,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6689,
            0,
            149.0128,
            0,
            1.34811,
            0,
            17.71217,
            0,
            -0.15892,
            0,
            0,
            0,
            29.3316,
            0,
            14.76777,
            "<D",
            119.2468,
            0,
        ],
        1728196980: [
            0.25978,
            0,
            0.25978,
            0,
            50.3477,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6818,
            0,
            145.5259,
            0,
            1.28305,
            0,
            17.58146,
            0,
            -0.22845,
            0,
            0,
            0,
            29.33061,
            0,
            17.7518,
            "<D",
            118.8648,
            0,
        ],
        1728197040: [
            0.17741,
            0,
            0.17741,
            0,
            50.40585,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6947,
            0,
            154.7284,
            0,
            1.27873,
            0,
            17.58582,
            0,
            -0.22514,
            0,
            0,
            0,
            29.33041,
            0,
            14.93335,
            "<D",
            118.4621,
            0,
        ],
        1728197100: [
            0.06931,
            0,
            0.06931,
            0,
            50.5643,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6861,
            0,
            153.2091,
            0,
            1.24131,
            0,
            17.55967,
            0,
            -0.23839,
            0,
            0,
            0,
            29.33025,
            0,
            15.94305,
            "<D",
            118.0055,
            0,
        ],
        1728197160: [
            0.1437,
            0,
            0.1437,
            0,
            50.48439,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6732,
            0,
            154.4703,
            0,
            1.22806,
            0,
            17.63257,
            0,
            -0.22845,
            0,
            0,
            0,
            29.33118,
            0,
            15.68796,
            "<D",
            117.2708,
            0,
        ],
        1728197220: [
            0.10789,
            0,
            0.10789,
            0,
            50.24948,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6646,
            0,
            155.9501,
            0,
            1.24775,
            0,
            17.61124,
            0,
            -0.23508,
            0,
            0,
            0,
            29.33143,
            0,
            14.27489,
            "<D",
            117.5733,
            0,
        ],
        1728197280: [
            0.17408,
            0,
            0.17408,
            0,
            50.34192,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6517,
            0,
            163.0731,
            0,
            0.89527,
            0,
            17.6316,
            0,
            -0.2417,
            0,
            0,
            0,
            29.33155,
            0,
            14.52732,
            "<D",
            117.7722,
            0,
        ],
        1728197340: [
            0.14477,
            0,
            0.14477,
            0,
            50.05812,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6302,
            0,
            142.3421,
            0,
            1.2976,
            0,
            17.68718,
            0,
            -0.22845,
            0,
            0,
            0,
            29.33158,
            0,
            14.29712,
            "<D",
            117.5021,
            0,
        ],
        1728197400: [
            0.31634,
            0,
            0.31634,
            0,
            50.15294,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            165.6286,
            0,
            0.89864,
            0,
            17.70778,
            0,
            -0.23508,
            0,
            0,
            0,
            29.33158,
            0,
            14.72028,
            "<D",
            117.5557,
            0,
        ],
        1728197460: [
            0.15254,
            0,
            0.15254,
            0,
            50.03531,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6216,
            0,
            162.7185,
            0,
            0.89867,
            0,
            17.56667,
            0,
            -0.12912,
            0,
            0,
            0,
            29.33158,
            0,
            14.88508,
            "<D",
            117.938,
            0,
        ],
        1728197520: [
            0.2363,
            0,
            0.2363,
            0,
            50.38031,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6044,
            0,
            165.4131,
            0,
            0.79261,
            0,
            17.54011,
            0,
            -0.08608,
            0,
            0,
            0,
            29.33164,
            0,
            15.31195,
            "<D",
            117.8675,
            0,
        ],
        1728197580: [
            0.33913,
            0,
            0.33913,
            0,
            50.03091,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            168.4527,
            0,
            1.07386,
            0,
            17.55241,
            0,
            -0.14236,
            0,
            0,
            0,
            29.33162,
            0,
            16.11548,
            "<D",
            117.3257,
            0,
        ],
        1728197640: [
            0.08901,
            0,
            0.08901,
            0,
            49.94515,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            159.0068,
            0,
            0.99739,
            0,
            17.44407,
            0,
            -0.23176,
            0,
            0,
            0,
            29.3316,
            0,
            14.63724,
            "<D",
            117.5336,
            0,
        ],
        1728197700: [
            0.16415,
            0,
            0.16415,
            0,
            49.88443,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.5915,
            0,
            163.3215,
            0,
            0.78099,
            0,
            17.55018,
            0,
            -0.22514,
            0,
            0,
            0,
            29.33156,
            0,
            15.03392,
            "<D",
            118.2992,
            0,
        ],
        1728197760: [
            0.08855,
            0,
            0.08855,
            0,
            48.13433,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            150.9252,
            0,
            1.03014,
            0,
            17.58003,
            0,
            -0.24169,
            0,
            0,
            0,
            29.33158,
            0,
            15.89061,
            "<D",
            118.5611,
            0,
        ],
        1728197820: [
            0.20815,
            0,
            0.20815,
            0,
            49.70654,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            140.965,
            0,
            0.81464,
            0,
            17.59312,
            0,
            -0.23507,
            0,
            0,
            0,
            29.33158,
            0,
            16.76076,
            "<D",
            117.6282,
            0,
        ],
        1728197880: [
            0.25262,
            0,
            0.25262,
            0,
            44.50532,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.60011,
            0,
            162.8044,
            0,
            1.23987,
            0,
            17.66888,
            0,
            -0.24169,
            0,
            0,
            0,
            29.33158,
            0,
            15.32018,
            "<D",
            117.7015,
            0,
        ],
        1728197940: [
            0.29045,
            0,
            0.29045,
            0,
            48.54311,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6087,
            0,
            153.7419,
            0,
            0.92824,
            0,
            17.76613,
            0,
            -0.23838,
            0,
            0,
            0,
            29.33158,
            0,
            14.75688,
            "<D",
            117.9637,
            0,
        ],
        1728198000: [
            0.34391,
            0,
            0.34391,
            0,
            46.1201,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.61731,
            0,
            165.4491,
            0,
            1.08768,
            0,
            17.76302,
            0,
            -0.245,
            0,
            0,
            0,
            29.33159,
            0,
            14.77948,
            "<D",
            117.9661,
            0,
        ],
        1728198060: [
            0.13611,
            0,
            0.13611,
            0,
            48.62483,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            164.7039,
            0,
            0.92201,
            0,
            17.70905,
            0,
            -0.24831,
            0,
            0,
            0,
            29.3312,
            0,
            15.45218,
            "<D",
            118.0589,
            0,
        ],
        1728198120: [
            0.40628,
            0,
            0.40628,
            0,
            47.91692,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6259,
            0,
            169.0072,
            0,
            0.81231,
            0,
            17.6833,
            0,
            -0.22183,
            0,
            0,
            0,
            29.33127,
            0,
            18.60371,
            "<D",
            118.0018,
            0,
        ],
        1728198180: [
            0.1887,
            0,
            0.1887,
            0,
            39.33028,
            0,
            0.10652,
            "<D",
            1.23746,
            "<D",
            1.34399,
            "<D",
            190.4038,
            "<D",
            0.09267,
            "<D",
            34.6345,
            0,
            158.757,
            0,
            0.8548,
            0,
            17.66444,
            0,
            -0.23507,
            0,
            0,
            0,
            29.33125,
            0,
            15.18746,
            "<D",
            118.5702,
            0,
        ],
    },
};
